import { FxContext } from '@agility/frameworkcore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { McnConfig } from '../mcnConfig/McnConfig';
import { IdleSessionModalComponent } from './IdleSessionModal';
import { OAuthStorage,OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class IdleSessionService implements OnDestroy {
  constructor(
    private keepalive: Keepalive,
    private idle: Idle,
    private dialog: MatDialog,
    private fxContext: FxContext,
    private http: HttpClient,
    private appConfig: McnConfig,
    private oauthStorage: OAuthStorage,private oAuthService: OAuthService) {
      this.setKeepAliveSessionConfiguration();
  }

  public setKeepAliveSessionConfiguration(): void {
    const timeInterval = 1000;

    // sets an idle timeout of 17 minutes.
    this.idle.setIdle(1020);

    // sets a timeout period of 2 minutes.
    this.idle.setTimeout(120);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeoutWarning.subscribe(
      (countdown) => {

        if(this.fxContext.IsAuthenticated)
        {
          this.validateAlert(countdown);
        }
      });
    this.keepalive.onPing.subscribe(() => {
        this.getData().subscribe(data => {
          var obj = JSON.parse(data);
          if (!isNullOrUndefined(obj)) {
            this.fxContext.userProfile.userAccessToken = obj.token;
          }
          this.keepalive.interval(timeInterval);
          this.keepalive.start();
        });
    });
    this.keepalive.interval(timeInterval);
   // this.start();

  }

  public start(): void {
    this.keepalive.start();
    this.idle.watch();
  }

  public stop(): void {
    this.idle.stop();
    this.keepalive.stop();
    window.clearTimeout();
  }


  private getData(): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'text/plain; charset=utf-8');
    const resp = this.http.get(this.appConfig.refreshToken, { responseType: 'text' });
    return resp;
  }

  public validateAlert(countDown: number): void {
    let setTimeOutHandle: 0;
    this.stop();
    const dialogRef = this.dialog.open(IdleSessionModalComponent, {
      panelClass: 'session-warn-modal',
      disableClose: true
    });

    dialogRef.componentInstance.countdown = countDown;
    dialogRef.afterClosed().subscribe(
      () => {
        if (dialogRef.componentInstance.keepalive) {
          dialogRef.close();
          this.dialog.closeAll();
          this.yesClickEvent(setTimeOutHandle);
          this.getData().subscribe(data => {
            var obj = JSON.parse(data);
            if (!isNullOrUndefined(obj)) {
              this.fxContext.userProfile.userAccessToken = obj.token;
            }
                // sets an idle timeout of 17 minutes.
                this.idle.setIdle(1020);

                // sets a timeout period of 2 minutes.
                this.idle.setTimeout(120);

                this.keepalive.interval(1000);
                this.keepalive.start();
              });

            }
      });
  }

  private clearTimeout(timeOutHandle: any): void {
    window.clearTimeout(timeOutHandle);
  }

  private yesClickEvent(timeOutHandle: any): void {
    this.clearTimeout(timeOutHandle);
    this.start();
  }

  public ngOnDestroy(): void {
    if (this.idle) {
      this.stop();
    }
  }
}
