import { FrameworkCoreModule } from '@agility/frameworkcore';
import { MatTooltipModule, MatMenuModule, MatTableModule, MatRadioModule } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from 'ng2-translate';
import { ExpansionPanelsModule } from 'ng2-expansion-panels';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import { DashboardComponent } from './DashboardComponent';
import { userDashboardComponent } from './userDashboard/userDashboardComponent';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { stackedChartComponent } from './userDashboard/stackedChart/stackedChartComponent';
import { pieChartComponent } from './userDashboard/pieChart/pieChartComponent';
import { guageChartComponent } from './userDashboard/guageChart/guageChartComponent';
import { horizontalBarChartComponent } from './userDashboard/horizontalBarChart/horizontalBarChartComponent';
import { lineChartComponent } from './userDashboard/lineChart/lineChartComponent';
import { areaChartComponent } from './userDashboard/areaChart/areaChartComponent';
import { mapChartComponent } from './userDashboard/mapChart/mapChartComponent';
import { bubbleChartComponent } from './userDashboard/bubbleBarChart/bubbleChartComponent';
import { candlestickChartComponent } from './userDashboard/candleStickChart/candlestickChartComponent';
import { scatterChartComponent } from './userDashboard/scatterBarChart/scatterChartComponent';
import { MessagingSysComponent } from './messagingSys/MessagingSysComponent';
import { TrackingDashboardComponent } from './trackingDashborad/TrackingDashboardComponent';
import { ModeTrackingComponent } from './trackingDashborad/modeTrackingDashboard/ModeTrackingComponent';
import { ChartModule } from 'angular2-highcharts';

import { McnControlsModule } from '../mcnControls/McnControlsModule';

import { MsgConfigNewResolver } from './messagingSys/services/MsgConfigResolver';
import { McnChartModule } from '../McnChartModule';
import { NotificationConfigComponent } from './notificationConfig/NotificationConfigComponent';
import { LanguageModule } from '../common/LanguageModule';

const AccountRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'Home', component: DashboardComponent, data: { title: 'Inbox' } },
      { path: 'tracking', component: TrackingDashboardComponent, data: { title: 'Tracking Dashboard' } },
      { path: 'mode-tracking', component: ModeTrackingComponent, data: { title: 'Tracking Dashboard' } },
      { path: 'user-dashboard', component: userDashboardComponent, data: { title: 'Dashboard' }, },
      {
        path: 'messaging-sys', component: MessagingSysComponent,
        data: { title: 'Message Configuartion' }, resolve: { dataModel: MsgConfigNewResolver }
      },
      { path: 'notification-config', component: NotificationConfigComponent, data: { title: 'Notification Configuartion' } }
    ],
  },
];

@NgModule({
  imports: [
    FrameworkCoreModule,
    CdkTableModule,
    CommonModule,
    RouterModule.forChild(AccountRoutes),
    ChartModule,
    NgxChartsModule,
    McnControlsModule,
    MatTooltipModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    ExpansionPanelsModule,
    AgmCoreModule,
    AgmDirectionModule,
    McnChartModule,
    LanguageModule
  ],
  exports: [
    FrameworkCoreModule,
    CdkTableModule,
    CommonModule,
    McnControlsModule,
    MatTooltipModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    ExpansionPanelsModule,
    LanguageModule
  ],
  declarations: [
    // GoogleChart,
    userDashboardComponent,
    stackedChartComponent,
    pieChartComponent,
    guageChartComponent,
    horizontalBarChartComponent,
    lineChartComponent,
    mapChartComponent,
    areaChartComponent,
    bubbleChartComponent,
    candlestickChartComponent,
    scatterChartComponent,
    TrackingDashboardComponent,
    ModeTrackingComponent,
    MessagingSysComponent,
    NotificationConfigComponent
  ],
  entryComponents: [
  ]
})

export class DashboardRoutingModule { }
