<!--Page Action Panel Starts-->
<div [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <mat-toolbar-row fxLayout="row" class="page-action-panel">
        <div class="container subheader-container px-0" fxFlex="100" fxFlex.sm="100" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="auto" class="form-inline pt-1 dashboard-subheader-title">
                <span class="jrny-label">
                    {{this.pageTitle | translate}}
                </span>
            </div>
            <div fxFlex="none">
                <div *ngIf="!this.isSysAdmin" fxLayout="row" fxLayoutAlign="end center"
                     class="imp-up-btns static-position"
                     [class.display-none-custom]="isInbox">
                    <ul fxLayout="row" fxLayoutAlign="space-around center" class="left-nav dropdown-wrapper">
                        <li class="dropdown first-li" *ngIf="!pcaShowHide"
                            [ngClass]="jshowHide ||  vshowHide || inspectionShowHide">
                            <a fxFlex="column" fxLayoutAlign="center center" class="subheader-navigation"
                               (click)="toggleClassName()" matTooltip="{{'Filters'|translate}}"
                               [class.active]="filterMenuService.isFilterOpened">
                                <i class="icon-filter"></i>
                            </a>
                        </li>
                      </ul>
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</div>
