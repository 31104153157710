import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Route, PreloadingStrategy } from '@angular/router';
import { FxContext } from '@agility/frameworkcore';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
  private preloadedModules: string[] = [];
  constructor(private fxContext: FxContext,
    private spinner: Ng4LoadingSpinnerService) { }
  private count = 0;

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (this.fxContext.IsAuthenticated && this.fxContext.userProfile != null
      && this.fxContext.userProfile.userRoles.findIndex(x => x.name === 'SysAdmin') < 0) {
      if (route.data && route.data['preload']) {
        this.spinner.show();
        if (this.fxContext.menus.length === this.count) {
          this.spinner.hide();
        }
        if (this.checkPermission(route.path) === true && this.defineWeightage()) {
          if (!this.preloadedModules.includes(route.path)) {
            this.count++;
            this.preloadedModules.push(route.path);
            return load();
          }
        }
      }
    }
    return Observable.of(null);
  }

  private checkPermission(routePath: string): boolean {
    const getMenus = this.fxContext.menus.find(a => a.url === '#' + routePath);
    return getMenus !== undefined;
  }

  private defineWeightage(): boolean {
    return (this.fxContext.menus.length / 2) >= this.count;
  }
}
