import { Injectable } from '@angular/core';
import { ShipEffectsDetailsModel } from '../../models/ShipEffectDetailsModel';
import { ShipStoreDetailsModel } from '../../models/ShipStoreDetailsModel';
import { ShipCargoDetailsDataModel } from '../../models/ShipCargoDetailsDataModel';

export interface MyData {
  shipStoreId: number;
  shipDeclarationId: number;
  shipVaccinationId: number;
  shipVaccinationDetailsId: number;
  shipEffectId: number;
  shipCargoId: number;
  shipCrewDetailId: number;
  shipCrewId: number;
  shipStoreDetailId: number;
  shipPanSecurityId: number;
  shipImmigrationId: number;
  shipEffectsDetailId: number;
  shipEffectsDetails: ShipEffectsDetailsModel[];
  shipDeclarationState: string;
  vesselPKPStatusforDashboard: string;
  formAStatusforDashboard: string;
  shipClearanceStatusforDashboard: string;
  shipPanStatusforDashboard: string;
  portClearanceStatusForDashboard: string;
  shipImmigrationState: string;
  shipPanState: string;
  shipStoreDetails: ShipStoreDetailsModel[];
  shipCargoDetailsDataId: number;
  shipCargoDetails: ShipCargoDetailsDataModel[];
  shipDangerousGoodId: number;
  shipEndemicCountryId: number;
  shipIssuancePortId: number;
  shipBlacklistId: number;
  sCReasonId: number;
  hbItemId: number;
  shipFormAId: number;
  shipPassengerId: number;
  shipPassengerDetailsId: number;
  shipCoPassengerDetailsId: number;
  journeyId: number;
  shipClearanceType: number;
  shipDeclarationRefModel: any;
  shipFormABillofLadingId: number;
  shipCallNumber: number;
  shipPassengerDetailId: number;
  shipPortofCallId: number;
  shipPortofCallDetailId: number;
  shipMasterDataTabId: any;
  vaccinationDetails: any;
  purposeofCall: number;
  shipMaritimeDeclarationId: number;
  shipVesselAdvisePkpId: number;
  dgCount: number;
  ShipimmigrationtatusforDashboard: string;
  TransactionType: string;
}

export interface Names {
}

@Injectable()
export class ShipClrSharedService {
  sharingData: MyData = {
    shipStoreId: 0,
    shipEffectId: 0,
    shipVaccinationId: 0,
    shipVaccinationDetailsId: 0,
    shipCargoId: 0,
    shipCrewDetailId: 0,
    shipCrewId: 0,
    shipStoreDetailId: 0,
    shipEffectsDetailId: 0,
    shipEffectsDetails: [],
    shipDeclarationId: 0,
    shipDeclarationState: '',
    vesselPKPStatusforDashboard: '',
    formAStatusforDashboard: '',
    shipClearanceStatusforDashboard: '',
    portClearanceStatusForDashboard: '',
    shipPanStatusforDashboard: '',
    shipImmigrationState: '',
    shipPanState: '',
    shipStoreDetails: [],
    shipCargoDetailsDataId: 0,
    shipCargoDetails: [],
    shipDangerousGoodId: 0,
    shipPanSecurityId: 0,
    shipImmigrationId: 0,
    shipEndemicCountryId: 0,
    shipIssuancePortId: 0,
    shipBlacklistId: 0,
    sCReasonId: 0,
    hbItemId: 0,
    shipFormAId: 0,
    shipPassengerId: 0,
    shipPassengerDetailsId: 0,
    shipCoPassengerDetailsId: 0,
    journeyId: 0,
    shipClearanceType: 0,
    shipDeclarationRefModel: '',
    shipFormABillofLadingId: 0,
    shipCallNumber: 0,
    shipPassengerDetailId: 0,
    shipPortofCallId: 0,
    shipPortofCallDetailId: 0,
    shipMasterDataTabId: null,
    vaccinationDetails: null,
    purposeofCall: 0,
    shipMaritimeDeclarationId: 0,
    shipVesselAdvisePkpId: 0,
    dgCount: 0,
    ShipimmigrationtatusforDashboard: '',
    TransactionType: '',
  };
  sharingNames: Names = {
  };



}
