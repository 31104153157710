import { Component, Inject, OnInit } from '@angular/core';
import { SafeUnsubscriber } from '../../../common/SafeUnsubscriber';
import { StorageService } from '../../../storageService/storageService';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AccountMessages } from '../../constant/accountConstant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToasterService } from '../../../toastrService/toasterService';

@Component({
  selector: 'app-duty-tax-output-popup',
  templateUrl: './DutyTaxOutputPopupComponent.html',
})

export class DutyTaxOutputPopupComponent extends SafeUnsubscriber implements OnInit {

  public customs = 0;
  public cif = 0;
  public sales = 0;
  public excise = 0;
  public total = 0;
  public ciflabel = 'CIF Value';
  public duty = 0;
  public other = 0;
  public antidumping = 0;
  public vat = 0;
  public gst = 0;
  public isImport = true;
  public dutylabel = 'Import Duty';
  public direction: any;
  public country: any;
  public quantity: any;
  public uom: any;
  public hscode: any;
  /* public trendImportPieChartOptions: any =
  {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    },
    series: [{
      name: '',
      colorByPoint: true,
      data: [{
        name: 'CIF',
        y: this.cif,
      },
      {
        name: 'Customs',
        y: this.customs
      },
      {
        name: 'Sales',
        y: this.sales
      },
      {
        name: 'Excise',
        y: this.excise
      }]
    }]
  }; */


  public dutyTaxCharForm: FormGroup;
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private fb: FormBuilder, public webStorageService: StorageService, @Inject(MAT_DIALOG_DATA) dutyData: any,
    private http: HttpClient, private appConfig: McnConfig, private toasterService: ToasterService) {
    super();
    this.customs = dutyData.dutyData.customsValue;
    this.duty = dutyData.dutyData.duty;
    this.cif = dutyData.dutyData.cif;
    this.total = this.duty + this.sales + this.excise;
    this.ciflabel = dutyData.dutyData.isCif === true ? 'CIF Value' : 'FOB Value';
    this.isImport = dutyData.dutyData.isCif === true;
    this.dutylabel = dutyData.dutyData.isCif === true ? 'Import Duty' : 'Export Duty';
    this.hscode = dutyData.hscode;
    this.direction = dutyData.direction;
    this.country = dutyData.country;
    this.quantity = dutyData.quantity;
    this.uom = dutyData.uom;
  }

  ngOnInit() {
    this.dutyTaxCharForm = this.fb.group({
      cifVal: [''],
      customs: [''],
      salesTax: [''],
      excise: [''],
      total: ['']
    });
  }

  downloadDataExcel() {
    this.getTariffItemDutyRateData(this.appConfig.anonymousDownloadTariffItemDutyDetailsApiUrl).subscribe((response) => {
      this.downloadFileTypes(response, 'application/vnd.ms-excel');
    });
  }

  downloadDataPdf() {
    this.getTariffItemDutyRateData(this.appConfig.anonymousDownloadPdfTariffItemDutyDetailsApiUrl).subscribe((response) => {
      this.downloadFileTypes(response, 'application/pdf');
    });
  }

  public downloadFileTypes(response: any, fileType?: any) {
    if (response === 0) {
      return;
    }
    this.renderBlob(response, fileType);
  }

  public getTariffItemDutyRateData(url: any): Observable<any> {
    const uploadDocumentModel = {
      originalFilePath: '',
      hscode: this.hscode,
      direction: this.direction,
      country: this.country,
      cif: this.cif,
      quantity: this.quantity,
      uom: this.uom
    };
    return this.http.post(url,
      uploadDocumentModel, { responseType: 'blob', headers: this.headers })
      .pipe(map((response) => response),
        catchError(() => Observable.throwError(
          { msg: this.DutyTaxChargeHandleError(), ngNavigationCancelingError: true })));
  }

  public DutyTaxChargeHandleError() {
    return this.toasterService.Error('Internal Server Error. Please Try Again Later !');
  }

  public renderBlob(response: any, responseType: any) {
    const res = response;
    const blob = new Blob([res], { type: responseType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    const filename = AccountMessages.DutyTaxFileName.toString() + (responseType === 'application/vnd.ms-excel' ? '.xlsx' : '.pdf');
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}

