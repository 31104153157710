import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { ToasterService } from '../../toastrService/toasterService';
@Injectable()
export class UserDashboardService {
  constructor(private http: HttpClient, private toastrService: ToasterService,
    private appConfig: McnConfig) { }
  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }
  getStats(): Observable<any> {
    return this.http.get(this.appConfig.getStats)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }
}
