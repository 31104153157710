<!--Profile Popup Started-->
<div class="container dailog-section">
    <div fxLayout="column" fxFlexFill>
        <div fxLayout="row" fxLayoutAlign="start center">
            <h1 matDialogTitle>Profile</h1>
        </div>
        <div mat-dialog-content>
            <div fxLayout="column" fxFlexFill>
                <div class="profile-details" fxLayout="column" fxLayout.gt-sm="row">
                    <div class="mb-4 mb-mat-0" fxFlex="100" fxFlex.gt-xs="30">
                        <ul class="languages" fxLayout="row">
                            <li class="active ml-0"><a href="javascript:void(0)">English</a></li>
                            <li><a href="javascript:void(0)"> </a></li>
                            <li><a href="javascript:void(0)">Malay</a></li>
                        </ul>
                        <div class="profile-pic">
                            <img src="../../../assets/images/user-img.jpg">
                            <a href="javascript:void(0)" class="change">Change</a>
                        </div>
                        <div class="login-details mt-3" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="prof-label-cont">
                                <p class="prof-cont">********</p>
                                <p class="prof-label">Password</p>
                            </div>
                            <ul class="hide-show-edit" fxLayout="row" fxLayoutAlign="space-between center">
                                <li class="hide-show">
                                    <a href="javascript:void(0)" matTooltip="Preview"><i class="icon-pwshow"></i></a>
                                </li>
                                <li class="edit">
                                    <a href="javascript:void(0)" matTooltip="Edit"><i class="icon-edit"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="70" [ngSwitch]="isEditView">
                        <div *ngSwitchDefault class="mt-5 mt-mat-0 ml-0 ml-mat-4" fxLayout="row wrap">
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Virat Bakshi</p>
                                    <p class="prof-label">Full Name</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">vir@gmail.com</p>
                                    <p class="prof-label">Login ID</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">vir.bakshi@gmail.com</p>
                                    <p class="prof-label">Primary Email ID</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">-</p>
                                    <p class="prof-label">Alternate Email ID</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Indian</p>
                                    <p class="prof-label">Nationality</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Asif Hameed</p>
                                    <p class="prof-label">JPN Name</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Male</p>
                                    <p class="prof-label">Gender</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Ship Agent</p>
                                    <p class="prof-label">Designation</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Ship</p>
                                    <p class="prof-label">Department Name</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">Malaysia</p>
                                    <p class="prof-label">Custom Station</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">+91 9675859578</p>
                                    <p class="prof-label">Mobile Number</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">-</p>
                                    <p class="prof-label">Telephone Number 1</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">-</p>
                                    <p class="prof-label">Telephone Number 2</p>
                                </div>
                            </div>
                            <div class="pl-0 pl-mat-2" fxFlex="100" fxFlex.gt-xs="50">
                                <div class="prof-label-cont">
                                    <p class="prof-cont">+91 8596</p>
                                    <p class="prof-label">Fax Number</p>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="true">
                            <form [formGroup]="profileModalInfoForm" novalidate
                                  (ngSubmit)="validateProfileModalInfoForm(profileModalInfoForm.value, profileModalInfoForm.valid)">
                                <div fxLayout="row wrap" fxLayoutAlign="start center">
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Full Name" controlName="fullName" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Login ID" controlName="loginID" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Primary Email ID" controlName="primaryMailID" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Alternate Email ID" controlName="alternateMailID" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">             
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="JPN Name" controlName="jpnName" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50" class="radio-group">
                                        <span class="mr-3 mb-0 checkbox-header">
                                            Gender
                                            <sup>&nbsp;*</sup>
                                        </span>
                                        <div fxLayout="column">
                                            <mat-radio-group fxLayout="row">
                                                <mat-radio-button value="male">Male</mat-radio-button>
                                                <mat-radio-button value="female">Female</mat-radio-button>
                                                <mat-radio-button value="neuter">Neuter Gender</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Designation" controlName="designation" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Department Name" controlName="departmentName" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <mcn-input placeholder="Custom Station" controlName="customStation" [formGroup]="profileModalInfoForm"></mcn-input>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <div fxLayout="row">
                                            <div fxFlex="100" fxFlex.gt-xs="40">
                                            </div>
                                            <div fxFlex="100" fxFlex.gt-xs="60">
                                                <mcn-input placeholder="Number" controlName="mobileNumber" [formGroup]="profileModalInfoForm"></mcn-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <div fxLayout="row">
                                            <div fxFlex="100" fxFlex.gt-xs="40">
                                            </div>
                                            <div fxFlex="100" fxFlex.gt-xs="60">
                                                <mcn-input placeholder="Number" controlName="tele1Input" [formGroup]="profileModalInfoForm"></mcn-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <div fxLayout="row">
                                            <div fxFlex="100" fxFlex.gt-xs="40">
                                            </div>
                                            <div fxFlex="100" fxFlex.gt-xs="60">
                                                <mcn-input placeholder="Number" controlName="tele2Input" [formGroup]="profileModalInfoForm"></mcn-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxFlex="100" fxFlex.gt-xs="50">
                                        <div fxLayout="row">
                                            <div fxFlex="100" fxFlex.gt-xs="40">
                                            </div>
                                            <div fxFlex="100" fxFlex.gt-xs="60">
                                                <mcn-input placeholder="Number" controlName="faxInput" [formGroup]="profileModalInfoForm"></mcn-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
          <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
            <a href="javascript:void(0)" class="overlay-close" (click)="closeProfileDialog()">
              <mat-icon class="overlay-menu-remove"></mat-icon>
            </a>
            <mcn-button type="button" text="{{'Edit' | translate}}" cssClass="btn-custom-primary" [hidden]="isEditView"
                        (OnClick)="editProfile()"></mcn-button>
            <mcn-button type="button" text="{{'Save' | translate}}" cssClass="btn-custom-primary" [hidden]="!isEditView"
                        (OnClick)="saveProfile()"></mcn-button>
          </div>
        </div>
    </div>
</div>
<!--Profile Popup Ended-->
