import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';


import { ReportCategorizationModel } from '../../models/ReportCategorizationModel';
import { OpmReportsService } from '../../services/opmReports/OpmReportsService';

@Injectable()
export class OpmReportsListResolver implements Resolve<ServiceDocument<ReportCategorizationModel>> {
  constructor(private service: OpmReportsService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<ReportCategorizationModel>> {
    return this.service.listOpmReports('', '');
  }
}

// @Injectable()
// export class OpmMirrorAnalysisReportOpenResolver implements Resolve<ServiceDocument<MirrorAnalysisOpenModel>> {
//   constructor(private service: OpmReportsService) { }

//   public resolve(route: ActivatedRouteSnapshot): Observable<ServiceDocument<MirrorAnalysisOpenModel>> {
//     return this.service.openOpmMirrorAnalysisReport('', '');
//   }
// }
