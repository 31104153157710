import { Injectable } from '@angular/core';
export interface MyData {
  drawbackFacilityId: number;
  drawbackFacilityReqNumber: string;
  drawbackPccId: number;
  drawbackRawMaterialId: number;
  drawbackId: number;
  isEdit: boolean;
  claimRightId: number;
  customStationId: number;
  drawbackNumber: string;
  claimRightDeclarationId: number;
  decExtensionRequestId: number;
  declarationId: number;
  referenceDeclarationId: number;
  drawbackClaimReqStatus: string;
  drawbackFacilityStatus: string;
}

@Injectable()
export class DrawbackSharedService {

  constructor() {
  }

  sharingData: MyData = {
    drawbackFacilityId: 0,
    drawbackFacilityReqNumber: null,
    drawbackPccId: 0,
    drawbackRawMaterialId: 0,
    drawbackId: 0,
    isEdit: false,
    claimRightId: 0,
    customStationId: 0,
    drawbackNumber: null,
    claimRightDeclarationId: 0,
    decExtensionRequestId: 0,
    declarationId: 0,
    referenceDeclarationId: 0,
    drawbackClaimReqStatus: null,
    drawbackFacilityStatus: null,
  };
}
