<div [formGroup]="formGroup">
        <mat-form-field class="mat-input-container" floatLabel="auto">
          <input matInput id="txtdate_{{optionalId}}{{placeholder | removeSpace}}" [matDatepicker]="picker"
          placeholder="{{placeholder}}"
          [value]="selectedValue"
           formControlName="{{controlName}}" [required]="required"
          [readonly]="readonly" (dateChange)="_setAction($event)">
          <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="allowInputOnly"></mat-datepicker-toggle>
          <mat-datepicker #picker [disabled]="completeDisable"></mat-datepicker>
        </mat-form-field>
    </div>