import { Injectable } from "@angular/core";
import { AuthConfig } from "angular-oauth2-oidc";
@Injectable({
  providedIn: "root",
})
export class AppSettings {
  buildVersion = "R2.0.alpha.1 Build 2020-12-14 16.00";
  i18nEnabled = true;
  defaultI18nLang = "en";
  authConfig: AuthConfig = {};
  apiEndpoints: { [key: string]: string } = {};
  keys: {
    encryptKey?: string;
    isChatbotActive?: boolean;
    isMicroService?: boolean;
    isCoreSOA?: boolean;
  } = {
    encryptKey: "",
    isMicroService: true,
    isCoreSOA: true,
    isChatbotActive: true,
  };
}

@Injectable({
  providedIn: "root",
})
export class AppSettingsService {
  APP_SETTINGS: AppSettings;

  constructor(appSetting?: AppSettings) {
    this.APP_SETTINGS = {
      ...new AppSettings(),
      ...appSetting,
    };
  }

  getConfig(): AppSettings {
    return this.APP_SETTINGS;
  }
}
