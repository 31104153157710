import { ModuleWithProviders, NgModule } from '@angular/core';
import { Http } from '@angular/http';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStaticLoader } from 'ng2-translate/ng2-translate';

@NgModule({
    imports: [TranslateModule],
    exports: [TranslateModule],
})
export class LanguageModule {

    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: LanguageModule,
            providers: [{
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [Http],
            },
                TranslateService],
        };
    }
}

export function translateLoader(http: Http) {
    return new TranslateStaticLoader(http, '../../assets/i18n', '.json');
}
