<form [formGroup]="forgotPasswordActivationForm" novalidate fxFlex (ngSubmit)="validateForgotPasswprdActivation(forgotPasswordActivationForm.value, forgotPasswordActivationForm.valid)" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div class="account-info">
    <div fxLayout="column" class="info-form-wrapper">
      <div fxLayout="row">
        <div fxFlex>
          <h5 class="main-title">{{'ForgotPassword' | translate }}</h5>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="30" fxFlex.xs="100" class="form-mat-group position-relative">
          <mcn-input placeholder="{{'RegEmail' | translate }}" controlName="emailId" formGroup="forgotPasswordActivationForm" (OnKeyup)="emailKeyPress()"></mcn-input>
                    <mcn-error-messages [control]="this.forgotPasswordActivationForm.controls.emailId" customErrorMsg="{{'Pleaseenteravalidemailaddress' | translate }}"></mcn-error-messages>
          <div class="mat-input-error mat-error" *ngIf="usersError">
            {{'useralready' | translate }} <strong>{{'Registered' | translate }}</strong>
          </div>
        </div>
        <div fxFlex="30" fxFlex.xs="100" class="form-mat-group position-relative">
          <mcn-textarea placeholder="{{'Token' |translate}}" controlName="token" formGroup="forgotPasswordActivationForm"></mcn-textarea>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="30" fxFlex.xs="100" fxFlexOrder.xs="1" class="form-mat-group">
          <mcn-input placeholder="{{'Password' | translate }} " #newPassword controlName="newPassword" type="password" formGroup="forgotPasswordActivationForm" class="astric-star"></mcn-input>
                    <mcn-error-messages [control]="this.forgotPasswordActivationForm.controls.newPassword" customErrorMsg="{{'Passwordis' | translate }}"></mcn-error-messages>
        </div>
        <div fxFlex="5" fxFlexOrder.xs="2" fxLayoutAlign="start center" class="pull-strength">
          <div class="strength" fxLayout="row">
            <small fxLayoutAlign="start center">{{'Strength' | translate }}:</small>
            <ul class="strengthBar">
              <li class="point" [style.background-color]="bar0"></li>
              <li class="point" [style.background-color]="bar1"></li>
              <li class="point" [style.background-color]="bar2"></li>
              <li class="point" [style.background-color]="bar3"></li>
              <li class="point" [style.background-color]="bar4"></li>
            </ul>
          </div>
        </div>
        <span fxFlex="5" fxHide.xs="true"></span>
        <div fxFlex="30" fxFlexOrder.xs="3" class="form-mat-group position-relative">
          <mcn-input placeholder="{{'ConfirmPassword' | translate }} " #confirmPassword controlName="confirmPassword" type="password" formGroup="forgotPasswordActivationForm" (OnKeypress)="cnfrmPasskey($event.target.value)"></mcn-input>
                    <mcn-error-messages [control]="this.forgotPasswordActivationForm.controls.confirmPassword" customErrorMsg="{{'confirmPasswordis' | translate }}"></mcn-error-messages>
        </div>
      </div>
      <div fxLayout="row">
        <p class="password-policy-note" fxLayoutAlign="start center">
          {{'RegNote' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="form-footer px-5 button-container" fxLayout="row" fxLayoutAlign="end end">
    <mcn-button cssClass="btn-custom-primary" type="submit" text="{{'Submit' | translate }}"></mcn-button>
    <mcn-button cssClass="btn-custom-secondary" type="button" text="{{'Cancel' | translate }}" (OnClick)="cancel()"></mcn-button>
  </div>
</form>
