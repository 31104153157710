<div [formGroup]="formGroup">
        <mat-radio-group formControlName="{{controlName}}" [disabled]="disabled">
                <mat-radio-button *ngFor="let option of optionsData;
                let i=index" id="radio_{{i}}{{optionalId}}_{{controlName | removeSpace}}"
                [matTooltip]="option[optionSelectedValue]"
                      (click)="_onChange($event,option[optionKey])" style="margin:5px" value="{{option[optionKey]}}"
                      [ngClass]="{'side-bar-event-enable': !this.disabled ,'side-bar-event-disable': this.disabled }">
                     <a *ngIf="isIcons" href="javascript:void(0)" fxLayout="row" fxLayoutAlign="center center">
                          <i class="cursor-pointer {{option[optionValue]}}"></i>
                     </a>
                     <span *ngIf="!isIcons">{{option[optionValue] | translate }}</span>
                </mat-radio-button>
        </mat-radio-group>
    </div>