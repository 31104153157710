<div fxLayout="column" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxLayout="column wrap">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxLayout="column wrap" fxFlex="100" fxLayoutAlign="center center">
        <h2 class="main-title mb-0"><span>{{'DutyRate' | translate}}</span></h2>
        <i class="star-heading-ul"></i>
      </div>
     <!-- <div fxLayout="column wrap" fxFlex="10">
        <span fxFlex="10" class="status-tag mt-3">{{this.tariffDutyRateDetails?.stateName | translate}}</span>
      </div> -->
    </div>
  </div>
  <div fxLayout="column">
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-2">
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
          <h6 class="preview-content">{{tariffDutyRateDetails?.dutyRateId}}</h6>
          <label class="preview-label">{{'DutyRateId' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
          <h6 class="preview-content">{{tariffDutyRateDetails?.dutyTypeData?.name}}</h6>
          <label class="preview-label">{{'DutyType' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
          <h6 class="preview-content">{{tariffDutyRateDetails?.dutyRateBasisData?.name}}</h6>
          <label class="preview-label">{{'DutyRateBasis' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.inQuotaRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.inQuotaRate}}</h6>
          <label class="preview-label">{{'InQuotaRate' | translate }}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.outQuotaRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.outQuotaRate}}</h6>
          <label class="preview-label">{{'OutQuotaRate' |  translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.adValDutyRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.adValDutyRate}}</h6>
          <label class="preview-label">{{'AdValoremDutyRate' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.specificDutyRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.specificDutyRate}}</h6>
          <label class="preview-label">{{'SpecificDutyRate' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.adValMinRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.adValMinRate}}</h6>
          <label class="preview-label">{{'AdValMinRate' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.adValMaxRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.adValMaxRate}}</h6>
          <label class="preview-label">{{'AdValMaxRate' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.yesRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.yesRate}}</h6>
          <label class="preview-label">{{'YesRate' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.noRate != null">
          <h6 class="preview-content">{{tariffDutyRateDetails.noRate}}</h6>
          <label class="preview-label">{{'NoRate' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2" *ngIf="tariffDutyRateDetails.quantityUnitData?.unitName!= null">
          <h6 class="preview-content">{{tariffDutyRateDetails.quantityUnitData.unitName}}</h6>
          <label class="preview-label">{{'UOM' | translate}}</label>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
          <h6 class="preview-content">{{tariffDutyRateDetails.activatedDate | date}}</h6>
          <label class="preview-label">{{'ActivatedDate' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>
