import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
// import { ValuationSharedService } from '../../services/sharedServices/ValuationSharedServices';
import { ModuleNames } from '../../../../common/Constants';
import { StorageService } from '../../../../storageService/storageService';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
// import { TypeCodeConstants } from '../../constant/TariffValuationConstant'
import { McnTypesModel } from '../../../../mdm/models/McnTypesModel';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';


@Component({
  selector: 'opm-compliance-reports',
  templateUrl: './OpmComplianceReportsComponent.html',
})

export class OpmComplianceReportsComponent implements OnInit {
  public opmReportsFrom: FormGroup;
  public modified: boolean;
  public newFrequecy: boolean;
  public reportName: string;

  constructor(private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogRef: MatDialogRef<OpmComplianceReportsComponent>,
    private dialog: MatDialog,
    public webStorage: StorageService,
    public appConfig: McnConfig,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
  ) {
  }

  ngOnInit() {
    this.reportName = this.riskIdentificationAndAnalysisSharedService.sharingData.opmEditReportName;
    this.opmReportsFrom = this.fb.group({
      fromDate: [''],
      toDate: [''],
      customsStation: [''],
    });
  }
}
