
import { Injectable } from '@angular/core';
import { ServiceDocument } from '@agility/frameworkcore';

export interface MyData {
  invoiceId: number;
  declarationId: number;
  invoiceNumber: number;
  editStatus: boolean;
  consigneeId: number;
  declarationTypeId: number;
  billOfLadingId: number;
  status: string;
  declarationPermitId: number;
  customStationId?: number;
  declarationCategory: number;
  referenceDeclarationId: number;
  declarationBillTypeId: number;
  declarationBillTypeCode: string;
  functionalUnitId: number;
  invoiceAmount: number;
  expression: string;
  aliasName: string;
  drCustomStationId?: number;
  passengerAssessmentId: number;
  declarationNumber: string;
  declarationDate: any;
  drDeclarationId: number;
  drDeclarationState: string;
  identityNumber: string;
  transactionType: string;
  stationCode: string;
  declaratinOwnerCSId: number;
  passengerAsstOwnerCSId: number;
  functionalUnitOwnerCSId: number;
  ownerCSId: number;
  refSubDeclarationId: number;
  isAssoInvoiceExecute: boolean;
  declarationStatus: string;
  isShowCreateDeclaration: boolean;
  functionalUnitState: string;
  invoiceCurrencyRate: number;
  declarationType: string;
  isdeclarationDocumentAvaible: boolean;
  isDrDeclarationDocumentAvaible: boolean;
  isPADocumentAvailable: boolean;
  declarationStatusDetails: any;
  declarationSourceCode: any;
  declarationTransactionTypeCode: any;
  declarationCancellationId: number;
  declarationTransactionType: any;
  paStatus: string;
  valueDeclarationId: number;
  modeOfTransport: number;
  functionalUnitQuantity: number;
  licenseInfoId: number;
  isSelectedWarehouseForms: boolean;
  modeofTransportName: any;
  journeyId: number;
  sCDeclarationId: number;
  transportModeforSCD: number;
}
@Injectable()
export class DeclarationSharedService {
  sharingData: MyData = {
    invoiceId: 0, declarationId: 0, modeOfTransport: 0, declarationCancellationId: 0, invoiceNumber: 0, editStatus: false,
    drDeclarationState: null, consigneeId: 0, declarationTypeId: 0, billOfLadingId: 0, status: null, declarationPermitId: 0,
    customStationId: 0, declarationCategory: 0, referenceDeclarationId: 0, declarationBillTypeId: 0,
    functionalUnitId: 0, invoiceAmount: 0, aliasName: '', expression: '', passengerAssessmentId: 0,
    declarationNumber: '', declarationDate: null, drDeclarationId: 0, identityNumber: null, transactionType: '',
    stationCode: '', declaratinOwnerCSId: 0, passengerAsstOwnerCSId: 0, functionalUnitOwnerCSId: 0,
    invoiceCurrencyRate: 0, ownerCSId: 0, refSubDeclarationId: 0, isAssoInvoiceExecute: false, declarationStatus: '',
    drCustomStationId: 0, isShowCreateDeclaration: true, declarationBillTypeCode: '', functionalUnitState: '',
    declarationType: null, isdeclarationDocumentAvaible: null, isDrDeclarationDocumentAvaible: null,
    declarationStatusDetails: null, isPADocumentAvailable: null, declarationSourceCode: '', declarationTransactionTypeCode: '',
    declarationTransactionType: '', paStatus: '', valueDeclarationId: 0, functionalUnitQuantity: 0, licenseInfoId: 0,
    isSelectedWarehouseForms:false, modeofTransportName: '', journeyId: 0 , sCDeclarationId: 0, transportModeforSCD: 0
  };

  public isDeclarationAssociation: boolean;
  constructor() {
  }

  public bindOwnerCSId = (serviceDocument: ServiceDocument<any>, sharedValue: any): ServiceDocument<any> => {
    serviceDocument.dataProfile.profileForm.controls.ownerCSId.setValue(sharedValue);
    return serviceDocument;
  }
}

export enum DeclarationConstants {
  DeclarationTypeImport = 1,
  DeclarationTypeExport = 2,
  Import = 'Import',
  Export = 'Export',
  Air = 2692,
  Sea = 2693,
  Land = 2694,
  Rail = 2696,
  TrainIcon = 'declare-train-icon',
  PlainIcon = 'declare-plane-icon',
  ShipIcon = 'declare-ship-icon',
  TruckIcon = 'declare-truck-icon'
}
