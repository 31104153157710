import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { ToasterService } from '../toastrService/toasterService';
import { CONFIGURATION } from '../mcnConfig/McnConfig';
import { environment } from '@env/environment';

@Injectable()
export class SignalRService {
  public hubConnection: HubConnection;

  public constructor(
    private toastrService: ToasterService,
  ) { }

  public startConnection(): void {
    this.hubConnection = HubConnectionBuilder.prototype.withUrl(
      environment.appSettings.apiEndpoints.gatewayBaseUri + '/' + CONFIGURATION.Routes.Notification).build();
    this.hubConnection.start().then((resp) => {
    })
      .catch((err) => {
      });
  }

  public stopConnection() {
    if (this.hubConnection != null) {
      this.hubConnection.stop().then(() => {
        // EMPTY
      })
        .catch((err) => {
          this.toastrService.Error('Error while establishing connection');
        });
    }
  }

  public ShowServerNotification(userId: any): void {
    this.hubConnection.on('RecieveData_' + userId, (value: string) => {
      if (value != null && value !== undefined) {
        alert(value);
      }
    });
  }

  public SendMessageToServer(message: string, userId: number): void {
    this.hubConnection.invoke('SendMessage', message, userId);
  }
}
