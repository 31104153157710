import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-quick-links-modal',
    templateUrl: './QuickLinksModalComponent.html',
})
export class QuickLinksModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<QuickLinksModalComponent>) {
    }

    // Quick links Dialog close
    public closeQuickLinkDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
    }
}

