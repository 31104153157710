import { Component, OnInit, ChangeDetectorRef, DoCheck } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle } from '@ng-idle/core';
import { LoginService } from '../account/login/LoginService';
import { StorageService } from '../storageService/storageService';
import { environment } from '@env/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from 'ng2-translate';
@Component({
  selector: 'idleSession-modal',
  template: `<div fxLayout="column">
        <div mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="stretch stretch">
                <div class="session-warn-container" fxLayout="column" fxLayoutAlign="stretch center">
                    <h5 class="font-semi-bold mb-1">
                        {{ this.title | translate}}
                    </h5>
                </div>
                <div class="session-message-container" fxLayout="column" fxLayoutAlign="center center">
                    <i class="session-expire-icon mt-3"></i>
                    <p class ='session-warn-message mt-3' fxLayout ='row' fxLayoutAlign ='center center'>{{this.headingMsg | translate}}</p>
                    <p style ='font-size: 37px;line-height: 12px; margin: 10px 0px 19px 0px;' *ngIf="!this.istimeOut"> {{this.countdown}} seconds! </p>
                    <p class='session-warn-message mb-0'>{{this.message1 | translate}} </p>
                    <p class='session-warn-message pb-2'> {{this.message2 | translate}}</p>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="mb-2">
            <div fxFlex="100" fxFlex.xs="100" class="" fxLayout="row" fxLayout.xs="column"
                 fxLayoutAlign="center" fxLayoutAlign.xs="start stretch">
                <button type="button" class="btn-custom-primary mr-0 mr-mat-3" (click)="continue($event)" *ngIf="!this.istimeOut">{{'Continue' | translate}}</button>
                <button type="button" mat-raised-button class="btn-custom-secondary mt-3 mt-mat-0" (click)="logout($event)" *ngIf="!this.istimeOut">{{'LogOff' | translate}}</button>
                <button type="button" class="btn-custom-primary mr-0 mr-mat-3" (click)="ok($event)" *ngIf="this.istimeOut">{{'Ok' | translate}}</button>
            </div>
        </div>
    </div>`,
})
export class IdleSessionModalComponent implements OnInit, DoCheck {
  public title = 'SessionTimeout';
  public headingMsg = 'Yoursessionisgoingtoexpirein';
  public countdown: number;
  public message1 = 'PleaseclickContinuetokeepWorking';
  public message2 = 'orclickLogOfftoendyoursesssionnow';
  public istimeOut = false;
  public keepalive = false;
  private subscription: any;
  constructor(public dialogRef: MatDialogRef<IdleSessionModalComponent>,
    private cdRef: ChangeDetectorRef,
    private keepalives: Keepalive,
    private accountService: LoginService,
    private storageService: StorageService,
    private oauthService: OAuthService,
    private translate: TranslateService,
    private router: Router,
    private idle: Idle) {
  }

  ngOnInit(): void {
    if (this.countdown > 0) {
      const $obs = interval(1000);
      this.subscription = $obs.subscribe(() => {
        this.countdown = this.countdown - 1;
        if (this.countdown <= 0) {
          this.logout(null);
        }
      });
    }
  }

  public continue(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.keepalive = true;
    this.dialogRef.close();
  }

  public logout(event: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.keepalive = false;
    this.dialogRef.close();
    this.router.navigate(['Account/Login']);
    this.clearData();
  }

  public ok(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  ngDoCheck(): void {
    if (!this.istimeOut) {
      if (this.countdown > 0) {
        // this.countdown = this.countdown - 1;
      } else {
        this.clearData();
      }
    }
    this.cdRef.detectChanges();
  }
  private clearData() {
    this.timeoutWarinig();
    this.accountService.logout().subscribe(() => {
      this.idle.stop();
      this.keepalives.stop();
      window.clearTimeout();
      if (environment.appSettings.keys.isMicroService) {
        this.accountService.logoutIdp(this.oauthService.getAccessToken()).subscribe((res) => {
        });
        this.oauthService.logOut();
      }
    });
    this.storageService.removeAll();
    this.storageService.set('isSession', true);
    this.dialogRef.close();
    this.router.navigate(['Account/Login']);
  }
  public timeoutWarinig() {
    this.istimeOut = true;
    this.title = 'SessionExpired';
    this.headingMsg = 'Yoursessionhasexpired';
    this.message1 = 'Youwillberedirectedtotheloginpage';
    this.message2 = '';
    this.countdown = 0;
  }
}

