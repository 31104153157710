import { Injectable } from '@angular/core';
import { GlobalSplCharforSplit, ModuleNames } from './Constants';
import { MatDialog } from '@angular/material';
import { CommonRemarksHistoryService } from '../commonComponents/remarksHistory/CommonRemarksHistoryService';
import { CommonRemarksHistoryComponent } from '../commonComponents/remarksHistory/CommonRemarksHistoryComponent';
import { McnConfig } from '../mcnConfig/McnConfig';
import { BwhConfig } from '../mcnConfig/BwhConfig';
import { PcaConfig } from '../mcnConfig/PcaConfig';
import { LicenseConfig } from '../mcnConfig/LicenseConfig';
import { ToasterService } from '../toastrService/toasterService';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RulingMessages } from '../tariffRulings/constant/RulingsConstant';
import { RmsConfig } from '../mcnConfig/RmsConfig';
import { DfsConfig } from '../mcnConfig/DfsConfig';
import { FreeZoneConfig } from '../mcnConfig/FreeZoneConfig';
import { ServiceReceiptConfig } from '../mcnConfig/ServiceReceiptConfig';
import { SezConfig } from '../mcnConfig/SezConfig';
export interface MyData {
  sourceTypeId: number;
  referenceId: number;
  suretyTransactionDetailsId: number;
  suretyTranNo: number;
  declarationId: number;
  sampleRequestTransactionAmount: any;
  paymentId: any;
  suretyRefNumber: any;
  suretyAmount: any;
  ownerCSId: any;
  sampleRequestId: any;
  sampleRequestNumber: any;
  suretyTransactionId: any;
  referenceFrom: any;
  suretyTransactionCount: any;
  sourceTypeCode: any;
  assRefId: any;
  sampleRequestStateName: string;
  invoiceId: number;
  inspectionRequetNo: number;
  invoiceItemId: number;
  personalId: number;
  declarationType: string;
}
@Injectable()
export class CommonSharedService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private commonRemarksHistoryApiUrl: any;
  sharingData: MyData = {
    sourceTypeId: 0, referenceId: 0, suretyTransactionDetailsId: 0, suretyTranNo: 0, declarationId: 0,
    sampleRequestTransactionAmount: 0, paymentId: 0, suretyRefNumber: null,
    suretyAmount: 0, ownerCSId: 0, sampleRequestId: 0, sampleRequestNumber: null,
    suretyTransactionId: 0, referenceFrom: null, suretyTransactionCount: 0, sourceTypeCode: null,
    assRefId: 0, sampleRequestStateName: null, invoiceId: 0, inspectionRequetNo: 0, invoiceItemId: 0,
    personalId: 0, declarationType:null
  };

  constructor(
    private commonRemarksHisService: CommonRemarksHistoryService,
    private dialog: MatDialog,
    private appConfig: McnConfig,
    private bwhConfig: BwhConfig,
    private pcaConfig: PcaConfig,
    private licenseConfig: LicenseConfig,
    private http: HttpClient,
    private toastrService: ToasterService,
    private rmsConfig: RmsConfig,
    private dfsConfig: DfsConfig,
    private fzConfig: FreeZoneConfig,
    private sezConfig: SezConfig,
    private serviceReceiptConfig: ServiceReceiptConfig,

  ) {
  }

  private handleError() {
    return this.toastrService.Error(RulingMessages.InterlServerError);
  }

  public isNotNullOrUndefined(val) {
    if (val !== '' && val != null && val !== undefined) {
      return true;
    }
    return false;
  }
  public prepareExpression(query: string, expression: string) {
    if (!this.isNotNullOrUndefined(expression)) {
      expression = query;
    } else {
      expression = expression + GlobalSplCharforSplit.Char1 + query;
    }
    return expression;
  }

  public getYearMonthDate(date: Date): string {
    const dateString = new Date(date).toISOString();
    const toDate = new Date(dateString);
    return toDate.getUTCFullYear() + ',' + (toDate.getUTCMonth() + 1) + ',' + toDate.getUTCDate();
  }

  public convertToUTCDate(date: Date): string {
    // return new Date(date.getTime() + (date.getTimezoneOffset() * 60000)).toDateString();
    const dateString = new Date(date).toDateString();
    const toDate = new Date(dateString);
    return toDate.getUTCFullYear() + ',' + (toDate.getUTCMonth() + 1) + ',' + toDate.getDate();
  }

  public getYearMonthDateTime(date: Date): string {
    const dateString = new Date(date).toISOString();
    const toDate = new Date(dateString);
    return toDate.getUTCFullYear() + ',' + (toDate.getUTCMonth() + 1) + ',' +
      toDate.getUTCDate() + ',' + toDate.getUTCHours() + ',' + toDate.getUTCMinutes() + ',' + toDate.getUTCSeconds();
  }

  public generateCommonRemarksHistoryUrl(referenceId: any, profileName: any, moduleName: any) {
    switch (moduleName) {
      case ModuleNames.Cargo:
        this.commonRemarksHistoryApiUrl = this.appConfig.getCargoRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Declaration:
        this.commonRemarksHistoryApiUrl = this.appConfig.getDeclarationRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Inspection:
        this.commonRemarksHistoryApiUrl = this.appConfig.getInspectionRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Assessment:
        this.commonRemarksHistoryApiUrl = this.appConfig.getAssessmentRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Payment:
        this.commonRemarksHistoryApiUrl = this.appConfig.getPaymentRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Release:
        this.commonRemarksHistoryApiUrl = this.appConfig.getReleaseRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Tariffquota:
        this.commonRemarksHistoryApiUrl = this.appConfig.getQuotaRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Refunds:
        this.commonRemarksHistoryApiUrl = this.appConfig.getRefundsRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.TariffValuation:
        this.commonRemarksHistoryApiUrl = this.appConfig.getTariffValuationRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.TariffExemption:
        this.commonRemarksHistoryApiUrl = this.appConfig.getExemptionRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.VesselShipClearance:
        this.commonRemarksHistoryApiUrl = this.appConfig.getVesselRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Drawback:
        this.commonRemarksHistoryApiUrl = this.appConfig.getDrawbackFacilityApprovalHistoryData +
          '?id=' + referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Surety:
        this.commonRemarksHistoryApiUrl = this.appConfig.getSuretyRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Accounting:
        this.commonRemarksHistoryApiUrl = this.appConfig.getAccountingRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.TariffRuling:
        this.commonRemarksHistoryApiUrl = this.appConfig.getTariffRulingRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Tariffmngt:
        this.commonRemarksHistoryApiUrl = this.appConfig.getTariffMngtRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Permit:
        this.commonRemarksHistoryApiUrl = this.appConfig.getPermitRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Bwh:
        this.commonRemarksHistoryApiUrl = this.bwhConfig.getWHRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Pca:
        this.commonRemarksHistoryApiUrl = this.pcaConfig.getSCRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.License:
        this.commonRemarksHistoryApiUrl = this.licenseConfig.getRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Rms:
        this.commonRemarksHistoryApiUrl = this.rmsConfig.getRiskProfileRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Dfs:
        this.commonRemarksHistoryApiUrl = this.dfsConfig.getDfsRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.FreeZone:
        this.commonRemarksHistoryApiUrl = this.fzConfig.getFzRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.ResourcePlanning:
        this.commonRemarksHistoryApiUrl = this.appConfig.getRPRemarksHistoryData + '?id=' +
          referenceId.toString() + '&dataProfile=' + profileName.toString();
        break;
      case ModuleNames.ServiceReceipt:
        this.commonRemarksHistoryApiUrl = this.serviceReceiptConfig.getServiceReceiptRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      case ModuleNames.Sez:
        this.commonRemarksHistoryApiUrl = this.sezConfig.getWHRemarksHistoryData + '?id=' +
          referenceId.toString() + ' &dataProfile=' + profileName.toString();
        break;
      default:
        this.commonRemarksHistoryApiUrl = null;
        break;
    }
  }

  public getCommonRemarksHistoryData(referenceId: any, profileName: any, moduleName: any) {
    this.generateCommonRemarksHistoryUrl(referenceId, profileName, moduleName);
    if (this.commonRemarksHistoryApiUrl !== null && this.commonRemarksHistoryApiUrl !== undefined) {
      this.commonRemarksHisService.getCommonRemarkshHistoryData(this.commonRemarksHistoryApiUrl).subscribe(
        (response) => {
          const dialogRef = this.dialog.open(CommonRemarksHistoryComponent, {
            panelClass: 'declaration-status-modal',
          });
          dialogRef.componentInstance.commonRemarksHistoryData = response;
        });
    }
  }
  public displayAlertMessages(actionName: string, profileName: string) {
    const message = profileName + actionName.replace(/\s/g, '');
    this.toastrService.Success(this.appConfig.getHardCodeMSg(message), null);
  }

  public getDecReferenceData(): Observable<any> {
    if (this.sharingData.declarationId > 0) {
      return this.http.get(this.appConfig.declarationReferenceData + '?declarationId='
        + this.sharingData.declarationId, { headers: this.headers })
        .pipe(map((response) => response), catchError((res) =>
          throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
    }
  }

  public getProfileOnPersonalId(): Observable<any> {
    if (this.sharingData.personalId > 0) {
      return this.http.get(this.appConfig.getProfileOnPersonalId + '?personalId='
        + this.sharingData.personalId, { headers: this.headers })
        .pipe(map((response) => response), catchError((res) =>
          throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
    }
  }

}
