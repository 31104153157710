<div class="chart-container pt-5">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
      <div fxFlex="100" fxFlex.xs="100" class="my-3" *ngIf="this.isHeaderFlagChart">
        <div class="card-grey-border chart-card" style="min-height: 300px">
          <h4 class="main-title mb-4">{{this.headerBarChartLabel}}</h4>
          <span style="float:right" *ngIf="this.isHeaderFlagChart">
            <span class="legend-label-color" style="background-color: rgb(90, 164, 84);"></span>
            <span class="legend-label-text">{{this.label1}}</span><br>
            <span class="legend-label-color" style="background-color: rgb(161, 10, 40);"></span>
            <span class="legend-label-text">{{this.label}}</span>
          </span>
          <ngx-charts-bar-vertical
            [view]="view"
            [scheme]="colorScheme"
            [customColors]="customColors"
            [results]="this.optionsBarChartData"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]=false
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showDataLabel]=false
            [animations]=true
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>
