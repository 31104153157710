import { FrameworkCoreModule } from '@agility/frameworkcore';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MatToolbarModule, MatTableModule } from '@angular/material';
import { ExpansionPanelsModule } from 'ng2-expansion-panels';
import { Ng2FilterPipeModule } from 'ng2-filter-pipe';
import { AccordionModule } from 'ngx-accordion/index';
import { TranslateModule } from 'ng2-translate';
import { ChartModule } from 'angular2-highcharts';


import { DashboardComponent } from './DashboardComponent';
import { DashboardRoutingModule } from './DashboardRoutingModule';
import { InboxTabsComponent } from './inbox-tabs/inbox-tabs.component';
import { DashboardSubheaderComponent } from './dashboardSubheader/dashboardSubheaderComponent';
import { McnControlsModule } from '../mcnControls/McnControlsModule';
import { MsgConfigService } from './messagingSys/services/MsgConfigService';
import { MsgConfigListResolver, MsgConfigNewResolver, MsgConfigOpenResolver } from './messagingSys/services/MsgConfigResolver';
import { InboxService } from './inbox-tabs/services/InboxService';
import { InboxListResolver } from './inbox-tabs/services/InboxResolver';
import { TrackingDashboradService } from './trackingDashborad/TrackingDashboradService';
import { UserDashboardService } from './userDashboard/userDashboardService';
import { ManifestBalancingService } from './manifestBalancing/ManifestBalancingService';
import { StringToDatePipe } from '../common/StringToDatePipe';
import { ReplaceToRefNumberPipe } from '../common/ReplaceToRefNumberPipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManifestBalancingListResolver, ManifestBalancingViewResolver } from './manifestBalancing/ManifestBalancingResolver';
@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    FrameworkCoreModule,
    CdkTableModule,
    DashboardRoutingModule,
    AccordionModule,
    MatTableModule,
    MatToolbarModule,
    Ng2FilterPipeModule,
    ExpansionPanelsModule,
    TranslateModule.forRoot(),
    McnControlsModule,
    ChartModule,
    InfiniteScrollModule    
  ],
  declarations: [InboxTabsComponent, DashboardComponent, DashboardSubheaderComponent, StringToDatePipe, ReplaceToRefNumberPipe],
  providers: [MsgConfigService, MsgConfigListResolver, MsgConfigNewResolver, MsgConfigOpenResolver,
    InboxService, InboxListResolver, TrackingDashboradService, UserDashboardService, ManifestBalancingService, ManifestBalancingListResolver, ManifestBalancingViewResolver],
  
  exports: [
    FrameworkCoreModule,
    CdkTableModule,
    CommonModule,
    StringToDatePipe,
    ReplaceToRefNumberPipe
]
})
export class DashboardModule {
}
