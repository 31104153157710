<div class="container mt-3 nopl-pr" fxLayout="column" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90" class="riskFreq-flex">
        <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="overflow-hidden">
            <div class="common-status-border">
                <div class="filter-tab-rgt-section">
                    <div class="filter-container singleicon-filter-container">
                        <div class="new-filter-menu box-shadow-card" [ngClass]="{'open': filterMenuOpened}">
                            <div class="filters-heading" fxLayout="row" fxLayoutAlign="space-between center">
                                <h4>{{'SortFilter' | translate}}</h4>
                                <div class="button-grp">
                                    <a href="javascript:void(0)" mat-raised-button
                                        class="btn-custom-primary-filter mr-2"
                                        (click)="searchNVDBCommodityLstFilter()">{{'SEARCH' | translate}}</a>
                                    <a href="javascript:void(0)" mat-raised-button class="btn-custom-secondary-filter"
                                        (click)="resetNVDBCommodityLstFilter()">{{'RESET'|translate}}</a>
                                </div>
                            </div>
                            <div class="filter-forms border-bottom" *ngIf="filterMenuOpened">
                                <h5>{{'SortBy' | translate }}</h5>
                                <div fxLayout="row wrap" fxLayoutAlign="start">
                                    <div class="sorting-lable" fxLayoutAlign="start center"
                                        [ngClass]="{'sortactive': this.selectedIndex === 2 }">
                                        <span class="ml-2 mr-2">{{'ItemHSCode'| translate}}</span>
                                        <i class="icon-sort-asc" title="{{'Ascending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,2,false)"
                                            [ngClass]="{'asc-active': (this.selectedIndex === 2 && this.selectedAsc === true)}"></i>
                                        <i class="icon-sort-dec" title="{{'Descending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,2,true)"
                                            [ngClass]="{'dec-active': (this.selectedIndex === 2 && this.selectedDec === true)}"></i>
                                    </div>
                                    <div class="sorting-lable" fxLayoutAlign="start center"
                                        [ngClass]="{'sortactive': this.selectedIndex === 3 }">
                                        <span class="ml-2 mr-2">{{'UnitPrice'| translate}}</span>
                                        <i class="icon-sort-asc" title="{{'Ascending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,3,false)"
                                            [ngClass]="{'asc-active': (this.selectedIndex === 3 && this.selectedAsc === true)}"></i>
                                        <i class="icon-sort-dec" title="{{'Descending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,3,true)"
                                            [ngClass]="{'dec-active': (this.selectedIndex === 3 && this.selectedDec === true)}"></i>
                                    </div>
                                    <div class="sorting-lable" fxLayoutAlign="start center"
                                        [ngClass]="{'sortactive': this.selectedIndex === 4 }">
                                        <span class="ml-2 mr-2">{{'CountryOfOrigin'| translate}}</span>
                                        <i class="icon-sort-asc" title="{{'Ascending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,4,false)"
                                            [ngClass]="{'asc-active': (this.selectedIndex === 4 && this.selectedAsc === true)}"></i>
                                        <i class="icon-sort-dec" title="{{'Descending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,4,true)"
                                            [ngClass]="{'dec-active': (this.selectedIndex === 4 && this.selectedDec === true)}"></i>
                                    </div>
                                    <div class="sorting-lable" fxLayoutAlign="start center"
                                        [ngClass]="{'sortactive': this.selectedIndex === 5 }">
                                        <span class="ml-2 mr-2">{{'ValuationMethod'| translate}}</span>
                                        <i class="icon-sort-asc" title="{{'Ascending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,5,false)"
                                            [ngClass]="{'asc-active': (this.selectedIndex === 5 && this.selectedAsc === true)}"></i>
                                        <i class="icon-sort-dec" title="{{'Descending' | translate }}"
                                            (click)="NVDBCommodityListSort($event,5,true)"
                                            [ngClass]="{'dec-active': (this.selectedIndex === 5 && this.selectedDec === true)}"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-forms">
                                <form [formGroup]="nvdbCommodityFltrForm" novalidate>
                                    <div fxLayout="row wrap">
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-input [formGroup]="nvdbCommodityFltrForm" controlName="tariffItemId"
                                                placeholder="{{'ItemHSCodes' | translate}}">
                                            </mcn-input>
                                        </div>
                                        <div fxFlex="2"></div>
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-input [formGroup]="nvdbCommodityFltrForm" controlName="unitPrice"
                                                placeholder="{{'UnitPrice' | translate}}">
                                            </mcn-input>
                                        </div>
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-input [formGroup]="nvdbCommodityFltrForm" controlName="countryId"
                                                placeholder="{{'CountryOfOrigin' | translate}}">
                                            </mcn-input>
                                        </div>
                                    </div>
                                    <div fxLayout="row wrap">
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-select optionKey="rmsTypeId" optionValue="name"
                                                [formGroup]="nvdbCommodityFltrForm" controlName="valuationMethodId"
                                                [apiUrl]="this.rmsConfig.getRmsTypes"
                                                placeholder="{{'ValuationMethod' | translate}}" class="fx-selectBox"
                                                optionParameterKey="typeName"
                                                [optionParameterValue]="this.valuationMethod"
                                                [moduleName]="this.moduleNameRms">
                                            </mcn-select>
                                        </div>
                                        <div fxFlex="2"></div>
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-select [formGroup]="nvdbCommodityFltrForm" optionKey="states"
                                                optionValue="states" controlName="status" [isList]="true"
                                                [apiUrl]="this.rmsConfig.nvdbCommodityWFStatesApiUrl"
                                                optionParameterKey="dataProfile"
                                                [optionParameterValue]="this.nvdbCommodityDataProfile"
                                                placeholder="{{ 'Status' | translate }}" class="fx-selectBox">
                                            </mcn-select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="risk-indicator-arrows">
                    <span class="risk-indicator-arrows-p">
                        <i class="download-all-data"></i>
                    </span>
                    <span>
                        <i class="upload-arrow-icon"></i>
                        <!-- <i class="upload-arrow-blue-icon" (click)="UploadRiskRegister()" *ngIf="arrowColor"></i> -->
                    </span>
                    <span>
                        <span class="risk-indicator-arrows-p">
                            <i class="download-arrow-icon"></i>
                        </span>
                    </span>
                </div>
                <div>
                    <mat-card class="p-3 ml-3 my-3 user-individual-card user-individual-card-mr"
                        *ngFor="let details of nvdbCommodityList;trackBy:trackById" [ngClass]="[details?.workflowInstance?.stateName == 'Created' || details?.workflowInstance?.stateName == 'Modified' ||
                                          details?.workflowInstance?.stateName == 'Submitted' || details?.workflowInstance?.stateName == 'Returned For Correction' ? 'commonCreated'
                                          :details?.workflowInstance?.stateName == 'Activated' ? 'activated'
                                          :details?.workflowInstance?.stateName == 'Deactivated' ? 'deactivated'
                                          : '']">
                        <div fxLayout="column wrap" class="ml-5">
                            <div fxLayout="row">
                                <div fxFlex="15" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">
                                        {{details?.tariffItemDetails?.tariffCode}}</h6>
                                    <p class="preview-label">{{ 'ItemHSCodes' | translate }}</p>
                                </div>
                                <div fxFlex="15" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">{{details.unitPrice}}</h6>
                                    <p class="preview-label">{{ 'UnitPrice' | translate }}</p>
                                </div>
                                <div fxFlex="20" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">{{details?.countryDetails?.countryName}}
                                    </h6>
                                    <p class="preview-label">{{ 'CountryOfOrigin' | translate }}</p>
                                </div>
                                <div fxFlex="30" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">{{details?.rmsTypes?.name}}</h6>
                                    <p class="preview-label">{{ 'ValuationMethod' | translate }}</p>
                                </div>
                                <div fxFlex="15" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">
                                        <span class="status-tag created">{{details.workflowInstance.stateName |translate}}</span>
                                    </h6>
                                    <p class="preview-label">{{ 'Status' | translate }}</p>
                                </div>
                                <div fxFlex="5" class="mt-2">
                                    <ul fxLayout="row">
                                        <mcn-edit-icon
                                            [allowedActions]="this.nvdbCommodityServiceDocument?.dataProfile?.actionService?.allowedActions"
                                            (OnClick)="EditNVDBCommodity(details.nvdbMarketComId)"
                                            [workflowStateId]="details?.workflowInstance?.workflowStateId">
                                        </mcn-edit-icon>
                                        <li class="delete">
                                            <mcn-delete-icon fxFlex="100" class="mr-2"
                                                [allowedActions]="this.nvdbCommodityServiceDocument?.dataProfile?.actionService?.allowedActions"
                                                (OnClick)="deleteNVDBCommodity(details)"
                                                [workflowStateId]="details?.workflowInstance?.workflowStateId">
                                            </mcn-delete-icon>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <mcn-pagination [totalCount]="this.pageCount" (OnChange)="getNextNVDBCommodityList($event)">
                    </mcn-pagination>
                </div>
            </div>
        </div>
    </div>
</div>