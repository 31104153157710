<div class="mrg-10" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxFlex="100">
    <mat-tab-group class="inbox-tab-group">
      <mat-tab label="{{'WHOTHETRADERIS'| translate}}">
        <div fxFlex="100" class="card-grey-border p-0" fxLayout="column">
          <div class="ocean-bg pl-2" fxLayout="row wrap">
            <i class="icon-list-view"></i>
            <h5 class="mt-0 ml-2">{{'WHOTHETRADERIS'| translate}}</h5>
          </div>
          <div fxFlex="100">
            <div fxFlex="100" fxLayout="row wrap" class="p-3">
              <div fxFlex="35">
                <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.name}}</h6>
                <p class="preview-label">{{'Name'| translate}}</p>
              </div>
              <div fxFlex="25">
                <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.sector}}</h6>
                <p class="preview-label">{{'Sector'| translate}}</p>
              </div>

              <div fxFlex="20">
                <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.category}}</h6>
                <p class="preview-label">{{'Category'| translate}}</p>
              </div>
              <div fxFlex="20">
                <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.legalStatus}}</h6>
                <p class="preview-label">{{'LegalStatus'| translate}}</p>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="100">
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'OWNERSDIRECTORSCOMPANYSECRETARY'| translate}}</h5>
            </div>
            <div fxFlex="100">
              <div fxFlex="100" fxLayout="row wrap" class="p-3">
                <div fxFlex="33">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.ownerName}}</h6>
                  <p class="preview-label">{{'OwnerName'| translate}}</p>
                </div>
                <div fxFlex="33">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.directorName}}</h6>
                  <p class="preview-label">{{'DirectorName'| translate}}</p>
                </div>
                <div fxFlex="33">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.secretaryName}}</h6>
                  <p class="preview-label">{{'SecretaryName'| translate}}</p>
                </div>
              </div>
            </div>
          </div>
          <span fxFlex="4">&nbsp;</span>
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'CONTACTINFORMATIONHEADOFFICE'| translate}}</h5>
            </div>
            <div fxFlex="100">
              <div fxFlex="100" fxLayout="row wrap" class="p-3">
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.telephone}}</h6>
                  <p class="preview-label">{{'Telephone'| translate}}</p>
                </div>
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.faxNumber}}</h6>
                  <p class="preview-label">{{'Fax'| translate}}</p>
                </div>
              </div>

            </div>
            <div fxFlex="100">
              <div fxFlex="100" fxLayout="row wrap" class="p-3">
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.email}}</h6>
                  <p class="preview-label">{{'Email'| translate}}</p>
                </div>
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold"
                    title="NO.28, 3RD FLOOR LORONG MEDAN TUANKU SATU 41100, Selangor">
                    {{_whoTheTraderIs?.address1}} <br>
                    {{_whoTheTraderIs?.address2}} <br>
                    {{_whoTheTraderIs?.address3}}
                  </h6>
                  <p class="preview-label">{{'Address'| translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'Size'| translate}}</h5>
            </div>
            <div fxFlex="100">
              <div fxFlex="100" fxLayout="row wrap" class="p-3">
                <div fxFlex="33">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.size}}</h6>
                  <p class="preview-label">{{'SizeRMMill'| translate}}</p>
                </div>
                <div fxFlex="33">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.capacity}}</h6>
                  <p class="preview-label">{{'EmployeesCount'| translate}}</p>
                </div>

                <div fxFlex="33">
                  <h6 class="preview-content font-semi-bold">{{_whoTheTraderIs?.employeesCount}}</h6>
                  <p class="preview-label">{{'CapacityTonsyear'| translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'LINEOFBUSINESS'| translate}}">
        <div fxFlex="100">
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'Imports'| translate}}</h5>
            </div>
            <div fxFlex="100">

              <h4 class="main-title mb-0 p-2 font-wnormal">{{'TrendsInTopFiveImportCommodities'| translate}}
                <!-- <select (change)="setImportExportBar($event.target.value, 'import')">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5" selected>5</option>
              </select> -->
              </h4>
              <!-- bar chart -->
              <div id="bar_chart" [chartData]="this._importsBarChart" [chartOptions]="bar_ChartOptions"
                chartType="BarChart" GoogleChart></div>
              <!-- <div id="bar_chart"></div> -->
            </div>
          </div>
          <span fxFlex="4">&nbsp;</span>
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'Exports'| translate}}</h5>
            </div>
            <div fxFlex="100">
              <h4 class="main-title mb-0 p-2 font-wnormal">{{'TrendsInTopFiveExportCommodities'| translate}}
                <!-- <select (change)="setImportExportBar($event.target.value, 'export')">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5" selected>5</option>
                </select> -->
              </h4>
              <!-- bar chart -->
              <div id="bar_chart2" [chartData]="this._exportsBarChart" [chartOptions]="bar_ChartOptions"
                chartType="BarChart" GoogleChart></div>
              <!-- <div id="bar_chart2"></div> -->
            </div>
          </div>
        </div>
        <div fxFlex="100">
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'IMPORTSOVERTHEPERIOD'| translate}}</h5>
            </div>
            <div fxFlex="100">
              <!-- line chart -->
              <div id="line_chart" [chartData]="this._importsLineChartData" [chartOptions]="line_ChartOptions"
                chartType="LineChart" GoogleChart></div>
            </div>
          </div>
          <span fxFlex="4">&nbsp;</span>
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'EXPORTSOVERTHEPERIOD'| translate}}</h5>
            </div>
            <div fxFlex="100">
              <!-- line chart -->
              <div id="line_chart2" [chartData]="this._exportsLineChartData" [chartOptions]="line_ChartOptions"
                chartType="LineChart" GoogleChart></div>
            </div>
          </div>
        </div>

        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'KEYSUPPLIERSANDKEYBUYERS'| translate}}</h5>
            </div>
            <span fxFlex="4">&nbsp;</span>
            <div class="ocean-bg pl-2 ml-3 mr-3" fxLayout="row wrap">
              <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center center">
                <h5 class="mt-0 ml-2">{{'KeySuppliers'| translate}}</h5>
              </div>
              <div fxFlex="50" fxFlex.xs="100" fxLayoutAlign="center center">
                <h5 class="mt-0 ml-2">{{'KeyBuyers'| translate}}</h5>
              </div>
            </div>
            <div fxFlex="100">
              <div fxFlex="100" class="card-grey-border mt-0 p-0 ml-3 mr-3 mb-3">
                <mat-table #table [dataSource]="keySuppDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="Origin">
                    <mat-header-cell *cdkHeaderCellDef>{{'Origin' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Origin' | translate }}</span>
                      <span>{{row.OriginCountry}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="SupplierName">
                    <mat-header-cell *cdkHeaderCellDef>{{'SupplierName' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'SupplierName' | translate }}</span>
                      <span>{{row.Supplier}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="keySuppDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: keySuppDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
              <span fxFlex="4">&nbsp;</span>
              <div fxFlex="100" class="card-grey-border mt-0 p-0 ml-3 mr-3 mb-3">
                <mat-table #table [dataSource]="keyBuyerDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="Destination">
                    <mat-header-cell *cdkHeaderCellDef>{{'Destination' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Destination' | translate }}</span>
                      <span>{{row?.DestinationCountry}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="BuyerName">
                    <mat-header-cell *cdkHeaderCellDef>{{'BuyerName' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'BuyerName' | translate }}</span>
                      <span>{{row?.BuyerName}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="keyBuyerDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: keyBuyerDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'LOCALPURCHASES' | translate }}</h5>
            </div>
            <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
              <div fxFlex="100">
                <mat-table #table [dataSource]="localPurchesDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="Commodity">
                    <mat-header-cell *cdkHeaderCellDef>{{'Commodity' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Commodity' | translate }}</span>
                      <span>{{row?.itemName}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Value">
                    <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                      <span>{{row?.valueOfCommodity}}</span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Suppliers">
                    <mat-header-cell *cdkHeaderCellDef>{{'Suppliers' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Suppliers' | translate }}</span>
                      <span>{{row?.supplierName}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="localPurchesDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: localPurchesDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'COSTOFEXEMPTIONUTILIZED' | translate }}</h5>
            </div>
            <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
              <div fxFlex="100">
                <mat-table #table [dataSource]="exemptionDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="ExemptionId">
                    <mat-header-cell *cdkHeaderCellDef>{{'ExemptionId' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'ExemptionId' | translate }}</span>
                      <span>{{row?.exemptionId}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="CostofExemption">
                    <mat-header-cell *cdkHeaderCellDef>{{'CostofExemptionRMMill' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'CostofExemptionRMMill' | translate }}</span>
                      <span>{{row?.costOfExemption}}</span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Commodity">
                    <mat-header-cell *cdkHeaderCellDef>{{'Commodity' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Commodity' | translate }}</span>
                      <span>{{row?.commodityName}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="exemptionDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: exemptionDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'QUOTASLPCOSBYCBRAs' | translate }}</h5>
            </div>
            <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
              <div fxFlex="100">
                <mat-table #table [dataSource]="quotasDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="ReferenceName">
                    <mat-header-cell *cdkHeaderCellDef>{{'ReferenceName' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'ReferenceName' | translate }}</span>
                      <span>{{row?.quotaSchemeName}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Cost">
                    <mat-header-cell *cdkHeaderCellDef>{{'CostRMMill' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'CostRMMill' | translate }}</span>
                      <span>{{row?.valueOrQty}}</span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Validity">
                    <mat-header-cell *cdkHeaderCellDef>{{'Validity' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Validity' | translate }}</span>
                      <span>{{row?.status |translate}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="quotasDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: quotasDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="{{'BUSINESSVOLUME' | translate }}">
        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'FREQUENCY' | translate }}</h5>
            </div>
            <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
              <div fxFlex="100">
                <mat-table #table [dataSource]="businessVolumeDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="CPC">
                    <mat-header-cell *cdkHeaderCellDef>{{'CPC' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'CPC' | translate }}</span>
                      <span>{{row.CPCName}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Transaction">
                    <mat-header-cell *cdkHeaderCellDef>{{'TransactionsYear' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'TransactionsYear' | translate }}</span>
                      <span>{{row.TransactionCount}}</span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="Value">
                    <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                      <span>{{row.Value}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="businessVolumeDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: businessVolumeDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="100">
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'SECURITIESWITHCUSTOMS' | translate }}</h5>
            </div>
            <div fxFlex="100">
              <div fxFlex="100" fxLayout="row wrap" class="p-3">
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold">{{_businessVolumeSec}}</h6>
                  <p class="preview-label">{{'AmountRMMill' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
          <span fxFlex="4">&nbsp;</span>
          <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'REPAYMENTSREFUNDSANDDUTYDRAWBACKS' | translate }}</h5>
            </div>
            <div fxFlex="100">
              <div fxFlex="100" fxLayout="row wrap" class="p-3">
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold">{{_businessVolumeRepay.Frequency}}</h6>
                  <p class="preview-label">{{'Frequency' | translate }}</p>
                </div>
                <div fxFlex="50">
                  <h6 class="preview-content font-semi-bold">{{_businessVolumeRepay.Amount}}</h6>
                  <p class="preview-label">{{'AmountRMMill' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'ComplianceHistory' | translate }}">
        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'Frequency' | translate }}</h5>
            </div>
            <div fxFlex="100" fxLayout="column">
              <div fxFlex="100" class="p-0 mt-1 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Auditedduringlast3years' | translate }}
                </div>
                <div fxFlex="25" class="ocean-bg">
                  {{'Frequency'|translate}} : {{_complianceHistory?.auditFrequency}}
                </div>
                <div fxFlex="25" class="ocean-bg">
                  {{'Last audited on'|translate}} : {{_complianceHistory?.lastAuditDate | date}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'ComplianceratingbyPCAorunrated' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.complianceRatingPCA}} {{'(Note : A = Satisfactory, B = Unsatisfactory, C = Unrated)'|translate}}
}}                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'AEOStatusallowedsimplifiedproceduresetc' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.aeoStatus | translate}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Anyinvestigationorauditreferralspending' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.investigationAuditPending}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'InternalAuditsystem' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.internalAudit}}
                </div>
              </div>
              <div fxFlex="100" class="p-0">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'ThirdpartyAuditsystem' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.thirdPartyAudit}}
                </div>
              </div>
              <div fxFlex="100" class="p-0">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Financialcontrolsaccountingsystem' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.accountingSys}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <span fxFlex="4">&nbsp;</span>
        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'VIOLATIONS' | translate }}</h5>
            </div>
            <div fxFlex="100" fxLayout="column">
              <div fxFlex="100" class="p-0 mt-1 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'HScodeschanges' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.hsCodeViolation}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Declaredvaluesnotaccepted' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.decValueViolation}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Quantityweightdiscrepancies' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.qtyWtViolation}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Informationnotprovidedontime' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.informationViolation}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'Securitiesexpired' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.expiredSecurites}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'FinespenaltiespaidRMMill' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.penalties}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'RecoveriesdueunpaidyetRMMill' | translate }}
                </div>
                <div fxFlex="50" class="ocean-bg">
                  {{_complianceHistory?.recoveriesDue}}
                </div>
              </div>
              <div fxFlex="100" class="p-0 mb-1">
                <div fxFlex="50" class="gray-bg p-2" fxLayoutAlign="start center">
                  {{'AdditionaldutiespaidasresultofreassessmentsbyCustoms' | translate }}
                </div>
                <div fxFlex="25" class="ocean-bg">
                  {{'NumberofInstances' | translate }} : {{_complianceHistory?.additionalDutiesCount}}
                </div>
                <div fxFlex="25" class="ocean-bg">
                  {{'AmountRMMill' | translate }} : {{_complianceHistory?.additionalDuties}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2"> {{'GOODSCONFISCATED' | translate }}</h5>
            </div>
            <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
              <div fxFlex="100">
                <mat-table #table [dataSource]="goodsConfiscatedDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="HSCode">
                    <mat-header-cell *cdkHeaderCellDef>{{'HSCodeCaption' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'HSCodeCaption' | translate }}</span>
                      <span>{{row?.hsCode}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Value">
                    <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                      <span>{{row?.value}}</span>
                    </mat-cell>
                  </ng-container>
                  <ng-container cdkColumnDef="No.">
                    <mat-header-cell *cdkHeaderCellDef>{{'NumberofInstances' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'NumberofInstances' | translate }}</span>
                      <span>{{row?.numberOfInstances}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="goodsConfiscatedDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: goodsConfiscatedDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'ASSOCIATEDPARTIES' | translate }}">
        <div fxFlex="100">
          <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
            <div class="ocean-bg pl-2" fxLayout="row wrap">
              <i class="icon-list-view"></i>
              <h5 class="mt-0 ml-2">{{'ASSOCIATEDPARTIES' | translate }}</h5>
            </div>
            <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
              <div fxFlex="50">
                <mat-table #table [dataSource]="assPartiesDataSource" class="profile-360-table">
                  <ng-container cdkColumnDef="Name">
                    <mat-header-cell *cdkHeaderCellDef>
                      {{'Thirdpartiesassociatedagentscarrierswarehouses' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span
                        class="mobile-cont-text">{{'Thirdpartiesassociatedagentscarrierswarehouses' | translate }}</span>
                      <span>{{row?.partyName}}</span>
                    </mat-cell>
                  </ng-container>
                  <!--
                  <ng-container cdkColumnDef="Director">
                    <mat-header-cell *cdkHeaderCellDef>{{'Common directors / owners with' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Common directors / owners with' | translate }}</span>
                      <span>{{row.owners}}</span>
                    </mat-cell>
                  </ng-container> -->
                  <mat-header-row *cdkHeaderRowDef="assPartiesDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: assPartiesDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
              <div fxFlex="50">
                <mat-table #table [dataSource]="assPartiesDataSource" class="profile-360-table">
                  <!-- <ng-container cdkColumnDef="Name">
                    <mat-header-cell *cdkHeaderCellDef>
                      {{'Third parties - associated (agents, carriers, warehouses)' | translate }}</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span
                        class="mobile-cont-text">{{'Third parties - associated (agents, carriers, warehouses)' | translate }}</span>
                      <span>{{row.partyName}}</span>
                    </mat-cell>
                  </ng-container> -->

                  <ng-container cdkColumnDef="Director">
                    <mat-header-cell *cdkHeaderCellDef>{{'Commondirectorsownerswith' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">
                      <span class="mobile-cont-text">{{'Commondirectorsownerswith' | translate }}</span>
                      <span>{{row?.owners}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="directorsDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: directorsDisplayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
