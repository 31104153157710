import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'mcn-select',
  template: `<div [formGroup]="formGroup">
       <mat-form-field *ngIf="!iconList" class="mat-input-container" floatLabel="auto">
          <mat-select placeholder="{{placeholder}}" id="ddl_{{optionalId}}{{placeholder |
            removeSpace}}" formControlName="{{controlName}}" (selectionChange)="onOptionSelect($event.value)">
            <mat-option *ngFor="let optionObject of optionsData"
            [value]="optionObject[optionKey]" matTooltip=" {{optionObject[optionValue]}}">
                {{optionObject[optionValue]}}
            </mat-option>
          </mat-select>
       </mat-form-field>
       <mat-form-field *ngIf="iconList">
          <mat-select placeholder="{{placeholder}}" id="ddl_{{optionalId}}{{placeholder |
            removeSpace}}" formControlName="{{controlName}}" (selectionChange)="onOptionSelect($event.value)">
            <mat-option *ngFor="let optionObject of optionsData" [value]="optionObject[optionKey]" matTooltip="
             {{optionObject[optionValue]}}">
                <i class="{{optionObject[iconClass]}}"></i> - {{optionObject[optionValue]}}
            </mat-option>
          </mat-select>
       </mat-form-field>
    </div>`,
})

export class McnSelectComponent {
  @Input() public formGroup: FormGroup;
  @Input() public controlName: string;
  @Input() public required: boolean;
  @Input() public placeholder: string;

  // @Input() isCascading: boolean;
  @Input() public optionsData: any[] = [];
  @Input() public optionKey: any;
  @Input() optionalId = '';
  @Input() public isList = false;
  @Input() public isInbox = false;
  @Input() public isCascading = false;
  @Input() public optionValue: any;
  @Input() public optionCode: any = null;
  @Input() public optionParameterKey: any = null;
  @Input() public optionParameterValue: any = null;
  @Input() public containKeys: any[] = [];
  @Input() public iconClass: any;
  @Input() public iconList = false;
  @Input() public cssClass: string;
  @Input() public apiUrl: string;
  @Input() public disabled: boolean;
  @Input() public moduleName = '';
  @Output() public OnChange = new EventEmitter();
  @Input() public selectedValue: string;
  @Input() optionalParameterArray: any[] = [];

  public userData: string[];
  public isDataFromApiOnValueChange: boolean;
  public inputData: any;
  public infoObj: any = {};
  private control: AbstractControl;

  constructor(
    private ref: ChangeDetectorRef,
    private http: HttpClient,
  ) { }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.bindSelectInfo();
    const self: McnSelectComponent = this;
    this.control = this.formGroup.controls[this.controlName];
    if (this.control) {
      if (this.disabled && this.disabled.toString().toLowerCase() === 'true') {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
    if (self.apiUrl !== undefined && self.apiUrl != null && self.apiUrl !== '') {
      self.isDataFromApiOnValueChange = true;
    }
    if (self.isDataFromApiOnValueChange) {
      self.setApiToSelect();
    } else {
      self.optionsData = this.optionsData;
      this.bindSelectedValue(self);
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(): void {
    const self: McnSelectComponent = this;
    if (this.controlName !== undefined) {
      this.control = this.formGroup.controls[this.controlName];
      if (this.control) {
        if (!this.disabled) {
          this.control.enable();
        }
        if (this.disabled) {
          this.control.disable();
        }
      }

      if (this.selectedValue && this.optionsData !== undefined && this.optionsData.length > 0) {
        const option = this.optionCode != null ? this.optionCode : this.optionValue;
        const response = this.optionsData.find(x => x[option] === this.selectedValue);
        if (response !== undefined) {
          this.formGroup.controls[this.controlName].setValue(response[this.optionKey]);
        } else {
          this.formGroup.controls[this.controlName].setValue(null);
        }
        self.OnChange.emit({ event: event, options: response });
      }
      if (!isNullOrUndefined(this.optionParameterKey) && !isNullOrUndefined(this.optionParameterValue) && !isNullOrUndefined(self.apiUrl) && self.apiUrl !== '' && this.isCascading) {
        self.setApiToSelect();
      }
      if (!isNullOrUndefined(this.control.value) && this.control.value !== '') {
        const response = this.optionsData.find(x => x[this.optionKey] === this.control.value);
        self.OnChange.emit({ event: event, options: response });
      }
      this.bindSelectInfo();
    }
  }

  private setApiToSelect() {
    const self: McnSelectComponent = this;
    this.getDataFromAPI().subscribe((res) => {
      this.setOptionData(res);
      this.bindSelectedValue(self);
      if (this.control.value) {
        const result = this.optionsData.find((x) => x[this.optionKey] === this.control.value);
        self.OnChange.emit({ event, options: result });
      }
      this.ref.detectChanges();
    });
  }

  private bindSelectedValue(self: McnSelectComponent) {
    if (this.containKeys.length > 0) {
      for (let i = 0; i < this.containKeys.length; i++) {
        const index = this.optionsData.findIndex((x) => x[this.optionKey] === this.containKeys[i]);
        if (index !== -1) {
          this.optionsData.splice(index, 1);
        }
      }
    }
    if (this.selectedValue) {
      const option = this.optionCode != null ? this.optionCode : this.optionValue;
      const response = this.optionsData.find(x => x[option] === this.selectedValue);
      this.formGroup.controls[this.controlName].setValue(response[this.optionKey]);
      self.OnChange.emit({ event, options: response });
    }
  }

  private getDataFromAPI(): Observable<any> {
    let url = null;
    const api = this.apiUrl.split('?');
    if (this.optionalParameterArray.length > 0) {
      url = this.apiUrl;
      this.optionalParameterArray.forEach((res) => {
        const key = Object.keys(res);
        key.forEach((k) => {
          url += '&' + k + '=' + res[k];
        });
      });
    } else {
      if (this.moduleName !== '' && !isNullOrUndefined(this.moduleName) && !isNullOrUndefined(this.moduleName)) {
        url = api[0] + '?moduleName=' + this.moduleName;
        if (!isNullOrUndefined(this.optionParameterKey) && !isNullOrUndefined(this.optionParameterValue)) {
          url += '&&' + this.optionParameterKey + '=' + this.optionParameterValue;
        }
      } else if (!isNullOrUndefined(this.optionParameterKey) && !isNullOrUndefined(this.optionParameterValue)) {
        url = api[0] + '?' + this.optionParameterKey + '=' + this.optionParameterValue;
      } else {
        url = api[0];
      }
    }
    return this.http.get(url);
  }

  private onOptionSelect(key: any) {
    const self: McnSelectComponent = this;
    if (key != null && key !== '-- Select --') {
      if (key) {
        const option: any = this.optionsData.find((x) => x[this.optionKey] === key);
        self.OnChange.emit({ event, options: option });
      }
    } else {
      self.OnChange.emit({ event, options: this.infoObj });
      this.formGroup.controls[this.controlName].setValue(null);
    }
  }

  private bindSelectInfo() {
    this.infoObj[this.optionKey] = null;
    this.infoObj[this.optionValue] = '-- Select --';
    this.infoObj['typeCode'] = null;
    if (isNullOrUndefined(this.optionsData)) {
      this.optionsData = [];
    }
    if (this.optionsData.length > 0) {
      const index = this.optionsData.findIndex(x => x[this.optionValue] === '-- Select --');
      if (index === -1) {
        this.optionsData.splice(0, 0, this.infoObj);
      }
    } else {
      this.optionsData.splice(0, 0, this.infoObj);
    }
  }

  private setOptionData(res: any) {
    if (!this.isList) {
      const i = 0;
      this.userData = Object.keys(res);
      if (this.optionsData.length > 1) {
        this.optionsData = [];
        this.bindSelectInfo();
      }
      this.optionsData = this.optionsData.concat(res[this.userData[i]]);
    } else {
      if (this.optionsData.length > 1) {
        this.optionsData = [];
        this.bindSelectInfo();
      }
      this.optionsData = this.optionsData.concat(res);
    }
  }
}
