/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { StorageService } from '../storageService/storageService';


@Injectable()
export class ServiceReceiptConfig implements OnInit {

  // Service
  listService: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Service/ListService';
  newServiceRequest: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Service/NewServiceRequest';
  openService: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Service/OpenService';
  submitService: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Service/SubmitService';
  deleteService: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Service/DeleteService';
  getServiceNumbersApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/Service/GetServiceNumbers?filterValue=';

  // Service Price
  listServicePrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServicePrice/ListServicePrice';
  newServicePrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServicePrice/NewServicePriceRequest';
  openServicePrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServicePrice/OpenServicePrice';
  submitServicePrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServicePrice/SubmitServicePrice';
  deleteServicePrice: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServicePrice/DeleteServicePrice';
  getServicePriceNumbersApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServicePrice/GetServicePriceNumbers?filterValue=';

  // Service Receipts
  listServiceReceipt: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/ListServiceReceipt';
  newServiceReceipt: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/NewServiceReceipt';
  openServiceReceipt: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/OpenServiceReceipt';
  submitServiceReceipt: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/SubmitServiceReceipt';
  deleteServiceReceipt: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/DeleteServiceReceipt';
  getServiceReceiptRemarksHistoryData: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/GetServiceReceiptRemarksHistoryData';

  // Service Receipt Details
  newServiceReceiptDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/NewServiceReceiptDetails';
  openServiceReceiptDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/OpenServiceReceiptDetails';
  submitServiceReceiptDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/SubmitServiceReceiptDetails';
  deleteServiceReceiptDetails: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/DeleteServiceReceiptDetails';
  getServiceReferenceNumbersApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/GetReferenceNumbersForReceipts?filterValue=';
  getReferenceNumbersForReceiptsApiUrl: any = environment.appSettings.apiEndpoints.declarationApiBaseUrl + '/api/ServiceReceipt/GetReferenceNumbersForReceipts?filterValue=';


  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }
  ngOnInit() {
  }

}
