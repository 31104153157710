import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
// import { ValuationSharedService } from '../../services/sharedServices/ValuationSharedServices';
import { ModuleNames } from '../../../../common/Constants';
import { StorageService } from '../../../../storageService/storageService';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
// import { TypeCodeConstants } from '../../constant/TariffValuationConstant'
import { McnTypesModel } from '../../../../mdm/models/McnTypesModel';




@Component({
  selector: 'app-market-commodity-upload',
  templateUrl: './MarketResearchCommodityUploadComponent.html',
})

export class MarketResearchCommodityUploadComponent implements OnInit {
  public riskIndicatorUploadFrom: FormGroup;
  public modified: boolean;
  public newFrequecy: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogRef: MatDialogRef<MarketResearchCommodityUploadComponent>,
    private dialog: MatDialog,
    public appConfig: McnConfig,
    public webStorage: StorageService,
  ) {
  }

  ngOnInit() {
    this.riskIndicatorUploadFrom = this.fb.group({
      docType: [''],
      docRefNo: [''],
      expDate: [''],
      fileUpload: [''],
      remarks: [''],
    });
  }
}
