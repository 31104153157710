import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { PasswordManagementService } from '../account/login/passwordManagement/PasswordManagementService';
import { ErrorInterceptor } from '../common/ErrorInterceptor';
import { McnSpinnerInterceptor } from '../common/McnSpinnerInterceptor';
import { FilterService } from '../filter/FilterService';
import { StorageService } from '../storageService/storageService';
import { ValidationPatterns } from '../validations/ValidationPatterns';
import { ValidationService } from '../validations/ValidationService';
import { FilterMenuService } from './filter-menu.service';
import { InjectorService } from './InjectorService';

const webStorageConfig = {
  prefix: 'ng2plus',
};

@NgModule({
  imports: [],
  exports: [],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: McnSpinnerInterceptor,
    multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
})

export class SharedStaticModule {
  static injector: Injector;
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedStaticModule,
      providers: [
        FilterMenuService,
        FilterService,

        // For Common Validations
        ValidationService,
        ValidationPatterns,

        // For Storing in GLobal service
        LocalStorageService,
        SessionStorageService,
        StorageService,
        PasswordManagementService,
      ],
    };
  }
  constructor(private injector: Injector) {
    InjectorService.injector = injector;
  }
}
