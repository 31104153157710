<!--Quick Links Popup Started-->
<div class="container dailog-section"  [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
    <div fxLayout="column" fxFlexFill>
        <div mat-dialog-content fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
                <h1 matDialogTitle>{{'QuickLinks'|translate}}</h1>
            </div>
            <ul class="quick-details" fxFlexFill fxLayout="column" fxLayoutAlign="">
                              
                <li class="quick-links">
                    <a class="links" href="javascript:void(0)" [routerLink]="['/cargo/journey-landing/journey-list-view']" (click)="closeQuickLinkDialog()">
                        {{'JourneyList'|translate}}
                    </a>
                </li>               
                <li class="quick-links">
                    <a class="links" [routerLink]="['/payments/payments-list-view']" href="javascript:void(0)" (click)="closeQuickLinkDialog()">{{'Payments'|translate}}</a>
                </li>
                <li class="quick-links">
                    <a class="links" [routerLink]="['carrier/carrier-landing/carrier-grid-view']" href="javascript:void(0)" (click)="closeQuickLinkDialog()">
                        {{'NewVesselRegistration'|translate}}
                    </a>
                </li>
            </ul>
        </div>
        <div mat-dialog-actions>
          <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
            <a href="javascript:void(0)" class="overlay-close" (click)="closeQuickLinkDialog()">
              <mat-icon class="overlay-menu-remove"></mat-icon>
            </a>
            <mcn-button type="button" text="{{'CUSTOMIZE' | translate}}" cssClass="btn-custom-primary mr-0 mr-sm-2 mb-2 mb-sm-0"
                        (OnClick)="closeQuickLinkDialog()"></mcn-button>
          </div>
        </div>
    </div>
</div>
<!--Quick Links Popup Ended-->
