import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { StorageService } from '../../../../storageService/storageService';

@Component({
  selector: 'app-upld-crew-list',
  templateUrl: './UpldCrewListComponent.html',
})
export class UpldCrewListComponent implements OnInit {
  public upldCrewListDocs: FormGroup;

  constructor(private fb: FormBuilder,
    public storageService: StorageService,
    private dialogRef: MatDialogRef<UpldCrewListComponent>) { }

  ngOnInit() {
    this.upldCrewListDocs = this.fb.group({
      filePath: ['', [<any>Validators.required]],
      file: ['', [<any>Validators.required]],
    });
  }

  public Close() {
    this.dialogRef.close();
  }

  public fileChange(event: any) { }
}