<div [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <mat-toolbar-row fxLayout="row" class="page-action-panel mt27">
    <div class="container subheader-container px-0" fxLayout="row" fxLayoutAlign="space-between center">
      <a href="javascript:void(0)" class="go-back" [routerLink]="goBackLinkTVSubheader"
        *ngIf="riskIdentificationDetails" fxLayout="row" fxLayoutAlign="center center">
        <i class="back-arrow"></i><span class="go-back-span">{{'GoBack' | translate}}</span>
      </a>
      <div fxFlex="auto" class="form-inline pt-1 pr-5">
        <span class="jrny-label">
        {{this.riskFreqSubheaderPageTitle | translate}}
        </span>
      </div>
      <div fxFlex="none" [hidden]="this.riskFreqSubheaderPageTitle == ''">
        <div fxLayout="row" fxLayoutAlign="end center" class="imp-up-btns static-position"
          [class.display-none-custom]="">
          <ul fxLayout="row" fxLayoutAlign="space-around center" class="left-nav dropdown-wrapper">
            <li class="dropdown" [ngClass]="" *ngIf="this.showhideFilterIcon">
              <a fxFlex="column" fxLayoutAlign="center center" class="subheader-navigation" (click)="toggleClassName()"
                matTooltip="{{'Filters'|translate}}" [class.active]="filterMenuService.isFilterOpened">
                <i class="icon-sort-filter"></i>
              </a>
            </li>
            <!-- <a fxFlex="column" fxLayoutAlign="center center" class="subheader-navigation add-icon"
            (click)="createRiskIdentificationAnalysisSubheader($event)" matTooltip="Create">
            <i class="icon-plus"></i>
          </a> -->
            <mcn-new-button *ngIf="this.showhideCreateNewIcon"
              [allowedActions]="this.storageService.get('RiskIdentificationAndAnalysisAllowedActions')"
              tooliptext="{{'Create'| translate}}" (OnClick)="createRiskIdentificationAnalysisSubheader($event)"
              matTooltip="{{'Create'|translate}}">
            </mcn-new-button>

          </ul>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</div>