<div [ngSwitch]="this.dataService.lang">
<div *ngSwitchCase="'en'" class="p-2 position-relative">
  <div fxFlex="100" fxLayout="row wrap">
    <div fxFlex="80">
      <mat-form-field class="full-width chatbotInput mt-2">
        <!--input [disabled]="!this.dataService.user_input_required" #input matInput placeholder="{{'Type your message here'}}"-->
        <input #input matInput placeholder="{{'Type your message here'}}">
      </mat-form-field>
    </div>
    <div fxFlex="20" fxLayoutAlign="start center">
      <img src="../../../assets/images/chatBotImages/ic_send.png" class="askMC-iconPos cursor-pointer" />
    </div>
  </div>
</div>
<div *ngSwitchCase="'ar'" class="p-2 position-relative">
  <div class="arb" fxFlex="100" fxLayout="row wrap">
    <div fxFlex="80">
      <mat-form-field class="full-width chatbotInput mt-2">
        <input [disabled]="!this.dataService.user_input_required" #input matInput placeholder="{{'اكتب رسالتك هنا'}}">
      </mat-form-field>
    </div>
    <div fxFlex="20" fxLayoutAlign="start center">
      <img src="../../../assets/images/chatBotImages/ic_send_Arabic.png" class="askMC-iconPos cursor-pointer" />
    </div>
  </div>
</div>
</div>
