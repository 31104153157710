import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { areaData2017, areaData2018 } from '../data/data';
@Component({
  selector: 'app-area-chart',
  templateUrl: './areaChartComponent.html',
})
export class areaChartComponent implements OnInit {
  @Input() public optionsData: any;
  @Input() public yearData: any;
  @Input() public optionsCurrYearData: any;
  @Input() public optionsPrevYearData: any;

  public areaChartForm: FormGroup;
  public view: any[] = [1125, 400];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public showYAxisLabel = true;
  public legendTitle = false;
  public animations = true;

  colorScheme = {
    domain: ['#c73932', '#459ac9', '#783b76']
  };

  public area_ChartOptions = {
    title: '',
    hAxis: { title: 'Ports', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 }
  };

  ngOnInit() {
    this.areaChartForm = this.fb.group({
      year: ['']
    });
  }


  public onYearSelect(value: any) {
    if (value.options.value === '2020') {
      this.optionsData = this.optionsPrevYearData;
    } else if (value.options.value === '2021') {
      this.optionsData = this.optionsCurrYearData;
    }

  }

  constructor(
    private fb: FormBuilder) {
  }

  public onSelect(event) {

  }
}
