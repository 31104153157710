import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CommodityProfileService } from './CommodityProfileService';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { StorageService } from '../../../../storageService/storageService';

@Injectable()
export class TariffDescriptionResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
        private webStorage: StorageService) {
    }
    public resolve() {
        const hsCode = this.webStorage.get('hsCode');
        if (hsCode != null) {
            this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode = hsCode;
            this.webStorage.remove('hsCode');
        }
        return this.commodityProfileService.tariffDescription(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class RulingsAssociatedResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.rulingsAssociated(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeyTradersImportResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.keyTradersImport(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeyTradersExportResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }

    public resolve() {
        return this.commodityProfileService.keyTradersExport(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeyProducersResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService
    ) { }

    public resolve() {
        return this.commodityProfileService.keyProducers(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeySectorsResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.keySectors(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class AppliedTariffsResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.appliedTariffs(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class LpcoResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.lpco(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class CommodityHsCodeListResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,) {
    }
    public resolve() {
        return this.commodityProfileService.listHsCodes();
    }
}

/////////////////////////////////// Chart Resolvers /////////////////////////////////////////////

@Injectable()
export class ImportTariffRatesResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.importTariffRates(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class ExportTariffRatesResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.exportTariffRates(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class TrendsImportResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }

    public resolve() {
        return this.commodityProfileService.trendsImport(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class TrendsExportResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.trendsExport(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeySourcesResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.keySources(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeyDestinationsResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.keyDestinations(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeyImportProcedureResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.keyImportProcedure(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}

@Injectable()
export class KeyImportProcedureOtherResolver implements Resolve<any> {
    constructor(
        private commodityProfileService: CommodityProfileService,
        private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService) {
    }
    public resolve() {
        return this.commodityProfileService.keyImportProcedureOther(this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode);
    }
}
