import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToasterService } from '../../toastrService/toasterService';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { CommonMessages } from '../../common/Constants';

@Component({
  selector: 'mcn-fileUpload',
  template: `<div class="file-upload ml-2" fxLayout="row" fxLayoutAlign="start center">
                <label class="file-upload-label">{{labelName}} <sup>*</sup></label>
                <div class="custom-upload ml-4 mb-0">
                    <span> {{ actionLabelName }}</span>
                    <input type="file" name="upload" id="file" class="upload-input" (click)="fileBrowse($event)"
                    (change)="fileChange($event)">
                </div>
            </div>`
})

export class McnFileUploadComponent implements OnInit {
  @Output() OnChange = new EventEmitter<any>();
  @Input() labelName: any;
  @Input() actionLabelName: any;
  @Input() formGroup: FormGroup;
  @Input() isExcelUpload: any;
  public docdata: any;
  public base64textString: any;

  constructor(
    private toasterService: ToasterService,
    public appConfig: McnConfig,
  ) { }
  ngOnInit(): void {
  }

  fileChange(event: any) {
    event.preventDefault();
    const fileList: FileList = event.target.files;
    const file = fileList[0];
    const size = file.size;
    const sizeKB = Math.round(size / 1024);
    if (sizeKB <= 10240) {
      if (file) {
        const reader = new FileReader();
        this.docdata = {
          'base64String': this.base64textString,
          'fileName': file.name,
          'size': file.size,
          'fileExtension': file.name.split('.')[1]
        };
        reader.onloadend = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else {
      this.toasterService.Warning(this.appConfig.getHardCodeMSg(CommonMessages.SelectedFilesizeshouldbebelow10MB));
    }
  }

  public fileBrowse(event: any) {
    const _self = this;
    if (!this.isExcelUpload) {
      if (!_self.formGroup.valid) {
        this.toasterService.Warning(this.appConfig.getHardCodeMSg(CommonMessages.PleaseEnterAllRequiredFiledsbeforebrowsethefile));
        event.preventDefault();
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.docdata['base64String'] = this.base64textString;
    const _self = this;
    _self.OnChange.emit(this.docdata);
  }

  private _setAction(event: any) {
    const _self = this;
    _self.OnChange.emit(event);
  }
}
