import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-risk-identification-analysis',
  templateUrl: "./RiskIdentificationAnalysisComponent.html",

})
export class RiskIdentificationAnalysisComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {

  }



}
