<div  [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
<div fxLayout="column" class="dailog-section container ">
  <div mat-dialog-content>
    <form [formGroup]="searchModalInfoForm" novalidate
      (ngSubmit)="validatesearchModalInfoForm(searchModalInfoForm.valid)">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxFlex="100" class="top-search">
          <i fxLayoutAlign="end end" class="icon-white-search search-i" matPrefix></i>
          <mat-form-field floatPlaceholder="never">
            <input matInput placeholder="{{'Search'|translate}}" formControlName="search" [disabled]="toggleValue">
          </mat-form-field>
        </div>
      </div>
      <expansion-panels-container class="custom-expansion-panel-container">
        <expansion-panel class="search-modal-content" (click)="handleToggle()">
          <expansion-panel-title>
            <a class="d-inline-block pr-4 adv-anchor">{{'AdvancedSearch'|translate}} </a>
            <div fxFlex="100" fxFlex.gt-xs="50">
            </div>
          </expansion-panel-title>
          <expansion-panel-content>
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="login-form-group">
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'ConsigneeName'|translate}}" controlName="consigneeName" class="login-input"
                  [formGroup]="searchModalInfoForm"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'DeclarationNo'|translate}}" controlName="declarationNo" class="login-input"
                  [formGroup]="searchModalInfoForm"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'TempDeclarationNo'|translate}}" controlName="tempDeclarationNo" class="login-input"
                  [formGroup]="searchModalInfoForm"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'TrackingID'|translate}}" controlName="trackingID" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>

              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'Custom Station'|translate}}" controlName="customStation" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'PortofShipment'|translate}}" controlName="portOfShipment" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'Country Of Origin'|translate}}" controlName="countryOfOrigin"
                  [formGroup]="searchModalInfoForm" class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'Duty'|translate}}" controlName="duty" [formGroup]="searchModalInfoForm" class="login-input">
                </mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'DutyValue'|translate}}" controlName="dutyValue" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'HSCodeCaption'|translate}}" controlName="hsCode" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'BillofLadingNo'|translate}}" controlName="billOfLadingNo"
                  [formGroup]="searchModalInfoForm" class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'PermitNo'|translate}}" controlName="permitNo" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'ExemptionNo'|translate}}" controlName="exemptionNo" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
              <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <mcn-input placeholder="{{'LicenseNo'|translate}}" controlName="licenseNo" [formGroup]="searchModalInfoForm"
                  class="login-input"></mcn-input>
              </div>
            </div>
          </expansion-panel-content>
        </expansion-panel>
      </expansion-panels-container>
    </form>
  </div>
  <div mat-dialog-actions>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
      <a href="javascript:void(0)" class="overlay-close" (click)="closeSearchDialog()">
        <mat-icon class="overlay-menu-remove"></mat-icon>
      </a>
      <mcn-button type="button" text="{{'Search' | translate}}"
        cssClass="btnbtn-custom-primary-filter mr-0 mr-sm-2 mb-2 mb-sm-0"></mcn-button>
      <mcn-button type="button" text="{{'Reset' | translate}}" cssClass="-custom-secondary-filter ml-0"></mcn-button>
    </div>
  </div>
</div>
</div>
<!--Search Popup Ended-->