/* tslint:disable: max-line-length */
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { CommonSharedService } from '../common/CommonSharedService';
import { CommonMessages } from '../common/Constants';
import { McnConfig } from '../mcnConfig/McnConfig';

@Injectable()
export class ValidationService {
  constructor(private mcnConfig: McnConfig,
    private commonSharedService: CommonSharedService, ) {
  }
  passwordValue: string;
  public NumericAndDecimalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
        if (value > 0) {
          if (/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter Numerical only' };
          }
        } else {
          return { errorMessage: 'Enter Number Greater than 0.' };
        }
      } else {
        return { errorMessage: 'Enter Numerical only.' };
      }
    };
  }

  public AllowNullAndNumeric(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.commonSharedService.isNotNullOrUndefined(control.value)) {
        const value = control.value;

        if (value > 0) {
          if (/^\-{0,1}\d+(.\d+){0,1}$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter Number Greaterthan 0.' };
          }
        } else {
          return { errorMessage: 'Enter Numerical only' };
        }
      }
    };
  }

  public AllowNullAndDateGreateThanCurrentDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (this.commonSharedService.isNotNullOrUndefined(control.value)) {
        let currentDate, enteredDate;
        currentDate = new Date();
        enteredDate = new Date(control.value);
        if (enteredDate > currentDate) {
          return null;
        } else {
          return { errorMessage: 'Date should be greater than Current Date' };
        }
      }
    };
  }

  public NumericsNoZeroOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;

        if (value > 0) {
          if (/^([0-9]{1,100})$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter Numerical only' };
          }
        } else if (value <= 0) {
          return { errorMessage: 'Enter greater than 0 only' };
        } else {
          return { errorMessage: 'Enter Numerical only' };
        }
      } else {
        return { errorMessage: 'Enter Numerical only' };
      }
    };
  }

  public NumericsWithZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
          if (/^([0-9]{1,100})$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter Numerical only' };
          }
        }  else {
          return { errorMessage: 'Enter Numerical only' };
        }
    };
  }


  public NumericsOnetoHundredOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
        if (value > 0) {
          if (/^[1-9]$|^[1-9][0-9]$|^(100)$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter number between 0 and 100 only' };
          }
        } else {
          return { errorMessage: 'Enter number between 0 and 100 only' };
        }
      } else {
        return { errorMessage: 'Enter Numerical only' };
      }
    };
  }

  public NumericsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
        if (value > 0) {
          if (/^([0-9]{1,100})$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter Numerical only' };
          }
        } else {
          return { errorMessage: 'Enter Numerical only' };
        }
      } else {
        return { errorMessage: 'Enter Numerical only' };
      }
    };
  }
  
  public AllowCharectersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {

        if (/^[a-zA-Z ]*$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Enter Characters only' };
        }
      } else {
        return { errorMessage: 'Enter Characters only.' };
      }
    };
  }

  public AllowAlphanumericsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;

        if (/^[a-zA-Z0-9 ]+$/.test(value)) {
          return null;
        } else {
          return {
            errorMessage: 'Enter alphanumeric only'
          };
        }
      } else {
        return { errorMessage: 'Enter alphanumeric only' };
      }
    };
  }

  public ContainerNumberCheckvalidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
        if (/^[A-Z]{4}\d{7}/.test(value)) {
          // var sum = 0;
          // var i = 0;
          // for (i = 0; i < 10; i++) {
          //  var n = value.substr(i, 1);
          //  if (i < 4) {
          //    n = "0123456789A?BCDEFGHIJK?LMNOPQRSTU?VWXYZ".indexOf(value.substr(i, 1));
          //  }
          //  n *= Math.pow(2, i);
          //  sum += n;
          // }
          // if (value.substr(0, 4) == "HLCU") {
          //  sum -= 2;
          // }
          // sum %= 11;
          // sum %= 10;
          // if (sum == value.substr(10)) {
          //  return null;
          // }
          // else {
          //  return {
          //    errorMessage: 'Enter correct container number.'
          //  };
          // }
          return null;
        } else {
          return {
            errorMessage: 'Enter correct container number.'
          };
        }
      } else {
        return { errorMessage: 'Enter alphanumeric only' };
      }
    };
  }

  public AllowCharactersNumericsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;

        if (/^[a-zA-Z0-9]*$/.test(value)) {
          return null;
        } else {
          return {
            errorMessage: 'Enter characters and Numerical only'
          };
        }
      } else {
        return { errorMessage: 'Enter characters and Numerical only' };
      }
    };
  }

  public AllowNumericAndSpecialCharacterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;

        if (/^[0-9.]*$/.test(value)) {
          return null;
        } else {
          return {
            errorMessage: 'Enter Numerical only'
          };
        }
      } else {
        return { errorMessage: 'Enter Numerical only' };
      }
    };
  }

  public AllowSpecialCharectersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {

        if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Enter specialcharecters only' };
        }
      } else {
        return { errorMessage: 'Enter specialcharecters only.' };
      }
    };
  }

  public AllowAllValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {

        if (/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Enter Numeric/Characters/Special Characters.' };
        }
      } else {
        return { errorMessage: 'Enter Numeric/Characters/Special Characters.' };
      }
    };
  }

  public AllowCharectersWithSpecialCharectersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {

        if (/^[ A-Za-z_@./()-]*$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Enter characters/special Characters only' };
        }
      } else {
        return { errorMessage: 'Enter characters/special Characters only' };
      }
    };
  }

  public EmailValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {

        if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Enter Valid Email Address' };
        }
      } else {
        return { errorMessage: 'Enter Valid Email Address' };
      }
    };
  }

  public RomanNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {

        if (/^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Enter Roman Number only' };
        }
      } else {
        return { errorMessage: 'Enter Roman Number only' };
      }
    };
  }

  public DateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        let currentDate, enteredDate;
        currentDate = new Date();
        enteredDate = new Date(control.value);
        if (enteredDate > currentDate) {
          return null;
        } else if (new Date(control.value).getDate() === new Date().getDate() && new Date(control.value).getMonth()
          === new Date().getMonth() && new Date(control.value).getFullYear() === new Date().getFullYear()) {
          return null;
        } else {
          return { errorMessage: 'Date should not be less than Current Date' };
        }
      } else {
        return { errorMessage: 'Date should not be less than Current Date' };
      }
    };
  }
  public DateTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        let currentDateNTime, enteredDateNTime;
        enteredDateNTime = new Date(control.value);
        currentDateNTime = new Date();
        if (enteredDateNTime > currentDateNTime) {
          return null;
        } else if (new Date(control.value).getDate() === new Date().getDate() && new Date(control.value).getMonth()
          === new Date().getMonth() && new Date(control.value).getFullYear() === new Date().getFullYear() &&
          new Date(control.value).getMinutes() > new Date().getMinutes()) {
          return null;
        } else {
          return { errorMessage: 'Date and Time should not be less than Current Date and Time' };
        }
      } else {
        return { errorMessage: 'Date and Time should not be less than Current Date and Time' };
      }
    };
  }


  public DateTimeGreaterValidator(fromGroup: FormGroup, controlName: any, errorMsg: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        let currentDateNTime, enteredDateNTime;
        currentDateNTime = new Date(fromGroup.controls[controlName].value);
        enteredDateNTime = new Date(control.value);
        if (new Date(control.value).getDate() === currentDateNTime.getDate() &&
          new Date(control.value).getMonth() === currentDateNTime.getMonth() &&
          new Date(control.value).getFullYear() === currentDateNTime.getFullYear()) {
          if (new Date(control.value).getMinutes() >= currentDateNTime.getMinutes() && new Date(control.value).getSeconds() >= currentDateNTime.getSeconds()) {
            return null;
          } else {
            return { errorMessage: errorMsg };
          }
        } else if (enteredDateNTime > currentDateNTime) {
          return null;
        } else if (enteredDateNTime <= currentDateNTime) {
          return { errorMessage: errorMsg };
        } else {
          return null;
        }
      } else {
        return { errorMessage: errorMsg };
      }
    };
  }

  public DateGreaterValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        let currentDate, enteredDate;
        currentDate = new Date();
        enteredDate = new Date(control.value);
        if (enteredDate < currentDate) {
          return null;
        } else {
          return { errorMessage: 'Date should not be greater than Current Date' };
        }
      } else {
        return { errorMessage: 'Date should not be greater than Current Date' };
      }
    };
  }

  public DateLessThanOrEqualsCurrentDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        let currentDate, enteredDate;
        currentDate = new Date();
        enteredDate = new Date(control.value);
        if (currentDate >= enteredDate) {
          return null;
        } else {
          return { errorMessage: 'Date should not be greater than Current Date' };
        }
      } else {
        return { errorMessage: 'Date should not be greater than Current Date' };
      }
    };
  }

  public DateGreateThanCurrentDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        let currentDate, enteredDate;
        currentDate = new Date();
        enteredDate = new Date(control.value);
        if (enteredDate > currentDate) {
          return null;
        } else {
          return { errorMessage: 'Date should be greater than Current Date' };
        }
      } else {
        return { errorMessage: 'Date should be greater than Current Date' };
      }
    };
  }

  public StartDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const enteredDate = new Date(control.value).getDate();
        const currentDate = new Date().getDate();
        const enteredMonth = new Date(control.value).getMonth() + 1;
        const currentMonth = new Date().getMonth() + 1;
        const enteredYear = new Date(control.value).getFullYear();
        const currentYear = new Date().getFullYear();
        if (enteredMonth > currentMonth && enteredYear >= currentYear) {
          return null;
        } else if (enteredMonth >= currentMonth && enteredDate >= currentDate && enteredYear >= currentYear) {
          return null;
        } else {
          return { errorMessage: 'Date should not be less than Current Date' };
        }
      } else {
        return { errorMessage: 'Date should not be less than Current Date' };
      }
    };
  }

  public checkDateCondition = (startDate: any, endDate: any, errorMsg: string = null): string => {
    const startdateC = new Date(startDate);
    const endDateC = new Date(endDate);
    if (startdateC <= endDateC) {
      return null;
    } else {
      return errorMsg;
    }
  }

  public CheckDatePreCondition = (startDate: any, errorMsg: any): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const endDate: Date = new Date(control.value);
        startDate = new Date(startDate);
        if (startDate < endDate) {
          return null;
        } else {
          return { errorMessage: errorMsg };
        }
      } else { return null; }
    };
  }

  public CheckDateTimeCondition = (fromGruop: FormGroup, controlName: any): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const startdate: Date = new Date(fromGruop.controls[controlName].value);
        const endDate: Date = new Date(control.value);
        if (startdate < endDate) {
          return null;
        } else if (startdate === endDate) {
          const startTime: Date = new Date(fromGruop.controls[controlName].value);
          const endTime: Date = new Date(control.value);

          if (startTime.getHours() === endTime.getHours()) {
            if (startTime.getMinutes() >= endTime.getMinutes()) {
              return { errorMessage: CommonMessages.EndDateTimeErrorMessage };
            }
          } else if (startTime.getHours() > endTime.getHours()) {
            return { errorMessage: CommonMessages.EndDateTimeErrorMessage };
          }
        } else {
          return { errorMessage: CommonMessages.EndDateTimeErrorMessage };
        }
      } else { return null; }
    };
  }

  public CheckEndDateCondition = (fromGroup: FormGroup, controlName: any): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const startdate: Date = new Date(fromGroup.controls[controlName].value);
        const endDate: Date = new Date(control.value);
        if (startdate <= endDate) {
          return null;
        } else {
          return { errorMessage: CommonMessages.EndDateErrorMessage };
        }
      } else { return null; }
    };
  }

  public CheckEndDateAndCurrentDateCondition = (fromGroup: FormGroup, controlName: any, isExpiry: boolean = false): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const startdate: Date = new Date(fromGroup.controls[controlName].value);
        const endDate: Date = new Date(control.value);
        const currentDate = new Date();
        if (!(endDate > currentDate)) {
          return { errorMessage: isExpiry ? 'Expiry Date should not be greater than Current Date' : 'Date should not be greater than Current Date' };
        } else if (startdate <= endDate) {
          return null;
        } else {
          return { errorMessage: CommonMessages.EndDateErrorMessage };
        }
      } else { return null; }
    };
  }

  public CheckCrossFieldDateAndCurrentDateCondition = (fromGroup: FormGroup, controlName: any, errorMessage: string = ''): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const startdate: Date = new Date(fromGroup.controls[controlName].value);
        const endDate: Date = new Date(control.value);
        const currentDate = new Date();
        if (endDate > currentDate) {
          return { errorMessage: 'Date should not be greater than Current Date' };
        }
        if (startdate <= endDate) {
          return null;
        } else {
          return { errorMessage: errorMessage };
        }
      } else { return null; }
    };
  }

  public PasswordValidation(min: any = 0, max: any = 0): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        this.passwordValue = control.value;

        if (min > 0 && this.passwordValue.length > 0 && this.passwordValue.length < min) {
          return { errorMessage: 'password must be of minimum ' + min + ' characters' };
        } else if (max > 0 && this.passwordValue.length > 0 && this.passwordValue.length > max) {
          return { errorMessage: 'Password is reached maximum length ' };
        } else if (this.passwordValue.length > this.mcnConfig.passwordLength) {
          return { errorMessage: 'Password is reached maximum limit ' };
        } else if (/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,15})$/.test(control.value)) {
          return null;
        } else {
          return { errorMessage: 'Password is not Strong' };
        }
      } else {
        return { errorMessage: 'Password is not Strong' };
      }
    };
  }

  public EndTimeValidator = (fromGruop: FormGroup, controlName: any): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const startTime: Date = new Date(fromGruop.controls[controlName].value);
        const endTime: Date = new Date(control.value);

        if (startTime.getHours() === endTime.getHours()) {
          if (startTime.getMinutes() === endTime.getMinutes()) {
            return { errorMessage: CommonMessages.EndDateTimeErrorMessage };
          } else if (startTime.getMinutes() > endTime.getMinutes()) {
            return { errorMessage: CommonMessages.EndDateTimeErrorMessage };
          }
        } else if (startTime.getHours() > endTime.getHours()) {
          return { errorMessage: CommonMessages.EndTimeErrorMessage };
        }
      } else { return null; }
    };
  }

  public NumericAndQuantityValidator(fromGroup: FormGroup, quantity: any, controls: any[] = [], isAddition: boolean = true,
    isMuliplication: boolean = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
        if (value.match('^[a-zA-Z]*$')) {
          return { errorMessage: 'Enter Numerical only' };
        }
        if (value > 0) {
          if (/^\-{0,1}\d+(.\d+){0,1}$/.test(value)) {

            let totalQuantity = value;
            if (controls.length > 0) {
              totalQuantity = 0;
              controls.forEach(x => {
                if (isAddition) {
                  totalQuantity = totalQuantity + Number(fromGroup.controls[x].value);
                } else if (isMuliplication) {
                  totalQuantity = totalQuantity * Number(fromGroup.controls[x].value);
                }
              });
            }
            if (totalQuantity > quantity) {
              return { errorMessage: 'Quantity is Exceeded' };
            }
            return null;
          } else {
            return { errorMessage: 'Enter Numerical only' };
          }
        } else {
          return { errorMessage: 'Enter Numerical only' };
        }
      } else {
        return { errorMessage: 'Enter Numerical only.' };
      }
    };
  }
  public removeSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value !== null || control.value !== undefined) {
        if (control && control.value && !control.value.replace(/\s/g, '').length) {
          const isWhitespace = (control.value || '').trim().length === 0;
          control.setValue(isWhitespace ? '' : control.value);
          control.setValue(control.value.replace(/\s/g, '').length > 0 ? control.setValue(control.value.trim()) : control.value);
          return null;
        } else {
          return null;
        }
      }
      return null;
    };
  }

  public NotRequeiredNumericAndDecimalValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;
        if (value > 0) {
          if (/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
            return null;
          } else {
            return { errorMessage: 'Enter Numerical only' };
          }
        } else {
          return { errorMessage: 'Enter Number Greater than 0.' };
        }
      }
    };
  }
  public CheckStartDateEndDateCondition = (fromGroup: FormGroup, controlName: any, errorMessage: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const startdate: Date = new Date(fromGroup.controls[controlName].value);
        const endDate: Date = new Date(control.value);
        if (startdate <= endDate) {
          return null;
        } else {
          return { errorMessage: errorMessage };
        }
      } else { return null; }
    };
  }

  public CheckDateShouldNotBeGreaterThanCurrentDateCondition = (errorMessage: string = null): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const endDate: Date = new Date(control.value);
        const currentDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        if (endDate >= currentDate) {
          return { errorMessage: errorMessage != null && errorMessage.length > 0 ? errorMessage : 'Date should not be greater than Current Date' };
        } else {
          return null;
        }
      } else { return null; }
    };
  }

  public AllowNumericsPlusAndMinusValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value != null) {
        const value = control.value;

        if (/^(?!0+$)[0-9 +-]+$/.test(value)) {
          return null;
        } else {
          return {
            errorMessage: 'Enter Numerical only'
          };
        }
      } 
      // else {
      //   return { errorMessage: 'Enter Numerical only' };
      // }
    };
  }

}
