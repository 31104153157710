import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FilterMenuService } from '../../../common/filter-menu.service';
import { NVDBMarketCommodityService } from '../services/nvdbMarketCommodity/NVDBMarketCommodityService';
import { NVDBMarketCommodityModel } from '../models/NVDBMarketCommodityModel';
import { StorageService } from '../../../../app/storageService/storageService';
import { RiskIdentificationAndAnalysisSharedService } from '../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { SafeUnsubscriber } from '../../../../app/common/SafeUnsubscriber';
import { RmsConfig } from '../../../../app/mcnConfig/RmsConfig';
import {
  TypesName, ModuleName, ProfileName, ModelName, Message, SuccessErrMsgs, AlertMessages, AllowedActions, ConstantNumber
} from '../../riskSelectivityProfiling/common/Constants';
import { McnConfig } from '../../../../app/mcnConfig/McnConfig';
import { CommonSharedService } from '../../../../app/common/CommonSharedService';
import { GlobalSplCharforSplit } from '../../../../app/common/Constants';
import { ToasterService } from '../../../../app/toastrService/toasterService';
import { AlertModalService } from '../../../../app/services/AlertModalService';


@Component({
  selector: 'app-market-commodity',
  templateUrl: './NVDBCommodityListComponent.html',

})
export class NVDBCommodityListComponent extends SafeUnsubscriber implements OnInit {
  public filterMenuOpened: boolean;
  public nvdbCommodityFltrForm: FormGroup;
  public researchCommodityForm: FormGroup;
  private nvdbCommodityServiceDocument: any = [];
  public nvdbCommodityList: NVDBMarketCommodityModel[];
  public pageCount: number;
  private nvdbCommodityLstData: any;
  public valuationMethod: string;
  public moduleNameRms: string;
  public nvdbCommodityDataProfile: string;
  public selectedIndex: any = 1;
  public selectedDec = true;
  public selectedAsc = false;
  private nvdbCommodityExpression: any;

  constructor(
    private fb: FormBuilder,
    private filterMenuService: FilterMenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nvdbCommodityService: NVDBMarketCommodityService,
    public webStorage: StorageService,
    public nvdbCommoditySharedService: RiskIdentificationAndAnalysisSharedService,
    public rmsConfig: RmsConfig,
    public mcnConfig: McnConfig,
    private commonSharedService: CommonSharedService,
    private toasterService: ToasterService,
    private ref: ChangeDetectorRef,
    private alertModalService: AlertModalService,
  ) {
    super();
    this.valuationMethod = TypesName.ValuationMethodTypes;
    this.moduleNameRms = ModuleName.Rms;
    this.nvdbCommodityDataProfile = ProfileName.NVDBCommodity;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.nvdbCommodityServiceDocument = this.nvdbCommodityService.nvdbCommodityServiceDocument;
      if (this.nvdbCommodityServiceDocument.dataProfile !== undefined) {
        this.nvdbCommodityList = this.nvdbCommodityServiceDocument.dataProfile.dataList;
      } else {
        this.nvdbCommodityList = [];
      }
      if (this.nvdbCommodityServiceDocument.totalCount !== null) {
        this.pageCount = this.nvdbCommodityServiceDocument.totalCount;
      }
      this.nvdbCommodityLstData = this.nvdbCommodityService.nvdbCommodityServiceDocument;

      this.webStorage.set(AllowedActions.RiskIdentificationAndAnalysisAllowedActions,
        this.nvdbCommodityServiceDocument.dataProfile.actionService.allowedActions);
    });

    this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
      this.filterMenuOpened = isFilterMenuOpened;
    });

    this.nvdbCommodityFltrForm = this.fb.group({
      tariffItemId: [''],
      unitPrice: [''],
      countryId: [''],
      valuationMethodId: [''],
      status: [''],
    });
  }

  public FiltermenuOpen() {
    if (this.filterMenuOpened === undefined || this.filterMenuOpened === false) {
      this.filterMenuOpened = true;
    } else {
      this.filterMenuOpened = false;
    }
  }

  /**
   * Open
   * @param id nvdbMarketComId
   */
  EditNVDBCommodity(id: number): void {
    this.nvdbCommoditySharedService.sharingData.nvdbMarketComId = id;
    this.nvdbCommodityService.openNVDBMarketCommodity().subscribe(() => {
      this.router.navigate(['risk-identification-Analysis/market-research-commodity-details']);
    });
  }
  /**
   * Search Filter
   */
  searchNVDBCommodityLstFilter() {
    this.nvdbCommodityExpression = null;
    let query = '';
    const tariffItemId = this.nvdbCommodityFltrForm.value['tariffItemId'];

    if (this.commonSharedService.isNotNullOrUndefined(tariffItemId)) {
      query = `NVDBMarketCommodityListModel.TariffItemDetails.TariffCode.ToUpper().Contains("${tariffItemId.toUpperCase()}")`;
      this.nvdbCommodityExpression = this.commonSharedService.prepareExpression(query, this.nvdbCommodityExpression);
    }
    if (this.commonSharedService.isNotNullOrUndefined(this.nvdbCommodityFltrForm.value['unitPrice'])) {
      if (this.nvdbCommodityExpression == null) {
        query = 'NVDBMarketCommodityListModel.UnitPrice.Value ==' + Number(this.nvdbCommodityFltrForm.value['unitPrice']);
        this.nvdbCommodityExpression = this.commonSharedService.prepareExpression(query, this.nvdbCommodityExpression);
      } else {
        this.nvdbCommodityExpression = this.nvdbCommodityExpression + GlobalSplCharforSplit.Char1 +
          'NVDBMarketCommodityListModel.UnitPrice.Value == "' + Number(this.nvdbCommodityFltrForm.value['unitPrice']) + '"';
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(this.nvdbCommodityFltrForm.value['countryId'])) {
      if (this.nvdbCommodityExpression == null) {
        query = 'NVDBMarketCommodityListModel.CountryDetails.CountryName.ToUpper().Contains("' +
          this.nvdbCommodityFltrForm.value['countryId'] + '")';
        this.nvdbCommodityExpression = this.commonSharedService.prepareExpression(query, this.nvdbCommodityExpression);
      } else {
        this.nvdbCommodityExpression = this.nvdbCommodityExpression + GlobalSplCharforSplit.Char1 +
          'NVDBMarketCommodityListModel.CountryDetails.CountryName.ToUpper().Contains("' +
          this.nvdbCommodityFltrForm.value['countryId'] + '")';
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(this.nvdbCommodityFltrForm.value['valuationMethodId'])) {
      if (this.nvdbCommodityExpression == null) {
        query = 'NVDBMarketCommodityListModel.ValuationMethodId == ' + this.nvdbCommodityFltrForm.value['valuationMethodId'];
        this.nvdbCommodityExpression = this.commonSharedService.prepareExpression(query, this.nvdbCommodityExpression);
      } else {
        this.nvdbCommodityExpression = this.nvdbCommodityExpression + GlobalSplCharforSplit.Char1 +
          'NVDBMarketCommodityListModel.ValuationMethodId == ' + this.nvdbCommodityFltrForm.value['valuationMethodId'];
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(this.nvdbCommodityFltrForm.value['status'])) {
      const stateData = this.nvdbCommodityFltrForm.value['status'];
      if (this.nvdbCommodityExpression == null) {
        query = 'NVDBMarketCommodityListModel.WorkflowInstance.StateName.ToUpper().Equals("' + stateData.toUpperCase() + '")';
        this.nvdbCommodityExpression = this.commonSharedService.prepareExpression(query, this.nvdbCommodityExpression);
      } else {
        this.nvdbCommodityExpression = this.nvdbCommodityExpression + GlobalSplCharforSplit.Char1 +
          'NVDBMarketCommodityListModel.WorkflowInstance.StateName.ToUpper().Equals("' + stateData.toUpperCase() + '")';
      }
    }
    this.getNVDBCommodityData(this.nvdbCommodityExpression, ModelName.NVDBCommodityListModel);
  }


  /**
   * Search
   * @param expression search Experession
   * @param aliasName Holds profileName
   */
  private getNVDBCommodityData(expression: string, aliasName: string) {
    this.nvdbCommodityService.listNVDBMarketCommodity(expression, aliasName).subscribe((response) => {
      this.NVDBCommodityListSortSplit();
    });
  }

  /**
   * Reset in Search Filter
   */
  resetNVDBCommodityLstFilter() {
    this.nvdbCommodityFltrForm.reset();
    this.nvdbCommodityService.listNVDBMarketCommodity('', '')
      .subscribe(() => {
        this.nvdbCommodityList = this.nvdbCommodityService.nvdbCommodityServiceDocument.dataProfile.dataList;
        this.nvdbCommodityLstData = this.nvdbCommodityService.nvdbCommodityServiceDocument;
        if (this.nvdbCommodityLstData.totalCount > ConstantNumber.IsZero) {
          this.pageCount = this.nvdbCommodityLstData.totalCount;
        }
      });
  }

  /**
   * Sorting
   * @param event event
   * @param index index Value
   * @param isDecending Is Decending True or False
   */
  NVDBCommodityListSort(event: any, index: any, isDecending: boolean) {
    event.preventDefault();
    this.selectedIndex = index;
    if (isDecending) {
      this.selectedDec = true;
    } else {
      this.selectedDec = false;
    }
    if (!isDecending) {
      this.selectedAsc = true;
    } else {
      this.selectedAsc = false;
    }
    this.nvdbCommodityService
      .listNVDBMarketCommodity('', '', ConstantNumber.IsOne, this.mcnConfig.appData.pageCount, index, isDecending)
      .subscribe((response) => {
        this.NVDBCommodityListSortSplit();
      });
  }

  NVDBCommodityListSortSplit() {
    this.nvdbCommodityList = this.nvdbCommodityService.nvdbCommodityServiceDocument.dataProfile.dataList;
    this.nvdbCommodityLstData = this.nvdbCommodityService.nvdbCommodityServiceDocument;
    this.pageCount = this.nvdbCommodityLstData.totalCount > ConstantNumber.IsZero ?
    this.nvdbCommodityLstData.totalCount : ConstantNumber.IsZero;
  }

  /**
   * Pagination
   * @param response response
   */
  getNextNVDBCommodityList(response: any) {
    this.nvdbCommodityService.listNVDBMarketCommodity(
      this.nvdbCommodityExpression, ModelName.NVDBCommodityListModel, response.PageIndex, response.PageCount)
      .subscribe((_response) => {
        this.nvdbCommodityList = this.nvdbCommodityService.nvdbCommodityServiceDocument.dataProfile.dataList;
      });
  }

  /**
   * Delete
   * @param nvdbCommoditySD ServiceDocument Model
   */
  deleteNVDBCommodity(nvdbCommoditySD: NVDBMarketCommodityModel) {
    const confirmationMsg = this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ConfirmDelete) +
      this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.NVDBCommodityDelete)
      + ' ?';
    this.alertModalService
      .confirm(AlertMessages.delete, AlertMessages.WarnIcon, Message.DeleteNVDBCommodity, confirmationMsg, '')
      .subscribe((response) => {
        if (response === true) {
          this.nvdbCommodityService.deleteNVDBMarketCommodity(nvdbCommoditySD.nvdbMarketComId)
            .subscribe((itemresponse) => {
              if (itemresponse) {
                if (this.nvdbCommodityList.length > ConstantNumber.IsZero) {
                  const index = this.nvdbCommodityList.findIndex(x => x.nvdbMarketComId ===
                    nvdbCommoditySD.nvdbMarketComId);

                  if (index !== ConstantNumber.IsMinuOne) {
                    this.nvdbCommodityList.splice(index, ConstantNumber.IsOne);
                  }
                  this.ref.markForCheck();
                }
                this.toasterService.Success(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.RecordDeletedSuccessfully), null);
                this.router.navigate(['/risk-identification-Analysis/market-research-data-commodity'],
                  { relativeTo: this.activatedRoute });
                this.ref.markForCheck();
              }
            },
              Error => {
                this.toasterService.Error(
                  this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
              });
        }
      });
  }

  public trackById(item) {
    if (!item) { return null; }
    return item.nvdbMarketComId;
  }
}
