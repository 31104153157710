<dashboard-subheader></dashboard-subheader>
<div class="filter-tab-rgt-section" *ngIf="filterMenuOpened">
    <div class="container filter-container singleicon-filter-container" fxFlex="77">
        <div class="new-filter-menu box-shadow-card" [ngClass]="{'open': filterMenuOpened}">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="filters-heading">
              <h4>{{'InboxFilter' | translate }}</h4>
                <div class="button-grp">
                    <a mat-raised-button class="btn-custom-primary-filter  mr-2" (click)="filterInbox($event)">{{'SEARCH' | translate }}</a>
                    <a mat-raised-button class="btn-custom-secondary-filter" (click)="resetFilter($event)">{{ 'RESET' | translate }}</a>
                </div>
            </div>
            <div class="filter-forms">
                <form novalidate [formGroup]="inboxFilterForm">
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-sm="25" class="mb-4 px-2 p-2 position-relative">
                      <mcn-select placeholder="{{'Module' | translate }}" [formGroup]=" this.inboxFilterForm" optionKey="dataSourceSchema"
                                  optionValue="dataSourceSchema" [isList]="true" [isInbox]="true" controlName="moduleName"
                                  [apiUrl]="this.appConfig.getAllModulesApiUrl"></mcn-select>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="27" class="mb-4 px-2 p-2 position-relative">
                      <mcn-date ngDefaultControl placeholder="{{'FromDate' | translate }}" controlName="fromDate"
                                [formGroup]="this.inboxFilterForm" disabled="true"></mcn-date>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="27" class="mb-4 px-2 p-2 position-relative">
                      <mcn-date ngDefaultControl placeholder="{{'ToDate' | translate }}" controlName="toDate"
                                [formGroup]="this.inboxFilterForm" disabled="true"></mcn-date>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="20" class="mb-4 px-2 p-2 position-relative">
                      <mcn-checkbox [formGroup]="this.inboxFilterForm" ngDefaultControl controlName="isActive" text="{{'IsActive'|translate}}"></mcn-checkbox>
                    </div>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>
<inbox></inbox>
