import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { StorageService } from '../../storageService/storageService';
import { CommonTypes, ModuleNames } from '../../common/Constants';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { TranslateService } from 'ng2-translate';
import { SafeUnsubscriber } from '../../common/SafeUnsubscriber';
import { BwhConfig } from '../../mcnConfig/BwhConfig';

@Component({
  selector: 'app-return-for-correction',
  templateUrl: './ReturnForCorrectionComponent.html',
})

export class ReturnForCorrectionComponent extends SafeUnsubscriber implements OnInit {
  public returnForCorrectionForm: FormGroup;
  public title: any;
  public reasonId: any;
  public remarks: any;
  public dailogSaveButtonClicked = false;
  public reason: string;
  public moduleName: string;
  public returnOrRejectTitle = 'ReturnForCorrection';
  public returnOrRejectRemarksFeild = 'ReturnforCorrectionRemarks';
  public reasonTypeUrl = '';

  constructor(
    private fb: FormBuilder,
    public webStorage: StorageService,
    private ref: ChangeDetectorRef,
    public appConfig: McnConfig,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<ReturnForCorrectionComponent>,
    public bwhConfig: BwhConfig,
  ) {
    super();
    this.reason = CommonTypes.CANCELLATIONREASONS;
    this.moduleName = ModuleNames.Cargo;
  }

  ngOnInit() {
    this.returnForCorrectionForm = this.fb.group({
      reasonId: ['', [<any>Validators.required]],
      remarks: ['', [<any>Validators.required]]
    });
    this.ref.markForCheck();
    this.reasonTypeUrl = this.reasonTypeUrl === '' ? this.appConfig.getMcnTypes : this.reasonTypeUrl;
  }

  public submit() {
    if (this.returnForCorrectionForm.valid) {
      this.reasonId = this.returnForCorrectionForm.controls.reasonId.value;
      this.remarks = this.returnForCorrectionForm.controls.remarks.value;
      this.dailogSaveButtonClicked = true;
      this.dialogRef.close();
    } else {
      Object.keys(this.returnForCorrectionForm.controls).forEach(field => {
        const control = this.returnForCorrectionForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  public cancelCorrection() {
    this.dialogRef.close();
  }
}
