import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StorageService } from '../../../storageService/storageService';
export let viewData: any = {};

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pieChartComponent.html',
})


export class pieChartComponent implements OnInit {
  @Input() public optionsData: any;
  @Input() public yearData: any;
  @Input() public optionsCurrYearData: any;
  @Input() public optionsPrevYearData: any;

  public pieChartForm: FormGroup;

  public view: any[] = [720, 500];
  public showLegend = true;
  public animations = true;
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;

  colorScheme = {
    domain: ['#c73932', '#459ac9', '#783b76', '#7ab058', '#bd4482', '#d0ca49', '#ce8751', '#6368bc']
  };

  public pie_ChartOptions = {
    title: '',
    width: 600,
    height: 400
  };
  ngOnInit() {
    this.pieChartForm = this.fb.group({
      year: ['']
    });
  }


  public onYearSelect(value: any) {
    if (value.options.value === '2020') {
      this.optionsData = this.optionsPrevYearData;
    } else if (value.options.value === '2021') {
      this.optionsData = this.optionsCurrYearData;
    }

  }

  constructor(private fb: FormBuilder,public webStorage: StorageService) {
    viewData = this.optionsData;
    Object.assign(this, { viewData });
  }

  public onSelect(event) {
  }
}