import { ServiceDocument } from '@agility/frameworkcore';
import { AfterContentInit, Component, DoCheck, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { HttpStatusCode } from '../../../../../app/common/Constants';
import { SafeUnsubscriber } from '../../../../../app/common/SafeUnsubscriber';
import { McnConfig } from '../../../../../app/mcnConfig/McnConfig';
import { RmsConfig } from '../../../../../app/mcnConfig/RmsConfig';
import { Constant, currentActionId, ModelName, Status, SuccessErrMsgs } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';
import { StorageService } from '../../../../../app/storageService/storageService';
import { ToasterService } from '../../../../../app/toastrService/toasterService';
import { CommonSharedService } from '../../../../common/CommonSharedService';
import { NVDBMarketSpecificationsModel } from '../../models/NVDBMarketSpecificationsModel';
import { SpecificationModel } from '../../models/SpecificationModel';
import { NVDBMarketCommodityService } from '../../services/nvdbMarketCommodity/NVDBMarketCommodityService';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';


@Component({
  selector: 'nvdb-commodity-popup',
  templateUrl: './NvdbCommodityPopUpComponent.html',
})

export class NvdbCommodityPopUpComponent extends SafeUnsubscriber implements OnInit, AfterContentInit, DoCheck {
  public nvdbSpecServiceDocument: ServiceDocument<NVDBMarketSpecificationsModel>;
  public refreshBasePage = false;
  public moduleNameRms: string;
  public nvdbSpecificationDetails: any;
  public caption: string;
  public specificationType: any;
  public specificationData: SpecificationModel[] = [];
  private stateName: string;
  public nvdbSpecificationFormControls: any;
  public parentForm: string; // C = commodity, V = vehicle

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public rmsConfig: RmsConfig,
    private toasterService: ToasterService,
    private commonSharedService: CommonSharedService,
    private nvdbCommodityService: NVDBMarketCommodityService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    private mcnConfig: McnConfig,
    public webStorage: StorageService,
  ) {
    super();
    this.moduleNameRms = ModelName.Rms;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.nvdbSpecServiceDocument = this.nvdbCommodityService.nvdbSpecServiceDocument;
      this.nvdbSpecificationDetails = this.nvdbSpecServiceDocument.dataProfile.dataModel;
      this.riskIdentificationAndAnalysisSharedService.sharingData.tariffItemSpecId = this.nvdbSpecificationDetails.tariffItemSpecId;
    });

    this.stateName = this.nvdbSpecServiceDocument.dataProfile.dataModel.workflowInstance.stateName;
    this.nvdbSpecificationFormControls = this.nvdbSpecServiceDocument.dataProfile.profileForm.controls;

    // In Start State Caption and Specification is Null
    if (this.stateName === Status.Start) {
      this.caption = null;
      this.specificationType = null;
    }
    this.nvdbSpecificationFormControls.specificationId.setValidators([Validators.required]);
  }

  ngAfterContentInit() {
    // Autocomplete Code
    if (this.stateName !== Status.Start) {
      if (this.nvdbSpecServiceDocument.dataProfile.dataModel.specificationId != null) {
        const responseSpecification: SpecificationModel = new SpecificationModel();
        responseSpecification.specificationId = this.nvdbSpecServiceDocument.dataProfile.dataModel.specificationId;
        responseSpecification.code = this.nvdbSpecServiceDocument.dataProfile.dataModel.specificationDetails.code;
        this.specificationData.push(responseSpecification);
        this.caption = this.nvdbSpecServiceDocument.dataProfile.dataModel.specificationDetails.caption;
        this.specificationType = this.nvdbSpecServiceDocument.dataProfile.dataModel.specificationDetails.specificationName.name;
      }
    }
  }

  ngDoCheck() {
    // If No Value in Specification Code the Caption and Specification Need to be Clear
    if (!this.commonSharedService.isNotNullOrUndefined(
      this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.specificationId.value)) {
      this.caption = null;
      this.specificationType = null;
    }
  }

  /**
   * On Click Of Cancel button
   */
  public cancel() {
    this.dialog.closeAll();
  }

  /**
   * Get Specification Details
   * @param event Get Specification Details of Caption and Specification Name
   */
  getSpecification(event: any) {
    this.caption = event.options.caption;
    this.specificationType = event.options.specName;
  }

  /**
   *  On Leave of Specification Code Auto Complete
   * @param event
   */
  onLeave(event: any) {
    if (!this.commonSharedService.isNotNullOrUndefined(event)) {
      this.caption = null;
      this.specificationType = null;
    }
  }

  /**
   * Create /Save Method
   * @param event
   */
  submitNVDBCommoditySpecification(event: any) {
    if (this.nvdbSpecServiceDocument.dataProfile.profileForm.valid) {
      if (this.CheckDateConditions()) {
        if (this.parentForm === Constant.IsNVDBMarketCommodity) {
          this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.nvdbMarketComId
            .setValue(this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketComId);
        } else {
          this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.nvdbMarketVehId
            .setValue(this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketVehicleId);
        }

        if (!this.commonSharedService.isNotNullOrUndefined(this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.startDate.value)) {
          this.nvdbSpecificationFormControls.startDate.setValue(new Date());
        }

        this.nvdbCommodityService.submitNVDBMarketCommoditySpecs().subscribe((res) => {
          if (this.commonSharedService.isNotNullOrUndefined(res) && res.result === HttpStatusCode.OK) {
            if (this.nvdbSpecServiceDocument.dataProfile.actionService.currentActionId ===
              currentActionId.StartToCreatedNVDBCommoditySpecifications) {
              this.toasterService.Success(
                this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.RecordCreatedSuccessfully), null);
            } else {
              this.toasterService.Success(
                this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.RecordUpdatedSuccessfully), null);
            }
            this.dialog.closeAll();
          }
        });
      }
    } else {
      Object.keys(this.nvdbSpecServiceDocument.dataProfile.profileForm.controls).map(
        (controlName) => this.nvdbSpecServiceDocument.dataProfile.profileForm.controls[controlName]).forEach(
          (control) => { control.markAsTouched(); },
        );
    }
  }

  /**
 * Dates Validation
 */
  private CheckDateConditions() {
    if ((this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.startDate.value) &&
      (this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.endDate.value)) {
      const startdateC = new Date(this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.startDate.value);
      const endDateC = new Date(this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.endDate.value);
      if (startdateC <= endDateC) {
        return true;
      } else {
        this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ToDateGraterthanFromDate));
        return false;
      }
    } else if (this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.startDate.value === null &&
      this.nvdbSpecServiceDocument.dataProfile.profileForm.controls.endDate.value !== null) {
      this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.FromDateToDateRequired));
      return false;
    } else {
      return true;
    }
  }

}
