
export enum AccountMessages {
  SuccessfullyUpdated = 'SuccessfullyUpdated',
  PleaseTryAgain = 'PleaseTryAgain',
  InvalidCaptcha = 'InvalidCaptcha',
  LoginFailedPleaseTryAgain = 'LoginFailedPleaseTryAgain',
  InternalServerErrorPleaseTryAgainLater = 'InternalServerErrorPleaseTryAgainLater',
  PleaseRequestToIncreaseTheCeilingLimitOnceTheExistingLimitExhausts = 'PleaseRequestToIncreaseTheCeilingLimitOnceTheExistingLimitExhausts',
  PleaseAssociateAtleastOneAgent = 'PleaseAssociateAtleastOneAgent',
  AgentAssociationCannotBeDeletedAsReferenceExist = 'AgentAssociationCannotBeDeletedAsReferenceExist',
  DepartmentAlreadyCreated = 'DepartmentAlreadyCreated',
  PleaseAddprofilesdetails = 'PleaseAddprofilesdetails',
  PleaseEnterAtleastOneInput = 'PleaseEnterAtleastOneInput',
  SelectOneCustomStation = 'SelectOneCustomStation',
  SelectOnePort = 'SelectOnePort',
  PleaseSelectDefaultProfile = 'PleaseSelectDefaultProfile',
  SelectAtLeastOneProfile = 'SelectAtLeastOneProfile',
  selecteddocumenttype = 'selecteddocumenttype',
  DocAlreadyUploaded = 'DocAlreadyUploaded',
  PleaseAddBankDetails = 'PleaseAddBankDetails',
  PleaseAddDepartmentDetails = 'PleaseAddDepartmentDetails',
  Pleaseaddstakeholderdetails = 'Pleaseaddstakeholderdetails',
  Pleaseaddprofiledetails = 'Pleaseaddprofiledetails',
  Pleaseaddcustomsstationdetails = 'Pleaseaddcustomsstationdetails',
  SelectDepartment = 'SelectDepartment',
  DuplicateBankDetails = 'DuplicateBankDetails',
  PleaseEnterBankDetails = 'PleaseEnterBankDetails',
  PleaseUploadAtleastOneDocument = 'PleaseUploadAtleastOneDocument',
  SelectedDocumentAlreadyUploaded = 'SelectedDocumentAlreadyUploaded',
  ValidEmailID = 'ValidEmailID',
  AddAtLeastOneDepartment = 'AddAtLeastOneDepartment',
  AcceptTermsAndServices = 'AcceptTermsAndServices',
  SucessfullySentResetPasswordLink = 'SucessfullySentResetPasswordLink',
  SucessfullySentActivationLink = 'SucessfullySentActivationLink',
  EnterOneSearch = 'EnterOneSearch',
  SelectDefaultCustomStations = 'SelectDefaultCustomStations',
  SelectDefaultProfile = 'SelectDefaultProfile',
  SelectCustomStations = 'SelectCustomStations',
  SelectProfile = 'SelectProfile',
  SelectUserDetails = 'SelectUserDetails',
  CurrentlyAllignwithSameDept = 'CurrentlyAllignwithSameDept',
  PleaseSelectDeafultProfile = 'PleaseSelectDeafultProfile',
  EnterOneSearchOption = 'EnterOneSearchOption',
  SelectAtLeastOneDept = 'SelectAtLeastOneDept',
  CeilingLimitExaustMsg = 'CeilingLimitExaustMsg',
  AccessFailAcountMsg = 'AccessFailAcountMsg',
  LackedOut = 'LackedOut',
  InvalidLogin = 'InvalidLogin',
  PleaseSelectDefaultCustomStation = 'PleaseSelectDefaultCustomStation',
  PleaseSelectDefaultPort = 'PleaseSelectDefaultPort',
  PleaseAddprotssdetails = 'PleaseAddprotssdetails',
  ActivatedWithPasswordlinkhasbeensendtoyourEmailID = 'ActivatedWithPasswordlinkhasbeensendtoyourEmailID',
  Newversionavailable = 'Newversionavailable',
  DutyTaxFileName = 'Template_DutyTaxCharge'
}

export enum State {
  Start = 'Start',
  Created = 'Created',
  Modified = 'Modified',
  Submitted = 'Submitted',
  Acknowledged = 'Acknowledged',
  Activated = 'Activated'
}
export enum RegistrationType {
  Individual = 'individual',
  Organization = 'organization',
  Government = 'government',
  OrganizationDis = 'Organization',
  IndividualDis = 'Individual',
  Trader = 'Trader',
  Master = 'Master',
  Active = 'active'
}

export enum StackHolderType {
  Gov = 'G',
  Org = 'O',
  Cus = 'C',
  Ind = 'I'
}

export enum Default {
  Zero = '0',
  One = '1',
  two = '2'
}

export enum AppKeySize {
  IsEight = '8',
  IsOneTwoEight = '128'
}

export enum DialogRefAct {
  NotAlert = 'Alert!',
  CustomsStations = 'Custom Stations',
  DissociateTheCustomsStation = 'dissociate the Custom Stations',
  Ports = 'Ports',
  DissociateThePort = 'dissociate the Port',
  Departemnt = 'Department',
  ChangeTheDepartemnt = 'change the department',
  Profiles = 'Profiles',
  ChangeTheProfile = 'change the profile'
}

export enum Selecteddoc {
  NotSelected = 'Not Selected'
}

export enum ComponentInstance {
  MasterUser = 'MasterUser',
  ReplaceTheMasterUser = 'replacetheMasterUser'
}
export enum Activation {
  registeredSuccess = 'Your account is activated successfully',
  alreadyRegistered = 'Youraccountisalreadyactivatedsuccessfully',
  activationLinkExpired = 'YourActivationLinkisExpired',
  Zero = 0,
  One = 1,
  two = 2,
  three = 3
}
