import { ProfileModel } from '@agility/frameworkcore';
import { StorageLocationModel } from './StorageLocationModel';
import { OrganizationModel } from './OrganizationModel';
import { BillOfLadingModel } from '../../billofLading/BillOfLading';
import { SupplementaryManifestModel } from './SupplementaryManifestModel';
import { McnTypesModel } from '../../../../mdm/models/McnTypesModel';
export class ManifestModel extends ProfileModel {
    public manifestId: number;
    public journeyId: number;
    public agentId: number;
    public organizationDetails: OrganizationModel;
    public manifestNumber: string;
    public manifestDate: Date;
    public manifestfor: number;
    public manifestForData: McnTypesModel;
    public manifestType: number;
    public storageType: number;
    public storageLocationId: number;
    public storageTypeData: McnTypesModel;
    public storageLocation?: StorageLocationModel;
    public totalNoOfHBs: number;
    public manifestSubmittedDate: Date;
    public actualArrivalDate: Date;
    public isNilCargo: boolean;
    public remarks: string; useInTranshipment: boolean;
    public billOfLadingList: BillOfLadingModel[];
    public supplementaryManifestList: SupplementaryManifestModel[];
    public agentname: string;
    public journeyType: string;
    public journeyNumber: string;
    public expectedTimeOfArrival?: Date;
    public manifestforName: string;
    public isJourneyArrived: boolean;
    public arrivalDate: Date;
    public bolCount: any;
}
