import { Injectable } from '@angular/core';

export interface MyData {
  sectionId: number;
  chapterId: number;
  headingId: number;
  subheadingId: number;
  tariffItemsAssoProhibitionId: number;
  tariffId: number;
  conditionalProhibitionId: number;
  tariffItemDutyId: number;
  dutyTypeId: number;
  tariffItemSpecificationId: number;
  specificationId: number;
  dutyId: number;
  tariffEndDate: Date;
  sectionEndDate: Date;
  sectionInfiniteDate: boolean;
  chapterEndDate: Date;
  chapterInfiniteDate: boolean;
  headingEndDate: Date;
  headingInfiniteDate: boolean;
  subHeadingEndDate: Date;
  subHeadingInfiniteDate: boolean;

  chapterIdList: any;
  headingIdList: any;
  subheadingIdList: any;

  productCodeId: number;

  dutyTaxStartDate: Date;
  dutyTaxEndDate: Date;

  tariffItemDutyRateId: number;
  tariffItemId: number;
  dutyRateCountryId: number;
  dutyRateBasisTypeCode: string;
  isAntiDumping: boolean;
  isOtherDuties: boolean;
  dutyRateId: number;
  isTRQ: boolean;
  jsonObject: string;
  wcoCorrelationId: number;
  masterCodeId: number;
  CorrelationId: number;
}

export interface Codes {
  sectionCode: string;
  chapterCode: string;
  headingCode: string;
  subheadingCode: string;
  tariffCode: string;
  dutyTypeName: string;
  tableName: string;
  dutyTypeCode: string;
  itemCount: string;
}

@Injectable()
export class TariffSharedService {
  sharingData: MyData = {
    sectionId: 0,
    chapterId: 0,
    headingId: 0,
    subheadingId: 0,
    tariffId: 0,
    dutyId: 0,
    conditionalProhibitionId: 0,
    tariffItemSpecificationId: 0,
    tariffItemsAssoProhibitionId: 0,
    specificationId: 0,
    tariffItemDutyId: 0,
    dutyTypeId: 0,
    sectionEndDate: null,
    sectionInfiniteDate: null,
    chapterEndDate: null,
    chapterInfiniteDate: null,
    headingEndDate: null,
    headingInfiniteDate: null,
    subHeadingEndDate: null,
    subHeadingInfiniteDate: null,
    productCodeId: 0,
    dutyTaxStartDate: null,
    tariffEndDate: null,
    dutyTaxEndDate: null,
    chapterIdList: [],
    headingIdList: [],
    subheadingIdList: [],
    tariffItemDutyRateId: 0,
    tariffItemId: 0,
    dutyRateCountryId: 0,
    dutyRateBasisTypeCode: null,
    isAntiDumping: null,
    isOtherDuties: null,
    dutyRateId: 0,
    isTRQ: false,
    jsonObject: null,
    wcoCorrelationId: 0,
    masterCodeId: 0,
    CorrelationId: 0
  };

  sharingCodes: Codes = {
    sectionCode: null,
    chapterCode: null,
    headingCode: null,
    subheadingCode: null,
    tariffCode: null,
    dutyTypeName: null,
    tableName: null,
    itemCount: null,
    dutyTypeCode: null
  };

  constructor() {
  }
}
