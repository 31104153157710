import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mode-tracking-dashboard',
  templateUrl: './ModeTrackingComponent.html',
})


export class ModeTrackingComponent implements OnInit {

  public lat = 1.934400;
  public lng = 101.9499962;


  public posX: any;
  public posY: any;
  public alpha: any;
  public beta: any;
  public newAlpha: any;
  public newBeta: any;
  public sensitivity: any = 5;


  public seriesOptions: any[] = [];
  public origin: any;
  public destination: any;
  public optionsTemp: any =
    {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Temperature (°C)'
      },
      xAxis: {
        categories: ['19: 00', '19: 30', '20: 30', '20: 30', '21: 00', '21: 30', '22: 00', '22: 30', '23: 00', '23: 30', '00: 00']
      },
      yAxis: {
        title: {
          text: 'Temperature (°C)'
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        }
      },
      series: [{
        name: '',
        data: [22.00, 29.00, 25.00, 30.00, 33.00, 30.00, 25.00, 22.00, 25.50]
      }]
    };

  public optionsFh: any =
    {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Humidity (%)'
      },
      xAxis: {
        categories: ['19: 00', '19: 30', '20: 30', '20: 30', '21: 00', '21: 30', '22: 00', '22: 30', '23: 00', '23: 30', '00: 00']
      },
      yAxis: {
        title: {
          text: 'Humidity (%)'
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        }
      },
      series: [{
        name: '',
        data: [10.00, 15.00, 11.00, 20.00, 17.00, 27.00, 30.00, 28.00, 25.00, 23.00, 27.00]
      }]
    };

  public optionsPresure: any =
    {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Pressure'
      },
      xAxis: {
        categories: ['19: 00', '19: 30', '20: 30', '20: 30', '21: 00', '21: 30', '22: 00', '22: 30', '23: 00', '23: 30', '00: 00']
      },
      yAxis: {
        title: {
          text: 'Pressure'
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: true
        }
      },
      series: [{
        name: '',
        data: [610.00, 690.00, 650.00, 710.00, 790.00, 740.00, 700.00, 650.00, 690.00]
      }]
    };

  public renderOpts = {
    suppressMarkers: true,
  };

  public markerOpts = {
    origin: {
      icon: '../../../../assets/shipImages/Truck_Green.png',
    },
    destination: {
      icon: '../../../../assets/shipImages/Port_Location.svg',
    },
  };

  public testData: any = {
    '1': [[0.09381676901740763, 0.09381676901740763, 0.07662538699690404],
    [0.28962312528892725, 0.28962312528892725, 0.26625386996904027],
    [0.236997077256916, 0.236997077256916, 0.1540247678018576],
    [0.16310068433111624, 0.16310068433111624, 0.326625386996904],
    [0.15123262963514972, 0.15123262963514972, 0.09210526315789475],
    [0.03276907803496683, 0.03276907803496683, 0.07662538699690404],
    [0.0, 0.0, 0.40325077399380804], [0.09363387653747149, 0.09363387653747149, 0.018575851393188857],
    [0.011688698810946268, 0.011688698810946268, 0.0], [0.28598119036732594, 0.28598119036732594, 0.21362229102167182],
    [0.38523547406791786, 0.38523547406791786, 0.10758513931888546],
    [0.09321327435640289, 0.09321327435640289, 0.11532507739938082],
    [0.15348341968519255, 0.15348341968519255, 0.07662538699690404],
    [0.008831887960673067, 0.008831887960673067, 0.011222910216718267],
    [0.09516092769936924, 0.09516092769936924, 0.08281733746130032],
    [0.13708069246528454, 0.13708069246528454, 0.24690402476780188],
    [0.21731062165760207, 0.21731062165760207, 0.1540247678018576]]
  };

  public options1: any = {
    chart: {
      margin: 100,
      type: 'scatter',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 30,
        depth: 250,
        viewDistance: 5,
        frame: {
          bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
          back: { size: 1, color: 'rgba(0,0,0,0.04)' },
          side: { size: 1, color: 'rgba(0,0,0,0.06)' }
        }
      },
      events: {
        redraw: function () { }
      }
    },
    plotOptions: {
      scatter3d: {
        width: 2,
        height: 2,
        depth: 2
      }
    },
    title: {
      text: 'Scatter Plot of clusters'
    },
    xAxis: {
      min: 0,
      max: 1.5,
      gridLineWidth: 1,
      title: {
        enabled: true,
        text: 'Assessed Value'
      },
      scrollbar: {
        enabled: true
      },
    },
    yAxis: {
      min: 0,
      max: 1.5,
      title: {
        enabled: true,
        text: 'Declared Value'
      },
      scrollbar: {
        enabled: true
      },
    },
    zAxis: {
      min: 0,
      max: 1.5,
      title: {
        enabled: true,
        text: 'Declared quantity'
      },
      scrollbar: {
        enabled: true
      },
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      x: 30,
      y: 50,
      floating: true
    },
    tooltip: {
      // enabled: false,
      pointFormat: '{series.name}: <b>{point.data:.1f}%</b>'
    },
    series: this.seriesOptions = [
      {
        name: 'Outliers',
        marker: { symbol: 'circle' },
        data: this.testData['1']
      },
      {
        name: 'Non Outliers',
        marker: { symbol: 'circle' },
        data: this.testData['2']
      },
      {
        name: 'test',
        marker: { symbol: 'diamond' },
        data: [[12.685995664556348, 4.184151749511521, 4.0], [11.944335977832624, 4.1363929244459845, 3.0],
        [13.735605561641428, 4.135916195692854, 3.0], [13.332203630949095, 4.137945890812678, 3.0],
        [12.939258699170809, 4.138041221051358, 3.0], [13.009880328845764, 4.137217412299707, 3.0],
        [13.833075155778943, 4.13804122093318, 2.0], [12.63808880087041, 4.13699208944312, 2.0],
        [12.638670476168409, 4.137564479426356, 1.0], [12.63808880087041, 4.13699208944312, 1.0],
        [11.944944866299638, 4.13699208944312, 1.0], [13.147003817902053, 4.1450036138535635, 1.0],
        [13.044522098972998, 4.137945890812678, 0.0], [13.842715633625554, 4.14752863046963, 0.0],
        [13.842715633625554, 4.14752863046963, 0.0], [13.86191042717733, 4.16642294639291, 0.0],
        [12.811611225343333, 4.307967603630217, 2.0], [13.280776662265676, 4.30714170692216, 1.0],
        [12.906083988730302, 4.30714170692216, 1.0], [14.931629637898329, 4.137984981250005, 4.0],
        [15.219316492547078, 4.1379897677291595, 4.0], [14.577198754606558, 4.188224932456934, 4.0],
        [13.889681274578093, 4.193768994144918, 4.0], [14.931892264567711, 4.1382434183451435, 4.0],
        [14.668366074501778, 4.278069300762595, 4.0], [13.83351077758548, 4.138469894708837, 4.0],
        [15.264493415527753, 4.066584458019577, 4.0], [13.83931336015662, 4.144180211427575, 3.0],
        [15.550523011069675, 4.064960378067264, 3.0], [15.28276941716297, 4.084550085889653, 3.0],
        [15.55059460845617, 4.065030746635542, 3.0], [15.274514981581598, 4.076435141678533, 3.0],
        [14.525167056669197, 4.137003268953314, 3.0], [15.520212830971197, 4.0351782515528845, 3.0],
        [13.84602715380224, 4.150787905645312, 3.0], [13.00641478313101, 4.168345981080174, 3.0],
        [13.075407504892864, 4.168345981080174, 3.0], [11.060518109242649, 4.168345981080174, 3.0],
        [11.060518109242649, 4.168345981080174, 3.0], [15.27782700101752, 4.079691055422174, 3.0],
        [14.138975240541452, 4.040145295197131, 3.0], [14.8599032572732, 4.067444306245975, 3.0],
        [13.850745212330242, 4.15543182210308, 3.0], [13.88162558576193, 4.1858353361610225, 3.0],
        [13.869977814999046, 4.174365731202142, 3.0], [15.55226608492849, 4.066673560297056, 3.0],
        [13.102321371459366, 4.194848805252751, 3.0], [13.102321371459366, 4.194848805252751, 3.0],
        [13.795467531774596, 4.194848805252751, 3.0], [13.86110715192203, 4.165632132206789, 3.0],
        [14.524785582852921, 4.136627888476837, 3.0], [14.524785582852921, 4.136627888476837, 3.0],
        [14.524785582852921, 4.136627888476837, 3.0], [14.85903714206922, 4.066593025891257, 2.0],
        [13.950070952234965, 4.195294867181378, 3.0], [14.85903714206922, 4.066593025891257, 3.0],
        [13.84098920301359, 4.145829504910609, 2.0], [15.5312189479153, 4.045990802814257, 2.0],
        [14.843818490525312, 4.051637110788995, 2.0], [15.687176187082104, 4.141487338446812, 2.0],
        [14.8718922389545, 4.079229232363244, 2.0], [14.679848241018673, 4.289393119404725, 2.0],
        [15.943064063649697, 4.0522596177384, 2.0], [13.860406960798407, 4.164942811067637, 2.0],
        [13.481821125748708, 4.147645173932868, 2.0], [12.648913817754293, 4.147645173932868, 2.0],
        [14.97108017173683, 4.176818159042893, 1.0], [14.869900581209913, 4.077271306799466, 2.0],
        [11.462521248919588, 4.164942811067637, 2.0], [13.765096876603732, 4.164942811067637, 2.0],
        [14.899078732632233, 4.105961819812291, 1.0], [14.536778371173135, 4.148430204375343, 0.0],
        [15.26457535464173, 4.066664993115358, 0.0], [15.552276544667109, 4.066683840818209, 0.0],
        [15.644601087485963, 4.157496184559991, 0.0]]
      }
    ]
  };

  constructor() { }

  ngOnInit() {
    this.getDirection();
  }

  getDirection() {
    this.origin = { lat: 1.934400, lng: 103.358727 };
    this.destination = { lat: 2.7166638, lng: 101.9499962 };
  }

  mousemove(e) {
    this.newBeta = this.beta + (this.posX - e.pageX) / this.sensitivity;
    this.newBeta = Math.min(100, Math.max(-100, this.newBeta));
    this.options1.chart.options3d.beta = this.newBeta;

    // Run alpha
    this.newAlpha = this.alpha + (e.pageY - this.posY) / this.sensitivity;
    this.newAlpha = Math.min(100, Math.max(-100, this.newAlpha));
    this.options1.chart.options3d.alpha = this.newAlpha;

    this.options1.chart.events.redraw(false);
  }

  mousedown(chart) {

  }



}
