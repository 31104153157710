import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ToasterService {
  constructor(private toastrService: ToastrService) {
  }

  public Error(message: any, title: any = null, timeOut: number = 2000, position: string = '', closeButton: boolean = false) {
    position = position === ''.toUpperCase() ? 'toast-top-right' : position.toUpperCase()
      === 'bottom'.toUpperCase() ? 'toast-bottom-right' : '';
    if (!isNullOrUndefined(message) && message !== '') {
      return this.toastrService.error(message, title, {
        timeOut: timeOut, positionClass: position,
        toastClass: 'toast', closeButton: closeButton
      });
    }
  }

  public Warning(message: any, title: any = null, timeOut: number = 2000, position: string = '', closeButton: boolean = false) {
    position = position === ''.toUpperCase() ? 'toast-top-right' : position.toUpperCase() ===
      'bottom'.toUpperCase() ? 'toast-bottom-right' : '';
    if (!isNullOrUndefined(message) && message !== '') {
      return this.toastrService.warning(message, title, {
        timeOut: timeOut, positionClass: position,
        toastClass: 'toast', closeButton: closeButton
      });
    }
  }

  public Success(message: any, title: any = null, timeOut: number = 2000, position: string = '', closeButton: boolean = false) {
    position = position === ''.toUpperCase() ? 'toast-top-right' : position.toUpperCase() ===
      'bottom'.toUpperCase() ? 'toast-bottom-right' : '';
    if (!isNullOrUndefined(message) && message !== '') {
      return this.toastrService.success(message, title, {
        timeOut: timeOut, positionClass: position,
        toastClass: 'toast', closeButton: closeButton
      });
    }
  }

  public Info(message: any, title: any = null, timeOut: number = 2000, position: string = '', closeButton: boolean = false) {
    position = position === ''.toUpperCase() ? 'toast-top-right' : position.toUpperCase() ===
      'bottom'.toUpperCase() ? 'toast-bottom-right' : '';
    if (!isNullOrUndefined(message) && message !== '') {
      return this.toastrService.info(message, title, {
        timeOut: timeOut, positionClass: position,
        toastClass: 'toast', closeButton: closeButton
      });
    }
  }

  public Remove(toasterId: any = null) {
    return this.toastrService.remove(toasterId);
  }

  public ClearAll() {
    return this.toastrService.clear();
  }

  public Show(message: any, title: any = null, timeOut: number = 2000, position: any = 'toast-top-right') {
    if (!isNullOrUndefined(message) && message !== '') {
      return this.toastrService.show(message, title, { timeOut: timeOut, positionClass: position, toastClass: 'toast' });
    }
  }
}
