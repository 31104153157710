<!--<style>
  body {
    font-family: sans-serif, Arial, Helvetica, sans-serif;
  }

  .success-card {
    width: 600px;
    height: auto;
    border: solid 0px #d5d5d5;
    /*border-top: solid 3px #9a9a9a;*/
    margin: auto;
    padding: 20px;
    text-align: center;
  }

  .email-template .activated-email-header {
    height: 40px;
    width: 100%;
    display: block;
    margin-bottom: 2rem;
    background: #1863a6;
  }

    .email-template .activated-email-header .logo-section {
      padding: 0px;
    }

    .email-template .activated-email-header .logo {
      width: 120px;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
    }

  .email-template .activated-email-content .success-card .contz-text {
    font-size: 2.2rem;
    color: #f5861f;
  }
</style>-->

<!-- Activated Success starts -->
<div class="email-template">
    <div class="activated-email-header">
        <div class="logo-section">
            <img src="../../../../assets/images/MicroClear-LogoSB.png" class="logo" alt="MicroClear" />
        </div>
    </div>
    <div class="activated-email-content">
        <div class="success-card" *ngIf="this.isShowSuccessActivation">
            <div>
                <img src="../../../../assets/images/Congrats.jpg" class="logo" alt="Congratulations" />
            </div>
            <h4>{{ activationMessage | translate}}</h4>
            <p>{{ 'Please' | translate}} <a href="javascript:void(0)" (click)="gotoLogin()">{{ 'ClickHere' | translate}}</a> {{ 'toaccesstheservices' | translate}}</p>
        </div>
        <div class="success-card" *ngIf="!this.isShowSuccessActivation">
            <div>
                <h2 class="contz-text">{{ 'Warning' | translate}} !</h2>
            </div>
            <h4>{{ activationMessage | translate}}</h4>
            <p>{{ 'PleasecontactCustomsRegisterationDepartmentforActivation' | translate}}
            </p>
        </div>
    </div>
</div>
<!-- Activated Success Ends -->
