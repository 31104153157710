import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ChatBotDataService } from '../services/ChatBotDataService';

@Component({
  selector: 'chat-footer',
  templateUrl: './ChatFooterComponent.html',
})
export class ChatFooterComponent implements OnInit {

  constructor(public ChatBotDataService: ChatBotDataService) { }
  public msg: Subject<any> = new Subject();
  public activeClass = false;

  ngOnInit() { }


  public onChange(target: any) {
    this.msg.next(target.value);
    target.value = '';
  }
  public download_messages() {
    this.ChatBotDataService.download_messages()
  }

  public onMsgReceive(msg: string) { }

  // Toggle Class
  public onToggleClass() {
    this.activeClass = !this.activeClass;
  }
    
}
