import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { malguageData, egtguageData } from '../data/data';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-guage-chart',
  templateUrl: './guageChartComponent.html',
})
export class guageChartComponent implements OnInit {
  @Input() public optionsData: any;
  @Input() public yearData: any;
  @Input() public countryData: any;
  @Input() public optionsCurrYearData: any;
  @Input() public optionsPrevYearData: any;
  public guageChartForm: FormGroup;

  view: any[] = [550, 400];
  data: any[];

  showAxis = false;
  animations = true;

  colorScheme = {
    domain: ['#c73932', ' #6dc0b7', '#bd4482']
  };

  ngOnInit() {
    this.guageChartForm = this.fb.group({
      year: ['']
    });
  }

  public onCounrtySelect(value: any) {
    if (value === 'ma') {
      this.optionsData = malguageData;
    } else if (value === 'ey') {
      this.optionsData = egtguageData;
    }
  }

  public onYearSelect(value: any) {
    if (value.options.value === '2020') {
      this.optionsData = this.optionsPrevYearData;
    } else if (value.options.value === '2021') {
      this.optionsData = this.optionsCurrYearData;
    }
  }

  constructor(private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) {
  }

  public onSelect(event) {
  }
}