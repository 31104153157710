import { FxContext } from '@agility/frameworkcore';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CommonTypes, ModuleNames } from '../../common/Constants';
import { FilterMenuService } from '../../common/filter-menu.service';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { StorageService } from '../../storageService/storageService';
import { InboxService } from './services/InboxService';
@Component({
  selector: 'inbox',
  templateUrl: './inbox-tabs.component.html',
})
export class InboxTabsComponent implements OnInit, AfterContentInit {

  public inboxFilterForm: FormGroup;
  public filterMenuOpened: boolean;
  public subScribeRefreshDynamicDataMethod: Subscription;
  public subScribeResetFilteredDynamicDataMethod: Subscription;
  public isFirst: boolean;
  public isFilter: boolean;
  public msgType: any[] = [];
  public msgList: any[] = [];
  public msgDates: any[] = [];
  public pageCount: any;
  public dateTotalCount: any;
  public labelText: any = 'All';
  public sysAdminIndex: any;
  public riskAnalyst = false;
  public rmsHomePage = false;
  public riskManager = false;
  public seniourRiskAnalyst = false;
  public valAdmin = false;
  public deputyDirectorValuation = false;
  public showHideForRMS = true;
  public userName: any = '';

  modalScrollDistance = 2;
  modalScrollThrottle = 70;
  datePageIndex: number;
  msgPageIndex: number;

  constructor(private fxContext: FxContext,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public storageService: StorageService,
    private inboxService: InboxService,
    public appConfig: McnConfig,
    private filterMenuService: FilterMenuService) {
    this.subScribeRefreshDynamicDataMethod = this.inboxService.callRefreshDynamicData$.subscribe(
      mission => {
        this.inboxFilterList();
      });
    this.subScribeResetFilteredDynamicDataMethod = this.inboxService.callResetFilteredDynamicData$.subscribe(
      mission => {
        this.inboxResetFilterList();
      });
    this.userName = this.fxContext.userProfile.name;
  }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe(() => {
        this.appConfig.getCascadingTypes(ModuleNames.Inbox, CommonTypes.MsgTypes).subscribe((res) => {
          this.msgType = this.extractDomainData(res);
        });
      });
  }

  ngAfterContentInit() {
    this.isFirst = true;
    this.inboxService.getMessageDatesList().subscribe((response) => {
      if (!isNullOrUndefined(response) && response.length > 0) {
        this.isFirst = true;
        this.msgDates = response;
        this.dateTotalCount = response[0].totalCount;
        this.getMessageList(this.msgDates[0].createdDate, 1, true);
        this.datePageIndex = 1;
        this.msgPageIndex = 1;
      } else {
        this.isFirst = false;
        this.msgDates = [];
        this.pageCount = 0;
        this.dateTotalCount = 0;
        this.datePageIndex = 0;
        this.msgPageIndex = 0;
      }
    });
  }

  public onDateScroll(event: any) {
    if (this.msgDates.length < this.dateTotalCount) {
      this.datePageIndex = this.datePageIndex + 1;
      if (!this.inboxService.isFilter) {
        this.inboxService.getMessageDatesList(this.getMsgTypeId(this.labelText),
          true, null, null, null, this.datePageIndex, this.appConfig.appData.associatePageCount)
          .subscribe((dateList) => {
            this.msgDates = this.msgDates.concat(dateList);
          });
      } else {
        this.inboxService.getMessageDatesList(this.getMsgTypeId(this.labelText), this.inboxService.isActive,
          this.inboxService.fromDate, this.inboxService.toDate, this.inboxService.moduleName,
          this.datePageIndex, this.appConfig.appData.associatePageCount)
          .subscribe((dateList) => {
            this.msgDates = this.msgDates.concat(dateList);
          });
      }
    }
  }

  public onMsgListScroll(event: any, date: any) {
    if (this.msgList.length < this.pageCount) {
      this.msgPageIndex = this.msgPageIndex + 1;
      this.getMessageList(date, this.msgPageIndex, false);
    }
  }

  private getMessageList(date: any, pageIndex: number, isCount: boolean) {
    this.inboxService.getMessageList(this.getMsgTypeId(this.labelText), true, date, null,
      pageIndex, this.appConfig.appData.associatePageCount)
      .subscribe((msgResponse) => {
        if (!isNullOrUndefined(msgResponse) && msgResponse.length > 0) {
          this.isFirst = false;
          if (isCount) {
            this.msgList = msgResponse;
            this.pageCount = msgResponse[0].totalCount;
          } else {
            this.msgList = this.msgList.concat(msgResponse);
          }
        } else {
          this.isFirst = false;
          this.msgList = [];
          this.pageCount = 0;
        }
      });
  }

  public replaceRefnumber(bodyString: string, refnumber: any) {
    return bodyString.replace('{{msg.refNumber}}', refnumber);
  }

  public toggleClassName() {
    this.filterMenuService.toggleFilterMenu();
  }

  public getMsgData(date: any) {
    if (!this.isFirst) {
      this.msgList = [];
      this.pageCount = 0;
      this.getMessageList(date, 1, true);
      this.msgPageIndex = 1;
    }
  }

  public updateboomark(event: any, id: any) {
    event.preventDefault();
    event.stopPropagation();
    let bookmark = this.msgList.find(x => x.msgInboxId === id).isBookmark;

    if (bookmark === null) {
      bookmark = true;
    } else if (bookmark === true) {
      bookmark = false;
    } else {
      bookmark = true;
    }

    this.inboxService.updateBookMarkMessage(id, bookmark).subscribe((data) => {
      const defaultndex = this.msgList.findIndex(x => x.msgInboxId === id);
      if (defaultndex !== -1) {
        const indexData: any = this.msgList[defaultndex];
        indexData.isBookmark = bookmark;
        this.msgList.splice(defaultndex, 1, indexData);
      }
    });
  }

  public updateUnReadMessage(event: any, id: any) {
    const unread = this.msgList.find(x => x.msgInboxId === id).unread;
    if (unread === true) {
      this.inboxService.updateUnReadMessage(id, false).subscribe((data) => {
        const defaultndex = this.msgList.findIndex(x => x.msgInboxId === id);
        if (defaultndex !== -1) {
          const indexData: any = this.msgList[defaultndex];
          indexData.unread = false;
          this.msgList.splice(defaultndex, 1, indexData);
        }
      });
    }
  }

  public getMessageDateList(response: any, msgType: any) {
    this.isFirst = true;
    this.inboxService.getMessageDatesList(msgType, true)
      .subscribe((dateList) => {
        if (dateList.length > 0) {
          this.isFirst = true;
          this.msgDates = dateList;
          this.dateTotalCount = dateList[0].totalCount;
          this.getMessageList(this.msgDates[0].createdDate, 1, true);
          this.datePageIndex = 1;
          this.msgPageIndex = 1;
        } else {
          this.isFirst = false;
          this.msgDates = [];
          this.pageCount = 0;
          this.dateTotalCount = 0;
          this.datePageIndex = 0;
          this.msgPageIndex = 0;
        }
      });
  }

  public gotoReference(navigateUrl: any, params: any, id: any) {
    if (params != null) {
      this.storageService.set('inbox', params);
      this.storageService.set('msgInboxId', id);
      this.router.navigate(['/' + navigateUrl]);
    } else {
      this.router.navigate(['/' + navigateUrl]);
    }
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.clearValues();
    this.labelText = tabChangeEvent.tab.textLabel;
    if (tabChangeEvent.tab.textLabel === 'All') {
      this.getMessageDateList(null, 0);
    } else {
      this.getMessageDateList(null, this.getMsgTypeId(tabChangeEvent.tab.textLabel));
    }
  }

  private getMsgTypeId(name: any) {
    if (name !== null && name !== undefined && name !== '') {
      const msgTId = this.labelText === 'All' ? 0 : this.msgType.find(x => x.name === name).typeId;
      return msgTId;
    }
    return null;
  }

  private clearValues() {
    this.msgDates = [];
    this.msgList = [];
    this.pageCount = 0;
    this.dateTotalCount = 0;
    this.msgPageIndex = 0;
    this.datePageIndex = 0;
  }


  public closePanel(event: any) {
    this.msgList = [];
    this.pageCount = 0;
    this.isFirst = false;
    this.msgPageIndex = 0;
  }

  public getMsgType(id: number) {
    if (id > 0 && this.msgType.length > 0) {
      return this.msgType.find(x => x.typeId === id).name;
    } else {
      return null;
    }
  }

  public inboxFilterList = (): void => {
    const filterresponse = this.inboxService.filterMessageResponse;
    if (filterresponse.length > 0) {
      this.isFirst = true;
      this.msgDates = filterresponse;
      this.dateTotalCount = filterresponse[0].totalCount;
      this.getMessageList(this.msgDates[0].createdDate, 1, true);
      this.msgPageIndex = 1;
    } else {
      this.isFirst = false;
      this.msgDates = [];
      this.pageCount = 0;
      this.dateTotalCount = 0;
    }
  }

  public inboxResetFilterList = (): void => {
    this.isFilter = false;
    this.getMessageDateList(null, 0);
  }


  private extractDomainData(response: any) {
    const i = 0;
    let keys: string[];
    keys = Object.keys(response);
    const body = response[keys[i]];
    return body || [];
  }

  public getString(data: string) {
    return data.replace('.', '. <br\> ');
  }
}
