<div class="container mt-3 nopl-pr" fxLayout="column" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90" class="riskFreq-flex">
        <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="overflow-hidden">
            <div class="">
                <div class="filter-tab-rgt-section">
                    <div class="filter-container singleicon-filter-container">
                        <div class="new-filter-menu box-shadow-card" [ngClass]="{'open': filterMenuOpened}">
                            <div class="filters-heading" fxLayout="row" fxLayoutAlign="space-between center">
                                <h4>Filters</h4>
                                <div class="button-grp">
                                    <a href="javascript:void(0)" mat-raised-button
                                        class="btn-custom-primary-filter mr-2">{{'APPLY'|translate}}</a>
                                    <a href="javascript:void(0)" mat-raised-button
                                        class="btn-custom-secondary-filter">{{'RESET'|translate}}</a>
                                </div>
                            </div>
                            <div class="filter-forms">
                                <form [formGroup]="riskImpctFltrForm" novalidate>
                                    <div fxLayout="row wrap">
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-select [formGroup]="riskImpctFltrForm" controlName="itemHsCode"
                                                placeholder="{{'Name'|translate}}"></mcn-select>
                                        </div>
                                        <div fxFlex="2"></div>
                                        <div fxFlex="32" class="ps-rel">
                                            <mcn-select [formGroup]="riskImpctFltrForm" controlName="country"
                                                placeholder="{{'Description'|translate}}"></mcn-select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!openResearchCommodity">
                    <mat-card class="p-3 ml-3 my-3 user-individual-card user-individual-card-mr"
                        *ngFor="let details of opmReportsList">
                        <div fxLayout="column wrap" class="ml-5">
                            <div fxLayout="row">
                                <div fxFlex="20" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">{{details.name}}</h6>
                                    <p class="preview-label">{{'Name'|translate}}</p>
                                </div>
                                <div fxFlex="75" class="mt-2">
                                    <h6 class="preview-content font-semi-bold">{{details.description}}</h6>
                                    <p class="preview-label">{{'Description'|translate}}</p>
                                </div>
                                <div fxFlex="5" class="mt-2">
                                    <ul fxLayout="row">
                                        <li class="edit">
                                            <i class="icon-black-edit" appCustomTooltip title="{{'Edit'|translate}}"
                                                (click)="GetComplianceReports(details.name)"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <mcn-pagination [totalCount]="this.pageCount" (OnChange)="getNextReportsList($event)">
                    </mcn-pagination>
                </div>

            </div>
        </div>
    </div>
</div>