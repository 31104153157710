import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NVDBHistoricalDataModel } from '../../models/NVDBHistoricalDataModel';
import { NVDBAppliedTariffDataModel } from '../../models/NVDBAppliedTariffDataModel';
import { QueryStringParameter, SuccessErrMsgs } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';

@Injectable()
export class NVDBHistoricalDataService {
  public nvdbHistoricalDataServiceDocument: ServiceDocument<NVDBHistoricalDataModel>
    = new ServiceDocument<NVDBHistoricalDataModel>();

  public nvdbAppliedTariffServiceDocument: ServiceDocument<NVDBAppliedTariffDataModel>
    = new ServiceDocument<NVDBAppliedTariffDataModel>();

  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private toasterService: ToasterService,
    public mcnConfig: McnConfig,
    public rmsConfig: RmsConfig) {
  }

  public listNVDBHistoricalData(
    expression: string,
    aliasName: string,
    pageIndex: number = 1,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = 1,
    isDescending: boolean = true): Observable<ServiceDocument<NVDBHistoricalDataModel>> {

    const filterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };

    return this.nvdbHistoricalDataServiceDocument.list(
      this.rmsConfig.listNVDBHistoricalData, filterModel).pipe(map((response) => response),
        catchError(() => throwError({
          msg: this.NVDBHistoricalDataHandleError(), ngNavigationCancelingError: true
        })));
  }

  public openNVDBAppliedTariffData(
    decId: number
  ): Observable<ServiceDocument<NVDBAppliedTariffDataModel>> {
    return this.nvdbAppliedTariffServiceDocument.open(
      this.rmsConfig.openNVDBAppliedTraiffData, new HttpParams()
        .set(QueryStringParameter.decId, decId.toString())).pipe(map((response) => response),
          catchError(() => throwError({
            msg: this.NVDBHistoricalDataHandleError(), ngNavigationCancelingError: true
          })));
  }


  public openViewOutlierData(
    decId: number,
    tariffCode: string
  ): Observable<ServiceDocument<NVDBHistoricalDataModel>> {
    return this.nvdbHistoricalDataServiceDocument.open(
      this.rmsConfig.openViewOutlierData, new HttpParams()
        .set(QueryStringParameter.decId, decId.toString())
        .set(QueryStringParameter.tariffCode, tariffCode.toString())).pipe(map((response) => response),
          catchError(() => throwError({
            msg: this.NVDBHistoricalDataHandleError(), ngNavigationCancelingError: true
          })));
  }


  public NVDBHistoricalDataHandleError() {
    return this.toasterService.Error(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
  }
}
