import { Injectable } from '@angular/core';
import { GovtAuthorityModel } from '../../registartionProcess/userGovAuthorityRegistration/govtAuthorityInfo/models/GovtAuthorityInfoModel';
import { DepartmentModel } from './departmentModel';
import { AddressData, SubmitModel } from './submitModel';
import {
  IorganizationInfo, IfileData, IotherInfo, IportDetails, IprofileDetails,
  IstakeHolderDetails, PersonalInfo, AccountInfo, FormData, IaddressInfo, IbankDetails, IcustomStationDetails,
} from './wizardDataModel';

@Injectable()
export class FormDataService {
  public deptEnable: boolean;
  public idName: boolean;
  public Gender: boolean;
  public profileResponse: any;
  public formData: FormData = new FormData();
  public submitModel: SubmitModel = new SubmitModel();
  public myProfile: SubmitModel = new SubmitModel();
  public userProfiles: IstakeHolderDetails[] = [];
  public registerUser: SubmitModel = new SubmitModel();
  public addressData: AddressData = new AddressData();
  public isaccountInfoValid = false;
  public isaddressInfoValid = false;
  public isorganizationInfoValid = false;
  public isotherInfoValid = false;
  public ispersonalInfoValid = false;
  public setIdName(name: any) {
    this.idName = name;
  }
  public getIdName() {
    return this.idName;
  }

  public setGender(name: any) {
    this.Gender = name;
  }
  public getGender() {
    return this.Gender;
  }

  public getaccountInfo(): AccountInfo {
    const accountInfo: AccountInfo = {
      emailId: this.submitModel.emailID,
      password: this.submitModel.password,
      confirmPassword: this.submitModel.confirmPassword,
      altEmailId: this.submitModel.altEmailId,
    };
    return accountInfo;
  }
  public setaccountInfo(data: AccountInfo) {
    this.isaccountInfoValid = true;
    this.submitModel.emailID = data.emailId;
    this.submitModel.confirmPassword = data.confirmPassword;
    this.submitModel.password = data.password;
    this.submitModel.altEmailId = data.altEmailId;
  }

  public getpersonalInfo(): PersonalInfo {
    const personalInfo: PersonalInfo = {
      firstName: this.submitModel.firstName,
      lastName: this.submitModel.lastName,
      DocTypeVal: this.formData.DocTypeVal,
      gender: this.submitModel.gender,
      IdType: this.submitModel.IdType,
      IdNumber: this.submitModel.IdNumber,
      idDetails: this.formData.idDetails,
      uploadedDocs: this.formData.uploadedDocs,
      isMaster: this.submitModel.isMaster,
      emailId: this.submitModel.emailID,
    };
    return personalInfo;
  }

  public getIsMaster(): boolean {
    return this.submitModel.isMaster;
  }
  public setpersonalInfo(data: PersonalInfo) {
    this.submitModel.firstName = data.firstName;
    this.submitModel.lastName = data.lastName;
    this.submitModel.IdNumber = data.idDetails.idNumber;
    this.submitModel.IdType = data.idDetails.idType;
    this.submitModel.gender = data.gender;
    this.submitModel.isMaster = data.isMaster;
    this.submitModel.emailID = (data.emailId == null || data.emailId === '') ? this.submitModel.emailID : data.emailId;
  }

  public getaddressInfo(): IaddressInfo {
    const addressInfo: IaddressInfo = {
      country: this.submitModel.country,
      state: this.submitModel.state,
      city: this.submitModel.city,
      pin: this.submitModel.pin,
      countryname: this.submitModel.countryname,
      statename: this.submitModel.statename,
      pincode: this.submitModel.pincode,
      cityname: this.submitModel.cityname,
      addressLine1: this.submitModel.addressLine1,
      addressLine2: this.submitModel.addressLine2,
      mobCode: this.submitModel.mobCode,
      mobNumber: this.submitModel.mobNumber,
      primaryTelCode: this.submitModel.primaryTelCode,
      primaryTelNumber: this.submitModel.primaryTelNumber,
      altCode: this.submitModel.altCode,
      altNumber: this.submitModel.altNumber,
      faxCode: this.submitModel.faxCode,
      faxNumber: this.submitModel.faxNumber,
      primaryTeleCodeValue: this.submitModel.primaryTeleCodeValue,
      mobTeleCodeValue: this.submitModel.mobTeleCodeValue,
      altrTeleCodeValue: this.submitModel.altrTeleCodeValue,
      faxTeleCodeValue: this.submitModel.faxTeleCodeValue,
    };
    return addressInfo;
  }
  public setaddressInfo(data: IaddressInfo) {
    this.isaddressInfoValid = true;
    this.submitModel.country = data.country;
    this.submitModel.state = data.state;
    this.submitModel.city = data.city;
    this.submitModel.pin = data.pin;
    this.submitModel.addressLine1 = data.addressLine1;
    this.submitModel.addressLine2 = data.addressLine2;
    this.submitModel.mobCode = data.mobCode;
    this.submitModel.mobNumber = data.mobNumber;
    this.submitModel.primaryTelCode = data.primaryTelCode;
    this.submitModel.primaryTelNumber = data.primaryTelNumber;
    this.submitModel.countryname = data.countryname;
    this.submitModel.statename = data.statename;
    this.submitModel.pincode = data.pincode;
    this.submitModel.cityname = data.cityname;
    this.submitModel.altCode = data.altCode;
    this.submitModel.altNumber = data.altNumber;
    this.submitModel.faxCode = data.faxCode;
    this.submitModel.faxNumber = data.faxNumber;
    this.submitModel.primaryTeleCodeValue = data.primaryTeleCodeValue;
    this.submitModel.mobTeleCodeValue = data.mobTeleCodeValue;
    this.submitModel.altrTeleCodeValue = data.altrTeleCodeValue;
    this.submitModel.faxTeleCodeValue = data.faxTeleCodeValue;
  }

  public getotherInfo(): IotherInfo {
    const otherInfo: IotherInfo = {
      bankDetails: this.formData.bankDetails,
      customStationId: this.submitModel.customStationId,
      registeredCSId: this.submitModel.registeredCSId,
      registeredCSName: this.submitModel.registeredCSName,
    };
    return otherInfo;
  }
  public setotherInfo(data: IotherInfo) {
    this.isotherInfoValid = true;
    this.submitModel.customStationId = data.customStationId;
    this.submitModel.registeredCSId = data.registeredCSId;
    this.submitModel.registeredCSName = data.registeredCSName;
  }

  public getorganizationInfo(): IorganizationInfo {
    const organizationInfo: IorganizationInfo = {
      regAuthority: this.submitModel.regAuthority,
      businessEntity: this.submitModel.businessEntity,
      organizationName: this.submitModel.organizationName,
      regAuthorityName: this.submitModel.regAuthorityName,
      businessEntityId: this.submitModel.businessEntityId,
      brn: this.submitModel.brn,
      doctypval: '',
      gstNumber: this.submitModel.gstNumber,
      OrguploadedDocs: this.formData.OrguploadedDocs,
    };
    return organizationInfo;
  }
  public setorganizationInfo(data: IorganizationInfo) {
    this.isorganizationInfoValid = true;
    this.submitModel.regAuthority = data.regAuthority;
    this.submitModel.businessEntity = data.businessEntity;
    this.submitModel.brn = data.brn;
    this.submitModel.gstNumber = data.gstNumber;
    this.submitModel.organizationName = data.organizationName;
    this.submitModel.regAuthorityName = data.regAuthorityName;
    this.submitModel.businessEntityId = data.businessEntityId;
  }

  public getSubmitModelData(): SubmitModel {
    return this.submitModel;
  }

  public setCounterValue(value: boolean) {
    this.submitModel.isCustomCounter = value;
    this.registerUser.isCustomCounter = value;
  }

  public setDepartment(name: any) {
    this.submitModel.departmentName = name;
  }

  public setDepartmentId(id: any) {
    this.submitModel.departmentId = id;
  }

  public setStakeHolderType(type: string) {
    this.submitModel.stakeHoldertype = type;
  }
  public getStakeHolderType() {
    return this.submitModel.stakeHoldertype;
  }

  public setBanks(bank: IbankDetails): IbankDetails[] {
    this.submitModel.banks.push(bank);
    return this.submitModel.banks;
  }
  public getBanks(): IbankDetails[] {
    return this.submitModel.banks;
  }

  public setPorts(ports: IportDetails[], clearExistingPorts: boolean) {
    this.submitModel.associatedports = ports;
  }
  public getPorts(): IportDetails[] {
    return this.submitModel.associatedports;
  }

  public setCustomStation(customStations: IcustomStationDetails[], clearExistingCustomStations: boolean) {
    this.submitModel.associatedCustomStations = [];
    this.submitModel.associatedCustomStations = customStations;
  }
  public getCustomStation(): IcustomStationDetails[] {
    return this.submitModel.associatedCustomStations;
  }

  public resetSubmitData() {
    this.submitModel = new SubmitModel();
    this.myProfile = new SubmitModel();
    this.registerUser = new SubmitModel();
  }

  public getDepartmentrequired() {
    return this.submitModel.deptRequired;
  }

  public setOrgFiles(docs: IfileData[]): IfileData[] {
    this.submitModel.orgFiles = docs;
    return this.submitModel.orgFiles;
  }
  public setIndividualFiles(docs: IfileData[]): IfileData[] {
    this.submitModel.indvidualFiles = docs;
    return this.submitModel.indvidualFiles;
  }

  public setStakeHolder(stakeholder: IstakeHolderDetails[], clearExistingPorts: boolean) {
    this.submitModel.associatedStakeHolders = [];
    this.submitModel.associatedStakeHolders = stakeholder;
  }
  public getStakeHolder(): IstakeHolderDetails[] {
    return this.submitModel.associatedStakeHolders;
  }

  public setProfiles(profileDetails: IprofileDetails[], clearExistingProfiles: boolean) {
    this.submitModel.associatedProfiles = [];
    this.submitModel.associatedProfiles = profileDetails;
  }
  public getProfiles(): IprofileDetails[] {
    return this.submitModel.associatedProfiles;
  }

  public getDepartments(): DepartmentModel[] {
    return this.submitModel.departments;
  }
  public setDepartments(data: DepartmentModel[]) {
    this.submitModel.departments = data;
  }

  public getGovtAuthorityInfo(): GovtAuthorityModel {
    const govtAuthorityModel: GovtAuthorityModel = {
      regnStationId: this.submitModel.registeredCSId,
      regnStationName: this.submitModel.registeredCSName,
      regnStationCode: this.submitModel.regnStationCode,
      regnAgencyCode: this.submitModel.regnAgencyCode,
      regnAgencyName: this.submitModel.regnAgencyName,
      gstNo: this.submitModel.gstNumber,
      deptRequired: this.submitModel.deptRequired,
      description: this.submitModel.description,
    };

    return govtAuthorityModel;
  }

  public setGovtAuthorityInfo(data: GovtAuthorityModel) {
    this.submitModel.registeredCSId = data.regnStationId;
    this.submitModel.registeredCSName = data.regnStationName;
    this.submitModel.regnStationCode = data.regnStationCode;
    this.submitModel.regnAgencyCode = data.regnAgencyCode;
    this.submitModel.regnAgencyName = data.regnAgencyName;
    this.submitModel.gstNumber = data.gstNo;
    this.submitModel.deptRequired = data.deptRequired;
    this.submitModel.description = data.description;
  }

  // addUser For Organaization, Govt,Customs
  public setUser(orgId: number, orgName: string, model: any, profileDetails: any[], type: any, customStations: any[], deptRequired: boolean = null) {
    this.registerUser.organizationId = orgId;
    this.registerUser.organizationName = orgName;
    this.registerUser.stakeHoldertype = type;
    this.registerUser.associatedProfiles = profileDetails;
    this.registerUser.IdType = model.IdType;
    this.registerUser.departmentId = model.departmentId;
    this.registerUser.designationTypeId = model.designationTypeId;
    this.registerUser.gender = model.genderId;
    this.registerUser.IdNumber = model.idNumber;
    this.registerUser.firstName = model.firstName;
    this.registerUser.lastName = model.lastName;
    this.registerUser.emailID = model.emailId;
    this.registerUser.gstNumber = model.gsTNumber;
    this.registerUser.mobNumber = model.mobileNumber;
    this.registerUser.mobCode = model.mobileCountryCode;
    this.registerUser.altrTeleCodeValue = model.residenceCountryCode;
    this.registerUser.altNumber = model.residencyNumber;
    this.registerUser.primaryTelCode = model.businessCountryCode;
    this.registerUser.primaryTelNumber = model.businessNumber;
    this.registerUser.faxCode = model.faXCountryCode;
    this.registerUser.faxNumber = model.faxNumber;
    this.registerUser.altEmailId = null;
    this.registerUser.isMaster = model.isMasterUser;
    this.registerUser.country = null;
    this.registerUser.state = null;
    this.registerUser.city = null;
    this.registerUser.pin = null;
    this.registerUser.addressLine1 = null;
    this.registerUser.addressLine2 = null;
    this.registerUser.idocTypeVal = null;
    this.registerUser.customStationId = [];
    this.registerUser.registeredCSId = null;
    this.registerUser.odocTypeVal = null;
    this.registerUser.businessEntityId = null;
    this.registerUser.regAuthority = null;
    this.registerUser.businessEntity = null;
    this.registerUser.brn = null;
    this.registerUser.gstNumber = null;
    this.registerUser.registeredCSName = null;
    this.registerUser.countryname = null;
    this.registerUser.statename = null;
    this.registerUser.cityname = null;
    this.registerUser.pincode = null;
    this.registerUser.regnStationCode = null;
    this.registerUser.regnAgencyName = null;
    this.registerUser.deptRequired = deptRequired;
    this.registerUser.regnAgencyCode = null;
    this.registerUser.description = null;
    this.registerUser.regAuthorityName = null;
    this.registerUser.mobTeleCodeValue = null;
    this.registerUser.primaryTeleCodeValue = null;
    this.registerUser.altrTeleCodeValue = null;
    this.registerUser.faxTeleCodeValue = null;
    this.registerUser.indvidualFiles = [];
    this.registerUser.orgFiles = [];
    this.registerUser.banks = [];
    this.registerUser.associatedports = [];
    this.registerUser.associatedCustomStations = customStations;
    this.registerUser.associatedStakeHolders = [];
    this.registerUser.departments = [];
    this.registerUser.department = null;
    this.registerUser.modifiedDate = null;
    this.registerUser.designationTypeId = null;
    this.registerUser.registrationAuthority = [];
    this.registerUser.businessAuthority = [];
  }

  public setUSerInfo(model: any) {
    const userDetails: any = {
      userId: model.userId,
      personalId: model.personalId,
      contactId: model.pontactId,
    };
    this.registerUser.userDetails = userDetails;
  }

  public getUserInfo(): SubmitModel {
    return this.registerUser;
  }

  public setMyProfile(data): SubmitModel {

    this.profileResponse = data;
    const isAccountInfoNull: boolean = data.accountInfo == null;
    this.myProfile.emailID = isAccountInfoNull ? null : data.accountInfo.emailId;
    this.myProfile.altEmailId = isAccountInfoNull ? null : data.accountInfo.alternateEmailId;

    const isPersonalInfoNull: boolean = data.personalInfo == null;
    this.myProfile.firstName = isPersonalInfoNull ? null : data.personalInfo.firstName;
    this.myProfile.lastName = isPersonalInfoNull ? null : data.personalInfo.lastName;
    this.myProfile.gender = isPersonalInfoNull ? null : data.personalInfo.genderId;
    this.myProfile.nationality = isPersonalInfoNull ? null : data.personalInfo.nationality;
    this.myProfile.IdType = isPersonalInfoNull ? null : data.personalInfo.idType;
    this.myProfile.gstNumber = isPersonalInfoNull ? null : data.personalInfo.gSTNo;
    this.myProfile.IdNumber = isPersonalInfoNull ? null : data.personalInfo.idNumber;
    this.myProfile.isMaster = isPersonalInfoNull ? null : data.personalInfo.isMaster;
    this.myProfile.stakeHoldertype = isPersonalInfoNull ? null : data.personalInfo.referenceTypeId;
    this.myProfile.departmentId = isPersonalInfoNull ? null : data.personalInfo.departmentId;

    const isAddressInfoNull: boolean = data.addressInfo == null;

    this.myProfile.countryname = isAddressInfoNull ? null : data.addressInfo.country != null ? data.addressInfo.country.countryName : null;
    this.myProfile.statename = isAddressInfoNull ? null : data.addressInfo.state != null ? data.addressInfo.state.stateName : null;
    this.myProfile.cityname = isAddressInfoNull ? null : data.addressInfo.city != null ? data.addressInfo.city.cityName : null;
    this.myProfile.pincode = isAddressInfoNull ? null : data.addressInfo.pin != null ? data.addressInfo.pin.postalCode : null;

    this.myProfile.addressLine1 = isAddressInfoNull ? null : data.addressInfo.addressLine1;
    this.myProfile.addressLine2 = isAddressInfoNull ? null : data.addressInfo.addressLine2;

    this.myProfile.city = isAddressInfoNull ? null : data.addressInfo.city == null ? null : data.addressInfo.city;
    this.myProfile.country = isAddressInfoNull ? null : data.addressInfo.country == null ? null : data.addressInfo.country;
    this.myProfile.state = isAddressInfoNull ? null : data.addressInfo.state == null ? null : data.addressInfo.state;
    this.myProfile.pin = isAddressInfoNull ? null : data.addressInfo.pin == null ? null : data.addressInfo.pin;

    this.myProfile.mobCode = isAddressInfoNull ? null : Number(data.addressInfo.mobileCountryCode);
    this.myProfile.mobNumber = isAddressInfoNull ? null : data.addressInfo.mobileNumber;
    this.myProfile.primaryTelCode = isAddressInfoNull ? null : Number(data.addressInfo.businessCountryCode);
    this.myProfile.primaryTelNumber = isAddressInfoNull ? null : data.addressInfo.businessNumber;
    this.myProfile.altCode = isAddressInfoNull ? null : Number(data.addressInfo.residenceCountryCode);
    this.myProfile.altNumber = isAddressInfoNull ? null : data.addressInfo.residencyNumber;
    this.myProfile.faxNumber = isAddressInfoNull ? null : data.addressInfo.faxNo;
    this.myProfile.faxCode = isAddressInfoNull ? null : Number(data.addressInfo.faxCountryCode);

    this.myProfile.registeredCSName = data.otherInfo == null ? null : data.otherInfo.registeredCustomStation;
    this.myProfile.registeredCSId = data.otherInfo == null ? null : data.otherInfo.registeredCustomStationId;
    this.myProfile.regnStationCode = data.otherInfo == null ? null : data.otherInfo.registeredCustomStationCode;

    const isOrganziationInfo: boolean = data.organizationInfo == null;
    this.myProfile.registrationAuthority = isOrganziationInfo ? null : data.organizationInfo.registrationAuthority;
    this.myProfile.businessAuthority = isOrganziationInfo ? null : data.organizationInfo.businessAuthority;
    this.myProfile.organizationName = isOrganziationInfo ? null : data.organizationInfo.organizationName;
    this.myProfile.regnAgencyName = isOrganziationInfo ? null : data.organizationInfo.organizationName;
    this.myProfile.regnAgencyCode = isOrganziationInfo ? null : data.organizationInfo.code;
    this.myProfile.organizationId = isOrganziationInfo ? null : data.organizationInfo.organizationId;
    this.myProfile.brn = isOrganziationInfo ? null : data.organizationInfo.brn;
    this.myProfile.gstNumber = isOrganziationInfo ? null : data.organizationInfo.gstNo;
    this.myProfile.regAuthority = this.myProfile.registrationAuthority != null ? this.myProfile.registrationAuthority.typeId : null;
    this.myProfile.businessEntity = this.myProfile.businessAuthority != null ? this.myProfile.businessAuthority.typeId : null;

    this.myProfile.associatedStakeHolders = data.businessStackHolderCategory == null ? [] : data.businessStackHolderCategory;

    this.myProfile.associatedProfiles = data.profileDetails == null ? [] : data.profileDetails;

    this.myProfile.associatedports = data.portDetails == null ? [] : data.portDetails;

    this.myProfile.associatedCustomStations = data.customStationDetails == null ? [] : data.customStationDetails;

    this.myProfile.banks = data.bankDetails == null ? [] : data.bankDetails;

    this.myProfile.departments = data.departmentDetails == null ? [] : data.departmentDetails;

    this.myProfile.department = data.department == null ? null : data.department;
    this.myProfile.modifiedDate = data.modifiedDate == null ? null : data.modifiedDate;
    return this.myProfile;
  }

  public setOrganizationId(organizationId: any) {
    this.submitModel.organizationId = organizationId;
  }

  public getOrganizationId(): any {
    return this.submitModel.organizationId;
  }

  public getMyProfile() {
    return this.myProfile;
  }

  public getProfileResponse(): any {
    return this.profileResponse;
  }

  public getAddressData() {
    return this.addressData;
  }

  public setMyProfileToSubmitModel(type: any): SubmitModel {
    this.submitModel.organizationId = null;
    this.submitModel.organizationName = this.myProfile.organizationName;
    this.submitModel.stakeHoldertype = this.myProfile.stakeHoldertype;
    this.submitModel.associatedProfiles = this.myProfile.associatedProfiles;
    this.submitModel.IdType = this.myProfile.IdType;
    this.submitModel.departmentId = null;
    this.submitModel.designationTypeId = null;
    this.submitModel.gender = this.myProfile.gender;
    this.submitModel.IdNumber = this.myProfile.IdNumber;
    this.submitModel.firstName = this.myProfile.firstName;
    this.submitModel.lastName = this.myProfile.lastName;
    this.submitModel.emailID = this.myProfile.emailID;
    this.submitModel.gstNumber = this.myProfile.gstNumber;
    this.submitModel.mobNumber = this.myProfile.mobNumber;
    this.submitModel.mobCode = this.myProfile.mobCode;
    this.submitModel.altrTeleCodeValue = null;
    this.submitModel.altCode = this.myProfile.altCode;
    this.submitModel.altNumber = this.myProfile.altNumber;
    this.submitModel.primaryTelCode = this.myProfile.primaryTelCode;
    this.submitModel.primaryTelNumber = this.myProfile.primaryTelNumber;
    this.submitModel.faxCode = this.myProfile.faxCode;
    this.submitModel.faxNumber = this.myProfile.faxNumber;
    this.submitModel.altEmailId = this.myProfile.altEmailId;
    this.submitModel.isMaster = null;
    this.submitModel.country = this.myProfile.country;
    this.submitModel.state = this.myProfile.state;
    this.submitModel.city = this.myProfile.city;
    this.submitModel.pin = this.myProfile.pin;
    this.submitModel.addressLine1 = this.myProfile.addressLine1;
    this.submitModel.addressLine2 = this.myProfile.addressLine2;
    this.submitModel.idocTypeVal = null;
    this.submitModel.customStationId = [];
    this.submitModel.registeredCSId = null;
    this.submitModel.odocTypeVal = null;
    this.submitModel.businessEntityId = null;
    this.submitModel.regAuthority = this.myProfile.registrationAuthority != null ? this.myProfile.registrationAuthority.typeId : null;
    this.submitModel.businessEntity = this.myProfile.businessAuthority != null ? this.myProfile.businessAuthority.typeId : null;
    this.submitModel.brn = this.myProfile.brn;
    this.submitModel.gstNumber = this.myProfile.gstNumber;
    this.submitModel.registeredCSName = this.myProfile.registeredCSName;
    this.submitModel.countryname = this.myProfile.countryname;
    this.submitModel.statename = this.myProfile.statename;
    this.submitModel.cityname = this.myProfile.cityname;
    this.submitModel.pincode = this.myProfile.pincode;
    this.submitModel.regnStationCode = null;
    this.submitModel.regnAgencyName = null;
    this.submitModel.deptRequired = null;
    this.submitModel.regnAgencyCode = null;
    this.submitModel.description = null;
    this.submitModel.regAuthorityName = null;
    this.submitModel.mobTeleCodeValue = null;
    this.submitModel.primaryTeleCodeValue = null;
    this.submitModel.altrTeleCodeValue = null;
    this.submitModel.faxTeleCodeValue = null;
    this.submitModel.indvidualFiles = this.myProfile.indvidualFiles;
    this.submitModel.orgFiles = this.myProfile.orgFiles;
    this.submitModel.banks = this.myProfile.banks;
    this.submitModel.associatedports = this.myProfile.associatedports;
    this.submitModel.associatedStakeHolders = this.myProfile.associatedStakeHolders;
    this.submitModel.departments = this.myProfile.departments;
    this.submitModel.department = null;
    this.submitModel.modifiedDate = this.myProfile.modifiedDate;
    this.submitModel.registrationAuthority = this.myProfile.registrationAuthority;
    this.submitModel.businessAuthority = this.myProfile.businessAuthority;
    this.submitModel.associatedCustomStations = this.myProfile.associatedCustomStations;
    return this.submitModel;
  }
}
