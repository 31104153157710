<div class="rndm-scltn-cnfg-list" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="column Wrap">
        <div fxLayout="row wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="80" class="main-title pb-0">
                        <span>{{ 'MarketResearchDataForCommodityDetails' | translate }}</span>
                    </h2>
                    <h6 fxFlex="20" class="preview-content font-semi-bold">
                        <span class="status-tag created">
                            {{this.nvdbCommodityDetails?.workflowInstance?.stateName}}
                        </span>
                    </h6>
                </div>
                <div>
                    <hr class="hr-line">
                </div>
            </div>
        </div>
        <div fxLayout="column">
            <form [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm" novalidate>
                <div mat-dialog-content>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name"
                                [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                controlName="typeId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{ 'PricingDataSource' | translate }}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [disabled]="this.disablePriceDataSource"
                                [optionParameterValue]="this.priceingDataSource" [moduleName]="this.moduleNameRms">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm.controls.typeId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name"
                                [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                controlName="valuationMethodId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{ 'ValuationMethod' | translate }}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [disabled]="this.disableValuationMethod"
                                [optionParameterValue]="this.valuationMethod" [moduleName]="this.moduleNameRms">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm.controls.valuationMethodId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-date [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                placeholder="{{ 'Date' | translate }}" [completeDisable]="true" [disabled]="true"
                                controlName="nvdbMarketComDate">
                            </mcn-date>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-autocomplete ngDefaultControl placeholder="{{'ItemHSCodes' | translate}}"
                                [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                [formControl]="nvdbCommodityServiceDocument?.dataProfile.profileForm.controls.tariffItemId"
                                [apiUrl]="this.rmsConfig.autoTariffItems" optionKey="tariffItemId"
                                optionValue="tariffCode" Class="astric-star" [optionsData]="tariffDataData"
                                (OnChange)="getTariffDescription($event)" [disabled]="this.disableControl"
                                (OnLeave)="OnLeave($event)">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.tariffItemId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-textarea placeholder="{{'TariffDescription' | translate}}" [isFormGroup]="false"
                                [value]="this.tariffItemDescription" [disabled]="true">
                            </mcn-textarea>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-textarea [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm"
                                placeholder="{{'Description' | translate}}" maxlength="512"
                                [disabled]="this.disableControl" controlName="description">
                            </mcn-textarea>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>

                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-input [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm"
                                placeholder="{{'Quantity' | translate}}" maxlength="16" Class="astric-star"
                                [disabled]="this.disableControl" controlName="quantity">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.quantity">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-autocomplete [optionsData]="quantityUOMReferenceData"
                                [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                ngDefaultControl optionKey="quantityUnitId" optionValue="unitName"
                                [formControl]="nvdbCommodityServiceDocument.dataProfile?.profileForm.controls.quantityUnitId"
                                [apiUrl]="this.rmsConfig.quantityUnitApiUrl"
                                placeholder="{{ 'QuantityUOM' | translate }}" [disabled]="this.disableControl"
                                class="astric-star">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.quantityUnitId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-autocomplete [optionsData]="countryData"
                                [formGroup]="nvdbCommodityServiceDocument?.dataProfile?.profileForm" ngDefaultControl
                                optionKey="countryId" optionValue="countryName"
                                [formControl]="nvdbCommodityServiceDocument.dataProfile?.profileForm.controls.countryId"
                                [apiUrl]="this.rmsConfig.countryApiUrl" placeholder="{{'CountryOfOrigin' | translate}}"
                                [disabled]="this.disableControl" class="fx-selectBox astric-star">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.countryId">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-input [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm"
                                placeholder="{{'MinValue' | translate}}" maxlength="16" controlName="minValue"
                                [disabled]="this.disableControl" (OnBlur)="minMaxCheck($event)">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.minValue">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-input [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm"
                                placeholder="{{'MaxValue' | translate}}" maxlength="16" controlName="maxValue"
                                [disabled]="this.disableControl" (OnBlur)="minMaxCheck($event)">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.maxValue">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-input [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                placeholder="{{'UnitPrice' | translate}}" maxlength="19" Class="astric-star"
                                [disabled]="this.disableControl" controlName="unitPrice">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.unitPrice">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-date [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                placeholder="{{'FromDate' | translate}}" Class="astric-star" controlName="fromDate"
                                [completeDisable]="disableControl" [disabled]="this.disableFromDate"
                                (OnChange)="checkDateConditions($event)">
                            </mcn-date>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm.controls.fromDate">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                            <mcn-date [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm"
                                placeholder="{{'ToDate' | translate}}" controlName="toDate"
                                [completeDisable]="disableEnableToDate" [disabled]="this.disableToDate"
                                (OnChange)="checkDateConditions($event)">
                            </mcn-date>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm.controls.toDate">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel mt-15">
                            <div fxFlex="2" fxFlex.gt-xs="2" class="p-2 ps-rel checkbox-mr">
                                <mcn-checkbox [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile?.profileForm"
                                    controlName="infiniteDate" optionalId="1" (OnCheck)="checkInfinite()"
                                    [disabled]="this.disableControl" ngDefaultControl>
                                </mcn-checkbox>
                            </div>
                            <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                                <span>{{'InfiniteDate' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="30" fxFlex.gt-xs="66" class="p-2 ps-rel" *ngIf="showHideRemarksControl">
                            <mcn-textarea [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm"
                                placeholder="{{'Remarks' | translate}}" maxlength="512"
                                [disabled]="this.disableRemarksControl"
                                [ngClass]="{'astric-star': mandatorySymbol == true, '': mandatorySymbol == false }"
                                controlName="remarks">
                            </mcn-textarea>
                            <mcn-error-messages customErrorMsg=""
                                [control]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm.controls.remarks">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div *ngIf="showHideSpecificationSublist">
                        <mat-expansion-panel class="p-1 my-3">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'SpecificationsList' | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="column"  fxLayoutAlign="center center"
                                 *ngIf="this.nvdbSpecificationList?.length=='0'">
                                <img src="../../../../../../assets/images/No-Records-Found.png" alt="No Records" />
                                <span class="no-records-found">{{'NoRecordsFound' | translate}}</span>
                            </div>
                            <mat-card class="card-grey-border p-3 my-2" *ngFor="let details of nvdbSpecificationList"
                                fxLayout="column">
                                <div fxLayout="column" fxFlex="100">
                                    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center">
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.specificationDetails?.code}}
                                            </h6>
                                            <p class="preview-label">{{'SpecificationCode' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.specificationDetails?.specificationName?.description}}
                                            </h6>
                                            <p class="preview-label">{{'SpecificationType' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.startDate | date}}
                                            </h6>
                                            <p class="preview-label">{{'StartDate' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.endDate | date}}
                                            </h6>
                                            <p class="preview-label">{{'EndDate' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2" *ngIf="this.showHideControl">
                                            <ul fxLayout="row">
                                                <li class="edit">
                                                    <i class="icon-black-edit" appCustomTooltip
                                                        title="{{'Edit' | translate}}"
                                                        (click)="editNVDBSpecification(details.tariffItemSpecId)">
                                                    </i>
                                                </li>
                                                <li class="ml-1 mr-1"></li>
                                                <li class="delete">
                                                    <i class="icon-black-delete" appCustomTooltip
                                                        title="{{'Delete' | translate}}"
                                                        (click)="deleteNVDBSpecification(details)">
                                                    </i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="100" class="mt-2">
                                        <p class="preview-content" style="white-space: normal;">
                                            {{details?.specificationDetails?.caption}}
                                        </p>
                                        <h6 class="preview-label">{{'Caption' | translate}}</h6>
                                    </div>
                                </div>
                            </mat-card>
                            <div *ngIf="this.showHideControl">
                                <span class="cur-pointer" (click)="newCommoditySpecificationPopup()">
                                    <i class="r-icon-plus"></i> {{'AddNew' | translate}}</span>
                            </div>
                        </mat-expansion-panel>
                    </div>
                    <div>
                        <div class="button-container">
                            <mcn-button-list class="mr-0 fxbtn-gap" (OnClick)="SubmitNVDBCommodity($event)"
                                [allowedActions]="this.nvdbCommodityServiceDocument?.dataProfile.actionService.allowedActions"
                                [formGroup]="this.nvdbCommodityServiceDocument?.dataProfile.profileForm">
                            </mcn-button-list>
                            <mcn-button type="button" cssClass="btn-custom-secondary" (OnClick)="cancel()"
                                text="{{'Cancel' | translate}}" class="ml-3">
                            </mcn-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>