import { HttpClient } from '@angular/common/http';
import {
  OnInit, Component, ViewChild, ElementRef, AfterViewInit, Input, ChangeDetectorRef, Output, EventEmitter, OnChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { Observable, fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import { StorageService } from '../../storageService/storageService';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'mcn-autocomplete',
  template: ` <mat-form-field [formGroup]="formGroup" class="{{cssClass}} material-auto-complete"
              floatLabel="auto">
              <input matInput id="txtautocomplete_{{optionalId}}{{placeholder | removeSpace}}"
               type="text" (keyup)="handleKeyupEvent($event.target.value)"
                (keypress)="handleKeyPress($event.target.value)" placeholder="{{placeholder}}"
                  [formControl]="formControl" [matAutocomplete]="auto"
                   [required]="required" #autoCompleteInput />
          </mat-form-field>
          <i class="autocomplete-icon autocomplete-iconPos" appCustomTooltip title="Please
          enter minimum 3 characters"></i>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
          (optionSelected)="onOptionSelect($event)">
           <div infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="10"
                [scrollWindow]="false"
                (scrolled)="onScroll()"
                [ngStyle]="{'max-height': (this.filteredOptions != null
                  && this.filteredOptions.length >= 10) ? '175px' : '',
                   'overflow-y': 'scroll','position': 'relative','max-width': 'none'}">

             <mat-option *ngFor="let option of this.filteredOptions" [value]="option[optionKey]"
             matTooltip="{{option[optionValue]}}">
              <span  style='text-align: center' *ngIf="this.isShowAdditionalOptionValue && (option[optionValue] === 'No Records Found' || option[optionValue] === this.inputvaliationMsg)">{{option[optionValue]}}</span>
              <span  style='text-align: center' *ngIf="this.isShowAdditionalOptionValue && !(option[optionValue] === 'No Records Found' || option[optionValue] === this.inputvaliationMsg)">{{option[optionValue]}} - {{option[additionalOptionValue]}} </span>
              <span  style='text-align: center' *ngIf="!this.isShowAdditionalOptionValue">{{option[optionValue]}}</span>
            </mat-option>
         </div>
        </mat-autocomplete>`,
})

export class McnAutoCompleteComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() formControl: FormControl;
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() optionsData: any;
  @Input() optionKey: any;
  @Input() optionValue: any;
  @Input() optionParameterKey: any = null;
  @Input() optionParameterValue: any = null;
  @Input() optionalParameterArray: any[] = [];
  @Input() cssClass: string;
  @Input() imageSrc: any;
  @Input() imageClass: any;
  @Input() minLength = 3;
  @Input() apiUrl: string;
  @Input() disabled: boolean;
  @Input() optionalId = '';
  @Input() isList = false;
  @Output() OnChange = new EventEmitter();
  @Output() OnKeyPress = new EventEmitter();
  @Output() OnLeave = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
  @Input() isShowAdditionalOptionValue = false;
  @Input() additionalOptionValue: any;

  public userData: string[];
  public keyword: any;
  public filterCount = 10;
  public filteredOptions: any[] = [];
  public infoObj: any = {};
  public pageIndex: number;
  public inputvaliationMsg: any;


  constructor(
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private storageService: StorageService,
  ) {
  }

  ngOnInit() {
    const self: McnAutoCompleteComponent = this;
    self.formControl.enable();
    this.pageIndex = 0;

    if (this.disabled) {
      self.formControl.disable();
    }
    self.filteredOptions = self.optionsData;
    this.refresh();
    if (self.formControl.value) {
      this.displayFn(self.formControl.value);
    }
  }

  private filter(val: string): any {
    const self: McnAutoCompleteComponent = this;
    return val ? self.optionsData.filter((option: any) => new RegExp(`^${val}`, 'gi')
      .test(option[self.optionValue])) : self.optionsData;
  }


  public handleKeyPress(event: any) {
    this.OnKeyPress.emit(this.autoCompleteInput.nativeElement.value);
  }

  public ngOnChanges(): void {
    const self: McnAutoCompleteComponent = this;
    if (!this.disabled) {
      self.formControl.enable();
    }
    if (this.disabled) {
      self.formControl.disable();
    }
    if (self.formControl.value) {
      this.displayFn(self.formControl.value);
    }
  }

  handleKeyupEvent(event: any) {
    this.OnLeave.emit(event);
  }


  private refresh() {
    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  ngAfterViewInit() {
    this.pageIndex = 0;
    const self: McnAutoCompleteComponent = this;
    fromEvent(this.autoCompleteInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        tap((event: any) => {
          const key: any = event.keyCode || event.charCode;
          if (key === 8 || key === 46) {
            this.pageIndex = 0;
            if (this.autoCompleteInput.nativeElement.value
              && this.autoCompleteInput.nativeElement.value.length >= this.minLength && this.pageIndex === 0) {
              if (/^([%]{0,3})$/.test(this.autoCompleteInput.nativeElement.value)) {
                this.loadData('');
              } else if (/[!@#$%^&*+\=\[\]{};':"\\|,<>\?]/.test(this.autoCompleteInput.nativeElement.value)) {
                this.bindNoRecordsInfo();
              } else if (/^[A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\s]*[A-Za-z0-9][-.]$/.test(this.autoCompleteInput.nativeElement.value)) {
                this.loadData(this.autoCompleteInput.nativeElement.value);
              }
            } else {
              this.optionsData = [];
              this.bindMinCharecterInfo();
            }
          }
        })
      ).subscribe();
    fromEvent(this.autoCompleteInput.nativeElement, 'keypress')
      .pipe(
        debounceTime(150),
        tap(() => {
          this.bindAutoCompleteData();
        })
      ).subscribe();
    fromEvent(this.autoCompleteInput.nativeElement, 'paste')
      .pipe(
        debounceTime(150),
        tap(() => {
          this.bindAutoCompleteData();
        })
      ).subscribe();
    fromEvent(this.autoCompleteInput.nativeElement, 'mouseenter')
      .pipe(
        debounceTime(150),
        tap(() => {
          this.pageIndex = 0;
          if (isNullOrUndefined(this.autoCompleteInput.nativeElement.value)
            || this.autoCompleteInput.nativeElement.value === '') {
            this.optionsData = [];
            this.bindMinCharecterInfo();
          }
        })
      ).subscribe();
    this.trigger.panelClosingActions
      .subscribe((e: any) => {
        this.formControl.setValue(null);
        self.filteredOptions = [];
        this.trigger.closePanel();
      });
  }

  public bindAutoCompleteData() {
    this.pageIndex = 0;
    if (this.autoCompleteInput.nativeElement.value
      && this.autoCompleteInput.nativeElement.value.length >= this.minLength && this.pageIndex === 0) {
      if (/^([%]{0,3})$/.test(this.autoCompleteInput.nativeElement.value)) {
        this.loadData('');
      } else if (/[!@#$%^&*+\=\[\]{};':"\\|,<>\?]/.test(this.autoCompleteInput.nativeElement.value)) {
        this.bindNoRecordsInfo();
      } else if (/^[A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\s]*[A-Za-z0-9]$/.test(this.autoCompleteInput.nativeElement.value)) {
        this.loadData(this.autoCompleteInput.nativeElement.value);
      }
    } else {
      this.optionsData = [];
      this.bindMinCharecterInfo();
    }
  }

  public displayFn(res: any) {
    const self: McnAutoCompleteComponent = this;
    this.refresh();
    if (this.optionsData && res) {
      const displayValue = self.optionsData.find(x => x[this.optionKey] === res);
      if (!isNullOrUndefined(displayValue)) {
        return displayValue[this.optionValue];
      }
      return null;
    }
    return null;
  }

  public onOptionSelect(event: any): any {
    if (event.option.value) {
      const self: McnAutoCompleteComponent = this;
      if (event.option.value === 0 || event.option.value === '0') {
        self.formControl.setValue(null);
      }
      const option: any = self.optionsData.find((x) => x[this.optionKey] === event.option.value);
      self.OnChange.emit({ event, options: option });
    }
  }


  loadData(word: any) {
    const apiUrl = this.getAPIUrl(word);
    this.getData(apiUrl).subscribe(res => {
      if (!isNullOrUndefined(res) && !isNullOrUndefined(this.bindData(res)) && this.bindData(res).length === 0) {
        this.bindNoRecordsInfo();
      } else {
        this.optionsData = [];
        this.refresh();
        this.setOptionData(res);
      }
    });
  }

  public onScroll() {
    if (!isNullOrUndefined(this.filteredOptions) && this.filteredOptions.length >= 10) {
      if (this.autoCompleteInput.nativeElement.value && this.autoCompleteInput.nativeElement.value.length >= this.minLength) {
        if (/^([%]{0,3})$/.test(this.autoCompleteInput.nativeElement.value)) {
          const apiUrlData = this.getAPIUrl('');
          this.getDataInfo(apiUrlData);
        } else if (/^[A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\s]*[A-Za-z0-9]$/.test(this.autoCompleteInput.nativeElement.value)) {
          const apiUrlData = this.getAPIUrl(this.autoCompleteInput.nativeElement.value);
          this.getDataInfo(apiUrlData);
        }
      } else {
        this.optionsData = [];
        this.bindMinCharecterInfo();
      }
    }
  }

  private getDataInfo(apiUrlData) {
    this.getData(apiUrlData).subscribe(res => {
      if (!isNullOrUndefined(res) && !isNullOrUndefined(this.bindData(res)) && this.bindData(res).length > 0) {
        this.refresh();
        this.setOptionData(res);
      }
    });
  }
  private getData(dataUrl: any): Observable<any> {
    return this.http.get(dataUrl);
  }


  private bindData(response: any) {
    const i = 0;
    if (!isNullOrUndefined(response) && response.length !== 0) {
      if (!this.isList) {
        this.userData = Object.keys(response);
        if (this.userData.length > 0) {
          if (!isNullOrUndefined(response[this.userData[i]])) {
            return response[this.userData[i]];
          }
          return [];
        } else {
          return [];
        }
      } else {
        return response ? response : [];
      }
    }
    return [];
  }

  private bindMinCharecterInfo() {
    this.infoObj[this.optionKey] = 0;
    this.infoObj[this.optionValue] = 'Enter minimum ' + this.minLength + ' characters..';
    this.optionsData = [];
    this.pageIndex = 0;
    this.optionsData.push(this.infoObj);
    this.filteredOptions = this.optionsData;
  }

  private bindNoRecordsInfo() {
    this.infoObj[this.optionKey] = 0;
    this.infoObj[this.optionValue] = 'No Records Found';
    this.pageIndex = 0;
    this.optionsData = [];
    this.optionsData.push(this.infoObj);
    this.filteredOptions = this.optionsData;
  }

  private setOptionData(response: any) {
    this.optionsData.splice(this.optionsData.findIndex(x => x[this.optionKey] === 0), 1);
    this.optionsData = this.optionsData.concat(this.bindData(response));
    this.filteredOptions = this.optionsData;
    this.refresh();
  }

  private getAPIUrl(word: string) {
    this.storageService.set('Auto', true);
    const i = 0;
    let url = this.apiUrl;
    url = this.apiUrl + word;
    if (this.optionParameterKey !== null && this.optionParameterValue !== null) {
      url = url + '&' + this.optionParameterKey + '=' + this.optionParameterValue;
    }
    if (this.optionalParameterArray.length > 0) {
      this.optionalParameterArray.forEach((res) => {
        const key = Object.keys(res);
        key.forEach((k) => {
          url += '&' + k + '=' + res[k];
        });
      });
    }

    this.pageIndex = this.pageIndex + 1;
    return url + '&isAutoComplete=' + true + '&pageIndex=' + this.pageIndex + '&filterCount=' + this.filterCount;
  }
}
