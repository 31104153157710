import { Injectable } from '@angular/core';
import { DateTimeAdapter } from 'ng-pick-datetime';

export interface MyData {
  exemptionLawRegulationId: number;
  exemptionId: number;
  tariffLineExemptionId: number;
  sectionItemDetailsId: number;
  isPanel: boolean;
  organizationId: number;
  exemptionRequestTariffLineId: number;
  exemptionCategoryInformationId: number;
  exemptionApprovalConditionId: number;
  organizationType: number;
  categoryId: number;
  sectionId: number;
  exemptionReqTariffSecItemDetailsId: number;
  tariffcount: number;
  tariffItemId: number;
  tariffLineDetailsId: number;
  ftaManagementId: number;
  customStationId: number;
  exTariffItemId: number;
  tariffDetailsId: number;
  applicableOn: number;
  applicableOnNmae: number;
  exAplicabletypid: number;
  exAplicabletypeCode: any;
  exemCategTypeId: number;
  exemptionCatId: number;
  exempCatType: number;
  ftaMgmtTariffDetailId: number;
  isExemptionDocumentAvaible: boolean;
  ftaManagementStartDate: Date;
  ftaManagementEndDate: Date;
}

@Injectable()
export class ExemptionSharedService {
  sharingData: MyData = {
    exemptionLawRegulationId: 0,
    exemptionId: 0,
    tariffLineExemptionId: 0,
    sectionItemDetailsId: 0,
    isPanel: false,
    organizationId: 0,
    exemptionRequestTariffLineId: 0,
    exemptionCategoryInformationId: 0,
    exemptionApprovalConditionId: 0,
    categoryId: 0,
    organizationType: 0,
    tariffItemId: 0,
    tariffcount: 0,
    sectionId: 0,
    exemptionReqTariffSecItemDetailsId: 0,
    ftaManagementId: 0,
    customStationId: 0,
    tariffLineDetailsId: 0,
    exTariffItemId: 0,
    tariffDetailsId: 0,
    applicableOn: 0,
    exemptionCatId: 0,
    exempCatType: 0,
    applicableOnNmae: null,
    exAplicabletypid: 0,
    exemCategTypeId: 0,
    ftaMgmtTariffDetailId: 0,
    isExemptionDocumentAvaible: false,
    ftaManagementStartDate: null,
    ftaManagementEndDate: null,
    exAplicabletypeCode: null
  };
}
