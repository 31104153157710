import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ServiceDocument } from '@agility/frameworkcore';
import { TraderOrgsModel } from '../../models/TraderOrgsModel';
import { IndexValue, SuccessErrMsgs } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';

@Injectable()
export class TraderProfileService {
  public orgServiceDocument: ServiceDocument<TraderOrgsModel> = new ServiceDocument<TraderOrgsModel>();
  public orderIndex = IndexValue.IsOne;
  public isDescending = false;

  constructor(
    private httpClient: HttpClient,
    private toasterService: ToasterService,
    public mcnConfig: McnConfig,
    public appConfig: RmsConfig
  ) { }

  public whoTheTraderIs(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.whoTheTraderIs + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keySuppliersBuyers(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.keySuppliersBuyers + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public localPurchases(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.localPurchases + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public costOfExemption(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.costOfExemption + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public quotasByCbra(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.quotasByCbra + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public businessVolume(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.businessVolume + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public complianceHistory(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.complianceHistory + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public goodsConfiscated(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.goodsConfiscated + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public assocParties(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.assocParties + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public listOrgs(expression?: string,
    aliasName?: string,
    pageIndex: number = IndexValue.IsOne,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = IndexValue.IsOne,
    isDescending: boolean = false): Observable<ServiceDocument<TraderOrgsModel>> {
    const filterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };

    filterModel.orderIndex = this.orderIndex;
    filterModel.isDescending = this.isDescending;

    return this.orgServiceDocument.list(this.appConfig.listTraderProfileOrgs, filterModel)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  ///////////////// Charts ///////////////////////////

  public importsBarChart(id: number, top: number = IndexValue.IsFive): Observable<any> {
    return this.httpClient.get(this.appConfig.importsBarChart + id + '&top=' + top)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public exportsBarChart(id: number, top: number = IndexValue.IsFive): Observable<any> {
    return this.httpClient.get(this.appConfig.exportsBarChart + id + '&top=' + top)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public importsLineChart(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.importsLineChart + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public exportsLineChart(id: number): Observable<any> {
    return this.httpClient.get(this.appConfig.exportsLineChart + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public handleError() {
    return this.toasterService.Error(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater);
  }
}
