/* tslint:disable: max-line-length */
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export interface MyData {
  pageCount: number;
  associatePageCount: number;
  associateLessPageCount: number;
  sessionTimeout: number;
  localeJsonFile: any;
}

@Injectable()
export class ShipClearanceConfig {

  appData: MyData = { pageCount: 9, associatePageCount: 5, sessionTimeout: 0, associateLessPageCount: 5, localeJsonFile: null };

  // FOR SHIP DECLARATION - ARRIVAL
  listShipDeclaration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/ListShipDeclaration';
  newShipDeclaration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/NewShipDeclaration';
  openShipDeclaration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/OpenShipDeclaration';
  deleteShipclrdeclaration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/DeleteShipclrdeclaration';
  submitShipDeclaration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/SubmitShipDeclaration';
  getVesselRefData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/GetVesselData?vesselFilter=';
  getShipIdentityData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/GetShipIdentityData?vesselFilter=';
  getCallNumberData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/GetCallNumberData?vesselFilter=';
  shipDeclWFStatesApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDeclaration/GetShipDeclarationWorkflowStates';
  pKPWFStatesApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/VesselAdviceForm/GetVesselPKPWorkflowStates';
  immigrationWFStatesApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/GetShipImmigrationWorkflowStates';
  pansWFStatesApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/GetShipPANsWorkflowStates';
  cascadingPortLocationApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/Location/GetDestinationLocationByOrigin?filterValue=';
  formAWFStatesApiUrl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/GetShipFormAWorkflowStates';

  // For Vessel Advice Form - PKP
  newVesselAdvicePKP: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/VesselAdviceForm/NewVesselAdvice';
  openVesselAdvicePKP: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/VesselAdviceForm/OpenVesselAdvice';
  viewVesselAdvicePKP: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/VesselAdviceForm/ViewVesselAdvice';
  submitVesselAdvicePKP: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/VesselAdviceForm/SubmitVesselAdvice';
  listVesselPKP: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/VesselAdviceForm/ListVesselAdvice';


  // For Ship PKP Health Details - Arrival
  newShipPkpHealthDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPkpHealthDetails/NewShipPkpHealthDetails';
  openShipPkpHealthDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPkpHealthDetails/OpenShipPkpHealthDetails';
  submitShipPkpHealthDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPkpHealthDetails/SubmitShipPkpHealthDetails';
  getShipHealthDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPkpHealthDetails/GetShipHealthDetails';


  // For Ship Vaccination - Arrival
  newShipVaccination: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccination/NewShipVaccination';
  openShipVaccination: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccination/OpenShipVaccination';
  submitShipVaccination: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccination/SubmitShipVaccination';


  // For Ship Vaccination Details - Arrival
  newShipVaccinationDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccinationDetails/NewShipVaccinationDetails';
  openShipVaccinationDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccinationDetails/OpenShipVaccinationDetails';
  submitShipVaccinationDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccinationDetails/SubmitShipVaccinationDetails';
  listShipVaccinationDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccinationDetails/ListShipVaccinationDetails';
  deletevaccinelist: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipVaccinationDetails/Deletevaccinelist';

  // FOR SHIP STORE - ARRIVAL
  downloadTemplateForShipStoreDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/ExportDatatoExcelAndDonwload';

  // For Ship Crew
  newShipCrew: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrew/NewShipCrew';
  listShipCrew: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrew/ListShipCrew';
  openShipCrew: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrew/OpenShipCrew';
  submitShipCrew: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrew/SubmitShipCrew';
  newShipCrewDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrewDetails/NewShipCrewDetails';
  openShipCrewDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrewDetails/OpenShipCrewDetails';
  deleteShipCrewDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrewDetails/DeleteShipCrewDetails';
  listShipCrewDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrewDetails/ListShipCrewDetails';
  submitShipCrewDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCrewDetails/SubmitShipCrewDetails';


  listShipStore: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStore/ListShipStore';
  newShipStore: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStore/NewShipStore';
  openShipStore: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStore/OpenShipStore';
  submitShipStore: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStore/SubmitShipStore';
  listShipEffect: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffect/ListShipEffect';
  newShipEffect: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffect/NewShipEffect';
  openShipEffect: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffect/OpenShipEffect';
  submitShipEffect: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffect/SubmitShipEffect';

  // FOR SHIPCARGO - ARRIVAL
  listShipCargo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargo/ListShipCargo';
  newShipCargo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargo/NewShipCargo';
  openShipCargo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargo/OpenShipCargo';
  submitShipCargo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargo/SubmitShipCargo';

  newShipCargoDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoDetails/NewShipCargoDetails';
  submitShipCargoDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoDetails/SubmitShipCargoDetails';
  openShipCargoDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoDetails/OpenShipCargoDetails';
  listShipCargoDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoDetails/ListShipCargoDetails';
  deleteShipCargoDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoDetails/DeleteShipCargoDetails';
  openShipCargoHouseBills: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoHouseBills/OpenShipCargoHouseBills';
  viewCargoHouseBills: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoHouseBills/ViewShipCargoHouseBills';

  // FOR SHIP PAN SECURITY - ARRIVAL
  listShipPanSecurity: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/ListShipPanSecurity';
  newShipPanSecurity: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/NewShipPanSecurity';
  openShipPanSecurity: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/OpenShipPanSecurity';
  submitShipPanSecurity: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/SubmitShipPanSecurity';
  deleteShipPanSecurity: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/DeleteShipPanSecurity';
  viewShipPanSecurity: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPanSecurity/ViewPanSecurity';

  // FOR SHIP IMMIGRATION - ARRIVAL
  listShipImmigration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/ListShipImmigration';
  newShipImmigration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/NewShipImmigration';
  openShipImmigration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/OpenShipImmigration';
  submitShipImmigration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/SubmitShipImmigration';
  deleteShipImmigration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/DeleteShipImmigration';
  listImmigration: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/ListShipImmigration';
  viewShipImmigrationDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipImmigration/ViewShipImmigrationDetails';


  submitExcelUploadData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStore/SubmitExcelUploadData';
  submitExcelForCrewEffect: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffect/ShipEffectDetailsExcelUpload';
  submitExcelForShipCargo: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCargoDetails/ShipCargoExcelUpload';

  newShipStoreDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/NewShipStoreDetails';
  submitShipStoreDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/SubmitShipStoreDetails';
  listShipStoreDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/ListShipStoreDetails';
  openShipStoreDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/OpenShipStoreDetails';
  deleteShipStoreDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/DeleteShipStoreDetails';
  newShipEffectDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/NewShipEffectDetails';
  submitShipEffectDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/SubmitShipEffectDetails';
  openShipEffectDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/OpenShipEffectDetails';
  deleteShipEffectDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/DeleteShipEffectDetails';
  listShipEffectDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/ListShipEffectDetails';
  listShipDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/ListShipDetails';
  deleteShipStoreDetailsAll: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipStoreDetails/DeleteShipStoreDetailsAll';
  deleteShipEffectDetailsAll: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEffectDetails/DeleteShipEffectDetailsAll';

  // For Ship Dangerous
  newShipDangerous: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDangerous/NewShipDangerous';
  submitShipDangerous: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDangerous/SubmitShipDangerous';
  openShipDangerous: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDangerous/OpenShipDangerous';
  viewShipDangerous: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDangerous/ViewShipDangerous';
  listShipDangerous: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipDangerous/ListShipDangerous';

  /// For Ship Endemic Country
  listShipEndemicCountry: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/ListShipEndemicCountry';
  newShipEndemicCountry: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/NewShipEndemicCountry';
  openShipEndemicCountry: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/OpenShipEndemicCountry';
  submitShipEndemicCountry: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/SubmitShipEndemicCountry';
  deleteShipEndemicCountry: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/DeleteShipEndemicCountry';
  gateShipEndemicCountryData: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/GetCountry?countryNameFilter=';
  getShipWfStates: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipEndemicCountry/GetWorkFlowStates';
  /// For Ship Issuance Port
  listShipIssuancePort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/ListShipIssuancePort';
  newShipIssuancePort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/NewShipIssuancePort';
  openShipIssuancePort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/OpenShipIssuancePort';
  submitShipIssuancePort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/SubmitShipIssuancePort';
  deleteShipIssuancePort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/DeleteShipIssuancePort';
  getissuancePortsStates: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/GetWorkFlowStates';
  getShipIssuancePort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipIssuancePort/GetLocation?locationNameFilter=';

  /// For Ship Black List Port

  listShipBlackListPort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipBlackList/ListShipBlackListPort';
  newShipBlackListPort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipBlackList/NewShipBlackList';
  openShipBlackListPort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipBlackList/OpenShipBlackListPort';
  submitShipBlackListPort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipBlackList/SubmitShipBlackListPort';
  deleteShipBlackListPort: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipBlackList/DeleteBlackList';

  /// SCReason

  listSCReason: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCReason/ListSCReason';
  newSCReason: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCReason/NewSCReason';
  openSCReason: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCReason/OpenSCReason';
  submitSCReason: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/SCReason/SubmitSCReason';

  /// Upload Documents

  shipClrNewUploadDocuments: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/UploadDocuments/NewUploadDocuments';
  shipClrUploadDocumentsList: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/UploadDocuments/UploadDocumentsList';
  shipClrSubmitUploadDocuments: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/UploadDocuments/SubmitUploadDocuments';
  shipClrOpenUploadDocuments: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/UploadDocuments/OpenUploadDocuments';
  /// ShipFormA

  NewShipform: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/NewShipFormA';
  OpenShipform: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/OpenShipFormA';
  ViewShipFormA: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/ViewShipFormA';
  SubmitShipform: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/SubmitShipFormA';
  listShipform: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/ListShipFormA';
  listBillOfLading: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/ListBillOfLading';
  submitBillOfItemsAssociation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/SubmitListBillOfLadingAssociation';
  newBillOfLadingAssociation: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/NewBillOfLadingAssociation';
  OpenShipformdetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipFormA/OpenShipFormADetails';

  listShipPassenger: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassenger/ListShipPassenger';
  newShipPassenger: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassenger/NewShipPassenger';
  openShipPassenger: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassenger/OpenShipPassenger';
  submitShipPassenger: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassenger/SubmitShipPassenger';
  newShipPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassengerDetails/NewShipPassengerDetails';
  submitShipPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassengerDetails/SubmitShipPassengerDetails';
  listShipPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassengerDetails/ListShipPassengerDetails';
  openShipPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassengerDetails/OpenShipPassengerDetails';
  deleteShipPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassengerDetails/DeleteShipPassengerDetails';

  newShipCoPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCoPassengerDetails/NewShipCoPassengerDetails';
  submitShipCoPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCoPassengerDetails/SubmitShipCoPassengerDetails';
  listShipCoPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCoPassengerDetails/ListShipCoPassengerDetails';
  openShipCoPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCoPassengerDetails/OpenShipCoPassengerDetails';
  deleteShipCoPassengerDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipCoPassengerDetails/DeleteShipCoPassengerDetails';
  deleteShipPassengerDetailsAll: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPassenger/DeleteShipPassengerDetailsAll';

  listShipPortofCall: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCall/ListShipPortofCall';
  newShipPortofCall: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCall/NewShipPortofCall';
  openShipPortofCall: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCall/OpenShipPortofCall';
  submitShipPortofCall: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCall/SubmitShipPortofCall';

  newShipPortofCallDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCallDetails/NewShipPortofCallDetails';
  submitShipPortofCallDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCallDetails/SubmitShipPortofCallDetails';
  listShipPortofCallDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCallDetails/ListShipPortofCallDetails';
  openShipPortofCallDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCallDetails/OpenShipPortofCallDetails';
  deleteShipPortofCallDetails: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCallDetails/DeleteShipPortofCallDetails';
  getcountryname: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipPortofCallDetails/GetCountryDataBasedOnLocation';

  newShipMaritimeDecl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipMaritimeDeclaration/NewShipMaritimeDecl';
  openShipMaritimeDecl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipMaritimeDeclaration/OpenShipMaritimeDecl';
  submitShipMaritimeDecl: any = environment.appSettings.apiEndpoints.cargoApiBaseUrl + '/api/ShipMaritimeDeclaration/SubmitShipMaritimeDecl';

  constructor() { }

}
