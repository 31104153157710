import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CommonSharedService } from '../../../../app/common/CommonSharedService';
import { GlobalSplCharforSplit } from '../../../../app/common/Constants';
import { SafeUnsubscriber } from '../../../../app/common/SafeUnsubscriber';
import { McnConfig } from '../../../../app/mcnConfig/McnConfig';
import { RmsConfig } from '../../../../app/mcnConfig/RmsConfig';
import { StorageService } from '../../../../app/storageService/storageService';
import { ToasterService } from '../../../../app/toastrService/toasterService';
import { FilterMenuService } from '../../../common/filter-menu.service';
import { Constant, ConstantNumber, ModelName, ModuleName, SuccessErrMsgs, TypesName } from '../../riskSelectivityProfiling/common/Constants';
import { NVDBHistoricalDataModel } from '../models/NVDBHistoricalDataModel';
import { NVDBHistoricalDataService } from '../services/nvdbHistoricalData/NVDBHistoricalDataService';
import { NvdbAppliedTarrifHistoryComponent } from './nvdbAppliedTarrifHistory/NvdbAppliedTarrifHistoryComponent';
import { NvdbOutlierComponent } from './nvdbOutlier/NvdbOutlierComponent';

@Component({
    selector: 'app-nvdb-data',
    templateUrl: './NvdbHistoricalDataComponent.html',

})
export class NvdbHistoricalDataComponent extends SafeUnsubscriber implements OnInit {
    public filterMenuOpened: boolean;
    public riskImpctFltrForm: FormGroup;
    public researchCommodityForm: FormGroup;
    public newFrequecy: boolean;
    public modified: boolean;
    public nvdbHistoricalDataFltrForm: FormGroup;
    public nvdbHistoricalDataList: NVDBHistoricalDataModel[];
    private nvdbHistoricalData: any;
    public pageCount: number;
    private nvdbHistoricalDataServiceDocument: any = [];
    public moduleNameRms: string;
    public ModuleNametariffmngt: string;
    public moduleNameDeclaration: string;
    public declarationType: string;
    public modeOfTransport: string;
    public valuationMethod: string;
    private nvdbHistoricalExpression: any;
    public dateValidation: string;
    private fromDate: any;
    private toDate: any;
    private currentDate: any;

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private filterMenuService: FilterMenuService,
        private nvdbHistoricalDataService: NVDBHistoricalDataService,
        public rmsConfig: RmsConfig,
        private commonSharedService: CommonSharedService,
        private toasterService: ToasterService,
        private mcnConfig: McnConfig,
        public webStorage: StorageService,
        private router: Router) {
        super();
        this.moduleNameRms = ModuleName.Rms;
        this.ModuleNametariffmngt = ModuleName.Tariffmngt;
        this.moduleNameDeclaration = ModuleName.Declaration;
        this.declarationType = TypesName.DirectionTypes;
        this.modeOfTransport = TypesName.MODEOFTRANSPORTDECLTYPES;
        this.valuationMethod = TypesName.VALUATIONMETHODTypes;
    }

    ngOnInit() {

        this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
            this.filterMenuOpened = isFilterMenuOpened;
        });

        this.nvdbHistoricalDataFltrForm = this.fb.group({
            declarationType: [''],
            modeOfTransport: [''],
            valuationMethod: [''],
            itemHScode: [''],
            countryOfOrigin: [''],
            desitinationCountry: [''],
            fromdate: [''],
            todate: [''],
            importerName: [''],
            supplierName: [''],
        });

        this.nvdbHistoricalDataServiceDocument = this.nvdbHistoricalDataService.nvdbHistoricalDataServiceDocument;
    }

    /**
     * Reset Button
     */
    public resetNVDBHistoricalDataLstFilter() {
        this.nvdbHistoricalDataFltrForm.reset();
        if (this.router.url.includes('risk-identification-Analysis/nvdb-historical-data-List')) {
            this.router.navigate(['risk-identification-Analysis/nvdb-historical-data']);
        } else {
            this.router.navigate(['risk-identification-Analysis/nvdb-historical-data-List']);
        }
    }

    public FiltermenuOpen() {
        if (this.filterMenuOpened === undefined || this.filterMenuOpened === false) {
            this.filterMenuOpened = true;
        } else {
            this.filterMenuOpened = false;
        }
    }

    /**
     * View Tariff Details Method
     */
    public ViewTarrrifDetails = (declarationId: number): void => {
        this.nvdbHistoricalDataService.openNVDBAppliedTariffData(declarationId).subscribe((res) => {
            this.dialog.open(NvdbAppliedTarrifHistoryComponent, {
                panelClass: 'custom-modal-container',
            });
        });
    }

    /**
 * View Outlier Details Method
 */
    public viewOutlierDetails = (declarationId: number, tariffCode: string): void => {
        this.nvdbHistoricalDataService.openViewOutlierData(declarationId, tariffCode).subscribe((res) => {
            this.dialog.open(NvdbOutlierComponent, {
                panelClass: 'custom-modal-container',
            });
        });
    }

    /**
      * Checks Date Validation
      * @param event
      */
    checkDateConditions(event: any) {
        this.currentDate = new Date().toISOString().substring(0, 10);
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.controls.fromdate.value)) {
            this.fromDate = new Date(this.nvdbHistoricalDataFltrForm.controls.fromdate.value).toISOString().substring(0, 10);
        } else {
            this.fromDate = SuccessErrMsgs.InvalidDate;
        }

        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.controls.todate.value)) {
            this.toDate = new Date(this.nvdbHistoricalDataFltrForm.controls.todate.value).toISOString().substring(0, 10);
        } else {
            this.toDate = SuccessErrMsgs.InvalidDate;
        }

        if (this.fromDate !== SuccessErrMsgs.InvalidDate && this.toDate === SuccessErrMsgs.InvalidDate) {
            if (this.fromDate <= this.currentDate) {
                return true;
            } else {
                this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.FromDateShouldbelessThanCurrentDate));
                this.nvdbHistoricalDataFltrForm.controls.fromdate.setValue(null);
                return false;
            }
        }

        if (this.fromDate === SuccessErrMsgs.InvalidDate && this.toDate !== SuccessErrMsgs.InvalidDate) {
            if (this.toDate <= this.currentDate) {
                return true;
            } else {
                this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ToDateShouldbeGraterThanCurrentDate));
                this.nvdbHistoricalDataFltrForm.controls.todate.setValue(null);
                return false;
            }
        }

        if (this.fromDate !== SuccessErrMsgs.InvalidDate && this.toDate !== SuccessErrMsgs.InvalidDate) {

            if (this.fromDate > this.currentDate) {
                this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.FromDateShouldbelessThanCurrentDate));
                this.nvdbHistoricalDataFltrForm.controls.fromdate.setValue(null);
                return false;
            }

            if (this.toDate > this.currentDate) {
                this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ToDateShouldbeGraterThanCurrentDate));
                this.nvdbHistoricalDataFltrForm.controls.todate.setValue(null);
                return false;
            }

            if (this.fromDate <= this.toDate) {
                return true;
            } else {
                if (event.targetElement.id === Constant.FromDate) {
                    this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.FromDateLessthanToDate));
                    this.nvdbHistoricalDataFltrForm.controls.fromdate.setValue(null);
                } else {
                    this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ToDateGraterthanFromDate));
                    this.nvdbHistoricalDataFltrForm.controls.todate.setValue(null);
                }
                return false;
            }
        }

    }

    /**
     * Main Search Button
     */
    public searchNVDBHistoricalDataLstFilter() {
        this.nvdbHistoricalExpression = null;
        let query = '';
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['importerName'])) {
            query = 'NVDBHistoricalDataListModel.ImporterName.ToUpper().Contains("' +
                this.nvdbHistoricalDataFltrForm.value['importerName'].toUpperCase() + '")';
            this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query, this.nvdbHistoricalExpression);
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['supplierName'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.SupplierName.ToUpper().Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['supplierName'] + '")';
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.SupplierName.ToUpper().Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['supplierName'] + '")';
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['itemHScode'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.TariffCode.Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['itemHScode'] + '")';
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.TariffCode.Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['itemHScode'] + '")';
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['countryOfOrigin'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.CountryName.ToUpper().Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['countryOfOrigin'] + '")';
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.CountryName.ToUpper().Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['countryOfOrigin'] + '")';
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['desitinationCountry'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.ExportCountry.ToUpper().Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['desitinationCountry'] + '")';
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.ExportCountry.ToUpper().Contains("' +
                    this.nvdbHistoricalDataFltrForm.value['desitinationCountry'] + '")';
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['declarationType'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.BillType == ' + this.nvdbHistoricalDataFltrForm.value['declarationType'];
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.BillType == ' + this.nvdbHistoricalDataFltrForm.value['declarationType'];
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['modeOfTransport'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.ModeoftransportId == ' +
                    this.nvdbHistoricalDataFltrForm.value['modeOfTransport'];
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.ModeoftransportId == ' +
                    this.nvdbHistoricalDataFltrForm.value['modeOfTransport'];
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['valuationMethod'])) {
            if (this.nvdbHistoricalExpression == null) {
                query = 'NVDBHistoricalDataListModel.ValuationMethod == ' +
                    this.nvdbHistoricalDataFltrForm.value['valuationMethod'];
                this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query,
                    this.nvdbHistoricalExpression);
            } else {
                this.nvdbHistoricalExpression = this.nvdbHistoricalExpression + GlobalSplCharforSplit.Char1 +
                    'NVDBHistoricalDataListModel.ValuationMethod == ' +
                    this.nvdbHistoricalDataFltrForm.value['valuationMethod'];
            }
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['fromdate'])) {
            query = 'NVDBHistoricalDataListModel.DeclarationDate.Value.DateTime >= new DateTime('
                + this.commonSharedService.getYearMonthDateTime(this.nvdbHistoricalDataFltrForm.value['fromdate']) + ')';
            this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query, this.nvdbHistoricalExpression);
        }
        if (this.commonSharedService.isNotNullOrUndefined(this.nvdbHistoricalDataFltrForm.value['todate'])) {
            query = 'NVDBHistoricalDataListModel.DeclarationDate.Value.DateTime <= new DateTime('
                + this.commonSharedService.getYearMonthDateTime(this.nvdbHistoricalDataFltrForm.value['todate']) + ')';
            this.nvdbHistoricalExpression = this.commonSharedService.prepareExpression(query, this.nvdbHistoricalExpression);
        }
        this.GetNVDBHistoricalData(this.nvdbHistoricalExpression, ModelName.NVDBHistoricalDataListModel);
    }

    /**
     * Get List NVDB Historical Data
     * @param expression :Query
     * @param aliasName :Model
     */
    private GetNVDBHistoricalData(expression: string, aliasName: string) {
        this.nvdbHistoricalDataService.listNVDBHistoricalData(expression, aliasName).subscribe((response) => {
            this.nvdbHistoricalDataList = this.nvdbHistoricalDataService.nvdbHistoricalDataServiceDocument.dataProfile.dataList;
            this.nvdbHistoricalData = this.nvdbHistoricalDataService.nvdbHistoricalDataServiceDocument;
            this.pageCount =
                this.nvdbHistoricalData.totalCount > ConstantNumber.IsZero ? this.nvdbHistoricalData.totalCount : ConstantNumber.IsZero;
        });
    }

    /**
     * item
     */
    public trackById(item) {
        if (!item) { return null; }
        return item.declarationId;
    }
}
