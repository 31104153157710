import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Http, RequestOptions, Response, URLSearchParams } from '@angular/http';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { ShipClearanceConfig } from '../../mcnConfig/ShipClearanceConfig';
import { ShipEffectModel } from '../models/ShipEffectModel';
import { ToasterService } from '../../toastrService/toasterService';
import { map, catchError } from 'rxjs/operators';
import { ShipClrSharedService } from '../services/sharedServices/ShipClrSharedServices';
import { StorageService } from '../../storageService/storageService';
import { ShipEffectsDetailsModel } from '../models/ShipEffectDetailsModel';
import { CommonSharedService } from '../../common/CommonSharedService';
import { Router } from '@angular/router';
import { GlobalSplCharforSplit } from '../../common/Constants';

@Injectable()
export class ShipEffectService {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public serviceDocument: ServiceDocument<ShipEffectModel> = new ServiceDocument<ShipEffectModel>();
  public mcserviceDocument: ServiceDocument<ShipEffectsDetailsModel> = new ServiceDocument<ShipEffectsDetailsModel>();
  public filterServiceDocument: ServiceDocument<ShipEffectModel> = new ServiceDocument<ShipEffectModel>();
  public modeOfSourceData: any;
  public isSort = false;
  public searchObj: any;
  public orderBy: string;
  private refreshDynamicData = new Subject<string>();

  // Observable string streams
  public callRefreshDynamicData$ = this.refreshDynamicData.asObservable();

  // Service message commands
  public hitRefreshDynamicData() {
    this.refreshDynamicData.next();
  }

  constructor(
    private appConfig: ShipClearanceConfig,
    private toastrService: ToasterService,
    private shipClrSharedService: ShipClrSharedService,
    private http: HttpClient,
    private commonSharedService: CommonSharedService,
    private router: Router,
    private storageService: StorageService
  ) { }

  private handleError() {
    return this.toastrService.Error('Internal Server Error.Please Try Again Later.');
  }

  public list(expression: string = '', aliasName: string = '', pageIndex: any = 1,
    recordPerPage: any = this.appConfig.appData.pageCount, orderIndex: any = 1, isDescending: any = true):
    Observable<ServiceDocument<ShipEffectModel>> {
    const listShipEffect = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.serviceDocument.list(this.appConfig.listShipEffect, listShipEffect)
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public new(): Observable<ServiceDocument<ShipEffectModel>> {
    return this.serviceDocument.new(this.appConfig.newShipEffect)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public open(): Observable<ServiceDocument<ShipEffectModel>> {
    if (this.commonSharedService.isNotNullOrUndefined(this.shipClrSharedService.sharingData.shipDeclarationId)
      && this.shipClrSharedService.sharingData.shipDeclarationId > 0) {
      return this.serviceDocument.open(this.appConfig.openShipEffect, new HttpParams().set('ShipDeclarationId',
        this.shipClrSharedService.sharingData.shipDeclarationId.toString()))
        .pipe(map((response) => response), catchError(() => throwError(
          { msg: this.handleError(), ngNavigationCancelingError: true })));
    } else {
      this.router.navigate(['/shipClrCreateData/crew-effects']);
    }
  }


  public submit(): Observable<any> {
    return this.serviceDocument.submit(this.appConfig.submitShipEffect)
      .pipe(map((response) => response), catchError(() =>
        throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }


  public listShipEffectDetails(expression: string, aliasName: string,
    pageIndex: number = 1, recordPerPage: number = this.appConfig.appData.pageCount, orderIndex: any = 1, isDescending: any = true):
    Observable<ServiceDocument<ShipEffectsDetailsModel>> {
    if (this.commonSharedService.isNotNullOrUndefined(expression)) {
      expression = expression + GlobalSplCharforSplit.Char1
        + 'ShipEffectDetailsModel.ShipDeclarationId ==' + this.shipClrSharedService.sharingData.shipDeclarationId;
    } else { expression = 'ShipEffectDetailsModel.ShipDeclarationId ==' + this.shipClrSharedService.sharingData.shipDeclarationId; }
    const ShipeffectFilterModel = {
      expression: expression,
      aliasName: 'ShipEffectDetailsModel',
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending,
    };
    return this.mcserviceDocument.list(this.appConfig.listShipEffectDetails, ShipeffectFilterModel)
      .pipe(map((response) => response), catchError(
        (res) => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }



  public newShipEffectDetails(): Observable<ServiceDocument<ShipEffectsDetailsModel>> {

    return this.mcserviceDocument.new(this.appConfig.newShipEffectDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public submitShipEffectDetails(): Observable<any> {

    return this.mcserviceDocument.submit(this.appConfig.submitShipEffectDetails)
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public openShipEffectDetails(): Observable<ServiceDocument<ShipEffectsDetailsModel>> {

    return this.mcserviceDocument.open(this.appConfig.openShipEffectDetails, new HttpParams()
      .set('id', this.shipClrSharedService.sharingData.shipEffectsDetailId.toString()))
      .pipe(map((response) => response), catchError(() => throwError(
        { msg: this.handleError(), ngNavigationCancelingError: true })));
  }

  public deleteshipeffect(shipEffectsDetailId: any): Observable<any> {
    return this.http.post(this.appConfig.deleteShipEffectDetails, new HttpParams()
      .set('shipEffectsDetailId', shipEffectsDetailId.toString()));
  }

  public deleteall(shipEffectId: any): Observable<any> {
    return this.http.post(this.appConfig.deleteShipEffectDetailsAll, new HttpParams()
      .set('shipEffectId', shipEffectId.toString()));
  }


  public submitExcelforShipCrewEffect(formModel: ShipEffectsDetailsModel): Observable<any> {
    return this.http.post(this.appConfig.submitExcelForCrewEffect, formModel, { headers: this.headers })
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.handleError(), ngNavigationCancelingError: true })));
  }

}
