import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeUnsubscriber } from '../../../../app/common/SafeUnsubscriber';
import { CommonSharedService } from '../../../common/CommonSharedService';
import { GlobalSplCharforSplit } from '../../../common/Constants';
import { FilterMenuService } from '../../../common/filter-menu.service';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../mcnConfig/RmsConfig';
import { AlertModalService } from '../../../services/AlertModalService';
import { StorageService } from '../../../storageService/storageService';
import { ToasterService } from '../../../toastrService/toasterService';
import { AlertMessages, AllowedActions, ModelName, SuccessErrMsgs } from '../../riskSelectivityProfiling/common/Constants';
import { NVDBMarketVehiclesModel } from '../models/NVDBMarketVehiclesModel';
import { NVDBMarketVehiclesService } from '../services/nvdbMarketVehicles/NVDBMarketVehiclesService';
import { RiskIdentificationAndAnalysisSharedService } from '../services/sharedServices/RiskIdentificationAndAnalysisSharedService';

@Component({
  selector: 'app-market-vehical',
  templateUrl: './MarketResearchDataVehicleComponent.html',
})

export class MarketResearchDataVehicleComponent extends SafeUnsubscriber implements OnInit {
  public filterMenuOpened: boolean;
  public nvdbVehicleForm: FormGroup;
  public researchCommodityForm: FormGroup;
  public nvdbVehicleSD: any = [];
  public nvdbVehicleList: NVDBMarketVehiclesModel[];
  public pageCount: number;
  public selectedIndex: any = 1;
  public selectedDec = true;
  public selectedAsc = false;
  private expression = '';

  constructor(
    private fb: FormBuilder,
    private filterMenuService: FilterMenuService,
    private router: Router,
    public webStorage: StorageService,
    private activatedRoute: ActivatedRoute,
    private nvdbMarketVehiclesService: NVDBMarketVehiclesService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    public mcnConfig: McnConfig,
    public rmsConfig: RmsConfig,
    private alertModalService: AlertModalService,
    private ref: ChangeDetectorRef,
    private toasterService: ToasterService,
    private commonSharedService: CommonSharedService
  ) {
    super();
  }


  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.getListData();
      this.webStorage.set(AllowedActions.RiskIdentificationAndAnalysisAllowedActions,
        this.nvdbVehicleSD.dataProfile.actionService.allowedActions);
    });

    this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
      this.filterMenuOpened = isFilterMenuOpened;
    });

    this.nvdbVehicleForm = this.fb.group({
      tariffItemId: [''],
      unitPrice: [''],
      countryId: [''],
      vehicleTypeId: [''],
      status: ['']
    });

  }

  /**
   * Sorting
   * @param event event
   * @param orderIndex orderIndex
   * @param isDescending IsDescending ture or false
   */
  public sort(event: any, orderIndex: any, isDescending: boolean) {
    event.preventDefault();
    // const expression = this.filterExpression();
    this.nvdbMarketVehiclesService.isDescending = isDescending;
    this.nvdbMarketVehiclesService.orderIndex = orderIndex;
    const pageIndex = event == null ? 1 : event.PageIndex;
    const pageCount = event == null ? this.mcnConfig.appData.associatePageCount : event.PageCount;
    this.nvdbMarketVehiclesService.listNVDBMarketVehicles(
      this.expression, ModelName.NVDBMarketVehiclesListModel, pageIndex, pageCount).subscribe(() => {
        this.getListData();
      });
  }

  private getListData() {
    this.nvdbVehicleSD = this.nvdbMarketVehiclesService.nvdbMarketVehiclesServiceDocument;
    if (this.nvdbVehicleSD.dataProfile !== undefined) {
      this.nvdbVehicleList = this.nvdbVehicleSD.dataProfile.dataList;
    } else {
      this.nvdbVehicleList = [];
    }
    if (this.nvdbVehicleSD.totalCount !== null) {
      this.pageCount = this.nvdbVehicleSD.totalCount;
    }
  }

  /**
   * Search Filter
   */
  public filter() {
    this.expression = this.filterExpression();
    this.nvdbMarketVehiclesService.listNVDBMarketVehicles(this.expression,
      ModelName.NVDBMarketVehiclesListModel).subscribe((response) => {
        this.getListData();
      });
  }

  /**
   * Search
   */
  private filterExpression() {
    const tariffItem = this.nvdbVehicleForm.value['tariffItemId'];
    const unitPrice = this.nvdbVehicleForm.value['unitPrice'];
    const vehicleType = this.nvdbVehicleForm.value['vehicleTypeId'];
    const country = this.nvdbVehicleForm.value['countryId'];
    const status = this.nvdbVehicleForm.value['status'];
    this.expression = null;

    if (this.commonSharedService.isNotNullOrUndefined(tariffItem)) {
      if (this.expression == null) {
        this.expression = `NVDBMarketVehiclesListModel.TariffItemDetails.TariffCode.ToUpper()
                                .Contains("${tariffItem.toUpperCase()}")`;
      } else {
        this.expression += `${GlobalSplCharforSplit.Char1}NVDBMarketVehiclesListModel.TariffItemDetails.TariffCode.ToUpper()
                                .Contains("${tariffItem.toUpperCase()}")`;
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(unitPrice)) {
      if (this.expression == null) {
        this.expression = `NVDBMarketVehiclesListModel.UnitPrice.Value == ${Number(unitPrice)}`;
      } else {
        this.expression += `${GlobalSplCharforSplit.Char1}NVDBMarketVehiclesListModel.UnitPrice.Value == ${Number(unitPrice)}`;
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(country)) {
      if (this.expression == null) {
        this.expression = `NVDBMarketVehiclesListModel.CountryDetails.CountryName.ToUpper()
                                .Contains("${country.toUpperCase()}")`;
      } else {
        this.expression += `${GlobalSplCharforSplit.Char1}NVDBMarketVehiclesListModel.CountryDetails.CountryName.ToUpper()
                                .Contains("${country.toUpperCase()}")`;
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(vehicleType)) {
      if (this.expression == null) {
        this.expression = `NVDBMarketVehiclesListModel.VehicleTypeId == ${vehicleType}`;
      } else {
        this.expression += `${GlobalSplCharforSplit.Char1}NVDBMarketVehiclesListModel.VehicleTypeId == ${vehicleType}`;
      }
    }
    if (this.commonSharedService.isNotNullOrUndefined(status)) {
      if (this.expression == null) {
        this.expression = `NVDBMarketVehiclesListModel.WorkflowInstance.StateName.ToUpper()
                                .Equals("${status.toUpperCase()}")`;
      } else {
        this.expression += `${GlobalSplCharforSplit.Char1}NVDBMarketVehiclesListModel.WorkflowInstance.StateName.ToUpper()
                                .Equals("${status.toUpperCase()}")`;
      }
    }
    return this.expression;
  }


  /**
   * Reset
   */
  public resetFilter() {
    this.expression = '';
    this.nvdbVehicleForm.reset();
    this.filter();
  }


  /**
   * Pagination
   * @param response response
   */
  public doPagination(response: any) {
    this.expression = this.filterExpression();
    this.expression = this.expression != null ? this.expression : '';
    this.nvdbMarketVehiclesService.listNVDBMarketVehicles(
      this.expression, ModelName.NVDBMarketVehiclesListModel, response.PageIndex, response.PageCount)
      .subscribe(() => {
        this.getListData();
      });
  }


  public FiltermenuOpen() {
    if (this.filterMenuOpened === undefined || this.filterMenuOpened === false) {
      this.filterMenuOpened = true;
    } else {
      this.filterMenuOpened = false;
    }
  }


  /**
   * Open
   * @param id nvdbMarketVehicleId
   */
  public editVehicle(id: number) {
    this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketVehicleId = id;
    this.nvdbMarketVehiclesService.openNVDBMarketVehicles().subscribe(() => {
      this.router.navigate(['risk-identification-Analysis/market-research-vehicle-details']);
    });
  }


  /**
   * Delete
   * @param model NVDBMarketVehiclesModel
   */
  public deleteVehicle(model: NVDBMarketVehiclesModel) {
    const confirmationMsg = this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ConfirmDelete)
      + SuccessErrMsgs.SelectedVehicleData;

    this.alertModalService
      .confirm(AlertMessages.delete, AlertMessages.WarnIcon, SuccessErrMsgs.DeleteNVDBMarketVehicle, confirmationMsg, '')
      .subscribe((response) => {
        if (response === true) {
          this.nvdbMarketVehiclesService.deleteNVDBMarketVehicles(model.nvdbMarketVehicleId)
            .subscribe((itemresponse) => {
              if (itemresponse) {
                if (this.nvdbVehicleList.length > 0) {
                  const index = this.nvdbVehicleList.findIndex(
                    x => x.nvdbMarketVehicleId === model.nvdbMarketVehicleId);

                  if (index !== -1) {
                    this.nvdbVehicleList.splice(index, 1);
                  }

                  this.ref.markForCheck();
                }
                this.toasterService.Success(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.RecordDeletedSuccessfully), null);
                this.ref.markForCheck();
              }
            },
              Error => {
                this.toasterService.Error(
                  this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
              });
        }
      });
  }

  public trackById(item) {
    if (!item) { return null; }
    return item.nvdbMarketComId;
  }
}
