
import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { McnConfig } from '../../mcnConfig/McnConfig';

@Injectable()
export class McnPagerService {
  constructor(private appConfig: McnConfig) { }
  public getPager(Items: any, currentPage: number = 1, pageSize: number = this.appConfig.appData.pageCount, isPagination: boolean = false) {
    if (Items == null) {
      Items = [];
    }

    let startPage: number, endPage: number, totalPages: number, totalItems: any;

    if (isPagination) {
      // calculate total pages
      totalPages = Math.ceil(Items / pageSize);
    } else {
      // calculate total pages
      totalPages = (Math.ceil(Items.length / pageSize) !== 0) ? Math.ceil(Items.length / pageSize) : 1;
    }

    if (totalPages <= 9) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, Items.length - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);

    if (!isPagination) {
      // get current page of items
      totalItems = Items.slice(startIndex, endIndex + 1);
    }

    // return object with all pager properties required by the view
    return {
      totalItems,
      totalCount: Items.length,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }
}
