<!-- <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script> -->
<div class="mrg-10" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxLayout="column" class="mt-3">
    <div fxFlex="100">
      <mat-tab-group class="inbox-tab-group">
        <mat-tab label="{{'TARIFFDESCRIPTION'| translate}}">
          <div fxFlex="100">
            <div fxFlex="100" class="card-grey-border p-0" fxLayout="column">
              <div class="ocean-bg pl-2" fxLayout="row wrap">
                <i class="icon-list-view"></i>
                <h5 class="mt-0 ml-2">{{'TARIFFDESCRIPTION'| translate}}</h5>
              </div>
              <div fxFlex="100" fxLayout="column">
                <div fxFlex="100" class="p-0 mt-1 rmsmax-height">
                  <div fxFlex="10" class="ocean-bg pl-2" fxLayoutAlign="start center">
                    <b>{{'HS2'| translate}}</b>
                  </div>
                  <div fxFlex="15" class="ocean-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.hS2}}
                  </div>
                  <div fxFlex="75" class="ocean-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.hS2Description}}
                  </div>
                </div>
                <div fxFlex="100" class="p-0 mt-1 rmsmax-height">
                  <div fxFlex="10" class="gray-bg pl-2" fxLayoutAlign="start center">
                    <b>{{'HS4'| translate}}</b>
                  </div>
                  <div fxFlex="15" class="gray-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.hS4}}
                  </div>
                  <div fxFlex="75" class="gray-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.hS4Description}}
                  </div>
                </div>
                <div fxFlex="100" class="p-0 mt-1 rmsmax-height">
                  <div fxFlex="10" class="ocean-bg pl-2" fxLayoutAlign="start center">
                    <b>{{'HS6'| translate}}</b>
                  </div>
                  <div fxFlex="15" class="ocean-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.hS6}}
                  </div>
                  <div fxFlex="75" class="ocean-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.hS6Description}}
                  </div>
                </div>
                <div fxFlex="100" class="p-0 mt-1 rmsmax-height">
                  <div fxFlex="10" class="gray-bg pl-2" fxLayoutAlign="start center">
                    <b>{{'TariffLine'| translate}}</b>
                  </div>
                  <div fxFlex="15" class="gray-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.tariffCode}}
                  </div>
                  <div fxFlex="75" class="gray-bg pl-2" fxLayoutAlign="start center">
                    {{_tariffDescription?.tariffCodeDescription}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="100">
            <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
              <div class="ocean-bg pl-2" fxLayout="row wrap">
                <i class="icon-list-view"></i>
                <h5 class="mt-0 ml-2">{{'TARIFFRATEFORIMPORTSOVERTHEPERIOD'| translate}}</h5>
              </div>
              <div fxFlex="100">
                <div id="line_chart" [chartData]="this._importTariffRates"
                  [chartOptions]="tariffRates_Line_ChartOptions" chartType="LineChart" GoogleChart></div>
              </div>
            </div>
            <span fxFlex="4">&nbsp;</span>
            <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
              <div class="ocean-bg pl-2" fxLayout="row wrap">
                <i class="icon-list-view"></i>
                <h5 class="mt-0 ml-2">{{'TARIFFRATEFOREXPORTSOVERTHEPERIOD'| translate}}</h5>
              </div>
              <div fxFlex="100">
                <div id="line_chart2" [chartData]="this._exportTariffRates"
                  [chartOptions]="tariffRates_Line_ChartOptions" chartType="LineChart" GoogleChart></div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{'RULINGSASSOCIATED'| translate}}">
          <ng-template matTabContent>
            <div fxFlex="100">
              <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'RULINGSASSOCIATED'| translate}}</h5>
                </div>
                <div fxFlex="100">
                  <mat-table #table [dataSource]="rulingsAssDataSource" class="profile-360-table">
                    <ng-container cdkColumnDef="ClassificationCount">
                      <mat-header-cell *cdkHeaderCellDef>{{'ClassificationRulingsCount' | translate }}
                      </mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ClassificationRulingsCount' | translate }}</span>
                        <span>{{row?.classRulingCount}}</span>
                      </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="ValuationCount">
                      <mat-header-cell *cdkHeaderCellDef>{{'ValuationRulingsCount' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ValuationRulingsCount' | translate }}</span>
                        <span>{{row?.valuationRulingsCount}}</span>
                      </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="TariffCode">
                      <mat-header-cell *cdkHeaderCellDef>{{'TariffCode' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'TariffCode' | translate }}</span>
                        <span>{{row?.tariffCode}}</span>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *cdkHeaderRowDef="rulingsAssDisplayedColumns"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: rulingsAssDisplayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'TRENDS' | translate }}">
          <ng-template matTabContent>
            <div fxFlex="100">
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'IMPORTSOVERTHEPERIOD' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <div id="line_chart3" [chartData]="this._trendsImport" [chartOptions]="trends_Line_ChartOptions"
                    chartType="LineChart" GoogleChart></div>
                </div>
              </div>
              <span fxFlex="4">&nbsp;</span>
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'EXPORTSOVERTHEPERIOD' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <div id="line_chart4" [chartData]="this._trendsExport" [chartOptions]="trends_Line_ChartOptions"
                    chartType="LineChart" GoogleChart></div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'KEYSOURCESDESTINATIONS' | translate }}">
          <ng-template matTabContent>
            <div fxFlex="100">
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'KEYSOURCESIMPORTS' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <div id="pie_chart" [chartData]="this._keySources" [chartOptions]="pie_ChartOptions"
                    chartType="PieChart" GoogleChart></div>
                </div>
              </div>
              <span fxFlex="4">&nbsp;</span>
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'KEYDESTINATIONSEXPORTS' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <div id="pie_chart2" [chartData]="this._keyDestinations" [chartOptions]="pie_ChartOptions"
                    chartType="PieChart" GoogleChart></div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'KEYTRADERS' | translate }}">
          <ng-template matTabContent>
            <div fxFlex="100">
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'IMPORTS' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <mat-table #table [dataSource]="keyTradersImportDataSource" class="profile-360-table">
                    <ng-container cdkColumnDef="Importer">
                      <mat-header-cell *cdkHeaderCellDef>{{'Importer' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'Importer' | translate }}</span>
                        <span>{{row?.importer}}</span>
                      </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="Value">
                      <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                        <span>{{row?.value}}</span>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *cdkHeaderRowDef="keyTradersImportDisplayedColumns"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: keyTradersImportDisplayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
              <span fxFlex="4">&nbsp;</span>
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'EXPORTS' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <mat-table #table [dataSource]="keyTradersExportDataSource" class="profile-360-table">
                    <ng-container cdkColumnDef="Exporter">
                      <mat-header-cell *cdkHeaderCellDef>{{'Exporter' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'Exporter' | translate }}</span>
                        <span>{{row?.exporter}}</span>
                      </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="Value">
                      <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                        <span>{{row?.value}}</span>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *cdkHeaderRowDef="keyTradersExportDisplayedColumns"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: keyTradersExportDisplayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'KEYPRODUCERSSECTORS' | translate }}">
          <ng-template matTabContent>
            <div fxFlex="100">
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'KEYPRODUCERS' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <mat-table #table [dataSource]="keyProducersDataSource" class="profile-360-table">
                    <ng-container cdkColumnDef="LocalManufactureName">
                      <mat-header-cell *cdkHeaderCellDef>{{'LocalManufacture' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'LocalManufacture' | translate }}</span>
                        <span>{{row?.localManufactureName}}</span>
                      </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="Value">
                      <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                        <span>{{row?.localManuValue}}</span>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *cdkHeaderRowDef="keyProducersDisplayedColumns"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: keyProducersDisplayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
              <span fxFlex="4">&nbsp;</span>
              <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'KEYSECTORS' | translate }}</h5>
                </div>
                <div fxFlex="100">
                  <mat-table #table [dataSource]="keySectorsDataSource" class="profile-360-table">
                    <ng-container cdkColumnDef="Sectors">
                      <mat-header-cell *cdkHeaderCellDef>{{'Sectors' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'Sectors' | translate }}</span>
                        <span>{{row?.sectors}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="Import">
                      <mat-header-cell *cdkHeaderCellDef>{{'ImportRMMill' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ImportRMMill' | translate }}</span>
                        <span>{{row?.importValue}}</span>
                      </mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="Export">
                      <mat-header-cell *cdkHeaderCellDef>{{'ExportRMMill' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'ExportRMMill' | translate }}</span>
                        <span>{{row?.exportValue}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="LocalMfg">
                      <mat-header-cell *cdkHeaderCellDef>{{'LocalMfgRMMill' | translate }}</mat-header-cell>
                      <mat-cell *cdkCellDef="let row">
                        <span class="mobile-cont-text">{{'LocalMfgRMMill' | translate }}</span>
                        <span>{{row?.localMfgValue}}</span>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *cdkHeaderRowDef="keySectorsDisplayedColumns"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: keySectorsDisplayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'KEYIMPORTPROCEDURES' | translate }}">
          <ng-template matTabContent>
            <div fxLayout="column">
              <div fxFlex="100">
                <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
                  <div class="ocean-bg pl-2" fxLayout="row wrap">
                    <i class="icon-list-view"></i>
                    <h5 class="mt-0 ml-2">{{'APPLIEDTARIFFS' | translate }}</h5>
                  </div>
                  <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
                    <div fxFlex="100">
                      <mat-table #table [dataSource]="appliedTariffDataSource" class="profile-360-table">
                        <ng-container cdkColumnDef="ImportProcedure">
                          <mat-header-cell *cdkHeaderCellDef>{{'ImportProcedure' | translate }}</mat-header-cell>
                          <mat-cell *cdkCellDef="let row">
                            <span class="mobile-cont-text">{{'ImportProcedure' | translate }}</span>
                            <span>{{row?.importProcedureId}}</span>
                          </mat-cell>
                        </ng-container>

                        <ng-container cdkColumnDef="Value">
                          <mat-header-cell *cdkHeaderCellDef>{{'ValueRMMill' | translate }}</mat-header-cell>
                          <mat-cell *cdkCellDef="let row">
                            <span class="mobile-cont-text">{{'ValueRMMill' | translate }}</span>
                            <span>{{row?.importProcedure}}</span>
                          </mat-cell>
                        </ng-container>
                        <ng-container cdkColumnDef="FTAExemption">
                          <mat-header-cell *cdkHeaderCellDef>{{'FTAExemption' | translate }}</mat-header-cell>
                          <mat-cell *cdkCellDef="let row">
                            <span class="mobile-cont-text">{{'FTAExemption' | translate }}</span>
                            <span>{{row?.subImportProcedureId}}</span>
                          </mat-cell>
                        </ng-container>
                        <mat-header-row *cdkHeaderRowDef="appliedTariffDisplayedColumns"></mat-header-row>
                        <mat-row *cdkRowDef="let row; columns: appliedTariffDisplayedColumns;"></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex="100">
                <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                  <div fxFlex="100">
                    <div id="pie_chart3" [chartData]="this._keyImportProcedure" [chartOptions]="pie_ChartOptions"
                      chartType="PieChart" GoogleChart></div>
                  </div>
                </div>
                <span fxFlex="4">&nbsp;</span>
                <div fxFlex="48" class="card-grey-border p-0 mt-4" fxLayout="column">
                  <div fxFlex="100">
                    <div id="pie_chart4" [chartData]="this._keyImportProcedureOther" [chartOptions]="pie_ChartOptions"
                      chartType="PieChart" GoogleChart></div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'LPCO' | translate }}">
          <ng-template matTabContent>
            <div fxFlex="100">
              <div fxFlex="100" class="card-grey-border p-0 mt-4" fxLayout="column">
                <div class="ocean-bg pl-2" fxLayout="row wrap">
                  <i class="icon-list-view"></i>
                  <h5 class="mt-0 ml-2">{{'LPCO' | translate }}</h5>
                </div>
                <div fxFlex="100" class="card-grey-border p-0 m-3" fxLayout="column">
                  <div fxFlex="100">
                    <mat-table #table [dataSource]="lpcoDataSource" class="profile-360-table">
                      <ng-container cdkColumnDef="License">
                        <mat-header-cell *cdkHeaderCellDef>{{'License(s)' | translate }}</mat-header-cell>
                        <mat-cell *cdkCellDef="let row">
                          <span class="mobile-cont-text">{{'License(s)' | translate }}</span>
                          <span>{{row?.licenseName}}</span>
                        </mat-cell>
                      </ng-container>
                      <ng-container cdkColumnDef="Permit">
                        <mat-header-cell *cdkHeaderCellDef>{{'Permit(s)' | translate }}</mat-header-cell>
                        <mat-cell *cdkCellDef="let row">
                          <span class="mobile-cont-text">{{'Permit(s)' | translate }}</span>
                          <span>{{row?.permitName}}</span>
                        </mat-cell>
                      </ng-container>
                      <ng-container cdkColumnDef="Certificate">
                        <mat-header-cell *cdkHeaderCellDef>{{'Certificate(s)' | translate }}</mat-header-cell>
                        <mat-cell *cdkCellDef="let row">
                          <span class="mobile-cont-text">{{'Certificate(s)' | translate }}</span>
                          <span>{{row?.certificateName}}</span>
                        </mat-cell>
                      </ng-container>
                      <ng-container cdkColumnDef="Others">
                        <mat-header-cell *cdkHeaderCellDef>{{'Others' | translate }}</mat-header-cell>
                        <mat-cell *cdkCellDef="let row">
                          <span class="mobile-cont-text">{{'Others' | translate }}</span>
                          <span>{{row?.others}}</span>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *cdkHeaderRowDef="lpcoDisplayedColumns"></mat-header-row>
                      <mat-row *cdkRowDef="let row; columns: lpcoDisplayedColumns;"></mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>