import { Component, OnInit, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MarketResearchVehicalUploadComponent } from '../marketResearchVehicalUpload/MarketResearchVehicalUploadComponent';
import { ServiceDocument } from '@agility/frameworkcore/servicedocument/ServiceDocument';
import { NVDBMarketVehiclesModel } from '../../models/NVDBMarketVehiclesModel';
import { NVDBMarketVehiclesService } from '../../services/nvdbMarketVehicles/NVDBMarketVehiclesService';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { TariffitemModel } from '../../../../mdm/models/TariffitemModel';
import { CountryModel } from '../../../../mdm/models/CountryModel';
import { VehicleBrandModel } from '../../../../mdm/models/VehicleBrandModel';
import { VehicleBrandModelModel } from '../../../../mdm/models/VehicleBrandModelModel';
import { ValidationService } from '../../../../validations/ValidationService';
import { ToasterService } from '../../../../toastrService/toasterService';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { CommonSharedService } from '../../../../common/CommonSharedService';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { CommonStates, HttpStatusCode } from '../../../../common/Constants';
import { NVDBMarketCommodityService } from '../../services/nvdbMarketCommodity/NVDBMarketCommodityService';
import { NvdbCommodityPopUpComponent } from '../../marketResearchDataCommodity/nvdbCommodityPopUp/NvdbCommodityPopUpComponent';
import { NVDBMarketSpecificationsModel } from '../../models/NVDBMarketSpecificationsModel';
import { AlertModalService } from '../../../../services/AlertModalService';
import {
  Message, ProfileName, Status, UserNames, Constant, QueryStringParameter, SuccessErrMsgs, AlertMessages
} from '../../../riskSelectivityProfiling/common/Constants';
import { SafeUnsubscriber } from '../../../../../app/common/SafeUnsubscriber';
import { StorageService } from '../../../../../app/storageService/storageService';

@Component({
  selector: 'app-market-details-vehical',
  templateUrl: './MarketResearchVehicalDetailsComponent.html',
})

export class MarketResearchVehicalDetailsComponent extends SafeUnsubscriber implements OnInit, AfterContentInit {
  public serviceDocument: ServiceDocument<NVDBMarketVehiclesModel>;
  public vehicleDetails: any;
  public nvdbSpecificationList: any;
  public profileForm: any;
  public auto_hsCode: TariffitemModel[] = [];
  public auto_countryOfOrigin: CountryModel[] = [];
  public auto_countryOfManufac: CountryModel[] = [];
  public auto_brand: VehicleBrandModel[] = [];
  public auto_brandModel: VehicleBrandModelModel[] = [];
  public toDateClass = 'astric-star';
  public disableToDate = false;
  public disableCtrls = false;
  public disableRemarks = true;
  public showHideRemarks = false;
  public showHideSpecificationSublist: boolean;
  public showHideAddButton: boolean;
  public disableToDateControl = true;
  public disableFromDateControl = true;
  public mandatorySymbol = false;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private service: NVDBMarketVehiclesService,
    private nvdbCommodityService: NVDBMarketCommodityService,
    private router: Router,
    public rmsConfig: RmsConfig,
    public webStorage: StorageService,
    private validationService: ValidationService,
    private toasterService: ToasterService,
    private mcnConfig: McnConfig,
    private commonSharedService: CommonSharedService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    private alertModalService: AlertModalService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.serviceDocument = this.service.nvdbMarketVehiclesServiceDocument;
      this.vehicleDetails = this.serviceDocument.dataProfile.dataModel;
      this.profileForm = this.serviceDocument.dataProfile.profileForm;
      this.nvdbSpecificationList = this.serviceDocument.dataProfile.dataModel.specificationList;
    });

    // Validations
    this.enableDisableCtrls();
    this.checkInfinite();
    // Make Filed Required and Validations
    this.profileForm.controls.typeId.setValidators([Validators.required]);
    this.profileForm.controls.tariffItemId.setValidators([Validators.required]);
    this.profileForm.controls.nvdbMarketVehicleDate.setValidators([Validators.required]);
    this.profileForm.controls.vehicleConditionId.setValidators([Validators.required]);
    this.profileForm.controls.vehicleTypeId.setValidators([Validators.required]);
    this.profileForm.controls.modelCode.setValidators([Validators.required]);
    this.profileForm.controls.noOfDoors.setValidators([Validators.required, this.validationService.NumericsValidator()]);
    this.profileForm.controls.engineTypeId.setValidators([Validators.required]);
    this.profileForm.controls.yearManufacture.setValidators([Validators.required, this.validationService.NumericsValidator()]);
    this.profileForm.controls.seatingCap.setValidators([Validators.required, this.validationService.NumericsValidator()]);
    this.profileForm.controls.engineCap.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.profileForm.controls.vehicleCategoryTypeId.setValidators([Validators.required]);
    this.profileForm.controls.transmissionId.setValidators([Validators.required]);
    this.profileForm.controls.salePrice.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.profileForm.controls.manufacturingCountryId.setValidators([Validators.required]);
    this.profileForm.controls.unitPrice.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.profileForm.controls.estimatedPrice.setValidators([Validators.required, this.validationService.NumericAndDecimalValidator()]);
    this.profileForm.controls.fromDate.setValidators([Validators.required]);
    this.profileForm.controls.toDate.setValidators([Validators.required,
    this.validationService.AllowNullAndDateGreateThanCurrentDate()]);
    this.profileForm.controls.valuationMethodId.setValidators([Validators.required]);
    this.profileForm.controls.brand.setValidators([Validators.required]);
    this.profileForm.controls.model.setValidators([Validators.required]);
    this.profileForm.controls.countryId.setValidators([Validators.required]);

    // Setting Mandatory Symbol
    if (this.serviceDocument.userProfile.name === UserNames.DeputyDirectorValuation) {
      this.mandatorySymbol = true;
    } else {
      this.mandatorySymbol = false;
    }

    const stateName = this.serviceDocument.dataProfile.dataModel.workflowInstance.stateName;
    // Setting Validating and UnValidating
    if (stateName === Status.Submitted && this.serviceDocument.userProfile.name === UserNames.DeputyDirectorValuation) {
      this.profileForm.controls.remarks.setValidators([Validators.required]);
    } else {
      this.profileForm.controls.remarks.clearValidators();
    }
  }



  ngAfterContentInit() {
    this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketVehicleId =
      this.profileForm.get(QueryStringParameter.nvdbMarketVehicleId).value;
    this.setAutoCompletes();
  }

  /**
   * Autocomplete
   */
  private setAutoCompletes() {
    if (this.serviceDocument.dataProfile.dataModel.workflowInstance.stateName !== CommonStates.start) {
      if (this.commonSharedService.isNotNullOrUndefined(this.serviceDocument.dataProfile.dataModel.autoTariff)) {
        this.auto_hsCode.push(<TariffitemModel>this.serviceDocument.dataProfile.dataModel.autoTariff);
      }
      if (this.commonSharedService.isNotNullOrUndefined(this.serviceDocument.dataProfile.dataModel.autoCountryOfOrigin)) {
        this.auto_countryOfOrigin.push(<CountryModel>this.serviceDocument.dataProfile.dataModel.autoCountryOfOrigin);
      }
      if (this.commonSharedService.isNotNullOrUndefined(this.serviceDocument.dataProfile.dataModel.autoCountryOfManuf)) {
        this.auto_countryOfManufac.push(<CountryModel>this.serviceDocument.dataProfile.dataModel.autoCountryOfManuf);
      }
      if (this.commonSharedService.isNotNullOrUndefined(this.serviceDocument.dataProfile.dataModel.autoBrand)) {
        this.auto_brand.push(<VehicleBrandModel>this.serviceDocument.dataProfile.dataModel.autoBrand);
      }
      if (this.commonSharedService.isNotNullOrUndefined(this.serviceDocument.dataProfile.dataModel.autoModel)) {
        this.auto_brandModel.push(<VehicleBrandModelModel>this.serviceDocument.dataProfile.dataModel.autoModel);
      }
    }
  }

  /**
   * Validations
   */
  private enableDisableCtrls() {
    const stateName = this.serviceDocument.dataProfile.dataModel.workflowInstance.stateName;

    if (stateName === Status.Start) {
      this.profileForm.controls['nvdbMarketVehicleDate'].setValue(new Date());
      this.showHideSpecificationSublist = false;
    } else {
      this.showHideSpecificationSublist = true;
    }

    if (stateName !== Status.Start && stateName !== Status.Created
      && stateName !== Status.Modified && stateName !== Status.ReturnedForCorrection) {
      this.disableCtrls = true;
      this.disableToDate = true;
      this.showHideAddButton = false;
    } else {
      this.disableCtrls = false;
      this.disableToDate = false;
      this.showHideAddButton = true;
    }

    if (stateName === Status.Start || stateName === Status.Created || stateName === Status.Modified ||
      (stateName === Status.Submitted && this.serviceDocument.userProfile.name === UserNames.ValuationAdmin)) {
      this.showHideRemarks = false;
    } else {
      this.showHideRemarks = true;
    }

    if (stateName === Status.Submitted && this.serviceDocument.userProfile.name === UserNames.DeputyDirectorValuation) {
      this.disableRemarks = false;
    } else {
      this.disableRemarks = true;
    }

    // If State Name is Submitted,Activated,Deactiavated Date Controls
    if (stateName === Status.Submitted
      || stateName === Status.Activated
      || stateName === Status.deactivated
      || (stateName === Status.ReturnedForCorrection
        && this.serviceDocument.userProfile.name === UserNames.DeputyDirectorValuation)) {
      this.disableToDateControl = true;
      this.disableFromDateControl = true;
    } else {
      this.disableToDateControl = false;
      this.disableFromDateControl = false;
    }

  }


  /**
   *Check Infinate Date Validation
   */
  checkInfinite() {
    if (this.profileForm.controls.infiniteDate.value === true) {
      this.profileForm.controls.toDate.setValue(null);
      this.disableToDate = true;
      this.profileForm.controls.toDate.disable();
      this.profileForm.controls.toDate.setValidators(null);
      this.toDateClass = '';
    } else {
      this.disableToDate = false;
      this.profileForm.controls.toDate.enable();
      this.profileForm.controls.toDate
        .setValidators([Validators.required, this.validationService.AllowNullAndDateGreateThanCurrentDate()]);
      this.toDateClass = 'astric-star';
    }
  }

  /**
   * Check Date Validations
   * @param event event
   */
  public checkDateConditions(event) {
    if ((this.profileForm.controls.fromDate.value) && (this.profileForm.controls.toDate.value)) {
      const fromD = new Date(this.profileForm.controls.fromDate.value);
      const toD = new Date(this.profileForm.controls.toDate.value);
      if (fromD <= toD) {
        return true;
      } else {
        if (event.targetElement.id === Constant.FromDate) {
          this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.FromDateLessthanToDate));
          this.profileForm.controls.fromDate.setValue(null);
        } else {
          this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ToDateGraterthanFromDate));
          this.profileForm.controls.toDate.setValue(null);
        }
        return false;
      }
    }
  }

  /**
   * Submit
   */
  public submitVehicle() {
    if (this.profileForm.valid) {
      this.service.submitNVDBMarketVehicles().subscribe((res) => {
        if (this.commonSharedService.isNotNullOrUndefined(res) && res.result === HttpStatusCode.OK) {
          const actionName = this.serviceDocument.dataProfile.profileForm.controls['actionName'].value;
          this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketVehicleId = res.mcnProfileResponse;
          if (this.router.url === '/risk-identification-Analysis/market-research-vehicle-details') {
            this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details-Form']);
          } else if (this.router.url === '/risk-identification-Analysis/market-research-vehicle-details-Form') {
            this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details-Open']);
          } else if (this.router.url === '/risk-identification-Analysis/market-research-vehicle-details-Open') {
            this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details-Form']);
          }
          this.commonSharedService.displayAlertMessages(actionName, ProfileName.NVDBMarketVehicles);
        }
      });
    } else {
      this.touchTheControls(this.profileForm);
    }
  }

  /**
   * New Commodity Specification
   */
  newCommoditySpecificationPopup() {
    this.nvdbCommodityService.newNVDBMarketCommoditySpecs().subscribe(() => {
      const dialogRef = this.dialog.open(NvdbCommodityPopUpComponent, {
        panelClass: 'custom-modal-container',
        width: '900px'
      });

      dialogRef.componentInstance.parentForm = Constant.IsNVDBMarketVehicle;

      dialogRef.afterClosed().subscribe(() => {
        if (this.router.url === '/risk-identification-Analysis/market-research-vehicle-details') {
          this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details-Form']);
        } else if (this.router.url === '/risk-identification-Analysis/market-research-vehicle-details-Form') {
          this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details-Open']);
        } else if (this.router.url === '/risk-identification-Analysis/market-research-vehicle-details-Open') {
          this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details-Form']);
        }
      });
    });
  }


  /**
   * Open Specifications
   * @param id tariffItemSpecId
   */
  editNVDBSpecification(id: number): void {
    this.riskIdentificationAndAnalysisSharedService.sharingData.tariffItemSpecId = id;
    this.nvdbCommodityService.openNVDBMarketCommoditySpecs().subscribe(() => {
      const dialogRef = this.dialog.open(NvdbCommodityPopUpComponent, {
        panelClass: 'custom-modal-container',
        width: '900px'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.service.openNVDBMarketVehicles().subscribe(() => {
          this.serviceDocument = this.service.nvdbMarketVehiclesServiceDocument;
          this.nvdbSpecificationList = this.serviceDocument.dataProfile.dataModel.specificationList;
        });
      });
    });
  }


  /**
   * Delete Specifications
   * @param nvdbSpecSD NVDBMarketSpecificationsModel
   */
  public deleteNVDBSpecification(nvdbSpecSD: NVDBMarketSpecificationsModel) {
    const confirmationMsg = this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.ConfirmDelete)
      + ' "' + nvdbSpecSD.specificationDetails.code
      + '" ' + this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.NVDBSpecification)
      + ' ?';

    this.alertModalService
      .confirm(AlertMessages.delete, AlertMessages.WarnIcon, Message.DeleteNVDBCommoditySpecification, confirmationMsg, '')
      .subscribe((response) => {
        if (response === true) {
          this.nvdbCommodityService.deleteNVDBMarketCommoditySpecs(nvdbSpecSD.tariffItemSpecId)
            .subscribe((itemresponse) => {
              if (itemresponse) {
                if (this.nvdbSpecificationList.length > 0) {
                  const index = this.nvdbSpecificationList.findIndex(x => x.tariffItemSpecId ===
                    nvdbSpecSD.tariffItemSpecId);

                  if (index !== -1) {
                    this.nvdbSpecificationList.splice(index, 1);
                  }
                  this.ref.markForCheck();
                }
                this.toasterService.Success(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.RecordDeletedSuccessfully), null);
                this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details'],
                  { relativeTo: this.activatedRoute });
                this.ref.markForCheck();
              }
            },
              Error => {
                this.toasterService.Error(
                  this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
              });
        }
      });
  }


  private touchTheControls(fg: FormGroup) {
    Object.values(fg.controls).forEach(ctrl => {
      ctrl.markAsTouched();
    });
  }

  public ViewUpLoadDocs() {
    this.dialog.open(MarketResearchVehicalUploadComponent, {
      panelClass: 'custom-modal-container',
      width: '900px'
    });
  }

  /**
   * cancel
   */
  public cancel() {
    this.router.navigate(['/risk-identification-Analysis/market-research-data-vehicle']);
  }
}
