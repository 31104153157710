import { ProfileModel } from '@agility/frameworkcore';
import { CustomStationModel } from './customStationModel';

export class UserCustomStationModel extends ProfileModel {
    public userCustomStationId: number;
    public userId: string;
    public customStationId: number;
    public isDefault: string;
    public ownerLocId: number;
    public ownerCSId: number;
    public associatedCustomStations: any[];
}
