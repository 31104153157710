import { Component, OnInit } from '@angular/core';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonTypes, ModuleNames } from '../../common/Constants';
import { TrackingDashboradService } from './TrackingDashboradService';
import { ActivatedRoute } from '@angular/router';

interface marker {
  locationId: number;
  name: string;
  locationTypeId: number;
  countryId: number;
  Latitude: number;
  Longitude: number;
  mode: string;
  rms: string;
}

@Component({
  selector: 'app-tracking-dashboard',
  templateUrl: './TrackingDashboardComponent.html',
})


export class TrackingDashboardComponent implements OnInit {

  public trackingForm: FormGroup;
  public modeTypeCode: any = CommonTypes.MODEOFTRANSPORTDECLTYPES;
  public moduleName: any = ModuleNames.Declaration;

  // google maps zoom level
  zoom = 6;

  // initial center position for the map
  lat = 4.140634;
  lng = 109.6181485;

  public isFirst: boolean;

  public countries: any[] = [
    { value: 136, viewValue: 'MALAYSIA' },
    { value: 196, viewValue: 'SAUDI ARABIA' },
  ];

  public slectedData: marker;

  public markers: marker[] = [];

  constructor(
    public appConfig: McnConfig,
    private fb: FormBuilder,
    private trackingDashboradService: TrackingDashboradService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.trackingForm = this.fb.group({
      countryId: [136],
      modeoftransport: ['']
    });
    this.activatedRoute.data
      .subscribe(() => {
        this.isFirst = true;
        this.trackingDashboradService.list(136, this.trackingForm.controls.modeoftransport.value, true).subscribe((res) => {
          this.markers = res;
        });
      });
  }

  public reset(event: any) {
    this.trackingForm.controls.modeoftransport.setValue(0);
    this.trackingDashboradService.list(this.trackingForm.controls.countryId.value, 0, true).subscribe((res) => {
      this.markers = res;
    });
  }

  public selectedCountry(res: any) {
    if (res.options.value === 196) {
      this.lat = 24.266906;
      this.lng = 45.107849;
    } else {
      this.lat = 4.140634;
      this.lng = 109.6181485;
    }

    const selectAll = (this.trackingForm.controls.modeoftransport.value === '' || this.trackingForm.controls.modeoftransport.value === 0);
    this.trackingDashboradService.list(this.trackingForm.controls.countryId.value,
      this.trackingForm.controls.modeoftransport.value, selectAll).subscribe((res) => {
        this.markers = res;
      });
  }

  public selectedMode(res: any) {
    this.trackingDashboradService.list(this.trackingForm.controls.countryId.value,
      this.trackingForm.controls.modeoftransport.value, false).subscribe((res) => {
        this.markers = res;
      });
  }

  public getIcon(rms: any, mode: any) {
    mode = mode === 'SEA' ? 'Ship' : mode;
    mode = mode === 'AIR' ? 'Air' : mode;
    mode = mode === 'LAND' ? 'Truck' : mode;
    mode = mode === 'RAIL' ? 'Rail' : mode;
    const path: string = '../../../assets/shipImages/' + mode + '_' + rms + '.png';
    return path;
  }


  public clickedMarker(label: string, index: number) {
    this.slectedData = this.markers[index];
  }
}

