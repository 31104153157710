import { ProfileModel } from '@agility/frameworkcore';
import { HBContainerItem } from './billOfLadingLineContainerInformation/HBContainerItem';
import { HBVehicleItem } from './billOfLadingLineVehicleInformation/HBVehicleItem';

export class HBItemModel extends ProfileModel {
  public hbItemId: number;
  public billOfLadingId?: number;
  public description: string;
  public quantity?: number;
  public qantityUOM?: number;
  public grossWeight?: number;
  public grossWeightUOM?: number;
  public netWeight?: number;
  public netWeightUOM?: number;
  public volume?: number;
  public volumeUOM?: number;
  public hbItemNo: string;
  public cargoTypeId?: number;
  public actualQuantity?: number;
  public actualVolume?: number;
  public reasonRemarks?: string;
  public cargoClassId?: number;
  public hsCodeId?: number;
  public countryOfOriginId?: number;
  public country?: any;
  public transRefId: number;
  public undgNumber: string;
  public flashpoint: string;
  public noOfPackages?: number;
  public isSTAItem: string;
  public staCodeId?: number;
  public isNonConsole: string;
  public actualQty?: number;
  public assignedTo?: number;
  public assignedBy?: number;
  public assignedDate?: Date;
  public actualGWeight?: number;
  public subHeadingId?: number;
  public processedQty?: number;
  public availableQty?: number;
  public processedGrossWeight?: number;
  public availableGrossWeight?: number;
  public ownerCSId?: number;
  public ownerLocId?: number;
  public billofladingnumber: string;
  public hs6Code?: any;
  public billOfLadingLineNumber?: number;
  public billOfLadingDate?: Date;
  public cargoType?: any;
  public netWeightUnit?: any;
  public grossWeightUnit?: any;
  public volumeUnit?: any;
  public quantityUnit?: any;
  public cargoClassType: any;
  public isShortLanded = false;
  public isOverLanded = false;
  public containerList: HBContainerItem[] = [];
  public vehicleList: HBVehicleItem[] = [];
  public blQuantityReached: number;
  public cancelReasonId?: number;
  public cancelRemarks?: any;
  public reasonForAmendment?: any;
  public hbItemAmmended: any;
  public previousStateName: string;
  public amendmentReasonRemarks: string;
  public carrierMode: any;
  public quantityManifestedUOM: any;
}
