<div class="chart-container pt-5" [ngClass]="{'arb': (this.webStorageService.get('lang_code') === 'Arb')}">
    <h5 class="main-title m-0">
      AI Models (Revenue Forecasting & Gap Analysis, Fraud Detection)
    </h5>
    <div fxLayoutAlign="start center">
      <div fxFlex.gt-sm="100" fxFlex.lt-sm="100" class="mb-4 mt-3">
        <mat-tab-group #tabGroup [selectedIndex]="this.selectedTab" (selectedTabChange)="tabChanged($event)" class="carrier-tab-group">
          <mat-tab label="Forecasting">
            <ng-template mat-tab-label>
              {{'Forecasting' | translate}}
            </ng-template>
              <div *ngIf="this.selectedTab === 0">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">ARIMA Model - Training Data</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'ARIMA Model Training Data.'| translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                            <ngx-charts-line-chart
                                [view]="view"
                                [scheme]="colorSchemeData"
                                [legend]="showLegend"
                                [showXAxisLabel]="showXAxisLabel"
                                [showYAxisLabel]="showYAxisLabel"
                                [xAxis]="xAxis"
                                [yAxis]="yAxis"
                                [xAxisLabel]="xAxisLabel"
                                [yAxisLabel]="yAxisLabel"
                                [timeline]="timeline"
                                [results]="arima"
                                (select)="onSelect($event)"
                                (activate)="onActivate($event)"
                                (deactivate)="onDeactivate($event)"
                                [yAxisTickFormatting]="formatNumber"
                                [xAxisTickFormatting]="axisDate"
                                >
                                <ng-template #tooltipTemplate let-model="model">
                                  {{ '$' + model.value + ' Bn' }}
                                </ng-template>
                                <ng-template #seriesTooltipTemplate  let-model="model">
                                  <span *ngFor="let series of model">
                                    {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                    <br/>
                                  </span>                                
                                </ng-template>
                            </ngx-charts-line-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">ARIMA Model - Forecast</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'ARIMA Model Forecast.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorScheme"
                              [legend]="showLegend"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="arimaForecast"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>                      
                      <!-- <img src="../../../assets/images/rms/arima_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">MULTIVARIATE Model - Training Data</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'MULTIVARIATE Model Training Data.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeMultiVariate"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="multiVariate"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/multivariate.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">MULTIVARIATE Model - Forecast</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'MULTIVARIATE Model Forecast.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeForecast"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="multiVariateForecast"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>                      
                      <!-- <img src="../../../assets/images/rms/multivariate_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">LSTM Model - Training Data</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'LSTM Model Training Data.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeData"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="lstm"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/lstm.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">LSTM Model - Forecast</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'LSTM Model Forecast.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeForecast"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="lstmForecast"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                </div>
              </div>
          </mat-tab>
          <mat-tab label="Gap Analysis">
            <ng-template mat-tab-label>
              {{'Gap Analysis' | translate}}
            </ng-template>
            <div *ngIf="this.selectedTab === 1">
              <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
                <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">Hodrick-Prescott filter(HP filter)</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'Hodrick-Prescott filter(HP filter).'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeData"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="totalRevenue"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/hp_filter.png" style="width: 100%;" /> -->
                    </div>
                </div>
                <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">Hodrick-Prescott filter(HP filter) - Analysis                        </h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'Hodrick-Prescott filter(HP filter) - Analysis.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeForecast"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="trendRevenue"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/hp_filter_trend.png" style="width: 100%;" /> -->
                    </div>
                </div>
                <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">Hodrick-Prescott filter(HP filter) - Analysis</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'Hodrick-Prescott filter(HP filter) - Analysis.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <ngx-charts-line-chart
                              [view]="view"
                              [scheme]="colorSchemeData"
                              [legend]="showLegend"
                              [legendTitle]="'Determinants'"
                              [showXAxisLabel]="showXAxisLabel"
                              [showYAxisLabel]="showYAxisLabel"
                              [xAxis]="xAxis"
                              [yAxis]="yAxis"
                              [xAxisLabel]="xAxisLabel"
                              [yAxisLabel]="yAxisLabel"
                              [timeline]="timeline"
                              [results]="cycle"
                              (select)="onSelect($event)"
                              (activate)="onActivate($event)"
                              (deactivate)="onDeactivate($event)"
                              [yAxisTickFormatting]="formatNumber"
                              [xAxisTickFormatting]="axisDate"
                              >
                              <ng-template #tooltipTemplate let-model="model">
                                {{ '$' + model.value + ' Bn' }}
                              </ng-template>
                              <ng-template #seriesTooltipTemplate  let-model="model">
                                <span *ngFor="let series of model">
                                  {{ series.series}}:{{ ' $' + series.value + ' Bn' }}
                                  <br/>
                                </span>                                
                              </ng-template>
                          </ngx-charts-line-chart>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/hp_filter_cycle.png" style="width: 100%;" /> -->
                    </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Risk Profiling & Fraud Detection">
            <ng-template mat-tab-label>
              {{'Risk Profiling & Fraud Detection' | translate}}
            </ng-template>
            <div *ngIf="this.selectedTab === 2">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">RandomForest Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'RandomForest Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_random" 
                        [chartData]="this.recallRandomForestData" 
                        [chartOptions]="column_chart_recall_random" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">RandomForest Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'RandomForest Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_random" 
                        [chartData]="this.revenueRandomForestData" 
                        [chartOptions]="column_chart_revenue_random" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">AdaBoost Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'AdaBoost Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_ada" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_recall_ada" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">AdaBoost Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'AdaBoost Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_ada" 
                        [chartData]="this.revenueAdaBoostData" 
                        [chartOptions]="column_chart_revenue_ada" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">XGBoost Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'XGBoost Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_xgb" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_recall_xgb" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">XGBoost Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'XGBoost Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_xgb" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_revenue_xgb" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">LightGBM Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'LightGBM Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_lightgbm" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_recall_lightgbm" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">LightGBM Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'LightGBM Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_lightgbm" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_revenue_lightgbm" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">CatBoost Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'CatBoost Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_cat" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_recall_cat" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">CatBoost Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'CatBoost Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_cat" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_revenue_cat" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">NaiveEnsemble Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'NaiveEnsemble Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_naive" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_recall_naive" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">NaiveEnsemble Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'NaiveEnsemble Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_naive" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_revenue_naive" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">DATE Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'DATE Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_recall_date" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_recall_date" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="49" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">DATE Classifier</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'DATE Classifier.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="column_chart_revenue_date" 
                        [chartData]="this.recallAdaBoostData" 
                        [chartOptions]="column_chart_revenue_date" 
                        chartType="ColumnChart" 
                        GoogleChart></div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                </div>
            </div>
          </mat-tab>
          <mat-tab label="Fraud Detection(Comparison)">
            <ng-template mat-tab-label>
              {{'Fraud Detection(Comparison)' | translate}}
            </ng-template>
            <div *ngIf="this.selectedTab === 3">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">Fraud Detection</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'Fraud Detection.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <div id="line_chart_recall" 
                            [chartData]="this.recallData" 
                            [chartOptions]="line_ChartRecallOptions" 
                            chartType="LineChart" 
                            GoogleChart>
                          </div>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                  <div fxFlex="100" fxFlex.xs="100" class="my-3">
                    <div class="card-grey-border chart-card" style="min-height: 300px">
                      <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
                        <div fxFlex="75" fxFlex.xs="75" class="px-2">
                          <h4 class="main-title mb-4">Fraud Detection</h4>
                        </div>
                        <div fxFlex="25" fxFlex.xs="25">
                          <div fxLayoutAlign="end center"  fxLayout="row wrap">
                            <a matTooltip="{{'Fraud Detection.'|translate}}">
                              <i class="info-icon-dark"></i>
                            </a>
                          </div>
                        </div>
                        <div fxFlex="100" fxFlex.xs="75">
                          <div id="line_chart_revenue" 
                            [chartData]="this.revenueData" 
                            [chartOptions]="line_ChartRevenueOptions" 
                            chartType="LineChart" 
                            GoogleChart>
                          </div>
                        </div>
                      </div>
                      <!-- <img src="../../../assets/images/rms/lstm_forecast.png" style="width: 100%;" /> -->
                    </div>
                  </div>
                </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>