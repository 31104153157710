<div [ngSwitch]="msg.lang">
<div *ngSwitchCase="'en'" class="ml-3 mr-3">
<div [ngSwitch]="msg.sendBy">
  <div *ngSwitchCase="'bot'" class="msg" [class.show]="isVisible">
    <div class="card bot p-3 mt-2">
      <div fxFlex="100" fxLayout="row wrap">
        <div fxFlex="17">
          <img src="../../../../assets/images/chatBotImages/chat_bot_logo.png" width="50" />
        </div>
        <div fxFlex="80" class="pl-2 buble-bot" fxLayoutAlign="end center">
          <h6 [innerHTML]="getString(this.msg.text)"></h6>
          <button type="button" (click)="feedback(this.msg.text,'positive')"> like </button>
          <button type="button" (click)="feedback(this.msg.text,'negative')"> dislike </button>
        </div>
      </div>
    </div>
    <div *ngFor="let option of this.msg.options; let i = index;" class="msg option-msg">
      <div class="flex-wrap my-2">
        <button (click)='testMethod(option, i+1)' type="button" aria-disabled="false" class="chat-msgbtn mt-0 mb-2 mr-2 ml-4"
        data-button-use="secondary" tabindex="0">
        <span [innerHTML]="option"> </span>
        </button>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'map'" class="card bot p-0 mt-2" [class.show]="isVisible">
      <agm-map [latitude]="this.msg.options[1]" [longitude]="this.msg.options[0]" style="height: 190px;">
        <agm-marker [latitude]="this.msg.options[1]" [longitude]="this.msg.options[0]"></agm-marker>
      </agm-map>
  </div>
  <div *ngSwitchCase="'option'" [class.show]="isVisible">
    <div class="card bot p-3 mt-2">
      <div fxFlex="100" fxLayout="row wrap">
        <div fxFlex="17">
          <img src="../../../../assets/images/chatBotImages/chat_bot_logo.png" width="50" />
        </div>
        <div fxFlex="83" class="buble-bot">
          <div fxFlex="100" fxLayout="row wrap">
            <div fxFlex="100" fxLayout="column wrap">
              <h6 style="color:black">
                {{"Hello! I am your personal virtual assistant and I am here to help you. Before we begin, please select the language you prefer."}}
              </h6>
            </div>
            <!-- <div fxFlex="100" fxLayout="column wrap">
              <h6 style="color:black" class="arb"> {{"مرحبا! أنا مساعدك الشخصي الافتراضي  وأنا هنا لمساعدتك. قبل أن نبدأ ، يرجى تحديد اللغة التي تفضلها"}}</h6>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="100" fxLayout="row wrap">
      <div fxFlex="50" class="flex-wrap my-2">
        <button type="button" (click)='change_language_english()' aria-disabled="false" class="chat-msgbtn mt-0 mb-2 mr-2 ml-4"
        data-button-use="secondary" tabindex="0">
        <span>{{"English"}}</span>
        </button>
      </div>      
    </div>
  </div>
  <div *ngSwitchCase="'user'" class="msg user-msg" [class.show]="isVisible">
    <div class="card user">
      <div fxFlex="100" fxLayout="row wrap" class="mt-2">
        <div fxFlex="72" fxLayoutAlign="end center" class="buble-user pr-2 mr-2">
          <h6 [innerHTML]="this.msg.text"></h6>
        </div>
        <div fxFlex="20">
          <img src="../../../../assets/images/chatBotImages/user1.png" width="50" />

        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div *ngSwitchCase="'ar'" class='arb ml-3 mr-3'>
  <div [ngSwitch]="msg.sendBy">
    <div *ngSwitchCase="'bot'" class="msg" [class.show]="isVisible">
      <div class="chat-bot">
        <div fxFlex="100" fxLayout="row wrap">
          <div fxFlex="17">
            <img src="../../../../assets/images/chatBotImages/chat_bot_logo.png" width="50" />
          </div>
          <div fxFlex="80" class="pl-2 buble-bot" fxLayoutAlign="end center">
            <h6 [innerHTML]="getString(this.msg.text)"></h6>
          </div>
        </div>
      </div>
      <div *ngFor="let option of this.msg.options; let i = index;">
        <div class="arb flex-wrap my-2">
          <button (click)='testMethod(option, i+1)' type="button" aria-disabled="false" class="chat-msgbtn mt-0 mb-2 mr-2 ml-4"
          data-button-use="secondary" tabindex="0">
          <span [innerHTML]="option"> </span>
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'map'" class="card bot p-0 mt-2" [class.show]="isVisible">
        <agm-map [latitude]="this.msg.options[1]" [longitude]="this.msg.options[0]" style="height: 190px;">
          <agm-marker [latitude]="this.msg.options[1]" [longitude]="this.msg.options[0]"></agm-marker>
        </agm-map>
    </div>
    <div *ngSwitchCase="'option'" [class.show]="isVisible">
      <div class="card bot">
        <div fxFlex="100" fxLayout="row wrap">
          <div fxFlex="20">
            <img src="../../../../assets/images/chatBotImages/chat_bot_logo.png" width="50" />
          </div>
          <div fxFlex="74" class="buble-bot" fxLayoutAlign="end center">
            <div fxFlex="100" fxLayout="row wrap">
              <div fxFlex="100" fxLayout="column wrap">
                <h6 style="color:black">
                  {{"Hello! I am your personal virtual assistant and I am here to help you. Before we begin, please select the language you prefer."}}
                </h6>              </div>
              <div fxFlex="100" fxLayout="column wrap">
                <h6 style="color:black" class="arb"> 
                  {{"مرحبا! أنا مساعدك الشخصي الافتراضي  وأنا هنا لمساعدتك. قبل أن نبدأ ، يرجى تحديد اللغة التي تفضلها"}}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="100" fxLayout="row wrap">
        <div fxFlex="50" class="flex-wrap my-2">
          <button type="button" (click)='change_language_english()' aria-disabled="false" class="chat-msgbtn mt-0 mb-2 mr-2 ml-4"
          data-button-use="secondary" tabindex="0">
          <span>{{"English"}}</span>
          </button>
        </div>        
      </div>
    </div>
    <div *ngSwitchCase="'user'" class="msg user-msg" [class.show]="isVisible">
      <div class="card user p-3 mt-2">
        <div fxFlex="100" fxLayout="row wrap">
          <div fxFlex="77" fxLayoutAlign="end center" class="buble-user">
            <h6 [innerHTML]="this.msg.text"></h6>
          </div>
          <div fxFlex="20">
            <img src="../../../../assets/images/chatBotImages/user1.png" width="50" />
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
