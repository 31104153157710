import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ChatBotDataService } from '../services/ChatBotDataService';

@Component({
  selector: 'chat-msg',
  templateUrl: './ChatMsgComponent.html',
})
export class ChatMsgComponent implements OnInit {
  @Input() msg: any;
  public isVisible = false;
  @Output() dataToPass = new EventEmitter();
  @Output() language_english = new EventEmitter();
  @Output() language_arabic = new EventEmitter();
  constructor(public dataService: ChatBotDataService) { }

  ngOnInit() {
    setTimeout(() => { this.isVisible = true; }, 0);
  }

  public feedback(data: string, userfeedback: string) {
    this.dataService.feedback('feedback', JSON.stringify({ 'botMessage': data, 'userfeedback': userfeedback }));
  }


  public getString(data: string) {
    let message = '';
    for (const t of data.split(' ')) {
      if (t.startsWith('http') && t.length > 1) {
        let l = '';
        const s = t.split('/');
        for (let i = 3; i < s.length; i++) {
          l += '/' + s[i];
        }
        message += '<a href="' + l + '" target="_blank">Here</a>';
      } else if (t.startsWith('#name')) {
        continue;
      } else {
        message += t + ' ';
      }
    }

    return message;
  }

  public testMethod(text: string, index: number) {
    this.dataToPass.emit([text, index]);
  }

  public change_language_english() {
    this.language_english.emit();
  }

  public change_language_arabic() {
    this.language_arabic.emit();
  }

}
