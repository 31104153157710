import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../app/storageService/storageService';
import { FilterMenuService } from '../../../../common/filter-menu.service';
import { RiskIdentificationAndAnalysisSharedService } from '../../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { OpmComplianceReportsComponent } from '../opmComplianceReports/OpmComplianceReportsComponent';

@Component({
  selector: 'app-opm-compliance-reports-list',
  templateUrl: './OpmComplianceReportListComponent.html',

})
export class OpmComplianceReportListComponent implements OnInit {

  opmComplianceReports = [
    {
      no: 1,
      name: 'Compliance Costs (In Hrs.)',
      description: 'This report provides the compliance costs (In Hrs.) for the assessed items',
    },
    {
      no: 2,
      name: 'AEO Traders',
      description: 'This report provides the total number of AEO traders',
    },
    {
      no: 3,
      name: 'Traders by Category',
      description: 'This report provides the number of traders and their respective compliance category',
    },

  ];

  opmDeclarationReports = [
    {
      no: 1,
      name: 'Pre-Arrival Declarations',
      description: 'This report provides the total Declarations submitted before the journey has arrived',
    },
  ];

  opmEnforcementReports = [
    {
      no: 1,
      name: 'PCA Seizures',
      description: 'This report provides information regarding the seizures through PCA (Post Clearance Audit)',
    },
    {
      no: 2,
      name: 'Number of Vessels Seized',
      description: 'This report provides the total number of vessels seized in a given time period',
    },
  ];

  opmWorkloadReports = [
    {
      no: 1,
      name: 'Average Time in the Port',
      description: 'This report provides the average time taken by the vessels to stay at the port',
    },
    {
      no: 2,
      name: 'Average Time for Customs Clearance',
      description: 'This report provides the average time for goods to go through Customs',
    },
    {
      no: 3,
      name: 'Average Time to Release Goods on Arrival',
      description: 'This report provides the average time to release goods on arrival',
    },
    {
      no: 4,
      name: 'Inspector Workload',
      description: 'This report gives the list of Inspections inspected by the Inspector at any point of time',
    },
    {
      no: 5,
      name: 'Number of Vessels Arriving at the Port',
      description: 'This report provides the total number of vessels arriving at the port',
    },

  ];

  opmPerformanceReports = [
    {
      no: 1,
      name: 'Risk Profile Performance',
      description: 'This report provides the performance of selected risk profile',
    },
  ];

  opmProhibitedReports = [
    {
      no: 1,
      name: 'Infringements and Seizures',
      description: 'This report provides information regarding the detections, infringements and seizures',
    },
    {
      no: 2,
      name: 'Value of Auctioned Goods',
      description: 'This report provides the value of auctioned goods (seized, forfeited, abandoned)',
    },
  ];

  opmRevenueReports = [
    {
      no: 1,
      name: 'Revenue by Channel',
      description: 'This report provides the percentage of revenue collected for each of RMS Channel (green, yellow and red)',
    },
    {
      no: 2,
      name: 'Revenue Forgone',
      description: 'This report provides the total revenue forgone',
    },
    {
      no: 3,
      name: 'Percentage Change in Item Value',
      description: 'This report provides the percentage change in value of imports and exports for items',
    },
  ];

  public viewHistory: boolean;
  public arrowColor: boolean;
  public showComplianceList: boolean;
  public showDeclarationList: boolean;
  public showEnforcementList: boolean;
  public showWorkloadList: boolean;
  public showPerformanceList: boolean;
  public showProhibitedList: boolean;
  public showRevenueList: boolean;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private filterMenuService: FilterMenuService,
    private router: Router,
    public webStorage: StorageService,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
  ) {
  }

  ngOnInit() {
    const name = this.riskIdentificationAndAnalysisSharedService.sharingData.opmReportName;
    if (name === 'Compliance') {
      this.showComplianceList = true;
    } else if (name === 'Declaration') {
      this.showDeclarationList = true;
    } else if (name === 'Enforcement') {
      this.showEnforcementList = true;
    } else if (name === 'Overall Workload') {
      this.showWorkloadList = true;
    } else if (name === 'Performance') {
      this.showPerformanceList = true;
    } else if (name === 'Prohibited Goods') {
      this.showProhibitedList = true;
    } else if (name === 'Revenue') {
      this.showRevenueList = true;
    }
  }

  public ViewComplianceReports(name: string) {
    this.riskIdentificationAndAnalysisSharedService.sharingData.opmEditReportName = name;
    this.dialog.open(OpmComplianceReportsComponent, {
      panelClass: 'custom-modal-container',
      width: '900px'
    });
  }


}
