
<div class="chart-container pt-5">
  <h4 class="main-title mb-4"> Tracking Details </h4>
  <p> <a href="javascript:void(0)" matTooltip="{{ 'Tracking' | translate }}" routerLink="/Dashboard/mode-tracking" fxLayoutAlign="end end"> Mode Based Tracking</a></p>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div fxFlex="100">
      <div class="card-grey-border chart-card">
        <h4 class="main-title mb-1">Tracking Details </h4>
        <div fxLayout="row wrap" fxLayoutAlign="end end">
          <div fxFlex="100" fxFlex.gt-sm="20" fxLayout="row" fxLayoutAlign="end center" class="px-2 position-relative">
            <mcn-select class="astric-star" placeholder="{{'Country' | translate }}" [formGroup]="this.trackingForm" optionKey="value"
                        optionValue="viewValue" controlName="countryId" [optionsData]="this.countries" (OnChange)="selectedCountry($event)"></mcn-select>
          </div>
          <div fxFlex="20" class="px-2 position-relative">
            <mcn-select optionKey="typeId" optionValue="name" [formGroup]="this.trackingForm" ngDefaultControl
                        controlName="modeoftransport" [apiUrl]="this.appConfig.getMcnTypes" placeholder="{{ 'ModeofTransportation' | translate }}" class="astric-star"
                        optionParameterKey="typeName" [optionParameterValue]="this.modeTypeCode"  (OnChange)="selectedMode($event)"
                        [moduleName]="this.moduleName">
            </mcn-select>
          </div>
          <div fxFlex="10" class="px-2 position-relative m-3">
            <mcn-button type="reset" cssClass="btn-custom-secondary" (OnClick)="reset($event)" text="{{'RESET' | translate }}"></mcn-button>
          </div>

        </div>
        <agm-map [zoom]="zoom" [disableDefaultUI]="true"
                 [latitude]="this.lat"
                 [longitude]="this.lng"
                 [zoomControl]="true">

          <agm-marker *ngFor="let m of markers; let i = index"
                      (markerClick)="clickedMarker(m.label, i)"
                      [latitude]="m.latitude"
                      [longitude]="m.longitude"
                      [iconUrl]="this.getIcon(m.rms,m.mode)">

            <agm-info-window>
              <div style="width:210px; height:100px; background:#fff; padding-top: 20px; padding-left: 10px;">
                <table width="100%" cellpadding="2" cellspacing="4" border="0">
                  <tr>
                    <td width="40%" style="font-family:Arial; font-size:12px; color:#808080">Name</td>
                    <td width="5%">:</td>
                    <td width="55%" style="font-weight:bold; color:#ff6a00">{{this.slectedData?.name}}</td>
                  </tr>
                  <tr>
                    <td style="font-family:Arial; font-size:12px; color:#808080">RMS Channel</td>
                    <td>:</td>
                    <td style="font-weight:bold; color:#ff6a00">{{this.slectedData?.rms}}</td>
                  </tr>
                  <tr>
                    <td style="font-family:Arial; font-size:12px; color:#808080">Latitude</td>
                    <td>:</td>
                    <td style="font-weight:bold; color:#ff6a00">{{this.slectedData?.latitude}}</td>
                  </tr>
                  <tr>
                    <td style="font-family:Arial; font-size:12px; color:#808080">Longitude</td>
                    <td>:</td>
                    <td style="font-weight:bold; color:#ff6a00">{{this.slectedData?.longitude}}</td>
                  </tr>
                </table>
              </div>
            </agm-info-window>

          </agm-marker>
        </agm-map>
      </div>
    </div>
  </div>
</div>
