export class TariffLinesFilterModel {
  public tariffCode: string;
  public subHeadingCode: string;
  public headingCode: string;
  public chapterCode: string;
  public pageIndex?: number;
  public recordPerPage?: number;
  public orderIndex: number;
  public isDescending?: boolean;
  public description: string;
}

