import { Injectable, PipeTransform } from '@angular/core';

@Injectable()
export class FilterService implements PipeTransform {
  public transform(items: any[], filter: { [key: string]: any }): any[] {
    return items.filter((item) => {
      const notMatchingField = Object.keys(filter)
        .find((key) => item[key] !== filter[key]);

      return !notMatchingField; // true if matches all fields
    });
  }
}
