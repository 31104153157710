import { CommonService, FxContext, ServiceDocument, ServiceDocumentResult, UserProfile } from '@agility/frameworkcore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatTabChangeEvent } from '@angular/material';
import { Router } from '@angular/router';
import { StorageService } from '../../storageService/storageService';
import { TranslateService } from 'ng2-translate';
import { LanguageSelectionModalComponent } from '../../header/languageSelection/LanguageSelectionModalComponent';
import { LoginService } from './LoginService';
import { ForgotPasswordActivationComponent } from './passwordManagement/forgotPasswordActivation/forgotPasswordActivationComponent';
import { ForgotPWDComponent } from './passwordManagement/forgotPwd/forgotPwdComponent';
import { LoginViewModel } from './viewmodels/LoginViewModel';
import { CaptchaModel } from './viewmodels/CaptchaViewModel';
import { ValidationService } from '../../validations/ValidationService';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { AccountMessages, AppKeySize } from '../constant/accountConstant';
import { AlertMessageModalComponent } from '../../commonComponents/commonModals/AlertMessageModalComponent';
import * as CryptoJS from 'crypto-js';
import { SafeUnsubscriber } from '../../common/SafeUnsubscriber';
import { CaptchaComponent } from 'angular-captcha';
import { ToasterService } from '../../toastrService/toasterService';
import { environment } from '@env/environment';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { Subject, Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { IdleSessionModalComponent } from '../../idleSessionService/IdleSessionModal';
import { IdleSessionService } from '../../idleSessionService/IdleSessionService'
@Component({
  selector: 'login',
  templateUrl: './LoginComponent.html',
  // tslint:disable-next-line: comment-format
})

export class LoginComponent extends SafeUnsubscriber implements OnInit {
  public loginForm: FormGroup;
  public dataModel: LoginViewModel;
  public captchaModel: CaptchaModel;
  public serviceDocument: ServiceDocument<UserProfile>;
  public forgotPWDComponent: ForgotPWDComponent;
  public forgotPasswordActivationComponent: ForgotPasswordActivationComponent;
  public logval: ServiceDocumentResult;
  public arblanguage: boolean;
  public loginData: any;
  public isMicroService = environment.appSettings.keys.isMicroService;
  @ViewChild(CaptchaComponent) captchaComponent: CaptchaComponent;
  public msg: Subject<any> = new Subject();
  public msgArray: Observable<Array<any>> = new Observable<Array<any>>();
  public activeClass: boolean = false;
  public selectedTab: any;
  //public estimator: boolean = false;
  public tariff: boolean;
  public duty: boolean = false;
  public radioData = [
    { 'radioID': 1, 'radioName': 'Import' },
    { 'radioID': 2, 'radioName': 'Export' }
  ];

  constructor(private fb: FormBuilder,
    private fxContext: FxContext,
    private router: Router,
    public appConfig: McnConfig,
    private accountService: LoginService,
    private commonService: CommonService,
    private service: LoginService,
    private dialog: MatDialog,
    public mcnConfig: McnConfig,
    private validationService: ValidationService,
    public storageService: StorageService,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private toasterService: ToasterService,
    private oauthStorage: OAuthStorage,
    private idleSessionService: IdleSessionService) {
    super();

    // this.logOut();
    this.idpClear();
    if (!isNullOrUndefined(this.storageService.get('isSession')) && Boolean(this.storageService.get('isSession')) === true) {
      this.openSessionDailog();
    }
    this.storageService.removeAll();
    if (this.storageService.get('lang_code')) {
      this.translate.use(this.storageService.get('lang_code').toString());
    } else {
      this.storageService.set('lanPath', '../../../assets/i18n/English.jpg');
      this.storageService.set('lang_code', 'en');
      this.storageService.set('language_Id', 2);
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    this.mcnConfig.getJson().subscribe((response) => {
      this.storageService.set('localeJsonFile', response);
    });
    this.idpClear();
    this.appConfig.getLanguageBasedJson();
    this.dataModel = new LoginViewModel();
    this.dataModel.email = '';
    this.dataModel.password = '';
    this.dataModel.rememberMe = false;
    this.dataModel.userCaptchaInput = '';
    this.dataModel.userEnteredCaptchaCode = '';
    this.dataModel.captchaId = '';

    this.loginForm = this.commonService.getFormGroup(this.dataModel, 0);
    this.loginForm.controls.email.setValidators([Validators.required, this.validationService.EmailValidation()]);
    this.loginForm.controls.password.setValidators([Validators.required]);
  }

  private idpClear() {
    this.accountService.logoutIdp(this.oauthService.getAccessToken()).subscribe((res) => {
    });
    this.oauthService.logOut();
    this.oauthStorage.removeItem('access_token');
    this.oauthStorage.removeItem('id_token');
    this.oauthStorage.removeItem('refresh_token');
    localStorage.removeItem('nonce');
    localStorage.removeItem('PKCI_verifier');
    this.oauthStorage.removeItem('nonce');
    this.oauthStorage.removeItem('PKCI_verifier');
    this.oauthStorage.removeItem('expires_at');
    this.oauthStorage.removeItem('id_token_claims_obj');
    this.oauthStorage.removeItem('id_token_expires_at');
    this.oauthStorage.removeItem('id_token_stored_at');
    this.oauthStorage.removeItem('access_token_stored_at');
    this.oauthStorage.removeItem('granted_scopes');
    this.oauthStorage.removeItem('session_state');
  }

  public goToIDPServerLogin() {
    if (this.isMicroService) {
      this.oauthService.configure(environment.appSettings.authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(x => {
        if (this.oauthService.hasValidAccessToken()) {
          this.fxContext.IsAuthenticated = this.oauthService.hasValidAccessToken();
        } else {
          this.oauthService.initImplicitFlow();
        }
      });
    }
  }

  public openForgotPWDDialog(event: any) {
    this.dialog.open(ForgotPWDComponent, {
      panelClass: 'forgot-pwd-modal',
    });
  }

  public validate(): any {
    if (this.loginForm.valid) {
      this.fxContext.userProfile = null;
      this.service.serviceDocument.userProfile = null;
      this.loginForm.value.password = this.getEncryptData(this.loginForm.value.password);
      this.accountService.login(this.loginForm.value).subscribe(() => {
        const responseContext = this.service.serviceDocument.responseContext['loginResult'];
        if (responseContext !== null && responseContext !== undefined) {
          if (responseContext.isLockedOut) {
            this.showaccessFailMsg();
            return;
          } else if (responseContext.succeeded && this.service.serviceDocument.userProfile !== null) {
            this.loginData = this.service.serviceDocument;
            this.fxContext.userProfile = this.service.serviceDocument.userProfile;
            this.storageService.set('IsRandomPassword', this.loginData.isRandomPassword);
            this.fxContext.IsAuthenticated = true;
            this.storageService.set('isAuthenticated', 'true');
            this.storageService.set('defaultCustomsId', this.loginData.defaultCustomsId);
            this.storageService.set('defaultCustomsName', this.loginData.defaultCustomsName);
            this.storageService.set('defaultRole', this.loginData.defaultRole);
            this.storageService.set('defaultPort', this.loginData.defaultPort);
            this.storageService.set('userRoles', this.loginData.userProfile.userRoles.map(x => x.roleId));
            this.storageService.set('defaultUserId', this.service.serviceDocument.userProfile.userId);
            this.fxContext.menus = this.service.serviceDocument.userProfile.menus;
            this.storageService.set('userProfile', JSON.stringify(this.service.serviceDocument.userProfile));
            this.storageService.set('name', this.service.serviceDocument.userProfile.name);
            this.router.navigate(['Dashboard/Home']);
            this.idleSessionService.start();
            this.storageService.set("isSession", false);
          } else {
            this.toasterService.Warning(this.appConfig.getHardCodeMSg(AccountMessages.InvalidLogin)
              + ',' + this.appConfig.getHardCodeMSg(AccountMessages.PleaseTryAgain));
            return false;
          }
        } else {
          this.toasterService.Warning(this.appConfig.getHardCodeMSg(AccountMessages.InvalidLogin)
            + ',' + this.appConfig.getHardCodeMSg(AccountMessages.PleaseTryAgain));
          return false;
        }
      }, () => {
        this.toasterService.Warning(this.appConfig.getHardCodeMSg(AccountMessages.LoginFailedPleaseTryAgain));
        return false;
      });

    } else {
      Object.keys(this.loginForm.controls).map(
        (controlName) => this.loginForm.controls[controlName]).forEach(
          (control) => { control.markAsTouched(); },
        );
    }
  }

  public openLanguageSelectionDialog() {
    this.dialog.open(LanguageSelectionModalComponent, {
      panelClass: 'language-selection-modal',
    });
  }

  public openSessionDailog() {
    const dialogRef = this.dialog.open(IdleSessionModalComponent, {
      panelClass: 'session-warn-modal',
      disableClose: true
    });
    dialogRef.componentInstance.countdown = 0;
    dialogRef.componentInstance.timeoutWarinig();
    dialogRef.afterClosed().subscribe(
      () => {
        this.fxContext.IsAuthenticated = false;
        this.dialog.closeAll();
      });
  }

  public showaccessFailMsg() {
    const dialogRefAct = this.dialog.open(AlertMessageModalComponent, {
      panelClass: 'custom-modal-container',
      width: '350px'
    });
    dialogRefAct.componentInstance.icon = 'warn-icon';
    dialogRefAct.componentInstance.state = 'delete';
    dialogRefAct.componentInstance.isButtons = false;
    dialogRefAct.componentInstance.title = this.appConfig.getHardCodeMSg(AccountMessages.LackedOut);
    dialogRefAct.componentInstance.Message = this.appConfig.getHardCodeMSg(AccountMessages.AccessFailAcountMsg);
    dialogRefAct.afterClosed().subscribe(
      () => { });
  }

  private getEncryptData(password: string) {
    const key = CryptoJS.enc.Utf8.parse(this.appConfig.appKey);
    const iv = CryptoJS.enc.Utf8.parse(this.appConfig.appKey);
    const encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
      {
        keySize: parseInt(AppKeySize.IsOneTwoEight) / parseInt(AppKeySize.IsEight),
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encryptedpassword.toString();
  }

  public logOut() {
    if (!isNullOrUndefined(this.fxContext) && (this.fxContext.IsAuthenticated === true)) {
      this.accountService.logout().subscribe(
        (response) => {
          const result = response;
          if (result) {
            if (environment.appSettings.keys.isMicroService) {
              this.accountService.logoutIdp(this.oauthService.getAccessToken()).subscribe((res) => {
              });
            }
            this.service.serviceDocument.userProfile = null;
            this.fxContext.IsAuthenticated = false;
            this.fxContext.userProfile = null;
            this.idleSessionService.stop();
            if (environment.appSettings.keys.isMicroService) {
              this.oauthService.logOut();
              this.accountService.logoutIdp(this.oauthService.getAccessToken()).subscribe((res) => {
              });
            }
          }
        });
    }
  }

  public onChange(target: any) {
    this.msg.next(target.value);
    target.value = '';
  }

  public onMsgReceive(msg: string) { }

  // Toggle Class
  public onToggleClass() {
    this.activeClass = !this.activeClass;
  }

  public openLogin() {
    this.router.navigate(['./Account/LoginHome']);
  }

  public tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.getTariffTabList(tabChangeEvent.index);
  }

  private getTariffTabList(id: number) {
    if (id === 0) {
      this.selectedTab = id;
      this.tariff = true;
    } else if (id === 1) {
      this.selectedTab = id;
      this.duty = true;
    }
  } 
}
