import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FilterMenuService } from '../../../common/filter-menu.service';
import { RiskIdentificationAndAnalysisSharedService } from '../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { CommodityProfileService } from './commodity360DegreeProfile/CommodityProfileService';
import { CommodityHsCodeModel } from '../models/CommodityHsCodeModel';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { CommonSharedService } from '../../../common/CommonSharedService';
import { ToasterService } from '../../../toastrService/toasterService';
import { GlobalSplCharforSplit } from '../../../common/Constants';
import { IndexValue, ModelName, SuccessErrMsgs } from '../../riskSelectivityProfiling/common/Constants';
import { SafeUnsubscriber } from '../../../../app/common/SafeUnsubscriber';
import { StorageService } from '../../../../app/storageService/storageService';

@Component({
  selector: 'app-risk-analysis-commodity',
  templateUrl: './CommodityProfileComponent.html',
})

export class CommodityProfileComponent extends SafeUnsubscriber implements OnInit {
  public filterMenuOpened: boolean;
  public cmmdtyProfileFltrForm: FormGroup;
  public pageCount: number;
  private serviceDocument: any = [];
  public hsList: CommodityHsCodeModel[];
  public selectedIndex: any = IndexValue.IsOne;
  public selectedDec = true;
  public selectedAsc = false;
  private commodityExpression: any;
  private expression = '';

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private filterMenuService: FilterMenuService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    private service: CommodityProfileService,
    public mcnConfig: McnConfig,
    public webStorage: StorageService,
    private commonSharedService: CommonSharedService,
    private toasterService: ToasterService) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.getListData();
    });

    this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
      this.filterMenuOpened = isFilterMenuOpened;
    });
    this.cmmdtyProfileFltrForm = this.fb.group({
      tariffCode: [''],
      description: ['']
    });

  }

  public FiltermenuOpen() {
    if (this.filterMenuOpened === undefined || this.filterMenuOpened === false) {
      this.filterMenuOpened = true;
    } else {
      this.filterMenuOpened = false;
    }
  }
  public openCommodityProfile(hsCode: string) {
    this.riskIdentificationAndAnalysisSharedService.sharingData.hsCode = hsCode;
    this.router.navigate(['risk-identification-Analysis/commodity-profile-details']);
  }

  public sort(event: any, orderIndex: any, isDescending: boolean) {
    event.preventDefault();
    // const expression = this.filterExpression();
    this.service.isDescending = isDescending;
    this.service.orderIndex = orderIndex;
    const pageIndex = event == null ? IndexValue.IsOne : event.PageIndex;
    const pageCount = event == null ? this.mcnConfig.appData.associatePageCount : event.PageCount;
    this.service.listHsCodes(this.expression, ModelName.TariffItemsModel, pageIndex, pageCount).subscribe(() => {
      this.getListData();
    });
  }

  private getListData() {
    this.serviceDocument = this.service.hsCodeServiceDocument;
    if (this.serviceDocument.dataProfile !== undefined) {
      this.hsList = this.serviceDocument.dataProfile.dataList;
    } else {
      this.hsList = [];
    }
    if (this.serviceDocument.totalCount !== null) {
      this.pageCount = this.serviceDocument.totalCount;
    }
  }

  public filter() {
    this.expression = this.filterExpression();
    this.service.listHsCodes(this.expression, ModelName.TariffItemsModel).subscribe((response) => {
      this.getListData();
    });
  }

  private filterExpression() {
    const code = this.cmmdtyProfileFltrForm.value['tariffCode'];
    const desc = this.cmmdtyProfileFltrForm.value['description'];
    this.expression = null;

    if (!this.commonSharedService.isNotNullOrUndefined(code)
      && !this.commonSharedService.isNotNullOrUndefined(desc)) {
      this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.PleaseSelectoneValue));
    } else {
      if (this.commonSharedService.isNotNullOrUndefined(code)) {
        if (this.expression == null) {
          this.expression = `TariffItemsModel.TariffCode.ToUpper().Contains("${code.toUpperCase()}")`;
        } else {
          this.expression += `${GlobalSplCharforSplit.Char1}TariffItemsModel.TariffCode.ToUpper()
                                .Contains("${code.toUpperCase()}")`;
        }
      }
      if (this.commonSharedService.isNotNullOrUndefined(desc)) {
        if (this.expression == null) {
          this.expression = `TariffItemsModel.Description.ToUpper().Contains("${desc.toUpperCase()}")`;
        } else {
          this.expression += `${GlobalSplCharforSplit.Char1}TariffItemsModel.Description.ToUpper()
                          .Contains("${desc.toUpperCase()}")`;
        }
      }
      return this.expression;
    }
  }

  public resetFilter() {
    this.cmmdtyProfileFltrForm.reset();
    this.expression = '';
    this.service.listHsCodes('', ModelName.TariffItemsModel).subscribe((response) => {
      this.getListData();
    });
  }

  public doPagination(response: any) {
    this.service.listHsCodes(
      this.expression, ModelName.TariffItemsModel, response.PageIndex, response.PageCount)
      .subscribe((_response) => {
        this.hsList = this.service.hsCodeServiceDocument.dataProfile.dataList;
      });
  }
}
