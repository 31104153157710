import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { settings } from 'cluster';


export namespace ApiAiConstants {
  export enum AVAILABLE_LANGUAGES {
    EN = 'en' as any, DE = 'de' as any, ES = 'es' as any, PT_BR = 'pt-BR' as any, ZH_HK = 'zh-HK' as any,
    ZH_CN = 'zh-CN' as any, ZH_TW = 'zh-TW' as any, NL = 'nl' as any, FR = 'fr' as any, IT = 'it' as any,
    JA = 'ja' as any, KO = 'ko' as any, PT = 'pt' as any, RU = 'ru' as any, UK = 'uk' as any,
    HI = 'hi' as any, AR = 'ar' as any
  }

  // export const DEFAULT_BASE_URL: string = environment.appSettings.apiEndpoints.chatBotBaseUrl;
  export const DEFAULT_BASE_URL: string = 'http://10.138.108.33:5012/v1/web_chatbot';
  
  export const DEFAULT_CLIENT_LANG: AVAILABLE_LANGUAGES = AVAILABLE_LANGUAGES.EN;
  // export const DEFAULT_CLIENT_LANG: AVAILABLE_LANGUAGES = AVAILABLE_LANGUAGES.HI;
  // export const DEFAULT_CLIENT_LANG: AVAILABLE_LANGUAGES = AVAILABLE_LANGUAGES.AR;
}

