import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NVDBMarketVehiclesModel } from '../../models/NVDBMarketVehiclesModel';
import { NVDBMarketSpecificationsModel } from '../../models/NVDBMarketSpecificationsModel';
import { RiskIdentificationAndAnalysisSharedService } from '../sharedServices/RiskIdentificationAndAnalysisSharedService';
import { QueryStringParameter, SuccessErrMsgs } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';

@Injectable()
export class NVDBMarketVehiclesService {
  public nvdbMarketVehiclesServiceDocument: ServiceDocument<NVDBMarketVehiclesModel> = new ServiceDocument<NVDBMarketVehiclesModel>();
  public nvdbSpecServiceDocument: ServiceDocument<NVDBMarketSpecificationsModel> = new ServiceDocument<NVDBMarketSpecificationsModel>();
  public nvdbHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  public orderIndex = 1;
  public isDescending = true;

  constructor(
    private httpClient: HttpClient,
    private toasterService: ToasterService,
    public rmsConfig: RmsConfig,
    public mcnConfig: McnConfig,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService
  ) { }

  public newNVDBMarketVehicles(): Observable<ServiceDocument<NVDBMarketVehiclesModel>> {
    return this.nvdbMarketVehiclesServiceDocument.new(this.rmsConfig.newNVDBMarketVehicles)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
      })));
  }

  public listNVDBMarketVehicles(
    expression?: string,
    aliasName?: string,
    pageIndex: number = 1,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = 1,
    isDescending: boolean = true
  ): Observable<ServiceDocument<NVDBMarketVehiclesModel>> {
    const nvdbMarketVehiclesFilterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };

    nvdbMarketVehiclesFilterModel.orderIndex = this.orderIndex;
    nvdbMarketVehiclesFilterModel.isDescending = this.isDescending;

    return this.nvdbMarketVehiclesServiceDocument.list(
      this.rmsConfig.listNVDBMarketVehicles, nvdbMarketVehiclesFilterModel)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
      })));
  }

  public openNVDBMarketVehicles(): Observable<ServiceDocument<NVDBMarketVehiclesModel>> {
    return this.nvdbMarketVehiclesServiceDocument.open(
      this.rmsConfig.openNVDBMarketVehicles, new HttpParams()
        .set(QueryStringParameter.id, this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketVehicleId.toString())
    ).pipe(map((response) => response), catchError(() => throwError({
      msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
    })));
  }

  public deleteNVDBMarketVehicles(id: number): Observable<any> {
    return this.httpClient.post(this.rmsConfig.deleteNVDBMarketVehicles, new HttpParams()
      .set(QueryStringParameter.nvdbMarketVehicleId, id.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
      })));
  }

  public submitNVDBMarketVehicles(): Observable<any> {
    return this.nvdbMarketVehiclesServiceDocument.submit(this.rmsConfig.submitNVDBMarketVehicles)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
      })));
  }


  /******************************** NVDB Market Vehicles Specifications **************************/


  public newNVDBMarketVehiclesSpecs(): Observable<ServiceDocument<NVDBMarketSpecificationsModel>> {
    return this.nvdbSpecServiceDocument.new(this.rmsConfig.newNVDBMarketVehiclesSpecifications)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
      })));
  }

  public listNVDBMarketVehiclesSpecs(
    pageIndex: any = 1,
    recordPerPage: any = this.mcnConfig.appData.pageCount
  ): Observable<ServiceDocument<NVDBMarketSpecificationsModel>> {
    return this.nvdbSpecServiceDocument.list(
      this.rmsConfig.listNVDBMarketVehiclesSpecifications, new HttpParams()
        .set(QueryStringParameter.nvdbMarketVehicleId,
          this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketVehicleId.toString())
        .set(QueryStringParameter.pageIndex, pageIndex.toString())
        .set(QueryStringParameter.recordPerPage, recordPerPage.toString())
    ).pipe(map((response) => response), catchError(() => throwError({
      msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
    })));
  }

  public openNVDBMarketVehiclesSpecs(): Observable<ServiceDocument<NVDBMarketSpecificationsModel>> {
    return this.nvdbSpecServiceDocument.open(
      this.rmsConfig.openNVDBMarketVehiclesSpecifications, new HttpParams()
        .set(QueryStringParameter.id, this.riskIdentificationAndAnalysisSharedService.sharingData.tariffItemSpecId.toString())
    ).pipe(map((response) => response), catchError(() => throwError({
      msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
    })));
  }

  public deleteNVDBMarketVehiclesSpecs(formModel: NVDBMarketSpecificationsModel): Observable<any> {
    return this.httpClient.post(this.rmsConfig.deleteNVDBMarketVehiclesSpecifications, formModel, { headers: this.nvdbHeaders })
      .map(response => this.extractSubmitData(response))
      .pipe(map((response) => response),
        catchError(() => throwError({ msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true })));
  }

  public submitNVDBMarketVehiclesSpecs(): Observable<any> {
    return this.nvdbSpecServiceDocument.submit(this.rmsConfig.submitNVDBMarketVehiclesSpecifications)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketVehiclesHandleError(), ngNavigationCancelingError: true
      })));
  }

  public NVDBMarketVehiclesHandleError() {
    return this.toasterService.Error(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
  }

  private extractSubmitData(response: any) {
    const body = response;
    return body || [];
  }
}
