import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { lineData2017, lineData2018 } from '../data/data';

@Component({
  selector: 'app-line-chart',
  templateUrl: './lineChartComponent.html',
})
export class lineChartComponent implements OnInit {
  @Input() public optionsData: any;
  @Input() public yearData: any;
  @Input() public optionsCurrYearData: any;
  @Input() public optionsPrevYearData: any;

  public view: any[] = [1000, 250];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public showYAxisLabel = true;
  public showGridLines = false;
  public animations = true;
  public lineChartForm: FormGroup;

  public colorScheme = {
    domain: ['#4ed8da', '#f69679', '#ec008c']
  };

  ngOnInit() {
    this.lineChartForm = this.fb.group({
      year: ['']
    });
  }


  public onYearSelect(value: any) {
    if (value.options.value === '2020') {
      this.optionsData = this.optionsPrevYearData;
    } else if (value.options.value === '2021') {
      this.optionsData = this.optionsCurrYearData;
    }

  }

  public line_ChartOptions = {
    title: '',
    curveType: 'function',
    legend: {
      position: 'bottom'
    }
  };


  constructor(
    private fb: FormBuilder) {
  }

  public onSelect(event) {
  }
}
