import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';


@Injectable()
export class FilterMenuService {
  public isFilterOpened = false;
  public isOrgFromAddnlUsrMngmt = false;
  private toggleFilter: Subject<boolean> = new Subject<boolean>();

  public toggleFilterMenu(isFilterByTabChange: boolean = true): void {
    this.isFilterOpened = isFilterByTabChange === false ? isFilterByTabChange : !this.isFilterOpened;
    this.toggleFilter.next(this.isFilterOpened);
  }

  public isFilterMenuOpen(): Observable<boolean> {
    return this.toggleFilter.asObservable();
  }
}
