<div class="rndm-scltn-cnfg-list" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="column Wrap">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="90" class="main-title main-title-rndm-scltn-cnfg-list pb-0">
                        <span>{{'MirrorAnalysisForm' | translate}}</span>
                    </h2>
                </div>
                <div>
                    <hr class="hr-line">
                </div>

            </div>
        </div>
        <div fxLayout="column">
          <form [formGroup]="mirrorAnalysisForm" novalidate>
            <div mat-dialog-content>
              <div fxLayout="row Wrap">
                <div fxFlex="2" fxFlex.gt-xs="2"></div>
                <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                    <mcn-autocomplete [optionsData]="countryData" ngDefaultControl optionKey="countryName"
                        optionValue="countryName" [formGroup]="mirrorAnalysisForm"
                        [apiUrl]="this.rmsConfig.countryApiUrl" placeholder="{{'ReportingCountry' | translate}}"
                        [formControl]="mirrorAnalysisForm.controls.reporterCountry" class="fx-selectBox">
                    </mcn-autocomplete>
                    <mcn-error-messages [control]="mirrorAnalysisForm.controls.reporterCountry">
                    </mcn-error-messages>
                </div>
                <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                    <mcn-autocomplete [optionsData]="countryData" ngDefaultControl optionKey="countryName"
                        optionValue="countryName" [formGroup]="mirrorAnalysisForm"
                        [apiUrl]="this.rmsConfig.countryApiUrl" placeholder="{{'PartnerCountry' | translate}}"
                        [formControl]="mirrorAnalysisForm.controls.partnerCountry" class="fx-selectBox">
                    </mcn-autocomplete>
                    <mcn-error-messages [control]="mirrorAnalysisForm.controls.partnerCountry">
                    </mcn-error-messages>
                </div>
                <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                  <mcn-input placeholder="{{'SeriesOfHScodes' | translate }}" ngDefaultControl controlName="tariffItemId" [formGroup]="mirrorAnalysisForm" class="astric-star"></mcn-input>
                  <mcn-error-messages [control]="mirrorAnalysisForm.controls.tariffItemId" customErrorMsg="">
                  </mcn-error-messages>
                </div>
            </div>
                <div fxLayout="row Wrap">
                    <div fxFlex="2"></div>
                    <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                      <mat-radio-group [formGroup]="mirrorAnalysisForm" formControlName="reportType">
                          <div class="ml-10">{{'ReportType' | translate}}</div>
                          <mat-radio-button value="A" [checked]="true" (change)="reportTypeChange($event.value)" class="ml-10">{{'Yearly' | translate}}
                          </mat-radio-button>
                          <mat-radio-button value="M" (change)="reportTypeChange($event.value)" >{{'Monthly' | translate}}</mat-radio-button>
                      </mat-radio-group>
                  </div>
                    <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                      <mcn-select [formGroup]="mirrorAnalysisForm"
                     optionKey="item" optionValue="item" placeholder="{{'TimePeriod' | translate}}"
                     controlName="year" [optionsData]="this.mirrorAnalysisYear">
                      </mcn-select>
                      <mcn-error-messages [control]="mirrorAnalysisForm.controls.year">
                      </mcn-error-messages>
                  </div>
                  <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                    <mcn-select [formGroup]="mirrorAnalysisForm"
                   optionKey="item" optionValue="item" placeholder="{{'Month' | translate}}"
                   controlName="month" [optionsData]="this.mirrorAnalysisMonth">
                    </mcn-select>
                    <mcn-error-messages [control]="mirrorAnalysisForm.controls.month">
                    </mcn-error-messages>
                </div>
                </div>
            </div>
        </form>
            <!-- <form [formGroup]="mirrorAnalysisForm" novalidate>
                <div mat-dialog-content>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mat-radio-group [formGroup]="mirrorAnalysisForm"
                                formControlName="rangeToGenerateRandomNumberFrom">
                                <div class="ml-10">{{'TradeType' | translate}}</div>
                                <mat-radio-button value="Internal Risk" class="ml-10">{{'Import' | translate}}
                                </mat-radio-button>
                                <mat-radio-button value="External Risk">{{'Export' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                            <mcn-select optionKey="typeId" optionValue="name"   [formGroup]="mirrorAnalysisForm"
                                ngDefaultControl  controlName="modeofTransportId"
                                [apiUrl]="this.rmsConfig.getMcnTypes"         placeholder="{{'ModeofTransport' | translate}}"
                                class="fx-selectBox"   optionParameterKey="typeName"
                                [optionParameterValue]="this.modeOfTransportType"
                                        [moduleName]="this.declarationModuleName">
                            </mcn-select>
                            <mcn-error-messages [control]="mirrorAnalysisForm.controls.modeofTransportId">
                            </mcn-error-messages>

                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                            <mcn-autocomplete ngDefaultControl placeholder="{{ 'ItemHSCode' | translate }}"
                                [formGroup]="mirrorAnalysisForm"
                                [formControl]="mirrorAnalysisForm.controls.tariffItemId"
                                [apiUrl]="this.rmsConfig.autoTariffItems" optionKey="tariffItemId"
                                optionValue="tariffCode">
                            </mcn-autocomplete>
                            <mcn-error-messages [control]="mirrorAnalysisForm.controls.tariffItemId">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2" fxFlex.gt-xs="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                            <mcn-select  placeholder="{{'Parameter' | translate}}"
                                                            [formGroup]="mirrorAnalysisForm"
                                                            controlName="parameter"
                                                            [optionsData]="this.mirrorAnalysisParameter"
                                                            optionValue="name"
                                                            optionKey="rmsTypeId">
                            </mcn-select>
                            <mcn-error-messages [control]="mirrorAnalysisForm.controls.parameter">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                            <mcn-autocomplete [optionsData]="countryData" ngDefaultControl optionKey="countryId"
                                optionValue="countryName" [formGroup]="mirrorAnalysisForm"
                                [apiUrl]="this.rmsConfig.countryApiUrl" placeholder="{{'BaseCountry' | translate}}"
                                [formControl]="mirrorAnalysisForm.controls.reporterCountry" class="fx-selectBox">
                            </mcn-autocomplete>
                            <mcn-error-messages [control]="mirrorAnalysisForm.controls.reporterCountry">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">

                            <mcn-autocomplete [optionsData]="countryData" ngDefaultControl optionKey="countryId"
                                optionValue="countryName" [formGroup]="mirrorAnalysisForm"
                                [apiUrl]="this.rmsConfig.countryApiUrl" placeholder="{{'PartnerCountry' | translate}}"
                                [formControl]="mirrorAnalysisForm.controls.partnerCountry" class="fx-selectBox">
                            </mcn-autocomplete>
                            <mcn-error-messages [control]="mirrorAnalysisForm.controls.partnerCountry">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2" fxFlex.gt-xs="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mat-radio-group [formGroup]="mirrorAnalysisForm" formControlName="reportType">
                                <div class="ml-10">{{'ReportType' | translate}}</div>
                                <mat-radio-button value="Internal Risk" class="ml-10">{{'Yearly' | translate}}
                                </mat-radio-button>
                                <mat-radio-button value="External Risk">{{'Monthly' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 astric-star ps-rel">
                            <mcn-select  placeholder="{{'Year' | translate}}"
                                                            [formGroup]="mirrorAnalysisForm"
                                                            controlName="year"
                                                            [optionsData]="this.mirrorAnalysisYear"
                                                            optionValue="name"
                                                            optionKey="rmsTypeId">
                            </mcn-select>
                            <mcn-error-messages [control]="mirrorAnalysisForm.controls.year">
                            </mcn-error-messages>
                        </div>
                    </div>

                </div>
            </form> -->
        </div>
        <div class="form-footer px-2">
            <div class="button-container btn-container">
                <mcn-button type="submit" cssClass="btn-custom-primary" text="{{'Analyze' | translate}}"
                    (click)="generateReport()">
                </mcn-button>
                <mcn-button type="button" cssClass="btn-custom-secondary" text="{{'Cancel' | translate}}" class="ml-3"
                    (click)="cancel()">
                </mcn-button>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
