import { ProfileModel } from '@agility/frameworkcore';
export class ShipStoreDetailsModel extends ProfileModel {
  shipStoreDetailId: number;
  shipDeclarationId: number;
  nameofArticle?: number;
  locationonBoard?: number;
  quantity?: number;
  uom?: number;
  ownerCSId: number;
  other: string;
  others: string;
  shipStoreId: number;
  uoM1Name?: any;
  excelDocuments?: any;
  locationBoard?: any;
  articleName?:any;
}
