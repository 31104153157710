import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storageService/storageService';

@Injectable()
export class FreeZoneConfig implements OnInit {

  // FreeZone importExport  freeZoneApiBaseUrl

  listfzDeclaration: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/ListFZDeclaration';
  deletefzDeclaration: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/DeleteFZDeclaration';
  openfzDeclaration: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/OpenFZDeclaration';
  newfzDeclaration: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/NewFZDeclaration';
  submitfzDeclaration: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/SubmitFZDeclaration';
  getFzWfStates: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/GetFZDeclarationWfStates';

  submitFzUploadDocument: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/SubmitFzUploadDocument';
  openFzUploadDocument: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/OpenFzUploadDocument';
  deleteFzUploadDocuments: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/DeleteFzUploadDocuments';
  downloadFzUploadDocuments: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/DownloadFzUploadDocuments';

  organizationApiUrl: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/GetOrganizations?filterValue=';

  // FreeZone importExportInvoice  freeZoneApiBaseUrl

  listFZInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoice/ListFZInvoice';
  newFZInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoice/NewFZInvoice';
  openFZInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoice/OpenFZInvoice';
  submitFZInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoice/SubmitFZInvoice';
  deleteFZInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoice/DeleteFZInvoice';
  getFZInvoiceWfStates: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoice/GetFZInvoiceWfStates';

  // import export Bills association
  filterFZDecBill: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateBills/AssociateBillsFilterList';
  submitFZDecBill: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateBills/SubmitAssociateBills';
  listFZDecBill: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateBills/ListAssociateBills';
  deleteFZitems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/DeleteFZitems';
  getOrganizationDetails: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZDeclaration/GetOrganizationDetails';

  // FreeZone importExportInvoiceItem  freeZoneApiBaseUrl
  newFZInvoiceItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/NewFZitems';
  submitFZitems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/SubmitFZitems';
  openFZitems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/OpenFZitems';
  listFZitems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/ListFZitems';
  listFZInvitems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/ListFZInvitems';
  getAssociatedPermits: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInvoiceItem/GetAssociatedPermits';

  // import export declarations association
  filterFZDecDecl: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateDeclarations/AssociateDeclarationsFilterList';
  submitFZDecDecl: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateDeclarations/SubmitAssociateDeclarations';
  listFZDecDecl: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateDeclarations/ListAssociateDeclarations';

  // import export Invoice association
  filterFZDecInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateInvoice/AssociateInvoiceFilterList';
  submitFZDecInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateInvoice/SubmitAssociateInvoice';
  listFZDecInvoice: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateInvoice/ListAssociateInvoice';

  // import export Invoice Item association
  filterFZDecInvoiceItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateInvoiceItem/AssociateInvoiceItemFilterList';
  submitFZDecInvoiceItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateInvoiceItem/SubmitAssociateInvoiceItem';
  listFZDecInvoiceItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/AssociateInvoiceItem/ListAssociateInvoiceItem';

  // FreeZone Transfer Request

  listFZTransferReq: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZTransferRequest/ListFzTransferRequest';
  newFZTransferReq: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZTransferRequest/NewFzTransferRequest';
  openFZTransferReq: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZTransferRequest/OpenFzTransferRequest';
  submitFZTransferReq: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZTransferRequest/SubmitFzTransferRequest';
  deleteFZTransferReq: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZTransferRequest/DeleteFzTransferRequest';

  transReqOrganizationApiUrl: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZTransferRequest/GetOrganizations?filterValue=';

  getFzBusinessStackHolderOrganizations: any =
    environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetFZBusinessStackHolderOrganizations?organizationFilter=';

  getFzOprBusinessStackHolderOrganizations: any =
    environment.appSettings.apiEndpoints.registrationApiBaseUrl + '/api/AssociateProcess/GetFZOperatorBusinessStackHolderOrganizations?organizationFilter=';


  // FreeZone TransferRequest Item Association

  filterFZTransReqItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl +
    '/api/FzTransferReqAssociateInvoiceItem/AssociateFzTransferReqInvoiceItemFilterList';
  submitFZTransReqItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl +
    '/api/FzTransferReqAssociateInvoiceItem/SubmitFzTransferReqAssociateInvoiceItem';
  listFZTransReqItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FzTransferReqAssociateInvoiceItem/ListFzTransferReqAssociateInvoiceItem';

  // FreeZone Form

  listFzForm: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/ListFzForm';
  openFzForm: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/OpenFzForm';
  submitFzForm: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/SubmitFzForm';
  getFZFormWfStates: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/GetFZFormWfStates';
  listFZInOutitems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/ListFZInOutitems';
  viewFzTransferRequest: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/ViewFzTransferRequest';

  // Transaction History
  getFzRemarksHistoryData: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZForm/GetFzRemarksHistoryData';

  // FZInventory and Quantity Balance
  listFzInvOrganizations: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInventory/ListFZInvOrganizations';
  listFzInventoryItems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInventory/ListFZInvItems';
  listFzQuantityBalanceItems: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInventory/ListFZQuantityBalance';
  openFzInvOrganizations: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInventory/OpenFZQtyBalance';
  openFzBalanceTariffItem: any = environment.appSettings.apiEndpoints.freeZoneApiBaseUrl + '/api/FZInventory/OpenTariffItem';

  // MDM
  getMcnTypes: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetModuleType';


  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {

  }
  ngOnInit() {
  }
}
