import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';
import { OnInit, Component } from '@angular/core';
import { NVDBOutliersSharedService } from '../../services/sharedServices/nvdbOutlierSharedService';
import { StorageService } from '../../../../../app/storageService/storageService';


@Component({
  selector: 'nvdb-Outlier-Scatter-Chart',
  templateUrl: './nvdbOutlierScatterChartComponent.html'
})

export class NVDBOutlierScatterChartComponent extends SafeUnsubscriber implements OnInit {

  public posX: any;
  public posY: any;
  public alpha: any;
  public beta: any;
  public newAlpha: any;
  public newBeta: any;
  public sensitivity: any = 5;
  public dailogSaveButtonClicked: boolean;
  public seriesOptions: any[] = [];
  public testData: any;
  public options1: any;

  ngOnInit() {
    this.testData = this.nvdbSharedService.sharingData.outliers;
    this.setData();
  }

  constructor(
    public webStorage: StorageService,
    private nvdbSharedService: NVDBOutliersSharedService) {
    super();
  }

  private setData() {
    this.options1 = {
      chart: {
        // renderTo: 'chartContainer',
        margin: 100,
        type: 'scatter',
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 30,
          depth: 250,
          viewDistance: 5,
          frame: {
            bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
            back: { size: 1, color: 'rgba(0,0,0,0.04)' },
            side: { size: 1, color: 'rgba(0,0,0,0.06)' }
          }
        }
      },
      plotOptions: {
        scatter3d: {
          width: 2,
          height: 2,
          depth: 2
        }
      },
      title: {
        text: 'Scatter Plot of clusters'
      },
      xAxis: {
        min: 0,
        max: 20,
        gridLineWidth: 1,
        title: {
          enabled: true,
          text: 'Assessed Value (Log)'
        }
      },
      yAxis: {
        min: 0,
        max: 10,
        title: {
          enabled: true,
          text: 'Unit Cost (Log)'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 30,
        y: 50,
        floating: true
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.data:.1f}%</b>'
      },
      series: this.seriesOptions = [
        {
          name: 'Outliers',
          marker: { symbol: 'circle' },
          data: this.testData['outliers'],
        },
        {
          name: 'Non Outliers',
          marker: { symbol: 'circle' },
          data: this.testData['rest']
        },
        {
          name: 'Series 3',
          marker: { symbol: 'diamond' },
          data: this.testData['out']
        }
      ]
    };
  }

  public test(event: any) { }
}

