<div fxLayout="column Wrap" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="row Wrap">
        <div fxLayout="column Wrap" fxFlex="100" fxLayoutAlign="center center">
            <div fxLayout="row" class="flex-risk-head">
                <h2 fxFlex="100" class="main-title risk-indicator-upload-text pb-0">
                    <span>{{this.reportName}}</span>
                </h2>
            </div>
            <div>
                <i class="star-heading-ul"></i>
            </div>
        </div>
    </div>
    <div fxLayout="column">
        <form [formGroup]="opmReportsFrom" novalidate>
            <div mat-dialog-content>
                <div fxLayout="row Wrap" class="mt-3">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-date class="astric-star" [formGroup]="opmReportsFrom" placeholder="From Date"
                            controlName="fromDate">
                        </mcn-date>
                    </div>
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-date class="astric-star" [formGroup]="opmReportsFrom" placeholder="To Date"
                            controlName="toDate">
                        </mcn-date>
                    </div>
                </div>
                <div fxLayout="row Wrap">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-input class="astric-star" [formGroup]="opmReportsFrom" placeholder="Customs Station"
                            controlName="customsStation">
                        </mcn-input>
                    </div>
                    <!-- <mcn-error-messages  class="error" customErrorMsg="Required"></mcn-error-messages> -->
                </div>
            </div>
        </form>
    </div>
    <div class="form-footer px-2">
        <div class="button-container">
            <mcn-button type="submit" cssClass="btn-custom-primary" text="{{'Generate' | translate}}">
            </mcn-button>
            <mcn-button type="submit" cssClass="btn-custom-primary" text="{{'Reset' | translate}}">
            </mcn-button>
            <!-- <mcn-button type="button" cssClass="btn-custom-secondary" text="{{'Cancel' | translate}}" class="ml-3">
            </mcn-button> -->
        </div>
    </div>
</div>
<button matDialogClose class="dialog-close">
    <i class="icon-close"></i>
</button>