import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes,PreloadAllModules, PreloadingStrategy, Route } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateStaticLoader } from 'ng2-translate';
import { Http } from '@angular/http';
import { AppComponent } from './AppComponent';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class McxPreloadingStrategyService implements PreloadingStrategy {
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    const loadRoute = (delay: number) => (delay > 0 ? timer(delay * 1000).pipe(map(() => fn())) : fn());
    if (route.data && route.data.preload) {
      const delay = route.data.loadAfterSeconds ? route.data.loadAfterSeconds : 5;
      return loadRoute(delay);
    }
    return of(null);
  }
}
const appRoutes: Routes = [
  {
    path: 'registration',
    loadChildren: './account/registration/registrationModule#RegistrationModule',
    data: { title: 'Registration', preload: true },
  },
  {
    path: 'Dashboard',
    loadChildren: './dashboard/DashboardModule#DashboardModule',
    data: { title: 'DFS', preload: true,loadAfterSeconds:0.5 },
  },
  {
    path: 'posting',
    loadChildren: './account/registration/postings/PostingModule#PostingModule',
    data: { title: 'Posting' },
  },
  {
    path: 'user-registration',
    loadChildren: './account/registration/registrationModule#RegistrationModule',
    data: { title: 'Registration' },
  },
  {
    path: 'cargo',
    loadChildren: './cargo/JourneyModule#JourneyModule',
    data: { title: 'Journey', preload: true },
  },
  {
    path: 'journey',
    loadChildren: './cargo/ManifestModule#ManifestModule',
    data: { title: 'Journey', preload: true },
  },
  {
    path: 'certificates',
    loadChildren: './cargo/CertificatesModule#CertificatesModule',
    data: { title: 'Journey' },
  },
  {
    path: 'order',
    loadChildren: './cargo/OrdersModule#OrdersModule',
    data: { title: 'Journey' },
  },
  {
    path: 'bill',
    loadChildren: './cargo/BLModule#BLModule',
    data: { title: 'Journey' },
  },
  {
    path: 'edi',
    loadChildren: './cargo/EDIIntegrationModule#EDIIntegrationModule',
    data: { title: 'Edi' },
  },
  {
    path: 'vehicle',
    loadChildren: './cargo/VehicleModule#VehicleModule',
    data: { title: 'Journey' },
  },
  {
    path: 'carrier',
    loadChildren: './carrierRegistration/CarrierModule#CarrierModule',
    data: { title: 'Carrier Registration', preload: true },
  },
  {
    path: 'carrier-land',
    loadChildren: './carrierRegistration/LandCarrierModule#LandCarrierModule',
    data: { title: 'Carrier Registration' },
  },
  {
    path: 'carrier-rail',
    loadChildren: './carrierRegistration/RailCarrierModule#RailCarrierModule',
    data: { title: 'Carrier Registration' },
  },
  {
    path: 'carrier-vessel',
    loadChildren: './carrierRegistration/VesselModule#VesselModule',
    data: { title: 'Carrier Registration' },
  },
  {
    path: 'mdm',
    loadChildren: './mdm/MdmModule#MdmModule',
    data: { title: 'Master Data', preload: true },
  },
  {
    path: 'declaration',
    loadChildren: './declaration/DeclarationModule#DeclarationModule',
    data: { title: 'Declaration', preload: true },
  },
  {
    path: 'declaration-partial',
    loadChildren: './declaration/DeclarationPartialModule#DeclarationPartialModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'declaration-invoiceItem',
    loadChildren: './declaration/InvoiceItemModule#InvoiceItemModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'declaration-xml',
    loadChildren: './declaration/DeclarationXmlModule#DeclarationXmlModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'declaration-cbra',
    loadChildren: './declaration/CbraModule#CbraModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'declaration-directRelease',
    loadChildren: './declaration/DirectReleaseModule#DirectReleaseModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'declaration-functionalUnit',
    loadChildren: './declaration/FunctionalUnitModule#FunctionalUnitModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'declaration-passengerAssessment',
    loadChildren: './declaration/PassengerAssessmentModule#PassengerAssessmentModule',
    data: { title: 'Declaration' },
  },
  {
    path: 'inspection',
    loadChildren: './inspection/InspectionModule#InspectionModule',
    data: { title: 'Inspection', preload: true },
  },
  {
    path: 'inspectionInstrcn',
    loadChildren: './inspection/inspectionInstrcn/InspectionInstrcnModule#InspectionInstrcnModule',
    data: { title: 'Inspection Instructions', preload: true },
  },
  {
    path: 'assessment',
    loadChildren: './assessment/AssessmentModule#AssessmentModule',
    data: { title: 'Assessment', preload: true },
  },
  {
    path: 'assessment-pricing-database',
    loadChildren: './assessment/AssessmentPricingDatabaseModule#AssessmentPricingDatabaseModule',
    data: { title: 'Assessment' },
  },
  {
    path: 'payments',
    loadChildren: './payments/PaymentsModule#PaymentsModule',
    data: { title: 'Payments', preload: true },
  },
  {
    path: 'releases',
    loadChildren: './clearance/ReleasesModule#ReleasesModule',
    data: { title: 'Releases', preload: true },
  },
  {
    path: 'surety',
    loadChildren: './surety/SuretyModule#SuretyModule',
    data: { title: 'Surety', preload: true },
  },
  {
    path: 'accounting',
    loadChildren: './accounting/AccountingModule#AccountingModule',
    data: { title: 'Accounting', preload: true },
  },
  {
    path: 'tariff-management',
    loadChildren: './tariffManagement/TariffManagementModule#TariffManagementModule',
    data: { title: 'Tariff-Management', preload: true },
  },
  {
    path: 'tariffmngt-conditionProhibition',
    loadChildren: './tariffManagement/ConditionProhibitionModule#ConditionProhibitionModule',
    data: { title: 'Tariff-Management' },
  },
  {
    path: 'tariffmngt-correlation',
    loadChildren: './tariffManagement/TariffCorrelationsModule#TariffCorrelationsModule',
    data: { title: 'Tariff-Correlation' },
  },
  {
    path: 'tariffmngt-wco-correlation',
    loadChildren: './tariffManagement/TariffWCOCorrelationsModule#TariffWCOCorrelationsModule',
    data: { title: 'Tariff-WCO-Correlation' },
  },
  {
    path: 'tariff-valuation',
    loadChildren: './tariffValuation/TariffValuationModule#TariffValuationModule',
    data: { title: 'Tariff-Valuation', preload: true },
  },
  {
    path: 'tariff-exemption',
    loadChildren: './tariffExemption/TariffExemptionModule#TariffExemptionModule',
    data: { title: 'Tariff-Exemption', preload: true },
  },
  {
    path: 'tariff-exemption-category',
    loadChildren: './tariffExemption/TariffExemptionCategoryModule#TariffExemptionCategoryModule',
    data: { title: 'Tariff-Exemption' },
  },
  {
    path: 'tariff-exemption-request',
    loadChildren: './tariffExemption/TariffExemptionRequestModule#TariffExemptionRequestModule',
    data: { title: 'Tariff-Exemption' },
  },
  {
    path: 'tariff-exemption-ftaMngt',
    loadChildren: './tariffExemption/TariffFtaManagementModule#TariffFtaManagementModule',
    data: { title: 'Tariff-Exemption' },
  },
  {
    path: 'tariff-ruling',
    loadChildren: './tariffRulings/TariffRulingModule#TariffRulingModule',
    data: { title: 'Tariff-Ruling', preload: true },
  },
  {
    path: 'resource-planning',
    loadChildren: './resourcePlanning/ResourcePlanningModule#ResourcePlanningModule',
    data: { title: 'Resource Planning', preload: true },
  },
  {
    path: 'tariff-quotas',
    loadChildren: './tariffQuotas/TariffQuotasModule#TariffQuotasModule',
    data: { title: 'Tariff-Quotas', preload: true },
  },
  {
    path: 'integration',
    loadChildren: './integration/IntegrationModule#IntegrationModule',
    data: { title: 'Integration', preload: true },
  },
  {
    path: 'warehouse',
    loadChildren: './warehouse/warehouseModule#warehouseModule',
    data: { title: 'Warehouse', preload: true },
  },
  {
    path: 'dfs',
    loadChildren: './dfs/dfsModule#DfsModule',
    data: { title: 'dfs', preload: true },
  },
  {
    path: 'freezone',
    loadChildren: './freeZone/FreeZoneModule#FreeZoneModule',
    data: { title: 'Freezone Declaration', preload: true },
  },
  {
    path: 'drawback',
    loadChildren: './drawback/DrawbackModule#DrawbackModule',
    data: { title: 'Drawback', preload: true },
  },
  {
    path: 'drawback-additionalPcc',
    loadChildren: './drawback/AdditionalPccModule#AdditionalPccModule',
    data: { title: 'Drawback' },
  },
  {
    path: 'drawback-claim-application',
    loadChildren: './drawback/ClaimApplicationModule#ClaimApplicationModule',
    data: { title: 'Drawback' },
  },
  {
    path: 'drawback-claim-transfer',
    loadChildren: './drawback/ClaimTransferModule#ClaimTransferModule',
    data: { title: 'Drawback' },
  },
  {
    path: 'drawback-request-extension',
    loadChildren: './drawback/RequestExtensionModule#RequestExtensionModule',
    data: { title: 'Drawback' },
  },
  {
    path: 'enforcement',
    loadChildren: './enforcement/EnforcementModule#EnforcementModule',
    data: { title: 'Enforcement', preload: true },
  },
  {
    path: 'transfer-control-doc',
    loadChildren: './transferControlDoc/TransferControlDocModule#TransferControlDocModule',
    data: { title: 'Transfer Control Documents', preload: true },
  },
  {
    path: 'pca',
    loadChildren: './pca/PCAModule#PcaModule',
    data: { title: 'Pca', preload: true },
  },
  {
    path: 'ship-clr',
    loadChildren: './shipClr/ShipClrModule#ShipClrModule',
    data: { title: 'Ship Arrival Declaration', preload: true },
  },
  {
    path: 'refunds',
    loadChildren: './refunds/RefundsModule#RefundsModule',
    data: { title: 'Refunds', preload: true },
  },
  {
    path: 'risk-selectivity-profiling',
    loadChildren: './rms/riskSelectivityProfiling/RiskSelectivityProfilingModule#RiskSelectivityProfilingModule',
    data: { title: 'Risk Selectivity Profiling', preload: true },
  },
  {
    path: 'risk-identification-Analysis',
    loadChildren: './rms/riskIdentificationAnalysis/RiskIdentificationAnalysisModule#RiskIdentificationAnalysisModule',
    data: { title: 'Risk Identification and Analysis', preload: true },
  },
  {
    path: 'risk-review-evaluation',
    loadChildren: './rms/riskReviewEvaluation/RiskReviewEvaluationModule#RiskReviewEvaluationModule',
    data: { title: 'Risk Review and Evaluation', preload: true },
  },
  {
    path: 'targeting-monitoring',
    loadChildren: './rms/targetingMonitoring/TargetingMonitoringModule#TargetingMonitoringModule',
    data: { title: 'Targeting and Monitoring', preload: true },
  },
  {
    path: 'rms-preferences',
    loadChildren: './rms/rmsPreferences/RmsPreferencesModule#RmsPreferencesModule',
    data: { title: 'RMS Preferences', preload: true },
  },
  {
    path: 'permitMasterData',
    loadChildren: './permit/permitMstrData/PermitMstrDataModule#PermitMstrDataModule',
    data: { title: 'permit', preload: true },
  },
  {
    path: 'permitConfiguration',
    loadChildren: './permit/permitConfig/PermitConfigModule#PermitConfigModule',
    data: { title: 'permit', preload: true },
  },
  {
    path: 'permitAuthRequest',
    loadChildren: './permit/permitAuthReq/PermitAuthReqModule#PermitAuthReqModule',
    data: { title: 'permit', preload: true },
  },
  {
    path: 'permitApplication',
    loadChildren: './permit/permitApplcn/PermitApplcnModule#PermitApplcnModule',
    data: { title: 'permit', preload: true },
  },
  {
    path: 'enfHeadOfStation',
    loadChildren: './enforcement/activity/headOfStn/HeadOfStnModule#HeadOfStnModule',
    data: { title: 'Enforcement - Head Of Station', preload: true },
  },
  {
    path: 'enfHeadOfOprn',
    loadChildren: './enforcement/activity/headOfOprn/HeadOfOprnModule#HeadOfOprnModule',
    data: { title: 'Enforcement - Head Of Operation', preload: true },
  },
  {
    path: 'enfOprnLeader',
    loadChildren: './enforcement/activity/oprnLeader/OprnLeaderModule#OprnLeaderModule',
    data: { title: 'Enforcement - Operation Leader', preload: true },
  },
  {
    path: 'shipClrMasterData',
    loadChildren: './shipClr/masterDataConfig/MasterDataConfigModule#MasterDataConfigModule',
    data: { title: 'Master Data', preload: true },
  },
  {
    path: 'shipClrCreateData',
    loadChildren: './shipClr/createShipFrm/CreateShipFrmModule#CreateShipFrmModule',
    data: { title: 'Ship Arrival Declaration', preload: true },
  },
  {
    path: 'shipClrViewData',
    loadChildren: './shipClr/createShipFrm/ViewShipFrmModule#ViewShipFrmModule',
    data: { title: 'Ship Arrival Declaration', preload: true },
  },
  {
    path: 'enf-inv-head-of-stn',
    loadChildren: './enforcement/investigation/invstgHeadOfStn/InvstgHeadOfStnModule#InvstgHeadOfStnModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-ass-inv-offcr',
    loadChildren: './enforcement/investigation/assInvOffcr/AssInvOffcrModule#AssInvOffcrModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-inv-opr-ldr',
    loadChildren: './enforcement/investigation/invOprLdr/InvOprLdrModule#InvOprLdrModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-inv-tec-div-offcr',
    loadChildren: './enforcement/investigation/invTecDivOffcr/InvTecDivOffcrModule#InvTecDivOffcrModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'warehouseStgExt',
    loadChildren: './warehouse/storageExtension/storageExtensionModule#storageExtensionModule',
    data: { title: 'Warehouse Storage Extension', preload: true },
  },
  {
    path: 'warehouseVehicleExt',
    loadChildren: './warehouse/vehicleMovementExt/vehicleMovementExtModule#vehicleMovementExtModule',
    data: { title: 'Vehicle Movement Extension', preload: true },
  },
  {
    path: 'enf-inv-director',
    loadChildren: './enforcement/investigation/invDirector/InvDirectorModule#InvDirectorModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'warehouseRemitDuties',
    loadChildren: './warehouse/remitDuties/remitDutiesModule#remitDutiesModule',
    data: { title: 'Remit Duties Information', preload: true },
  },
  {
    path: 'warehouseTrnsfrGoods',
    loadChildren: './warehouse/transferGoods/transferGoodsModule#transferGoodsModule',
    data: { title: 'Transfer Goods Information', preload: true },
  },
  {
    path: 'warehouseQuantityBlc',
    loadChildren: './warehouse/quantityBalance/quantityBalanceModule#quantityBalanceModule',
    data: { title: 'Quantity Balance Information', preload: true },
  },
  {
    path: 'enf-inv-officer',
    loadChildren: './enforcement/investigation/invOfficer/InvOfficerModule#InvOfficerModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-inv-supervisor',
    loadChildren: './enforcement/investigation/invSuperVisor/InvSuperVisorModule#InvSuperVisorModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-inv-supervisor-ip',
    loadChildren: './enforcement/investigation/invSupervisorIP/InvSupervisorIPModule#InvSupervisorIPModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-inv-supervisor-review',
    loadChildren: './enforcement/investigation/invSupervisorIPReview/InvSupervisorIPReviewModule#InvSupervisorIPReviewModule',
    data: { title: 'Enforcement - Investigation', preload: true },
  },
  {
    path: 'enf-logistics',
    loadChildren: './enforcement/logistics/enfLogistics/EnfLogisticsModule#EnfLogisticsModule',
    data: { title: 'Enforcement - Logistics', preload: true },
  },
  {
    path: 'enfInbound',
    loadChildren: './enforcement/storage/inbound/InboundModule#InboundModule',
    data: { title: 'Enforcement - Storage', preload: true },
  },
  {
    path: 'enfOutbound',
    loadChildren: './enforcement/storage/outbound/OutboundModule#OutboundModule',
    data: { title: 'Enforcement - Storage', preload: true },
  },
  {
    path: 'enfInbDiscpncy',
    loadChildren: './enforcement/storage/inbDiscpncy/InbDiscpncyModule#InbDiscpncyModule',
    data: { title: 'Enforcement - Storage', preload: true },
  },
  {
    path: 'enfInsVerfRpt',
    loadChildren: './enforcement/storage/inspVerfRpt/InspVerfRptModule#InspVerfRptModule',
    data: { title: 'Enforcement - Storage', preload: true },
  },
  {
    path: 'adviseConsent',
    loadChildren: './legalAffairs/criminalCase/adviseConsent/AdviseConsentModule#AdviseConsentModule',
    data: { title: 'Advise and Consent', preload: true },
  },
  {
    path: 'crimeCaseMgmt',
    loadChildren: './legalAffairs/criminalCase/crimeCaseMgmt/CrimeCaseMgmtModule#CrimeCaseMgmtModule',
    data: { title: 'Criminal Case Management', preload: true },
  },
  {
    path: 'compoundCaseMgmt',
    loadChildren: './legalAffairs/compoundMgmt/CompoundMgmtModule#CompoundMgmtModule',
    data: { title: 'Criminal Case Management', preload: true },
  },
  {
    path: 'legal-affairs-appeal',
    loadChildren: './legalAffairs/appeal/AppealModule#AppealModule',
    data: { title: 'Criminal Case Management', preload: true },
  },
  {
    path: 'k9-unit',
    loadChildren: './enforcement/logistics/k9Details/K9DetailsModule#K9DetailsModule',
    data: { title: 'K9 Registration', preload: true },
  },
  {
    path: 'enf-dispose',
    loadChildren: './enforcement/dispose/disposeDetails/DisposeDetailsModule#DisposeDetailsModule',
    data: { title: 'K9 Registration', preload: true },
  },
  {
    path: 'profile-config',
    loadChildren: '././account/registration/profileConfiguration/ProfileConfigModule#ProfileConfigModule',
    data: { title: 'Profile Configuration' },
  },
  {
    path: 'License-generic',
    loadChildren: './license/generic/LicenseGenericModule#LicenseGenericModule',
    data: { title: 'License-Generic' },
  },
  {
    path: 'selectivity-criteria',
    loadChildren: './pca/selectivityCriteria/SelectivityCriteriaModule#SelectivityCriteriaModule',
    data: { title: 'Selectivity Criteria', preload: true },
  },
  {
    path: 'risk-report',
    loadChildren: './pca/Report/ReportModule#ReportModule',
    data: { title: 'Reports', preload: true },
  },
  {
    path: 'License-excise',
    loadChildren: './license/excise/LicenseExciseModule#LicenseExciseModule',
    data: { title: 'License-Excise' },
  },
  {
    path: 'License-lmw',
    loadChildren: './license/lmw/LicenseLMWModule#LicenseLMWModule',
    data: { title: 'License-lmw' },
  },
  {
    path: 'License-agent',
    loadChildren: './license/agent/LicenseAgentModule#LicenseAgentModule',
    data: { title: 'License-Application' },
  },
  {
    path: 'License-dfs',
    loadChildren: './license/dfs/DFSModule#DFSModule',
    data: { title: 'License-Application' },
  },
  {
    path: 'License-aeo',
    loadChildren: './license/aeo/LicenseAEOModule#LicenseAEOModule',
    data: { title: 'License-aeo' },
  },
  {
    path: 'serviceReceipts',
    loadChildren: './serviceReceipts/services/ServicesModule#ServicesModule',
    data: { title: 'Services', preload: true },
  },
  {
    path: 'servicePriceList',
    loadChildren: './serviceReceipts/servicePrice/ServicePriceModule#ServicePriceModule',
    data: { title: 'Services', preload: true },
  },
  {
    path: 'serviceReceiptsList',
    loadChildren: './serviceReceipts/serviceReceipts/ServiceReceiptsModule#ServiceReceiptsModule',
    data: { title: 'Services', preload: true },
  },
  {
    path: 'sezWarehouse',
    loadChildren: './specialEconomicZone/sezWarehouseModule#sezWarehouseModule',
    data: { title: 'Warehouse', preload: true },
  },
  {
    path: 'sezWarehouseStgExt',
    loadChildren: './specialEconomicZone/sezStorageExtension/sezStorageExtensionModule#sezStorageExtensionModule',
    data: { title: 'Warehouse Storage Extension', preload: true },
  },
  {
    path: 'sezWarehouseVehicleExt',
    loadChildren: './specialEconomicZone/sezVehicleMovementExt/sezVehicleMovementExtModule#sezVehicleMovementExtModule',
    data: { title: 'Vehicle Movement Extension', preload: true },
  },
  {
    path: 'sezWarehouseRemitDuties',
    loadChildren: './specialEconomicZone/sezRemitDuties/sezRemitDutiesModule#sezRemitDutiesModule',
    data: { title: 'Remit Duties Information', preload: true },
  },
  {
    path: 'sezWarehouseTrnsfrGoods',
    loadChildren: './specialEconomicZone/sezTransferGoods/sezTransferGoodsModule#sezTransferGoodsModule',
    data: { title: 'Transfer Goods Information', preload: true },
  },
  {
    path: 'sezWarehouseQuantityBalance',
    loadChildren: './specialEconomicZone/sezQuantityBalance/sezQuantityBalanceModule#sezQuantityBalanceModule',
    data: { title: 'Quantity Balance Information', preload: true },
  },
  {
    path: 'postal',
    loadChildren: './postal/postalCN23/PostalCN23Module#PostalCN23Module',
    data: { title: 'Services', preload: true },
  },
  { path: '**', component: AppComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: McxPreloadingStrategyService ,
      onSameUrlNavigation: 'reload',
    }),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: (http: Http) => new TranslateStaticLoader(http, '../dist/assets/i18n', '.json'),
      deps: [Http],
    }),
  ],
})
export class AppRoutingModule { }
