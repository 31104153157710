import { FxContext } from '@agility/frameworkcore';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {TranslateService } from 'ng2-translate';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { StorageService } from '../../storageService/storageService';
@Component({
  selector: 'app-language-selection-modal',
  templateUrl: './LanguageSelectionModalComponent.html',
})
export class LanguageSelectionModalComponent implements OnInit {
  public lanSelect: boolean;
  public languageId: number;

  constructor(
    private fxContext: FxContext,
    public dialogRef: MatDialogRef<LanguageSelectionModalComponent>,
    public webStorageService: StorageService,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private mcnConfig: McnConfig,
  ) { }

  public languageList = [
    {
      id: 3,
      name: 'Malay',
      imgPath: '../../../assets/i18n/Malay.jpg',
    },
    {
      id: 1,
      name: 'English',
      imgPath: '../../../assets/i18n/English.jpg',
    },
    {
      id: 2,
      name: 'Arabic',
      imgPath: '../../../assets/i18n/Arabic.jpg',
    },
    {
      id: 4,
      name: 'French',
      imgPath: '../../../assets/i18n/French.jpg',
    },
  ];
  public ngOnInit() {

  }
  public getSelectedLanguage(lag: any) {
    let language = '';
    this.lanSelect = true;
    if (lag === 'Arabic') {
      language = 'Arb';
      this.languageId = 2;
    } else if (lag === 'English') {
      language = 'en';
      this.languageId = 1;
    } else if (lag === 'Malay') {
      language = 'malay';
      this.languageId = 3;
    } else if (lag === 'French') {
      language = 'fr';
      this.languageId = 4;
    }
    this.fxContext.languageId = this.languageId;

    this.webStorageService.set('lanPath', '../../assets/i18n/' + lag + '.jpg');
    this.webStorageService.set('lang_code', language);
    this.webStorageService.set('language_Id', this.fxContext.languageId);
    this.mcnConfig.getJson().subscribe((response) => {
      this.webStorageService.set('localeJsonFile', response);
    });
    this.translate.use(language);
    this.matDialog.closeAll();
  }
}