<!--Header Starts-->
<header [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}" (clickOutside)="onClickOutside($event)">
  <mat-toolbar-row class="hbg-blue header-toolbar" fxLayout='row wrap' fxLayoutAlign="space-between center">
    <!--Logo/Brand-->
    <a class="navbar-brand mt-3" fxFlex.gt-sm="33" fxLayout="row wrap" fxFlex.sm="40" fxFLex="33">
      <img src="../../assets/images/MC_Logo.png" alt="Microclear" />
      <!-- <img src="../../assets/images/Saudi_logo_mobile.png" class="saudiLogo" fxHide.xs="true" fxHide.sm="true" /> -->
    </a>
    <!-- <div fxFlex.gt-sm="23" fxFlex.sm="20" fxFlex="23" fxLayout="row wrap">
      <img src="../../assets/images/ZAkat_2030logo.png" alt="MicroClear" height="auto" />      
    </div> -->
    <!--Menu Trigger-->
    <div class="menu-section" fxLayout='row wrap' fxLayoutAlign="center" fxFlex.sm="15" fxFLex="33">
      <div class="menu-open-btn" (click)="openMenuDialog()">
        {{ 'Menu' | translate }}
        <i class="icon-arrow-down"></i>
      </div>
    </div>
    <!--User Actions Starts-->
    <div fxFlex.xs="29" fxFlex="37" fxFlex.lg="37" fxFlex.sm="43" class="user-actions" fxLayout='row wrap' fxLayoutAlign="end">
      <!-- <div class="lang-dropdown mr-2">
        <span style="top: -2px;" class="language-selection-content">{{ 'SessionTimeout' | translate }} : {{ (minutesDisplay) }}:{{ (secondsDisplay) && (secondsDisplay <=59) ? secondsDisplay : '00' }}</span>
      </div> -->
      <button class="navbar-toggler" type="button" (click)="onClickMenu()" [class.selected]="active">
        <span> </span>
        <span> </span>
        <span> </span>
      </button>

      <div class="lang-dropdown">
        <a href="javascript:void(0)" (click)="openLanguageSelectionDialog()">
          <i fxHide.xs="true" class="language-flag"><img src='{{this.storageService.get("lanPath")}}'></i>
        </a>
        <a fxHide="true" fxHide.xs="false" href="javascript:void(0)" (click)="openLanguageSelectionDialog()" style="margin-right:30px; margin-top:-5px;">
          <i class="language-flag"><img class="mt-minus6" src='{{this.storageService.get("lanPath")}}'></i>
        </a>
        <span fxHide.xs="true" fxHide.sm="true"><a href="javascript:void(0)" class="language-selection-content" (click)="openLanguageSelectionDialog()">{{this.storageService.get("lang_code") != "Arb" ? (this.storageService.get("lang_code") != "en"? this.storageService.get("lang_code") == "fr"?"French":"Malay":"English") : "Arabic" | translate }}</a></span>
        <span fxHide="true" fxHide.xs="true" fxHide.sm="true"><a href="javascript:void(0)" (click)="openLanguageSelectionDialog()">{{this.storageService.get("lang_code") != "Arb" ? (this.storageService.get("lang_code") != "en"? this.storageService.get("lang_code") == "fr"?"French" :"Malay":"English") : "Arabic" | translate }} </a></span>
      </div>

      <ul class="right-nav" [class.selected]="active">
        <!-- <li class="nav-item">
          <a href="javascript:void(0)" matTooltip="{{ 'Search' | translate }}" (click)="openSearchDialog()"><mat-icon class="icon-search"></mat-icon></a>
        </li> -->
        <!-- <li class="nav-item">
          <a href="javascript:void(0)" matTooltip="{{ 'ManifestBalancing' | translate }}" routerLink="Dashboard/manifest-balancing"><i class="vessel-history-white"></i></a>
        </li> -->
        <li class="nav-item">
          <a href="javascript:void(0)" matTooltip="{{ 'Dashboard' | translate }}" routerLink="Dashboard/user-dashboard"><i class="icon-dashboard"></i></a>
        </li>
        <!-- <li class="nav-item">
          <a href="javascript:void(0)" matTooltip="{{ 'Tracking' | translate }}" routerLink="Dashboard/tracking"><i class="map-icon-white"></i></a>
        </li> -->
        <!-- <li class="nav-item">
          <a href="javascript:void(0)" matTooltip="{{ 'QuickLinks' | translate }}" (click)="openQuickLinksDialog()"><mat-icon class="icon-attach"></mat-icon></a>
        </li> -->
        <li class="nav-item">
          <a matTooltip="{{ 'Inbox' | translate }}" href="javascript:void(0)" routerLink="Dashboard/Home">
            <mat-icon class="icon-alerts"></mat-icon>
            <!--<span class="count">05</span>-->
          </a>
        </li>
        <li class="nav-item circular-nav-item topminus">
          <a class="user-dd cursor-pointer" aria-expanded="true" matTooltip="{{ 'MyProfile' | translate }}" (click)="onToggleClass()">
            <img src="../../assets/images/user-img.jpg" class="" alt="Username" />
          </a>
          <div class="dropdown-menu-right declaration-option-dropdown box-shadow-card" [ngClass]="{'active': activeClass}">
            <div class="dropdown-wrapper p-0" fxLayout="column">
              <div fxFlex="100" class="profile-bg"></div>
              <div fxLayout="row wrap">
                <div class="profile-pic-header cursor-pointer" matTooltip="{{ 'Profile' | translate }}">
                  <img src="../../assets/images/Avatar.png" class="" alt="Username" (click)="openProfileDialog()" />
                </div>
                <div fxFlex="20">&nbsp;</div>
                <div class="custom-flex2 ml-3" fxLayoutAlign="space-between" fxFlex="70">
                  <div fxFlex="100">
                    <i class="icon-profile profile-icon"></i>
                    <p class="preview-content user-profile-text user-preview-content">{{this.fxContext?.userProfile?.name}}</p>
                    <p class="preview-label user-preview-label mt-2"> {{this.storageService.get('eMailId')}}</p>
                    <p class="preview-label user-preview-label"><i class="float-left mr-1" [ngClass]="this.postingClass"></i> {{this.userData?.postingName | translate}}</p>
                    <p class="preview-label user-preview-label mt-1" *ngIf="this.userCustomStation"><i class="customs-station-icon float-left mr-1"></i> {{this.userData?.customStationName | translate}}</p>
                    <p class="preview-label user-preview-label mt-1 mb-2" *ngIf="this.userPort"><i class="float-left mr-1" [ngClass]="this.portClass"></i> {{this.userData?.portName}}</p>
                  </div>
                </div>
              </div>
              <div class="align-items-stretch orange-grey-theme graybg small-icon p-1" fxLayout="row" fxLayout.sm="row" fxLayoutAlign="end center">
                <a href="javascript:void(0);" id="btn_change_pwd" matTooltip="{{ 'ChangePassword' | translate }}" (click)="openChangePasswordModal($event)"><img src="../../assets/images/changepassword.png" /></a>
                <a href="javascript:void(0);" id="btn_logout" matTooltip="{{ 'Logout' | translate }}" (click)="logOut()"><img src="../../assets/images/logout.png" /></a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="help-icon" fxLayout="row" fxLayoutAlign="center" matTooltip="{{'Help'|translate}}"><mat-icon class="icon-help"></mat-icon></a>
        </li>
      </ul>
    </div>
    <!--User Actions Ends-->
  </mat-toolbar-row>
</header>
<!--Header Ends-->
