import { NgModule } from '@angular/core';
import { LoginRoutingModule } from '../login/LoginRoutingModule';
import { ForgotPasswordActivationComponent } from '../login/passwordManagement/forgotPasswordActivation/forgotPasswordActivationComponent';
import { ForgotPWDComponent } from '../login/passwordManagement/forgotPwd/forgotPwdComponent';
import { PasswordManagementService } from '../login/passwordManagement/PasswordManagementService';
import { ImagesCarouselComponent } from './imagesCarousel/imagesCarouselComponent';
import { LoginComponent } from './LoginComponent';
import { LoginService } from './LoginService';
import { LogoComponent } from './logo/logoComponent';
import { McnControlsModule } from '../../mcnControls/McnControlsModule';
import { BotDetectCaptchaModule } from 'angular-captcha';
import { ActivatedSuccessComponent } from '../activationProcess/activatedSuccess/ActivatedSuccessComponent';
import { ActivationFailComponent } from '../activationProcess/activationFail/ActivationFailComponent';
import { DummyPageComponent } from './idpLogin/DummyPageComponent';
import { ProfileDetailsComponent } from '../registration/profile/ProfileDetailsComponent';
import { FormDataService } from '../registration/commonRegistrationComponents/wizardData/wizardDataService';
import { RegistrationService } from '../registration/commonRegistrationComponents/RegistrationService';

import { LoginHomeComponent } from './LoginHomeComponent';
import { environment } from '@env/environment';

import { ChartModule } from 'angular2-highcharts';
import { TariffSearchComponent } from './tariffSearch/TariffSearchComponent';
import { ViewDutyRateComponent } from './tariffSearch/ViewDutyRateComponent';
import { DutyTaxOutputPopupComponent } from './dutyTaxOutputPopup/DutyTaxOutputPopupComponent';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { TariffItemService } from '../../tariffManagement/services/tariffServices/TariffItemService';

@NgModule({
  imports: [
    McnControlsModule,
    LoginRoutingModule,
    ChartModule,
    BotDetectCaptchaModule.forRoot({
      captchaEndpoint: 'http://localhost:50843/captcha/captcha'
    }),
    AgmCoreModule,
    AgmDirectionModule,
  ],
  declarations: [
    LoginComponent,
    LoginHomeComponent,
    ImagesCarouselComponent,
    LogoComponent,
    ForgotPasswordActivationComponent,
    ForgotPWDComponent, ActivatedSuccessComponent, ActivationFailComponent, DummyPageComponent,
    ProfileDetailsComponent,
    TariffSearchComponent, DutyTaxOutputPopupComponent, ViewDutyRateComponent,
  ],
  providers: [
    LoginService,
    PasswordManagementService,
    FormDataService,
    RegistrationService,
    TariffItemService
  ],
  entryComponents: [
    ForgotPasswordActivationComponent, ForgotPWDComponent,
    TariffSearchComponent, DutyTaxOutputPopupComponent, ViewDutyRateComponent
  ],
})
export class LoginModule {
}
