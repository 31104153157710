<mat-card class="chat-window p-0">
  <ng-container  [ngTemplateOutlet]="template">
    <div class="msgArea chatbox" #msgArea >
      <ng-container *ngFor="let msg of allMessages | async; let i = index;"
        [ngTemplateOutlet]="defaultMsgTemplate"
        [ngTemplateOutletContext]="{
                                       text: msg.content ,
                                       sendBy: msg.sendBy,
                                       lang: msg.lang,
                                       options: msg.options
                                    }"
      >
      </ng-container>
    </div>
    <div class="input-area">
      <ng-container class="input-area" [ngTemplateOutlet]="inputTemplate"></ng-container>
    </div>
  </ng-container>
</mat-card>

<ng-template  #defaultMsgTemplate let-text="text" let-object="object" let-sendBy="sendBy" let-lang="lang" let-options="options">
  <chat-msg (dataToPass)="handleData($event)" (language_arabic)="change_language_arabic()" 
  (language_english)="change_language_english()"  [msg]="{text: text, sendBy: sendBy, lang: lang, options: options}" ></chat-msg>
</ng-template>

<ng-template #defaultInputTemplate>
  <chat-input (change)="onChange($event.target);"></chat-input>
</ng-template>
