import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FilterMenuService } from '../../../common/filter-menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RiskIdentificationAndAnalysisSharedService } from '../services/sharedServices/RiskIdentificationAndAnalysisSharedService';
import { TraderProfileService } from './traderProfile360DegreeProfile/TraderProfileService';
import { TraderOrgsModel } from '../models/TraderOrgsModel';
import { McnConfig } from '../../../mcnConfig/McnConfig';
import { CommonSharedService } from '../../../common/CommonSharedService';
import { ToasterService } from '../../../toastrService/toasterService';
import { ModelName, SuccessErrMsgs, IndexValue } from '../../riskSelectivityProfiling/common/Constants';
import { StorageService } from '../../../../app/storageService/storageService';

@Component({
  selector: 'app-trader-profile',
  templateUrl: './TraderProfileComponent.html',

})

export class TraderProfileComponent implements OnInit {
  public filterMenuOpened: boolean;
  public traderProfileFltrForm: FormGroup;
  public pageCount: number;
  private serviceDocument: any = [];
  public orgList: TraderOrgsModel[];
  public selectedIndex: any = IndexValue.IsOne;
  public selectedDec = true;
  public selectedAsc = false;
  private expression = '';

  constructor(
    private fb: FormBuilder,
    private filterMenuService: FilterMenuService,
    private router: Router,
    public webStorage: StorageService,
    private activatedRoute: ActivatedRoute,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService,
    private service: TraderProfileService,
    public mcnConfig: McnConfig,
    private commonSharedService: CommonSharedService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(() => {
      this.getData();
    });

    this.filterMenuService.isFilterMenuOpen().subscribe((isFilterMenuOpened: boolean) => {
      this.filterMenuOpened = isFilterMenuOpened;
    });
    this.traderProfileFltrForm = this.fb.group({
      name: ['']
    });
  }

  public FiltermenuOpen() {
    if (this.filterMenuOpened === undefined || this.filterMenuOpened === false) {
      this.filterMenuOpened = true;
    } else {
      this.filterMenuOpened = false;
    }
  }

  public openTraderProfile(id: number) {
    this.riskIdentificationAndAnalysisSharedService.sharingData.traderId = id;
    this.router.navigate(['risk-identification-Analysis/trader-profile-details']);
  }

  public sort(event: any, orderIndex: any, isDescending: boolean) {
    event.preventDefault();
    this.service.isDescending = isDescending;
    this.service.orderIndex = orderIndex;
    const pageIndex = event == null ? IndexValue.IsOne : event.PageIndex;
    const pageCount = event == null ? this.mcnConfig.appData.associatePageCount : event.PageCount;
    this.service.listOrgs(this.expression, ModelName.TraderProfileOrgModel, pageIndex, pageCount).subscribe(() => {
      this.getData();
    });
  }

  public filter(isReset: boolean = false) {
    this.expression = this.filterExpression(isReset);
    this.service.listOrgs(this.expression, ModelName.TraderProfileOrgModel).subscribe((response) => {
      this.getData();
    });
  }

  private getData() {
    this.serviceDocument = this.service.orgServiceDocument;
    if (this.serviceDocument.dataProfile !== undefined) {
      this.orgList = this.serviceDocument.dataProfile.dataList;
    } else {
      this.orgList = [];
    }
    if (this.serviceDocument.totalCount !== null) {
      this.pageCount = this.serviceDocument.totalCount;
    }
  }

  private filterExpression(isReset: boolean = false) {
    const name = this.traderProfileFltrForm.value['name'];
    this.expression = null;

    if (!this.commonSharedService.isNotNullOrUndefined(name)) {
      if (isReset === false) {
        this.toasterService.Warning(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.PleaseSelectoneValue));
      }
    } else {
      this.expression = `TraderProfileOrgModel.Name.ToUpper().Contains("${name.toUpperCase()}")`;
    }
    return this.expression;
  }

  public resetFilter() {
    this.traderProfileFltrForm.reset();
    this.expression = '';
    this.filter(true);
  }

  public doPagination(response: any) {
    // this.expression = this.filterExpression();
    this.expression = this.expression != null ? this.expression : '';
    this.service.listOrgs(this.expression, ModelName.TraderProfileOrgModel, response.PageIndex, response.PageCount)
      .subscribe((res) => {
        this.getData();
      });
  }
}
