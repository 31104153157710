<div fxLayout="column Wrap" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="row Wrap">
        <div fxLayout="column Wrap" fxFlex="100">
            <h2 fxFlex="90" class="main-title pb-0">
                <span>{{'Outlier' | translate}}</span>
            </h2>
        </div>
    </div>
    <div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-2">
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbViewOutlierInfo?.declarationDate | date}}</h6>
                <label class="preview-label">{{'Date' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbViewOutlierInfo?.tariffCode}}</h6>
                <label class="preview-label">{{'HSCodeCaption' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbViewOutlierInfo?.countryName}}</h6>
                <label class="preview-label">{{'CountryOfOrigin' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbViewOutlierInfo?.quantity}}</h6>
                <label class="preview-label">{{'Qty' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbViewOutlierInfo?.value}}</h6>
                <label class="preview-label">{{'AssessedValue' | translate}}</label>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-2">
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <span class="metrics-text"
                    (click)="viewOutlierMagnitude(getdata = 'MAG')">{{'ViewOutlierMagnitude' | translate}}
                </span>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <span class="metrics-text"
                    (click)="viewOutlierMagnitude(getdata = 'LOF')">{{'ViewOutlierLOF' | translate}}
                </span>
            </div>
        </div>
    </div>
</div>
<button matDialogClose class="dialog-close">
    <i class="icon-close"></i>
</button>