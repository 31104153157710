
<div class="chart-container pt-5">
  <h5 class="main-title m-0">Current Statistics</h5> <p>Last Updated: {{ this.date | date:'MMM d, y h:mm:ss a'}} </p>
  <div class="card-grey-border no-padding">
    <div class="stats-list" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign.xs="start start" fxLayoutAlign="space-around center">
      <div fxFlex="16.66" fxFlex.xs="100">
        <div class="stat-data orange p-3">
          <p class="manifest">Manifest Received</p>
          <h5>{{ statisticsData?.manifestReceived || '--' }}</h5>
        </div>
      </div>
      <div fxFlex="16.66" fxFlex.xs="100">
        <div fxFlex.xs="100" class="stat-data blue p-3" fxLayout="column">
          <p class="containers">Total Containers</p>
          <h5>{{ statisticsData?.totalContainers || '--' }}</h5>
        </div>
      </div>
      <div fxFlex="16.66" fxFlex.xs="100">
        <div fxFlex.xs="100" class="stat-data violet p-3">
          <p class="imports">Import Bills</p>
          <h5>{{ statisticsData?.importBills || '--' }}</h5>
        </div>
      </div>
      <div fxFlex="16.66" fxFlex.xs="100">
        <div fxFlex.xs="100" class="stat-data red p-3">
          <p class="exports">Exports Bills</p>
          <h5>{{ statisticsData?.exportBills || '--' }}</h5>
        </div>
      </div>
      <div fxFlex="16.66" fxFlex.xs="100">
        <div fxFlex.xs="100" class="stat-data green p-3">
          <p class="berth">Berth Availability</p>
          <h5>{{ statisticsData?.berthAvailability || '--' }}</h5>
        </div>
      </div>
      <div fxFlex="16.66" fxFlex.xs="100" class="border-none">
        <div fxFlex.xs="100" class="stat-data cyan p-3">
          <p class="goods">Vessels In Port</p>
          <h5>{{ statisticsData?.vesselsInPort || '--' }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
    <div fxFlex="100" class="my-3">
      <expansion-panels-container class="test">
        <expansion-panel>
          <expansion-panel-title>
            <h4 class="main-title mb-4"> Trade Analysis </h4>
          </expansion-panel-title>
          <expansion-panel-content class="ml-0">
            <div fxFlex="100" class="my-3">
              <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=159489d9-7279-408e-9b34-f8c6f83f4f78&autoAuth=true&ctid=137fc8bc-66c4-4299-8fb9-6197b1050797&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
            </div>
          </expansion-panel-content>
        </expansion-panel>
      </expansion-panels-container>
    </div>

    <div fxFlex="100" class="my-3">
      <div class="card-grey-border chart-card">
        <h4 class="main-title mb-4">Arrivals,Departures &amp; Transits </h4>
        <app-stackedChart 
          [optionsData]="this.stackedChartData" 
          [optionsCurrYearData]="this.stackedChartData"
          [optionsPrevYearData]="this.stackedChartPrevYrData"
          [yearData]="years" 
          [countryData]="countries">
        </app-stackedChart>
      </div>
    </div>
    <div fxFlex="50" fxFlex.xs="100" class="my-3">
      <div class="card-grey-border chart-card">
        <h4 class="main-title mb-4">Arrivals By Mode Of Transport </h4>
        <app-guage-chart 
          [optionsData]="this.guageData" 
          [optionsCurrYearData]="this.guageData"
          [optionsPrevYearData]="this.guagePrevYrData"
          [yearData]="years" 
          [countryData]="countries">
        </app-guage-chart>
      </div>
    </div>
    <div fxFlex="49" fxFlex.xs="100" class="my-3">
      <div class="card-grey-border chart-card">
        <h4 class="main-title mb-4">Importation By Vessel Types </h4>
        <app-pie-chart 
          [optionsData]="this.pieChartData"
          [optionsCurrYearData]="this.pieChartData"
          [optionsPrevYearData]="this.pieChartPrevYrData"
          [yearData]="years">
        </app-pie-chart>
      </div>
    </div>
    <div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4">Import &amp; Export Manifests </h4>
        <app-area-chart 
          [optionsData]="this.areaData" 
          [optionsCurrYearData]="this.areaData"
          [optionsPrevYearData]="this.areaPrevYrData"
          [yearData]="years">
        </app-area-chart>
      </div>
    </div>
    <div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4">Top Ten Exporting Countries </h4>
        <app-hbar-chart 
          [optionsData]="this.bar_ChartData" 
          title="" 
          subtitle="">
        </app-hbar-chart>
        <p fxLayoutAlign="center center">Value in Million US Dollars</p>
      </div>
    </div>
    <div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4">Transactions Processed </h4>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start">
          <div fxFlex="20">
            <ul class="stats-list2 dGraph">
              <li class="manifestDoc red">
                <div class="stat-data">
                  <p class="">Manifests</p>
                  <h5>{{ statisticsData?.manifestsProcessed || '--' }}</h5>
                </div>
              </li>
              <li class="deliveryOrder blue">
                <div class="stat-data">
                  <p class="">Delivery Orders</p>
                  <h5>{{ statisticsData?.doProcessed || '--' }}</h5>
                </div>
              </li>
              <!--<li class="requests yellow">
                  <div class="stat-data">
                      <p class="">Ruling Requests</p>
                      <h5>4695</h5>
                  </div>
              </li>-->
            </ul>
          </div>
          <div fxFlex="80">
            <app-line-chart 
              [optionsData]="this.lineData" 
              [optionsCurrYearData]="this.lineData"
              [optionsPrevYearData]="this.linePrevYrData"
              [yearData]="years">
            </app-line-chart>
          </div>
        </div>
      </div>
    </div>
    <!--<div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4">Value of Goods Stored in Warehouse</h4>
        <app-candlestick-chart [optionsData]="this.candle_ChartData"></app-candlestick-chart>
      </div>
    </div>-->
    <!--<div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4"></h4>
        <app-bubble-chart [optionsData]="this.bubble_ChartData"></app-bubble-chart>
      </div>
    </div>-->
    <!--<div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4">Customs Revenue </h4>
        <app-scatterChart-chart [optionsData]="this.scatter_ChartData"></app-scatterChart-chart>
      </div>
    </div>-->
    <div fxFlex="100" class="my-3">
      <div class="card-grey-border">
        <h4 class="main-title mb-4">Importation based on Country of Origin </h4>
        <app-map-chart 
          [optionsData]="this.map_ChartData">
        </app-map-chart>
        <p fxLayoutAlign="center center">Value in Million US Dollars</p>
      </div>
    </div>
  </div>
</div>
