<div fxLayout="row wrap" fxLayoutAlign="end end" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxFlex="100" fxFlex.gt-sm="10" fxLayout="row" fxLayoutAlign="end center">

    <mcn-select [formGroup]="pieChartForm" optionKey="value"
                optionValue="viewValue" placeholder="{{'Year' | translate}}" controlName="year"
                [optionsData]="this.yearData" (OnChange)="onYearSelect($event)" selectedValue="2021">
    </mcn-select>

    <!--<mat-form-field>
      <mat-select placeholder="Year" (selectionChange)="onYearSelect($event.value)" value="2018">
        <mat-option *ngFor="let year of this.yearData" [value]="year.value">
          {{ year.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>
</div>
<div id="pie_chart" [chartData]="this.optionsData" [chartOptions]="pie_ChartOptions" chartType="PieChart" GoogleChart></div>
