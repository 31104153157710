<div fxLayout="row wrap" fxLayoutAlign="end end">
  <div fxFlex="100" fxFlex.gt-sm="10" fxLayout="row" fxLayoutAlign="end center">
    <mcn-select [formGroup]="areaChartForm" optionKey="value"
                optionValue="viewValue" placeholder="{{'year' | translate}}" controlName="year"
                [optionsData]="this.yearData" (OnChange)="onYearSelect($event)" selectedValue ="2021">
    </mcn-select>

    <!--<mat-form-field>
      <mat-select placeholder="Year" (OnChange)="onYearSelect($event.value)" value="2018">
        <mat-option *ngFor="let year of this.yearData" [value]="year.value">
          {{ year.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>
</div>
<!--<ngx-charts-area-chart-stacked [view]="view"
                               [scheme]="colorScheme"
                               [results]="this.optionsData"
                               [gradient]="gradient"
                               [xAxis]="showXAxis"
                               [yAxis]="showYAxis"
                               [legend]="showLegend"
                               [showXAxisLabel]="showXAxisLabel"
                               [showYAxisLabel]="showYAxisLabel"
                               [xAxisLabel]="xAxisLabel"
                               [yAxisLabel]="yAxisLabel"
                               (select)="onSelect($event)">
</ngx-charts-area-chart-stacked>-->
<div id="area_chart" [chartData]="this.optionsData" [chartOptions]="area_ChartOptions" chartType="AreaChart" GoogleChart></div>
