<div class="rndm-scltn-cnfg-list" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="column Wrap">
        <div fxLayout="row Wrap">
            <div fxLayout="column Wrap" fxFlex="100">
                <div fxLayout="row" class="flex-risk-head flex-random-head">
                    <h2 fxFlex="80" class="main-title pb-0">
                        <span>{{'MarketResearchDataForVehicleDetails' | translate }}</span>
                    </h2>
                    <h6 fxFlex="20" class="preview-content font-semi-bold">
                        <span class="status-tag created">
                            {{this.vehicleDetails.workflowInstance.stateName == 'Start' ? 'New' : this.vehicleDetails.workflowInstance.stateName}}
                        </span>
                    </h6>
                </div>
                <div>
                    <hr class="hr-line">
                </div>
            </div>
        </div>
        <div fxLayout="column">
            <form [formGroup]="profileForm" novalidate>
                <div mat-dialog-content>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="typeId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'PricingDataSource' | translate }}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'PRICINGSOURCETYPES'"
                                [moduleName]="'Rms'" [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.typeId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-autocomplete ngDefaultControl placeholder="{{'ItemHSCode' | translate}}"
                                [formGroup]="profileForm" [formControl]="profileForm.controls.tariffItemId"
                                [apiUrl]="this.rmsConfig.autoTariffItems" optionKey="tariffItemId"
                                optionValue="tariffCode" Class="astric-star" [optionsData]="auto_hsCode"
                                [disabled]="disableCtrls">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.tariffItemId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-date [formGroup]="profileForm" placeholder="{{'Date' | translate}}"
                                [completeDisable]="true" controlName="nvdbMarketVehicleDate" [disabled]="disableCtrls">
                            </mcn-date>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-autocomplete [optionsData]="auto_countryOfOrigin" [formGroup]="profileForm"
                                ngDefaultControl optionKey="countryId" optionValue="countryName"
                                [formControl]="profileForm.controls.countryId" [apiUrl]="this.rmsConfig.countryApiUrl"
                                placeholder="{{'CountryofOrigin' | translate}}" class="fx-selectBox astric-star"
                                [disabled]="disableCtrls">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.countryId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="vehicleConditionId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'VehicleCondition' | translate}}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'VEHICLECONDITION'"
                                [moduleName]="'Rms'" [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.vehicleConditionId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="vehicleTypeId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'VehicleType' | translate}}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'VEHICLETYPE'"
                                [moduleName]="'Rms'" [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.vehicleTypeId">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-autocomplete [optionsData]="auto_brand" [formGroup]="profileForm" ngDefaultControl
                                optionKey="vehicleBrandId" optionValue="brandName"
                                [formControl]="profileForm.controls.brand" [apiUrl]="this.rmsConfig.autoVehicleBrands"
                                placeholder="{{'Brand' | translate}}" class="fx-selectBox astric-star"
                                [disabled]="disableCtrls">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.brand">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-autocomplete [optionsData]="auto_brandModel" [formGroup]="profileForm" ngDefaultControl
                                optionKey="brandModelId" optionValue="modelName"
                                [formControl]="profileForm.controls.model" [apiUrl]="this.rmsConfig.autoVehicleModels"
                                placeholder="{{'Model' | translate}}" class="fx-selectBox astric-star"
                                [disabled]="disableCtrls">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.model">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'ModelCode' | translate}}" controlName="modelCode" maxlength="25"
                                [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.modelCode">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'NoofDoors' | translate}}" controlName="noOfDoors" maxlength="2"
                                [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.noOfDoors">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="engineTypeId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'EngineType' | translate}}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'ENGINETYPE'" [moduleName]="'Rms'"
                                [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.engineTypeId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'YearofManufacture' | translate}}" controlName="yearManufacture"
                                maxlength="4" [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.yearManufacture">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'SeatingCapacity' | translate}}" controlName="seatingCap" maxlength="3"
                                [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.seatingCap">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'EngineCapacity' | translate}}" controlName="engineCap" maxlength="5"
                                [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.engineCap">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="vehicleCategoryTypeId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'VehicleCategory' | translate}}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'VEHICLECATEGORY'"
                                [moduleName]="'Rms'" [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg=""
                                [control]="profileForm.controls.vehicleCategoryTypeId">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="transmissionId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'Transmission' | translate}}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'TRANSMISSION'"
                                [moduleName]="'Rms'" [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.transmissionId">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'SalePriceCountyofOrigin' | translate}}" controlName="salePrice"
                                maxlength="25" [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.salePrice">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-autocomplete [optionsData]="auto_countryOfManufac" [formGroup]="profileForm"
                                ngDefaultControl optionKey="countryId" optionValue="countryName"
                                [formControl]="profileForm.controls.manufacturingCountryId"
                                [apiUrl]="this.rmsConfig.countryApiUrl"
                                placeholder="{{'ManufacturingCountry' | translate}}" class="fx-selectBox astric-star"
                                [disabled]="disableCtrls">
                            </mcn-autocomplete>
                            <mcn-error-messages customErrorMsg=""
                                [control]="profileForm.controls.manufacturingCountryId">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'UnitPrice' | translate}}" controlName="unitPrice" maxlength="25"
                                [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.unitPrice">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-input class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'EstimatedPrice(RM)' | translate}}" controlName="estimatedPrice"
                                maxlength="25" [disabled]="disableCtrls">
                            </mcn-input>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.estimatedPrice">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-select optionKey="rmsTypeId" optionValue="name" [formGroup]="profileForm"
                                controlName="valuationMethodId" [apiUrl]="this.rmsConfig.getRmsTypes"
                                placeholder="{{'ValuationMethods' | translate}}" class="fx-selectBox astric-star"
                                optionParameterKey="typeName" [optionParameterValue]="'VALUATION METHOD'"
                                [moduleName]="'Rms'" [disabled]="disableCtrls">
                            </mcn-select>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.valuationMethodId">
                            </mcn-error-messages>

                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-date class="astric-star" [formGroup]="profileForm"
                                placeholder="{{'FromDate' | translate}}" controlName="fromDate"
                                (OnChange)="checkDateConditions($event)" [disabled]="this.disableFromDateControl"
                                [completeDisable]="disableCtrls">
                            </mcn-date>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.fromDate">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel">
                            <mcn-date [ngClass]="toDateClass" [formGroup]="profileForm"
                                placeholder="{{'ToDate' | translate}}" controlName="toDate"
                                [completeDisable]="disableToDate" [disabled]="this.disableToDateControl"
                                (OnChange)="checkDateConditions($event)">
                            </mcn-date>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.toDate">
                            </mcn-error-messages>
                        </div>
                        <div fxFlex="32" fxFlex.gt-xs="32" class="p-2 ps-rel mt-15">
                            <div fxFlex="2" fxFlex.gt-xs="2" class="p-2 ps-rel checkbox-mr">
                                <mcn-checkbox [formGroup]="profileForm" controlName="infiniteDate" optionalId="1"
                                    ngDefaultControl (OnCheck)="checkInfinite()" [disabled]="disableCtrls">
                                </mcn-checkbox>
                            </div>
                            <div fxFlex="30" fxFlex.gt-xs="30" class="p-2 ps-rel">
                                <span>{{'InfiniteDate' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row Wrap">
                        <div fxFlex="2"></div>
                        <div fxFlex="30" fxFlex.gt-xs="66" class="p-2 ps-rel" *ngIf="showHideRemarks">
                            <mcn-textarea [formGroup]="profileForm" placeholder="{{'Remarks' | translate}}"
                                maxlength="512"
                                [ngClass]="{'astric-star': mandatorySymbol == true, '': mandatorySymbol == false }"
                                controlName="remarks" [disabled]="disableRemarks">
                            </mcn-textarea>
                            <mcn-error-messages customErrorMsg="" [control]="profileForm.controls.remarks">
                            </mcn-error-messages>
                        </div>
                    </div>
                    <div *ngIf="showHideSpecificationSublist">
                        <mat-expansion-panel class="p-1 my-3">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'SpecificationsList' | translate }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxLayout="column"  fxLayoutAlign="center center"
                                 *ngIf="this.nvdbSpecificationList?.length=='0'">
                                <img src="../../../../../../assets/images/No-Records-Found.png" alt="No Records" />
                                <span class="no-records-found">{{'NoRecordsFound' | translate}}</span>
                            </div>
                            <mat-card class="card-grey-border p-3 my-2" *ngFor="let details of nvdbSpecificationList"
                                fxLayout="column">
                                <div fxLayout="column" fxFlex="100">
                                    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center">
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.specificationDetails?.code}}
                                            </h6>
                                            <p class="preview-label">{{'SpecificationCode' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.specificationDetails?.specificationName?.description}}
                                            </h6>
                                            <p class="preview-label">{{'SpecificationType' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.startDate | date}}
                                            </h6>
                                            <p class="preview-label">{{'StartDate' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2">
                                            <h6 class="preview-content font-semi-bold">
                                                {{details?.endDate | date}}
                                            </h6>
                                            <p class="preview-label">{{'EndDate' | translate}}</p>
                                        </div>
                                        <div fxFlex="20" class="mt-2" *ngIf="this.showHideAddButton">
                                            <ul fxLayout="row">
                                                <li class="edit">
                                                    <i class="icon-black-edit" appCustomTooltip
                                                        title="{{'Edit' | translate}}"
                                                        (click)="editNVDBSpecification(details.tariffItemSpecId)">
                                                    </i>
                                                </li>
                                                <li class="ml-1 mr-1"></li>
                                                <li class="delete">
                                                    <i class="icon-black-delete" appCustomTooltip
                                                        title="{{'Delete' | translate}}"
                                                        (click)="deleteNVDBSpecification(details)">
                                                    </i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="100" class="mt-2">
                                        <p class="preview-content" style="white-space: normal;">
                                            {{details?.specificationDetails?.caption}}
                                        </p>
                                        <h6 class="preview-label">{{'Caption' | translate}}</h6>
                                    </div>
                                </div>
                            </mat-card>
                            <div *ngIf="this.showHideAddButton">
                                <span class="cur-pointer" (click)="newCommoditySpecificationPopup()">
                                    <i class="r-icon-plus"></i>{{'AddNew' | translate}}</span>
                            </div>
                        </mat-expansion-panel>
                    </div>
                    <div class="form-footer px-2">
                        <div class="button-container">
                            <mcn-button-list class="mr-0 fxbtn-gap" (OnClick)="submitVehicle()"
                                [allowedActions]="serviceDocument.dataProfile.actionService.allowedActions"
                                [formGroup]="profileForm">
                            </mcn-button-list>
                            <mcn-button type="button" cssClass="btn-custom-secondary" text="{{'Cancel' | translate}}"
                                class="ml-3" (click)="cancel();">
                            </mcn-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>