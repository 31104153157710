import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { VesselAmmendedModel } from './addVessel/models/VesselAmmendedModel';

export interface CarrierData {
  carrierType: number;
  carrierId: number;
  flightId: number;
  pilotId: number;
  isChecked: boolean;
  railCarrierId: number;
  vesselId: number;
  vesselTypeId: number;
  vesselAmmendedServiceDocument: ServiceDocument<VesselAmmendedModel>;
  landCarrierId: number;
  vesselTypeCode: string;
  isUploadCertificateAvaible: boolean;
  originPortName: string;
  destinationPort: string;
  originLocationId: number;
  destinationlocationId: number;
  locationId: number;
}
@Injectable()
export class CarrierShareService {
  public sharingData: CarrierData = {
    carrierType: 0, isUploadCertificateAvaible: null, carrierId: 0, flightId: 0, pilotId: 0,
     isChecked: false, railCarrierId: 0, vesselId: 0, vesselTypeId: 0, vesselAmmendedServiceDocument: null,
      landCarrierId: 0, vesselTypeCode: null, originPortName: null, destinationPort: null, originLocationId: 0,
       destinationlocationId: 0, locationId: 0
  };

  constructor() {
  }
}
