<div class="chart-container pt-5">
  <h4 class="main-title mb-4"> Carrier Tracking Details </h4>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="my-3">
    <div fxFlex="100" class="my-3">
      <div class="card-grey-border map-chart-card">
        <agm-map [latitude]="lat" [longitude]="lng" style="height:300px !important">
          <agm-direction [origin]="origin" [destination]="destination" [renderOptions]="renderOpts" [markerOptions]="markerOpts">
          </agm-direction>
        </agm-map>
      </div>
    </div>
    <div fxLayout="row wrap">
      <div fxFlex="49" fxFlex.xs="100" class="my-3">
        <div class="card-grey-border map-chart-card">
          <chart [options]="optionsTemp"></chart>
        </div>
      </div>
      <span fxFlex="2" fxHide.xs="true"> &nbsp;</span>
      <div fxFlex="49" fxFlex.xs="100" class="my-3">
        <div class="card-grey-border map-chart-card">
          <chart [options]="optionsFh"></chart>
        </div>
      </div>
      <div fxFlex="49" fxFlex.xs="100" class="my-3">
        <div class="card-grey-border map-chart-card">
          <chart [options]="optionsPresure"></chart>
        </div>
      </div>
    </div>
    <div fxFlex="100" class="my-3">
      <chart [options]="this.options1"></chart>
    </div>
  </div>
</div>
