<div fxLayout="column Wrap" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="row Wrap">
        <div fxLayout="column Wrap" fxFlex="100" fxLayoutAlign="center center">
            <div fxLayout="row" class="flex-risk-head">
                <h2 fxFlex="100" class="main-title risk-indicator-upload-text pb-0">
                    <span>Upload Document</span>
                </h2>
                <!-- <h6 fxFlex="10" class="preview-content font-semi-bold"> -->
                <!-- <span class="status-tag created" *ngIf="newFrequecy">New</span> -->
                <!-- <span class="status-tag created">Modified</span> -->
                <!-- </h6> -->
            </div>
            <div>
                <i class="star-heading-ul"></i>
            </div>

        </div>
    </div>
    <div fxLayout="column">
        <form [formGroup]="riskIndicatorUploadFrom" novalidate>
            <div mat-dialog-content>
                <div fxLayout="row Wrap" class="mt-3">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-select class="astric-star" [formGroup]="riskIndicatorUploadFrom"
                            placeholder="Document Type" controlName="docType">
                        </mcn-select>
                    </div>
                    <!-- <mcn-error-messages  class="error" customErrorMsg="Required"></mcn-error-messages> -->
                </div>
                <div fxLayout="row Wrap">
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-input class="astric-star" [formGroup]="riskIndicatorUploadFrom"
                            placeholder="Document Reference No" controlName="docRefNo">
                        </mcn-input>
                    </div>
                    <div fxFlex="50" fxFlex.gt-xs="50" class="p-2 ps-rel">
                        <mcn-date [formGroup]="riskIndicatorUploadFrom" placeholder="Expiry Date" controlName="docType">
                        </mcn-date>
                    </div>
                    <!-- <mcn-error-messages  class="error" customErrorMsg="Required"></mcn-error-messages> -->
                </div>
                <div fxLayout="row wrap" style="margin-bottom:20px">
                    <div fxFlex="1" fxFlex.gt-xs="1"></div>
                    <div fxFlex="48" fxFlex.gt-xs="48" class="view-content position-relative">
                        <ul>
                            <li class="preview-content font-bold" fxLayoutAlign="start start">
                                <span style="margin-top:10px;">{{'FileUpload*'| translate}}</span>
                            </li>
                        </ul>
                        <mcn-button fxFlex="20" cssClass="btn btn-primary" type="button" text="{{'Browse'| translate}}"
                            style="height:33px;margin-left:10px">
                        </mcn-button>
                    </div>
                </div>
                <div fxLayout="row Wrap">
                    <div fxFlex="100" fxFlex.gt-xs="100" class="p-2 ps-rel">
                        <span>Remarks
                            <sup class="star-color">*</sup>
                        </span>
                        <mcn-textarea [formGroup]="riskIndicatorUploadFrom" placeholder="Remarks" controlName="remarks">
                        </mcn-textarea>
                    </div>
                    <!-- <mcn-error-messages  class="error" customErrorMsg="Required"></mcn-error-messages> -->
                </div>
                <div fxLayout="row Wrap">
                    <div fxFlex="100" fxFlex.gt-xs="100" class="p-2 ps-rel">
                        <div fxFlex="100" class="docs-table mob-table">
                            <table fxFlex="100" class="table table-striped custom-table" style="width:100%">
                                <thead class="docs-table-thead">
                                    <tr>
                                        <th>
                                            <a class="d-flex flex-row align-items-center">
                                                <span>{{'DocumentType' | translate }}</span>
                                                <span
                                                    class="sort-icons d-inline-flex flex-column justify-content-center align-items-center">
                                                    <i class="icon-triangle-up"></i>
                                                    <i class="icon-triangle-down"></i>
                                                </span>
                                            </a>
                                        </th>
                                        <th>
                                            <a class="d-flex flex-row align-items-center">
                                                <span>{{'Document Ref No' | translate }}</span>
                                                <span
                                                    class="sort-icons d-inline-flex flex-column justify-content-center align-items-center">
                                                    <i class="icon-triangle-up"></i>
                                                    <i class="icon-triangle-down"></i>
                                                </span>
                                            </a>
                                        </th>
                                        <th>
                                            <a class="d-flex flex-row align-items-center">
                                                <span>{{'File Name' | translate }}</span>
                                                <span
                                                    class="sort-icons d-inline-flex flex-column justify-content-center align-items-center">
                                                    <i class="icon-triangle-up"></i>
                                                    <i class="icon-triangle-down"></i>
                                                </span>
                                            </a>
                                        </th>
                                        <th>
                                            <a class="d-flex flex-row align-items-center">
                                                <span>{{'Expiry Date' | translate }}</span>
                                                <span
                                                    class="sort-icons d-inline-flex flex-column justify-content-center align-items-center">
                                                    <i class="icon-triangle-up"></i>
                                                    <i class="icon-triangle-down"></i>
                                                </span>
                                            </a>
                                        </th>
                                        <th>{{'Action' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody class="docs-table-body">
                                    <tr>
                                        <td data-title="Document Type">.XLSX</td>
                                        <td data-title="File Name">A12D440</td>
                                        <td data-title="Document Type">Risk Indicators</td>
                                        <td data-title="File Name">12 Feb 2018</td>
                                        <td data-title="" class="icon-td">
                                            <ul class="icon-td-list">
                                                <li class="icon-td-icons">
                                                    <a>
                                                        <i class="icon-preview mr-10"></i>
                                                    </a>
                                                    <a>
                                                        <i class="download-arrow-icon mr-10"></i>
                                                    </a>
                                                    <a>
                                                        <i class="icon-delete"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <mcn-error-messages  class="error" customErrorMsg="Required"></mcn-error-messages> -->
                </div>
            </div>





        </form>
    </div>
    <div class="form-footer px-2">
        <div class="button-container">
            <mcn-button type="submit" cssClass="btn-custom-primary" text="{{'Upload' | translate}}">
            </mcn-button>
            <!-- <mcn-button type="submit" cssClass="btn-custom-primary"  text="{{'SAVE' | translate}}">
            </mcn-button> -->
            <mcn-button type="button" cssClass="btn-custom-secondary" text="{{'Cancel' | translate}}" class="ml-3">
            </mcn-button>
        </div>
    </div>
</div>
<button matDialogClose class="dialog-close">
    <i class="icon-close"></i>
</button>