import { Injectable } from '@angular/core';

export interface MyData {
  likelihoodId: number;
  consequenceId: number;
  riskValueId: number;
  targetDecisionId: number;
  watchListId: number;
  riskId: number;
  riskNumber: string;
  srAId: number;
  riskProfileId: number;
  configurationId: number;
  riskIdentificationId: number;
  complianceCategoryId: number;
  complianceScorePreferenceId: number;
  randomConfigId: number;
  dynamicWatchListId: number;
  complianceScoreConfigurationId: number;
  riskRuleId: number;
  riskRuleDEDetails: any[];
  reviewRiskState: string;
  riskIndicator: string;
  riskFPSDetails: any[];
  riskSDDetails: any[];
  risksRegisterHistoryId: number;
  isValidateRiskRuleDetails: boolean;
  isValidateRRDAction: boolean;
  isValidateRiskRuleSD: boolean;
  isValidateRiskRuleFPS: boolean;
  isOpnfromWatchlistConfig: boolean;
  referenceId: number;
  riskProfileReviewHistoryId: number;
  riskFrequencyList: any[];
  riskImpactList: any[];
  declarationType: string;
  registeredOrganizationId: number;
  complianceOrgDetails: string;
  RisksHistoryId: number;
  isFromList: boolean;
  inspInsList: number;
  isFromPopUp: boolean;
  actionCode: string;
  aiRisksConfigurationId: number;
  riskAnalsisValue: string;
  riskAnalysisFromDate: any;
  riskAnalysisToDate: any;
}

@Injectable()
export class RiskSelectivityProfilingSharedService {
  sharingData: MyData = {
    likelihoodId: 0,
    consequenceId: 0,
    riskValueId: 0,
    targetDecisionId: 0,
    watchListId: 0,
    dynamicWatchListId: 0,
    riskId: 0,
    riskNumber: null,
    srAId: 0,
    riskProfileId: 0,
    configurationId: 0,
    riskIdentificationId: 0,
    complianceCategoryId: 0,
    complianceScorePreferenceId: 0,
    randomConfigId: 0,
    complianceScoreConfigurationId: 0,
    riskRuleId: 0,
    riskRuleDEDetails: [],
    reviewRiskState: null,
    riskIndicator: null,
    riskFPSDetails: [],
    riskSDDetails: [],
    risksRegisterHistoryId: 0,
    isValidateRiskRuleDetails: false,
    isValidateRRDAction: false,
    isValidateRiskRuleSD: false,
    isValidateRiskRuleFPS: false,
    referenceId: 0,
    riskProfileReviewHistoryId: 0,
    riskFrequencyList: [],
    riskImpactList: [],
    isOpnfromWatchlistConfig: false,
    declarationType: null,
    registeredOrganizationId: 0,
    complianceOrgDetails: null,
    RisksHistoryId: 0,
    isFromList: false,
    inspInsList: 0,
    isFromPopUp: false,
    actionCode: null,
    aiRisksConfigurationId: 0,
    riskAnalsisValue: '',
    riskAnalysisFromDate: '',
    riskAnalysisToDate: ''
  };

  constructor() {
  }
}
