export let guageData = [
  {
    'name': 'Vessels Arrived',
    'value': 4000
  },
  {
    'name': 'Aircrafts Arrived ',
    'value': 2500
  },
  {
    'name': 'Vehicles Arrived',
    'value': 8000
  }
];

export let malguageData = [
  {
    'name': 'Vessels Arrived',
    'value': 940000
  },
  {
    'name': 'Aircrafts Arrived ',
    'value': 500000
  },
  {
    'name': 'Vehicles Arrived',
    'value': 200000
  }
];

export let egtguageData = [
  {
    'name': 'Vessels Arrived',
    'value': 940000
  },
  {
    'name': 'Aircrafts Arrived ',
    'value': 500000
  },
  {
    'name': 'Vehicles Arrived',
    'value': 200000
  }
];

export let guageData2017 = [
  {
    'name': 'Vessels Arrived',
    'value': 3500
  },
  {
    'name': 'Aircrafts Arrived ',
    'value': 2000
  },
  {
    'name': 'Vehicles Arrived',
    'value': 7000
  }
];

export let guageData2018 = [
  {
    'name': 'Vessels Arrived',
    'value': 4000
  },
  {
    'name': 'Aircrafts Arrived ',
    'value': 2500
  },
  {
    'name': 'Vehicles Arrived',
    'value': 8000
  }
];


export let areaData2017 = [

  ['Port', 'Imports', 'Exports', 'Transit'],
  ['Port Klang', 324, 53, 345],
  ['Tanjung Pelepas Port', 123, 6567, 79],
  ['Kuantan Port', 324, 5009, 123],
  ['Penang Port', 107, 456, 789],
  ['Bintulu Port', 234, 45, 658],
  ['Kemaman  Port', 657, 879, 897],

];

export let areaData2018 = [

  ['Port', 'imports', 'Exports', 'Transit'],
  ['Port Klang', 234, 4556, 898],
  ['Tanjung Pelepas Port', 345, 907, 45],
  ['Kuantan Port', 345, 768, 7686],
  ['Penang Port', 6767, 678, 211],
  ['Bintulu Port', 567, 345, 767],
  ['Kemaman  Port', 43, 120, 332],
];

export let multi = [
  {
    'name': '1st',
    'series': [
      {
        'name': 'Import',
        'value': 40
      },
      {
        'name': 'Export',
        'value': 30
      },
      {
        'name': 'Transit',
        'value': 20
      }
    ]
  },

  {
    'name': '2nd',
    'series': [
      {
        'name': 'Import',
        'value': 35
      },
      {
        'name': 'Export',
        'value': 20
      },
      {
        'name': 'Transit',
        'value': 15
      }
    ]
  },

  {
    'name': '3rd',
    'series': [
      {
        'name': 'Import',
        'value': 30
      },
      {
        'name': 'Export',
        'value': 20
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '4th',
    'series': [
      {
        'name': 'Import',
        'value': 25
      },
      {
        'name': 'Export',
        'value': 15
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '5th',
    'series': [
      {
        'name': 'Import',
        'value': 30
      },
      {
        'name': 'Export',
        'value': 20
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '6th',
    'series': [
      {
        'name': 'Import',
        'value': 26
      },
      {
        'name': 'Export',
        'value': 14
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '7th',
    'series': [
      {
        'name': 'Import',
        'value': 20
      },
      {
        'name': 'Export',
        'value': 15
      },
      {
        'name': 'Transit',
        'value': 9
      }
    ]
  },

  {
    'name': '8th',
    'series': [
      {
        'name': 'Import',
        'value': 30
      },
      {
        'name': 'Export',
        'value': 23
      },
      {
        'name': 'Transit',
        'value': 23
      }
    ]
  },

  {
    'name': '9th',
    'series': [
      {
        'name': 'Import',
        'value': 34
      },
      {
        'name': 'Export',
        'value': 23
      },
      {
        'name': 'Transit',
        'value': 12
      }
    ]
  },

  {
    'name': '10th',
    'series': [
      {
        'name': 'Import',
        'value': 36
      },
      {
        'name': 'Export',
        'value': 13
      },
      {
        'name': 'Transit',
        'value': 5
      }
    ]
  },

  {
    'name': '11th',
    'series': [
      {
        'name': 'Import',
        'value': 45
      },
      {
        'name': 'Export',
        'value': 29
      },
      {
        'name': 'Transit',
        'value': 20
      }
    ]
  },

  {
    'name': '12th',
    'series': [
      {
        'name': 'Import',
        'value': 23
      },
      {
        'name': 'Export',
        'value': 12
      },
      {
        'name': 'Transit',
        'value': 9
      }
    ]
  },
  {
    'name': '13th',
    'series': [
      {
        'name': 'Import',
        'value': 30
      },
      {
        'name': 'Export',
        'value': 27
      },
      {
        'name': 'Transit',
        'value': 14
      }
    ]
  },

  {
    'name': '14th',
    'series': [
      {
        'name': 'Import',
        'value': 28
      },
      {
        'name': 'Export',
        'value': 16
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '15th',
    'series': [
      {
        'name': 'Import',
        'value': 38
      },
      {
        'name': 'Export',
        'value': 24
      },
      {
        'name': 'Transit',
        'value': 15
      }
    ]
  },

  {
    'name': '16th',
    'series': [
      {
        'name': 'Import',
        'value': 40
      },
      {
        'name': 'Export',
        'value': 23
      },
      {
        'name': 'Transit',
        'value': 15
      }
    ]
  },

  {
    'name': '17th',
    'series': [
      {
        'name': 'Import',
        'value': 29
      },
      {
        'name': 'Export',
        'value': 21
      },
      {
        'name': 'Transit',
        'value': 18
      }
    ]
  },

  {
    'name': '18th',
    'series': [
      {
        'name': 'Import',
        'value': 29
      },
      {
        'name': 'Export',
        'value': 12
      },
      {
        'name': 'Transit',
        'value': 9
      }
    ]
  },

  {
    'name': '19',
    'series': [
      {
        'name': 'Import',
        'value': 36
      },
      {
        'name': 'Export',
        'value': 28
      },
      {
        'name': 'Transit',
        'value': 18
      }
    ]
  },

  {
    'name': '20th',
    'series': [
      {
        'name': 'Import',
        'value': 38
      },
      {
        'name': 'Export',
        'value': 28
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '21st',
    'series': [
      {
        'name': 'Import',
        'value': 37
      },
      {
        'name': 'Export',
        'value': 25
      },
      {
        'name': 'Transit',
        'value': 26
      }
    ]
  },

  {
    'name': '22nd',
    'series': [
      {
        'name': 'Import',
        'value': 40
      },
      {
        'name': 'Export',
        'value': 29
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },

  {
    'name': '23rd',
    'series': [
      {
        'name': 'Import',
        'value': 38
      },
      {
        'name': 'Export',
        'value': 25
      },
      {
        'name': 'Transit',
        'value': 18
      }
    ]
  },

  {
    'name': '24th',
    'series': [
      {
        'name': 'Import',
        'value': 39
      },
      {
        'name': 'Export',
        'value': 12
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },
  {
    'name': '25th',
    'series': [
      {
        'name': 'Import',
        'value': 37
      },
      {
        'name': 'Export',
        'value': 29
      },
      {
        'name': 'Transit',
        'value': 18
      }
    ]
  },

  {
    'name': '26th',
    'series': [
      {
        'name': 'Import',
        'value': 39
      },
      {
        'name': 'Export',
        'value': 29
      },
      {
        'name': 'Transit',
        'value': 19
      }
    ]
  },

  {
    'name': '27th',
    'series': [
      {
        'name': 'Import',
        'value': 40
      },
      {
        'name': 'Export',
        'value': 25
      },
      {
        'name': 'Transit',
        'value': 15
      }
    ]
  },

  {
    'name': '28th',
    'series': [
      {
        'name': 'Import',
        'value': 35
      },
      {
        'name': 'Export',
        'value': 20
      },
      {
        'name': 'Transit',
        'value': 18
      }
    ]
  },
  {
    'name': '29th',
    'series': [
      {
        'name': 'Import',
        'value': 45
      },
      {
        'name': 'Export',
        'value': 29
      },
      {
        'name': 'Transit',
        'value': 19
      }
    ]
  },

  {
    'name': '30th',
    'series': [
      {
        'name': 'Import',
        'value': 39
      },
      {
        'name': 'Export',
        'value': 26
      },
      {
        'name': 'Transit',
        'value': 15
      }
    ]
  },
  {
    'name': '31st',
    'series': [
      {
        'name': 'Import',
        'value': 28
      },
      {
        'name': 'Export',
        'value': 19
      },
      {
        'name': 'Transit',
        'value': 10
      }
    ]
  },
];

export let yMulti2018 = [
  {
    'name': 'Jan',
    'series': [
      {
        'name': 'Import',
        'value': 1200
      },
      {
        'name': 'Export',
        'value': 1000
      },
      {
        'name': 'Transit',
        'value': 800
      }
    ]
  },

  {
    'name': 'Feb',
    'series': [
      {
        'name': 'Import',
        'value': 1100
      },
      {
        'name': 'Export',
        'value': 900
      },
      {
        'name': 'Transit',
        'value': 700
      }
    ]
  },

  {
    'name': 'March',
    'series': [
      {
        'name': 'Import',
        'value': 1000
      },
      {
        'name': 'Export',
        'value': 800
      },
      {
        'name': 'Transit',
        'value': 600
      }
    ]
  },
  {
    'name': 'April',
    'series': [
      {
        'name': 'Import',
        'value': 1050
      },
      {
        'name': 'Export',
        'value': 650
      },
      {
        'name': 'Transit',
        'value': 450
      }
    ]
  },

  {
    'name': 'May',
    'series': [
      {
        'name': 'Import',
        'value': 1080
      },
      {
        'name': 'Export',
        'value': 960
      },
      {
        'name': 'Transit',
        'value': 380
      }
    ]
  },

  {
    'name': 'Jun',
    'series': [
      {
        'name': 'Import',
        'value': 900
      },
      {
        'name': 'Export',
        'value': 800
      },
      {
        'name': 'Transit',
        'value': 600
      }
    ]
  },

  {
    'name': 'Jul',
    'series': [
      {
        'name': 'Import',
        'value': 1150
      },
      {
        'name': 'Export',
        'value': 800
      },
      {
        'name': 'Transit',
        'value': 600
      }
    ]
  },
  {
    'name': 'Aug',
    'series': [
      {
        'name': 'Import',
        'value': 900
      },
      {
        'name': 'Export',
        'value': 700
      },
      {
        'name': 'Transit',
        'value': 550
      }
    ]
  },
  {
    'name': 'Sep',
    'series': [
      {
        'name': 'Import',
        'value': 950
      },
      {
        'name': 'Export',
        'value': 700
      },
      {
        'name': 'Transit',
        'value': 600
      }
    ]
  },
  {
    'name': 'Oct',
    'series': [
      {
        'name': 'Import',
        'value': 1250
      },
      {
        'name': 'Export',
        'value': 900
      },
      {
        'name': 'Transit',
        'value': 500
      }
    ]
  },
  {
    'name': 'Nov',
    'series': [
      {
        'name': 'Import',
        'value': 1000
      },
      {
        'name': 'Export',
        'value': 700
      },
      {
        'name': 'Transit',
        'value': 550
      }
    ]
  },
  {
    'name': 'Dec',
    'series': [
      {
        'name': 'Import',
        'value': 900
      },
      {
        'name': 'Export',
        'value': 850
      },
      {
        'name': 'Transit',
        'value': 650
      }
    ]
  },
];

export let yMulti2017 = [
  {
    'name': 'Jan',
    'series': [
      {
        'name': 'Import',
        'value': 1100
      },
      {
        'name': 'Export',
        'value': 900
      },
      {
        'name': 'Transit',
        'value': 700
      }
    ]
  },

  {
    'name': 'Feb',
    'series': [
      {
        'name': 'Import',
        'value': 1000
      },
      {
        'name': 'Export',
        'value': 900
      },
      {
        'name': 'Transit',
        'value': 700
      }
    ]
  },

  {
    'name': 'March',
    'series': [
      {
        'name': 'Import',
        'value': 900
      },
      {
        'name': 'Export',
        'value': 790
      },
      {
        'name': 'Transit',
        'value': 680
      }
    ]
  },
  {
    'name': 'April',
    'series': [
      {
        'name': 'Import',
        'value': 1050
      },
      {
        'name': 'Export',
        'value': 650
      },
      {
        'name': 'Transit',
        'value': 450
      }
    ]
  },

  {
    'name': 'May',
    'series': [
      {
        'name': 'Import',
        'value': 980
      },
      {
        'name': 'Export',
        'value': 800
      },
      {
        'name': 'Transit',
        'value': 500
      }
    ]
  },

  {
    'name': 'Jun',
    'series': [
      {
        'name': 'Import',
        'value': 790
      },
      {
        'name': 'Export',
        'value': 600
      },
      {
        'name': 'Transit',
        'value': 500
      }
    ]
  },

  {
    'name': 'Jul',
    'series': [
      {
        'name': 'Import',
        'value': 1000
      },
      {
        'name': 'Export',
        'value': 780
      },
      {
        'name': 'Transit',
        'value': 500
      }
    ]
  },
  {
    'name': 'Aug',
    'series': [
      {
        'name': 'Import',
        'value': 890
      },
      {
        'name': 'Export',
        'value': 650
      },
      {
        'name': 'Transit',
        'value': 500
      }
    ]
  },
  {
    'name': 'Sep',
    'series': [
      {
        'name': 'Import',
        'value': 1050
      },
      {
        'name': 'Export',
        'value': 850
      },
      {
        'name': 'Transit',
        'value': 700
      }
    ]
  },
  {
    'name': 'Oct',
    'series': [
      {
        'name': 'Import',
        'value': 1250
      },
      {
        'name': 'Export',
        'value': 900
      },
      {
        'name': 'Transit',
        'value': 500
      }
    ]
  },
  {
    'name': 'Nov',
    'series': [
      {
        'name': 'Import',
        'value': 1050
      },
      {
        'name': 'Export',
        'value': 800
      },
      {
        'name': 'Transit',
        'value': 600
      }
    ]
  },
  {
    'name': 'Dec',
    'series': [
      {
        'name': 'Import',
        'value': 900
      },
      {
        'name': 'Export',
        'value': 750
      },
      {
        'name': 'Transit',
        'value': 600
      }
    ]
  },
];


export let lineData2017 = [
  ['Month', 'Manifests', 'Delivery Orders'],
  ['Jan', 100, 420],
  ['Feb', 620, 1920],
  ['Mar', 123, 2134],
  ['Apr', 234, 567],
  ['May', 1170, 460],
  ['Jun', 660, 1120],
  ['Jul', 3345, 546],
  ['Oct', 34, 546],
  ['Nov', 546, 56],
  ['Dec', 3345, 435],
];

export let lineData2018 = [
  ['Month', 'Manifests', 'Delivery Orders'],
  ['Jan', 234, 324],
  ['Feb', 67, 867],
  ['Mar', 678, 67453],
  ['Apr', 98, 432],
  ['May', 546, 78],
  ['Jun', 3241, 890],
  ['Jul', 345, 8797],
  ['Oct', 234, 980],
  ['Nov', 435, 546],
  ['Dec', 345, 1290],
];

export let pieChartData2017 = [
  ['Vessel Types ', 'Importation Count'],
  ['Container Ship', 600],
  ['Tug', 300],
  ['Bulk Carrier', 250],
  ['Chemical Tanker', 150],
  ['General Cargo Ship', 70],
  ['Multi Purpose Carrier', 80],
  ['Patrol Vessel', 90],
  ['Passenger Ship', 40],
  ['Oil Products Tanker', 30],
  ['Deck Cargo Ship', 20]
];

export let pieChartData2018 = [
  ['Vessel Types ', 'Importation Count'],
  ['Container Ship', 800],
  ['Tug', 400],
  ['Bulk Carrier', 300],
  ['Chemical Tanker', 200],
  ['General Cargo Ship', 100],
  ['Multi Purpose Carrier', 100],
  ['Patrol Vessel', 100],
  ['Passenger Ship', 50],
  ['Oil Products Tanker', 50],
  ['Deck Cargo Ship', 50]
];

export let pie_ChartData = [
  ['Vessel Types ', 'Importation Count'],
  ['Container Ship', 180],
  ['Tug', 7],
  ['Bulk Carrier', 3],
  ['Chemical Tanker', 2],
  ['General Cargo Ship', 1],
  ['Multi Purpose Carrier', 1],
  ['Patrol Vessel', 1],
  ['Passenger Ship', 1],
  ['Oil Products Tanker', 1],
  ['Deck Cargo Ship', 1]
];

export let bar_ChartData = [
  ['Country', '2018', '2017'],
  ['China, CN', 100, 90],
  ['United States, USA', 200, 150],
  ['Japan, JP', 120, 100],
  ['Germany, DE', 200, 300],
  ['Indonesia, ID', 100, 50],
  ['India, IN', 100, 80],
  ['United Arab Emirates, AE', 150, 100],
  ['Italy, IU', 180, 100],
  ['United Kingdom, UK', 300, 200],
  ['Turkey, TR', 100, 50]
];

export let map_ChartData = [
  ['Country', 'Goods'],
  ['China', 1250.022204],
  ['United States Of America', 1055.728498],
  ['Japan', 536.3644769],
  ['Germany', 465.8544406],
  ['Indonesia', 399.6706906],
  ['United Arab Emirates', 339.5119002],
  ['Italy', 310.6547553],
  ['United Kingdom', 281.3262188],
  ['Turkey', 247.7536701],
  ['Korea(South)', 195.010763],
  ['France', 174.8872415],
  ['Netherlands', 174.789203],
  ['Iran', 170.4335344],
  ['Kuwait', 168.774638],
  ['Aruba', 142.6985748],
  ['Vietnam', 132.0736337],
  ['Thailand', 128.1914115],
  ['Switzerland', 104.7846702],
  ['Spain', 104.4403681],
  ['Australia', 84.61619038],
  ['Saudi Arabia', 77.79539221],
  ['Syria', 67.81928748],
  ['Brazil', 67.78127631],
  ['Canada', 65.39631547],
  ['Malaysia', 64.2934045],
  ['Croatia', 61.41104456],
  ['Jordan', 60.47080779],
  ['Bahrain', 55.57021416],
  ['Poland', 53.50309601],
  ['Austria', 53.2361766],
  ['Mexico', 48.65407965],
  ['Taiwan', 43.31802519],
  ['Lebanon', 42.58438784],
  ['Pakistan', 39.69516433],
  ['Belgium', 37.84332543],
  ['Ireland', 37.80070628],
  ['Argentina', 36.76541322],
  ['Egypt', 36.23457003],
  ['South Africa', 32.79878797],
  ['Slovakia', 30.25684808],
  ['Singapore', 29.15375935],
  ['Denmark', 28.61632596],
  ['Sultanate Of Oman', 28.58654218],
  ['Czeck  Republic', 27.1949586],
  ['Bangladesh', 23.25316131],
  ['Philippines', 23.22321394],
  ['Romania', 21.91639909],
  ['Sweden', 21.05828347],
  ['Russian Federation', 20.34861356],
  ['Portugal', 15.81139853],
  ['Hungary', 13.21599179],
  ['Sri Lanka(Ceylon)', 11.2413267],
  ['New Zealand', 9.693926531],
  ['Greece', 9.320226942],
  ['Qatar', 8.003983967],
  ['Equador', 7.821265942],
  ['Cambodia', 7.55432717],
  ['Chile', 7.545772996],
  ['Norway', 7.420251681],
  ['Iraq', 6.740154824],
  ['Ukraine', 5.698517663],
  ['Bulgaria', 5.532448432],
  ['Tunisia', 5.491031169],
  ['Finland', 5.286128779],
  ['Morocco', 5.070699791],
  ['Cyprus', 4.860997125],
  ['Paraguay', 3.663896019],
  ['Guatemala', 3.639471331],
  ['Puerto Rico', 3.440904641],
  ['Slovenia', 3.072222773],
  ['Myanmar(Burma)', 3.043348325],
  ['Hong Kong(Special Administrative Region Of China)', 2.945192646],
  ['Colombia', 2.430368761],
  ['Yemen', 2.292194712],
  ['Yugoslavia(Serbia & Montenegro)', 2.212557278],
  ['Belarus', 2.149531984],
  ['Lithuania', 1.925330478],
  ['Sudan', 1.812336672],
  ['Azerbaijan', 1.685231123],
  ['Bosnia And Herzegovina', 1.459509073],
  ['Latvia', 1.406353051],
  ['Costa Rica', 1.291292395],
  ['Georgia', 1.278234114],
  ['Kenya', 1.211501644],
  ['Madagascar', 1.174446814],
  ['Ethiopia', 1.164315564],
  ['Nigeria', 1.089956385],
  ['Peru', 1.02648627]
];


export let area_ChartData = [
  ['Port', 'imports', 'Exports', 'Transit'],
  ['Port Klang', 1000, 400, 120],
  ['Tanjung Pelepas Port', 1170, 460, 300],
  ['Kuantan Port', 660, 1120, 350],
  ['Penang Port', 1030, 540, 350],
  ['Bintulu Port', 1970, 450, 330],
  ['Kemaman  Port', 860, 120, 332],
];

export let line_ChartData = [
  ['Month', 'Manifests', 'Delivery Orders'],
  ['Jan', 1000, 400],
  ['Feb', 1170, 460],
  ['Mar', 660, 1120],
  ['Apr', 1030, 540],
  ['May', 1170, 460],
  ['Jun', 620, 1920],
  ['Jul', 324, 530],
  ['Oct', 345, 560],
  ['Nov', 190, 3245],
  ['Dec', 345, 457],
];

export let bubble_ChartData = [
  ['ID', 'Importation ', 'Frequence', 'Region', 'Goods'],
  ['CAN', 80.66, 1.67, 'North America', 33739900],
  ['DEU', 79.84, 1.36, 'Europe', 81902307],
  ['DNK', 78.6, 1.84, 'Europe', 5523095],
  ['EGY', 72.73, 2.78, 'Middle East', 79716203],
  ['GBR', 80.05, 2, 'Europe', 61801570],
  ['IRN', 72.49, 1.7, 'Middle East', 73137148],
  ['IRQ', 68.09, 4.77, 'Middle East', 31090763],
  ['ISR', 81.55, 2.96, 'Middle East', 7485600],
  ['RUS', 68.6, 1.54, 'Europe', 141850000],
  ['USA', 78.09, 2.05, 'North America', 307007000]
];

export let scatter_ChartData = [
  ['Date', 'Revenue Percentage'],
  [new Date(2018, 3, 22), 78],
  [new Date(2018, 3, 21, 9, 30, 2), 88],
  [new Date(2018, 3, 20), 67],
  [new Date(2018, 3, 19, 8, 34, 7), 98],
  [new Date(2018, 3, 18, 15, 34, 7), 95],
  [new Date(2018, 3, 16, 7, 30, 45), 89],
  [new Date(2018, 3, 16, 15, 40, 35), 68]
];

export let candle_ChartData = [
  ['Day', 'Low', 'Opening value', 'Closing value', 'High'],
  ['Mon', 28, 28, 38, 38],
  ['Tue', 38, 38, 55, 55],
  ['Wed', 55, 55, 77, 77],
  ['Thu', 77, 77, 66, 66],
  ['Fri', 66, 66, 22, 22]
];

export var importValue = [
  {
    "name": "Jul-20",
    "value": 40.40
  },
  {
    "name": "Aug-20",
    "value": 39.90
  },
  {
    "name": "Sep-20",
    "value": 37.70
  },
  {
    "name": "Oct-20",
    "value": 38.00
  },
  {
    "name": "Nov-20",
    "value": 38.50
  },
  {
    "name": "Dec-20",
    "value": 39.00
  },
  {
    "name": "Jan-21",
    "value": 41.00
  },
  {
    "name": "Feb-21",
    "value": 37.90
  },
  {
    "name": "Mar-21",
    "value": 31.50
  },
  {
    "name": "Apr-21",
    "value": 17.10
  },
  {
    "name": "May-21",
    "value": 32.20
  },
  {
    "name": "Jun-21",
    "value": 21.10
  }
];

export var importValue2020 = [
  {
    "name": "Jul-19",
    "value": 30.40
  },
  {
    "name": "Aug-19",
    "value": 19.90
  },
  {
    "name": "Sep-19",
    "value": 27.70
  },
  {
    "name": "Oct-19",
    "value": 28.20
  },
  {
    "name": "Nov-19",
    "value": 48.50
  },
  {
    "name": "Dec-19",
    "value": 19.00
  },
  {
    "name": "Jan-20",
    "value": 31.70
  },
  {
    "name": "Feb-20",
    "value": 17.80
  },
  {
    "name": "Mar-20",
    "value": 41.50
  },
  {
    "name": "Apr-20",
    "value": 37.30
  },
  {
    "name": "May-20",
    "value": 22.29
  },
  {
    "name": "Jun-20",
    "value": 31.10
  }
];

export var topImportCommodities = [
  {
    "name": "Pharma..",
    "value": 3.40
  },
  {
    "name": "Machinery..",
    "value": 1.60
  },
  {
    "name": "Cereals",
    "value": 1.57
  },
  {
    "name": "Vehicles",
    "value": 1.45
  },
  {
    "name": "Iron & Steel",
    "value": 1.34
  }
];

export var topImportCommodities2020 = [
  {
    "name": "Pharma..",
    "value": 2.99
  },
  {
    "name": "Machinery..",
    "value": 1.89
  },
  {
    "name": "Cereals",
    "value": 1.67
  },
  {
    "name": "Vehicles",
    "value": 1.35
  },
  {
    "name": "Iron & Steel",
    "value": 2.34
  }
];

export var topImportIndustrialZones = [
  {
    "name": "Bubanza",
    "value": 3.90
  },
  {
    "name": "Bujumbura",
    "value": 1.20
  },
  {
    "name": "Bururi",
    "value": 1.30
  },
  {
    "name": "Makamba",
    "value": 1.99
  },
  {
    "name": "Rumonge",
    "value": 1.39
  }
];

export var topImportIndustrialZones2020 = [
  {
    "name": "Bubanza",
    "value": 2.90
  },
  {
    "name": "Bujumbura",
    "value": 1.80
  },
  {
    "name": "Bururi",
    "value": 1.90
  },
  {
    "name": "Makamba",
    "value": 1.29
  },
  {
    "name": "Rumonge",
    "value": 1.59
  }
];

export var importCommodityZones = [
  {
    "name": "Petroleum Oils",
    "series": [
      {
        "name": "Bubanza",
        "value": 32.00
      },
      {
        "name": "Bujumbura",
        "value": 21.30
      },
      {
        "name": "Bururi",
        "value": 28.00
      },
      {
        "name": "Makamba",
        "value": 19.23
      },
      {
        "name": "Rumonge",
        "value": 38.00
      },
      {
        "name": "Muyinga",
        "value": 8.60
      },
      {
        "name": "Rutana",
        "value": 7.60
      }
    ]
  },

  {
    "name": "Iron",
    "series": [
      {
        "name": "Bubanza",
        "value": 22.00
      },
      {
        "name": "Bujumbura",
        "value": 11.00
      },
      {
        "name": "Bururi",
        "value": 13.00
      },
      {
        "name": "Makamba",
        "value": 20.00
      },
      {
        "name": "Rumonge",
        "value": 30.01
      },
      {
        "name": "Muyinga",
        "value": 18.60
      },
      {
        "name": "Rutana",
        "value": 5.60
      }
    ]
  },

  {
    "name": "Vehicles",
    "series": [
      {
        "name": "Bubanza",
        "value": 10.05
      },
      {
        "name": "Bujumbura",
        "value": 19.00
      },
      {
        "name": "Bururi",
        "value": 18.75
      },
      {
        "name": "Makamba",
        "value": 7.65
      },
      {
        "name": "Rumonge",
        "value": 25.00
      },
      {
        "name": "Muyinga",
        "value": 9.60
      },
      {
        "name": "Rutana",
        "value": 5.60
      }
    ]
  },
  {
    "name": "Cereals",
    "series": [
      {
        "name": "Bubanza",
        "value": 12.00
      },
      {
        "name": "Bujumbura",
        "value": 20.21
      },
      {
        "name": "Bururi",
        "value": 18.09
      },
      {
        "name": "Makamba",
        "value": 17.90
      },
      {
        "name": "Rumonge",
        "value": 28.00
      },
      {
        "name": "Muyinga",
        "value": 18.60
      },
      {
        "name": "Rutana",
        "value": 17.60
      }
    ]
  },
  {
    "name": "Non-Metals",
    "series": [
      {
        "name": "Bubanza",
        "value": 6.22
      },
      {
        "name": "Bujumbura",
        "value": 10.32
      },
      {
        "name": "Bururi",
        "value": 5.62
      },
      {
        "name": "Makamba",
        "value": 15.72
      },
      {
        "name": "Rumonge",
        "value": 21.09
      },
      {
        "name": "Muyinga",
        "value": 6.60
      },
      {
        "name": "Rutana",
        "value": 17.60
      }
    ]
  },
  {
    "name": "Machinery, Mechanical applicances",
    "series": [
      {
        "name": "Bubanza",
        "value": 22.00
      },
      {
        "name": "Bujumbura",
        "value": 7.30
      },
      {
        "name": "Bururi",
        "value": 8.50
      },
      {
        "name": "Makamba",
        "value": 19.23
      },
      {
        "name": "Rumonge",
        "value": 28.00
      },
      {
        "name": "Muyinga",
        "value": 2.60
      },
      {
        "name": "Rutana",
        "value": 1.60
      }
    ]
  },
  {
    "name": "Steel",
    "series": [
      {
        "name": "Bubanza",
        "value": 10.80
      },
      {
        "name": "Bujumbura",
        "value": 23.00
      },
      {
        "name": "Bururi",
        "value": 26.00
      },
      {
        "name": "Makamba",
        "value": 14.00
      },
      {
        "name": "Rumonge",
        "value": 30.01
      },
      {
        "name": "Muyinga",
        "value": 2.40
      },
      {
        "name": "Rutana",
        "value": 7.20
      }
    ]
  },
  {
    "name": "Steam Coal",
    "series": [
      {
        "name": "Bubanza",
        "value": 4.05
      },
      {
        "name": "Bujumbura",
        "value": 19.00
      },
      {
        "name": "Bururi",
        "value": 18.55
      },
      {
        "name": "Makamba",
        "value": 19.65
      },
      {
        "name": "Rumonge",
        "value": 25.00
      },
      {
        "name": "Muyinga",
        "value": 28.40
      },
      {
        "name": "Rutana",
        "value": 17.30
      }
    ]
  },
  {
    "name": "Cooking Coal",
    "series": [
      {
        "name": "Bubanza",
        "value": 21.00
      },
      {
        "name": "Bujumbura",
        "value": 13.21
      },
      {
        "name": "Bururi",
        "value": 18.09
      },
      {
        "name": "Makamba",
        "value": 7.90
      },
      {
        "name": "Rumonge",
        "value": 28.00
      },
      {
        "name": "Muyinga",
        "value": 18.60
      },
      {
        "name": "Rutana",
        "value": 3.60
      }
    ]
  },
  {
    "name": "Others",
    "series": [
      {
        "name": "Bubanza",
        "value": 16.22
      },
      {
        "name": "Bujumbura",
        "value": 5.32
      },
      {
        "name": "Bururi",
        "value": 8.62
      },
      {
        "name": "Makamba",
        "value": 25.72
      },
      {
        "name": "Rumonge",
        "value": 31.09
      },
      {
        "name": "Muyinga",
        "value": 28.60
      },
      {
        "name": "Rutana",
        "value": 7.20
      }
    ]
  }
];

export let map_ImportGeographyData = [
  ['Country', 'Goods'],
  ['China', 1250.022204],
  ['United States Of America', 1055.728498],
  ['Japan', 536.3644769],
  ['Germany', 465.8544406],
  ['Indonesia', 399.6706906],
  ['United Arab Emirates', 339.5119002],
  ['Italy', 310.6547553],
  ['United Kingdom', 281.3262188],
  ['Turkey', 247.7536701],
  ['Korea(South)', 195.010763],
  ['France', 174.8872415],
  ['Netherlands', 174.789203],
  ['Iran', 170.4335344],
  ['Kuwait', 168.774638],
  ['Aruba', 142.6985748],
  ['Vietnam', 132.0736337],
  ['Thailand', 128.1914115],
  ['Switzerland', 104.7846702],
  ['Spain', 104.4403681],
  ['Australia', 84.61619038],
  ['Saudi Arabia', 77.79539221],
  ['Syria', 67.81928748],
  ['Brazil', 67.78127631],
  ['Canada', 65.39631547],
  ['Malaysia', 64.2934045],
  ['Croatia', 61.41104456],
  ['Jordan', 60.47080779],
  ['Bahrain', 55.57021416],
  ['Poland', 53.50309601],
  ['Austria', 53.2361766],
  ['Mexico', 48.65407965],
  ['Taiwan', 43.31802519],
  ['Lebanon', 42.58438784],
  ['Pakistan', 39.69516433],
  ['Belgium', 37.84332543],
  ['Ireland', 37.80070628],
  ['Argentina', 36.76541322],
  ['Egypt', 36.23457003],
  ['South Africa', 32.79878797],
  ['Slovakia', 30.25684808],
  ['Singapore', 29.15375935],
  ['Denmark', 28.61632596],
  ['Sultanate Of Oman', 28.58654218],
  ['Czeck  Republic', 27.1949586],
  ['Bangladesh', 23.25316131],
  ['Philippines', 23.22321394],
  ['Romania', 21.91639909],
  ['Sweden', 21.05828347],
  ['Russian Federation', 20.34861356],
  ['Portugal', 15.81139853],
  ['Hungary', 13.21599179],
  ['Sri Lanka(Ceylon)', 11.2413267],
  ['New Zealand', 9.693926531],
  ['Greece', 9.320226942],
  ['Qatar', 8.003983967],
  ['Equador', 7.821265942],
  ['Cambodia', 7.55432717],
  ['Chile', 7.545772996],
  ['Norway', 7.420251681],
  ['Iraq', 6.740154824],
  ['Ukraine', 5.698517663],
  ['Bulgaria', 5.532448432],
  ['Tunisia', 5.491031169],
  ['Finland', 5.286128779],
  ['Morocco', 5.070699791],
  ['Cyprus', 4.860997125],
  ['Paraguay', 3.663896019],
  ['Guatemala', 3.639471331],
  ['Puerto Rico', 3.440904641],
  ['Slovenia', 3.072222773],
  ['Myanmar(Burma)', 3.043348325],
  ['Hong Kong(Special Administrative Region Of China)', 2.945192646],
  ['Colombia', 2.430368761],
  ['Yemen', 2.292194712],
  ['Yugoslavia(Serbia & Montenegro)', 2.212557278],
  ['Belarus', 2.149531984],
  ['Lithuania', 1.925330478],
  ['Sudan', 1.812336672],
  ['Azerbaijan', 1.685231123],
  ['Bosnia And Herzegovina', 1.459509073],
  ['Latvia', 1.406353051],
  ['Costa Rica', 1.291292395],
  ['Georgia', 1.278234114],
  ['Kenya', 1.211501644],
  ['Madagascar', 1.174446814],
  ['Ethiopia', 1.164315564],
  ['Nigeria', 1.089956385],
  ['Peru', 1.02648627]
];

export let map_ImportGeographyData2020 = [
  ['Country', 'Goods'],
  ['China', 850.022204],
  ['United States Of America', 755.728498],
  ['Japan', 586.3644769],
  ['Germany', 565.8544406],
  ['Indonesia', 499.6706906],
  ['United Arab Emirates', 439.5119002],
  ['Italy', 317.6547553],
  ['United Kingdom', 291.3262188],
  ['Turkey', 237.7536701],
  ['Korea(South)', 185.010763],
  ['France', 164.8872415],
  ['Netherlands', 154.789203],
  ['Iran', 160.4335344],
  ['Kuwait', 158.774638],
  ['Aruba', 132.6985748],
  ['Vietnam', 112.0736337],
  ['Thailand', 118.1914115],
  ['Switzerland', 134.7846702],
  ['Spain', 124.4403681],
  ['Australia', 87.61619038],
  ['Saudi Arabia', 66.79539221],
  ['Syria', 67.81928748],
  ['Brazil', 67.78127631],
  ['Canada', 65.39631547],
  ['Malaysia', 64.2934045],
  ['Croatia', 61.41104456],
  ['Jordan', 60.47080779],
  ['Bahrain', 55.57021416],
  ['Poland', 53.50309601],
  ['Austria', 53.2361766],
  ['Mexico', 48.65407965],
  ['Taiwan', 43.31802519],
  ['Lebanon', 42.58438784],
  ['Pakistan', 39.69516433],
  ['Belgium', 37.84332543],
  ['Ireland', 37.80070628],
  ['Argentina', 36.76541322],
  ['Egypt', 36.23457003],
  ['South Africa', 32.79878797],
  ['Slovakia', 30.25684808],
  ['Singapore', 29.15375935],
  ['Denmark', 28.61632596],
  ['Sultanate Of Oman', 28.58654218],
  ['Czeck  Republic', 27.1949586],
  ['Bangladesh', 23.25316131],
  ['Philippines', 23.22321394],
  ['Romania', 21.91639909],
  ['Sweden', 21.05828347],
  ['Russian Federation', 20.34861356],
  ['Portugal', 15.81139853],
  ['Hungary', 13.21599179],
  ['Sri Lanka(Ceylon)', 11.2413267],
  ['New Zealand', 9.693926531],
  ['Greece', 9.320226942],
  ['Qatar', 8.003983967],
  ['Equador', 7.821265942],
  ['Cambodia', 7.55432717],
  ['Chile', 7.545772996],
  ['Norway', 7.420251681],
  ['Iraq', 6.740154824],
  ['Ukraine', 5.698517663],
  ['Bulgaria', 5.532448432],
  ['Tunisia', 5.491031169],
  ['Finland', 5.286128779],
  ['Morocco', 5.070699791],
  ['Cyprus', 4.860997125],
  ['Paraguay', 3.663896019],
  ['Guatemala', 3.639471331],
  ['Puerto Rico', 3.440904641],
  ['Slovenia', 3.072222773],
  ['Myanmar(Burma)', 3.043348325],
  ['Hong Kong(Special Administrative Region Of China)', 2.945192646],
  ['Colombia', 2.430368761],
  ['Yemen', 2.292194712],
  ['Yugoslavia(Serbia & Montenegro)', 2.212557278],
  ['Belarus', 2.149531984],
  ['Lithuania', 1.925330478],
  ['Sudan', 1.812336672],
  ['Azerbaijan', 1.685231123],
  ['Bosnia And Herzegovina', 1.459509073],
  ['Latvia', 1.406353051],
  ['Costa Rica', 1.291292395],
  ['Georgia', 1.278234114],
  ['Kenya', 11.211501644],
  ['Madagascar', 2.174446814],
  ['Ethiopia', 2.164315564],
  ['Nigeria', 2.089956385],
  ['Peru', 1.52648627]
];

export let map_ExportGeographyData = [
  ['Country', 'Goods'],  
  ['China', 250.022204],
  ['United States Of America', 655.728498],
  ['Japan', 336.3644769],
  ['Germany', 565.8544406],
  ['Indonesia', 99.6706906],
  ['United Arab Emirates', 300.5119002],
  ['Italy', 110.6547553],
  ['United Kingdom', 281.3262188],
  ['Turkey', 247.7536701],
  ['Korea(South)', 195.010763],
  ['France', 174.8872415],
  ['Netherlands', 174.789203],
  ['Iran', 170.4335344],
  ['Kuwait', 168.774638],
  ['Aruba', 142.6985748],
  ['Vietnam', 132.0736337],
  ['Thailand', 128.1914115],
  ['Switzerland', 104.7846702],
  ['Spain', 104.4403681],
  ['Australia', 84.61619038],
  ['Saudi Arabia', 77.79539221],
  ['Syria', 67.81928748],
  ['Brazil', 67.78127631],
  ['Canada', 65.39631547],
  ['Malaysia', 64.2934045],
  ['Croatia', 61.41104456],
  ['Jordan', 60.47080779],
  ['Bahrain', 55.57021416],
  ['Poland', 53.50309601],
  ['Austria', 53.2361766],
  ['Mexico', 48.65407965],
  ['Taiwan', 43.31802519],
  ['Lebanon', 42.58438784],
  ['Pakistan', 39.69516433],
  ['Belgium', 37.84332543],
  ['Ireland', 37.80070628],
  ['Argentina', 36.76541322],
  ['Egypt', 36.23457003],
  ['South Africa', 32.79878797],
  ['Slovakia', 30.25684808],
  ['Singapore', 29.15375935],
  ['Denmark', 28.61632596],
  ['Sultanate Of Oman', 28.58654218],
  ['Czeck  Republic', 27.1949586],
  ['Bangladesh', 23.25316131],
  ['Philippines', 23.22321394],
  ['Romania', 21.91639909],
  ['Sweden', 21.05828347],
  ['Russian Federation', 20.34861356],
  ['Portugal', 15.81139853],
  ['Hungary', 13.21599179],
  ['Sri Lanka(Ceylon)', 11.2413267],
  ['New Zealand', 9.693926531],
  ['Greece', 9.320226942],
  ['Qatar', 8.003983967],
  ['Equador', 7.821265942],
  ['Cambodia', 7.55432717],
  ['Chile', 7.545772996],
  ['Norway', 7.420251681],
  ['Iraq', 6.740154824],
  ['Ukraine', 5.698517663],
  ['Bulgaria', 5.532448432],
  ['Tunisia', 5.491031169],
  ['Finland', 5.286128779],
  ['Morocco', 5.070699791],
  ['Cyprus', 4.860997125],
  ['Paraguay', 3.663896019],
  ['Guatemala', 3.639471331],
  ['Puerto Rico', 3.440904641],
  ['Slovenia', 3.072222773],
  ['Myanmar(Burma)', 3.043348325],
  ['Hong Kong(Special Administrative Region Of China)', 2.945192646],
  ['Colombia', 12.430368761],
  ['Yemen', 21.292194712],
  ['Yugoslavia(Serbia & Montenegro)', 21.212557278],
  ['Belarus', 21.149531984],
  ['Lithuania', 11.925330478],
  ['Sudan', 10.812336672],
  ['Azerbaijan', 11.685231123],
  ['Bosnia And Herzegovina', 10.459509073],
  ['Latvia', 21.406353051],
  ['Costa Rica', 10.291292395],
  ['Georgia', 11.278234114],
  ['Kenya', 111.211501644],
  ['Madagascar', 31.174446814],
  ['Ethiopia', 21.164315564],
  ['Nigeria', 11.089956385],
  ['Peru', 1.02648627]
];

export let map_ExportGeographyData2020 = [
  ['Country', 'Goods'],  
  ['China', 150.022204],
  ['United States Of America', 555.728498],
  ['Japan', 236.3644769],
  ['Germany', 465.8544406],
  ['Indonesia', 199.6706906],
  ['United Arab Emirates', 320.5119002],
  ['Italy', 210.6547553],
  ['United Kingdom', 381.3262188],
  ['Turkey', 147.7536701],
  ['Korea(South)', 95.010763],
  ['France', 144.8872415],
  ['Netherlands', 74.789203],
  ['Iran', 270.4335344],
  ['Kuwait', 168.774638],
  ['Aruba', 142.6985748],
  ['Vietnam', 132.0736337],
  ['Thailand', 128.1914115],
  ['Switzerland', 104.7846702],
  ['Spain', 104.4403681],
  ['Australia', 84.61619038],
  ['Saudi Arabia', 77.79539221],
  ['Syria', 67.81928748],
  ['Brazil', 67.78127631],
  ['Canada', 65.39631547],
  ['Malaysia', 64.2934045],
  ['Croatia', 61.41104456],
  ['Jordan', 60.47080779],
  ['Bahrain', 55.57021416],
  ['Poland', 53.50309601],
  ['Austria', 53.2361766],
  ['Mexico', 48.65407965],
  ['Taiwan', 43.31802519],
  ['Lebanon', 42.58438784],
  ['Pakistan', 39.69516433],
  ['Belgium', 37.84332543],
  ['Ireland', 37.80070628],
  ['Argentina', 36.76541322],
  ['Egypt', 36.23457003],
  ['South Africa', 32.79878797],
  ['Slovakia', 30.25684808],
  ['Singapore', 29.15375935],
  ['Denmark', 28.61632596],
  ['Sultanate Of Oman', 28.58654218],
  ['Czeck  Republic', 27.1949586],
  ['Bangladesh', 23.25316131],
  ['Philippines', 23.22321394],
  ['Romania', 21.91639909],
  ['Sweden', 21.05828347],
  ['Russian Federation', 20.34861356],
  ['Portugal', 15.81139853],
  ['Hungary', 13.21599179],
  ['Sri Lanka(Ceylon)', 11.2413267],
  ['New Zealand', 9.693926531],
  ['Greece', 9.320226942],
  ['Qatar', 8.003983967],
  ['Equador', 7.821265942],
  ['Cambodia', 7.55432717],
  ['Chile', 7.545772996],
  ['Norway', 7.420251681],
  ['Iraq', 6.740154824],
  ['Ukraine', 5.698517663],
  ['Bulgaria', 5.532448432],
  ['Tunisia', 5.491031169],
  ['Finland', 5.286128779],
  ['Morocco', 5.070699791],
  ['Cyprus', 4.860997125],
  ['Paraguay', 3.663896019],
  ['Guatemala', 3.639471331],
  ['Puerto Rico', 3.440904641],
  ['Slovenia', 3.072222773],
  ['Myanmar(Burma)', 3.043348325],
  ['Hong Kong(Special Administrative Region Of China)', 2.945192646],
  ['Colombia', 12.430368761],
  ['Yemen', 21.292194712],
  ['Yugoslavia(Serbia & Montenegro)', 21.212557278],
  ['Belarus', 21.149531984],
  ['Lithuania', 11.925330478],
  ['Sudan', 10.812336672],
  ['Azerbaijan', 11.685231123],
  ['Bosnia And Herzegovina', 10.459509073],
  ['Latvia', 21.406353051],
  ['Costa Rica', 10.291292395],
  ['Georgia', 11.278234114],
  ['Kenya', 111.211501644],
  ['Madagascar', 31.174446814],
  ['Ethiopia', 21.164315564],
  ['Nigeria', 11.089956385],
  ['Peru', 1.02648627]
];

export var exportValue = [
  {
    "name": "Jul-20",
    "value": 30.90
  },
  {
    "name": "Aug-20",
    "value": 19.00
  },
  {
    "name": "Sep-20",
    "value": 27.20
  },
  {
    "name": "Oct-20",
    "value": 18.50
  },
  {
    "name": "Nov-20",
    "value": 8.50
  },
  {
    "name": "Dec-20",
    "value": 31.00
  },
  {
    "name": "Jan-21",
    "value": 11.00
  },
  {
    "name": "Feb-21",
    "value": 27.80
  },
  {
    "name": "Mar-21",
    "value": 11.50
  },
  {
    "name": "Apr-21",
    "value": 16.50
  },
  {
    "name": "May-21",
    "value": 12.20
  },
  {
    "name": "Jun-21",
    "value": 5.10
  }
];

export var exportValue2020 = [
  {
    "name": "Jul-19",
    "value": 40.90
  },
  {
    "name": "Aug-19",
    "value": 13.00
  },
  {
    "name": "Sep-19",
    "value": 17.20
  },
  {
    "name": "Oct-19",
    "value": 28.50
  },
  {
    "name": "Nov-19",
    "value": 18.50
  },
  {
    "name": "Dec-19",
    "value": 41.00
  },
  {
    "name": "Jan-20",
    "value": 21.00
  },
  {
    "name": "Feb-20",
    "value": 17.80
  },
  {
    "name": "Mar-20",
    "value": 21.50
  },
  {
    "name": "Apr-20",
    "value": 6.50
  },
  {
    "name": "May-20",
    "value": 22.20
  },
  {
    "name": "Jun-20",
    "value": 15.10
  }
];

export var topExportCommodities = [
  {
    "name": "Pharma..",
    "value": 5.400
  },
  {
    "name": "Machinery..",
    "value": 2.60
  },
  {
    "name": "Cereals",
    "value": 1.57
  },
  {
    "name": "Vehicles",
    "value": 2.55
  },
  {
    "name": "Iron & Steel",
    "value": 0.98
  }
];

export var topExportIndustrialZones = [
  {
    "name": "Bubanza",
    "value": 3.65
  },
  {
    "name": "Bujumbura",
    "value": 1.70
  },
  {
    "name": "Bururi",
    "value": 2.80
  },
  {
    "name": "Makamba",
    "value": 1.99
  },
  {
    "name": "Rumonge",
    "value": 2.39
  }
];

export var topExportIndustrialZones2020 = [
  {
    "name": "Bubanza",
    "value": 3.89
  },
  {
    "name": "Bujumbura",
    "value": 1.90
  },
  {
    "name": "Bururi",
    "value": 1.80
  },
  {
    "name": "Makamba",
    "value": 1.69
  },
  {
    "name": "Rumonge",
    "value": 1.39
  }
];

export var exportCommodityZones = [
  {
    "name": "Petroleum Oils",
    "series": [
      {
        "name": "Bubanza",
        "value": 32.00
      },
      {
        "name": "Bujumbura",
        "value": 21.30
      },
      {
        "name": "Bururi",
        "value": 28.00
      },
      {
        "name": "Makamba",
        "value": 19.23
      },
      {
        "name": "Rumonge",
        "value": 38.00
      },
      {
        "name": "Muyinga",
        "value": 6.60
      },
      {
        "name": "Rutana",
        "value": 17.60
      }
    ]
  },

  {
    "name": "Iron",
    "series": [
      {
        "name": "Bubanza",
        "value": 22.00
      },
      {
        "name": "Bujumbura",
        "value": 11.00
      },
      {
        "name": "Bururi",
        "value": 13.00
      },
      {
        "name": "Makamba",
        "value": 20.00
      },
      {
        "name": "Rumonge",
        "value": 30.01
      },
      {
        "name": "Muyinga",
        "value": 18.30
      },
      {
        "name": "Rutana",
        "value": 4.60
      }
    ]
  },

  {
    "name": "Vehicles",
    "series": [
      {
        "name": "Bubanza",
        "value": 10.05
      },
      {
        "name": "Bujumbura",
        "value": 19.00
      },
      {
        "name": "Bururi",
        "value": 18.75
      },
      {
        "name": "Makamba",
        "value": 7.65
      },
      {
        "name": "Rumonge",
        "value": 25.00
      },
      {
        "name": "Muyinga",
        "value": 5.60
      },
      {
        "name": "Rutana",
        "value": 17.60
      }
    ]
  },
  {
    "name": "Cereals",
    "series": [
      {
        "name": "Bubanza",
        "value": 12.00
      },
      {
        "name": "Bujumbura",
        "value": 20.21
      },
      {
        "name": "Bururi",
        "value": 18.09
      },
      {
        "name": "Makamba",
        "value": 17.90
      },
      {
        "name": "Rumonge",
        "value": 28.00
      },
      {
        "name": "Muyinga",
        "value": 7.60
      },
      {
        "name": "Rutana",
        "value": 9.40
      }
    ]
  },
  {
    "name": "Non-Metals",
    "series": [
      {
        "name": "Bubanza",
        "value": 6.22
      },
      {
        "name": "Bujumbura",
        "value": 10.32
      },
      {
        "name": "Bururi",
        "value": 5.62
      },
      {
        "name": "Makamba",
        "value": 15.72
      },
      {
        "name": "Rumonge",
        "value": 21.09
      },
      {
        "name": "Muyinga",
        "value": 12.60
      },
      {
        "name": "Rutana",
        "value": 13.60
      }
    ]
  },
  {
    "name": "Machinery, Mechanical applicances",
    "series": [
      {
        "name": "Bubanza",
        "value": 12.00
      },
      {
        "name": "Bujumbura",
        "value": 14.30
      },
      {
        "name": "Bururi",
        "value": 8.00
      },
      {
        "name": "Makamba",
        "value": 29.23
      },
      {
        "name": "Rumonge",
        "value": 18.00
      },
      {
        "name": "Muyinga",
        "value": 4.60
      },
      {
        "name": "Rutana",
        "value": 3.60
      }
    ]
  },
  {
    "name": "Steel",
    "series": [
      {
        "name": "Bubanza",
        "value": 20.00
      },
      {
        "name": "Bujumbura",
        "value": 13.00
      },
      {
        "name": "Bururi",
        "value": 23.00
      },
      {
        "name": "Makamba",
        "value": 24.00
      },
      {
        "name": "Rumonge",
        "value": 20.01
      },
      {
        "name": "Muyinga",
        "value": 12.60
      },
      {
        "name": "Rutana",
        "value": 17.60
      }
    ]
  },
  {
    "name": "Steam Coal",
    "series": [
      {
        "name": "Bubanza",
        "value": 14.05
      },
      {
        "name": "Bujumbura",
        "value": 29.00
      },
      {
        "name": "Bururi",
        "value": 18.75
      },
      {
        "name": "Makamba",
        "value": 17.65
      },
      {
        "name": "Rumonge",
        "value": 35.00
      },
      {
        "name": "Muyinga",
        "value": 6.60
      },
      {
        "name": "Rutana",
        "value": 17.60
      }
    ]
  },
  {
    "name": "Cooking Coal",
    "series": [
      {
        "name": "Bubanza",
        "value": 22.00
      },
      {
        "name": "Bujumbura",
        "value": 10.21
      },
      {
        "name": "Bururi",
        "value": 8.09
      },
      {
        "name": "Makamba",
        "value": 17.90
      },
      {
        "name": "Rumonge",
        "value": 18.00
      },
      {
        "name": "Muyinga",
        "value": 2.60
      },
      {
        "name": "Rutana",
        "value": 1.60
      }
    ]
  },
  {
    "name": "Others",
    "series": [
      {
        "name": "Bubanza",
        "value": 6.22
      },
      {
        "name": "Bujumbura",
        "value": 10.32
      },
      {
        "name": "Bururi",
        "value": 5.62
      },
      {
        "name": "Makamba",
        "value": 15.72
      },
      {
        "name": "Rumonge",
        "value": 21.09
      },
      {
        "name": "Muyinga",
        "value": 2.20
      },
      {
        "name": "Rutana",
        "value": 11.60
      }
    ]
  }
];

export var inventoryWarehouseCount = [
  {
    "name": "Warehouses",
    "value": 3
  },
  {
    "name": "Warehouse Items",
    "value": 245
  }
];

export var inventoryWarehouseValueCount = [
  {
    "name": "Million(s) USD",
    "value": 30
  }
];

export var formTypeData = [
  {
    "name": "Inbound",
    "value": 32
  },
  {
    "name": "Outbound",
    "value": 75
  }
];

export var formTypeDataWH1 = [
  {
    "name": "Inbound",
    "value": 9
  },
  {
    "name": "Outbound",
    "value": 35
  }
];

export var formTypeDataWH2 = [
  {
    "name": "Inbound",
    "value": 11
  },
  {
    "name": "Outbound",
    "value": 15
  }
];

export var formTypeDataWH3 = [
  {
    "name": "Inbound",
    "value": 12
  },
  {
    "name": "Outbound",
    "value": 25
  }
];

export var goodsCategoryData = [
  {
    "name": "Raw materials",
    "value": 20
  },
  {
    "name": "Finished Goods",
    "value": 100
  },
  {
    "name": "Machinery",
    "value": 75
  },
  {
    "name": "Same State Goods",
    "value": 10
  }
];

export var goodsCategoryDataWH1 = [
  {
    "name": "Raw materials",
    "value": 10
  },
  {
    "name": "Finished Goods",
    "value": 40
  },
  {
    "name": "Machinery",
    "value": 30
  },
  {
    "name": "Same State Goods",
    "value": 4
  }
];

export var goodsCategoryDataWH2 = [
  {
    "name": "Raw materials",
    "value": 5
  },
  {
    "name": "Finished Goods",
    "value": 20
  },
  {
    "name": "Machinery",
    "value": 25
  },
  {
    "name": "Same State Goods",
    "value": 4
  }
];

export var goodsCategoryDataWH3 = [
  {
    "name": "Raw materials",
    "value": 5
  },
  {
    "name": "Finished Goods",
    "value": 40
  },
  {
    "name": "Machinery",
    "value": 20
  },
  {
    "name": "Same State Goods",
    "value": 2
  }
];

export var localMarketData = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 40
      },
      {
        "name": "Jun-8",
        "value": 30
      },
      {
        "name": "Jun-9",
        "value": 50
      },
      {
        "name": "Jun-10",
        "value": 60
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 30
      },
      {
        "name": "Jun-8",
        "value": 45
      },
      {
        "name": "Jun-9",
        "value": 55
      },
      {
        "name": "Jun-10",
        "value": 16
      }
    ]
  }
];

export var localMarketDataWH1 = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 20
      },
      {
        "name": "Jun-8",
        "value": 10
      },
      {
        "name": "Jun-9",
        "value": 26
      },
      {
        "name": "Jun-10",
        "value": 35
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 23
      },
      {
        "name": "Jun-9",
        "value": 22
      },
      {
        "name": "Jun-10",
        "value": 7
      }
    ]
  }
];

export var localMarketDataWH2 = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 15
      },
      {
        "name": "Jun-9",
        "value": 14
      },
      {
        "name": "Jun-10",
        "value": 15
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 8
      },
      {
        "name": "Jun-8",
        "value": 10
      },
      {
        "name": "Jun-9",
        "value": 23
      },
      {
        "name": "Jun-10",
        "value": 5
      }
    ]
  }
];

export var localMarketDataWH3 = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 10
      },
      {
        "name": "Jun-8",
        "value": 11
      },
      {
        "name": "Jun-9",
        "value": 8
      },
      {
        "name": "Jun-10",
        "value": 10
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 10
      },
      {
        "name": "Jun-8",
        "value": 8
      },
      {
        "name": "Jun-9",
        "value": 10
      },
      {
        "name": "Jun-10",
        "value": 5
      }
    ]
  }
];

export var otherLocationsData = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 10
      },
      {
        "name": "Jun-8",
        "value": 22
      },
      {
        "name": "Jun-9",
        "value": 43
      },
      {
        "name": "Jun-10",
        "value": 26
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 27
      },
      {
        "name": "Jun-8",
        "value": 43
      },
      {
        "name": "Jun-9",
        "value": 23
      },
      {
        "name": "Jun-10",
        "value": 17
      }
    ]
  }
];

export var otherLocationsDataWH1 = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 4
      },
      {
        "name": "Jun-8",
        "value": 6
      },
      {
        "name": "Jun-9",
        "value": 10
      },
      {
        "name": "Jun-10",
        "value": 11
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 11
      },
      {
        "name": "Jun-9",
        "value": 7
      },
      {
        "name": "Jun-10",
        "value": 6
      }
    ]
  }
];

export var otherLocationsDataWH2 = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 2
      },
      {
        "name": "Jun-8",
        "value": 6
      },
      {
        "name": "Jun-9",
        "value": 12
      },
      {
        "name": "Jun-10",
        "value": 10
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 10
      },
      {
        "name": "Jun-9",
        "value": 11
      },
      {
        "name": "Jun-10",
        "value": 5
      }
    ]
  }
];

export var otherLocationsDataWH3 = [
  {
    "name": "Inbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 4
      },
      {
        "name": "Jun-8",
        "value": 10
      },
      {
        "name": "Jun-9",
        "value": 21
      },
      {
        "name": "Jun-10",
        "value": 11
      }
    ]
  },
  {
    "name": "Outbound",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 18
      },
      {
        "name": "Jun-9",
        "value": 11
      },
      {
        "name": "Jun-10",
        "value": 8
      }
    ]
  }
];

export var disposedItemValueData = [
  {
    "name": "Disposed Items",
    "series": [
      {
        "name": "Jun-7",
        "value": 13
      },
      {
        "name": "Jun-8",
        "value": 26
      },
      {
        "name": "Jun-9",
        "value": 11
      },
      {
        "name": "Jun-10",
        "value": 43
      },
      {
        "name": "Jun-11",
        "value": 55
      },
      {
        "name": "Jun-12",
        "value": 60
      }
    ]
  }
];

export var discrepancyData = [
  {
    "name": "Wrong Items",
    "series": [
      {
        "name": "Jun-7",
        "value": 2
      },
      {
        "name": "Jun-8",
        "value": 1
      },
      {
        "name": "Jun-9",
        "value": 4
      },
      {
        "name": "Jun-10",
        "value": 3
      },
      {
        "name": "Jun-11",
        "value": 1
      },
      {
        "name": "Jun-12",
        "value": 4
      },
      {
        "name": "Jun-13",
        "value": 3
      }
    ]
  },

  {
    "name": "Over Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 9
      },
      {
        "name": "Jun-8",
        "value": 12
      },
      {
        "name": "Jun-9",
        "value": 6
      },
      {
        "name": "Jun-10",
        "value": 10
      },
      {
        "name": "Jun-11",
        "value": 14
      },
      {
        "name": "Jun-12",
        "value": 8
      },
      {
        "name": "Jun-13",
        "value": 4
      }
    ]
  },

  {
    "name": "Goods Not Arrived",
    "series": [
      {
        "name": "Jun-7",
        "value": 22
      },
      {
        "name": "Jun-8",
        "value": 31
      },
      {
        "name": "Jun-9",
        "value": 44
      },
      {
        "name": "Jun-10",
        "value": 43
      },
      {
        "name": "Jun-11",
        "value": 51
      },
      {
        "name": "Jun-12",
        "value": 24
      },
      {
        "name": "Jun-13",
        "value": 13
      }
    ]
  },
  {
    "name": "Short Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 10
      },
      {
        "name": "Jun-9",
        "value": 14
      },
      {
        "name": "Jun-10",
        "value": 3
      },
      {
        "name": "Jun-11",
        "value": 11
      },
      {
        "name": "Jun-12",
        "value": 14
      },
      {
        "name": "Jun-13",
        "value": 3
      }
    ]
  }
];

export var discrepancyDataWH1 = [
  {
    "name": "Wrong Items",
    "series": [
      {
        "name": "Jun-7",
        "value": 1
      },
      {
        "name": "Jun-8",
        "value": 0
      },
      {
        "name": "Jun-9",
        "value": 2
      },
      {
        "name": "Jun-10",
        "value": 1
      },
      {
        "name": "Jun-11",
        "value": 1
      },
      {
        "name": "Jun-12",
        "value": 1
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  },

  {
    "name": "Over Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 6
      },
      {
        "name": "Jun-8",
        "value": 5
      },
      {
        "name": "Jun-9",
        "value": 3
      },
      {
        "name": "Jun-10",
        "value": 6
      },
      {
        "name": "Jun-11",
        "value": 8
      },
      {
        "name": "Jun-12",
        "value": 2
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  },

  {
    "name": "Goods Not Arrived",
    "series": [
      {
        "name": "Jun-7",
        "value": 12
      },
      {
        "name": "Jun-8",
        "value": 14
      },
      {
        "name": "Jun-9",
        "value": 21
      },
      {
        "name": "Jun-10",
        "value": 21
      },
      {
        "name": "Jun-11",
        "value": 31
      },
      {
        "name": "Jun-12",
        "value": 14
      },
      {
        "name": "Jun-13",
        "value": 9
      }
    ]
  },
  {
    "name": "Short Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 8
      },
      {
        "name": "Jun-8",
        "value": 4
      },
      {
        "name": "Jun-9",
        "value": 7
      },
      {
        "name": "Jun-10",
        "value": 1
      },
      {
        "name": "Jun-11",
        "value": 6
      },
      {
        "name": "Jun-12",
        "value": 7
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  }
];

export var discrepancyDataWH2 = [
  {
    "name": "Wrong Items",
    "series": [
      {
        "name": "Jun-7",
        "value": 0
      },
      {
        "name": "Jun-8",
        "value": 0
      },
      {
        "name": "Jun-9",
        "value": 1
      },
      {
        "name": "Jun-10",
        "value": 1
      },
      {
        "name": "Jun-11",
        "value": 0
      },
      {
        "name": "Jun-12",
        "value": 1
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  },

  {
    "name": "Over Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 3
      },
      {
        "name": "Jun-8",
        "value": 5
      },
      {
        "name": "Jun-9",
        "value": 3
      },
      {
        "name": "Jun-10",
        "value": 3
      },
      {
        "name": "Jun-11",
        "value": 5
      },
      {
        "name": "Jun-12",
        "value": 2
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  },

  {
    "name": "Goods Not Arrived",
    "series": [
      {
        "name": "Jun-7",
        "value": 5
      },
      {
        "name": "Jun-8",
        "value": 5
      },
      {
        "name": "Jun-9",
        "value": 10
      },
      {
        "name": "Jun-10",
        "value": 11
      },
      {
        "name": "Jun-11",
        "value": 18
      },
      {
        "name": "Jun-12",
        "value": 7
      },
      {
        "name": "Jun-13",
        "value": 6
      }
    ]
  },
  {
    "name": "Short Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 5
      },
      {
        "name": "Jun-8",
        "value": 3
      },
      {
        "name": "Jun-9",
        "value": 5
      },
      {
        "name": "Jun-10",
        "value": 1
      },
      {
        "name": "Jun-11",
        "value": 5
      },
      {
        "name": "Jun-12",
        "value": 7
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  }
];

export var discrepancyDataWH3 = [
  {
    "name": "Wrong Items",
    "series": [
      {
        "name": "Jun-7",
        "value": 0
      },
      {
        "name": "Jun-8",
        "value": 0
      },
      {
        "name": "Jun-9",
        "value": 2
      },
      {
        "name": "Jun-10",
        "value": 1
      },
      {
        "name": "Jun-11",
        "value": 0
      },
      {
        "name": "Jun-12",
        "value": 2
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  },

  {
    "name": "Over Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 3
      },
      {
        "name": "Jun-8",
        "value": 5
      },
      {
        "name": "Jun-9",
        "value": 2
      },
      {
        "name": "Jun-10",
        "value": 4
      },
      {
        "name": "Jun-11",
        "value": 5
      },
      {
        "name": "Jun-12",
        "value": 3
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  },

  {
    "name": "Goods Not Arrived",
    "series": [
      {
        "name": "Jun-7",
        "value": 6
      },
      {
        "name": "Jun-8",
        "value": 4
      },
      {
        "name": "Jun-9",
        "value": 11
      },
      {
        "name": "Jun-10",
        "value": 20
      },
      {
        "name": "Jun-11",
        "value": 21
      },
      {
        "name": "Jun-12",
        "value": 11
      },
      {
        "name": "Jun-13",
        "value": 7
      }
    ]
  },
  {
    "name": "Short Shipment",
    "series": [
      {
        "name": "Jun-7",
        "value": 5
      },
      {
        "name": "Jun-8",
        "value": 4
      },
      {
        "name": "Jun-9",
        "value": 6
      },
      {
        "name": "Jun-10",
        "value": 1
      },
      {
        "name": "Jun-11",
        "value": 6
      },
      {
        "name": "Jun-12",
        "value": 8
      },
      {
        "name": "Jun-13",
        "value": 1
      }
    ]
  }
];

export var transferredGoodsData = [
  {
    "name": "Darkfin Storages Co.",
    "series": [
      {
        "name": "Jun-7",
        "value": 21
      },
      {
        "name": "Jun-8",
        "value": 32
      },
      {
        "name": "Jun-9",
        "value": 12
      },
      {
        "name": "Jun-10",
        "value": 10
      },
      {
        "name": "Jun-11",
        "value": 22
      },
      {
        "name": "Jun-12",
        "value": 42
      }
    ]
  },
  {
    "name": "Global Clearance Warehouse",
    "series": [
      {
        "name": "Jun-7",
        "value": 42
      },
      {
        "name": "Jun-8",
        "value": 25
      },
      {
        "name": "Jun-9",
        "value": 55
      },
      {
        "name": "Jun-10",
        "value": 20
      },
      {
        "name": "Jun-11",
        "value": 12
      },
      {
        "name": "Jun-12",
        "value": 30
      }
    ]
  },
  {
    "name": "UPS Warehouse Ltd.",
    "series": [
      {
        "name": "Jun-7",
        "value": 22
      },
      {
        "name": "Jun-8",
        "value": 24
      },
      {
        "name": "Jun-9",
        "value": 43
      },
      {
        "name": "Jun-10",
        "value": 26
      },
      {
        "name": "Jun-11",
        "value": 32
      },
      {
        "name": "Jun-12",
        "value": 11
      }
    ]
  }
];

export var vehiclesValueData = [
  {
    "name": "Vehicle Count",
    "series": [
      {
        "name": "Jun-7",
        "value": 22
      },
      {
        "name": "Jun-8",
        "value": 34
      },
      {
        "name": "Jun-9",
        "value": 12
      },
      {
        "name": "Jun-10",
        "value": 44
      },
      {
        "name": "Jun-11",
        "value": 24
      },
      {
        "name": "Jun-12",
        "value": 56
      }
    ]
  }
];

export var inventoryValueData = [
  {
    "name": "Inventory Value",
    "series": [
      {
        "name": "Jun-7",
        "value": 12.3
      },
      {
        "name": "Jun-8",
        "value": 32.1
      },
      {
        "name": "Jun-9",
        "value": 24
      },
      {
        "name": "Jun-10",
        "value": 44.5
      },
      {
        "name": "Jun-11",
        "value": 56.7
      },
      {
        "name": "Jun-12",
        "value": 34.6
      }
    ]
  }
];

export var disposedGoods = [
  {
    "name": "Accident",
    "value": 5
  },
  {
    "name": "Damaged",
    "value": 40
  },
  {
    "name": "Leaked",
    "value": 5
  },
    {
    "name": "Abandoned",
    "value": 20
  },
  {
  "name": "Deteroirated",
  "value": 30
  }
];

export var disposedGoodsPie = [
  ['Disposed Goods ', 'Count(%)'],
  ['Accident', 13],
  ['Damaged', 28],
  ['Leaked', 14],
  ['Abandoned', 20],
  ['Deteroirated', 25]
];

export var disposedGoodsPieWH1 = [
  ['Disposed Goods ', 'Count(%)'],
  ['Accident', 10],
  ['Damaged', 22],
  ['Leaked', 11],
  ['Abandoned', 19],
  ['Deteroirated', 38]
];

export var disposedGoodsPieWH2 = [
  ['Disposed Goods ', 'Count(%)'],
  ['Accident', 14],
  ['Damaged', 24],
  ['Leaked', 12],
  ['Abandoned', 25],
  ['Deteroirated', 25]
];

export var disposedGoodsPieWH3 = [
  ['Disposed Goods ', 'Count(%)'],
  ['Accident', 21],
  ['Damaged', 29],
  ['Leaked', 15],
  ['Abandoned', 20],
  ['Deteroirated', 25]
];

export var commodityType = [
  {
    "name": "Jun-7",
    "series": [
      {
        "name": "Goods",
        "value": 110
      },
      {
        "name": "Vehicle",
        "value": 90
      }
    ]
  },
  {
    "name": "Jun-8",
    "series": [
      {
        "name": "Goods",
        "value": 80
      },
      {
        "name": "Vehicle",
        "value": 100
      }
    ]
  },
  {
    "name": "Jun-9",
    "series": [
      {
        "name": "Goods",
        "value": 89
      },
      {
        "name": "Vehicle",
        "value": 77
      }
    ]
  },
  {
    "name": "Jun-10",
    "series": [
      {
        "name": "Goods",
        "value": 77
      },
      {
        "name": "Vehicle",
        "value": 90
      }
    ]
  },
  {
    "name": "Jun-11",
    "series": [
      {
        "name": "Goods",
        "value": 110
      },
      {
        "name": "Vehicle",
        "value": 100
      }
    ]
  }
];

export var commodityTypeWH1 = [
  {
    "name": "Jun-7",
    "series": [
      {
        "name": "Goods",
        "value": 40
      },
      {
        "name": "Vehicle",
        "value": 30
      }
    ]
  },
  {
    "name": "Jun-8",
    "series": [
      {
        "name": "Goods",
        "value": 30
      },
      {
        "name": "Vehicle",
        "value": 40
      }
    ]
  },
  {
    "name": "Jun-9",
    "series": [
      {
        "name": "Goods",
        "value": 30
      },
      {
        "name": "Vehicle",
        "value": 37
      }
    ]
  },
  {
    "name": "Jun-10",
    "series": [
      {
        "name": "Goods",
        "value": 30
      },
      {
        "name": "Vehicle",
        "value": 30
      }
    ]
  },
  {
    "name": "Jun-11",
    "series": [
      {
        "name": "Goods",
        "value": 40
      },
      {
        "name": "Vehicle",
        "value": 60
      }
    ]
  }
];

export var commodityTypeWH2 = [
  {
    "name": "Jun-7",
    "series": [
      {
        "name": "Goods",
        "value": 35
      },
      {
        "name": "Vehicle",
        "value": 24
      }
    ]
  },
  {
    "name": "Jun-8",
    "series": [
      {
        "name": "Goods",
        "value": 15
      },
      {
        "name": "Vehicle",
        "value": 25
      }
    ]
  },
  {
    "name": "Jun-9",
    "series": [
      {
        "name": "Goods",
        "value": 16
      },
      {
        "name": "Vehicle",
        "value": 20
      }
    ]
  },
  {
    "name": "Jun-10",
    "series": [
      {
        "name": "Goods",
        "value": 15
      },
      {
        "name": "Vehicle",
        "value": 16
      }
    ]
  },
  {
    "name": "Jun-11",
    "series": [
      {
        "name": "Goods",
        "value": 20
      },
      {
        "name": "Vehicle",
        "value": 20
      }
    ]
  }
];

export var commodityTypeWH3 = [
  {
    "name": "Jun-7",
    "series": [
      {
        "name": "Goods",
        "value": 16
      },
      {
        "name": "Vehicle",
        "value": 20
      }
    ]
  },
  {
    "name": "Jun-8",
    "series": [
      {
        "name": "Goods",
        "value": 16
      },
      {
        "name": "Vehicle",
        "value": 18
      }
    ]
  },
  {
    "name": "Jun-9",
    "series": [
      {
        "name": "Goods",
        "value": 18
      },
      {
        "name": "Vehicle",
        "value": 17
      }
    ]
  },
  {
    "name": "Jun-10",
    "series": [
      {
        "name": "Goods",
        "value": 17
      },
      {
        "name": "Vehicle",
        "value": 19
      }
    ]
  },
  {
    "name": "Jun-11",
    "series": [
      {
        "name": "Goods",
        "value": 20
      },
      {
        "name": "Vehicle",
        "value": 21
      }
    ]
  }
];

export var hsCodeData = [
  {
    "name": "Machinery",
    "value": 40,
    "extra": {
      "code": "me"
    }
  },
  {
    "name": "Steel",
    "value": 43,
    "extra": {
      "code": "st"
    }
  },
  {
    "name": "Iron",
    "value": 36,
    "extra": {
      "code": "fe"
    }
  },
  {
    "name": "Cooking Coal",
    "value": 24,
    "extra": {
      "code": "cc"
    }
  },
  {
    "name": "Steam Coal",
    "value": 33,
    "extra": {
      "code": "sc"
    }
  },
  {
    "name": "Fruits",
    "value": 12,
    "extra": {
      "code": "fr"
    }
  },
  {
    "name": "Vegetables",
    "value": 10,
    "extra": {
      "code": "ve"
    }
  },
  {
    "name": "Oils",
    "value": 5,
    "extra": {
      "code": "oo"
    }
  },
  {
    "name": "Electronics",
    "value": 7,
    "extra": {
      "code": "ee"
    }
  },
  {
    "name": "Leather",
    "value": 9,
    "extra": {
      "code": "ll"
    }
  },
  {
    "name": "Coffee",
    "value": 13,
    "extra": {
      "code": "cof"
    }
  },
  {
    "name": "Tea",
    "value": 12,
    "extra": {
      "code": "te"
    }
  },
  {
    "name": "Sugar",
    "value": 18,
    "extra": {
      "code": "su"
    }
  },
  {
    "name": "Cotton",
    "value": 21,
    "extra": {
      "code": "coc"
    }
  },
  {
    "name": "Rice, Maize",
    "value": 24,
    "extra": {
      "code": "rm"
    }
  },
  {
    "name": "Millet",
    "value": 28,
    "extra": {
      "code": "mi"
    }
  },
  {
    "name": "Chemicals",
    "value": 20,
    "extra": {
      "code": "cc"
    }
  },
  {
    "name": "Medicaments",
    "value": 12,
    "extra": {
      "code": "mm"
    }
  }
];

export interface TreeMapDataItem {
  name: StringOrNumberOrDate;
  size?: number;
  children?: TreeMapDataItem[];
  extra?: any;
}

export type StringOrNumberOrDate = string | number | Date;

export interface TreeMapData extends Array<TreeMapDataItem> {}

export const treemap: TreeMapData = [
  {
    name: 'flare',
    children: [
      {
        name: 'analytics',
        children: [
          {
            name: 'cluster',
            children: [
              { name: 'AgglomerativeCluster', size: 3938 },
              { name: 'CommunityStructure', size: 3812 },
              { name: 'HierarchicalCluster', size: 6714 },
              { name: 'MergeEdge', size: 743 }
            ]
          },
          {
            name: 'graph',
            children: [
              { name: 'BetweennessCentrality', size: 3534 },
              { name: 'LinkDistance', size: 5731 },
              { name: 'MaxFlowMinCut', size: 7840 },
              { name: 'ShortestPaths', size: 5914 },
              { name: 'SpanningTree', size: 3416 }
            ]
          },
          {
            name: 'optimization',
            children: [{ name: 'AspectRatioBanker', size: 7074 }]
          }
        ]
      },
      {
        name: 'animate',
        children: [
          { name: 'Easing', size: 17010 },
          { name: 'FunctionSequence', size: 5842 },
          {
            name: 'interpolate',
            children: [
              { name: 'ArrayInterpolator', size: 1983 },
              { name: 'ColorInterpolator', size: 2047 },
              { name: 'DateInterpolator', size: 1375 },
              { name: 'Interpolator', size: 8746 },
              { name: 'MatrixInterpolator', size: 2202 },
              { name: 'NumberInterpolator', size: 1382 },
              { name: 'ObjectInterpolator', size: 1629 },
              { name: 'PointInterpolator', size: 1675 },
              { name: 'RectangleInterpolator', size: 2042 }
            ]
          },
          { name: 'ISchedulable', size: 1041 },
          { name: 'Parallel', size: 5176 },
          { name: 'Pause', size: 449 },
          { name: 'Scheduler', size: 5593 },
          { name: 'Sequence', size: 5534 },
          { name: 'Transition', size: 9201 },
          { name: 'Transitioner', size: 19975 },
          { name: 'TransitionEvent', size: 1116 },
          { name: 'Tween', size: 6006 }
        ]
      },
      {
        name: 'data',
        children: [
          {
            name: 'converters',
            children: [
              { name: 'Converters', size: 721 },
              { name: 'DelimitedTextConverter', size: 4294 },
              { name: 'GraphMLConverter', size: 9800 },
              { name: 'IDataConverter', size: 1314 },
              { name: 'JSONConverter', size: 2220 }
            ]
          },
          { name: 'DataField', size: 1759 },
          { name: 'DataSchema', size: 2165 },
          { name: 'DataSet', size: 586 },
          { name: 'DataSource', size: 3331 },
          { name: 'DataTable', size: 772 },
          { name: 'DataUtil', size: 3322 }
        ]
      },
      {
        name: 'display',
        children: [
          { name: 'DirtySprite', size: 8833 },
          { name: 'LineSprite', size: 1732 },
          { name: 'RectSprite', size: 3623 },
          { name: 'TextSprite', size: 10066 }
        ]
      },
      {
        name: 'flex',
        children: [{ name: 'FlareVis', size: 4116 }]
      },
      {
        name: 'physics',
        children: [
          { name: 'DragForce', size: 1082 },
          { name: 'GravityForce', size: 1336 },
          { name: 'IForce', size: 319 },
          { name: 'NBodyForce', size: 10498 },
          { name: 'Particle', size: 2822 },
          { name: 'Simulation', size: 9983 },
          { name: 'Spring', size: 2213 },
          { name: 'SpringForce', size: 1681 }
        ]
      },
      {
        name: 'query',
        children: [
          { name: 'AggregateExpression', size: 1616 },
          { name: 'And', size: 1027 },
          { name: 'Arithmetic', size: 3891 },
          { name: 'Average', size: 891 },
          { name: 'BinaryExpression', size: 2893 },
          { name: 'Comparison', size: 5103 },
          { name: 'CompositeExpression', size: 3677 },
          { name: 'Count', size: 781 },
          { name: 'DateUtil', size: 4141 },
          { name: 'Distinct', size: 933 },
          { name: 'Expression', size: 5130 },
          { name: 'ExpressionIterator', size: 3617 },
          { name: 'Fn', size: 3240 },
          { name: 'If', size: 2732 },
          { name: 'IsA', size: 2039 },
          { name: 'Literal', size: 1214 },
          { name: 'Match', size: 3748 },
          { name: 'Maximum', size: 843 },
          {
            name: 'methods',
            children: [
              { name: 'add', size: 593 },
              { name: 'and', size: 330 },
              { name: 'average', size: 287 },
              { name: 'count', size: 277 },
              { name: 'distinct', size: 292 },
              { name: 'div', size: 595 },
              { name: 'eq', size: 594 },
              { name: 'fn', size: 460 },
              { name: 'gt', size: 603 },
              { name: 'gte', size: 625 },
              { name: 'iff', size: 748 },
              { name: 'isa', size: 461 },
              { name: 'lt', size: 597 },
              { name: 'lte', size: 619 },
              { name: 'max', size: 283 },
              { name: 'min', size: 283 },
              { name: 'mod', size: 591 },
              { name: 'mul', size: 603 },
              { name: 'neq', size: 599 },
              { name: 'not', size: 386 },
              { name: 'or', size: 323 },
              { name: 'orderby', size: 307 },
              { name: 'range', size: 772 },
              { name: 'select', size: 296 },
              { name: 'stddev', size: 363 },
              { name: 'sub', size: 600 },
              { name: 'sum', size: 280 },
              { name: 'update', size: 307 },
              { name: 'variance', size: 335 },
              { name: 'where', size: 299 },
              { name: 'xor', size: 354 },
              { name: '_', size: 264 }
            ]
          },
          { name: 'Minimum', size: 843 },
          { name: 'Not', size: 1554 },
          { name: 'Or', size: 970 },
          { name: 'Query', size: 13896 },
          { name: 'Range', size: 1594 },
          { name: 'StringUtil', size: 4130 },
          { name: 'Sum', size: 791 },
          { name: 'Variable', size: 1124 },
          { name: 'Variance', size: 1876 },
          { name: 'Xor', size: 1101 }
        ]
      },
      {
        name: 'scale',
        children: [
          { name: 'IScaleMap', size: 2105 },
          { name: 'LinearScale', size: 1316 },
          { name: 'LogScale', size: 3151 },
          { name: 'OrdinalScale', size: 3770 },
          { name: 'QuantileScale', size: 2435 },
          { name: 'QuantitativeScale', size: 4839 },
          { name: 'RootScale', size: 1756 },
          { name: 'Scale', size: 4268 },
          { name: 'ScaleType', size: 1821 },
          { name: 'TimeScale', size: 5833 }
        ]
      },
      {
        name: 'util',
        children: [
          { name: 'Arrays', size: 8258 },
          { name: 'Colors', size: 10001 },
          { name: 'Dates', size: 8217 },
          { name: 'Displays', size: 12555 },
          { name: 'Filter', size: 2324 },
          { name: 'Geometry', size: 10993 },
          {
            name: 'heap',
            children: [
              { name: 'FibonacciHeap', size: 9354 },
              { name: 'HeapNode', size: 1233 }
            ]
          },
          { name: 'IEvaluable', size: 335 },
          { name: 'IPredicate', size: 383 },
          { name: 'IValueProxy', size: 874 },
          {
            name: 'math',
            children: [
              { name: 'DenseMatrix', size: 3165 },
              { name: 'IMatrix', size: 2815 },
              { name: 'SparseMatrix', size: 3366 }
            ]
          },
          { name: 'Maths', size: 17705 },
          { name: 'Orientation', size: 1486 },
          {
            name: 'palette',
            children: [
              { name: 'ColorPalette', size: 6367 },
              { name: 'Palette', size: 1229 },
              { name: 'ShapePalette', size: 2059 },
              { name: 'SizePalette', size: 2291 }
            ]
          },
          { name: 'Property', size: 5559 },
          { name: 'Shapes', size: 19118 },
          { name: 'Sort', size: 6887 },
          { name: 'Stats', size: 6557 },
          { name: 'Strings', size: 22026 }
        ]
      },
      {
        name: 'vis',
        children: [
          {
            name: 'axis',
            children: [
              { name: 'Axes', size: 1302 },
              { name: 'Axis', size: 24593 },
              { name: 'AxisGridLine', size: 652 },
              { name: 'AxisLabel', size: 636 },
              { name: 'CartesianAxes', size: 6703 }
            ]
          },
          {
            name: 'controls',
            children: [
              { name: 'AnchorControl', size: 2138 },
              { name: 'ClickControl', size: 3824 },
              { name: 'Control', size: 1353 },
              { name: 'ControlList', size: 4665 },
              { name: 'DragControl', size: 2649 },
              { name: 'ExpandControl', size: 2832 },
              { name: 'HoverControl', size: 4896 },
              { name: 'IControl', size: 763 },
              { name: 'PanZoomControl', size: 5222 },
              { name: 'SelectionControl', size: 7862 },
              { name: 'TooltipControl', size: 8435 }
            ]
          },
          {
            name: 'data',
            children: [
              { name: 'Data', size: 20544 },
              { name: 'DataList', size: 19788 },
              { name: 'DataSprite', size: 10349 },
              { name: 'EdgeSprite', size: 3301 },
              { name: 'NodeSprite', size: 19382 },
              {
                name: 'render',
                children: [
                  { name: 'ArrowType', size: 698 },
                  { name: 'EdgeRenderer', size: 5569 },
                  { name: 'IRenderer', size: 353 },
                  { name: 'ShapeRenderer', size: 2247 }
                ]
              },
              { name: 'ScaleBinding', size: 11275 },
              { name: 'Tree', size: 7147 },
              { name: 'TreeBuilder', size: 9930 }
            ]
          },
          {
            name: 'events',
            children: [
              { name: 'DataEvent', size: 2313 },
              { name: 'SelectionEvent', size: 1880 },
              { name: 'TooltipEvent', size: 1701 },
              { name: 'VisualizationEvent', size: 1117 }
            ]
          },
          {
            name: 'legend',
            children: [
              { name: 'Legend', size: 20859 },
              { name: 'LegendItem', size: 4614 },
              { name: 'LegendRange', size: 10530 }
            ]
          },
          {
            name: 'operator',
            children: [
              {
                name: 'distortion',
                children: [
                  { name: 'BifocalDistortion', size: 4461 },
                  { name: 'Distortion', size: 6314 },
                  { name: 'FisheyeDistortion', size: 3444 }
                ]
              },
              {
                name: 'encoder',
                children: [
                  { name: 'ColorEncoder', size: 3179 },
                  { name: 'Encoder', size: 4060 },
                  { name: 'PropertyEncoder', size: 4138 },
                  { name: 'ShapeEncoder', size: 1690 },
                  { name: 'SizeEncoder', size: 1830 }
                ]
              },
              {
                name: 'filter',
                children: [
                  { name: 'FisheyeTreeFilter', size: 5219 },
                  { name: 'GraphDistanceFilter', size: 3165 },
                  { name: 'VisibilityFilter', size: 3509 }
                ]
              },
              { name: 'IOperator', size: 1286 },
              {
                name: 'label',
                children: [
                  { name: 'Labeler', size: 9956 },
                  { name: 'RadialLabeler', size: 3899 },
                  { name: 'StackedAreaLabeler', size: 3202 }
                ]
              },
              {
                name: 'layout',
                children: [
                  { name: 'AxisLayout', size: 6725 },
                  { name: 'BundledEdgeRouter', size: 3727 },
                  { name: 'CircleLayout', size: 9317 },
                  { name: 'CirclePackingLayout', size: 12003 },
                  { name: 'DendrogramLayout', size: 4853 },
                  { name: 'ForceDirectedLayout', size: 8411 },
                  { name: 'IcicleTreeLayout', size: 4864 },
                  { name: 'IndentedTreeLayout', size: 3174 },
                  { name: 'Layout', size: 7881 },
                  { name: 'NodeLinkTreeLayout', size: 12870 },
                  { name: 'PieLayout', size: 2728 },
                  { name: 'RadialTreeLayout', size: 12348 },
                  { name: 'RandomLayout', size: 870 },
                  { name: 'StackedAreaLayout', size: 9121 },
                  { name: 'TreeMapLayout', size: 9191 }
                ]
              },
              { name: 'Operator', size: 2490 },
              { name: 'OperatorList', size: 5248 },
              { name: 'OperatorSequence', size: 4190 },
              { name: 'OperatorSwitch', size: 2581 },
              { name: 'SortOperator', size: 2023 }
            ]
          },
          { name: 'Visualization', size: 16540 }
        ]
      }
    ]
  }
];

export var vesselsInPortData = [
  {
    "name": "Jun 17",
    "value": 23
  },
  {
    "name": "Jun 18",
    "value": 32
  },
  {
    "name": "Jun 19",
    "value": 12
  },
  {
    "name": "Jun 20",
    "value": 14
  },
  {
    "name": "Jun 21",
    "value": 31
  },
  {
    "name": "Jun 22",
    "value": 41
  },
  {
    "name": "Jun 23",
    "value": 23
  }
];

export var blUsed = [
  {
    "name": "Closed",
    "value": 25
  },
  {
    "name": "Not Closed due to RMS",
    "value": 105
  },
  {
    "name": "Not Closed due to Claimed Quantity Pending Exhaustion",
    "value": 14
  }
];

export var blNotUsed = [
  {
    "name": "0-24 hrs",
    "value": 4
  },
  {
    "name": "24-48 hrs",
    "value": 3
  },
  {
    "name": "48 - 1 Week",
    "value": 1
  }
];

export let cargoChartData = [
  ['Compositions ', 'Percentage'],
  ['Seeds', 100],
  ['Chemicals', 200],
  ['Iron Ore', 300],
  ['POL', 500],
  ['Other', 100],
  ['Cement', 100],
  ['Poksteel', 100],
  ['Rice', 50],
  ['Fertilizer', 80],
  ['Edible Oil', 90]
];

export let arimaData = [
  {
    "name":"Total Revenue",
    "series":[
      {"name": "2011-07-01", "value":	0.030584298},
      {"name": "2011-08-01", "value":	0.724600285},
      {"name": "2011-09-01", "value":	1.203827281},
      {"name": "2011-10-01", "value":	1.266110559},
      {"name": "2011-11-01", "value":	1.004029016},
      {"name": "2011-12-01", "value":	1.273326679},
      {"name": "2012-01-01", "value":	1.859608782},
      {"name": "2012-02-01", "value":	1.021146365},
      {"name": "2012-03-01", "value":	1.205020063},
      {"name": "2012-04-01", "value":	2.511615228},
      {"name": "2012-05-01", "value":	0.991964293},
      {"name": "2012-06-01", "value":	2.64621931 },
      {"name": "2012-07-01", "value":	0.037103343},
      {"name": "2012-08-01", "value":	0.785055095},
      {"name": "2012-09-01", "value":	1.130635548},
      {"name": "2012-10-01", "value":	1.328033944},
      {"name": "2012-11-01", "value":	0.938360246},
      {"name": "2012-12-01", "value":	1.370816449},
      {"name": "2013-01-01", "value":	1.976592341},
      {"name": "2013-02-01", "value":	0.949535285},
      {"name": "2013-03-01", "value":	1.230978545},
      {"name": "2013-04-01", "value":	3.083017457},
      {"name": "2013-05-01", "value":	0.974372797},
      {"name": "2013-06-01", "value":	2.724025074},
      {"name": "2013-07-01", "value":	0.03368368 },
      {"name": "2013-08-01", "value":	0.688560589},
      {"name": "2013-09-01", "value":	1.392687561},
      {"name": "2013-10-01", "value":	0.963852673},
      {"name": "2013-11-01", "value":	1.474875472},
      {"name": "2013-12-01", "value":	1.400417982},
      {"name": "2014-01-01", "value":	1.948801431},
      {"name": "2014-02-01", "value":	0.976357824},
      {"name": "2014-03-01", "value":	1.242708864},
      {"name": "2014-04-01", "value":	2.463875551},
      {"name": "2014-05-01", "value":	0.874924603},
      {"name": "2014-06-01", "value":	2.968018871},
      {"name": "2014-07-01", "value":	0.029880959},
      {"name": "2014-08-01", "value":	0.65888911 },
      {"name": "2014-09-01", "value":	1.477725396},
      {"name": "2014-10-01", "value":	1.361160766},
      {"name": "2014-11-01", "value":	0.823072813},
      {"name": "2014-12-01", "value":	1.553985012},
      {"name": "2015-01-01", "value":	1.801120878},
      {"name": "2015-02-01", "value":	1.122082494},
      {"name": "2015-03-01", "value":	1.380935104},
      {"name": "2015-04-01", "value":	2.697053562},
      {"name": "2015-05-01", "value":	0.976990484},
      {"name": "2015-06-01", "value":	2.965846166},
      {"name": "2015-07-01", "value":	0.069761531},
      {"name": "2015-08-01", "value":	0.776546464},
      {"name": "2015-09-01", "value":	1.449555255},
      {"name": "2015-10-01", "value":	1.323551463},
      {"name": "2015-11-01", "value":	1.038682727},
      {"name": "2015-12-01", "value":	1.545336028},
      {"name": "2016-01-01", "value":	1.704279463},
      {"name": "2016-02-01", "value":	1.159535086},
      {"name": "2016-03-01", "value":	1.520280663},
      {"name": "2016-04-01", "value":	2.537318603},
      {"name": "2016-05-01", "value":	1.138794188},
      {"name": "2016-06-01", "value":	3.069445924},
      {"name": "2016-07-01", "value":	0.034990103},
      {"name": "2016-08-01", "value":	0.794257361},
      {"name": "2016-09-01", "value":	1.485696384},
      {"name": "2016-10-01", "value":	1.345090679},
      {"name": "2016-11-01", "value":	1.015419902},
      {"name": "2016-12-01", "value":	1.497297287},
      {"name": "2017-01-01", "value":	1.938055542},
      {"name": "2017-02-01", "value":	1.003789642},
      {"name": "2017-03-01", "value":	1.59131731 },
      {"name": "2017-04-01", "value":	2.311580803},
      {"name": "2017-05-01", "value":	1.212094609},
      {"name": "2017-06-01", "value":	3.049479953},
      {"name": "2017-07-01", "value":	0.031610291},
      {"name": "2017-08-01", "value":	0.891786234},
      {"name": "2017-09-01", "value":	1.332818864},
      {"name": "2017-10-01", "value":	1.505026827},
      {"name": "2017-11-01", "value":	1.058689344},
      {"name": "2017-12-01", "value":	1.843986213},
      {"name": "2018-01-01", "value":	2.765056702},
      {"name": "2018-02-01", "value":	1.094386012},
      {"name": "2018-03-01", "value":	1.470928553},
      {"name": "2018-04-01", "value":	2.836753113},
      {"name": "2018-05-01", "value":	1.152473976},
      {"name": "2018-06-01", "value":	3.536294962},
      {"name": "2018-07-01", "value":	0.035721471},
      {"name": "2018-08-01", "value":	0.992593711},
      {"name": "2018-09-01", "value":	1.588805709},
      {"name": "2018-10-01", "value":	1.690191665},
      {"name": "2018-11-01", "value":	1.110406078},
      {"name": "2018-12-01", "value":	1.581249847},
      {"name": "2019-01-01", "value":	2.232439188},
      {"name": "2019-02-01", "value":	1.191321912},
      {"name": "2019-03-01", "value":	1.714037217},
{"name": "2019-04-01", "value":	3.071856463},
    ]
  }
];

export let arimaForecast = [
  {
    "name": "Train",
    "series": [
      {"name": "2011-07-01", "value":	0.030584298},
      {"name": "2011-08-01", "value":	0.724600285},
      {"name": "2011-09-01", "value":	1.203827281},
      {"name": "2011-10-01", "value":	1.266110559},
      {"name": "2011-11-01", "value":	1.004029016},
      {"name": "2011-12-01", "value":	1.273326679},
      {"name": "2012-01-01", "value":	1.859608782},
      {"name": "2012-02-01", "value":	1.021146365},
      {"name": "2012-03-01", "value":	1.205020063},
      {"name": "2012-04-01", "value":	2.511615228},
      {"name": "2012-05-01", "value":	0.991964293},
      {"name": "2012-06-01", "value":	2.64621931 },
      {"name": "2012-07-01", "value":	0.037103343},
      {"name": "2012-08-01", "value":	0.785055095},
      {"name": "2012-09-01", "value":	1.130635548},
      {"name": "2012-10-01", "value":	1.328033944},
      {"name": "2012-11-01", "value":	0.938360246},
      {"name": "2012-12-01", "value":	1.370816449},
      {"name": "2013-01-01", "value":	1.976592341},
      {"name": "2013-02-01", "value":	0.949535285},
      {"name": "2013-03-01", "value":	1.230978545},
      {"name": "2013-04-01", "value":	3.083017457},
      {"name": "2013-05-01", "value":	0.974372797},
      {"name": "2013-06-01", "value":	2.724025074},
      {"name": "2013-07-01", "value":	0.03368368 },
      {"name": "2013-08-01", "value":	0.688560589},
      {"name": "2013-09-01", "value":	1.392687561},
      {"name": "2013-10-01", "value":	0.963852673},
      {"name": "2013-11-01", "value":	1.474875472},
      {"name": "2013-12-01", "value":	1.400417982},
      {"name": "2014-01-01", "value":	1.948801431},
      {"name": "2014-02-01", "value":	0.976357824},
      {"name": "2014-03-01", "value":	1.242708864},
      {"name": "2014-04-01", "value":	2.463875551},
      {"name": "2014-05-01", "value":	0.874924603},
      {"name": "2014-06-01", "value":	2.968018871},
      {"name": "2014-07-01", "value":	0.029880959},
      {"name": "2014-08-01", "value":	0.65888911 },
      {"name": "2014-09-01", "value":	1.477725396},
      {"name": "2014-10-01", "value":	1.361160766},
      {"name": "2014-11-01", "value":	0.823072813},
      {"name": "2014-12-01", "value":	1.553985012},
      {"name": "2015-01-01", "value":	1.801120878},
      {"name": "2015-02-01", "value":	1.122082494},
      {"name": "2015-03-01", "value":	1.380935104},
      {"name": "2015-04-01", "value":	2.697053562},
      {"name": "2015-05-01", "value":	0.976990484},
      {"name": "2015-06-01", "value":	2.965846166},
      {"name": "2015-07-01", "value":	0.069761531},
      {"name": "2015-08-01", "value":	0.776546464},
      {"name": "2015-09-01", "value":	1.449555255},
      {"name": "2015-10-01", "value":	1.323551463},
      {"name": "2015-11-01", "value":	1.038682727},
      {"name": "2015-12-01", "value":	1.545336028},
      {"name": "2016-01-01", "value":	1.704279463},
      {"name": "2016-02-01", "value":	1.159535086},
      {"name": "2016-03-01", "value":	1.520280663},
      {"name": "2016-04-01", "value":	2.537318603},
      {"name": "2016-05-01", "value":	1.138794188},
      {"name": "2016-06-01", "value":	3.069445924},
      {"name": "2016-07-01", "value":	0.034990103},
      {"name": "2016-08-01", "value":	0.794257361},
      {"name": "2016-09-01", "value":	1.485696384},
      {"name": "2016-10-01", "value":	1.345090679},
      {"name": "2016-11-01", "value":	1.015419902},
      {"name": "2016-12-01", "value":	1.497297287},
      {"name": "2017-01-01", "value":	1.938055542},
      {"name": "2017-02-01", "value":	1.003789642},
      {"name": "2017-03-01", "value":	1.59131731 },
      {"name": "2017-04-01", "value":	2.311580803},
      {"name": "2017-05-01", "value":	1.212094609},
      {"name": "2017-06-01", "value":	3.049479953},
      {"name": "2017-07-01", "value":	0.031610291},
      {"name": "2017-08-01", "value":	0.891786234},
      {"name": "2017-09-01", "value":	1.332818864},
      {"name": "2017-10-01", "value":	1.505026827},
      {"name": "2017-11-01", "value":	1.058689344},
      {"name": "2017-12-01", "value":	1.843986213},
      {"name": "2018-01-01", "value":	2.765056702},
      {"name": "2018-02-01", "value":	1.094386012},
      {"name": "2018-03-01", "value":	1.470928553},
      {"name": "2018-04-01", "value":	2.836753113},
      {"name": "2018-05-01", "value":	1.152473976},
      {"name": "2018-06-01", "value":	3.536294962},
      {"name": "2018-07-01", "value":	0.035721471},
      {"name": "2018-08-01", "value":	0.992593711},
      {"name": "2018-09-01", "value":	1.588805709},
      {"name": "2018-10-01", "value":	1.690191665},
      {"name": "2018-11-01", "value":	1.110406078},
      {"name": "2018-12-01", "value":	1.581249847},
      {"name": "2019-01-01", "value":	2.232439188},
      {"name": "2019-02-01", "value":	1.191321912},
      {"name": "2019-03-01", "value":	1.714037217},
{"name": "2019-04-01", "value":	3.071856463},
    ]
  },
  {
    "name": "Predictions",
    "series": [
      {"name": "2019-05-01", "value":	1.077086334},
      {"name": "2019-06-01", "value":	2.776078451},
      {"name": "2019-07-01", "value":	0.668144224},
      {"name": "2019-08-01", "value":	1.245758414},
      {"name": "2019-09-01", "value":	1.582371615},
      {"name": "2019-10-01", "value":	1.626352546},
      {"name": "2019-11-01", "value":	1.311175474},
      {"name": "2019-12-01", "value":	1.456948491},
      {"name": "2020-01-01", "value":	1.712230067},
      {"name": "2020-02-01", "value":	1.352651777},
      {"name": "2020-03-01", "value":	1.665517883},
      {"name": "2020-04-01", "value":	2.438073675} 
    ]
  },
  {
    "name": "Upper Confidence",
    "series": [
      {"name": "2019-05-01", "value": 2.533502158},
      {"name": "2019-06-01", "value": 4.290101761},
      {"name": "2019-07-01", "value": 2.186289093},
      {"name": "2019-08-01", "value": 2.764270252},
      {"name": "2019-09-01", "value": 3.100913072},
      {"name": "2019-10-01", "value": 3.144896394},
      {"name": "2019-11-01", "value": 2.829719515},
      {"name": "2019-12-01", "value": 2.975492548},
      {"name": "2020-01-01", "value": 3.230774124},
      {"name": "2020-02-01", "value": 2.871195834},
      {"name": "2020-03-01", "value": 3.184061933},
      {"name": "2020-04-01", "value": 3.956617647} 
    ]
  },
  {
    "name": "Lower Confidence",
    "series": [
      {"name": "2019-05-01", "value": -0.379329489},
      {"name": "2019-06-01", "value": 1.262055142},	
      {"name": "2019-07-01", "value": -0.850000645},
      {"name": "2019-08-01", "value": -0.272753424},
      {"name": "2019-09-01", "value": 0.063830158},	
      {"name": "2019-10-01", "value": 0.107808698},	
      {"name": "2019-11-01", "value": -0.207368566},
      {"name": "2019-12-01", "value": -0.061595565},
      {"name": "2020-01-01", "value": 0.193686009},	
      {"name": "2020-02-01", "value": -0.165892281},
      {"name": "2020-03-01", "value": 0.146973832},	
      {"name": "2020-04-01", "value": 0.919529704}	
    ]
  }
];

export let lstm = [
  {
    "name": "Total Revenue",
    "series": [
      {"name": "2011-07-01", "value": 0.030584298},
      {"name": "2011-08-01", "value": 0.724600285},
      {"name": "2011-09-01", "value": 1.203827281},
      {"name": "2011-10-01", "value": 1.266110559},
      {"name": "2011-11-01", "value": 1.004029016},
      {"name": "2011-12-01", "value": 1.273326679},
      {"name": "2012-01-01", "value": 1.859608782},
      {"name": "2012-02-01", "value": 1.021146365},
      {"name": "2012-03-01", "value": 1.205020063},
      {"name": "2012-04-01", "value": 2.511615228},
      {"name": "2012-05-01", "value": 0.991964293},
      {"name": "2012-06-01", "value": 2.64621931 },
      {"name": "2012-07-01", "value": 0.037103343},
      {"name": "2012-08-01", "value": 0.785055095},
      {"name": "2012-09-01", "value": 1.130635548},
      {"name": "2012-10-01", "value": 1.328033944},
      {"name": "2012-11-01", "value": 0.938360246},
      {"name": "2012-12-01", "value": 1.370816449},
      {"name": "2013-01-01", "value": 1.976592341},
      {"name": "2013-02-01", "value": 0.949535285},
      {"name": "2013-03-01", "value": 1.230978545},
      {"name": "2013-04-01", "value": 3.083017457},
      {"name": "2013-05-01", "value": 0.974372796},
      {"name": "2013-06-01", "value": 2.724025074},
      {"name": "2013-07-01", "value": 0.03368368 },
      {"name": "2013-08-01", "value": 0.688560589},
      {"name": "2013-09-01", "value": 1.392687561},
      {"name": "2013-10-01", "value": 0.963852673},
      {"name": "2013-11-01", "value": 1.474875472},
      {"name": "2013-12-01", "value": 1.400417982},
      {"name": "2014-01-01", "value": 1.948801431},
      {"name": "2014-02-01", "value": 0.976357824},
      {"name": "2014-03-01", "value": 1.242708864},
      {"name": "2014-04-01", "value": 2.463875551},
      {"name": "2014-05-01", "value": 0.874924603},
      {"name": "2014-06-01", "value": 2.968018871},
      {"name": "2014-07-01", "value": 0.029880959},
      {"name": "2014-08-01", "value": 0.65888911 },
      {"name": "2014-09-01", "value": 1.477725396},
      {"name": "2014-10-01", "value": 1.361160766},
      {"name": "2014-11-01", "value": 0.823072813},
      {"name": "2014-12-01", "value": 1.553985012},
      {"name": "2015-01-01", "value": 1.801120878},
      {"name": "2015-02-01", "value": 1.122082494},
      {"name": "2015-03-01", "value": 1.380935104},
      {"name": "2015-04-01", "value": 2.697053562},
      {"name": "2015-05-01", "value": 0.976990484},
      {"name": "2015-06-01", "value": 2.965846166},
      {"name": "2015-07-01", "value": 0.069761531},
      {"name": "2015-08-01", "value": 0.776546464},
      {"name": "2015-09-01", "value": 1.449555255},
      {"name": "2015-10-01", "value": 1.323551463},
      {"name": "2015-11-01", "value": 1.038682727},
      {"name": "2015-12-01", "value": 1.545336028},
      {"name": "2016-01-01", "value": 1.704279463},
      {"name": "2016-02-01", "value": 1.159535086},
      {"name": "2016-03-01", "value": 1.520280663},
      {"name": "2016-04-01", "value": 2.537318603},
      {"name": "2016-05-01", "value": 1.138794188},
      {"name": "2016-06-01", "value": 3.069445924},
      {"name": "2016-07-01", "value": 0.034990103},
      {"name": "2016-08-01", "value": 0.794257361},
      {"name": "2016-09-01", "value": 1.485696384},
      {"name": "2016-10-01", "value": 1.345090679},
      {"name": "2016-11-01", "value": 1.015419902},
      {"name": "2016-12-01", "value": 1.497297287},
      {"name": "2017-01-01", "value": 1.938055542},
      {"name": "2017-02-01", "value": 1.003789642},
      {"name": "2017-03-01", "value": 1.59131731 },
      {"name": "2017-04-01", "value": 2.311580803},
      {"name": "2017-05-01", "value": 1.212094609},
      {"name": "2017-06-01", "value": 3.049479953},
      {"name": "2017-07-01", "value": 0.031610291},
      {"name": "2017-08-01", "value": 0.891786234},
      {"name": "2017-09-01", "value": 1.332818864},
      {"name": "2017-10-01", "value": 1.505026827},
      {"name": "2017-11-01", "value": 1.058689344},
      {"name": "2017-12-01", "value": 1.843986213},
      {"name": "2018-01-01", "value": 2.765056702},
      {"name": "2018-02-01", "value": 1.094386012},
      {"name": "2018-03-01", "value": 1.470928553},
      {"name": "2018-04-01", "value": 2.836753113},
      {"name": "2018-05-01", "value": 1.152473976},
      {"name": "2018-06-01", "value": 3.536294962},
      {"name": "2018-07-01", "value": 0.035721471},
      {"name": "2018-08-01", "value": 0.992593711},
      {"name": "2018-09-01", "value": 1.588805709},
      {"name": "2018-10-01", "value": 1.690191665},
      {"name": "2018-11-01", "value": 1.110406078},
      {"name": "2018-12-01", "value": 1.581249847},
      {"name": "2019-01-01", "value": 2.232439188},
      {"name": "2019-02-01", "value": 1.191321912},
      {"name": "2019-03-01", "value": 1.714037217},
      {"name": "2019-04-01", "value": 3.071856463}
    ]
  }
];

export let lstmForecast = [
  {
    "name": "Train",
    "series": [
      {"name": "2011-07-01", "value": 0.030584298},
      {"name": "2011-08-01", "value": 0.724600285},
      {"name": "2011-09-01", "value": 1.203827281},
      {"name": "2011-10-01", "value": 1.266110559},
      {"name": "2011-11-01", "value": 1.004029016},
      {"name": "2011-12-01", "value": 1.273326679},
      {"name": "2012-01-01", "value": 1.859608782},
      {"name": "2012-02-01", "value": 1.021146365},
      {"name": "2012-03-01", "value": 1.205020063},
      {"name": "2012-04-01", "value": 2.511615228},
      {"name": "2012-05-01", "value": 0.991964293},
      {"name": "2012-06-01", "value": 2.64621931 },
      {"name": "2012-07-01", "value": 0.037103343},
      {"name": "2012-08-01", "value": 0.785055095},
      {"name": "2012-09-01", "value": 1.130635548},
      {"name": "2012-10-01", "value": 1.328033944},
      {"name": "2012-11-01", "value": 0.938360246},
      {"name": "2012-12-01", "value": 1.370816449},
      {"name": "2013-01-01", "value": 1.976592341},
      {"name": "2013-02-01", "value": 0.949535285},
      {"name": "2013-03-01", "value": 1.230978545},
      {"name": "2013-04-01", "value": 3.083017457},
      {"name": "2013-05-01", "value": 0.974372796},
      {"name": "2013-06-01", "value": 2.724025074},
      {"name": "2013-07-01", "value": 0.03368368 },
      {"name": "2013-08-01", "value": 0.688560589},
      {"name": "2013-09-01", "value": 1.392687561},
      {"name": "2013-10-01", "value": 0.963852673},
      {"name": "2013-11-01", "value": 1.474875472},
      {"name": "2013-12-01", "value": 1.400417982},
      {"name": "2014-01-01", "value": 1.948801431},
      {"name": "2014-02-01", "value": 0.976357824},
      {"name": "2014-03-01", "value": 1.242708864},
      {"name": "2014-04-01", "value": 2.463875551},
      {"name": "2014-05-01", "value": 0.874924603},
      {"name": "2014-06-01", "value": 2.968018871},
      {"name": "2014-07-01", "value": 0.029880959},
      {"name": "2014-08-01", "value": 0.65888911 },
      {"name": "2014-09-01", "value": 1.477725396},
      {"name": "2014-10-01", "value": 1.361160766},
      {"name": "2014-11-01", "value": 0.823072813},
      {"name": "2014-12-01", "value": 1.553985012},
      {"name": "2015-01-01", "value": 1.801120878},
      {"name": "2015-02-01", "value": 1.122082494},
      {"name": "2015-03-01", "value": 1.380935104},
      {"name": "2015-04-01", "value": 2.697053562},
      {"name": "2015-05-01", "value": 0.976990484},
      {"name": "2015-06-01", "value": 2.965846166},
      {"name": "2015-07-01", "value": 0.069761531},
      {"name": "2015-08-01", "value": 0.776546464},
      {"name": "2015-09-01", "value": 1.449555255},
      {"name": "2015-10-01", "value": 1.323551463},
      {"name": "2015-11-01", "value": 1.038682727},
      {"name": "2015-12-01", "value": 1.545336028},
      {"name": "2016-01-01", "value": 1.704279463},
      {"name": "2016-02-01", "value": 1.159535086},
      {"name": "2016-03-01", "value": 1.520280663},
      {"name": "2016-04-01", "value": 2.537318603},
      {"name": "2016-05-01", "value": 1.138794188},
      {"name": "2016-06-01", "value": 3.069445924},
      {"name": "2016-07-01", "value": 0.034990103},
      {"name": "2016-08-01", "value": 0.794257361},
      {"name": "2016-09-01", "value": 1.485696384},
      {"name": "2016-10-01", "value": 1.345090679},
      {"name": "2016-11-01", "value": 1.015419902},
      {"name": "2016-12-01", "value": 1.497297287},
      {"name": "2017-01-01", "value": 1.938055542},
      {"name": "2017-02-01", "value": 1.003789642},
      {"name": "2017-03-01", "value": 1.59131731 },
      {"name": "2017-04-01", "value": 2.311580803},
      {"name": "2017-05-01", "value": 1.212094609},
      {"name": "2017-06-01", "value": 3.049479953},
      {"name": "2017-07-01", "value": 0.031610291},
      {"name": "2017-08-01", "value": 0.891786234},
      {"name": "2017-09-01", "value": 1.332818864},
      {"name": "2017-10-01", "value": 1.505026827}
    ]
  },
  {
    "name": "Actual Collections",
    "series": [
      {"name": "2017-11-01", "value": 1.058689344},
      {"name": "2017-12-01", "value": 1.843986213},
      {"name": "2018-01-01", "value": 2.765056702},
      {"name": "2018-02-01", "value": 1.094386012},
      {"name": "2018-03-01", "value": 1.470928553},
      {"name": "2018-04-01", "value": 2.836753113},
      {"name": "2018-05-01", "value": 1.152473976},
      {"name": "2018-06-01", "value": 3.536294962},
      {"name": "2018-07-01", "value": 0.035721471},
      {"name": "2018-08-01", "value": 0.992593711},
      {"name": "2018-09-01", "value": 1.588805709},
      {"name": "2018-10-01", "value": 1.690191665},
      {"name": "2018-11-01", "value": 1.110406078},
      {"name": "2018-12-01", "value": 1.581249847},
      {"name": "2019-01-01", "value": 2.232439188},
      {"name": "2019-02-01", "value": 1.191321912},
      {"name": "2019-03-01", "value": 1.714037217},
      {"name": "2019-04-01", "value": 3.071856463}
    ]
  },
  {
    "name": "Predicted Collections",
    "series": [
      {"name": "2017-11-01", "value":	0.960662208},
      {"name": "2017-12-01", "value":	1.428018176},
      {"name": "2018-01-01", "value":	1.821318528},
      {"name": "2018-02-01", "value":	0.686277888},
      {"name": "2018-03-01", "value":	1.382356864},
      {"name": "2018-04-01", "value":	2.588944384},
      {"name": "2018-05-01", "value":	1.083357312},
      {"name": "2018-06-01", "value":	2.85771392},
      {"name": "2018-07-01", "value":	-0.108374056},
      {"name": "2018-08-01", "value":	0.655233984},
      {"name": "2018-09-01", "value":	1.271077248},
      {"name": "2018-10-01", "value":	1.519933312},
      {"name": "2018-11-01", "value":	0.927990272},
      {"name": "2018-12-01", "value":	1.65492608},
      {"name": "2019-01-01", "value":	2.33273088},
      {"name": "2019-02-01", "value":	0.978685376},
      {"name": "2019-03-01", "value":	1.453012096},
      {"name": "2019-04-01", "value":	2.349027328}
    ]
  }
];

export let multivariate = [
  {
    "name": "REV",
    "series": [
		{"name": "2011-08-01", "value": 0.724600285 },
		{"name": "2011-09-01", "value": 1.203827281 },
		{"name": "2011-10-01", "value": 1.266110559 },
		{"name": "2011-11-01", "value": 1.004029016 },
		{"name": "2011-12-01", "value": 1.273326679 },
		{"name": "2012-01-01", "value": 1.859608782 },
		{"name": "2012-02-01", "value": 1.021146365 },
		{"name": "2012-03-01", "value": 1.205020063 },
		{"name": "2012-04-01", "value": 2.511615228 },
		{"name": "2012-05-01", "value": 0.991964293 },
		{"name": "2012-06-01", "value": 2.64621931	},
		{"name": "2012-08-01", "value": 0.785055095 },
		{"name": "2012-09-01", "value": 1.130635548 },
		{"name": "2012-10-01", "value": 1.328033944 },
		{"name": "2012-11-01", "value": 0.938360246 },
		{"name": "2012-12-01", "value": 1.370816449 },
		{"name": "2013-01-01", "value": 1.976592341 },
		{"name": "2013-02-01", "value": 0.949535285 },
		{"name": "2013-03-01", "value": 1.230978545 },
		{"name": "2013-04-01", "value": 3.083017457 },
		{"name": "2013-05-01", "value": 0.974372796 },
		{"name": "2013-06-01", "value": 2.724025074 },
		{"name": "2013-08-01", "value": 0.688560589 },
		{"name": "2013-09-01", "value": 1.392687561 },
		{"name": "2013-10-01", "value": 0.963852673 },
		{"name": "2013-11-01", "value": 1.474875472 },
		{"name": "2013-12-01", "value": 1.400417982 },
		{"name": "2014-01-01", "value": 1.948801431 },
		{"name": "2014-02-01", "value": 0.976357824 },
		{"name": "2014-03-01", "value": 1.242708864 },
		{"name": "2014-04-01", "value": 2.463875551 },
		{"name": "2014-05-01", "value": 0.874924603 },
		{"name": "2014-06-01", "value": 2.968018871 },
		{"name": "2014-08-01", "value": 0.65888911	},
		{"name": "2014-09-01", "value": 1.477725396 },
		{"name": "2014-10-01", "value": 1.361160766 },
		{"name": "2014-11-01", "value": 0.823072813 },
		{"name": "2014-12-01", "value": 1.553985012 },
		{"name": "2015-01-01", "value": 1.801120878 },
		{"name": "2015-02-01", "value": 1.122082494 },
		{"name": "2015-03-01", "value": 1.380935104 },
		{"name": "2015-04-01", "value": 2.697053562 },
		{"name": "2015-05-01", "value": 0.976990484 },
		{"name": "2015-06-01", "value": 2.965846166 },
		{"name": "2015-08-01", "value": 0.776546464 },
		{"name": "2015-09-01", "value": 1.449555255 },
		{"name": "2015-10-01", "value": 1.323551463 },
		{"name": "2015-11-01", "value": 1.038682727 },
		{"name": "2015-12-01", "value": 1.545336028 },
		{"name": "2016-01-01", "value": 1.704279463 },
		{"name": "2016-02-01", "value": 1.159535086 },
		{"name": "2016-03-01", "value": 1.520280663 },
		{"name": "2016-04-01", "value": 2.537318603 },
		{"name": "2016-05-01", "value": 1.138794188 },
		{"name": "2016-06-01", "value": 3.069445924 },
		{"name": "2016-08-01", "value": 0.794257361 },
		{"name": "2016-09-01", "value": 1.485696384 },
		{"name": "2016-10-01", "value": 1.345090679 },
		{"name": "2016-11-01", "value": 1.015419902 },
		{"name": "2016-12-01", "value": 1.497297287 },
		{"name": "2017-01-01", "value": 1.938055542 },
		{"name": "2017-02-01", "value": 1.003789642 },
		{"name": "2017-03-01", "value": 1.59131731	},
		{"name": "2017-04-01", "value": 2.311580803 },
		{"name": "2017-05-01", "value": 1.212094609 },
		{"name": "2017-06-01", "value": 3.049479953 },
		{"name": "2017-08-01", "value": 0.891786234 },
		{"name": "2017-09-01", "value": 1.332818864 },
		{"name": "2017-10-01", "value": 1.505026827 },
		{"name": "2017-11-01", "value": 1.058689344 },
		{"name": "2017-12-01", "value": 1.843986213 },
		{"name": "2018-01-01", "value": 2.765056702 },
		{"name": "2018-02-01", "value": 1.094386012 },
		{"name": "2018-03-01", "value": 1.470928553 },
		{"name": "2018-04-01", "value": 2.836753113 },
		{"name": "2018-05-01", "value": 1.152473976 },
		{"name": "2018-06-01", "value": 3.536294962 },
		{"name": "2018-08-01", "value": 0.992593711 },
		{"name": "2018-09-01", "value": 1.588805709 },
		{"name": "2018-10-01", "value": 1.690191665 },
		{"name": "2018-11-01", "value": 1.110406078 },
		{"name": "2018-12-01", "value": 1.581249847 },
		{"name": "2019-01-01", "value": 2.232439188 },
		{"name": "2019-02-01", "value": 1.191321912 },
		{"name": "2019-03-01", "value": 1.714037217 },
    ]
  },
  {
    "name": "GDP",
    "series": [
		{"name": "2011-08-01", "value": 0.277152391 },
		{"name": "2011-09-01", "value": 0.398048299 },
		{"name": "2011-10-01", "value": 0.408613206 },
		{"name": "2011-11-01", "value": 0.465707422 },
		{"name": "2011-12-01", "value": 0.540952631 },
		{"name": "2012-01-01", "value": 0.493107286 },
		{"name": "2012-02-01", "value": 0.481771321 },
		{"name": "2012-03-01", "value": 0.564895879 },
		{"name": "2012-04-01", "value": 0.372964697 },
		{"name": "2012-05-01", "value": 0.411727248 },
		{"name": "2012-06-01", "value": 0.851135078 },
		{"name": "2012-08-01", "value": 0.320983014 },
		{"name": "2012-09-01", "value": 0.342279424 },
		{"name": "2012-10-01", "value": 0.396173305 },
		{"name": "2012-11-01", "value": 0.412725179 },
		{"name": "2012-12-01", "value": 0.483932688 },
		{"name": "2013-01-01", "value": 0.562214077 },
		{"name": "2013-02-01", "value": 0.440403563 },
		{"name": "2013-03-01", "value": 0.575586824 },
		{"name": "2013-04-01", "value": 0.39213097 }, 
		{"name": "2013-05-01", "value": 0.435380698 },
		{"name": "2013-06-01", "value": 0.863213398 },
		{"name": "2013-08-01", "value": 0.30270576 }, 
		{"name": "2013-09-01", "value": 0.364883174 },
		{"name": "2013-10-01", "value": 0.402066996 },
		{"name": "2013-11-01", "value": 0.487599744 },
		{"name": "2013-12-01", "value": 0.42450304 }, 
		{"name": "2014-01-01", "value": 0.553937826 },
		{"name": "2014-02-01", "value": 0.497056295 },
		{"name": "2014-03-01", "value": 0.567379258 },
		{"name": "2014-04-01", "value": 0.429717216 },
		{"name": "2014-05-01", "value": 0.445743565 },
		{"name": "2014-06-01", "value": 0.951012984 },
		{"name": "2014-08-01", "value": 0.255315752 },
		{"name": "2014-09-01", "value": 0.381751466 },
		{"name": "2014-10-01", "value": 0.452304625 },
		{"name": "2014-11-01", "value": 0.402872182 },
		{"name": "2014-12-01", "value": 0.530796063 },
		{"name": "2015-01-01", "value": 0.546226275 },
		{"name": "2015-02-01", "value": 0.508486803 },
		{"name": "2015-03-01", "value": 0.61307899 }, 
		{"name": "2015-04-01", "value": 0.488294013 },
		{"name": "2015-05-01", "value": 0.400562602 },
		{"name": "2015-06-01", "value": 0.980913766 },
		{"name": "2015-08-01", "value": 0.257164671 },
		{"name": "2015-09-01", "value": 0.404434045 },
		{"name": "2015-10-01", "value": 0.439544224 },
		{"name": "2015-11-01", "value": 0.427963185 },
		{"name": "2015-12-01", "value": 0.593972343 },
		{"name": "2016-01-01", "value": 0.538136826 },
		{"name": "2016-02-01", "value": 0.491349532 },
		{"name": "2016-03-01", "value": 0.707342784 },
		{"name": "2016-04-01", "value": 0.455145484 },
		{"name": "2016-05-01", "value": 0.420139399 },
		{"name": "2016-06-01", "value": 1.016272763 },
		{"name": "2016-08-01", "value": 0.299099901 },
		{"name": "2016-09-01", "value": 0.441404416 },
		{"name": "2016-10-01", "value": 0.414355678 },
		{"name": "2016-11-01", "value": 0.449591202 },
		{"name": "2016-12-01", "value": 0.569128213 },
		{"name": "2017-01-01", "value": 0.547575903 },
		{"name": "2017-02-01", "value": 0.487462609 },
		{"name": "2017-03-01", "value": 0.728253769 },
		{"name": "2017-04-01", "value": 0.42685157 }, 
		{"name": "2017-05-01", "value": 0.460141986 },
		{"name": "2017-06-01", "value": 1.000778212 },
		{"name": "2017-08-01", "value": 0.381726836 },
		{"name": "2017-09-01", "value": 0.414481467 },
		{"name": "2017-10-01", "value": 0.436660615 },
		{"name": "2017-11-01", "value": 0.487721488 },
		{"name": "2017-12-01", "value": 0.557356415 },
		{"name": "2018-01-01", "value": 0.613382074 },
		{"name": "2018-02-01", "value": 0.529025201 },
		{"name": "2018-03-01", "value": 0.729759475 },
		{"name": "2018-04-01", "value": 0.462261846 },
		{"name": "2018-05-01", "value": 0.520758666 },
		{"name": "2018-06-01", "value": 1.015677985 },
		{"name": "2018-08-01", "value": 0.431172695 },
		{"name": "2018-09-01", "value": 0.42960274 }, 
		{"name": "2018-10-01", "value": 0.503298062 },
		{"name": "2018-11-01", "value": 0.505967111 },
		{"name": "2018-12-01", "value": 0.612349152 },
		{"name": "2019-01-01", "value": 0.655782175 },
		{"name": "2019-02-01", "value": 0.611978556 },
		{"name": "2019-03-01", "value": 0.724089269 }
    ]
  },
  {
    "name": "IMPORT",
    "series": [
		{"name": "2011-08-01", "value": 0.023202685 },
		{"name": "2011-09-01", "value": 0.274881289 },
		{"name": "2011-10-01", "value": 0.069442266 },
		{"name": "2011-11-01", "value": 0.088038349 },
		{"name": "2011-12-01", "value": 0.228425551 },
		{"name": "2012-01-01", "value": 0.451940062 },
		{"name": "2012-02-01", "value": 0.09294172  },
		{"name": "2012-03-01", "value": 0.104713534 },
		{"name": "2012-04-01", "value": 1.223284456 },
		{"name": "2012-05-01", "value": 0.112930879 },
		{"name": "2012-06-01", "value": 0.373515678 },
		{"name": "2012-08-01", "value": 0.026085542 },
		{"name": "2012-09-01", "value": 0.35411493  },
		{"name": "2012-10-01", "value": 0.100449017 },
		{"name": "2012-11-01", "value": 0.025131383 },
		{"name": "2012-12-01", "value": 0.303854998 },
		{"name": "2013-01-01", "value": 0.539681066 },
		{"name": "2013-02-01", "value": 0.036620272 },
		{"name": "2013-03-01", "value": 0.095818912 },
		{"name": "2013-04-01", "value": 1.563130784 },
		{"name": "2013-05-01", "value": 0.048896042 },
		{"name": "2013-06-01", "value": 0.401878668 },
		{"name": "2013-08-01", "value": 0.026300552 },
		{"name": "2013-09-01", "value": 0.400859292 },
		{"name": "2013-10-01", "value": 0.047535701 },
		{"name": "2013-11-01", "value": 0.290538347 },
		{"name": "2013-12-01", "value": 0.117351435 },
		{"name": "2014-01-01", "value": 0.566120816 },
		{"name": "2014-02-01", "value": 0.027203448 },
		{"name": "2014-03-01", "value": 0.103487124 },
		{"name": "2014-04-01", "value": 1.244574405 },
		{"name": "2014-05-01", "value": 0.042309039 },
		{"name": "2014-06-01", "value": 0.428410118 },
		{"name": "2014-08-01", "value": 0.028771125 },
		{"name": "2014-09-01", "value": 0.430962057 },
		{"name": "2014-10-01", "value": 0.105800403 },
		{"name": "2014-11-01", "value": 0.028195048 },
		{"name": "2014-12-01", "value": 0.316910593 },
		{"name": "2015-01-01", "value": 0.582472964 },
		{"name": "2015-02-01", "value": 0.035332602 },
		{"name": "2015-03-01", "value": 0.11911539  },
		{"name": "2015-04-01", "value": 1.422690402 },
		{"name": "2015-05-01", "value": 0.065387346 },
		{"name": "2015-06-01", "value": 0.452438387 },
		{"name": "2015-08-01", "value": 0.038130235 },
		{"name": "2015-09-01", "value": 0.447724713 },
		{"name": "2015-10-01", "value": 0.113818775 },
		{"name": "2015-11-01", "value": 0.017443873 },
		{"name": "2015-12-01", "value": 0.328958909 },
		{"name": "2016-01-01", "value": 0.489128944 },
		{"name": "2016-02-01", "value": 0.041193147 },
		{"name": "2016-03-01", "value": 0.138169277 },
		{"name": "2016-04-01", "value": 1.372797018 },
		{"name": "2016-05-01", "value": 0.046378194 },
		{"name": "2016-06-01", "value": 0.396500598 },
		{"name": "2016-08-01", "value": 0.031039317 },
		{"name": "2016-09-01", "value": 0.391417712 },
		{"name": "2016-10-01", "value": 0.109897883 },
		{"name": "2016-11-01", "value": 0.025025221 },
		{"name": "2016-12-01", "value": 0.315091628 },
		{"name": "2017-01-01", "value": 0.456575807 },
		{"name": "2017-02-01", "value": 0.040207884 },
		{"name": "2017-03-01", "value": 0.173953909 },
		{"name": "2017-04-01", "value": 1.168909081 },
		{"name": "2017-05-01", "value": 0.077098531 },
		{"name": "2017-06-01", "value": 0.374747051 },
		{"name": "2017-08-01", "value": 0.032935487 },
		{"name": "2017-09-01", "value": 0.370186822 },
		{"name": "2017-10-01", "value": 0.103501588 },
		{"name": "2017-11-01", "value": 0.044320466 },
		{"name": "2017-12-01", "value": 0.664486135 },
		{"name": "2018-01-01", "value": 1.30147662  },
		{"name": "2018-02-01", "value": 0.053946672 },
		{"name": "2018-03-01", "value": 0.168679896 },
		{"name": "2018-04-01", "value": 1.244010951 },
		{"name": "2018-05-01", "value": 0.051848142 },
		{"name": "2018-06-01", "value": 0.585625777 },
		{"name": "2018-08-01", "value": 0.038347919 },
		{"name": "2018-09-01", "value": 0.424903812 },
		{"name": "2018-10-01", "value": 0.123606934 },
		{"name": "2018-11-01", "value": 0.040950781 },
		{"name": "2018-12-01", "value": 0.110400298 },
		{"name": "2019-01-01", "value": 0.476316325 },
		{"name": "2019-02-01", "value": 0.041019773 },
		{"name": "2019-03-01", "value": 0.066499405 }
    ]
  }
];

export let multiVariateForecast = [
  {
    "name": "Train",
    "series": [
      {"name": "2011-08-01", "value": 0.724600285 },
      {"name": "2011-09-01", "value": 1.203827281 },
      {"name": "2011-10-01", "value": 1.266110559 },
      {"name": "2011-11-01", "value": 1.004029016 },
      {"name": "2011-12-01", "value": 1.273326679 },
      {"name": "2012-01-01", "value": 1.859608782 },
      {"name": "2012-02-01", "value": 1.021146365 },
      {"name": "2012-03-01", "value": 1.205020063 },
      {"name": "2012-04-01", "value": 2.511615228 },
      {"name": "2012-05-01", "value": 0.991964293 },
      {"name": "2012-06-01", "value": 2.64621931	},
      {"name": "2012-08-01", "value": 0.785055095 },
      {"name": "2012-09-01", "value": 1.130635548 },
      {"name": "2012-10-01", "value": 1.328033944 },
      {"name": "2012-11-01", "value": 0.938360246 },
      {"name": "2012-12-01", "value": 1.370816449 },
      {"name": "2013-01-01", "value": 1.976592341 },
      {"name": "2013-02-01", "value": 0.949535285 },
      {"name": "2013-03-01", "value": 1.230978545 },
      {"name": "2013-04-01", "value": 3.083017457 },
      {"name": "2013-05-01", "value": 0.974372796 },
      {"name": "2013-06-01", "value": 2.724025074 },
      {"name": "2013-08-01", "value": 0.688560589 },
      {"name": "2013-09-01", "value": 1.392687561 },
      {"name": "2013-10-01", "value": 0.963852673 },
      {"name": "2013-11-01", "value": 1.474875472 },
      {"name": "2013-12-01", "value": 1.400417982 },
      {"name": "2014-01-01", "value": 1.948801431 },
      {"name": "2014-02-01", "value": 0.976357824 },
      {"name": "2014-03-01", "value": 1.242708864 },
      {"name": "2014-04-01", "value": 2.463875551 },
      {"name": "2014-05-01", "value": 0.874924603 },
      {"name": "2014-06-01", "value": 2.968018871 },
      {"name": "2014-08-01", "value": 0.65888911	},
      {"name": "2014-09-01", "value": 1.477725396 },
      {"name": "2014-10-01", "value": 1.361160766 },
      {"name": "2014-11-01", "value": 0.823072813 },
      {"name": "2014-12-01", "value": 1.553985012 },
      {"name": "2015-01-01", "value": 1.801120878 },
      {"name": "2015-02-01", "value": 1.122082494 },
      {"name": "2015-03-01", "value": 1.380935104 },
      {"name": "2015-04-01", "value": 2.697053562 },
      {"name": "2015-05-01", "value": 0.976990484 },
      {"name": "2015-06-01", "value": 2.965846166 },
      {"name": "2015-08-01", "value": 0.776546464 },
      {"name": "2015-09-01", "value": 1.449555255 },
      {"name": "2015-10-01", "value": 1.323551463 },
      {"name": "2015-11-01", "value": 1.038682727 },
      {"name": "2015-12-01", "value": 1.545336028 },
      {"name": "2016-01-01", "value": 1.704279463 },
      {"name": "2016-02-01", "value": 1.159535086 },
      {"name": "2016-03-01", "value": 1.520280663 },
      {"name": "2016-04-01", "value": 2.537318603 },
      {"name": "2016-05-01", "value": 1.138794188 },
      {"name": "2016-06-01", "value": 3.069445924 },
      {"name": "2016-08-01", "value": 0.794257361 },
      {"name": "2016-09-01", "value": 1.485696384 },
      {"name": "2016-10-01", "value": 1.345090679 },
      {"name": "2016-11-01", "value": 1.015419902 },
      {"name": "2016-12-01", "value": 1.497297287 },
      {"name": "2017-01-01", "value": 1.938055542 },
      {"name": "2017-02-01", "value": 1.003789642 },
      {"name": "2017-03-01", "value": 1.59131731	},
      {"name": "2017-04-01", "value": 2.311580803 },
      {"name": "2017-05-01", "value": 1.212094609 },
      {"name": "2017-06-01", "value": 3.049479953 },
      {"name": "2017-08-01", "value": 0.891786234 },
      {"name": "2017-09-01", "value": 1.332818864 },
      {"name": "2017-10-01", "value": 1.505026827 }
    ]
  },
  {
    "name": "Actual Collections",
    "series": [
      {"name": "2017-11-01", "value": 1.058689344 },
      {"name": "2017-12-01", "value": 1.843986213 },
      {"name": "2018-01-01", "value": 2.765056702 },
      {"name": "2018-02-01", "value": 1.094386012 },
      {"name": "2018-03-01", "value": 1.470928553 },
      {"name": "2018-04-01", "value": 2.836753113 },
      {"name": "2018-05-01", "value": 1.152473976 },
      {"name": "2018-06-01", "value": 3.536294962 },
      {"name": "2018-08-01", "value": 0.992593711 },
      {"name": "2018-09-01", "value": 1.588805709 },
      {"name": "2018-10-01", "value": 1.690191665 },
      {"name": "2018-11-01", "value": 1.110406078 },
      {"name": "2018-12-01", "value": 1.581249847 },
      {"name": "2019-01-01", "value": 2.232439188 },
      {"name": "2019-02-01", "value": 1.191321912 },
      {"name": "2019-03-01", "value": 1.714037217 }
    ]
  },
  {
    "name": "Predicted Collections",
    "series": [
      {"name": "2017-11-01", "value":	1.484211456},
      {"name": "2017-12-01", "value":	1.855249152},
      {"name": "2018-01-01", "value":	1.352051968},
      {"name": "2018-02-01", "value":	0.625279168},
      {"name": "2018-03-01", "value":	1.969459968},
      {"name": "2018-04-01", "value":	2.913542144},
      {"name": "2018-05-01", "value":	0.917251712},
      {"name": "2018-06-01", "value":	1.937298176},
      {"name": "2018-08-01", "value":	0.334610592},
      {"name": "2018-09-01", "value":	1.741658368},
      {"name": "2018-10-01", "value":	1.439538432},
      {"name": "2018-11-01", "value":	1.514872448},
      {"name": "2018-12-01", "value":	1.838415104},
      {"name": "2019-01-01", "value":	2.000454016},
      {"name": "2019-02-01", "value":	1.253785344},
      {"name": "2019-03-01", "value":	2.335043328}
    ]
  }
];

export let totalRevenue = [
  {
    "name": "Total Revenue",
    "series": [
      {"name": "2012-07-01", "value":	0.06519045	},
      {"name": "2012-08-01", "value":	0.604548102	},
      {"name": "2012-09-01", "value":	-0.73191733	},
      {"name": "2012-10-01", "value":	0.61923385	},
      {"name": "2012-11-01", "value":	-0.656687699},
      {"name": "2012-12-01", "value":	0.9748977	},
      {"name": "2013-01-01", "value":	1.16983559	},
      {"name": "2013-02-01", "value":	-0.7161108	},
      {"name": "2013-03-01", "value":	0.25958482	},
      {"name": "2013-04-01", "value":	5.71402229	},
      {"name": "2013-05-01", "value":	-0.175914963},
      {"name": "2013-06-01", "value":	0.77805764	},
      {"name": "2013-07-01", "value":	-0.034196622},
      {"name": "2013-08-01", "value":	-0.964945067},
      {"name": "2013-09-01", "value":	2.62052013	},
      {"name": "2013-10-01", "value":	-3.641812714},
      {"name": "2013-11-01", "value":	5.365152259	},
      {"name": "2013-12-01", "value":	0.29601533	},
      {"name": "2014-01-01", "value":	-0.2779091	},
      {"name": "2014-02-01", "value":	0.268225389	},
      {"name": "2014-03-01", "value":	0.11730319	},
      {"name": "2014-04-01", "value":	-6.19141906	},
      {"name": "2014-05-01", "value":	-0.994481935},
      {"name": "2014-06-01", "value":	2.43993797	},
      {"name": "2014-07-01", "value":	-0.038027217},
      {"name": "2014-08-01", "value":	-0.296714785},
      {"name": "2014-09-01", "value":	0.85037835	},
      {"name": "2014-10-01", "value":	3.973080934	},
      {"name": "2014-11-01", "value":	-6.518026591},
      {"name": "2014-12-01", "value":	1.5356703	},
      {"name": "2015-01-01", "value":	-1.47680553	},
      {"name": "2015-02-01", "value":	1.457246701	},
      {"name": "2015-03-01", "value":	1.3822624	},
      {"name": "2015-04-01", "value":	2.33178011	},
      {"name": "2015-05-01", "value":	1.020658805	},
      {"name": "2015-06-01", "value":	-0.02172705	},
      {"name": "2015-07-01", "value":	0.398805726	},
      {"name": "2015-08-01", "value":	1.176573536	},
      {"name": "2015-09-01", "value":	-0.28170141	},
      {"name": "2015-10-01", "value":	-0.37609303	},
      {"name": "2015-11-01", "value":	2.156099141	},
      {"name": "2015-12-01", "value":	-0.08648984	},
      {"name": "2016-01-01", "value":	-0.96841415	},
      {"name": "2016-02-01", "value":	0.37452592	},
      {"name": "2016-03-01", "value":	1.39345559	},
      {"name": "2016-04-01", "value":	-1.59734959	},
      {"name": "2016-05-01", "value":	1.618037045	},
      {"name": "2016-06-01", "value":	1.03599758	},
      {"name": "2016-07-01", "value":	-0.347714287},
      {"name": "2016-08-01", "value":	0.177108976	},
      {"name": "2016-09-01", "value":	0.36141129	},
      {"name": "2016-10-01", "value":	0.21539216	},
      {"name": "2016-11-01", "value":	-0.23262825	},
      {"name": "2016-12-01", "value":	-0.48038741	},
      {"name": "2017-01-01", "value":	2.33776079	},
      {"name": "2017-02-01", "value":	-1.55745444	},
      {"name": "2017-03-01", "value":	0.71036647	},
      {"name": "2017-04-01", "value":	-2.257378	},
      {"name": "2017-05-01", "value":	0.73300421	},
      {"name": "2017-06-01", "value":	-0.19965971	},
      {"name": "2017-07-01", "value":	-0.033798113},
      {"name": "2017-08-01", "value":	0.975288729	},
      {"name": "2017-09-01", "value":	-1.5287752	},
      {"name": "2017-10-01", "value":	1.59936148	},
      {"name": "2017-11-01", "value":	0.43269442	},
      {"name": "2017-12-01", "value":	3.46688926	},
      {"name": "2018-01-01", "value":	8.2700116	},
      {"name": "2018-02-01", "value":	0.9059637	},
      {"name": "2018-03-01", "value":	-1.20388757	},
      {"name": "2018-04-01", "value":	5.2517231	},
      {"name": "2018-05-01", "value":	-0.59620633	},
      {"name": "2018-06-01", "value":	4.86815009	},
      {"name": "2018-07-01", "value":	0.0411118	},
      {"name": "2018-08-01", "value":	1.00807477	},
      {"name": "2018-09-01", "value":	2.55986845	},
      {"name": "2018-10-01", "value":	1.85164838	},
      {"name": "2018-11-01", "value":	0.51716734	},
      {"name": "2018-12-01", "value":	-2.62736366	},
      {"name": "2019-01-01", "value":	-5.32617514	},
      {"name": "2019-02-01", "value":	0.969359	},
      {"name": "2019-03-01", "value":	2.43108664	},
      {"name": "2019-04-01", "value":	2.3510335	} 
    ]
  }
];

export let cycle = [
  {
    "name": "Cycle",
    "series": [
      {"name": "2012-07-01", "value":	-0.194632776},
      {"name": "2012-08-01", "value":	0.343753776	},
      {"name": "2012-09-01", "value":	-0.993681253},
      {"name": "2012-10-01", "value":	0.35650068	},
      {"name": "2012-11-01", "value":	-0.920383248},
      {"name": "2012-12-01", "value":	0.710250407	},
      {"name": "2013-01-01", "value":	0.904258053	},
      {"name": "2013-02-01", "value":	-0.982591692},
      {"name": "2013-03-01", "value":	-0.007774128},
      {"name": "2013-04-01", "value":	5.445816576	},
      {"name": "2013-05-01", "value":	-0.444930101},
      {"name": "2013-06-01", "value":	0.508234452	},
      {"name": "2013-07-01", "value":	-0.304859023},
      {"name": "2013-08-01", "value":	-1.236514298},
      {"name": "2013-09-01", "value":	2.347942344	},
      {"name": "2013-10-01", "value":	-3.915525341},
      {"name": "2013-11-01", "value":	5.090135823	},
      {"name": "2013-12-01", "value":	0.019513649	},
      {"name": "2014-01-01", "value":	-0.556129205},
      {"name": "2014-02-01", "value":	-0.011998214},
      {"name": "2014-03-01", "value":	-0.165256589},
      {"name": "2014-04-01", "value":	-6.476695202},
      {"name": "2014-05-01", "value":	-1.282900863},
      {"name": "2014-06-01", "value":	2.147953571	},
      {"name": "2014-07-01", "value":	-0.333986134},
      {"name": "2014-08-01", "value":	-0.597060202},
      {"name": "2014-09-01", "value":	0.545234092	},
      {"name": "2014-10-01", "value":	3.662729741	},
      {"name": "2014-11-01", "value":	-6.83399277	},
      {"name": "2014-12-01", "value":	1.213652861	},
      {"name": "2015-01-01", "value":	-1.80528599	},
      {"name": "2015-02-01", "value":	1.121906604	},
      {"name": "2015-03-01", "value":	1.039695125	},
      {"name": "2015-04-01", "value":	1.981638536	},
      {"name": "2015-05-01", "value":	0.662608208	},
      {"name": "2015-06-01", "value":	-0.388024288},
      {"name": "2015-07-01", "value":	0.023916223	},
      {"name": "2015-08-01", "value":	0.792741131	},
      {"name": "2015-09-01", "value":	-0.674832551},
      {"name": "2015-10-01", "value":	-0.778890055},
      {"name": "2015-11-01", "value":	1.743262979	},
      {"name": "2015-12-01", "value":	-0.509738491},
      {"name": "2016-01-01", "value":	-1.402462188},
      {"name": "2016-02-01", "value":	-0.070718018},
      {"name": "2016-03-01", "value":	0.936620445	},
      {"name": "2016-04-01", "value":	-2.066169496},
      {"name": "2016-05-01", "value":	1.136833349	},
      {"name": "2016-06-01", "value":	0.542021534	},
      {"name": "2016-07-01", "value":	-0.854849548},
      {"name": "2016-08-01", "value":	-0.34357485	},
      {"name": "2016-09-01", "value":	-0.173206342},
      {"name": "2016-10-01", "value":	-0.333537756},
      {"name": "2016-11-01", "value":	-0.796240833},
      {"name": "2016-12-01", "value":	-1.059042369},
      {"name": "2017-01-01", "value":	1.743720558	},
      {"name": "2017-02-01", "value":	-2.167197853},
      {"name": "2017-03-01", "value":	0.084613498	},
      {"name": "2017-04-01", "value":	-2.899418653},
      {"name": "2017-05-01", "value":	0.074425354	},
      {"name": "2017-06-01", "value":	-0.874977317},
      {"name": "2017-07-01", "value":	-0.72600562	},
      {"name": "2017-08-01", "value":	0.266096324	},
      {"name": "2017-09-01", "value":	-2.25498575	},
      {"name": "2017-10-01", "value":	0.856159237	},
      {"name": "2017-11-01", "value":	-0.327395963},
      {"name": "2017-12-01", "value":	2.69008478	},
      {"name": "2018-01-01", "value":	7.476740087	},
      {"name": "2018-02-01", "value":	0.096524478	},
      {"name": "2018-03-01", "value":	-2.029200606},
      {"name": "2018-04-01", "value":	4.410823973	},
      {"name": "2018-05-01", "value":	-1.452394342},
      {"name": "2018-06-01", "value":	3.996945847	},
      {"name": "2018-07-01", "value":	-0.844849361},
      {"name": "2018-08-01", "value":	0.107571817	},
      {"name": "2018-09-01", "value":	1.645001167	},
      {"name": "2018-10-01", "value":	0.922555733	},
      {"name": "2018-11-01", "value":	-0.426062894},
      {"name": "2018-12-01", "value":	-3.584702009},
      {"name": "2019-01-01", "value":	-6.297647154},
      {"name": "2019-02-01", "value":	-0.016299586},
      {"name": "2019-03-01", "value":	1.431209807	},
      {"name": "2019-04-01", "value":	1.336928104	} 
    ]
  }
];

export let trendRevenue = [
  {
    "name": "Total Revenue",
    "series": [
      {"name": "2012-07-01", "value":	0.06519045	},
      {"name": "2012-08-01", "value":	0.604548102	},
      {"name": "2012-09-01", "value":	-0.73191733	},
      {"name": "2012-10-01", "value":	0.61923385	},
      {"name": "2012-11-01", "value":	-0.656687699},
      {"name": "2012-12-01", "value":	0.9748977	},
      {"name": "2013-01-01", "value":	1.16983559	},
      {"name": "2013-02-01", "value":	-0.7161108	},
      {"name": "2013-03-01", "value":	0.25958482	},
      {"name": "2013-04-01", "value":	5.71402229	},
      {"name": "2013-05-01", "value":	-0.175914963},
      {"name": "2013-06-01", "value":	0.77805764	},
      {"name": "2013-07-01", "value":	-0.034196622},
      {"name": "2013-08-01", "value":	-0.964945067},
      {"name": "2013-09-01", "value":	2.62052013	},
      {"name": "2013-10-01", "value":	-3.641812714},
      {"name": "2013-11-01", "value":	5.365152259	},
      {"name": "2013-12-01", "value":	0.29601533	},
      {"name": "2014-01-01", "value":	-0.2779091	},
      {"name": "2014-02-01", "value":	0.268225389	},
      {"name": "2014-03-01", "value":	0.11730319	},
      {"name": "2014-04-01", "value":	-6.19141906	},
      {"name": "2014-05-01", "value":	-0.994481935},
      {"name": "2014-06-01", "value":	2.43993797	},
      {"name": "2014-07-01", "value":	-0.038027217},
      {"name": "2014-08-01", "value":	-0.296714785},
      {"name": "2014-09-01", "value":	0.85037835	},
      {"name": "2014-10-01", "value":	3.973080934	},
      {"name": "2014-11-01", "value":	-6.518026591},
      {"name": "2014-12-01", "value":	1.5356703	},
      {"name": "2015-01-01", "value":	-1.47680553	},
      {"name": "2015-02-01", "value":	1.457246701	},
      {"name": "2015-03-01", "value":	1.3822624	},
      {"name": "2015-04-01", "value":	2.33178011	},
      {"name": "2015-05-01", "value":	1.020658805	},
      {"name": "2015-06-01", "value":	-0.02172705	},
      {"name": "2015-07-01", "value":	0.398805726	},
      {"name": "2015-08-01", "value":	1.176573536	},
      {"name": "2015-09-01", "value":	-0.28170141	},
      {"name": "2015-10-01", "value":	-0.37609303	},
      {"name": "2015-11-01", "value":	2.156099141	},
      {"name": "2015-12-01", "value":	-0.08648984	},
      {"name": "2016-01-01", "value":	-0.96841415	},
      {"name": "2016-02-01", "value":	0.37452592	},
      {"name": "2016-03-01", "value":	1.39345559	},
      {"name": "2016-04-01", "value":	-1.59734959	},
      {"name": "2016-05-01", "value":	1.618037045	},
      {"name": "2016-06-01", "value":	1.03599758	},
      {"name": "2016-07-01", "value":	-0.347714287},
      {"name": "2016-08-01", "value":	0.177108976	},
      {"name": "2016-09-01", "value":	0.36141129	},
      {"name": "2016-10-01", "value":	0.21539216	},
      {"name": "2016-11-01", "value":	-0.23262825	},
      {"name": "2016-12-01", "value":	-0.48038741	},
      {"name": "2017-01-01", "value":	2.33776079	},
      {"name": "2017-02-01", "value":	-1.55745444	},
      {"name": "2017-03-01", "value":	0.71036647	},
      {"name": "2017-04-01", "value":	-2.257378	},
      {"name": "2017-05-01", "value":	0.73300421	},
      {"name": "2017-06-01", "value":	-0.19965971	},
      {"name": "2017-07-01", "value":	-0.033798113},
      {"name": "2017-08-01", "value":	0.975288729	},
      {"name": "2017-09-01", "value":	-1.5287752	},
      {"name": "2017-10-01", "value":	1.59936148	},
      {"name": "2017-11-01", "value":	0.43269442	},
      {"name": "2017-12-01", "value":	3.46688926	},
      {"name": "2018-01-01", "value":	8.2700116	},
      {"name": "2018-02-01", "value":	0.9059637	},
      {"name": "2018-03-01", "value":	-1.20388757	},
      {"name": "2018-04-01", "value":	5.2517231	},
      {"name": "2018-05-01", "value":	-0.59620633	},
      {"name": "2018-06-01", "value":	4.86815009	},
      {"name": "2018-07-01", "value":	0.0411118	},
      {"name": "2018-08-01", "value":	1.00807477	},
      {"name": "2018-09-01", "value":	2.55986845	},
      {"name": "2018-10-01", "value":	1.85164838	},
      {"name": "2018-11-01", "value":	0.51716734	},
      {"name": "2018-12-01", "value":	-2.62736366	},
      {"name": "2019-01-01", "value":	-5.32617514	},
      {"name": "2019-02-01", "value":	0.969359	},
      {"name": "2019-03-01", "value":	2.43108664	},
      {"name": "2019-04-01", "value":	2.3510335	} 
    ]
  },
  {
    "name": "Trend",
    "series": [
      {"name": "2012-07-01", "value": 0.259823226 },
      {"name": "2012-08-01", "value": 0.260794326 },
      {"name": "2012-09-01", "value": 0.261763923 },
      {"name": "2012-10-01", "value": 0.26273317  },
      {"name": "2012-11-01", "value": 0.263695549 },
      {"name": "2012-12-01", "value": 0.264647293 },
      {"name": "2013-01-01", "value": 0.265577537 },
      {"name": "2013-02-01", "value": 0.266480892 },
      {"name": "2013-03-01", "value": 0.267358948 },
      {"name": "2013-04-01", "value": 0.268205714 },
      {"name": "2013-05-01", "value": 0.269015138 },
      {"name": "2013-06-01", "value": 0.269823188 },
      {"name": "2013-07-01", "value": 0.2706624   },
      {"name": "2013-08-01", "value": 0.271569231 },
      {"name": "2013-09-01", "value": 0.272577786 },
      {"name": "2013-10-01", "value": 0.273712627 },
      {"name": "2013-11-01", "value": 0.275016437 },
      {"name": "2013-12-01", "value": 0.276501681 },
      {"name": "2014-01-01", "value": 0.278220105 },
      {"name": "2014-02-01", "value": 0.280223603 },
      {"name": "2014-03-01", "value": 0.282559779 },
      {"name": "2014-04-01", "value": 0.285276142 },
      {"name": "2014-05-01", "value": 0.288418928 },
      {"name": "2014-06-01", "value": 0.291984399 },
      {"name": "2014-07-01", "value": 0.295958917 },
      {"name": "2014-08-01", "value": 0.300345417 },
      {"name": "2014-09-01", "value": 0.305144258 },
      {"name": "2014-10-01", "value": 0.310351193 },
      {"name": "2014-11-01", "value": 0.315966179 },
      {"name": "2014-12-01", "value": 0.322017439 },
      {"name": "2015-01-01", "value": 0.32848046  },
      {"name": "2015-02-01", "value": 0.335340097 },
      {"name": "2015-03-01", "value": 0.342567275 },
      {"name": "2015-04-01", "value": 0.350141574 },
      {"name": "2015-05-01", "value": 0.358050597 },
      {"name": "2015-06-01", "value": 0.366297238 },
      {"name": "2015-07-01", "value": 0.374889503 },
      {"name": "2015-08-01", "value": 0.383832406 },
      {"name": "2015-09-01", "value": 0.393131141 },
      {"name": "2015-10-01", "value": 0.402797025 },
      {"name": "2015-11-01", "value": 0.412836162 },
      {"name": "2015-12-01", "value": 0.423248651 },
      {"name": "2016-01-01", "value": 0.434048038 },
      {"name": "2016-02-01", "value": 0.445243938 },
      {"name": "2016-03-01", "value": 0.456835145 },
      {"name": "2016-04-01", "value": 0.468819906 },
      {"name": "2016-05-01", "value": 0.481203696 },
      {"name": "2016-06-01", "value": 0.493976046 },
      {"name": "2016-07-01", "value": 0.507135261 },
      {"name": "2016-08-01", "value": 0.520683826 },
      {"name": "2016-09-01", "value": 0.534617632 },
      {"name": "2016-10-01", "value": 0.548929916 },
      {"name": "2016-11-01", "value": 0.563612583 },
      {"name": "2016-12-01", "value": 0.57865496  },
      {"name": "2017-01-01", "value": 0.594040232 },
      {"name": "2017-02-01", "value": 0.609743413 },
      {"name": "2017-03-01", "value": 0.625752972 },
      {"name": "2017-04-01", "value": 0.642040653 },
      {"name": "2017-05-01", "value": 0.658578856 },
      {"name": "2017-06-01", "value": 0.675317607 },
      {"name": "2017-07-01", "value": 0.692207507 },
      {"name": "2017-08-01", "value": 0.709192406 },
      {"name": "2017-09-01", "value": 0.72621055  },
      {"name": "2017-10-01", "value": 0.743202243 },
      {"name": "2017-11-01", "value": 0.760090383 },
      {"name": "2017-12-01", "value": 0.77680448  },
      {"name": "2018-01-01", "value": 0.793271514 },
      {"name": "2018-02-01", "value": 0.809439222 },
      {"name": "2018-03-01", "value": 0.825313036 },
      {"name": "2018-04-01", "value": 0.840899127 },
      {"name": "2018-05-01", "value": 0.856188012 },
      {"name": "2018-06-01", "value": 0.871204243 },
      {"name": "2018-07-01", "value": 0.885961161 },
      {"name": "2018-08-01", "value": 0.900502953 },
      {"name": "2018-09-01", "value": 0.914867283 },
      {"name": "2018-10-01", "value": 0.929092648 },
      {"name": "2018-11-01", "value": 0.943230234 },
      {"name": "2018-12-01", "value": 0.957338349 },
      {"name": "2019-01-01", "value": 0.971472014 },
      {"name": "2019-02-01", "value": 0.985658586 },
      {"name": "2019-03-01", "value": 0.999876833 },
      {"name": "2019-04-01", "value": 1.014105396 }
    ]
  }
];

export let fraudRecall = [
  {
    "name": "Recall",
    "series": [
      {"name": "1", "value": 0.04312482676795683},
      {"name": "2", "value": 0.1270100436051563},
      {"name": "3", "value": 0.20168288438075704},
      {"name": "4", "value": 0.2879648811974254},
      {"name": "5", "value": 0.33554041457094175},
      {"name": "6", "value": 0.42639007200208723},
      {"name": "7", "value": 0.4460349407115105},
      {"name": "8", "value": 0.46005229184526664},
      {"name": "9", "value": 0.48945320662488},
      {"name": "10", "value": 0.5086416197619431},
      {"name": "11", "value": 0.5214932740316589},
      {"name": "12", "value": 0.5243328923101941},
      {"name": "13", "value": 0.5353481596231137},
      {"name": "14", "value": 0.5430997459164938},
      {"name": "15", "value": 0.5511533329312738},
      {"name": "16", "value": 0.553958305140291},
      {"name": "17", "value": 0.5562650662471005},
      {"name": "18", "value": 0.5618293499135575},
      {"name": "19", "value": 0.572833502646716},
      {"name": "20", "value": 0.576731475251579},
      {"name": "21", "value": 0.5811545948151473},
      {"name": "22", "value": 0.5909575535069755},
      {"name": "23", "value": 0.6310184132686939},
      {"name": "24", "value": 0.6315437604197003},
      {"name": "25", "value": 0.6367761538246819},
      {"name": "26", "value": 0.6436324521098536},
      {"name": "27", "value": 0.646830546014657},
      {"name": "28", "value": 0.6468405092359635},
      {"name": "29", "value": 0.647014590245468},
      {"name": "30", "value": 0.6472423424709061},
      {"name": "31", "value": 0.6488873253964946},
      {"name": "32", "value": 0.6502810248631558},
      {"name": "33", "value": 0.6502850301781452},
      {"name": "34", "value": 0.6830924656685201},
      {"name": "35", "value": 0.6836285270456466},
      {"name": "36", "value": 0.6855635948083803},
      {"name": "37", "value": 0.692809209732302},
      {"name": "38", "value": 0.6930185375143098},
      {"name": "39", "value": 0.6957161171719507},
      {"name": "40", "value": 0.6960475569889985},
      {"name": "41", "value": 0.6990209025779494},
      {"name": "42", "value": 0.6991476708004586},
      {"name": "43", "value": 0.7004233636823871},
      {"name": "44", "value": 0.7078387539605222},
      {"name": "45", "value": 0.7110717941953182},
      {"name": "46", "value": 0.7116278320584701},
      {"name": "47", "value": 0.7163306226796772},
      {"name": "48", "value": 0.7258852017301166},
      {"name": "49", "value": 0.7274567371199355},
      {"name": "50", "value": 0.7282135414128758},
      {"name": "51", "value": 0.7292344461974779},
      {"name": "52", "value": 0.7324885645433244},
      {"name": "53", "value": 0.7324885645433244},
      {"name": "54", "value": 0.7342485000103758},
      {"name": "55", "value": 0.7368389375828829},
      {"name": "56", "value": 0.738917145452771},
      {"name": "57", "value": 0.7389640076390417},
      {"name": "58", "value": 0.741168683265055},
      {"name": "59", "value": 0.7412085862160748},
      {"name": "60", "value": 0.742256176364261},
      {"name": "61", "value": 0.7427302053841868},
      {"name": "62", "value": 0.7427302053841868},
      {"name": "63", "value": 0.7475364832577311},
      {"name": "64", "value": 0.7476348638110273},
      {"name": "65", "value": 0.9199060647976778},
      {"name": "66", "value": 0.9280681960471927},
      {"name": "67", "value": 0.9280681960471927},
      {"name": "68", "value": 0.9391788401810093},
      {"name": "69", "value": 0.9391788401810093},
      {"name": "70", "value": 0.9406843379710049},
      {"name": "71", "value": 0.9440975173942135},
      {"name": "72", "value": 0.9459817678539045},
      {"name": "73", "value": 0.9462637420339298},
      {"name": "74", "value": 0.9495168087798855},
      {"name": "75", "value": 0.9543103698403167},
      {"name": "76", "value": 0.9667024642537535},
      {"name": "77", "value": 0.9667024642537535},
      {"name": "78", "value": 0.9684293558790436},
      {"name": "79", "value": 0.9684293558790436},
      {"name": "80", "value": 0.9684293558790436},
      {"name": "81", "value": 0.9684293558790436},
      {"name": "82", "value": 0.9684293558790436},
      {"name": "83", "value": 0.9684293558790436},
      {"name": "84", "value": 0.9684293558790436},
      {"name": "85", "value": 0.9915321631110973},
      {"name": "86", "value": 0.9915321631110973},
      {"name": "87", "value": 0.9915321631110973},
      {"name": "88", "value": 0.9931150636868038},
      {"name": "89", "value": 0.995117821304117},
      {"name": "90", "value": 0.995258357793032},
      {"name": "91", "value": 0.995258357793032},
      {"name": "92", "value": 0.9952742288541155},
      {"name": "93", "value": 0.9952742288541155},
      {"name": "94", "value": 0.9952742288541155},
      {"name": "95", "value": 0.9952742288541155},
      {"name": "96", "value": 0.9952742288541155},
      {"name": "97", "value": 0.9952742288541155},
      {"name": "98", "value": 0.9952742288541155},
      {"name": "99", "value": 0.9952742288541155},
      {"name": "100","value": 1}
    ]
  }
];

export let sampleRecallData = [
  ['n', 'DATE', 'RandomFore', 'LogisticRe', 'DecisionTr', 'AdaBoostCl', 'XGBClassif', 'LGBMClassi', '<catboost.', 'NaiveEnsem'],
  ["1", 0.04312482676795683, 	0.09460887949260043, 0.002114164904862579,0.07135306553911205,0.08456659619450317,0.09619450317124736,0.09355179704016914,0.0919661733615222, 0.09408033826638477],
  ["2", 0.1270100436051563, 	0.17970401691331925, 0.002642706131078224,0.14429175475687103,0.16913319238900634,0.1839323467230444, 0.17124735729386892,0.17230443974630022,0.17389006342494714],
  ["3", 0.20168288438075704, 	0.24682875264270612, 0.015856236786469344,0.21987315010570824,0.24154334038054967,0.2616279069767442, 0.2521141649048626, 0.2489429175475687, 0.2521141649048626],
  ["4", 0.2879648811974254, 	0.3144820295983087 , 0.03171247357293869 ,0.2928118393234672, 0.30761099365750527,0.33509513742071884,0.32716701902748413,0.3150105708245243, 0.322938689217759],
  ["5", 0.33554041457094175, 	0.3726215644820296 , 0.04704016913319239 ,0.3419661733615222, 0.363107822410148,  0.39904862579281186,0.3905919661733615, 0.38054968287526425,0.3927061310782241],
  ["6", 0.42639007200208723, 	0.42441860465116277, 0.05866807610993657 ,0.39217758985200846,0.4154334038054968, 0.4619450317124736, 0.4450317124735729, 0.44238900634249473,0.44820295983086683],
  ["7", 0.4460349407115105, 	0.4571881606765328 , 0.07029598308668077 ,0.4376321353065539, 0.46035940803382663,0.5110993657505285, 0.4952431289640592, 0.4904862579281184, 0.4978858350951374],
  ["8", 0.46005229184526664, 	0.5190274841437632 , 0.0755813953488372  ,0.4809725158562368, 0.4952431289640592, 0.5586680761099366, 0.5354122621564482, 0.5406976744186046, 0.5417547568710359],
  ["9", 0.48945320662488, 	0.5687103594080338 , 0.08403805496828752 ,0.5211416490486258, 0.5380549682875264, 0.5924947145877378, 0.5681818181818182, 0.578752642706131,  0.578752642706131,],
  ["10",0.5086416197619431, 	0.6035940803382663 , 0.10359408033826638 ,0.5496828752642706, 0.5824524312896406, 0.629492600422833,  0.6020084566596194, 0.6067653276955602, 0.6199788583509513],
  ["11",0.5214932740316589,	0.6247357293868921 , 0.11522198731501057 ,0.5798097251585623, 0.620507399577167,  0.6627906976744186, 0.6411205073995772, 0.6358350951374208, 0.6479915433403806],
  ["12",0.5243328923101941,	0.6495771670190275 , 0.12896405919661733 ,0.6273784355179705, 0.6490486257928119, 0.6897463002114165, 0.6733615221987315, 0.6643763213530656, 0.6701902748414377],
  ["13",0.5353481596231137,	0.686046511627907  , 0.13372093023255813 ,0.6691331923890064, 0.6775898520084567, 0.7103594080338267, 0.7061310782241015, 0.6929175475687104, 0.6966173361522199],
  ["14",0.5430997459164938,	0.7230443974630021 , 0.1379492600422833  ,0.7124735729386892, 0.7087737843551797, 0.7325581395348837, 0.7272727272727273, 0.7140591966173362, 0.7182875264270613],
  ["15",0.5511533329312738,	0.7489429175475687 , 0.14376321353065538 ,0.7484143763213531, 0.735200845665962,  0.7595137420718816, 0.7484143763213531, 0.7362579281183932, 0.7441860465116279],
  ["16",0.553958305140291,	0.7706131078224101 , 0.15803382663847781 ,0.7595137420718816, 0.757399577167019,  0.7785412262156448, 0.7669133192389006, 0.7595137420718816, 0.7642706131078224],
  ["17",0.5562650662471005,	0.7822410147991543 , 0.17019027484143764 ,0.7727272727272727, 0.7780126849894292, 0.7928118393234672, 0.7906976744186046, 0.7743128964059197, 0.7790697674418605],
  ["18",0.5618293499135575,	0.7986257928118393 , 0.1828752642706131  ,0.7854122621564482, 0.7996828752642706, 0.8054968287526427, 0.804968287526427,  0.7933403805496829, 0.7980972515856237],
  ["19",0.572833502646716,	0.813953488372093  , 0.1945031712473573  ,0.8017970401691332, 0.8218816067653277, 0.8208245243128964, 0.8202959830866807, 0.8102536997885835, 0.8102536997885835],
  ["20",0.576731475251579,	0.8324524312896406 , 0.20665961945031713 ,0.8144820295983086, 0.8319238900634249, 0.837737843551797,  0.8366807610993657, 0.830338266384778,  0.8292811839323467],
  ["21",0.5811545948151473,	0.8488372093023255 , 0.2156448202959831  ,0.8271670190274841, 0.8419661733615222, 0.8535940803382663, 0.8472515856236786, 0.8414376321353065, 0.8477801268498943],
  ["22",0.5909575535069755,	0.8652219873150105 , 0.2299154334038055  ,0.8414376321353065, 0.8578224101479915, 0.8678646934460887, 0.864693446088795,  0.8588794926004228, 0.8620507399577167],
  ["23",0.6310184132686939,	0.8805496828752643 , 0.2452431289640592  ,0.854122621564482,  0.8768498942917548, 0.8747357293868921, 0.8773784355179705, 0.8710359408033826, 0.8789640591966174],
  ["24",0.6315437604197003,	0.8937632135306554 , 0.25317124735729385 ,0.8736786469344608, 0.888477801268499,  0.8858350951374208, 0.8911205073995772, 0.8816067653276956, 0.8895348837209303],
  ["25",0.6367761538246819,	0.9016913319238901 , 0.2605708245243129  ,0.888477801268499,  0.8995771670190275, 0.8974630021141649, 0.9038054968287527, 0.8921775898520085, 0.9043340380549683],
  ["26",0.6436324521098536,	0.9080338266384778 , 0.2727272727272727  ,0.9064482029598309, 0.9112050739957717, 0.9075052854122622, 0.9149048625792812, 0.9022198731501057, 0.9143763213530656],
  ["27",0.646830546014657,	0.9164904862579282 , 0.2880549682875264  ,0.9170190274841438, 0.9164904862579282, 0.9154334038054969, 0.9201902748414377, 0.9085623678646935, 0.9201902748414377],
  ["28",0.6468405092359635,	0.9186046511627907 , 0.2986257928118393  ,0.9186046511627907, 0.9186046511627907, 0.9201902748414377, 0.9249471458773785, 0.912262156448203,  0.9238900634249472],
  ["29",0.647014590245468,	0.9201902748414377 , 0.3086680761099366  ,0.9244186046511628, 0.9217758985200846, 0.9249471458773785, 0.9275898520084567, 0.9143763213530656, 0.9249471458773785],
  ["30",0.6472423424709061,	0.9254756871035941 , 0.31976744186046513 ,0.9275898520084567, 0.9244186046511628, 0.9281183932346723, 0.9302325581395349, 0.9191331923890064, 0.9275898520084567],
  ["31",0.6488873253964946,	0.9281183932346723 , 0.3335095137420719  ,0.9307610993657506, 0.9254756871035941, 0.9307610993657506, 0.936046511627907,  0.9201902748414377, 0.9302325581395349],
  ["32",0.6502810248631558,	0.9291754756871036 , 0.3451374207188161  ,0.9307610993657506, 0.9265327695560254, 0.9334038054968288, 0.9386892177589852, 0.9217758985200846, 0.9323467230443975],
  ["33",0.6502850301781452,	0.9297040169133193 , 0.35517970401691334 ,0.9334038054968288, 0.928646934460888,  0.936046511627907,  0.9397463002114165, 0.9238900634249472, 0.9349894291754757],
  ["34",0.6830924656685201,	0.9307610993657506 , 0.3689217758985201  ,0.9339323467230444, 0.9297040169133193, 0.9392177589852009, 0.9423890063424947, 0.9275898520084567, 0.9371035940803383],
  ["35",0.6836285270456466,	0.9318181818181818 , 0.37790697674418605 ,0.936046511627907,  0.9328752642706131, 0.9418604651162791, 0.9439746300211417, 0.9291754756871036, 0.9381606765327696],
  ["36",0.6855635948083803,	0.9339323467230444 , 0.38636363636363635 ,0.9381606765327696, 0.9365750528541226, 0.9429175475687104, 0.945031712473573,  0.9318181818181818, 0.9402748414376322],
  ["37",0.692809209732302,	0.9349894291754757 , 0.39482029598308666 ,0.9397463002114165, 0.9371035940803383, 0.9455602536997886, 0.9487315010570825, 0.9328752642706131, 0.9423890063424947],
  ["38",0.6930185375143098,	0.9355179704016914 , 0.4096194503171247  ,0.9408033826638478, 0.9392177589852009, 0.9476744186046512, 0.9503171247357294, 0.9349894291754757, 0.9455602536997886],
  ["39",0.6957161171719507,	0.9386892177589852 , 0.41966173361522197 ,0.9418604651162791, 0.9408033826638478, 0.9513742071881607, 0.952431289640592,  0.9397463002114165, 0.9471458773784355],
  ["40",0.6960475569889985,	0.9423890063424947 , 0.4249471458773784  ,0.9439746300211417, 0.943446088794926,  0.9519027484143763, 0.9529598308668076, 0.9423890063424947, 0.9476744186046512],
  ["41",0.6990209025779494,	0.9460887949260042 , 0.4371035940803383  ,0.9439746300211417, 0.9471458773784355, 0.9529598308668076, 0.9561310782241015, 0.943446088794926,  0.9487315010570825],
  ["42",0.6991476708004586,	0.9476744186046512 , 0.44714587737843553 ,0.945031712473573,  0.9497885835095138, 0.9540169133192389, 0.9577167019027484, 0.9439746300211417, 0.9513742071881607],
  ["43",0.7004233636823871,	0.9482029598308668 , 0.4587737843551797  ,0.9455602536997886, 0.9513742071881607, 0.9561310782241015, 0.9582452431289641, 0.9466173361522199, 0.9534883720930233],
  ["44",0.7078387539605222,	0.9482029598308668 , 0.46247357293868924 ,0.9466173361522199, 0.952431289640592,  0.9593023255813954, 0.959830866807611,  0.9476744186046512, 0.9550739957716702],
  ["45",0.7110717941953182,	0.9497885835095138 , 0.47463002114164904 ,0.9471458773784355, 0.9545454545454546, 0.9603594080338267, 0.9614164904862579, 0.9476744186046512, 0.9577167019027484],
  ["46",0.7116278320584701,	0.9503171247357294 , 0.49207188160676535 ,0.9503171247357294, 0.9556025369978859, 0.9614164904862579, 0.9624735729386892, 0.9503171247357294, 0.9577167019027484],
  ["47",0.7163306226796772,	0.9529598308668076 , 0.5				 ,0.9545454545454546, 0.9571881606765328, 0.9624735729386892, 0.9645877378435518, 0.9540169133192389, 0.9577167019027484],
  ["48",0.7258852017301166,	0.9545454545454546 , 0.5052854122621564  ,0.9561310782241015, 0.9587737843551797, 0.9624735729386892, 0.9656448202959831, 0.9577167019027484, 0.959830866807611,],
  ["49",0.7274567371199355,	0.9561310782241015 , 0.5126849894291755  ,0.9587737843551797, 0.9608879492600423, 0.9630021141649049, 0.9667019027484144, 0.9587737843551797, 0.9608879492600423],
  ["50",0.7282135414128758,	0.9566596194503171 , 0.5406976744186046  ,0.9614164904862579, 0.9624735729386892, 0.9645877378435518, 0.9677589852008457, 0.959830866807611,  0.9640591966173362],
  ["51",0.7292344461974779,	0.9587737843551797 , 0.5650105708245243  ,0.9645877378435518, 0.9640591966173362, 0.9667019027484144, 0.9693446088794926, 0.9614164904862579, 0.9661733615221987],
  ["52",0.7324885645433244,	0.9608879492600423 , 0.5766384778012685  ,0.9682875264270613, 0.9640591966173362, 0.96723044397463,   0.9704016913319239, 0.9630021141649049, 0.9667019027484144],
  ["53",0.7324885645433244,	0.9619450317124736 , 0.5909090909090909  ,0.968816067653277,  0.9645877378435518, 0.9682875264270613, 0.9725158562367865, 0.9651162790697675, 0.9693446088794926],
  ["54",0.7342485000103758,	0.9635306553911205 , 0.5977801268498943  ,0.9693446088794926, 0.9667019027484144, 0.9693446088794926, 0.9735729386892178, 0.9651162790697675, 0.9719873150105708],
  ["55",0.7368389375828829,	0.9656448202959831 , 0.6226215644820295  ,0.9698731501057083, 0.9714587737843552, 0.9709302325581395, 0.9741014799154334, 0.9682875264270613, 0.9725158562367865],
  ["56",0.738917145452771,	0.96723044397463   , 0.6464059196617337  ,0.9704016913319239, 0.9725158562367865, 0.9714587737843552, 0.9746300211416491, 0.9693446088794926, 0.9741014799154334],
  ["57",0.7389640076390417,	0.9693446088794926 , 0.6612050739957717  ,0.9714587737843552, 0.9735729386892178, 0.9719873150105708, 0.9751585623678647, 0.9693446088794926, 0.9741014799154334],
  ["58",0.741168683265055,	0.9719873150105708 , 0.6749471458773785  ,0.9719873150105708, 0.9735729386892178, 0.9725158562367865, 0.9751585623678647, 0.9698731501057083, 0.9751585623678647],
  ["59",0.7412085862160748,	0.9730443974630021 , 0.6849894291754757  ,0.9730443974630021, 0.9741014799154334, 0.9741014799154334, 0.976215644820296,  0.9704016913319239, 0.9756871035940803],
  ["60",0.742256176364261,	0.9741014799154334 , 0.6881606765327696  ,0.9735729386892178, 0.9746300211416491, 0.9756871035940803, 0.9772727272727273, 0.9709302325581395, 0.976215644820296,],
  ["61",0.7427302053841868,	0.9746300211416491 , 0.6945031712473573  ,0.9746300211416491, 0.9751585623678647, 0.9767441860465116, 0.9772727272727273, 0.9709302325581395, 0.9772727272727273],
  ["62",0.7427302053841868,	0.9751585623678647 , 0.7045454545454546  ,0.9767441860465116, 0.976215644820296,  0.9778012684989429, 0.9793868921775899, 0.9709302325581395, 0.9783298097251586],
  ["63",0.7475364832577311,	0.9778012684989429 , 0.7151162790697675  ,0.9767441860465116, 0.9772727272727273, 0.9778012684989429, 0.9799154334038055, 0.9714587737843552, 0.9783298097251586],
  ["64",0.7476348638110273,	0.9793868921775899 , 0.7293868921775899  ,0.9778012684989429, 0.9788583509513742, 0.9778012684989429, 0.9825581395348837, 0.9719873150105708, 0.9788583509513742],
  ["65",0.9199060647976778,	0.9804439746300211 , 0.7457716701902748  ,0.9783298097251586, 0.9799154334038055, 0.9799154334038055, 0.983615221987315,  0.9725158562367865, 0.9793868921775899],
  ["66",0.9280681960471927,	0.9820295983086681 , 0.7579281183932347  ,0.9783298097251586, 0.9799154334038055, 0.9799154334038055, 0.9841437632135307, 0.9725158562367865, 0.9804439746300211],
  ["67",0.9280681960471927,	0.9830866807610994 , 0.7700845665961945  ,0.9788583509513742, 0.9815010570824524, 0.9815010570824524, 0.9862579281183932, 0.9730443974630021, 0.9809725158562368],
  ["68",0.9391788401810093,	0.9830866807610994 , 0.7769556025369979  ,0.9804439746300211, 0.983615221987315,  0.983615221987315,  0.9862579281183932, 0.9735729386892178, 0.9809725158562368],
  ["69",0.9391788401810093,	0.9830866807610994 , 0.7848837209302325  ,0.9809725158562368, 0.9841437632135307, 0.9846723044397463, 0.9867864693446089, 0.9735729386892178, 0.9815010570824524],
  ["70",0.9406843379710049,	0.9830866807610994 , 0.7986257928118393  ,0.9809725158562368, 0.9846723044397463, 0.985200845665962,  0.9867864693446089, 0.9741014799154334, 0.9825581395348837],
  ["71",0.9440975173942135,	0.9846723044397463 , 0.8134249471458774  ,0.9820295983086681, 0.9857293868921776, 0.9867864693446089, 0.9873150105708245, 0.9746300211416491, 0.9830866807610994],
  ["72",0.9459817678539045,	0.9846723044397463 , 0.8313953488372093  ,0.9825581395348837, 0.9857293868921776, 0.9873150105708245, 0.9883720930232558, 0.9746300211416491, 0.983615221987315,],
  ["73",0.9462637420339298,	0.9846723044397463 , 0.845137420718816   ,0.9825581395348837, 0.9857293868921776, 0.9878435517970402, 0.9894291754756871, 0.9751585623678647, 0.985200845665962,],
  ["74",0.9495168087798855,	0.9846723044397463 , 0.8551797040169133  ,0.9830866807610994, 0.9867864693446089, 0.9883720930232558, 0.9904862579281184, 0.976215644820296,  0.9867864693446089],
  ["75",0.9543103698403167,	0.9862579281183932 , 0.8636363636363636  ,0.9841437632135307, 0.9878435517970402, 0.9883720930232558, 0.9920718816067653, 0.9783298097251586, 0.9867864693446089],
  ["76",0.9667024642537535,	0.9862579281183932 , 0.8710359408033826  ,0.9846723044397463, 0.9883720930232558, 0.9899577167019028, 0.9920718816067653, 0.9804439746300211, 0.9867864693446089],
  ["77",0.9667024642537535,	0.9867864693446089 , 0.88107822410148    ,0.9846723044397463, 0.9883720930232558, 0.9899577167019028, 0.9920718816067653, 0.9815010570824524, 0.9867864693446089],
  ["78",0.9684293558790436,	0.9873150105708245 , 0.8916490486257929  ,0.9846723044397463, 0.9889006342494715, 0.9920718816067653, 0.992600422832981,  0.983615221987315,  0.9867864693446089],
  ["79",0.9684293558790436,	0.9883720930232558 , 0.8990486257928119  ,0.985200845665962,  0.9899577167019028, 0.9931289640591966, 0.9931289640591966, 0.985200845665962,  0.9878435517970402],
  ["80",0.9684293558790436,	0.9889006342494715 , 0.9064482029598309  ,0.9867864693446089, 0.9899577167019028, 0.9936575052854123, 0.9941860465116279, 0.9862579281183932, 0.9894291754756871],
  ["81",0.9684293558790436,	0.9894291754756871 , 0.9180761099365751  ,0.9867864693446089, 0.991014799154334,  0.9936575052854123, 0.9952431289640592, 0.9867864693446089, 0.9894291754756871],
  ["82",0.9684293558790436,	0.9904862579281184 , 0.9249471458773785  ,0.9878435517970402, 0.9915433403805497, 0.9936575052854123, 0.9957716701902748, 0.9867864693446089, 0.9904862579281184],
  ["83",0.9684293558790436,	0.9920718816067653 , 0.9312896405919662  ,0.9883720930232558, 0.992600422832981,  0.9941860465116279, 0.9963002114164905, 0.9873150105708245, 0.991014799154334,],
  ["84",0.9684293558790436,	0.9920718816067653 , 0.9376321353065539  ,0.9883720930232558, 0.992600422832981,  0.9952431289640592, 0.9963002114164905, 0.9878435517970402, 0.9936575052854123],
  ["85",0.9915321631110973,	0.9931289640591966 , 0.943446088794926	 ,0.9883720930232558, 0.992600422832981,  0.9952431289640592, 0.9963002114164905, 0.9878435517970402, 0.9941860465116279],
  ["86",0.9915321631110973,	0.9936575052854123 , 0.952431289640592	 ,0.9894291754756871, 0.9936575052854123, 0.9952431289640592, 0.9968287526427061, 0.9883720930232558, 0.9941860465116279],
  ["87",0.9915321631110973,	0.9936575052854123 , 0.9571881606765328  ,0.9904862579281184, 0.9952431289640592, 0.9952431289640592, 0.9973572938689218, 0.9904862579281184, 0.9947145877378436],
  ["88",0.9931150636868038,	0.9947145877378436 , 0.9608879492600423  ,0.9920718816067653, 0.9957716701902748, 0.9952431289640592, 0.9978858350951374, 0.9920718816067653, 0.9952431289640592],
  ["89",0.995117821304117,	0.9952431289640592 , 0.9645877378435518  ,0.9931289640591966, 0.9957716701902748, 0.9957716701902748, 0.9989429175475687, 0.992600422832981,  0.9957716701902748],
  ["90",0.995258357793032,	0.9957716701902748 , 0.9667019027484144  ,0.9936575052854123, 0.9963002114164905, 0.9957716701902748, 0.9989429175475687, 0.9936575052854123, 0.9963002114164905],
  ["91",0.995258357793032,	0.9968287526427061 , 0.9667019027484144  ,0.9941860465116279, 0.9963002114164905, 0.9957716701902748, 0.9994714587737844, 0.9947145877378436, 0.9968287526427061],
  ["92",0.9952742288541155,	0.9973572938689218 , 0.9698731501057083  ,0.9957716701902748, 0.9963002114164905, 0.9963002114164905, 0.9994714587737844, 0.9957716701902748, 0.9968287526427061],
  ["93",0.9952742288541155,	0.9978858350951374 , 0.9725158562367865  ,0.9957716701902748, 0.9963002114164905, 0.9963002114164905, 0.9994714587737844, 0.9957716701902748, 0.9973572938689218],
  ["94",0.9952742288541155,	0.9978858350951374 , 0.9746300211416491  ,0.9968287526427061, 0.9968287526427061, 0.9968287526427061, 0.9994714587737844, 0.9957716701902748, 0.9978858350951374],
  ["95",0.9952742288541155,	0.9989429175475687 , 0.9767441860465116  ,0.9978858350951374, 0.9968287526427061, 0.9973572938689218, 0.9994714587737844, 0.9973572938689218, 0.9984143763213531],
  ["96",0.9952742288541155,	0.9994714587737844 , 0.9788583509513742  ,0.9984143763213531, 0.9968287526427061, 0.9973572938689218, 0.9994714587737844, 0.9984143763213531, 0.9994714587737844],
  ["97",0.9952742288541155,	0.9994714587737844 , 0.9809725158562368  ,0.9984143763213531, 0.9978858350951374, 0.9978858350951374, 0.9994714587737844, 0.9984143763213531, 0.9994714587737844],
  ["98",0.9952742288541155,	0.9994714587737844 , 0.9862579281183932  ,0.9994714587737844, 0.9984143763213531, 0.9984143763213531, 0.9994714587737844, 0.9984143763213531, 1.0				],
  ["99",0.9952742288541155,	0.9994714587737844 , 0.9952431289640592  ,1.0				, 0.9984143763213531, 0.9989429175475687, 1.0				, 0.9989429175475687, 1.0				],
  ["100",1.0				,	1.0				   , 1.0				 ,1.0				, 1.0				, 1.0				, 1.0				, 1.0				, 1.0				]
];

export let recallRandomForestData = [
  ['Inspection Rate', 'Recall'],
  ["1",0.09460887949260043], 
  ["2",0.17970401691331925], 
  ["3",0.24682875264270612], 
  ["4",0.3144820295983087], 
  ["5",0.3726215644820296], 
  ["6",0.42441860465116277], 
  ["7",0.4571881606765328], 
  ["8",0.5190274841437632], 
  ["9",0.5687103594080338], 
  ["10",0.6035940803382663], 
  ["11",0.6247357293868921], 
  ["12",0.6495771670190275], 
  ["13",0.686046511627907], 
  ["14",0.7230443974630021], 
  ["15",0.7489429175475687], 
  ["16",0.7706131078224101], 
  ["17",0.7822410147991543], 
  ["18",0.7986257928118393], 
  ["19",0.813953488372093], 
  ["20",0.8324524312896406], 
  ["21",0.8488372093023255], 
  ["22",0.8652219873150105], 
  ["23",0.8805496828752643], 
  ["24",0.8937632135306554], 
  ["25",0.9016913319238901], 
  ["26",0.9080338266384778], 
  ["27",0.9164904862579282], 
  ["28",0.9186046511627907], 
  ["29",0.9201902748414377], 
  ["30",0.9254756871035941], 
  ["31",0.9281183932346723], 
  ["32",0.9291754756871036], 
  ["33",0.9297040169133193], 
  ["34",0.9307610993657506], 
  ["35",0.9318181818181818], 
  ["36",0.9339323467230444], 
  ["37",0.9349894291754757], 
  ["38",0.9355179704016914], 
  ["39",0.9386892177589852], 
  ["40",0.9423890063424947], 
  ["41",0.9460887949260042], 
  ["42",0.9476744186046512], 
  ["43",0.9482029598308668], 
  ["44",0.9482029598308668], 
  ["45",0.9497885835095138], 
  ["46",0.9503171247357294], 
  ["47",0.9529598308668076], 
  ["48",0.9545454545454546], 
  ["49",0.9561310782241015], 
  ["50",0.9566596194503171], 
  ["51",0.9587737843551797], 
  ["52",0.9608879492600423], 
  ["53",0.9619450317124736], 
  ["54",0.9635306553911205], 
  ["55",0.9656448202959831], 
  ["56",0.96723044397463], 
  ["57",0.9693446088794926], 
  ["58",0.9719873150105708], 
  ["59",0.9730443974630021], 
  ["60",0.9741014799154334], 
  ["61",0.9746300211416491], 
  ["62",0.9751585623678647], 
  ["63",0.9778012684989429], 
  ["64",0.9793868921775899], 
  ["65",0.9804439746300211], 
  ["66",0.9820295983086681], 
  ["67",0.9830866807610994], 
  ["68",0.9830866807610994], 
  ["69",0.9830866807610994], 
  ["70",0.9830866807610994], 
  ["71",0.9846723044397463], 
  ["72",0.9846723044397463], 
  ["73",0.9846723044397463], 
  ["74",0.9846723044397463], 
  ["75",0.9862579281183932], 
  ["76",0.9862579281183932], 
  ["77",0.9867864693446089], 
  ["78",0.9873150105708245], 
  ["79",0.9883720930232558], 
  ["80",0.9889006342494715], 
  ["81",0.9894291754756871], 
  ["82",0.9904862579281184], 
  ["83",0.9920718816067653], 
  ["84",0.9920718816067653], 
  ["85",0.9931289640591966], 
  ["86",0.9936575052854123], 
  ["87",0.9936575052854123], 
  ["88",0.9947145877378436], 
  ["89",0.9952431289640592], 
  ["90",0.9957716701902748], 
  ["91",0.9968287526427061], 
  ["92",0.9973572938689218], 
  ["93",0.9978858350951374], 
  ["94",0.9978858350951374], 
  ["95",0.9989429175475687], 
  ["96",0.9994714587737844], 
  ["97",0.9994714587737844], 
  ["98",0.9994714587737844], 
  ["99",0.9994714587737844], 
  ["100",1.0]
];

export let revenueRandomForestData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.004837770964571783], 
  ["2",0.017207327874826848], 
  ["3",0.029575315469817676], 
  ["4",0.04594598431126995], 
  ["5",0.17668999025811885], 
  ["6",0.19765696951098632], 
  ["7",0.27005654456894573], 
  ["8",0.2831603983578398], 
  ["9",0.2968476094299581], 
  ["10",0.32375359096781986], 
  ["11",0.3290804889672497], 
  ["12",0.367578859697009], 
  ["13",0.3818582732838411], 
  ["14",0.4001089104793376], 
  ["15",0.41373598824383095], 
  ["16",0.4212224067118753], 
  ["17",0.4273460175379536], 
  ["18",0.43250599745927787], 
  ["19",0.46834494947625094], 
  ["20",0.4823761259212855], 
  ["21",0.498939677875215], 
  ["22",0.5064964302001019], 
  ["23",0.5217170762829164], 
  ["24",0.5468997356631097], 
  ["25",0.5766888346714237], 
  ["26",0.5789350954746435], 
  ["27",0.6479648513327013], 
  ["28",0.6637548021938737], 
  ["29",0.6708496765034909], 
  ["30",0.711360551394611], 
  ["31",0.7113888704018791], 
  ["32",0.7113888704018791], 
  ["33",0.7156345815167614], 
  ["34",0.7157369436714969], 
  ["35",0.7159537945080098], 
  ["36",0.7215494734126962], 
  ["37",0.7330688894448066], 
  ["38",0.7331475692055545], 
  ["39",0.7347504820829489], 
  ["40",0.7444886539576381], 
  ["41",0.8722133686686063], 
  ["42",0.8857782444826261], 
  ["43",0.8859094249722139], 
  ["44",0.8859094249722139], 
  ["45",0.8891285899067488], 
  ["46",0.8915232223349511], 
  ["47",0.8927034187461695], 
  ["48",0.9062906216364487], 
  ["49",0.9108435618805615], 
  ["50",0.9108638203139725], 
  ["51",0.9165896097205668], 
  ["52",0.9166803446758446], 
  ["53",0.9264899917275685], 
  ["54",0.9354128329899742], 
  ["55",0.9536381471437071], 
  ["56",0.9620986823957709], 
  ["57",0.9636105749878395], 
  ["58",0.9752518269504644], 
  ["59",0.9799172585660182], 
  ["60",0.9823350027281119], 
  ["61",0.9837518090816701], 
  ["62",0.9878520732045624], 
  ["63",0.9886677604794057], 
  ["64",0.9901388508746042], 
  ["65",0.9902830138813781], 
  ["66",0.9941796236824795], 
  ["67",0.9942331230664876], 
  ["68",0.9942331230664876], 
  ["69",0.9942331230664876], 
  ["70",0.9942331230664876], 
  ["71",0.9944323547725339], 
  ["72",0.9944323547725339], 
  ["73",0.9944323547725339], 
  ["74",0.9944323547725339], 
  ["75",0.9944516858832888], 
  ["76",0.9944516858832888], 
  ["77",0.9944516858832888], 
  ["78",0.994453397863577], 
  ["79",0.9961837106074227], 
  ["80",0.9968494569420194], 
  ["81",0.9968494569420194], 
  ["82",0.9968494569420194], 
  ["83",0.9968494569420194], 
  ["84",0.9968494569420194], 
  ["85",0.9968494569420194], 
  ["86",0.9976205613968561], 
  ["87",0.9976205613968561], 
  ["88",0.9988874981426797], 
  ["89",0.9988874981426797], 
  ["90",0.9988874981426797], 
  ["91",0.9996135204499256], 
  ["92",0.9996135204499256], 
  ["93",0.9997797252029107], 
  ["94",0.9997797252029107], 
  ["95",0.9999350160815582], 
  ["96",0.9999857334975978], 
  ["97",0.9999857334975978], 
  ["98",0.9999857334975978], 
  ["99",0.9999857334975978], 
  ["100",1.0]
];

export let recallAdaBoostData = [
  ['Inspection Rate', 'Recall'],
  ["1",0.08456659619450317], 
  ["2",0.16913319238900634], 
  ["3",0.24154334038054967], 
  ["4",0.30761099365750527], 
  ["5",0.363107822410148], 
  ["6",0.4154334038054968], 
  ["7",0.46035940803382663], 
  ["8",0.4952431289640592], 
  ["9",0.5380549682875264], 
  ["10",0.5824524312896406], 
  ["11",0.620507399577167], 
  ["12",0.6490486257928119], 
  ["13",0.6775898520084567], 
  ["14",0.7087737843551797], 
  ["15",0.735200845665962], 
  ["16",0.757399577167019], 
  ["17",0.7780126849894292], 
  ["18",0.7996828752642706], 
  ["19",0.8218816067653277], 
  ["20",0.8319238900634249], 
  ["21",0.8419661733615222], 
  ["22",0.8578224101479915], 
  ["23",0.8768498942917548], 
  ["24",0.888477801268499], 
  ["25",0.8995771670190275], 
  ["26",0.9112050739957717], 
  ["27",0.9164904862579282], 
  ["28",0.9186046511627907], 
  ["29",0.9217758985200846], 
  ["30",0.9244186046511628], 
  ["31",0.9254756871035941], 
  ["32",0.9265327695560254], 
  ["33",0.928646934460888], 
  ["34",0.9297040169133193], 
  ["35",0.9328752642706131], 
  ["36",0.9365750528541226], 
  ["37",0.9371035940803383], 
  ["38",0.9392177589852009], 
  ["39",0.9408033826638478], 
  ["40",0.943446088794926], 
  ["41",0.9471458773784355], 
  ["42",0.9497885835095138], 
  ["43",0.9513742071881607], 
  ["44",0.952431289640592], 
  ["45",0.9545454545454546], 
  ["46",0.9556025369978859], 
  ["47",0.9571881606765328], 
  ["48",0.9587737843551797], 
  ["49",0.9608879492600423], 
  ["50",0.9624735729386892], 
  ["51",0.9640591966173362], 
  ["52",0.9640591966173362], 
  ["53",0.9645877378435518], 
  ["54",0.9667019027484144], 
  ["55",0.9714587737843552], 
  ["56",0.9725158562367865], 
  ["57",0.9735729386892178], 
  ["58",0.9735729386892178], 
  ["59",0.9741014799154334], 
  ["60",0.9746300211416491], 
  ["61",0.9751585623678647], 
  ["62",0.976215644820296], 
  ["63",0.9772727272727273], 
  ["64",0.9788583509513742], 
  ["65",0.9799154334038055], 
  ["66",0.9799154334038055], 
  ["67",0.9815010570824524], 
  ["68",0.983615221987315], 
  ["69",0.9841437632135307], 
  ["70",0.9846723044397463], 
  ["71",0.9857293868921776], 
  ["72",0.9857293868921776], 
  ["73",0.9857293868921776], 
  ["74",0.9867864693446089], 
  ["75",0.9878435517970402], 
  ["76",0.9883720930232558], 
  ["77",0.9883720930232558], 
  ["78",0.9889006342494715], 
  ["79",0.9899577167019028], 
  ["80",0.9899577167019028], 
  ["81",0.991014799154334], 
  ["82",0.9915433403805497], 
  ["83",0.992600422832981], 
  ["84",0.992600422832981], 
  ["85",0.992600422832981], 
  ["86",0.9936575052854123], 
  ["87",0.9952431289640592], 
  ["88",0.9957716701902748], 
  ["89",0.9957716701902748], 
  ["90",0.9963002114164905], 
  ["91",0.9963002114164905], 
  ["92",0.9963002114164905], 
  ["93",0.9963002114164905], 
  ["94",0.9968287526427061], 
  ["95",0.9968287526427061], 
  ["96",0.9968287526427061], 
  ["97",0.9978858350951374], 
  ["98",0.9984143763213531], 
  ["99",0.9984143763213531], 
  ["100",1.0]
];

export let revenueAdaBoostData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.0640703629605076], 
  ["2",0.07946284906475588], 
  ["3",0.09400911757902017], 
  ["4",0.10778770559902436], 
  ["5",0.11571303301347115], 
  ["6",0.13549047129604674], 
  ["7",0.1933037603005039], 
  ["8",0.28803704554145754], 
  ["9",0.3167836912192457], 
  ["10",0.35147397579530903], 
  ["11",0.37062347397465356], 
  ["12",0.3806686609810373], 
  ["13",0.4134373903485539], 
  ["14",0.4207776484994887], 
  ["15",0.42880640805635095], 
  ["16",0.43525907576033473], 
  ["17",0.47036237558094063], 
  ["18",0.4971875373826942], 
  ["19",0.516047782225835], 
  ["20",0.5185903582839476], 
  ["21",0.5213470745431166], 
  ["22",0.5858050583738907], 
  ["23",0.6085204688286543], 
  ["24",0.626911345742764], 
  ["25",0.629476106212112], 
  ["26",0.662635880410472], 
  ["27",0.6642280220785527], 
  ["28",0.6658146711432087], 
  ["29",0.6666280044451558], 
  ["30",0.6667101794989923], 
  ["31",0.6672871881886475], 
  ["32",0.67169325479053], 
  ["33",0.6828446663931768], 
  ["34",0.6832787247287625], 
  ["35",0.7049999026311202], 
  ["36",0.7303594666411003], 
  ["37",0.7320195881931273], 
  ["38",0.7405472473389932], 
  ["39",0.746613792155463], 
  ["40",0.7467385527189699], 
  ["41",0.7649053028803419], 
  ["42",0.7755780733273963], 
  ["43",0.7770899659194649], 
  ["44",0.7770899659194649], 
  ["45",0.7791238698344286], 
  ["46",0.7832241339573208], 
  ["47",0.7833553144469086], 
  ["48",0.7836883659454871], 
  ["49",0.7873966579148799], 
  ["50",0.7873966579148799], 
  ["51",0.7873966579148799], 
  ["52",0.7873966579148799], 
  ["53",0.7873966579148799], 
  ["54",0.7880994258232102], 
  ["55",0.7903675857076172], 
  ["56",0.7903675857076172], 
  ["57",0.8078588169802471], 
  ["58",0.8078588169802471], 
  ["59",0.8082539990967869], 
  ["60",0.8180636461485108], 
  ["61",0.818065358128799], 
  ["62",0.8181281307393684], 
  ["63",0.8290626201729904], 
  ["64",0.830329556918814], 
  ["65",0.8303473900468167], 
  ["66",0.8303473900468167], 
  ["67",0.8367565449184742], 
  ["68",0.837548264469282], 
  ["69",0.8383193689241185], 
  ["70",0.8384635319308924], 
  ["71",0.8451018781636406], 
  ["72",0.8451018781636406], 
  ["73",0.8451018781636406], 
  ["74",0.8468321909074863], 
  ["75",0.854492732037349], 
  ["76",0.854512063148104], 
  ["77",0.854512063148104], 
  ["78",0.8547433231520429], 
  ["79",0.8580558623448], 
  ["80",0.8580558623448], 
  ["81",0.8580558623448], 
  ["82",0.8580558623448], 
  ["83",0.869776364728269], 
  ["84",0.869776364728269], 
  ["85",0.869776364728269], 
  ["86",0.8697906312306712], 
  ["87",0.877972613023333], 
  ["88",0.8838614685523837], 
  ["89",0.8838614685523837], 
  ["90",0.8838614685523837], 
  ["91",0.8838614685523837], 
  ["92",0.8838614685523837], 
  ["93",0.8838614685523837], 
  ["94",0.8840094121822941], 
  ["95",0.8840094121822941], 
  ["96",0.8840094121822941], 
  ["97",0.8842263343513189], 
  ["98",0.8853742171345965], 
  ["99",0.8853742171345965], 
  ["100",0.9999999999999994]
];

export let recallXGBoostData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.09619450317124736], 
  ["2",0.1839323467230444], 
  ["3",0.2616279069767442], 
  ["4",0.33509513742071884], 
  ["5",0.39904862579281186], 
  ["6",0.4619450317124736], 
  ["7",0.5110993657505285], 
  ["8",0.5586680761099366], 
  ["9",0.5924947145877378], 
  ["10",0.629492600422833], 
  ["11",0.6627906976744186], 
  ["12",0.6897463002114165], 
  ["13",0.7103594080338267], 
  ["14",0.7325581395348837], 
  ["15",0.7595137420718816], 
  ["16",0.7785412262156448], 
  ["17",0.7928118393234672], 
  ["18",0.8054968287526427], 
  ["19",0.8208245243128964], 
  ["20",0.837737843551797], 
  ["21",0.8535940803382663], 
  ["22",0.8678646934460887], 
  ["23",0.8747357293868921], 
  ["24",0.8858350951374208], 
  ["25",0.8974630021141649], 
  ["26",0.9075052854122622], 
  ["27",0.9154334038054969], 
  ["28",0.9201902748414377], 
  ["29",0.9249471458773785], 
  ["30",0.9281183932346723], 
  ["31",0.9307610993657506], 
  ["32",0.9334038054968288], 
  ["33",0.936046511627907], 
  ["34",0.9392177589852009], 
  ["35",0.9418604651162791], 
  ["36",0.9429175475687104], 
  ["37",0.9455602536997886], 
  ["38",0.9476744186046512], 
  ["39",0.9513742071881607], 
  ["40",0.9519027484143763], 
  ["41",0.9529598308668076], 
  ["42",0.9540169133192389], 
  ["43",0.9561310782241015], 
  ["44",0.9593023255813954], 
  ["45",0.9603594080338267], 
  ["46",0.9614164904862579], 
  ["47",0.9624735729386892], 
  ["48",0.9624735729386892], 
  ["49",0.9630021141649049], 
  ["50",0.9645877378435518], 
  ["51",0.9667019027484144], 
  ["52",0.96723044397463], 
  ["53",0.9682875264270613], 
  ["54",0.9693446088794926], 
  ["55",0.9709302325581395], 
  ["56",0.9714587737843552], 
  ["57",0.9719873150105708], 
  ["58",0.9725158562367865], 
  ["59",0.9741014799154334], 
  ["60",0.9756871035940803], 
  ["61",0.9767441860465116], 
  ["62",0.9778012684989429], 
  ["63",0.9778012684989429], 
  ["64",0.9778012684989429], 
  ["65",0.9799154334038055], 
  ["66",0.9799154334038055], 
  ["67",0.9815010570824524], 
  ["68",0.983615221987315], 
  ["69",0.9846723044397463], 
  ["70",0.985200845665962], 
  ["71",0.9867864693446089], 
  ["72",0.9873150105708245], 
  ["73",0.9878435517970402], 
  ["74",0.9883720930232558], 
  ["75",0.9883720930232558], 
  ["76",0.9899577167019028], 
  ["77",0.9899577167019028], 
  ["78",0.9920718816067653], 
  ["79",0.9931289640591966], 
  ["80",0.9936575052854123], 
  ["81",0.9936575052854123], 
  ["82",0.9936575052854123], 
  ["83",0.9941860465116279], 
  ["84",0.9952431289640592], 
  ["85",0.9952431289640592], 
  ["86",0.9952431289640592], 
  ["87",0.9952431289640592], 
  ["88",0.9952431289640592], 
  ["89",0.9957716701902748], 
  ["90",0.9957716701902748], 
  ["91",0.9957716701902748], 
  ["92",0.9963002114164905], 
  ["93",0.9963002114164905], 
  ["94",0.9968287526427061], 
  ["95",0.9973572938689218], 
  ["96",0.9973572938689218], 
  ["97",0.9978858350951374], 
  ["98",0.9984143763213531], 
  ["99",0.9989429175475687], 
  ["100",1.0]
];

export let revenueXGBoostData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.0056900518180767], 
  ["2",0.019963758803947795], 
  ["3",0.03289691389160012], 
  ["4",0.046618007906923636], 
  ["5",0.08631084155030372], 
  ["6",0.11258453170170196], 
  ["7",0.13956940699784776], 
  ["8",0.23900272012268042], 
  ["9",0.2585728661253527], 
  ["10",0.3047760041424217], 
  ["11",0.3143395540277081], 
  ["12",0.3275943474120174], 
  ["13",0.33240651000477717], 
  ["14",0.37221868294075117], 
  ["15",0.40297747611733975], 
  ["16",0.4107630631407573], 
  ["17",0.4248468829796557], 
  ["18",0.43082625946644815], 
  ["19",0.4368893803223411], 
  ["20",0.44606737798020957], 
  ["21",0.4846280933254665], 
  ["22",0.493590381467014], 
  ["23",0.4967971345444592], 
  ["24",0.5086543100209406], 
  ["25",0.560056518175916], 
  ["26",0.5872226493850812], 
  ["27",0.6018005182021663], 
  ["28",0.6159223582699666], 
  ["29",0.6400644189649464], 
  ["30",0.6420130805280571], 
  ["31",0.6573323794749824], 
  ["32",0.6694947867703581], 
  ["33",0.7317075797855925], 
  ["34",0.7601361537923246], 
  ["35",0.7606711476324055], 
  ["36",0.7644308703429583], 
  ["37",0.777353753548881], 
  ["38",0.7789416152662413], 
  ["39",0.7808210842927017], 
  ["40",0.786389656842824], 
  ["41",0.7863902988354322], 
  ["42",0.786607149671945], 
  ["43",0.786607149671945], 
  ["44",0.7867668631663373], 
  ["45",0.7868455429270851], 
  ["46",0.787558868047193], 
  ["47",0.7976959313290465], 
  ["48",0.7976959313290465], 
  ["49",0.8021181904111554], 
  ["50",0.8087743697719063], 
  ["51",0.8152837468229389], 
  ["52",0.815337246206947], 
  ["53",0.8200428380267627], 
  ["54",0.8259794150063487], 
  ["55",0.8261767920670826], 
  ["56",0.8261767920670826], 
  ["57",0.8261767920670826], 
  ["58",0.8261767920670826], 
  ["59",0.8403786672133587], 
  ["60",0.8520199191759836], 
  ["61",0.8520199191759836], 
  ["62",0.8520199191759836], 
  ["63",0.8520199191759836], 
  ["64",0.8520199191759836], 
  ["65",0.8537502319198293], 
  ["66",0.8537502319198293], 
  ["67",0.8562928079779419], 
  ["68",0.8597496528424969], 
  ["69",0.9749444551562099], 
  ["70",0.9766881784123136], 
  ["71",0.9928910731855166], 
  ["72",0.9928910731855166], 
  ["73",0.9931988729748432], 
  ["74",0.9946156793284014], 
  ["75",0.9946156793284014], 
  ["76",0.9965025669361108], 
  ["77",0.9965025669361108], 
  ["78",0.9973456458955663], 
  ["79",0.9973649770063212], 
  ["80",0.9973649770063212], 
  ["81",0.9973649770063212], 
  ["82",0.9973649770063212], 
  ["83",0.9975091400130951], 
  ["84",0.9976644308917426], 
  ["85",0.9976644308917426], 
  ["86",0.9976644308917426], 
  ["87",0.9976644308917426], 
  ["88",0.9976644308917426], 
  ["89",0.9986793498726321], 
  ["90",0.9986793498726321], 
  ["91",0.9986793498726321], 
  ["92",0.9987827820150478], 
  ["93",0.9987827820150478], 
  ["94",0.9994279845861853], 
  ["95",0.9994633655121427], 
  ["96",0.9994633655121427], 
  ["97",0.9994633655121427], 
  ["98",0.9994633655121427], 
  ["99",0.9998585476286824], 
  ["100",0.9999999999999999]
];

export let recallLightGBMData = [
  ['Inspection Rate', 'Recall'],
  ["1",0.09355179704016914], 
  ["2",0.17124735729386892], 
  ["3",0.2521141649048626], 
  ["4",0.32716701902748413], 
  ["5",0.3905919661733615], 
  ["6",0.4450317124735729], 
  ["7",0.4952431289640592], 
  ["8",0.5354122621564482], 
  ["9",0.5681818181818182], 
  ["10",0.6020084566596194], 
  ["11",0.6411205073995772], 
  ["12",0.6733615221987315], 
  ["13",0.7061310782241015], 
  ["14",0.7272727272727273], 
  ["15",0.7484143763213531], 
  ["16",0.7669133192389006], 
  ["17",0.7906976744186046], 
  ["18",0.804968287526427], 
  ["19",0.8202959830866807], 
  ["20",0.8366807610993657], 
  ["21",0.8472515856236786], 
  ["22",0.864693446088795], 
  ["23",0.8773784355179705], 
  ["24",0.8911205073995772], 
  ["25",0.9038054968287527], 
  ["26",0.9149048625792812], 
  ["27",0.9201902748414377], 
  ["28",0.9249471458773785], 
  ["29",0.9275898520084567], 
  ["30",0.9302325581395349], 
  ["31",0.936046511627907], 
  ["32",0.9386892177589852], 
  ["33",0.9397463002114165], 
  ["34",0.9423890063424947], 
  ["35",0.9439746300211417], 
  ["36",0.945031712473573], 
  ["37",0.9487315010570825], 
  ["38",0.9503171247357294], 
  ["39",0.952431289640592], 
  ["40",0.9529598308668076], 
  ["41",0.9561310782241015], 
  ["42",0.9577167019027484], 
  ["43",0.9582452431289641], 
  ["44",0.959830866807611], 
  ["45",0.9614164904862579], 
  ["46",0.9624735729386892], 
  ["47",0.9645877378435518], 
  ["48",0.9656448202959831], 
  ["49",0.9667019027484144], 
  ["50",0.9677589852008457], 
  ["51",0.9693446088794926], 
  ["52",0.9704016913319239], 
  ["53",0.9725158562367865], 
  ["54",0.9735729386892178], 
  ["55",0.9741014799154334], 
  ["56",0.9746300211416491], 
  ["57",0.9751585623678647], 
  ["58",0.9751585623678647], 
  ["59",0.976215644820296], 
  ["60",0.9772727272727273], 
  ["61",0.9772727272727273], 
  ["62",0.9793868921775899], 
  ["63",0.9799154334038055], 
  ["64",0.9825581395348837], 
  ["65",0.983615221987315], 
  ["66",0.9841437632135307], 
  ["67",0.9862579281183932], 
  ["68",0.9862579281183932], 
  ["69",0.9867864693446089], 
  ["70",0.9867864693446089], 
  ["71",0.9873150105708245], 
  ["72",0.9883720930232558], 
  ["73",0.9894291754756871], 
  ["74",0.9904862579281184], 
  ["75",0.9920718816067653], 
  ["76",0.9920718816067653], 
  ["77",0.9920718816067653], 
  ["78",0.992600422832981], 
  ["79",0.9931289640591966], 
  ["80",0.9941860465116279], 
  ["81",0.9952431289640592], 
  ["82",0.9957716701902748], 
  ["83",0.9963002114164905], 
  ["84",0.9963002114164905], 
  ["85",0.9963002114164905], 
  ["86",0.9968287526427061], 
  ["87",0.9973572938689218], 
  ["88",0.9978858350951374], 
  ["89",0.9989429175475687], 
  ["90",0.9989429175475687], 
  ["91",0.9994714587737844], 
  ["92",0.9994714587737844], 
  ["93",0.9994714587737844], 
  ["94",0.9994714587737844], 
  ["95",0.9994714587737844], 
  ["96",0.9994714587737844], 
  ["97",0.9994714587737844], 
  ["98",0.9994714587737844], 
  ["99",1.0], 
  ["100",1.0]
];

export let revenueLightGBMData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.019187589740758383], 
  ["2",0.025326251726870933], 
  ["3",0.03998201564707182], 
  ["4",0.05489885656123223], 
  ["5",0.08894529388388626], 
  ["6",0.10647575803812197], 
  ["7",0.15353609885202452], 
  ["8",0.23052749037314244], 
  ["9",0.2964895915521761], 
  ["10",0.31004547946968253], 
  ["11",0.3444955161452937], 
  ["12",0.35361395115563315], 
  ["13",0.37430765555498724], 
  ["14",0.41347733655528], 
  ["15",0.41975852090039006], 
  ["16",0.4339719519136121], 
  ["17",0.45640937956906985], 
  ["18",0.4679888575762934], 
  ["19",0.4772079427610799], 
  ["20",0.4882455790070692], 
  ["21",0.4907071926640495], 
  ["22",0.5274430796870462], 
  ["23",0.5643716358249844], 
  ["24",0.5718590529481969], 
  ["25",0.5904166339428761], 
  ["26",0.62120966672523], 
  ["27",0.6546413604593749], 
  ["28",0.6672922527970007], 
  ["29",0.6846754866464461], 
  ["30",0.6948922996767274], 
  ["31",0.7401629790967912], 
  ["32",0.7552943882070802], 
  ["33",0.7564971256920942], 
  ["34",0.7618562659869528], 
  ["35",0.7621836822170823], 
  ["36",0.7641975416961709], 
  ["37",0.7719342659488613], 
  ["38",0.7734461585409299], 
  ["39",0.7786355274572029], 
  ["40",0.7787142072179507], 
  ["41",0.7804579304740545], 
  ["42",0.7880629749095728], 
  ["43",0.7880629749095728], 
  ["44",0.7881664070519885], 
  ["45",0.7897454235378594], 
  ["46",0.7992996288640726], 
  ["47",0.8157618886509469], 
  ["48",0.8169276045622272], 
  ["49",0.8179425235431167], 
  ["50",0.818519532232772], 
  ["51",0.8205575734334323], 
  ["52",0.824317296143985], 
  ["53",0.8404151894595081], 
  ["54",0.8429248098970717], 
  ["55",0.8430418665492814], 
  ["56",0.8430418665492814], 
  ["57",0.8430418665492814], 
  ["58",0.8430418665492814], 
  ["59",0.8452107315769696], 
  ["60",0.8513076640435557], 
  ["61",0.8513076640435557], 
  ["62",0.8621061937092852], 
  ["63",0.8621061937092852], 
  ["64",0.9885842301078408], 
  ["65",0.9885842301078408], 
  ["66",0.9885842301078408], 
  ["67",0.9885842301078408], 
  ["68",0.9885842301078408], 
  ["69",0.9885842301078408], 
  ["70",0.9885842301078408], 
  ["71",0.9886198963638462], 
  ["72",0.9900592437911999], 
  ["73",0.9944815028733089], 
  ["74",0.9956605579643352], 
  ["75",0.9963716004440588], 
  ["76",0.9963716004440588], 
  ["77",0.9963716004440588], 
  ["78",0.9963909315548137], 
  ["79",0.9965350945615876], 
  ["80",0.9974115571366642], 
  ["81",0.9974115571366642], 
  ["82",0.997502292091942], 
  ["83",0.997502292091942], 
  ["84",0.997502292091942], 
  ["85",0.997502292091942], 
  ["86",0.9992326048357877], 
  ["87",0.9993878957144351], 
  ["88",0.9993878957144351], 
  ["89",0.9995541004674203], 
  ["90",0.9995541004674203], 
  ["91",0.9999492825839601], 
  ["92",0.9999492825839601], 
  ["93",0.9999492825839601], 
  ["94",0.9999492825839601], 
  ["95",0.9999492825839601], 
  ["96",0.9999492825839601], 
  ["97",0.9999492825839601], 
  ["98",0.9999492825839601], 
  ["99",0.9999999999999998], 
  ["100",0.9999999999999998]
];

export let recallCatBoostData = [
  ['Inspection Rate', 'Recall'],
  ["1",0.0919661733615222], 
  ["2",0.17230443974630022], 
  ["3",0.2489429175475687], 
  ["4",0.3150105708245243], 
  ["5",0.38054968287526425], 
  ["6",0.44238900634249473], 
  ["7",0.4904862579281184], 
  ["8",0.5406976744186046], 
  ["9",0.578752642706131], 
  ["10",0.6067653276955602], 
  ["11",0.6358350951374208], 
  ["12",0.6643763213530656], 
  ["13",0.6929175475687104], 
  ["14",0.7140591966173362], 
  ["15",0.7362579281183932], 
  ["16",0.7595137420718816], 
  ["17",0.7743128964059197], 
  ["18",0.7933403805496829], 
  ["19",0.8102536997885835], 
  ["20",0.830338266384778], 
  ["21",0.8414376321353065], 
  ["22",0.8588794926004228], 
  ["23",0.8710359408033826], 
  ["24",0.8816067653276956], 
  ["25",0.8921775898520085], 
  ["26",0.9022198731501057], 
  ["27",0.9085623678646935], 
  ["28",0.912262156448203], 
  ["29",0.9143763213530656], 
  ["30",0.9191331923890064], 
  ["31",0.9201902748414377], 
  ["32",0.9217758985200846], 
  ["33",0.9238900634249472], 
  ["34",0.9275898520084567], 
  ["35",0.9291754756871036], 
  ["36",0.9318181818181818], 
  ["37",0.9328752642706131], 
  ["38",0.9349894291754757], 
  ["39",0.9397463002114165], 
  ["40",0.9423890063424947], 
  ["41",0.943446088794926], 
  ["42",0.9439746300211417], 
  ["43",0.9466173361522199], 
  ["44",0.9476744186046512], 
  ["45",0.9476744186046512], 
  ["46",0.9503171247357294], 
  ["47",0.9540169133192389], 
  ["48",0.9577167019027484], 
  ["49",0.9587737843551797], 
  ["50",0.959830866807611], 
  ["51",0.9614164904862579], 
  ["52",0.9630021141649049], 
  ["53",0.9651162790697675], 
  ["54",0.9651162790697675], 
  ["55",0.9682875264270613], 
  ["56",0.9693446088794926], 
  ["57",0.9693446088794926], 
  ["58",0.9698731501057083], 
  ["59",0.9704016913319239], 
  ["60",0.9709302325581395], 
  ["61",0.9709302325581395], 
  ["62",0.9709302325581395], 
  ["63",0.9714587737843552], 
  ["64",0.9719873150105708], 
  ["65",0.9725158562367865], 
  ["66",0.9725158562367865], 
  ["67",0.9730443974630021], 
  ["68",0.9735729386892178], 
  ["69",0.9735729386892178], 
  ["70",0.9741014799154334], 
  ["71",0.9746300211416491], 
  ["72",0.9746300211416491], 
  ["73",0.9751585623678647], 
  ["74",0.976215644820296], 
  ["75",0.9783298097251586], 
  ["76",0.9804439746300211], 
  ["77",0.9815010570824524], 
  ["78",0.983615221987315], 
  ["79",0.985200845665962], 
  ["80",0.9862579281183932], 
  ["81",0.9867864693446089], 
  ["82",0.9867864693446089], 
  ["83",0.9873150105708245], 
  ["84",0.9878435517970402], 
  ["85",0.9878435517970402], 
  ["86",0.9883720930232558], 
  ["87",0.9904862579281184], 
  ["88",0.9920718816067653], 
  ["89",0.992600422832981], 
  ["90",0.9936575052854123], 
  ["91",0.9947145877378436], 
  ["92",0.9957716701902748], 
  ["93",0.9957716701902748], 
  ["94",0.9957716701902748], 
  ["95",0.9973572938689218], 
  ["96",0.9984143763213531], 
  ["97",0.9984143763213531], 
  ["98",0.9984143763213531], 
  ["99",0.9989429175475687], 
  ["100",1.0]
];

export let revenueCatBoostData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.021069412740115055], 
  ["2",0.028378141920740597], 
  ["3",0.039521635614554206], 
  ["4",0.09212593926193535], 
  ["5",0.1291009328651923], 
  ["6",0.14580137190967055], 
  ["7",0.16396605342819418], 
  ["8",0.226187335012358], 
  ["9",0.24521335661341195], 
  ["10",0.32552698854892054], 
  ["11",0.3398664641108657], 
  ["12",0.352039285952995], 
  ["13",0.36419541598731353], 
  ["14",0.3722325927805933], 
  ["15",0.3906683378447578], 
  ["16",0.40193488012178985], 
  ["17",0.4156223051914444], 
  ["18",0.4361445975644368], 
  ["19",0.48743502767309105], 
  ["20",0.5128634988896733], 
  ["21",0.5176895713222753], 
  ["22",0.5304807033785142], 
  ["23",0.5343917223470416], 
  ["24",0.5590129948577098], 
  ["25",0.5759440519135187], 
  ["26",0.6108688777890737], 
  ["27",0.6156326769386904], 
  ["28",0.6182867457130758], 
  ["29",0.6201427463430846], 
  ["30",0.6679030730973491], 
  ["31",0.6679277541465047], 
  ["32",0.6972481272183959], 
  ["33",0.7044643381309433], 
  ["34",0.7219472948321799], 
  ["35",0.7275158673823022], 
  ["36",0.7360543690699939], 
  ["37",0.736399475763102], 
  ["38",0.7371434738633746], 
  ["39",0.7471947241332791], 
  ["40",0.7498743299469645], 
  ["41",0.7498961576956398], 
  ["42",0.7498961576956398], 
  ["43",0.7656808299509235], 
  ["44",0.7656808299509235], 
  ["45",0.7656808299509235], 
  ["46",0.7658478906940526], 
  ["47",0.7779897542259693], 
  ["48",0.7873929486242556], 
  ["49",0.7876579489063757], 
  ["50",0.7876579489063757], 
  ["51",0.8069954795873808], 
  ["52",0.8229807388664389], 
  ["53",0.8302864007490479], 
  ["54",0.8302864007490479], 
  ["55",0.8356954738023141], 
  ["56",0.8373709318444235], 
  ["57",0.8373709318444235], 
  ["58",0.8373709318444235], 
  ["59",0.8490121838070483], 
  ["60",0.8507424965508941], 
  ["61",0.8507424965508941], 
  ["62",0.8507424965508941], 
  ["63",0.8520094332967177], 
  ["64",0.8520094332967177], 
  ["65",0.8520094332967177], 
  ["66",0.8520094332967177], 
  ["67",0.8520094332967177], 
  ["68",0.8521535963034916], 
  ["69",0.8521535963034916], 
  ["70",0.8521535963034916], 
  ["71",0.8565758553856005], 
  ["72",0.8565758553856005], 
  ["73",0.8572210579567382], 
  ["74",0.8596356634883034], 
  ["75",0.9741724233787168], 
  ["76",0.9744099606437128], 
  ["77",0.9745133927861285], 
  ["78",0.9752287865490846], 
  ["79",0.9755290250921381], 
  ["80",0.9763057648154235], 
  ["81",0.9763057648154235], 
  ["82",0.9763057648154235], 
  ["83",0.9768407586555045], 
  ["84",0.9768600897662595], 
  ["85",0.9768600897662595], 
  ["86",0.977187505996389], 
  ["87",0.9819607210375909], 
  ["88",0.9819607210375909], 
  ["89",0.9819607210375909], 
  ["90",0.9927399195925654], 
  ["91",0.9937548385734548], 
  ["92",0.9952667311655236], 
  ["93",0.9952667311655236], 
  ["94",0.9952667311655236], 
  ["95",0.9968055874471323], 
  ["96",0.9970663791110439], 
  ["97",0.9970663791110439], 
  ["98",0.9970663791110439], 
  ["99",0.999235244138732], 
  ["100",0.9999999999999998]
];

export let recallNaiveEnsembleData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.09408033826638477], 
  ["2",0.17389006342494714], 
  ["3",0.2521141649048626], 
  ["4",0.322938689217759], 
  ["5",0.3927061310782241], 
  ["6",0.44820295983086683], 
  ["7",0.4978858350951374], 
  ["8",0.5417547568710359], 
  ["9",0.578752642706131], 
  ["10",0.6199788583509513], 
  ["11",0.6479915433403806], 
  ["12",0.6701902748414377], 
  ["13",0.6966173361522199], 
  ["14",0.7182875264270613], 
  ["15",0.7441860465116279], 
  ["16",0.7642706131078224], 
  ["17",0.7790697674418605], 
  ["18",0.7980972515856237], 
  ["19",0.8102536997885835], 
  ["20",0.8292811839323467], 
  ["21",0.8477801268498943], 
  ["22",0.8620507399577167], 
  ["23",0.8789640591966174], 
  ["24",0.8895348837209303], 
  ["25",0.9043340380549683], 
  ["26",0.9143763213530656], 
  ["27",0.9201902748414377], 
  ["28",0.9238900634249472], 
  ["29",0.9249471458773785], 
  ["30",0.9275898520084567], 
  ["31",0.9302325581395349], 
  ["32",0.9323467230443975], 
  ["33",0.9349894291754757], 
  ["34",0.9371035940803383], 
  ["35",0.9381606765327696], 
  ["36",0.9402748414376322], 
  ["37",0.9423890063424947], 
  ["38",0.9455602536997886], 
  ["39",0.9471458773784355], 
  ["40",0.9476744186046512], 
  ["41",0.9487315010570825], 
  ["42",0.9513742071881607], 
  ["43",0.9534883720930233], 
  ["44",0.9550739957716702], 
  ["45",0.9577167019027484], 
  ["46",0.9577167019027484], 
  ["47",0.9577167019027484], 
  ["48",0.959830866807611], 
  ["49",0.9608879492600423], 
  ["50",0.9640591966173362], 
  ["51",0.9661733615221987], 
  ["52",0.9667019027484144], 
  ["53",0.9693446088794926], 
  ["54",0.9719873150105708], 
  ["55",0.9725158562367865], 
  ["56",0.9741014799154334], 
  ["57",0.9741014799154334], 
  ["58",0.9751585623678647], 
  ["59",0.9756871035940803], 
  ["60",0.976215644820296], 
  ["61",0.9772727272727273], 
  ["62",0.9783298097251586], 
  ["63",0.9783298097251586], 
  ["64",0.9788583509513742], 
  ["65",0.9793868921775899], 
  ["66",0.9804439746300211], 
  ["67",0.9809725158562368], 
  ["68",0.9809725158562368], 
  ["69",0.9815010570824524], 
  ["70",0.9825581395348837], 
  ["71",0.9830866807610994], 
  ["72",0.983615221987315], 
  ["73",0.985200845665962], 
  ["74",0.9867864693446089], 
  ["75",0.9867864693446089], 
  ["76",0.9867864693446089], 
  ["77",0.9867864693446089], 
  ["78",0.9867864693446089], 
  ["79",0.9878435517970402], 
  ["80",0.9894291754756871], 
  ["81",0.9894291754756871], 
  ["82",0.9904862579281184], 
  ["83",0.991014799154334], 
  ["84",0.9936575052854123], 
  ["85",0.9941860465116279], 
  ["86",0.9941860465116279], 
  ["87",0.9947145877378436], 
  ["88",0.9952431289640592], 
  ["89",0.9957716701902748], 
  ["90",0.9963002114164905], 
  ["91",0.9968287526427061], 
  ["92",0.9968287526427061], 
  ["93",0.9973572938689218], 
  ["94",0.9978858350951374], 
  ["95",0.9984143763213531], 
  ["96",0.9994714587737844], 
  ["97",0.9994714587737844], 
  ["98",1.0], 
  ["99",1.0], 
  ["100",1.0]
];

export let revenueNaiveEnsembleData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.019129311078445583], 
  ["2",0.027269420686556883], 
  ["3",0.03730911508951553], 
  ["4",0.052534754448170635], 
  ["5",0.11330013946219425], 
  ["6",0.15278161487248637], 
  ["7",0.19943022442706257], 
  ["8",0.23608942899964777], 
  ["9",0.29021183116764254], 
  ["10",0.30649975429516235], 
  ["11",0.351902327529934], 
  ["12",0.3626616956465695], 
  ["13",0.3739060535123665], 
  ["14",0.38597301790667177], 
  ["15",0.4175611942004097], 
  ["16",0.43680777592860104], 
  ["17",0.441183454880367], 
  ["18",0.47131873056946916], 
  ["19",0.4776086174810444], 
  ["20",0.49011862810744855], 
  ["21",0.5190366858115989], 
  ["22",0.5235033137161785], 
  ["23",0.5491085468975239], 
  ["24",0.5738556499593791], 
  ["25",0.5860722699638831], 
  ["26",0.6057636099044619], 
  ["27",0.6186931983665136], 
  ["28",0.6629324096629013], 
  ["29",0.6671837560462325], 
  ["30",0.6852634805429513], 
  ["31",0.716517606682943], 
  ["32",0.7221004457354674], 
  ["33",0.7276690182855897], 
  ["34",0.7449404740887143], 
  ["35",0.7450203665021664], 
  ["36",0.758966300595348], 
  ["37",0.7592741003846746], 
  ["38",0.7616922725418402], 
  ["39",0.7628950100268542], 
  ["40",0.7628950100268542], 
  ["41",0.764559910857186], 
  ["42",0.7791558267998099], 
  ["43",0.7887419177588919], 
  ["44",0.7895339226397476], 
  ["45",0.7895532537505027], 
  ["46",0.7895532537505027], 
  ["47",0.7895532537505027], 
  ["48",0.7898806699806321], 
  ["49",0.7917723368666463], 
  ["50",0.7935604289452207], 
  ["51",0.8065231870253575], 
  ["52",0.8124120425544084], 
  ["53",0.82252456745213], 
  ["54",0.8401387759754664], 
  ["55",0.8448395885169774], 
  ["56",0.8448395885169774], 
  ["57",0.8448395885169774], 
  ["58",0.8461065252628011], 
  ["59",0.8462099574052168], 
  ["60",0.846224223907619], 
  ["61",0.8462384904100212], 
  ["62",0.8578975755006488], 
  ["63",0.8578975755006488], 
  ["64",0.8578975755006488], 
  ["65",0.8578975755006488], 
  ["66",0.8598722020981315], 
  ["67",0.8599984606443907], 
  ["68",0.8599984606443907], 
  ["69",0.8610133796252801], 
  ["70",0.8632786862092068], 
  ["71",0.8632786862092068], 
  ["72",0.8632786862092068], 
  ["73",0.9778011795972179], 
  ["74",0.9824211010701088], 
  ["75",0.9824211010701088], 
  ["76",0.9824211010701088], 
  ["77",0.9824211010701088], 
  ["78",0.9824211010701088], 
  ["79",0.9830663036412465], 
  ["80",0.9945715245034666], 
  ["81",0.9945715245034666], 
  ["82",0.9948027845074057], 
  ["83",0.9949689892603908], 
  ["84",0.9974903795624364], 
  ["85",0.9976456704410838], 
  ["86",0.9976456704410838], 
  ["87",0.9976456704410838], 
  ["88",0.9976456704410838], 
  ["89",0.9976456704410838], 
  ["90",0.9976963878571234], 
  ["91",0.9988400620221923], 
  ["92",0.9988400620221923], 
  ["93",0.9988400620221923], 
  ["94",0.9988400620221923], 
  ["95",0.9988400620221923], 
  ["96",0.9999092650447222], 
  ["97",0.9999092650447222], 
  ["98",1.0], 
  ["99",1.0], 
  ["100",1.0]
];

export let recallDATEData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.09619450317124736], 
  ["2",0.1839323467230444], 
  ["3",0.2616279069767442], 
  ["4",0.33509513742071884], 
  ["5",0.39904862579281186], 
  ["6",0.4619450317124736], 
  ["7",0.5110993657505285], 
  ["8",0.5586680761099366], 
  ["9",0.5924947145877378], 
  ["10",0.629492600422833], 
  ["11",0.6627906976744186], 
  ["12",0.6897463002114165], 
  ["13",0.7103594080338267], 
  ["14",0.7325581395348837], 
  ["15",0.7595137420718816], 
  ["16",0.7785412262156448], 
  ["17",0.7928118393234672], 
  ["18",0.8054968287526427], 
  ["19",0.8208245243128964], 
  ["20",0.837737843551797], 
  ["21",0.8535940803382663], 
  ["22",0.8678646934460887], 
  ["23",0.8747357293868921], 
  ["24",0.8858350951374208], 
  ["25",0.8974630021141649], 
  ["26",0.9075052854122622], 
  ["27",0.9154334038054969], 
  ["28",0.9201902748414377], 
  ["29",0.9249471458773785], 
  ["30",0.9281183932346723], 
  ["31",0.9307610993657506], 
  ["32",0.9334038054968288], 
  ["33",0.936046511627907], 
  ["34",0.9392177589852009], 
  ["35",0.9418604651162791], 
  ["36",0.9429175475687104], 
  ["37",0.9455602536997886], 
  ["38",0.9476744186046512], 
  ["39",0.9513742071881607], 
  ["40",0.9519027484143763], 
  ["41",0.9529598308668076], 
  ["42",0.9540169133192389], 
  ["43",0.9561310782241015], 
  ["44",0.9593023255813954], 
  ["45",0.9603594080338267], 
  ["46",0.9614164904862579], 
  ["47",0.9624735729386892], 
  ["48",0.9624735729386892], 
  ["49",0.9630021141649049], 
  ["50",0.9645877378435518], 
  ["51",0.9667019027484144], 
  ["52",0.96723044397463], 
  ["53",0.9682875264270613], 
  ["54",0.9693446088794926], 
  ["55",0.9709302325581395], 
  ["56",0.9714587737843552], 
  ["57",0.9719873150105708], 
  ["58",0.9725158562367865], 
  ["59",0.9741014799154334], 
  ["60",0.9756871035940803], 
  ["61",0.9767441860465116], 
  ["62",0.9778012684989429], 
  ["63",0.9778012684989429], 
  ["64",0.9778012684989429], 
  ["65",0.9799154334038055], 
  ["66",0.9799154334038055], 
  ["67",0.9815010570824524], 
  ["68",0.983615221987315], 
  ["69",0.9846723044397463], 
  ["70",0.985200845665962], 
  ["71",0.9867864693446089], 
  ["72",0.9873150105708245], 
  ["73",0.9878435517970402], 
  ["74",0.9883720930232558], 
  ["75",0.9883720930232558], 
  ["76",0.9899577167019028], 
  ["77",0.9899577167019028], 
  ["78",0.9920718816067653], 
  ["79",0.9931289640591966], 
  ["80",0.9936575052854123], 
  ["81",0.9936575052854123], 
  ["82",0.9936575052854123], 
  ["83",0.9941860465116279], 
  ["84",0.9952431289640592], 
  ["85",0.9952431289640592], 
  ["86",0.9952431289640592], 
  ["87",0.9952431289640592], 
  ["88",0.9952431289640592], 
  ["89",0.9957716701902748], 
  ["90",0.9957716701902748], 
  ["91",0.9957716701902748], 
  ["92",0.9963002114164905], 
  ["93",0.9963002114164905], 
  ["94",0.9968287526427061], 
  ["95",0.9973572938689218], 
  ["96",0.9973572938689218], 
  ["97",0.9978858350951374], 
  ["98",0.9984143763213531], 
  ["99",0.9989429175475687], 
  ["100",1.0]
];

export let revenueDATEData = [
  ['Inspection Rate', 'Revenue'],
  ["1",0.0056900518180767], 
  ["2",0.019963758803947795], 
  ["3",0.03289691389160012], 
  ["4",0.046618007906923636], 
  ["5",0.08631084155030372], 
  ["6",0.11258453170170196], 
  ["7",0.13956940699784776], 
  ["8",0.23900272012268042], 
  ["9",0.2585728661253527], 
  ["10",0.3047760041424217], 
  ["11",0.3143395540277081], 
  ["12",0.3275943474120174], 
  ["13",0.33240651000477717], 
  ["14",0.37221868294075117], 
  ["15",0.40297747611733975], 
  ["16",0.4107630631407573], 
  ["17",0.4248468829796557], 
  ["18",0.43082625946644815], 
  ["19",0.4368893803223411], 
  ["20",0.44606737798020957], 
  ["21",0.4846280933254665], 
  ["22",0.493590381467014], 
  ["23",0.4967971345444592], 
  ["24",0.5086543100209406], 
  ["25",0.560056518175916], 
  ["26",0.5872226493850812], 
  ["27",0.6018005182021663], 
  ["28",0.6159223582699666], 
  ["29",0.6400644189649464], 
  ["30",0.6420130805280571], 
  ["31",0.6573323794749824], 
  ["32",0.6694947867703581], 
  ["33",0.7317075797855925], 
  ["34",0.7601361537923246], 
  ["35",0.7606711476324055], 
  ["36",0.7644308703429583], 
  ["37",0.777353753548881], 
  ["38",0.7789416152662413], 
  ["39",0.7808210842927017], 
  ["40",0.786389656842824], 
  ["41",0.7863902988354322], 
  ["42",0.786607149671945], 
  ["43",0.786607149671945], 
  ["44",0.7867668631663373], 
  ["45",0.7868455429270851], 
  ["46",0.787558868047193], 
  ["47",0.7976959313290465], 
  ["48",0.7976959313290465], 
  ["49",0.8021181904111554], 
  ["50",0.8087743697719063], 
  ["51",0.8152837468229389], 
  ["52",0.815337246206947], 
  ["53",0.8200428380267627], 
  ["54",0.8259794150063487], 
  ["55",0.8261767920670826], 
  ["56",0.8261767920670826], 
  ["57",0.8261767920670826], 
  ["58",0.8261767920670826], 
  ["59",0.8403786672133587], 
  ["60",0.8520199191759836], 
  ["61",0.8520199191759836], 
  ["62",0.8520199191759836], 
  ["63",0.8520199191759836], 
  ["64",0.8520199191759836], 
  ["65",0.8537502319198293], 
  ["66",0.8537502319198293], 
  ["67",0.8562928079779419], 
  ["68",0.8597496528424969], 
  ["69",0.9749444551562099], 
  ["70",0.9766881784123136], 
  ["71",0.9928910731855166], 
  ["72",0.9928910731855166], 
  ["73",0.9931988729748432], 
  ["74",0.9946156793284014], 
  ["75",0.9946156793284014], 
  ["76",0.9965025669361108], 
  ["77",0.9965025669361108], 
  ["78",0.9973456458955663], 
  ["79",0.9973649770063212], 
  ["80",0.9973649770063212], 
  ["81",0.9973649770063212], 
  ["82",0.9973649770063212], 
  ["83",0.9975091400130951], 
  ["84",0.9976644308917426], 
  ["85",0.9976644308917426], 
  ["86",0.9976644308917426], 
  ["87",0.9976644308917426], 
  ["88",0.9976644308917426], 
  ["89",0.9986793498726321], 
  ["90",0.9986793498726321], 
  ["91",0.9986793498726321], 
  ["92",0.9987827820150478], 
  ["93",0.9987827820150478], 
  ["94",0.9994279845861853], 
  ["95",0.9994633655121427], 
  ["96",0.9994633655121427], 
  ["97",0.9994633655121427], 
  ["98",0.9994633655121427], 
  ["99",0.9998585476286824], 
  ["100",0.9999999999999999]
];

export let multiArabic = [
  {
    'name': 'الأول',
    'series': [
      {
        'name': 'استيراد',
        'value': 40
      },
      {
        'name': 'التصدير',
        'value': 30
      },
      {
        'name': 'الترانزيت',
        'value': 20
      }
    ]
  },

  {
    'name': 'الثاني',
    'series': [
      {
        'name': 'استيراد',
        'value': 35
      },
      {
        'name': 'التصدير',
        'value': 20
      },
      {
        'name': 'الترانزيت',
        'value': 15
      }
    ]
  },

  {
    'name': 'الثالث',
    'series': [
      {
        'name': 'استيراد',
        'value': 30
      },
      {
        'name': 'التصدير',
        'value': 20
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'الرابع',
    'series': [
      {
        'name': 'استيراد',
        'value': 25
      },
      {
        'name': 'التصدير',
        'value': 15
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'الخامس',
    'series': [
      {
        'name': 'استيراد',
        'value': 30
      },
      {
        'name': 'التصدير',
        'value': 20
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'السادس',
    'series': [
      {
        'name': 'استيراد',
        'value': 26
      },
      {
        'name': 'التصدير',
        'value': 14
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'السابع',
    'series': [
      {
        'name': 'استيراد',
        'value': 20
      },
      {
        'name': 'التصدير',
        'value': 15
      },
      {
        'name': 'الترانزيت',
        'value': 9
      }
    ]
  },

  {
    'name': 'الثامن',
    'series': [
      {
        'name': 'استيراد',
        'value': 30
      },
      {
        'name': 'التصدير',
        'value': 23
      },
      {
        'name': 'الترانزيت',
        'value': 23
      }
    ]
  },

  {
    'name': 'التاسع',
    'series': [
      {
        'name': 'استيراد',
        'value': 34
      },
      {
        'name': 'التصدير',
        'value': 23
      },
      {
        'name': 'الترانزيت',
        'value': 12
      }
    ]
  },

  {
    'name': 'العاشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 36
      },
      {
        'name': 'التصدير',
        'value': 13
      },
      {
        'name': 'الترانزيت',
        'value': 5
      }
    ]
  },

  {
    'name': 'الحادي عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 45
      },
      {
        'name': 'التصدير',
        'value': 29
      },
      {
        'name': 'الترانزيت',
        'value': 20
      }
    ]
  },

  {
    'name': 'الثاني عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 23
      },
      {
        'name': 'التصدير',
        'value': 12
      },
      {
        'name': 'الترانزيت',
        'value': 9
      }
    ]
  },
  {
    'name': 'الثالث عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 30
      },
      {
        'name': 'التصدير',
        'value': 27
      },
      {
        'name': 'الترانزيت',
        'value': 14
      }
    ]
  },

  {
    'name': 'الرابع عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 28
      },
      {
        'name': 'التصدير',
        'value': 16
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'الخامس عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 38
      },
      {
        'name': 'التصدير',
        'value': 24
      },
      {
        'name': 'الترانزيت',
        'value': 15
      }
    ]
  },

  {
    'name': 'السادس عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 40
      },
      {
        'name': 'التصدير',
        'value': 23
      },
      {
        'name': 'الترانزيت',
        'value': 15
      }
    ]
  },

  {
    'name': 'السابع عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 29
      },
      {
        'name': 'التصدير',
        'value': 21
      },
      {
        'name': 'الترانزيت',
        'value': 18
      }
    ]
  },

  {
    'name': 'الثامن عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 29
      },
      {
        'name': 'التصدير',
        'value': 12
      },
      {
        'name': 'الترانزيت',
        'value': 9
      }
    ]
  },

  {
    'name': 'التاسع عشر',
    'series': [
      {
        'name': 'استيراد',
        'value': 36
      },
      {
        'name': 'التصدير',
        'value': 28
      },
      {
        'name': 'الترانزيت',
        'value': 18
      }
    ]
  },

  {
    'name': 'العشرين',
    'series': [
      {
        'name': 'استيراد',
        'value': 38
      },
      {
        'name': 'التصدير',
        'value': 28
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'الحادي والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 37
      },
      {
        'name': 'التصدير',
        'value': 25
      },
      {
        'name': 'الترانزيت',
        'value': 26
      }
    ]
  },

  {
    'name': 'الثاني والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 40
      },
      {
        'name': 'التصدير',
        'value': 29
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },

  {
    'name': 'الثالث والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 38
      },
      {
        'name': 'التصدير',
        'value': 25
      },
      {
        'name': 'الترانزيت',
        'value': 18
      }
    ]
  },

  {
    'name': 'الرابع والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 39
      },
      {
        'name': 'التصدير',
        'value': 12
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },
  {
    'name': 'الخامس والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 37
      },
      {
        'name': 'التصدير',
        'value': 29
      },
      {
        'name': 'الترانزيت',
        'value': 18
      }
    ]
  },

  {
    'name': 'السادس والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 39
      },
      {
        'name': 'التصدير',
        'value': 29
      },
      {
        'name': 'الترانزيت',
        'value': 19
      }
    ]
  },

  {
    'name': 'السابع والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 40
      },
      {
        'name': 'التصدير',
        'value': 25
      },
      {
        'name': 'الترانزيت',
        'value': 15
      }
    ]
  },

  {
    'name': 'الثامن والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 35
      },
      {
        'name': 'التصدير',
        'value': 20
      },
      {
        'name': 'الترانزيت',
        'value': 18
      }
    ]
  },
  {
    'name': 'التاسع والعشرون',
    'series': [
      {
        'name': 'استيراد',
        'value': 45
      },
      {
        'name': 'التصدير',
        'value': 29
      },
      {
        'name': 'الترانزيت',
        'value': 19
      }
    ]
  },

  {
    'name': 'الثلاثين',
    'series': [
      {
        'name': 'استيراد',
        'value': 39
      },
      {
        'name': 'التصدير',
        'value': 26
      },
      {
        'name': 'الترانزيت',
        'value': 15
      }
    ]
  },
  {
    'name': 'الحادي والثلاثين',
    'series': [
      {
        'name': 'استيراد',
        'value': 28
      },
      {
        'name': 'التصدير',
        'value': 19
      },
      {
        'name': 'الترانزيت',
        'value': 10
      }
    ]
  },
];