import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { ServiceDocument } from '@agility/frameworkcore';
import { JourneyInfo } from './journeyDetails/journeyInfo/journeyInfoAccordion/models/JourneyInfoModel';
import { CargoAgentLinkModel } from '../cargo/journeyDetails/journeyInfo/journeyInfoAccordion/models/SlotAgentModel';
import { ManifestModel } from '../cargo/journeyDetails/manifest/models/ManifestModel';
import { HBItemModel } from '../cargo/journeyDetails/billOfLadingLine/BillOfLadingLine';
import { StorageService } from '../storageService/storageService';
import { SupplementaryManifestModel } from './journeyDetails/manifest/models/SupplementaryManifestModel';
import { CommonSharedService } from '../common/CommonSharedService';

export interface MyData {
  manifestData: any;
  billOfLadingData: any;
  editFlow: boolean;
  cargoOwnerCSId: number;
  journeyId: number;
  manifestModel: any;
  hbItemModel: any;
  journeyModeType: string;
  journeyModel: any;
  suppManifestModel: any;
  isEditSuppManifest: boolean;
  journeyStatus: string;
  hbItemList: any[];
  journeyArrivedDate: Date;
  isArrivalDateEnabled: boolean;
  isValidConsolidateBill: boolean;
  isHbPartyDocuments: boolean;
  isJourney: boolean;
  isDepartureDateEnabled: boolean;
  isFromBillListMenu: boolean;
}

export interface MyJourneyData {
  journeyData: any;
}

@Injectable()

export class JourneyService {
  public journeyServiceDocumentAgentList: ServiceDocument<CargoAgentLinkModel> = new ServiceDocument<CargoAgentLinkModel>();
  public blCurrentActionId: string;
  public bllCurrentActionId: string;
  public journeyModel: JourneyInfo = new JourneyInfo();
  public journeyId: number;
  public journeynumber: string;
  public hbPartyId: number;
  public manifestnumber: string;
  public manifestId: number;
  public billOfLadingId: number;
  public hbItemId: number;
  public agentname: string;
  public billofladingnumber: string;
  public journeyType: string;
  public voyageno: string;
  public agentCode: string;
  public carrierId: number;
  public journeyTypeShowHide = false;
  public expectedArrivalDate?: Date;
  public blStateName: string;
  public bllStateName: string;
  public hbPartyCurrentActionId: string;
  public containerCurrentActionId: string;
  public hbPartyIsNew = false;
  public isNocAgent = false;
  public nocAgentId = 0;
  public hbItemCount = 0;
  public shipmentOrderId = 0;
  public manifestForCode: string;

  // For NOC Details
  public noObjectionCertificateId: number;

  public sharingData: MyData = {
    manifestData: null, editFlow: false,
    cargoOwnerCSId: 0, journeyId: 0, billOfLadingData: null,
    manifestModel: new ManifestModel(), hbItemModel: new HBItemModel(),
    journeyModeType: null, journeyModel: new JourneyInfo(),
    suppManifestModel: new SupplementaryManifestModel(), isEditSuppManifest: false,
    journeyStatus: null, journeyArrivedDate: null, hbItemList: [], isArrivalDateEnabled: false,
    isValidConsolidateBill: false, isHbPartyDocuments: false, isJourney: false, isDepartureDateEnabled: false,
    isFromBillListMenu: false
  };

  public journeyData: MyJourneyData = { journeyData: null };
  public refType: string;

  constructor(
    private webStorage: StorageService,
    private commonSharedService: CommonSharedService,
  ) { }

  // tslint:disable-next-line: deprecation
  public cargoExtractSubmitData(res: Response) {
    const response = res.json();
    const body = response;
    return body || [];
  }

  public bindCargoOwnerCSId = (serviceDocument: ServiceDocument<any>): ServiceDocument<any> => {
    serviceDocument.dataProfile.profileForm.controls.ownerCSId.setValue(this.sharingData.cargoOwnerCSId);
    return serviceDocument;
  }
  public getCargoJourneyModeType = (): string => {
    this.sharingData.journeyModeType = this.sharingData.journeyModeType === null
      ? this.webStorage.get('journeyModeType') : this.sharingData.journeyModeType;
    return this.sharingData.journeyModeType;
  }

  public displayCargoStatusMessages = (serviceDocument: ServiceDocument<any>, profileName: any): void => {
    this.commonSharedService.displayAlertMessages(serviceDocument.dataProfile.profileForm.controls['actionName'].value, profileName);
  }
}
