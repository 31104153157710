import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StorageService } from '../../../storageService/storageService';
import { FilterMenuService } from '../../../common/filter-menu.service';
import { filter } from 'rxjs/operators';
import { NVDBMarketCommodityService } from '../services/nvdbMarketCommodity/NVDBMarketCommodityService';
import { NVDBMarketVehiclesService } from '../services/nvdbMarketVehicles/NVDBMarketVehiclesService';
import { SafeUnsubscriber } from '../../../../app/common/SafeUnsubscriber';


@Component({
  selector: 'risk-identification-analysis-subheader',
  templateUrl: './RiskIdentificationAnalysisSubheaderComponent.html',
})

export class RiskIdentificationAnalysisSubheaderComponent extends SafeUnsubscriber implements OnInit {
  public riskFreqSubheaderPageTitle: string;
  public filterMenuOpened: boolean;
  public riskIdentificationDetails = false;
  public goBackLinkTVSubheader = '';
  public showhideFilterIcon = false;
  public showhideCreateNewIcon = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public filterMenuService: FilterMenuService,
    public storageService: StorageService,
    private nvdbCommodityService: NVDBMarketCommodityService,
    private nvdbMarketVehiclesService: NVDBMarketVehiclesService,
  ) {
    super();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        let currentRoute = this.activatedRoute.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        this.riskFreqSubheaderPageTitle = currentRoute.snapshot.data['title'];

        if (this.router.url.indexOf('/opm-reports') > -1) {
          this.riskIdentificationDetails = false;
        } else {
          if (this.router.url.indexOf('/opm-compliance-reports') > -1) {
            this.riskIdentificationDetails = true;
            this.goBackLinkTVSubheader = '/risk-identification-Analysis/opm-reports';
          }

          if (this.router.url.indexOf('/market-research-data-vehicle') > -1) {
            this.riskIdentificationDetails = false;
          } else {
            if (this.router.url.indexOf('/market-research-vehicle-details') > -1) {
              this.riskIdentificationDetails = true;
              this.goBackLinkTVSubheader = '/risk-identification-Analysis/market-research-data-vehicle';
            }
          }

          if (this.router.url.indexOf('/market-research-data-commodity') > -1) {
            this.riskIdentificationDetails = false;
          } else {
            if (this.router.url.indexOf('/market-research-commodity-details') > -1) {
              this.riskIdentificationDetails = true;
              this.goBackLinkTVSubheader = '/risk-identification-Analysis/market-research-data-commodity';
            }
          }

          if (this.router.url.indexOf('/trader-profile-details') > -1) {
            this.riskIdentificationDetails = true;
            if (this.storageService.get('isTargetingAndMonitoring') === 'y') {
              this.goBackLinkTVSubheader = '/targeting-monitoring/targeting-monitoring';
            } else {
              this.goBackLinkTVSubheader = '/risk-identification-Analysis/trader-profile';
            }
          } else if (this.router.url.indexOf('/trader-profile') > -1) {
            this.riskIdentificationDetails = false;
          }

          if (this.router.url.indexOf('/commodity-profile-details') > -1) {
            this.riskIdentificationDetails = true;
            if (this.storageService.get('isTargetingAndMonitoring') === 'y') {
              this.goBackLinkTVSubheader = '/targeting-monitoring/targeting-commodity-profile';
            } else {
              this.goBackLinkTVSubheader = '/risk-identification-Analysis/commodity-profile';
            }
          } else if (this.router.url.indexOf('/commodity-profile') > -1) {
            this.riskIdentificationDetails = false;
          }
        }

        // Show Hide Search Filter
        if (this.router.url.includes('risk-identification-Analysis/nvdb-historical-data')
          || this.router.url.includes('risk-identification-Analysis/nvdb-historical-data-List')
          || this.router.url.includes('risk-selectivity-profiling/risk-register-transaction-history')
          || this.router.url.includes('risk-review-evaluation/risk-register-form')) {
          this.showhideFilterIcon = false;
        } else if (this.router.url === '/risk-identification-Analysis/trader-profile'
          || this.router.url === '/risk-identification-Analysis/commodity-profile'
          || this.router.url === '/risk-identification-Analysis/opm-reports') {
          this.showhideFilterIcon = true;
        } else if (this.router.url === '/risk-identification-Analysis/market-research-data-commodity'
          || this.router.url === '/risk-identification-Analysis/market-research-data-vehicle') {
          this.showhideFilterIcon = true;
          this.showhideCreateNewIcon = true;
        } else {
          this.showhideFilterIcon = false;
          this.showhideCreateNewIcon = false;
        }
      });
  }

  ngOnInit() { }


  public toggleClassName() {
    this.filterMenuService.toggleFilterMenu();
  }

  //  NVDBCommodityFormComponent
  createRiskIdentificationAnalysisSubheader(event: any): void {
    if (this.router.url.indexOf('/market-research-data-commodity') > -1) {
      this.nvdbCommodityService.newNVDBMarketCommodity().subscribe(() => {
        this.router.navigate(['/risk-identification-Analysis/market-research-commodity-details']);
      });
    } else if (this.router.url.indexOf('/market-research-data-vehicle') > -1) {
      this.nvdbMarketVehiclesService.newNVDBMarketVehicles().subscribe(() => {
        this.router.navigate(['/risk-identification-Analysis/market-research-vehicle-details']);
      });
    }
  }
}
