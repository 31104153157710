<div fxLayout="row wrap" fxLayoutAlign="end end">
  <div fxFlex="100" fxFlex.gt-sm="10" fxLayout="row" fxLayoutAlign="end center">
    <mcn-select [formGroup]="guageChartForm" optionKey="value"
                optionValue="viewValue" placeholder="{{'year' | translate}}" controlName="year" 
                [optionsData]="this.yearData" (OnChange)="onYearSelect($event)" selectedValue ="2021">
    </mcn-select>

    <!--<mat-form-field>
      <mat-select placeholder="Year" (selectionChange)="onYearSelect($event.value)" value="2018">
        <mat-option *ngFor="let year of this.yearData" [value]="year.value">
          {{ year.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>
</div>
<ngx-charts-gauge [view]="view"
                  [scheme]="colorScheme"
                  [results]="this.optionsData"
                  [min]="0"
                  [max]="100"
                  [angleSpan]="240"
                  [startAngle]="-120"
                  [bigSegments]="1"
                  [smallSegments]="1"
                  (select)="onSelect($event)">
</ngx-charts-gauge>
