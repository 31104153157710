<div fxLayout="column Wrap" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
    <div fxLayout="row Wrap">
        <div fxLayout="column Wrap" fxFlex="100">
            <h2 fxFlex="90" class="main-title pb-0">
                <span>{{'AppliedTarrif' | translate}}</span>
            </h2>
        </div>
    </div>
    <div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-2">
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbAppliedTariffInfo?.tariffCode}}</h6>
                <label class="preview-label">{{'ItemHSCodes' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbAppliedTariffInfo?.dutyRate}}</h6>
                <label class="preview-label">{{'DutyRateCaption' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbAppliedTariffInfo?.importMFNDuty}}</h6>
                <label class="preview-label">{{'ImportDuty' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbAppliedTariffInfo?.totalDuty}}</h6>
                <label class="preview-label">{{'TotalDuty' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbAppliedTariffInfo?.totalExemptions}}</h6>
                <label class="preview-label">{{'TotalExemptions' | translate}}</label>
            </div>
            <div fxflex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33.33" class="p-2">
                <h6 class="preview-content">{{nvdbAppliedTariffInfo?.totalPayable}}</h6>
                <label class="preview-label">{{'TotalPayable' | translate}}</label>
            </div>
        </div>

    </div>
</div>
<button matDialogClose class="dialog-close">
    <i class="icon-close"></i>
</button>