import { Injectable } from '@angular/core';
import { ServiceDocument } from '@agility/frameworkcore';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { ToasterService } from '../../../../toastrService/toasterService';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ReportCategorizationModel } from '../../models/ReportCategorizationModel';
import { MirrorAnalysisOpenModel } from '../../models/MirrorAnalysisOpenModel';


@Injectable()
export class OpmReportsService {
  public opmReportsServiceDocument: ServiceDocument<ReportCategorizationModel> = new ServiceDocument<ReportCategorizationModel>();
  public opmMirrorAnalysisReportsServiceDocument: ServiceDocument<MirrorAnalysisOpenModel> = new ServiceDocument<MirrorAnalysisOpenModel>();
  public orderIndex = 1;
  public isDescending = false;

  constructor(
    private httpClient: HttpClient,
    private toasterService: ToasterService,
    public rmsConfig: RmsConfig,
    public mcnConfig: McnConfig,
  ) {

  }

  public listOpmReports(
    expression?: string,
    aliasName?: string,
    pageIndex: number = 1,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = 1,
    isDescending: boolean = false
  ): Observable<ServiceDocument<ReportCategorizationModel>> {
    const opmReportsFilterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };

    opmReportsFilterModel.orderIndex = this.orderIndex;
    opmReportsFilterModel.isDescending = this.isDescending;

    return this.opmReportsServiceDocument.list(
      this.rmsConfig.listReportCategories, opmReportsFilterModel)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.OpmReportsHandleError(), ngNavigationCancelingError: true
      })));
  }

  public OpmReportsHandleError() {
    return this.toasterService.Error('Internal Server Error. Please Try Again Later !');
  }
}
