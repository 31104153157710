import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { MsgConfigService } from './MsgConfigService';

@Injectable()
export class MsgConfigListResolver implements Resolve<any> {
    constructor(private service: MsgConfigService, ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.service.list();
    }
}

@Injectable()
export class MsgConfigNewResolver implements Resolve<any> {
    constructor(private service: MsgConfigService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.service.new();
    }
}
@Injectable()
export class MsgConfigOpenResolver implements Resolve<any> {
    constructor(private service: MsgConfigService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.service.open(route.params['id']);
    }
}
