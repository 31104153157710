import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { AlertMessageModalComponent } from '../commonComponents/commonModals/AlertMessageModalComponent';

@Injectable()
export class AlertModalService {
  constructor(public dialog: MatDialog) {
  }

  public confirm(state: string, icon: string, title: string, message: string, url: any): Observable<boolean> {
    const dialogRefAct = this.dialog.open(AlertMessageModalComponent, {
      panelClass: 'custom-modal-small'
    });

    dialogRefAct.componentInstance.state = state;
    dialogRefAct.componentInstance.icon = icon;
    dialogRefAct.componentInstance.title = title;
    dialogRefAct.componentInstance.Message = message;
    return dialogRefAct.afterClosed();
  }
}
