<div fxLayout="column" fxFlexFill [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxLayout="column" fxLayoutAlign="center center">
    <h1 matDialogTitle class="dialog-title">{{ 'RemarksHistory' | translate }}</h1>
    <i class="star-heading-ul"></i>
  </div>
  <div fxLayout="column">
    <div mat-dialog-content>
      <div class="remarksProgress" fxLayout="row wrap" fxLayoutAlign="end">
        <span class="preview-content font-bold">{{(isShowLeadTime?'LeadTime':'TotalLeadTime')|translate}}</span>
        <span matTooltip="{{'RemarksHistory'|translate }}">
          <i class="info-icon-dark"
             matTooltip="{{'RemarksHistory'|translate }}"></i>
        </span>
        <span class="preview-content font-color" *ngIf="this.commonRemarksHistoryData != null && this.commonRemarksHistoryData.length > 0">
          :{{isShowLeadTime?this.commonRemarksHistoryData[0].daysTakenToComplete:this.commonRemarksHistoryData[0].finalDaysTakenToComplete}}
          {{'Days'|translate}}
        </span>
      </div>
      <mat-tab-group class="remarks-history-tabs">
        <mat-tab label="{{'Map' | translate}}">
          <div fxLayout="row wrap" class="bs-vertical-wizard" fxFlex="100" fxLayoutAlign="end end"
               *ngIf="this.commonRemarksHistoryData.length>0">
            <ul fxFlex="50">
              <li *ngFor="let remarksHistory of this.commonRemarksHistoryData"
                  [ngClass]="[remarksHistory.remarks===null ? 'complete' : 'current']">
                <div [ngClass]="[remarksHistory.remarksHistoryId===remarksModelData?.remarksHistoryId && remarksModelData?.remarks!==null  ? 'float-cnt' : '']"
                     *ngIf="remarksHistory.remarksHistoryId===remarksModelData?.remarksHistoryId">
                  <h6 class="mt-1 mb-0 remarks-name">{{remarksModelData?.fullName}}</h6>
                  <p class="mt-1">
                    {{remarksModelData?.remarks}}
                  </p>
                </div>
                <a (click)="getStateBasedRemarks($event,remarksHistory)">
                  {{remarksHistory?.stateName}} <i [ngClass]="[remarksHistory.remarks===null ? 'ico remarks-tick-icon ico-green' : 'ico remarks-hs-icon']"
                                                   class=""></i>
                  <span class="desc">{{remarksHistory?.organizationName}}</span>
                  <span class="desc">
                    {{remarksHistory?.createdDate | date}} :
                    {{remarksHistory?.createdDate | date:'shortTime'}}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="{{'TimeLine' | translate}}">
          <section class="responsive-material-table pt-4">
            <div fxLayout="row" fxLayoutAlign="end center" class="mb-3 mr-2">
              <section class="container-table-section responsive-material-table">
                <mat-table #table [dataSource]="commonRemarksHistoryDataSource">
                  <ng-container cdkColumnDef="From">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="35">
                      {{'From' | translate}}
                      <i class="sorting-icon ml-2" (click)="sortRemarksHistoryList()"></i>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row" class="overflow-ellipsis pd-r-0" fxFlex="35">
                      <span class="mobile-cont-text">{{'From' | translate}}</span>
                      <span>{{row?.organizationName}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Status">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="15">
                      {{'Status' | translate}}
                      <i class="sorting-icon ml-2" (click)="sortRemarksHistoryList()"></i>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="15">
                      <span class="mobile-cont-text">{{'Status' | translate}}</span>
                      <span>{{row?.stateName}}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="DateTime">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="20">
                      {{'DateTime' | translate}}
                      <i class="sorting-icon ml-2" (click)="sortRemarksHistoryList()"></i>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row" fxFlex="20">
                      <span class="mobile-cont-text">{{'DateTime' | translate}}</span>
                      <span>
                        {{row?.createdDate | date}} :
                        {{row?.createdDate | date:'shortTime'}}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <ng-container cdkColumnDef="Comments">
                    <mat-header-cell *cdkHeaderCellDef fxFlex="40">
                      {{'Comments' | translate}}
                      <i class="sorting-icon ml-2" (click)="sortRemarksHistoryList()"></i>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let row" class="pr-0" FxLayoutAlign="center end" fxFlex="40">
                      <span class="mobile-cont-text">{{'Comments' | translate}}</span>
                      <span class="mobile-cont-text-comments mt-3">{{row?.remarks}}</span>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *cdkHeaderRowDef="commonRemarksHistoryDisplayedColumns"></mat-header-row>
                  <mat-row *cdkRowDef="let row; columns: commonRemarksHistoryDisplayedColumns;"></mat-row>
                </mat-table>
              </section>
            </div>
          </section>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <button matDialogClose class="dialog-close">
    <i class="icon-close"></i>
  </button>
</div>
