<div fxLayout="column">
        <div mat-dialog-content>
            <div fxLayout="column" fxLayoutAlign="stretch stretch">
                <div class="session-warn-container" fxLayout="column" fxLayoutAlign="stretch center">
                    <h5 class="font-semi-bold mb-1">
                        {{ this.title | translate}}
                    </h5>
                </div>
                <div class="session-message-container" fxLayout="column" fxLayoutAlign="center center">
                    <i class="session-expire-icon mt-3"></i>
                    <p class ='session-warn-message mt-3' fxLayout ='row' fxLayoutAlign ='center center'>{{this.headingMsg | translate}}</p>
                    <p style ='font-size: 37px;line-height: 12px; margin: 10px 0px 19px 0px;' *ngIf="!this.istimeOut"> {{this.countdown}} seconds! </p>
                    <p class='session-warn-message mb-0'>{{this.message1 | translate}} </p>
                    <p class='session-warn-message pb-2'> {{this.message2 | translate}}</p>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="mb-2">
            <div fxFlex="100" fxFlex.xs="100" class="" fxLayout="row" fxLayout.xs="column"
                 fxLayoutAlign="center" fxLayoutAlign.xs="start stretch">
                <button type="button" class="btn-custom-primary mr-0 mr-mat-3" (click)="continue($event)" *ngIf="!this.istimeOut">{{'Continue' | translate}}</button>
                <button type="button" mat-raised-button class="btn-custom-secondary mt-3 mt-mat-0" (click)="logout($event)" *ngIf="!this.istimeOut">{{'LogOff' | translate}}</button>
                <button type="button" class="btn-custom-primary mr-0 mr-mat-3" (click)="ok($event)" *ngIf="this.istimeOut">{{'Ok' | translate}}</button>
            </div>
        </div>
    </div>