import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StorageService } from '../storageService/storageService';


@Injectable()
export class McnSpinnerInterceptor implements HttpInterceptor {
  public showLoading = false;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private storageService: StorageService
  ) { }


  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.storageService.get('Auto') !== true && req.url.indexOf('web_chatbot')<=-1) {
      this.spinnerService.show();
    }

    // Adding header
    return next
      .handle(req).pipe(
        finalize(() => {
          this.storageService.set('Auto', false);
          this.spinnerService.hide();
        })
      );



    //  do (event => {
    //  if (event instanceof HttpResponse) {
    //    if (event.status > 0) {
    //      this.spinnerService.hide();
    //      this.storageService.set("Auto", false)
    //    }
    //  }
    // }).finally(() => {
    //  this.spinnerService.hide();
    // });
  }
}
