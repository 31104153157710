<div [formGroup]="formGroup">
       <mat-form-field *ngIf="!iconList" class="mat-input-container" floatLabel="auto">
          <mat-select placeholder="{{placeholder}}" id="ddl_{{optionalId}}{{placeholder |
            removeSpace}}" formControlName="{{controlName}}" (selectionChange)="onOptionSelect($event.value)">
            <mat-option *ngFor="let optionObject of optionsData"
            [value]="optionObject[optionKey]" matTooltip=" {{optionObject[optionValue]}}">
                {{optionObject[optionValue]}}
            </mat-option>
          </mat-select>
       </mat-form-field>
       <mat-form-field *ngIf="iconList">
          <mat-select placeholder="{{placeholder}}" id="ddl_{{optionalId}}{{placeholder |
            removeSpace}}" formControlName="{{controlName}}" (selectionChange)="onOptionSelect($event.value)">
            <mat-option *ngFor="let optionObject of optionsData" [value]="optionObject[optionKey]" matTooltip="
             {{optionObject[optionValue]}}">
                <i class="{{optionObject[iconClass]}}"></i> - {{optionObject[optionValue]}}
            </mat-option>
          </mat-select>
       </mat-form-field>
    </div>