import { Injectable } from '@angular/core';

// Ai api Client
import { ApiAiClient, ApiAiConstants } from '../client/ApiAiClient';

// RxJs modules
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';


export class Message {
  constructor(public content: string, public sendBy: ESendBy, public lang: Lang, public options: []) { }
}

export enum ESendBy {
  user = 'user',
  bot = 'bot',
  option = 'option',
  map = 'map'
}

export enum Lang {
  ar = 'ar',
  en = 'en'
}

@Injectable({
  providedIn: 'root'
})
export class ChatBotDataService {

  private client;
  public user_input_required = false;
  public lang: Lang = Lang.en;
  public loc;
  public lang_selected = false;
  public messages = '';
  public prompt = '';
  public eng_username_asked = false;
  public arb_username_asked = false;
  public error_message_eng = 'Sorry, there seems to be some issue right now... Please check your network and try again!';
  public error_message_arb = 'عذرًا ، يبدو أن هناك بعض المشكلات في الوقت الحالي ... يرجى التحقق من شبكتك والمحاولة مرة أخرى';
  public go_back_eng = 'Go back';
  public go_back_arb = 'رجوع للخلف';
  public change_language = 'change language';
  public ask_name_text = 'ask name';
  public typingIndicator = false;

  conversation = new BehaviorSubject<Message[]>([]);

  constructor(private http: HttpClient) { }

  public showUserMessage(msg: string) {
    const userMessage = new Message(msg, ESendBy.user, this.lang, []);
    this.update(userMessage);
    const data = 'user' + ': ' + msg + '\n';
    this.messages += data;
    // this.showMap(["map"])
  }
  public feedback(data: string, userfeedback: string) {
    return this.client.textRequest('feedback', { 'botResponse': data, 'feedback': userfeedback });
  }

  public converseOptions(msg: string) {
    // this.converse(msg)
    this.showUserMessage(msg);
    this.typingIndicator = true;
    // return this.client.textRequest(this.prompt + msg).then(res => {
    return this.testHttpRequest(this.prompt + msg).subscribe((res: any) => {
      this.botMessageHandler(res);
    },
      err => {
        this.chatErrorHandler();
      });
  }


  public chatErrorHandler() {
    this.typingIndicator = false;
    if (this.lang === Lang.ar) {
      this.showBotMessage(this.error_message_arb, []);
    } else {
      this.showBotMessage(this.error_message_eng, []);
    }
  }

  public botMessageHandler(res: any) {
    const speech = res.response;
    this.typingIndicator = false;
    if (speech === this.change_language) {
      this.lang_selected = false;
      this.showLangOption();
      return;
    }
    const options = res.options;
    this.loc = res.loc;
    if (this.lang === Lang.ar) {
      options.push(this.go_back_arb);
    } else {
      options.push(this.go_back_eng);
    }
    this.user_input_required = res.inputRequired;
    this.prompt = res.prompt;
    if (this.loc.length) {
      this.showMap();
    }
    this.showBotMessage(speech, options);
  }

  public testHttpRequest(msg: string) {
    const data = { 'query': msg, 'lang': this.client.getApiLang(), 'sessionId': this.client.getSessionId() };
    return this.http.post(this.client.getApiBaseUrl(), data);
  }

  public showLangOption() {
    const langOptionMessage = new Message('', ESendBy.option, this.lang, []);
    this.update(langOptionMessage);
  }

  public showMap() {
    const optionMessage = new Message('', ESendBy.map, this.lang, this.loc);
    this.update(optionMessage);
  }

  public showBotMessage(speech, options) {
    const botMessage = new Message(speech, ESendBy.bot, this.lang, options);
    this.update(botMessage);
    const data = 'bot' + ': ' + speech + '\n';
    this.messages += data;
  }

  public update(msg: Message) {
    this.conversation.next([msg]);
  }

  public init(token: string) {
    this.client = new ApiAiClient({ accessToken: token, lang: ApiAiConstants.AVAILABLE_LANGUAGES.EN });
  }

  public change_language_arabic() {
    this.client.setApiLang(ApiAiConstants.AVAILABLE_LANGUAGES.HI);
    this.lang = Lang.ar;
    this.showUserMessage('عربى');
    const welcome_text: string = this.arb_username_asked ? '0' : this.ask_name_text;
    this.typingIndicator = true;
    return this.client.textRequest(welcome_text).then(res => {
      const speech = res.response;
      const options = res.options;
      this.user_input_required = res.inputRequired;
      this.prompt = res.prompt;
      // options.push(this.go_back_arb)
      this.typingIndicator = false;
      this.showBotMessage(speech, options);
      this.arb_username_asked = true;
    },
      err => {
        this.chatErrorHandler();
        // this.typingIndicator = false
        // this.showBotMessage(this.error_message_arb, [])
      });
  }

  public change_language_english() {
    this.client.setApiLang(ApiAiConstants.AVAILABLE_LANGUAGES.EN);
    this.lang = Lang.en;
    this.showUserMessage('English');
    const welcome_text: string = this.eng_username_asked ? 'Hi' : this.ask_name_text;
    this.typingIndicator = true;
    return this.client.textRequest(welcome_text).then(res => {
      const speech = res.response;
      const options = res.options;
      this.user_input_required = res.inputRequired;
      this.prompt = res.prompt;
      // options.push(this.go_back_eng)
      this.typingIndicator = false;
      this.showBotMessage(speech, options);
      this.eng_username_asked = true;
    },
      err => {
        this.chatErrorHandler();
        // this.typingIndicator = false
        // this.showBotMessage(this.error_message_eng, [])
      });
  }

  public download_messages() {
    const messages = this.messages;
    const file = new Blob([messages], { type: 'text/plain;charset=utf-8' });
    saveAs(file, 'chat.txt');
  }
}
