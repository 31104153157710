import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { multi, multiArabic } from '../data/data';
import { StorageService } from '../../../storageService/storageService';
import { FxContext } from '@agility/frameworkcore';

@Component({
  selector: 'app-stackedChart',
  templateUrl: './stackedChartComponent.html',
})

export class stackedChartComponent implements OnInit {

  @Input() public optionsData: any;
  @Input() public yearData: any;
  @Input() public countryData: any;
  @Input() public optionsCurrYearData: any;
  @Input() public optionsPrevYearData: any;
  public stackedChartForm: FormGroup;

  public isMonthChart = false;
  public isYearChart = true;
  public months: any = [];

 

  public view: any[] = [1200, 460];
  public showXAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public showYAxisLabel = true;
  public legendTitle = false;
  public animations = true;
  public xAxisLabel: any;
  public yAxisLabel: any;
  public showYAxis: any;

  public colorScheme = {
    domain: ['#f2817d', ' #6dc0b7', '#ffbfa2']
  };

  ngOnInit() {
    this.getMonths();
    this.stackedChartForm = this.fb.group({
      country: [''],
      month: [''],
      year: ['']
    });
  }

  constructor(private fb: FormBuilder,public webStorage: StorageService,
    private fxContext: FxContext
  ) {
    
  }

  public onMonthSelect(value: any) {
    const languageId = this.fxContext.languageId === undefined ? 1 : this.fxContext.languageId;
    if(languageId === 1){
      this.optionsData = multi;
    } else {
      this.optionsData = multiArabic;
    }
    this.isMonthChart = true;
    this.isYearChart = false;
  }
  public onCounrtySelect(value: any) {

  }

  public onSelect(value: any) { }

  public onYearSelect(value: any) {
    if (value.options.value === '2020') {
      this.optionsData = this.optionsPrevYearData;
      this.isMonthChart = false;
      this.isYearChart = true;
    } else if (value.options.value === '2021') {
      this.optionsData = this.optionsCurrYearData;
      this.isMonthChart = false;
      this.isYearChart = true;
    }
  }
  public getMonths(){
    const languageId = this.fxContext.languageId === undefined ? 1 : this.fxContext.languageId;
    if(languageId === 1){
    this.months = [
      { value: 'January', viewValue: 'January' },
      { value: 'February', viewValue: 'February' },
      { value: 'March', viewValue: 'March' },
      { value: 'April', viewValue: 'April' },
      { value: 'May', viewValue: 'May' },
      { value: 'June', viewValue: 'June' },
      { value: 'July', viewValue: 'July' },
      { value: 'August', viewValue: 'August' },
      { value: 'September', viewValue: 'September' },
      { value: 'October', viewValue: 'October' },
      { value: 'November', viewValue: 'November' },
      { value: 'December', viewValue: 'December' },
    ]; 
    } else{
    this.months = [
      { value: 'January', viewValue: 'يناير' },
      { value: 'February', viewValue: 'فبراير' },
      { value: 'March', viewValue: 'يمشي' },
      { value: 'April', viewValue: 'أبريل' },
      { value: 'May', viewValue: 'يمكن' },
      { value: 'June', viewValue: 'يونيو' },
      { value: 'July', viewValue: 'يوليو' },
      { value: 'August', viewValue: 'أغسطس' },
      { value: 'September', viewValue: 'سبتمبر' },
      { value: 'October', viewValue: 'أكتوبر' },
      { value: 'November', viewValue: 'نوفمبر' },
      { value: 'December', viewValue: 'ديسمبر' },
    ]; 
    }
  
  }
}
