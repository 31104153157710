import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { ToasterService } from '../../toastrService/toasterService';

@Injectable()
export class CommonRemarksHistoryService {
  constructor(private _http: HttpClient, private toastrService: ToasterService, public appConfig: McnConfig) { }

  private commonRemarksHistoryHandleError() {
    return this.toastrService.Error(this.appConfig.getHardCodeMSg('Error'), null);
  }

  public getCommonRemarkshHistoryData(apiUrl: any): Observable<any> {
    return this._http.get(apiUrl)
      .pipe(map((response) => response), catchError((res) => throwError({
        msg: this.commonRemarksHistoryHandleError(), ngNavigationCancelingError: true
      })));
  }
}
