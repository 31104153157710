export class CommonRemarksHistoryModel {
  public daysTakenToComplete?: number;
  public organizationName: string;
  public profileInstanceId?: number;
  public stateName: string;
  public createdDate?: Date;
  public createdBy: string;
  public modifiedDate?: Date;
  public modifiedBy?: string;
  public remarks: string;
  public isRemarksOriginator?: number;
  public remarksHistoryId?: number;
  public finalDaysTakenToComplete: number;
  public fullName: string;
}
