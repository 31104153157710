import { DataSource } from '@angular/cdk/table';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { StorageService } from '../../storageService/storageService';
import { MsgConfigService } from '../messagingSys/services/MsgConfigService';

export interface Data { }

export class ExampleDataSource extends DataSource<any> {
  constructor(private data: Data[]) {
    super();
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  public connect(): Observable<Data[]> {
    return of(this.data);
  }

  public disconnect() {
    // EMPTY
  }
}

@Component({
  selector: 'app-notification-sys',
  templateUrl: './NotificationConfigComponent.html',
})

export class NotificationConfigComponent implements OnInit {
  public actionConfigForm: FormGroup;
  public displayedColumns = ['role', 'roleBased', 'organizationBased', 'isCustomStationBased', 'icons'];
  public dataSource: ExampleDataSource | null;
  public isEdit = false;
  public pageCount: any;

  constructor(
    public appConfig: McnConfig,
    private fb: FormBuilder,
    public storageService: StorageService,
    private msgConfigService: MsgConfigService) {
  }

  ngOnInit() {
    this.actionConfigForm = this.fb.group({
      notificationRoleId: [0],
      isRoleBased: [''],
      isOrganizationBased: [''],
      isEconomicBased: [''],
      roleId: ['']
    });
  }

  public getConfigList(event: any) {
    this.msgConfigService.listNotification().subscribe((res) => {
      this.dataSource = new ExampleDataSource(res);
      if (res.length > 0) {
        this.pageCount = Number(res[0].totalCount);
      } else {
        this.pageCount = 0;
      }
    });
  }


  public getMessageConfigList(response: any) {
    this.msgConfigService.listNotification(response.PageIndex, response.PageCount)
      .subscribe((res) => {
        this.dataSource = new ExampleDataSource(res);
      });
  }

  public submit(event: any) {
    if (this.actionConfigForm.valid) {
      this.msgConfigService.submitNotification(this.actionConfigForm.value).subscribe((res) => {
        if (res === true) {
          this.actionConfigForm.reset();
          this.isEdit = false;
        }
      });
    } else {
      Object.keys(this.actionConfigForm.controls).forEach(field => {
        const control = this.actionConfigForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public cancel() {
    this.actionConfigForm.reset();
    this.isEdit = false;
  }

  public editMsgConfig(id: any) {
    this.msgConfigService.openNotification(id).subscribe((res) => {
      this.actionConfigForm.patchValue(res);
      this.isEdit = true;
    });
  }
}
