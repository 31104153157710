<div [ngClass]="{'arb': (this.webStorageService.get('lang_code') === 'Arb')}">
  <div fxLayout="column wrap">
    <div fxLayout="row wrap">
      <div fxLayout="column Wrap" fxFlex="100">
        <div fxLayout="row">
          <h4 fxFlex="100">
            <span>{{'Output' | translate}}</span>
          </h4>
        </div>
        <div>
          <hr class="hr-line mb-0 mt-0">
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="mt-2">
    <div class="display-block">
      <h4>Duty and Tax Charges</h4>
      <form [formGroup]="dutyTaxCharForm" novalidate>
        <div fxLayout="row">
          <div fxFlex="75" class="mt-15">
            <div fxLayout="column wrap" class="border-2px">
              <div fxLayout="row wrap" fxLayoutAlign="start center" class="border-bottom-2">
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 border-right">
                  <p class="preview-content mrg-5">
                    <span>{{ ciflabel | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5" >
                    <span>{{ cif || '--' }}</span>
                  </p>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 border-right">
                  <p class="preview-content mrg-5">
                    <span>{{'Customs Value' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span>{{ customs || '--' }}</span>
                  </p>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2">
                  <p class="preview-content mrg-5">
                    <span>{{ dutylabel | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span>{{ duty || '--' }} MYR</span>
                  </p>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center" class="border-bottom-2" *ngIf="isImport">
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 border-right">
                  <p class="preview-content mrg-5">
                    <span>{{ 'Sales Tax' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span>{{ sales || '--' }}</span>
                  </p>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 border-right">
                  <p class="preview-content mrg-5">
                    <span>{{ 'Excise' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span> {{ excise || '--' }} </span>
                  </p>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2">
                  <p class="preview-content mrg-5">
                    <span>{{ 'Anti Dumping' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span> {{ antidumping || '--' }} </span>
                  </p>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center" class="border-bottom-2">
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 border-right">
                  <p class="preview-content mrg-5">
                    <span>{{ 'VAT' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span>{{ vat || '--' }}</span>
                  </p>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2 border-right" *ngIf="isImport">
                  <p class="preview-content mrg-5">
                    <span>{{ 'GST' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span> {{ gst || '--' }} </span>
                  </p>
                </div>
                <div fxFlex="100" fxFlex.gt-xs="33.33" class="p-2">
                  <p class="preview-content mrg-5">
                    <span>{{ 'Other Taxes' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span> {{ other || '--' }} </span>
                  </p>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
                  <p class="preview-content font-bold mrg-5">
                    <span>{{ 'Total Duty' | translate }}</span>
                  </p>
                  <p class="preview-label mrg-5">
                    <span> {{ total || '--' }} MYR</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="25">
            <div class="display-block">
              <mat-tab-group class="custom-mat-tab-group">
                <mat-tab>
                  <ng-template matTabContent>
                    <div fxFlex="100">
                      <chart [options]="this.trendImportPieChartOptions"></chart>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
              <h6 class="text-right">
                Export Output as:
                <span>
                  <i class="icon-pdf-format mr-20" (click)="downloadDataPdf()"></i>
                </span>
                <span>
                  <i class="icon-excel-format" (click)="downloadDataExcel()"></i>
                </span>
              </h6>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>