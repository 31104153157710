import { Component, OnInit } from '@angular/core';
import {
  areaData2018, bar_ChartData,
  bubble_ChartData, candle_ChartData, guageData2018,
  lineData2018, map_ChartData,
  pieChartData2018, scatter_ChartData,
  yMulti2018, yMulti2017,
  guageData2017, pieChartData2017,
  areaData2017, lineData2017
} from './data/data';
import { Statistics, UserDashboardData } from './models/Statistics';
import { UserDashboardService } from './userDashboardService';
import { StorageService } from '../../storageService/storageService';
import { TranslateService } from 'ng2-translate';
import { FxContext } from '@agility/frameworkcore';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './userDashboardComponent.html',
})

export class userDashboardComponent implements OnInit {
  public areaData: any;
  public areaPrevYrData: any;
  public guageData: any;
  public guagePrevYrData: any;
  public stackedChartData: any;
  public stackedChartPrevYrData: any;
  public lineData: any;
  public linePrevYrData: any;
  public pieChartData: any;
  public pieChartPrevYrData: any;
  public bar_ChartData: any;
  public map_ChartData: any;
  public candle_ChartData: any = candle_ChartData;
  public bubble_ChartData: any = bubble_ChartData;
  public scatter_ChartData: any = scatter_ChartData;
  public date: Date;
  public statisticsData: Statistics;
  public userDashboard: UserDashboardData;
  public years: any = [];

  constructor(private userDashboradService: UserDashboardService,    public webStorageService: StorageService,
    private translate: TranslateService,     private fxContext: FxContext
  ) {
    
  }
  public countries = [
    { value: 'ma', viewValue: 'Malaysia' },
  ];


  // public years = [

  //   { value: '2020', viewValue: '2020' },
  //   { value: '2021', viewValue: '2021' }
  // ];

  ngOnInit() {
    this.getYears();
    this.date = new Date();
    this.userDashboradService.getStats().subscribe((res) => {
      this.userDashboard = res;
      this.statisticsData = this.userDashboard.statistics
        ||
      {
        berthAvailability: null,
        exportBills: 1800,
        importBills: 1250,
        manifestReceived: 3450,
        totalContainers: 990,
        vesselsInPort: null,
        manifestsProcessed: 4769,
        doProcessed: 7913
      };
      this.translateStackedChartData(this.userDashboard.bolStackedChartByStatusCurrentYear || yMulti2018);
      this.translateStackedChartPrevYrData(this.userDashboard.bolStackedChartByStatusPreviousYear || yMulti2017);
      this.translatePieChartData(this.userDashboard.importationByVesselTypeCurrentYear || pieChartData2018);
      this.translatePieChartPrevYrData(this.userDashboard.importationByVesselTypePreviousYear || pieChartData2017);
      this.translateGuageData(this.userDashboard.arrivalByMotCurrentYear || guageData2018);
      this.translateGuagePrevYrData(this.userDashboard.arrivalByMotPreviousYear || guageData2017);
      this.translateAreaData(this.userDashboard.manifestByModeTypeCurrentYear || areaData2018);
      this.translateAreaPrevYrData(this.userDashboard.manifestByModeTypePreviousYear || areaData2017);
      this.translateBarChartData(this.userDashboard.topExportingCountryCurrentYear || bar_ChartData);
      this.translateLineData(this.userDashboard.transactionProcessedCurrentYear || lineData2018);
      this.translateLinePrevYrData(this.userDashboard.transactionProcessedPreviousYear || lineData2017);
      this.map_ChartData = this.userDashboard.importationValueByCountryCurrentYear || map_ChartData;
      // this.stackedChartData = this.translateLabels(this.userDashboard.bolStackedChartByStatusCurrentYear || yMulti2018);
      // this.stackedChartPrevYrData = this.userDashboard.bolStackedChartByStatusPreviousYear || yMulti2017;
      // this.guageData = this.userDashboard.arrivalByMotCurrentYear || guageData2018;
      // this.guagePrevYrData = this.userDashboard.arrivalByMotPreviousYear || guageData2017;
      // this.pieChartData = this.userDashboard.importationByVesselTypeCurrentYear || pieChartData2018;
      // this.pieChartPrevYrData = this.userDashboard.importationByVesselTypePreviousYear || pieChartData2017;
      // this.areaData = this.userDashboard.manifestByModeTypeCurrentYear || areaData2018;
      // this.areaPrevYrData = this.userDashboard.manifestByModeTypePreviousYear || areaData2017;
      // this.bar_ChartData = this.userDashboard.topExportingCountryCurrentYear || bar_ChartData;
      // this.lineData = this.userDashboard.transactionProcessedCurrentYear || lineData2018;
      // this.linePrevYrData = this.userDashboard.transactionProcessedPreviousYear || lineData2017;
    });
  }
  translateStackedChartData(data) { 
     data.forEach(item => 
      { item.name = this.translate.instant(item.name);
        item.series.forEach(seriesItem => 
          { seriesItem.name = this.translate.instant(seriesItem.name);
           }); }); 
          this.stackedChartData = data; 
    }
    translateStackedChartPrevYrData(data) { 
      data.forEach(item => 
       { item.name = this.translate.instant(item.name);
         item.series.forEach(seriesItem => 
           { seriesItem.name = this.translate.instant(seriesItem.name);
            }); }); 
           this.stackedChartPrevYrData = data; 
     }
    public getYears(){
    const languageId = this.fxContext.languageId === undefined ? 1 : this.fxContext.languageId;
      if(languageId === 1){
        this.years = [
          { value: '2020', viewValue: '2020' },
          { value: '2021', viewValue: '2021' }
        ];
      } else {
        this.years = [
          { value: '2020', viewValue: 'ألفين وعشرون' },
          { value: '2021', viewValue: 'ألفين وواحد وعشرون' }
        ];
      }
    }
    translatePieChartData(data) { 
      data.forEach(item => 
       { 
        item[0] = this.translate.instant(item[0]);
        }); 
        this.pieChartData = data; 
     }
     translatePieChartPrevYrData(data) { 
      data.forEach(item => 
       { 
        item[0] = this.translate.instant(item[0]);
        }); 
        this.pieChartPrevYrData = data; 
     }
     translateGuageData(data) { 
      data.forEach(item => 
       { 
        item.name = this.translate.instant(item.name);
        }); 
        this.guageData = data; 
     }
     translateGuagePrevYrData(data) { 
      data.forEach(item => 
       { 
        item.name = this.translate.instant(item.name);
        }); 
        this.guagePrevYrData = data; 
     }

     translateAreaData(data) { 
      var itemCount = 0;
      data.forEach(item => 
        { 
          if(itemCount == 0){
            item[0] = this.translate.instant(item[0]);
            item[1] = this.translate.instant(item[1]);
            item[2] = this.translate.instant(item[2]);
            item[3] = this.translate.instant(item[3]);
          } else{
            item[0] = this.translate.instant(item[0]);
          }
          itemCount = itemCount + 1;
         }); 
        this.areaData = data; 
     }

     translateAreaPrevYrData(data) { 
      var itemCount = 0;
      data.forEach(item => 
        { 
          if(itemCount == 0){
            item[0] = this.translate.instant(item[0]);
            item[1] = this.translate.instant(item[1]);
            item[2] = this.translate.instant(item[2]);
            item[3] = this.translate.instant(item[3]);
          } else{
            item[0] = this.translate.instant(item[0]);
          }
          itemCount = itemCount + 1;
         }); 
        this.areaPrevYrData = data; 
     }
     translateBarChartData(data) { 
      data.forEach(item => 
       { 
        item[0] = this.translate.instant(item[0]);
        }); 
        console.log(data);
        this.bar_ChartData = data; 
     }
     translateLineData(data) { 
      var itemCount = 0;
      data.forEach(item => 
       { 
        if(itemCount == 0){
          item[0] = this.translate.instant(item[0]);
          item[1] = this.translate.instant(item[1]);
          item[2] = this.translate.instant(item[2]);
        } else{
          item[0] = this.translate.instant(item[0]);
        }
        itemCount = itemCount + 1;
        }); 
        this.lineData = data; 
     }
     translateLinePrevYrData(data) { 
      var itemCount = 0;
      data.forEach(item => 
       { 
        if(itemCount == 0){
          item[0] = this.translate.instant(item[0]);
          item[1] = this.translate.instant(item[1]);
          item[2] = this.translate.instant(item[2]);
        } else{
          item[0] = this.translate.instant(item[0]);
        }
        itemCount = itemCount + 1;
        }); 
        this.linePrevYrData = data; 
     }
}
