import { Component, Input, Output, OnInit, EventEmitter, OnChanges } from '@angular/core';
import { WorkflowAction } from '@agility/frameworkcore';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'mcn-new-button',
  template: `<li *ngIf="this.isNew">
                    <a  fxFlex="column" fxLayoutAlign="center center" class="subheader-navigation add-icon" [matTooltip]="this.tooliptext">
                            <i class="icon-plus cursor-pointer" (click)="create($event)"></i>
                    </a>
               </li>`
})

export class McnNewButtonComponent implements OnInit, OnChanges {
  @Input() allowedActions: WorkflowAction[];
  @Output() OnClick = new EventEmitter();
  @Input() tooliptext: any;
  public isNew: boolean;

  private _self: McnNewButtonComponent = this;

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(): void {
    this.getData();
  }

  public create(event: Event) {
    const _self = this;
    _self.OnClick.emit(event);
  }

  private getData() {
    if (!isNullOrUndefined(this.allowedActions)) {
      this.isNew = this.allowedActions.findIndex(x => x.actionName === 'New') > -1;
    }
  }
}
