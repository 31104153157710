
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SafeUnsubscriber } from '../../../common/SafeUnsubscriber';
import { StorageService } from '../../../storageService/storageService';
import { CustomsService } from '../../registration/commonRegistrationComponents/customsService';
@Component({
    selector: 'app-activated-fail',
    templateUrl: './ActivationFailComponent.html',
    providers: [CustomsService]
})
export class ActivationFailComponent extends SafeUnsubscriber  implements OnInit {
    public errorMessage: string;
    constructor(
        private router: Router,
        private webStorage: StorageService,
        private customsService: CustomsService,
    ) {
      super();
    }
    ngOnInit() {
    }

    public gotoLogin() {
        this.customsService.activate(this.webStorage.get('activationUserId')).subscribe((res) => {
            this.router.navigate(['Account/Login']);
        });
    }
}
