<footer class="footer-section-wraper">
  <div class="nav-item circular-nav-item ps-abs" fxLayout="column" style="bottom:0px; right:0px;">
    <div fxFlex="10" class="mr-2 mb-2 askMCN_icon" style="z-index:99999;" [ngClass]="{'deactive': activeClass}">
      <a class="user-dd cursor-pointer" aria-expanded="true" matTooltip="{{ 'AskMCN' | translate }}" (click)="onToggleClass()">
        <img src="../assets/images/chatBotImages/ChatBoat-Icon.png" alt="Username" style="width:50px;" />
      </a>
    </div>
    <div class="dropdown-menu-right chat-option-dropdown box-shadow-card" style="border-radius:10px;" [ngClass]="{'active ': activeClass, 'display-none-custom': !activeClass}">
      <div fxFlex="100" fxLayout="column">
        <div fxFlex="100" fxLayout="row wrap" class="p-3 main-border-bottom">
          <div fxFlex="75" fxLayoutAlign="end center">
            <img src="../assets/images/chatBotImages/Ask-MicroClear.png" />
          </div>
          <!-- <div fxFlex="47" fxLayoutAlign="start start">
            <h3 class="m-0 p-0 cl-black">Chat Bot</h3>
          </div> -->
           <div fxFlex="10">
            <i class="ml-4" (click)='download_messages()'>
             <img src="../assets/images/chatBotImages/Download_Icon.png"/></i> 
            </div>
          <div fxFlex="10">
            <i class="icon-close ml-3" (click)="onToggleClass()"></i>
          </div>
        </div>
        <div class="dropdown-wrapper p-0" fxLayout="column">
          <Chat-bot class="chat-window"
                    [token]="'0146bb6bc73f4ef4bbf44e82aa6af957'"
                    [msg]="msg"
                    [msgTemplate]='message'
                    [inputTemplate]='input'
                    (onMsgReceive)="onMsgReceive($event)">
            <ng-template #window>
            </ng-template>
          </Chat-bot>
          <ng-template #message let-text="text" let-object="object" let-sendBy="sendBy">
            <chat-msg [msg]="{text: text,sendBy: sendBy}"></chat-msg>
          </ng-template>
          <ng-template #input>
            <chat-input (change)="onChange($event.target);"></chat-input>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</footer>
