import { Component, OnInit } from '@angular/core';
import { ChatBotDataService } from '../services/ChatBotDataService';

@Component({
  selector: 'chat-input',
  templateUrl: './ChatInputComponent.html',
})
export class ChatInputComponent implements OnInit {

  constructor(public dataService: ChatBotDataService) { }

  ngOnInit() {
  }

}
