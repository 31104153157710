import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ServiceDocument } from '@agility/frameworkcore';
import { CommodityHsCodeModel } from '../../models/CommodityHsCodeModel';
import { IndexValue, SuccessErrMsgs } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';

@Injectable()
export class CommodityProfileService {
  public hsCodeServiceDocument: ServiceDocument<CommodityHsCodeModel> = new ServiceDocument<CommodityHsCodeModel>();
  public orderIndex = IndexValue.IsOne;
  public isDescending = false;

  constructor(
    private httpClient: HttpClient,
    private toasterService: ToasterService,
    public mcnConfig: McnConfig,
    public appConfig: RmsConfig
  ) { }

  public tariffDescription(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.tariffDescription + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public rulingsAssociated(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.rulingsAssociated + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keyTradersImport(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keyTradersImport + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keyTradersExport(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keyTradersExport + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keyProducers(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keyProducers + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keySectors(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keySectors + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public appliedTariffs(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.appliedTariffs + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public lpco(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.lpco + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public handleError() {
    return this.toasterService.Error(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater);
  }

  public listHsCodes(expression?: string,
    aliasName?: string,
    pageIndex: number = IndexValue.IsOne,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = IndexValue.IsOne,
    isDescending: boolean = false): Observable<ServiceDocument<CommodityHsCodeModel>> {
    const filterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };

    filterModel.orderIndex = this.orderIndex;
    filterModel.isDescending = this.isDescending;

    return this.hsCodeServiceDocument.list(this.appConfig.listCommodityProfileHsCodes, filterModel)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  ///////////// Charts Service //////////////////////////////////////

  public importTariffRates(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.importTariffRates + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public exportTariffRates(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.exportTariffRates + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public trendsImport(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.trendsImport + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public trendsExport(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.trendsExport + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keySources(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keySources + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keyDestinations(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keyDestinations + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keyImportProcedure(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keyImportProcedure + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }

  public keyImportProcedureOther(id: string): Observable<any> {
    return this.httpClient.get(this.appConfig.keyImportProcedureOther + id)
      .pipe(map((res) => res), catchError(() => throwError({
        msg: this.handleError(), ngNavigationCancelingError: true
      })));
  }
}
