import { Injectable } from '@angular/core';

export interface MyData {
    declarationId: number;
    nvdbMarketComId: number;
    nvdbMarketVehicleId: number;
    tariffItemSpecId: number;
    nvdbHistoricalDataPrimaryKey: number;
    traderId: number;
    hsCode: string;
    opmReportName: string;
    opmEditReportName: string;
}

@Injectable()
export class RiskIdentificationAndAnalysisSharedService {
    sharingData: MyData = {
        declarationId: 0,
        nvdbMarketComId: 0,
        nvdbMarketVehicleId: 0,
        tariffItemSpecId: 0,
        nvdbHistoricalDataPrimaryKey: 0,
        traderId: 0,
        hsCode: '',
        opmReportName: '',
        opmEditReportName: '',
    };

    constructor() {
    }
}
