import { Component, OnInit, ChangeDetectorRef, NgModule } from '@angular/core';
import { TranslateService } from 'ng2-translate';
import { McnConfig } from '../../mcnConfig/McnConfig';
import { DataSource } from '@angular/cdk/table';
import { StorageService } from '../../storageService/storageService';
import { Observable, of } from 'rxjs';
import { CommonRemarksHistoryModel } from './CommonRemarksHistoryModel';
import { Index } from '../../common/Constants';

export interface Data { }

export class RemarksHistoryDataSource extends DataSource<any> {
  constructor(private data: Data[]) {
    super();
  }
  connect(): Observable<Data[]> {
    return of(this.data);
  }

  disconnect() { }
}
@Component({
  selector: 'app-remarks-history',
  templateUrl: './CommonRemarksHistoryComponent.html',
})

export class CommonRemarksHistoryComponent implements OnInit {
  public commonRemarksHistoryData: CommonRemarksHistoryModel[] = [];
  public remarksModelData: CommonRemarksHistoryModel = new CommonRemarksHistoryModel();
  public commonRemarksHistoryDisplayedColumns = ['From', 'Status', 'DateTime', 'Comments'];
  public commonRemarksHistoryDataSource: RemarksHistoryDataSource | null;
  private isSortAscDesc = true;
  public isShowLeadTime = true;
  constructor(
    public webStorage: StorageService,
    public translate: TranslateService,
    private ref: ChangeDetectorRef,
    public appConfig: McnConfig) { }

  public ngOnInit() {
    this.setRemarksHistoryDataSource();
    this.checkLeadTime();
  }
  public getStateBasedRemarks(event: any, remarksModel: any) {
    this.remarksModelData = remarksModel;
    this.ref.markForCheck();
  }

  public sortRemarksHistoryList() {
    this.commonRemarksHistoryData = this.appConfig.sortList(this.commonRemarksHistoryData, this.isSortAscDesc);
    this.setRemarksHistoryDataSource();
  }

  private setRemarksHistoryDataSource() {
    this.commonRemarksHistoryDataSource = new RemarksHistoryDataSource(this.commonRemarksHistoryData);
    this.ref.markForCheck();
  }

  public checkLeadTime() {
    if (this.commonRemarksHistoryData[0].daysTakenToComplete === Index.zero &&
      this.commonRemarksHistoryData[0].finalDaysTakenToComplete === Index.zero) {
      this.isShowLeadTime = true;
    } else if (this.commonRemarksHistoryData[0].finalDaysTakenToComplete > Index.zero) {
      this.isShowLeadTime = false;
    }
  }
}

// @NgModule({
//   imports: [],
//   exports: [CommonRemarksHistoryComponent],
//   declarations: [CommonRemarksHistoryComponent],
// })
// export class CommonRemarksHistoryComponentModule { }


