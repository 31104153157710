import { NgModule } from '@angular/core';
import { McnControlsModule } from '../mcnControls/McnControlsModule';
import { CommonRemarksHistoryService } from './remarksHistory/CommonRemarksHistoryService';
import { CommonRemarksHistoryComponent } from './remarksHistory/CommonRemarksHistoryComponent';
import { ReturnForCorrectionComponent } from './returnForCorrection/ReturnForCorrectionComponent';
import { CommonSharedService } from '../common/CommonSharedService';

@NgModule({
  imports: [
    McnControlsModule
  ],
  declarations:
    [
      CommonRemarksHistoryComponent,
      ReturnForCorrectionComponent,
    ],
  exports:
    [
      CommonRemarksHistoryComponent,
      ReturnForCorrectionComponent,
    ],
  entryComponents:
    [
      CommonRemarksHistoryComponent,
      ReturnForCorrectionComponent,
    ],
  providers: [CommonRemarksHistoryService, CommonSharedService]
})
export class CommonRemarksModule { }
