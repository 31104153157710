<div class="wrapper-signin" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}" style="height:657px">
  <div fxLayout="row" fxFlexFill>
    <!--<div fxFlex="100" fxFlex.sm="41.67" fxFlex.gt-sm="33.33" fxLayout="column" fxLayoutAlign="start center" class="login-logo-wrapper" fxHide.lt-sm="true">
      <app-logo class="login-left-sec" fxFlex="100"></app-logo>
    </div>
      <h1></h1>-->
    <div fxFlex="100" fxFlex.sm="100" fxFlex.gt-sm="100" class="p-relative">
      <app-images-carousel></app-images-carousel>
      <div class="login-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div class="lang-dropdown">
          <!--<p class="cursor-pointer mr-2 estimator-content" (click)="openTariffDutyEstimator()">{{'Tariff and Duty Estimator'}}</p>-->
          <a href="javascript:void(0)" (click)="openLanguageSelectionDialog()">
            <i class="language-flag"><img src="{{this.storageService.get('lanPath')}}" alt="language"></i>
          </a>
          <span><a href="javascript:void(0)" class="language-selection-content" (click)="openLanguageSelectionDialog()">{{this.storageService.get("lang_code") != "Arb" ? (this.storageService.get("lang_code") != "en"? this.storageService.get("lang_code") == "fr"?"French":"Malay":"English") : "Arabic" | translate }}</a></span>
        </div>
        <div class="login-inner m-3 m-sm-0 full-height" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="space-around center" *ngIf="!estimator">
          <div fxHide.gt-sm="true" fxHide.sm="true" fxLayout="column">
            <!-- <div fxLayoutAlign="center center" fxLayoutAlign.xs="start center" fxLayout="column">
              <img src="../../../assets/images/Saudi_logo_mobile.png" class="pb-2 saudiLogin-mobile" alt="Saudi Customs" />
              <img src="../../../assets//images/SaudiLine.png" />
            </div> -->
            <div fxLayoutAlign="center center" fxLayoutAlign.xs="start center" class="" fxHide.gt-sm="true" href="" fxLayout="column">
              <img src="../../../assets/images/MicroClear-LogoSB.png" class="p-3" alt="MicroClear" />
              <img src="../../../assets//images/SaudiLine.png" />
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center" class="width84">
            <h3>
              <a class="sign-in-link" (click)="goToIDPServerLogin()">{{'Signin' | translate }}</a>
              <a class="sign-up-link" [routerLink]="'/registration/selection'" routerLinkActive="active">{{'SignUp' | translate }}</a>
            </h3>
            <p>{{'Useyourindividualororganizationalaccountcredentials' | translate }}</p>
            <div *ngIf="!isMicroService">
              <form fxFlexFill novalidate (ngSubmit)="validate()" [formGroup]="loginForm">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                  <div fxFlex="100" class="login-form-group arabicLogin">
                    <mcn-input [formGroup]="loginForm" ngDefaultControl controlName="email" placeholder="{{'Email' | translate }}" class="astric-star login-input"></mcn-input>
                    <span class="form-icon"> <i class="icon-username"></i></span>
                    <mcn-error-messages [control]="loginForm.controls.email" customErrorMsg="{{'Emailis' | translate }}"></mcn-error-messages>
                  </div>
                  <div fxFlex="100" class="login-form-group arabicLogin">
                    <mcn-input type="password" [formGroup]="loginForm" ngDefaultControl controlName="password" placeholder="{{'Password' | translate}}" class="astric-star login-input"></mcn-input>
                    <span class="form-icon"> <i class="icon-password"></i> </span>
                    <mcn-error-messages [control]="loginForm.controls.password" customErrorMsg="{{'Passwordis' | translate }}"></mcn-error-messages>
                  </div>

                  <!--<div fxFlex="100" id="captchaDiv" class="login-form-group arabicLogin">
                    <botdetect-captcha captchaStyleName="refreshCaptcha" help-link-enabled="false"></botdetect-captcha><br />
                    <span>{{'CaptchInfoMsg' | translate}}</span>
                    <mcn-input [formGroup]="loginForm" ngDefaultControl optionalId="userCaptchaInput" controlName="userCaptchaInput"
                               type="text" placeholder="{{'TypeHere' | translate}}" class="astric-star login-input"></mcn-input>
                  </div>-->
                  <div fxFlex="100">
                    <a href="javascript:void(0)" (click)="openForgotPWDDialog($event)" class="forgot-password">
                      {{'ForgotPassword' | translate }}
                    </a>
                  </div>
                  <div fxFlex="100" class="login-button-container pt-3 pb-3" fxLayout="row" fxLayoutAlign="center center">
                    <button md-raised-button type="submit" class="btn-custom-primary login-button">{{'Signin' | translate }}</button>
                  </div>
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                    <mcn-checkbox [formGroup]="loginForm" ngDefaultControl controlName="rememberMe" text="{{'Remembermeonthiscomputer' | translate }}" class="remember-me-common"></mcn-checkbox>
                    <a href="javascript:void(0)" class="need-help">{{'Needhelp' | translate }}</a>
                  </div>

                </div>
              </form>
            </div>

          </div>
        </div>
        <!--
        <div class="nav-item circular-nav-item ps-abs" fxLayout="column" style="bottom:0px; right:0px;">
          <div class="dropdown-menu-right chat-option-dropdown box-shadow-card" style="border-radius:10px;" [ngClass]="{'active': activeClass}">
            <div fxFlex="100" fxLayout="column">
              <div fxFlex="100" fxLayout="row wrap" class="p-3 main-border-bottom">
                <div fxFlex="43" fxLayoutAlign="start center">
                  <img src="../../../assets/images/MC_Logo.png" />
                </div>
                <div fxFlex="47" fxLayoutAlign="start start">
                  <h3 class="m-0 p-0 cl-black">Chat Bot</h3>
                </div>
                <div fxFlex="10" fxLayoutAlign="center center">
                  <i class="icon-close" (click)="onToggleClass()"></i>
                </div>
              </div>
              <div class="dropdown-wrapper p-0" fxLayout="column">
                <Chat-bot class="chat-window"
                          [token]="'0146bb6bc73f4ef4bbf44e82aa6af957'"
                          [msg]="msg"
                          [msgTemplate]='message'
                          [inputTemplate]='input'
                          (onMsgReceive)="onMsgReceive($event)">
                  <ng-template #window>
                  </ng-template>
                </Chat-bot>
                <ng-template #message let-text="text" let-object="object" let-sendBy="sendBy">
                  <chat-msg [msg]="{text: text,sendBy: sendBy}"></chat-msg>
                </ng-template>
                <ng-template #input>
                  <chat-input (change)="onChange($event.target);"></chat-input>
                </ng-template>
              </div>
            </div>
          </div>
          <div fxFlex="10" class="mr-2 mb-2 askMCN_icon" style="z-index:99999;" [ngClass]="{'deactive': activeClass}">
            <a class="user-dd cursor-pointer" aria-expanded="true" matTooltip="{{ 'AskMCN' | translate }}" (click)="onToggleClass()">
              <img src="../../../assets/images/chatBotImages/ChatBoat-Icon.png" alt="Username" style="width:50px;" />
            </a>
          </div>
        </div>
        -->

        <mat-tab-group #tabGroup [selectedIndex]="this.selectedTab" (selectedTabChange)="tabChanged($event)" fxLayout="column"
                       fxLayoutAlign="center center" fxLayoutAlign.xs="space-around center" *ngIf="estimator" class="tariff-duty-estimator"
                       style="opacity:0.6; width:590px;">
          <mat-tab label="{{'TARIFF AND POLICY SEARCH'| translate}}" (click)="openTariffSearch()">
            <div fxLayout="column wrap">
              <form [formGroup]="tariffPolicyDtls" class="width556" novalidate *ngIf="tariff">
                <div fxLayout="column wrap">
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2 ps-rel mr-4">
                      <mcn-autocomplete [optionsData]="this.options" [formGroup]="tariffPolicyDtls" ngDefaultControl optionKey="id"
                                        optionValue="name" [formControl]="tariffPolicyDtls.controls.hsCode"
                                        placeholder="{{'Select HS Code'| translate}}">
                      </mcn-autocomplete>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="33" class="p-2 ps-rel">
                      <p class="preview-content font-bold"><span>{{'Select Procedure'| translate}}</span></p>
                      <mcn-radio-group [formGroup]="tariffPolicyDtls" controlName="radio" [optionsData]="radioData" optionKey="radioID" optionValue="radioName" ngDefaultControl></mcn-radio-group>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2 ps-rel">
                      <mcn-select [formGroup]="tariffPolicyDtls" controlName="country" placeholder="{{'Country/Region'| translate}}"></mcn-select>
                    </div>
                  </div>
                </div>
                <div class="orange-grey-theme mt-2" fxLayoutAlign="end end">
                  <div class="button-container ps-rel" fxLayout="row">
                    <mcn-button type="button" text="Search" cssClass="btn-custom-primary" (click)="searchTariffResults()"></mcn-button>
                    <mcn-button type="button" text="Cancel" cssClass="btn-custom-secondary-filter" (click)="cancel()" class="ml-3"></mcn-button>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>
          <mat-tab label="{{'SIMPLIFIED DUTY AND TAX ESTIMATOR'| translate}}" (click)="openDutyEstimator()">
            <div fxLayout="column wrap">
              <form [formGroup]="dutyTaxEstimatorDtls" class="width556" novalidate *ngIf="duty">
                <div fxLayout="column wrap">
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-sm="100" class="p-2 ps-rel">
                      <mcn-radio-group [formGroup]="dutyTaxEstimatorDtls" controlName="radio" [optionsData]="radioData" optionKey="radioID" optionValue="radioName" ngDefaultControl></mcn-radio-group>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
                      <mcn-input [formGroup]="dutyTaxEstimatorDtls" controlName="importingFrom" placeholder="{{'Importing From'| translate}}"></mcn-input>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
                      <mcn-input [formGroup]="dutyTaxEstimatorDtls" controlName="currency" placeholder="{{'Currency'| translate}}"></mcn-input>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
                      <mcn-input [formGroup]="dutyTaxEstimatorDtls" controlName="hsCode" placeholder="{{'HS Code'| translate}}"></mcn-input>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
                      <mcn-input [formGroup]="dutyTaxEstimatorDtls" controlName="itemDesc" placeholder="{{'Item Description (Or)'| translate}}"></mcn-input>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
                      <mcn-input [formGroup]="dutyTaxEstimatorDtls" controlName="cif" placeholder="{{'CIF Price (Per Unit)'| translate}}"></mcn-input>
                    </div>
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2 ps-rel">
                      <mcn-autocomplete [optionsData]="this.options" [formGroup]="dutyTaxEstimatorDtls" ngDefaultControl optionKey="id"
                                        optionValue="name" [formControl]="dutyTaxEstimatorDtls.controls.quantity"
                                        placeholder="{{'Quantity (In UOMs)'| translate}}">
                      </mcn-autocomplete>
                    </div>
                  </div>
                  <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <div fxFlex="100" fxFlex.gt-xs="50" class="p-2 ps-rel">
                      <mcn-select [formGroup]="dutyTaxEstimatorDtls" controlName="specifications" placeholder="{{'Add Specifications'| translate}}"></mcn-select>
                    </div>
                  </div>
                </div>
                <div class="orange-grey-theme mt-2" fxLayoutAlign="end end">
                  <div class="button-container ps-rel" fxLayout="row">
                    <mcn-button type="button" text="Calculate Duty and Taxes" cssClass="btn-custom-primary" (click)="dutyTaxCharges()"></mcn-button>
                    <mcn-button type="button" text="Cancel" cssClass="btn-custom-secondary-filter" (click)="cancel()" class="ml-3"></mcn-button>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
