import { ServiceDocument } from '@agility/frameworkcore';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { RmsConfig } from '../../../../mcnConfig/RmsConfig';
import { ToasterService } from '../../../../toastrService/toasterService';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NVDBMarketCommodityModel } from '../../models/NVDBMarketCommodityModel';
import { NVDBMarketSpecificationsModel } from '../../models/NVDBMarketSpecificationsModel';
import { RiskIdentificationAndAnalysisSharedService } from '../sharedServices/RiskIdentificationAndAnalysisSharedService';
import { ConstantNumber, QueryStringParameter, SuccessErrMsgs } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';

@Injectable()
export class NVDBMarketCommodityService {
  public nvdbCommodityServiceDocument: ServiceDocument<NVDBMarketCommodityModel> = new ServiceDocument<NVDBMarketCommodityModel>();
  public nvdbSpecServiceDocument: ServiceDocument<NVDBMarketSpecificationsModel> = new ServiceDocument<NVDBMarketSpecificationsModel>();
  public nvdbHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private Http: HttpClient,
    private toasterService: ToasterService,
    public rmsConfig: RmsConfig,
    public mcnConfig: McnConfig,
    private riskIdentificationAndAnalysisSharedService: RiskIdentificationAndAnalysisSharedService
  ) { }

  public newNVDBMarketCommodity(): Observable<ServiceDocument<NVDBMarketCommodityModel>> {
    return this.nvdbCommodityServiceDocument.new(this.rmsConfig.newNVDBMarketCommodity)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public listNVDBMarketCommodity(
    expression: string,
    aliasName: string,
    pageIndex: number = ConstantNumber.IsOne,
    recordPerPage: number = this.mcnConfig.appData.pageCount,
    orderIndex: any = ConstantNumber.IsOne,
    isDescending: boolean = true): Observable<ServiceDocument<NVDBMarketCommodityModel>> {
    const filterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };
    return this.nvdbCommodityServiceDocument.list(
      this.rmsConfig.listNVDBMarketCommodity, filterModel).pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public openNVDBMarketCommodity(): Observable<ServiceDocument<NVDBMarketCommodityModel>> {
    return this.nvdbCommodityServiceDocument.open(
      this.rmsConfig.openNVDBMarketCommodity, new HttpParams()
        .set(QueryStringParameter.id, this.riskIdentificationAndAnalysisSharedService.sharingData.nvdbMarketComId.toString())
    ).pipe(map((response) => response), catchError(() => throwError({
      msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
    })));
  }

  public deleteNVDBMarketCommodity(id: number): Observable<any> {
    return this.Http.post(this.rmsConfig.deleteNVDBMarketCommodity, new HttpParams()
      .set(QueryStringParameter.NVDBMarketComId, id.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public submitNVDBMarketCommodity(): Observable<any> {
    return this.nvdbCommodityServiceDocument.submit(this.rmsConfig.submitNVDBMarketCommodity)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }


  /*************** NVDB Market Commodity Specifications **************************************************/


  public newNVDBMarketCommoditySpecs(): Observable<ServiceDocument<NVDBMarketSpecificationsModel>> {
    return this.nvdbSpecServiceDocument.new(this.rmsConfig.newNVDBMarketCommoditySpecifications)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public listNVDBMarketCommoditySpecs(
    expression: string,
    aliasName: string,
    pageIndex: any = ConstantNumber.IsOne,
    recordPerPage: any = this.mcnConfig.appData.pageCount,
    orderIndex: any = ConstantNumber.IsOne,
    isDescending: boolean = true
  ): Observable<ServiceDocument<NVDBMarketSpecificationsModel>> {

    const filterModel = {
      expression: expression,
      aliasName: aliasName,
      pageIndex: pageIndex,
      recordPerPage: recordPerPage,
      orderIndex: orderIndex,
      isDescending: isDescending
    };
    return this.nvdbSpecServiceDocument.list(
      this.rmsConfig.listNVDBMarketCommoditySpecifications, filterModel)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public openNVDBMarketCommoditySpecs(): Observable<ServiceDocument<NVDBMarketSpecificationsModel>> {
    return this.nvdbSpecServiceDocument.open(
      this.rmsConfig.openNVDBMarketCommoditySpecifications, new HttpParams()
        .set(QueryStringParameter.id, this.riskIdentificationAndAnalysisSharedService.sharingData.tariffItemSpecId.toString())
    ).pipe(map((response) => response), catchError(() => throwError({
      msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
    })));
  }

  public deleteNVDBMarketCommoditySpecs(id: number): Observable<any> {
    return this.Http.post(this.rmsConfig.deleteNVDBMarketCommoditySpecifications,
      new HttpParams().set(QueryStringParameter.tariffItemSpecId, id.toString()))
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public submitNVDBMarketCommoditySpecs(): Observable<any> {
    return this.nvdbSpecServiceDocument.submit(this.rmsConfig.submitNVDBMarketCommoditySpecifications)
      .pipe(map((response) => response), catchError(() => throwError({
        msg: this.NVDBMarketCommodityHandleError(), ngNavigationCancelingError: true
      })));
  }

  public NVDBMarketCommodityHandleError() {
    return this.toasterService.Error(this.mcnConfig.getHardCodeMSg(SuccessErrMsgs.InternalServerErrorPleaseTryAgainLater), null);
  }

  private extractSubmitData(response: any) {
    const body = response;
    return body || [];
  }
}
