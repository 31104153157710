import { Component, Input } from '@angular/core';

@Component({
  selector: 'mcn-scroll-pagination',
  template: `
<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="end end" *ngIf="this.totalCount > 0">
    <div fxLayout="column" class="mt-3" fxFlex="35" fxFlex.sm="25" fxFlex.xs="50" fxLayoutAlign="end end" fxLayoutAlign.sm="end end">
       <p style="color: #757171 !important;font-weight: bold"> Records : {{ this.pageCount }} of {{ this.totalCount }}</p>
    </div>
</div>
<div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="this.totalCount === 0"> 
   <img src="../../../assets/images/No-Records-Found.png" alt="No Records">
   <span class="no-records-found">{{'NoRecordsFound' | translate}}</span>
</div> `
})

export class McnScrollPaginationComponent {
  @Input() totalCount: number;
  @Input() pageCount: number;
  @Input() currentPage: number;

  constructor() {
  }

  ngOnInit(): void {
  }
}
