<form [formGroup]="changePasswordForm" class="forget-pwd-form my-4" novalidate
      (ngSubmit)="validateChangePassword(changePasswordForm.value, changePasswordForm.valid)" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div class="account-info">
    <div fxLayout="column" class="info-form-wrapper">
      <div fxLayout="row">
        <div fxFlex>
          <h5 class="main-title">{{'ChangePassword' | translate }}</h5>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="35" fxFlex.xs="100" class="form-mat-group p-2 position-relative">
          <mcn-input placeholder="{{'RegEmail' | translate }}" [readonly]="true" ngDefaultControl controlName="emailId" [formGroup]="changePasswordForm" class="astric-star"></mcn-input>
          <mcn-error-messages [control]="changePasswordForm.controls.emailId" customErrorMsg="{{'Emailis' | translate }}"></mcn-error-messages>
        </div>
        <div fxFlex="10" fxFlex.xs="100">
        </div>
        <div fxFlex="35" fxFlex.xs="100" class="form-mat-group p-2 position-relative">
          <mcn-input placeholder="{{'OldPassword'|translate}} " controlName="currentPassword" type="password" [formGroup]="changePasswordForm" class="astric-star"></mcn-input>
          <mcn-error-messages [control]="changePasswordForm.controls.currentPassword" customErrorMsg="{{'Passwordis' | translate }}"> </mcn-error-messages>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="35" fxFlex.xs="100" fxFlexOrder.xs="1" class="form-mat-group p-2 position-relative">
          <mcn-input placeholder="{{'Password' | translate }}" ngDefaultControl controlName="newPassword" type="password" [formGroup]="changePasswordForm" class="astric-star"></mcn-input>
          <mcn-error-messages [control]="changePasswordForm.controls.newPassword" customErrorMsg="{{'Passwordis' | translate }}"> </mcn-error-messages>
        </div>
        <div fxFlexOrder.xs="2" fxLayoutAlign="start center" class="pull-strength">
          <div class="strength" fxLayout="row">
            <small fxLayoutAlign="start center">{{'Strength' | translate }}:</small>
            <ul class="strengthBar">
              <li class="point" [style.background-color]="bar0"></li>
              <li class="point" [style.background-color]="bar1"></li>
              <li class="point" [style.background-color]="bar2"></li>
              <li class="point" [style.background-color]="bar3"></li>
              <li class="point" [style.background-color]="bar4"></li>
            </ul>
          </div>
        </div>
        <div fxFlex="35" fxFlexOrder.xs="3" class="p-2 form-mat-group p-2 position-relative">
          <mcn-input placeholder="{{'ConfirmPassword' | translate }}" ngDefaultControl #confirmPassword controlName="confirmPassword" [formGroup]="changePasswordForm" type="password" (OnKeypress)="cnfrmPasskey($event)" class="astric-star"></mcn-input>
          <mcn-error-messages [control]="changePasswordForm.controls.confirmPassword" customErrorMsg="{{'confirmPasswordis' | translate }}"></mcn-error-messages>
          <div class="mcn-error" *ngIf="notSame"> {{'Password' | translate }}  <strong>{{'mismatch' | translate }}</strong></div>
        </div>
      </div>
      <div fxLayout="row">
        <p class="password-policy-note" fxLayoutAlign="start center">
          {{'RegNote' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="form-footer px-5 button-container" fxLayout="row" fxLayoutAlign="end end">
    <mcn-button cssClass="btn-custom-primary" type="submit" text="{{'Submit' | translate}}"></mcn-button>
    <mcn-button cssClass="btn-custom-secondary" type="button" (OnClick)="cancel()" text="{{'Cancel' | translate }}"></mcn-button>
  </div>
</form>
