import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { StorageService } from '../../storageService/storageService';

@Component({
  selector: 'app-search-modal',
  templateUrl: './SearchModalComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush, // for only ExpressionChangedAfterItHasBeenCheckedError
})

export class SearchModalComponent implements OnInit, AfterViewInit {
  public searchModalInfoForm: FormGroup;
  public searchModalInfoSubmitted: boolean;
  public toggleName = 'Expand';
  public toggleValue = false;
  public toggleClass = 'icon-down-caret';

  constructor(private _fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public storageService: StorageService,
    private dialogRef: MatDialogRef<SearchModalComponent>
  ) {
  }

  // Search Dialog close
  public closeSearchDialog() {
    this.dialogRef.close();
  }

  public ngAfterViewInit() {
    this.searchModalInfoForm = this._fb.group({
      declarationDate: this._fb.group({
        declarationDate: ['', [Validators.required as any]],
      }),
      advanceSelection: ['', [Validators.required as any]],
      consigneeName: ['', [Validators.required as any]],
      modeOfTransport: ['', [Validators.required as any]],
      declarationNo: ['', [Validators.required as any]],
      tempDeclarationNo: ['', [Validators.required as any]],
      trackingID: ['', [Validators.required as any]],
      declarationType: ['', [Validators.required as any]],
      declarationSource: ['', [Validators.required as any]],
      customStation: ['', [Validators.required as any]],
      portOfShipment: ['', [Validators.required as any]],
      countryOfOrigin: ['', [Validators.required as any]],
      duty: ['', [Validators.required as any]],
      dutyValue: ['', [Validators.required as any]],
      hsCode: ['', [Validators.required as any]],
      billOfLadingNo: ['', [Validators.required as any]],
      permitNo: ['', [Validators.required as any]],
      exemptionNo: ['', [Validators.required as any]],
      licenseNo: ['', [Validators.required as any]],
      search: ['', [Validators.required as any]],
    });
    setTimeout(() => this.cdr.reattach()); // for Expression has changed after it was checked
  }

  ngOnInit() {
    this.cdr.detach(); // for Expression has changed after it was checked
  }

  public onDateChange(event: any, input: any) {
    if (input === 'declarationDate') {
      (this.searchModalInfoForm.controls.declarationDate).patchValue(event);
    }
  }

  public validatesearchModalInfoForm(isValid: boolean) {
    this.searchModalInfoSubmitted = true; // set form submit to true
  }
  public valueChangeEvent(event: any, inputType: any) {
    // general not added
    if (inputType === 'modeOfTransport') {
      this.searchModalInfoForm.controls.modeOfTransport.patchValue(event);
    }
    if (inputType === 'declarationType') {
      this.searchModalInfoForm.controls.declarationType.patchValue(event);
    }
    if (inputType === 'declarationSource') {
      this.searchModalInfoForm.controls.declarationSource.patchValue(event);
    }
    if (inputType === 'advanceSelection') {
      this.searchModalInfoForm.controls.advanceSelection.patchValue(event);
    }
  }

  public handleToggle() {
    if (this.toggleName === 'Expand') {
      this.toggleName = 'Collapse';
      this.toggleValue = true;
      this.toggleClass = 'icon-up-caret';
    } else {
      this.toggleName = 'Expand';
      this.toggleValue = false;
      this.toggleClass = 'icon-down-caret';
    }
  }
}
