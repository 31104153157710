import { AppSettings } from '../app/app.settings';

export const environment = {
  production: false,
  appSettings: new AppSettings(),
};

/*
export const gatewayBaseUri = 'http://localhost:50483';
export const declarationReportUri = 'http://10.138.108.29:9033';
export const anonymousServiceBaseUri = 'http://localhost:52626';
export const isMicroService = true;
export const isCoreSOA = false;
export const isChatbotActive = false;
export const chatBotBaseUrl = 'http://10.138.108.33:5012/v1/web_chatbot';
export const Environment = {
  production: false,
  appApiBaseUrl: gatewayBaseUri + '/nonCoreApi',
  registrationApiBaseUrl: gatewayBaseUri + '/registrationApi',
  cargoApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/cargoApi' : gatewayBaseUri + '/nonCoreApi',
  inspectionApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/inspectionApi' : gatewayBaseUri + '/nonCoreApi',
  resourcePlanningApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/resourcePlanningApi' : gatewayBaseUri + '/nonCoreApi',
  declarationApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/declarationApi' : gatewayBaseUri + '/nonCoreApi',
  paymentsApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/paymentsApi' : gatewayBaseUri + '/nonCoreApi',
  mdmApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/mdmApi' : gatewayBaseUri + '/nonCoreApi',
  dashboardApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/dashboardApi' : gatewayBaseUri + '/nonCoreApi',
  inboxApiBaseUrl: gatewayBaseUri + '/inboxApi',
  rmsApiBaseUrl: gatewayBaseUri + '/rmsApi',
  pcaApiBaseUrl: gatewayBaseUri + '/pcaApi',
  coreMdmApiBaseUrl: gatewayBaseUri + '/coreMdmApi',
  bwhApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/warehouseApi' : gatewayBaseUri + '/nonCoreApi',
  permitApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/permitApi' : gatewayBaseUri + '/nonCoreApi',
  licenseApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/licenseApi' : gatewayBaseUri + '/nonCoreApi',
  dfsApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/dutyfreeshopApi' : gatewayBaseUri + '/nonCoreApi',
  anonymousServiceApiBaseUrl: gatewayBaseUri + '/aggregatorAnonymousServiceApi',
  freeZoneApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/freeZoneApi' : gatewayBaseUri + '/nonCoreApi',
  serviceReceiptApiBaseUrl: isCoreSOA ? gatewayBaseUri + '/serviceReceiptApi' : gatewayBaseUri + '/nonCoreApi',
};
*/
