<div fxFlex="100" fxLayout="row wrap" class="mt-2 mb-2" fxLayoutAlign="space-around start" *ngIf="totalCount > (optionalPageCount === 0 ? appConfig.appData.pageCount : optionalPageCount)">
  <div fxLayout="row wrap" fxLayout.xs="row wrap" fxFlex="100" fxLayoutAlign="start center" fxLayoutAlign.xs="space-between center">
    <div fxFlex="35" fxFlex.xs="100" fxFlex.sm="40" class="mt-2" fxLayout="row" fxLayoutAlign.xs="space-between center">
      <div fxLayout="column" class="mt-4" [ngClass]="{ 'disabled':this.Pager.currentPage == 1}">
        <i class="first-icon" (click)="getPageNumber(1)"></i>
      </div>
      <div fxLayout="column" class="mt-4" [ngClass]="{ 'disabled':this.Pager.currentPage == 1}">
        <i class="previous-icon ml-2" (click)="getPageNumber(this.Pager.currentPage - 1)"></i>
      </div>
      <div fxFlex="9" fxFlex.xs="20" fxLayout="column" class="ml-2 mt-1">
        <mat-form-field>
          <input matInput [value]="currentPage" (keyup)="handleKeyupEvent($event.target.value)">
        </mat-form-field>
      </div>
      <div fxFlex="1" fxLayoutAlign="space-around center" style="margin-top: 5%;">/</div>
      <div fxFlex="9" fxFlex.xs="20" fxLayout="column" class="ml-1 mt-1">
        <mat-form-field>
          <input matInput [value]="this.Pager?.totalPages" readonly="true">
        </mat-form-field>
      </div>
      <div fxLayout="column" class="mt-4" [ngClass]="{disabled:this.Pager.currentPage == this.Pager.totalPages}">
        <i class="next-icon ml-2" (click)="getPageNumber(this.Pager.currentPage + 1)"></i>
      </div>
      <div fxLayout="column" class="mt-4" [ngClass]="{disabled:this.Pager.currentPage == this.Pager.totalPages}">
        <i class="last-icon ml-2" (click)="getPageNumber(this.Pager.totalPages)"></i>
      </div>
    </div>
    <div fxLayout="column" fxFlex="35" fxFlex.sm="25" fxFlex.xs="50" class="mt-2 p-2" fxLayoutAlign="center center" fxLayoutAlign.xs="center begin" fxLayoutAlign.sm="begin center">
      <p class="preview-content" style="color: #757171 !important;">{{'Total Records' | translate}} : {{ this.totalCount }}</p>
    </div>

    <div fxLayout="column" fxFlex="30" fxFlex.sm="33" fxFlex.xs="50">
      <div fxLayoutAlign.sm="begin center" fxLayoutAlign.xs="start center" fxLayoutAlign="end center">
        <div fxLayout="column" fxFlex="40" fxFlex.xs="60" fxFlex.sm="50" class="ml-4">
          <p class="label-preview" style="color: #757171; margin-top:10px;">{{'Items Per List' | translate}} : </p>
        </div>
        <div fxLayout="column" fxFlex="14" fxFlex.xs="40" fxFlex.sm="33">
          <mat-form-field class="mt-2">
            <mat-select [(value)]=" this.selected" (selectionChange)="onOptionSelect($event)">
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
              <mat-option [value]="8">8</mat-option>
              <mat-option [value]="9">9</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="15">15</mat-option>
              <mat-option [value]="20">20</mat-option>
              <mat-option [value]="25">25</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

   <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center" *ngIf="this.totalCount === 0"> 
   <img src="../../../assets/images/No-Records-Found.png" alt="No Records">
    <span class="no-records-found">{{'NoRecordsFound' | translate}}</span>
</div> 