import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { TariffitemDutyRateModel } from '../viewmodels/TariffitemDutyRateModel';
import { StorageService } from '../../../storageService/storageService';

@Component({
  selector: 'app-view-duty-rate',
  templateUrl: './ViewDutyRateComponent.html',
})
export class ViewDutyRateComponent implements OnInit {
  public tariffDutyRateDetails: TariffitemDutyRateModel;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private renderer: Renderer2,
    private dialog: MatDialogRef<ViewDutyRateComponent>,
    public webStorage: StorageService
  ) {
    this.tariffDutyRateDetails = data.dutyRateData;
  }

  ngOnInit() { }
  public cancleDutyRate(): void {
    this.dialog.close();
  }
  onDeactivate() {
    this.renderer.setProperty(document.body, 'scrollTop', 0);
  }
}
