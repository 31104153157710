<div [ngClass]="{'arb': (this.webStorageService.get('lang_code') === 'Arb')}">
    <div fxLayout="column">
        <div fxLayout="column" fxLayoutAlign="center center">
            <h1 matDialogTitle class="dialog-title">
                <span>{{ 'Language Selection' | translate }} </span>
            </h1>
            <i class="star-heading-ul"></i>
        </div>
        <div fxLayout="column">
            <div mat-dialog-content>
                <ul class="language-selection" fxFlexFill fxLayout="row" fxLayoutAlign="space-around center">
                    <li *ngFor="let language of languageList;let i=index">
                        <a href="javascript:void(0)" (click)="getSelectedLanguage(language.name)">
                            <p class="flag-img"><img src='{{language.imgPath}}' width="24" height="24" alt="language"></p>
                            <p class="flag-name mt-1 mb-0">{{language.name | translate }}</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <button matDialogClose class="dialog-close">
        <i class="icon-close"></i>
    </button>
</div>
