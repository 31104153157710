 <mat-form-field [formGroup]="formGroup" class="{{cssClass}} material-auto-complete"
              floatLabel="auto">
              <input matInput id="txtautocomplete_{{optionalId}}{{placeholder | removeSpace}}"
               type="text" (keyup)="handleKeyupEvent($event.target.value)"
                (keypress)="handleKeyPress($event.target.value)" placeholder="{{placeholder}}"
                  [formControl]="formControl" [matAutocomplete]="auto"
                   [required]="required" #autoCompleteInput />
          </mat-form-field>
          <i class="autocomplete-icon autocomplete-iconPos" appCustomTooltip title="Please
          enter minimum 3 characters"></i>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
          (optionSelected)="onOptionSelect($event)">
           <div infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="10"
                [scrollWindow]="false"
                (scrolled)="onScroll()"
                [ngStyle]="{'max-height': (this.filteredOptions != null
                  && this.filteredOptions.length >= 10) ? '175px' : '',
                   'overflow-y': 'scroll','position': 'relative','max-width': 'none'}">

             <mat-option *ngFor="let option of this.filteredOptions" [value]="option[optionKey]"
             matTooltip="{{option[optionValue]}}">
              <span  style='text-align: center' *ngIf="this.isShowAdditionalOptionValue && (option[optionValue] === 'No Records Found' || option[optionValue] === this.inputvaliationMsg)">{{option[optionValue]}}</span>
              <span  style='text-align: center' *ngIf="this.isShowAdditionalOptionValue && !(option[optionValue] === 'No Records Found' || option[optionValue] === this.inputvaliationMsg)">{{option[optionValue]}} - {{option[additionalOptionValue]}} </span>
              <span  style='text-align: center' *ngIf="!this.isShowAdditionalOptionValue">{{option[optionValue]}}</span>
            </mat-option>
         </div>
        </mat-autocomplete>