import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { IOverlayMenu } from './OverlayMenu';

@Injectable()
export class OverlayMenuService {
  constructor(private _http: Http) { }

  private handleError(error: Response) {
    const message = `Error status code ${error.status} at ${error.url}`;
    return Observable.throwError(message);
  }

  public getOverlayMenus(): Observable<IOverlayMenu[]> {
    return this._http
      .get('src/assets/api/json/overlay-menu.json')
      .map((response: Response) => response.json() as IOverlayMenu[])
      .do((data) => console.log(),
        (err) => console.log(err),
        () => console.log(''));
    // .catch(this.handleError());
  }

}
