<div class="wrapper-signin" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
  <div fxLayout="row" fxFlexFill>
    <h1></h1>
    <div fxFlex="100" fxFlex.sm="100" fxFlex.gt-sm="100" class="p-relative">
      <app-images-carousel></app-images-carousel>
      <div class="login-wrapper" fxLayout="column">
        <div class="lang-dropdown z-index">
          <a href="javascript:void(0)" (click)="openLanguageSelectionDialog()">
            <i class="language-flag"><img src="{{this.storageService.get('lanPath')}}" width="24" height="24" alt="language"></i>
          </a>
          <span><a href="javascript:void(0)" class="language-selection-content" (click)="openLanguageSelectionDialog()">{{this.storageService.get("lang_code") != "Arb" ? (this.storageService.get("lang_code") != "en"? this.storageService.get("lang_code") == "fr"?"French":"Malay":"English") : "Arabic" | translate }}</a></span>
        </div>
        <div fxFlex="100" class="m-3 m-sm-0" fxLayout="column">
          <app-logo class="login-left-sec" fxFlex="100" fxLayout="column"></app-logo>
        </div>
      </div>
    </div>
  </div>
</div>
