<div class="container dailog-section" [ngClass]="{'arb': (this.storageService.get('lang_code') === 'Arb')}">
    <div fxLayout="column" fxFlexFill>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <h1 matDialogTitle class="minusmt-27">{{'Menu'| translate}}</h1>
            <div class="head-search mt-2">
                <i class="icon-search-dark mt-2" aria-hidden="true"></i>
                <mat-form-field floatPlaceholder="never">
                    <input type="text" placeholder="{{'SearchMenu'| translate}}" matInput [(ngModel)]="menuFilter.name">
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-content>
          <div fxLayout="column" fxFlexFill class="mt-4">
            <ngx-masonry class="menu-ul overlay-menu-parentul">
              <ngxMasonryItem class="overlay-menus-titles" *ngFor='let menuitem of overlayMenusList; let i = index;'>
                <expansion-panels-container class="custom-expansion-panel-container menu-modal-expansion-panel-container">
                  <expansion-panel class="menu-modal-content">
                    <expansion-panel-title>
                      <i [className]="(menuitem.menuText | removeSpace) | lowercase"></i>
                      <h3 class="overlay-title mt-1">{{menuitem.menuText | translate}}</h3>
                    </expansion-panel-title>
                    <expansion-panel-content [ngClass]="{'show': menuFilter.name !== ''}">
                      <ul class="submenu-ul">
                        <li *ngFor='let submenuitem of menuitem.items ; let idx = index;'>
                          <a href="javascript:void(0)" (click)="closeMenuDialog(submenuitem.url)" [ngClass]="{'no-cursor': submenuitem.url === ''}">
                            <i id="menu_{{this.getMenuId(submenuitem.menuText)}}" class="icon-arrow-right"></i>
                            {{submenuitem.menuText | translate}}
                          </a>
                        </li>
                      </ul>
                    </expansion-panel-content>
                  </expansion-panel>
                </expansion-panels-container>
              </ngxMasonryItem>
            </ngx-masonry>
            <!--<masonry class="menu-ul overlay-menu-parentul">
      <masonry-brick class="overlay-menus-titles" *ngFor='let menuitem of overlayMenusList; let i = index;'>
          <expansion-panels-container class="custom-expansion-panel-container menu-modal-expansion-panel-container">
              <expansion-panel class="menu-modal-content">
                  <expansion-panel-title>
                      <i [className]="menuitem.menuText | lowercase"></i>
                      <h3 class="overlay-title">{{menuitem.menuText | translate}}</h3>
                  </expansion-panel-title>
                  <expansion-panel-content [ngClass]="{'show': menuFilter.name !== ''}">

                      <ul class="submenu-ul">
                          <li *ngFor='let submenuitem of menuitem.items ; let idx = index;'>
                              <a href="javascript:void(0)" (click)="closeMenuDialog(submenuitem.url)" [ngClass]="{'no-cursor': submenuitem.url === ''}">
                                  <i class="icon-arrow-right"></i>
                                  {{submenuitem.menuText | translate}}
                              </a>
                          </li>
                      </ul>
                  </expansion-panel-content>
              </expansion-panel>
          </expansion-panels-container>
      </masonry-brick>
  </masonry>-->
          </div>
        </div>
        <a href="javascript:void(0)" class="overlay-close" (click)="closeDialogmenu()">
            <mat-icon class="overlay-menu-remove"></mat-icon>
        </a>
    </div>
</div>
<!--Menu Popup Ended-->
