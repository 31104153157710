import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-footer',
    templateUrl: './FooterComponent.html',
})

export class FooterComponent implements OnInit {
    public ngOnInit() {
    }
}
