import { FxContext, ServiceDocument } from '@agility/frameworkcore';
import { DataSource } from '@angular/cdk/table';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';
import { SignalRService } from '../../../../signalR/signalR.service';
import { StorageService } from '../../../../storageService/storageService';
import { ShipStoreDetailsModel } from '../../../models/ShipStoreDetailsModel';
import { ShipStoreService } from '../../../services/ShipStoreService';

export interface Data { }

export class ExampleDataSource extends DataSource<any> {
  constructor(private data: Data[]) {
    super();
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Data[]> {
    return Observable.of(this.data);
  }

  disconnect() { }
}

@Component({
  selector: 'app-upld-ship-stores',
  templateUrl: './UpldShipStoresComponent.html',
})
export class UpldShipStoresComponent extends SafeUnsubscriber implements OnInit {
  public upldShipStoreDocs: FormGroup;
  private shipStoreServiceDocument: ServiceDocument<ShipStoreDetailsModel>;
  public uploaddocuments: any[] = [];
  private upLoadDocsUserId: any;
  public uploadDocsDataSource: any;
  public uploadDoctypes: any[] = [];

  constructor(private fb: FormBuilder,
    public shipStoreService: ShipStoreService,
    private dialog: MatDialog,
    private fxContext: FxContext,
    public storageService: StorageService,
    private signalRService: SignalRService,
    private dialogRef: MatDialogRef<UpldShipStoresComponent>) {
    super();
    this.uploadDoctypes = [
      { key: '1', value: 'Others' }
    ];
  }

  ngOnInit() {
    this.upldShipStoreDocs = this.fb.group({
      filePath: ['', [<any>Validators.required]],
      file: [''],
    });
    this.uploadDocsDataSource = new ExampleDataSource(this.uploaddocuments);
    this.fxContext.userProfile = JSON.parse(this.storageService.get('userProfile').toString());
    this.upLoadDocsUserId = this.fxContext.userProfile.userName;
    this.shipStoreServiceDocument = this.shipStoreService.mcserviceDocument;
    this.signalRService.startConnection();
    this.signalRService.ShowServerNotification(this.upLoadDocsUserId);
  }

  public Close() {
    this.dialogRef.close();
  }

  public uploadExcelFileUplExlFle() {
    const shipStoreDetailsModel = new ShipStoreDetailsModel();
    shipStoreDetailsModel.excelDocuments = this.uploaddocuments;
    if (this.upldShipStoreDocs.valid) {
      this.shipStoreService.submitExcelforShipStore(shipStoreDetailsModel).subscribe((response) => {
        this.dialog.closeAll();
      });
    } else {
      Object.keys(this.upldShipStoreDocs.controls).map(
        controlName => this.upldShipStoreDocs.controls[controlName]).forEach(
          control => { control.markAsTouched(); }
        );
    }
  }
  public fileChange(event: any) {
    const docdata = {
      'base64String': event.base64String,
      'fileName': event.fileName,
      'docId': 1,
      'docType': '',
      'expiryDate': new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(),
      'docRefNo': '',
    };
    this.uploaddocuments = [];
    this.uploaddocuments.push(docdata);
    this.upldShipStoreDocs.controls.filePath.setValue(docdata.fileName);
  }
}
