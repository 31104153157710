import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FrameworkCoreModule } from '@agility/frameworkcore';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HijriService, HijriModule } from 'ngx-hijri-lib';
import { ExpansionPanelsModule } from 'ng2-expansion-panels';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CdkTableModule } from '@angular/cdk/table';

import { McnPagerService } from './controls/McnPaginationService';
import { RemoveSpace } from './controls/RemoveSpace';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { McnAutoCompleteComponent } from './controls/McnAutoComplete';
import { McnSelectComponent } from './controls/McnSelectComponent';
import { McnMultiSelectComponent } from './controls/McnMultiSelectComponent';
import { McnTimerComponent } from './controls/McnTimerComponent';
import { McnButtonComponent } from './controls/McnButtonComponent';
import { McnButtonListComponent } from './controls/McnButtonListComponent';
import { McnNewButtonComponent } from './controls/McnNewButtonComponent';
import { McnCheckBoxComponent } from './controls/McnCheckboxComponent';
import { McnDatepickerComponent } from './controls/McnDatepickerComponent';
import { McnDateTimepickerComponent } from './controls/McnDateTimepickerComponent';
import { McnInputomponent } from './controls/McnInputComponent';
import { McnPaginationComponent } from './controls/McnPaginationComponent';
import { McnRadioButtonComponent } from './controls/McnRadiobuttonComponent';
import { McnTextareaComponent } from './controls/McnTextareaComponent';
import { McnErrorComponent } from './controls/McnErrorComponent';
import { McnErrorPatternComponent } from './controls/McnErrorPatternComponent';
import { McnRadioGroupComponent } from './controls/McnRadioGroupComponent';
import { McnFileUploadComponent } from './controls/McnFileUploadComponent';
import { McnHijriDatepickerComponent } from './controls/McnHijriDatepickerComponent';

import { McnEditButtonComponent } from './controls/McnEditButtonComponent';
import { McnEditIconButtonComponent } from './controls/McnEditIconButtonComponent';
import { McnDeleteIconButtonComponent } from './controls/McnDeleteIconButtonComponent';

import { McnScrollPaginationComponent } from './controls/McnScrollPaginationComponent';
import { McnUploadComponent } from './controls/mcnUploadComponent/McnUploadComponent';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LanguageModule } from '../common/LanguageModule';

import {
  MatFormFieldModule, MatTooltipModule, MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatToolbarModule,
  MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatNativeDateModule,
  MatRadioModule, MatSelectModule, MatSidenavModule, MatSnackBarModule,
  MatTabsModule, MatTableModule, MatPaginatorModule, MatExpansionModule,
  MatMenuModule, MatStepperModule
} from '@angular/material';

import { MatTreeModule } from '@angular/material/tree';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AccordionModule } from 'ngx-accordion';
import { CommonModule } from '@angular/common';
import { SharedStaticModule } from '../common/SharedStaticModule';
import { TranslateModule } from 'ng2-translate';
import { AlertMessageModalComponent } from '../commonComponents/commonModals/AlertMessageModalComponent';
@NgModule({
  imports: [
    FrameworkCoreModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularMultiSelectModule,
    HijriModule,
    InfiniteScrollModule,
    MatSelectModule,
    FormsModule, ReactiveFormsModule, ExpansionPanelsModule, CdkTableModule,
    MatFormFieldModule, MatTooltipModule, MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatToolbarModule,
    MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule,
    MatIconModule, MatInputModule, MatNativeDateModule, MatExpansionModule,
    MatRadioModule, MatSidenavModule, MatSnackBarModule, MatTabsModule, MatTableModule, MatPaginatorModule, FlexLayoutModule, MatMenuModule,
    AccordionModule, LanguageModule, FrameworkCoreModule, CommonModule, HttpClientModule, SharedStaticModule, TranslateModule,
    MatStepperModule,
    MatTreeModule
  ],
  declarations:
    [
      McnSelectComponent,
      McnMultiSelectComponent,
      McnAutoCompleteComponent,
      McnTimerComponent,
      McnButtonComponent,
      McnButtonListComponent,
      McnNewButtonComponent,
      McnCheckBoxComponent,
      McnDatepickerComponent,
      McnDateTimepickerComponent,
      McnInputomponent,
      McnPaginationComponent,
      McnRadioButtonComponent,
      McnRadioGroupComponent,
      McnTextareaComponent,
      McnErrorComponent,
      McnErrorPatternComponent,
      RemoveSpace,
      McnFileUploadComponent,
      McnHijriDatepickerComponent,
      McnEditButtonComponent,
      McnEditIconButtonComponent,
      McnDeleteIconButtonComponent,
      McnScrollPaginationComponent,
      McnUploadComponent,
      AlertMessageModalComponent
    ],
  exports:
    [
      McnAutoCompleteComponent,
      McnAutoCompleteComponent,
      McnMultiSelectComponent,
      McnSelectComponent,
      McnTimerComponent,
      McnButtonComponent,
      McnButtonListComponent,
      McnNewButtonComponent,
      McnCheckBoxComponent,
      McnDatepickerComponent,
      McnDateTimepickerComponent,
      McnInputomponent,
      McnPaginationComponent,
      McnRadioButtonComponent,
      McnTextareaComponent,
      McnErrorComponent,
      McnErrorPatternComponent,
      McnRadioGroupComponent,
      RemoveSpace,
      McnHijriDatepickerComponent,
      McnFileUploadComponent,
      McnEditButtonComponent,
      McnEditIconButtonComponent,
      McnDeleteIconButtonComponent,
      McnScrollPaginationComponent,
      McnUploadComponent,
      MatSelectModule, AngularMultiSelectModule,
      FormsModule, ReactiveFormsModule, ExpansionPanelsModule, CdkTableModule,
      MatFormFieldModule, MatTooltipModule, MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatToolbarModule,
      MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule,
      MatIconModule, MatInputModule, MatNativeDateModule, MatExpansionModule,
      MatRadioModule, MatSidenavModule, MatSnackBarModule, MatTabsModule,
      MatTableModule, MatPaginatorModule, FlexLayoutModule, MatMenuModule,
      AccordionModule, LanguageModule, FrameworkCoreModule, CommonModule, HttpClientModule, SharedStaticModule, TranslateModule,
      MatStepperModule,
      MatTreeModule,
      AlertMessageModalComponent
    ],
  entryComponents: [AlertMessageModalComponent],
  providers:
    [
      McnPagerService,
      HijriService,
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ]
})
export class McnControlsModule { }
