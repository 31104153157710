import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';
import { ActivatedRoute } from '@angular/router';
import { TraderProfileService } from './TraderProfileService';
import { StorageService } from '../../../../storageService/storageService';
import { TraderCommodity, IndexValue, ColorCode } from '../../../../../app/rms/riskSelectivityProfiling/common/Constants';
import { TranslateService } from 'ng2-translate';

export interface IData { }
export class DataTableDataSource extends DataSource<any> {
  constructor(private data: IData[]) {
    super();
  }
  public connect(): Observable<IData[]> {
    return of(this.data);
  }
  public disconnect() { }
}

@Component({
  selector: 'app-360-profile-info',
  templateUrl: './TraderProfile360DegreeProfileComponent.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraderProfile360DegreeProfileComponent extends SafeUnsubscriber implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private traderProfileService: TraderProfileService,
    public webStorage: StorageService,
    private translate: TranslateService
  ) {
    super();
  }


  public traderId: number;
  public _whoTheTraderIs: any;
  private _keySuppliers: any[];
  public keySuppDataSource: DataTableDataSource | null;
  public originCaption:any ;
  public keySuppDisplayedColumns = [TraderCommodity.Origin, TraderCommodity.SupplierName];
  private _keyBuyers: any[];
  public keyBuyerDataSource: DataTableDataSource | null;
  public keyBuyerDisplayedColumns = [TraderCommodity.Destination, TraderCommodity.BuyerName];
  private _localPurchases: any[];
  public localPurchesDataSource: DataTableDataSource | null;
  public localPurchesDisplayedColumns = [TraderCommodity.Commodity, TraderCommodity.Value, TraderCommodity.Suppliers];
  private _costOfExemption: any[];
  public exemptionDataSource: DataTableDataSource | null;
  public exemptionDisplayedColumns = [TraderCommodity.ExemptionId, TraderCommodity.CostofExemption, TraderCommodity.Commodity];
  private _quotasByCbra: any[];
  public quotasDataSource: DataTableDataSource | null;
  public quotasDisplayedColumns = [TraderCommodity.ReferenceName, TraderCommodity.Cost, TraderCommodity.Validity];
  public _businessVolumeFreq: any[];
  public _businessVolumeSec: any[];
  public _businessVolumeRepay: any;
  public businessVolumeDataSource: DataTableDataSource | null;
  public businessVolumeDisplayedColumns = [TraderCommodity.CPC, TraderCommodity.Transaction, TraderCommodity.Value];
  public _complianceHistory: any;
  private _goodsConfiscated: any[];
  public goodsConfiscatedDataSource: DataTableDataSource | null;
  public goodsConfiscatedDisplayedColumns = [TraderCommodity.HSCode, TraderCommodity.Value, TraderCommodity.Number];
  private _assocParties: any[];
  public assPartiesDataSource: DataTableDataSource | null;
  public assPartiesDisplayedColumns = [TraderCommodity.Name];
  public directorsDisplayedColumns = [TraderCommodity.Director];

  public yearRecords: any = [];
  public maxYear;
  public colours = [ColorCode.ColorCode60, ColorCode.ColorCodeFF, ColorCode.ColorCode4C, ColorCode.ColorCode99, ColorCode.ColorCodeFF000];
  private colourIndex = IndexValue.IsZero;
  public _importsBarChart = [[TraderCommodity.HSCode, TraderCommodity.Percentage, { role: 'style' }, { role: 'annotation' }]];
  public _exportsBarChart = [[TraderCommodity.HSCode, TraderCommodity.Percentage, { role: 'style' }, { role: 'annotation' }]];

  public _importsLineChart = [[TraderCommodity.Year, TraderCommodity.Frequency]];
  public _exportsLineChart = [[TraderCommodity.Year, TraderCommodity.Frequency]];

  // For demo purpose
  public _importsLineChartData = [
    ['Year', '--Powered by electric motor', '--Electrically operated', '--White chocolate'],
    ['2016', 4, 3, 1],
    ['2017', 6, 5, 2],
    ['2018', 8, 5, 3],
    ['2019', 10, 5, 4]
  ];
  public _exportsLineChartData = [
    ['Year', '--Powered by electric motor', '--Electrically operated'],
    ['2016', 7, 2],
    ['2017', 8, 4],
    ['2018', 9, 6],
    ['2019', 10, 4]
  ];

  // bar Chart Related Data
  public bar_ChartOptions = {
    title: '',
    chartArea: { width: ColorCode.widthSixPer },
    hAxis: {
      title: TraderCommodity.Percentage,
      minValue: IndexValue.IsZero,
      textStyle: {
        bold: true,
        fontSize: IndexValue.IsFourteen,
        color: ColorCode.ColorCode4d
      },
      titleTextStyle: {
        bold: true,
        fontSize: IndexValue.IsFourteen,
        color: ColorCode.ColorCode4d
      }
    },
    vAxis: {
      title: '',
      textStyle: {
        fontSize: IndexValue.IsTwelve,
        bold: true,
        color: ColorCode.ColorCode84
      },
      titleTextStyle: {
        fontSize: IndexValue.IsFourteen,
        bold: true,
        color: ColorCode.ColorCode84
      }
    },
    legend: 'none',
  };

  // area Chart Related Data
  public line_ChartOptions = {
    title: '',
    hAxis: { title: TraderCommodity.Year, titleTextStyle: { color: ColorCode.ColorCode333 }, format: ColorCode.format },
    vAxis: { title: TraderCommodity.Frequency, minValue: IndexValue.IsZero },
    pointSize: IndexValue.IsFive,
    legend: { position: 'none' },
  };

  ngOnInit() {
    this.activatedRoute.data.subscribe((res) => {
      this._whoTheTraderIs = res.whoTheTraderIsResolver;
      this._keySuppliers = res.keySuppliersBuyersResolver[0].Imports;
      this._keyBuyers = res.keySuppliersBuyersResolver[0].Exports;
      this._localPurchases = res.localPurchasesResolver;
      this._costOfExemption = res.costOfExemptionResolver;
      this._quotasByCbra = res.quotasByCbraResolver;
      this._businessVolumeFreq = res.businessVolumeResolver[0].Frequency;
      this._businessVolumeSec = res.businessVolumeResolver[0].Securities[0].SecurityAmount;
      this._businessVolumeRepay = res.businessVolumeResolver[0].Repayments[0];
      this._complianceHistory = res.complianceHistoryResolver[0];
      this._goodsConfiscated = res.goodsConfiscatedResolver;
      this._assocParties = res.assocPartiesResolver;

      ///// Charts ///////////////
      let chartItems = res.importsBarChart;
      this.setToBarChart(chartItems, TraderCommodity.importsBarChart);
      this.colourIndex = IndexValue.IsZero;
      chartItems = res.exportsBarChart;
      this.setToBarChart(chartItems, TraderCommodity.exportsBarChart);
      chartItems = res.exportsLineChart;
      this.setToLineChart(chartItems, TraderCommodity.exportsLineChart);
      chartItems = res.importsLineChart;
      this.setToLineChart(chartItems, TraderCommodity.importsLineChart);
      this.setToDataSource();
    });
  }

  public setToBarChart(chartItems: any, destinationItems: string) {
    chartItems.forEach((response) => {
      const item = [response.itemHSCode, parseFloat(response.percentage), this.colours[this.colourIndex], response.percentage + '%'];
      if (destinationItems === TraderCommodity.importsBarChart) {
        this._importsBarChart.push(item);
      } else if (destinationItems === TraderCommodity.exportsBarChart) {
        this._exportsBarChart.push(item);
      }
      this.colourIndex += IndexValue.IsOne;
    });
  }

  public setToLineChart(chartItems: any, destinationItems: string) {
    chartItems.forEach((response) => {
      if (response.ItemListTrans.length > IndexValue.IsZero) {
        const item = [response.ItemListTrans[0].Year, response.ItemListTrans[0].Frequency];
        if (destinationItems === TraderCommodity.exportsLineChart) {
          this._exportsLineChart.push(item);
        } else if (destinationItems === TraderCommodity.importsLineChart) {
          this._importsLineChart.push(item);
        }
        this.yearRecords.push(response.ItemListTrans[0].Year);
      }
    });
    this.maxYear = Math.max(...this.yearRecords) + IndexValue.IsOne;
    this.getYearProblem(destinationItems);
  }

  public getYearProblem(destinationItems: string) {
    let count: number;
    if (destinationItems === TraderCommodity.exportsLineChart) {
      count = this._exportsLineChart.length;
      if (count < IndexValue.IsEleven) {
        this.setYearProblem(destinationItems, count);
      }
    } else if (destinationItems === TraderCommodity.importsLineChart) {
      count = this._importsLineChart.length;
      if (count < IndexValue.IsEleven) {
        this.setYearProblem(destinationItems, count);
      }
    }
  }

  public setYearProblem(destinationItems: string, count: number) {
    for (let i = count; i < IndexValue.IsNine; i++) {
      if (destinationItems === TraderCommodity.exportsLineChart) {
        this._exportsLineChart.push([this.maxYear, IndexValue.IsZero]);
      } else if (destinationItems === TraderCommodity.importsLineChart) {
        this._importsLineChart.push([this.maxYear, IndexValue.IsZero]);
      }
      this.maxYear += IndexValue.IsOne;
    }
  }

  private setToDataSource() {
    this.keySuppDataSource = new DataTableDataSource(this._keySuppliers);
    this.keyBuyerDataSource = new DataTableDataSource(this._keyBuyers);
    this.localPurchesDataSource = new DataTableDataSource(this._localPurchases);
    this.exemptionDataSource = new DataTableDataSource(this._costOfExemption);
    this.quotasDataSource = new DataTableDataSource(this._quotasByCbra);
    this.businessVolumeDataSource = new DataTableDataSource(this._businessVolumeFreq);
    this.goodsConfiscatedDataSource = new DataTableDataSource(this._goodsConfiscated);
    this.assPartiesDataSource = new DataTableDataSource(this._assocParties);
  }

  public setImportExportBar(selectedValue: number, IorE: string) {
    let chartItems;
    this.colourIndex = IndexValue.IsZero;
    if (IorE === TraderCommodity.import) {
      this.traderProfileService.importsBarChart(this.webStorage.get(TraderCommodity.traderId), selectedValue).subscribe(res => {
        chartItems = res;
        this._importsBarChart.splice(IndexValue.IsOne, this._importsBarChart.length - IndexValue.IsOne);
        this.setToBarChart(chartItems, TraderCommodity.importsBarChart);
      });
    } else if (IorE === TraderCommodity.export) {
      this.traderProfileService.exportsBarChart(this.webStorage.get(TraderCommodity.traderId), selectedValue).subscribe(res => {
        chartItems = res;
        this._exportsBarChart.splice(IndexValue.IsOne, this._exportsBarChart.length - IndexValue.IsOne);
        this.setToBarChart(chartItems, TraderCommodity.exportsBarChart);
      });
    }
  }
}

