import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ShipCargoDetailsModel } from '../../../models/ShipCargoDetailsModel';
import { ShipCargoService } from '../../../services/ShipCargoService';
import { FxContext, ServiceDocument } from '@agility/frameworkcore';
import { StorageService } from '../../../../storageService/storageService';
import { SignalRService } from '../../../../signalR/signalR.service';
import { SafeUnsubscriber } from '../../../../common/SafeUnsubscriber';
import { ShipCargoDetailsDataModel } from '../../../../../app/shipClr/models/ShipCargoDetailsDataModel';
export interface Data { }
export class ExampleDatabase {
  /** Stream that emits whenever the data has been modified. */
  public agentsList: any[] = [
    { docType: 'XLSX', docRefNum: 'A12D440', fileName: 'Exchange Rate', expiryDate: '12 Feb 2018' },
  ];

  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get data(): any[] { return this.dataChange.value; }

  constructor() {
    this.dataChange.next(this.agentsList);
  }
}

export class ExampleDataSource extends DataSource<any> {
  constructor(private data: Data[]) {
    super();
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Data[]> {
    return Observable.of(this.data);
  }
  disconnect() { }
}
@Component({
  selector: 'app-upld-cargo-docs',
  templateUrl: './UpldCargoDocsComponent.html',
})
export class UpldCargoDocsComponent extends SafeUnsubscriber implements OnInit {
  public upldCargoDocs: FormGroup;
  private shipCargoDetailsServiceDocument: ServiceDocument<ShipCargoDetailsDataModel>;
  public uploaddocuments: any[] = [];
  private upLoadDocsUserId: any;
  public uploadDocsDataSource: any;
  public uploadDoctypes: any[] = [];
  constructor(private fb: FormBuilder,
    private shipCargoService: ShipCargoService,
    private fxContext: FxContext,
    public storageService: StorageService,
    private signalRService: SignalRService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UpldCargoDocsComponent>) {
    super();
    this.uploadDoctypes = [
      { key: '1', value: 'Others' }
    ];
  }

  ngOnInit() {
    this.upldCargoDocs = this.fb.group({
      filePath: ['', [<any>Validators.required]],
      file: ['',],
    });

    this.uploadDocsDataSource = new ExampleDataSource(this.uploaddocuments);
    this.fxContext.userProfile = JSON.parse(this.storageService.get('userProfile').toString());
    this.upLoadDocsUserId = this.fxContext.userProfile.userName;
    this.shipCargoDetailsServiceDocument = this.shipCargoService.mcserviceDocument;
    this.signalRService.startConnection();
    this.signalRService.ShowServerNotification(this.upLoadDocsUserId);
  }

  public uploadExcelFile() {
    const shipCargoDetailsModel = new ShipCargoDetailsModel();
    shipCargoDetailsModel.excelDocuments = this.uploaddocuments;
    if (this.upldCargoDocs.valid) {
      this.shipCargoService.submitExeclforShipCargo(shipCargoDetailsModel).subscribe((response) => {
        this.dialog.closeAll();
      });
    } else {
      Object.keys(this.upldCargoDocs.controls).map(
        controlName => this.upldCargoDocs.controls[controlName]).forEach(
          control => { control.markAsTouched(); }
        );
    }
  }
  public close() {
    this.dialogRef.close();
  }
  public fileChange(event: any) {
    const docdata = {
      'base64String': event.base64String,
      'fileName': event.fileName,
      'docId': 1,
      'docType': '',
      'expiryDate': new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(),
      'docRefNo': '',
    };
    this.uploaddocuments = [];
    this.uploaddocuments.push(docdata);
    this.upldCargoDocs.controls.filePath.setValue(docdata.fileName);
  }

}
