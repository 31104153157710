<div fxLayout="column" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxLayout="column" fxLayoutAlign="center center">
    <h1 matDialogTitle class="dialog-title">{{'OutlierAnalysis' | translate}}</h1>
  </div>
  <div mat-dialog-content>
    <div style="direction: ltr;">
      <chart [options]="this.options1" (touchdrag.hc)="test($event)"></chart>
    </div>
  </div>
</div>
<button matDialogClose class="dialog-close">
  <i class="icon-close"></i>
</button>