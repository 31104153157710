import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { InboxService } from './InboxService';

@Injectable()
export class InboxListResolver implements Resolve<any> {
  constructor(private service: InboxService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.service.getMessageList();
  }
}
