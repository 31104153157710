import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { McnConfig } from '../../../../mcnConfig/McnConfig';
import { SafeUnsubscriber } from '../../../../../app/common/SafeUnsubscriber';
import { StorageService } from '../../../../../app/storageService/storageService';

@Component({
  selector: 'app-market-vehical-upload',
  templateUrl: './MarketResearchVehicalUploadComponent.html',
})

export class MarketResearchVehicalUploadComponent extends SafeUnsubscriber implements OnInit {
  public riskIndicatorUploadFrom: FormGroup;
  public modified: boolean;
  public newFrequecy: boolean;

  constructor(private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogRef: MatDialogRef<MarketResearchVehicalUploadComponent>,
    private dialog: MatDialog,
    public webStorage: StorageService,
    public appConfig: McnConfig,
  ) {
    super();
  }

  ngOnInit() {
    this.riskIndicatorUploadFrom = this.fb.group({
      docType: [''],
      docRefNo: [''],
      expDate: [''],
      fileUpload: [''],
      remarks: [''],
    });
  }
}
