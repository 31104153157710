import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../../../storageService/storageService';
import { 
    arimaData, arimaForecast, multivariate, multiVariateForecast, lstm, lstmForecast, totalRevenue,
    cycle, trendRevenue, sampleRecallData, recallRandomForestData, revenueRandomForestData, 
    recallAdaBoostData, revenueAdaBoostData, recallXGBoostData, revenueXGBoostData, recallLightGBMData, revenueLightGBMData,
    recallCatBoostData, revenueCatBoostData, recallNaiveEnsembleData, revenueNaiveEnsembleData, 
    recallDATEData, revenueDATEData
  } from '../../../dashboard/userDashboard/data/data';
  import * as shape from 'd3-shape'
import { RMSForecastChart, RMSFraudDetectionChart } from '../models/RMSChart';
import { DatePipe } from '@angular/common';
import { NVDBOutliersSharedService } from '../services/sharedServices/nvdbOutlierSharedService';

@Component({
  selector: 'app-rms-model-charts',
  templateUrl: './RmsModelChartComponent.html',
})
export class RmsModelChartComponent implements OnInit {

  public arima: any[];
  public arimaForecast: any[];
  public multiVariate: any[];
  public multiVariateForecast: any[];
  public lstm: any[];
  public lstmForecast: any[];
  public date: Date;
  public selectedTab: any;
  public curve: any;
  public totalRevenue: any[];
  public cycle: any[];
  public trendRevenue: any[];
  public recallData: any[];
  public revenueData: any[];
  public recallRandomForestData: any[];
  public revenueRandomForestData: any[];
  public recallAdaBoostData: any[];
  public revenueAdaBoostData: any[];
  public recallXGBoostData: any[];      
  public revenueXGBoostData: any[]; 
  public recallLightGBMData: any[];
  public revenueLightGBMData: any[];
  public recallCatBoostData: any[];
  public revenueCatBoostData: any[];
  public recallNaiveEnsembleData: any[];
  public revenueNaiveEnsembleData: any[];
  public recallDATEData: any[];
  public revenueDATEData: any[];
  public rmsForecastDashboard: RMSForecastChart;
  public rmsFraudDetectionDashboard: RMSFraudDetectionChart;

  view: any[] = [1050, 300];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Customs Revenue';
  timeline: boolean = true;

  colorScheme = {
    domain: [ '#506899', '#82c065', '#fd1b3e', '#fd1b3e' ]
  };
  colorSchemeMultiVariate = {
    domain: [ '#bb0a1e', '#506899', '#000000' ]
  };
  colorSchemeData = {
    domain: ['#506899']
  };
  colorSchemeForecast = {
    domain: ['#00bfa5', '#bb0a1e', '#506899']
  };

  public column_chart_recall_random = {
    title: 'Performance of RandomFore',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall(Illicit Transactions)'
    }
  };
  public column_chart_revenue_random = {
    title: 'Performance of RandomFore',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };
  public column_chart_recall_ada = {
    title: 'Performance of AdaBoost',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall(Illicit Transactions)'
    }
  };
  public column_chart_revenue_ada = {
    title: 'Performance of AdaBoost',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };
  public column_chart_recall_xgb = {
    title: 'Performance of XGBoost',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall(No. of Seizures)'
    }
  };
  public column_chart_revenue_xgb = {
    title: 'Performance of XGBoost',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };
  public column_chart_recall_lightgbm = {
    title: 'Performance of LightGBM',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall(Illicit Transactions)'
    }
  };
  public column_chart_revenue_lightgbm = {
    title: 'Performance of LightGBM',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };
  public column_chart_recall_cat = {
    title: 'Performance of CatBoost',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall(Illicit Transactions)'
    }
  };
  public column_chart_revenue_cat = {
    title: 'Performance of CatBoost',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };
  public column_chart_recall_naive = {
    title: 'Performance of NaiveEnsemble',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall(Illicit Transactions)'
    }
  };
  public column_chart_revenue_naive = {
    title: 'Performance of NaiveEnsemble',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };
  public column_chart_recall_date = {
    title: 'Performance of DATE',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Recall'
    }
  };
  public column_chart_revenue_date = {
    title: 'Performance of DATE',
    legend: 'none',
    hAxis: {
      title: 'Inspection Rate'
    },
    vAxis:{
        title: 'Seized Revenue'
    }
  };

  public line_ChartRecallOptions = {
    title: '',
    curveType: 'function',
    legend: {
      position: 'right'
    },
    hAxis: {
      title: 'Top-n%(Inspection Rate)'
    },
    vAxis:{
        title: 'Recall(Illicit Transactions)'
    },
    series: {
        0: { lineWidth: 2, lineDashStyle: [0.5, 0.5, 0.5] },
        1: { lineWidth: 2, lineDashStyle: [1, 1, 1] },
        2: { lineWidth: 2, lineDashStyle: [1.5, 1.5, 1.5] },
        3: { lineWidth: 2, lineDashStyle: [2, 2, 2] },
        4: { lineWidth: 2, lineDashStyle: [2.5, 2.5, 2.5] },
        5: { lineWidth: 2, lineDashStyle: [3, 3, 3] },
        6: { lineWidth: 2, lineDashStyle: [3.5, 3.5, 3.5] },
        7: { lineWidth: 2, lineDashStyle: [4, 4, 4] },
        8: { lineWidth: 2, lineDashStyle: [4.5, 4.5, 4.5] }
    },
	  pointSize: 3,
    colors:['#506899', '#82c065', '#fd1b3e', '#fcd612', '#36524b', '#9096e6', '#b69c63', '#5e4c5f', '#ff80ed' ],
    width: 900,
    height: 500
  };

  public line_ChartRevenueOptions = {
    title: '',
    curveType: 'function',
    legend: {
      position: 'right'
    },
    hAxis: {
      title: 'Top-n%(Inspection Rate)'
    },
    vAxis:{
        title: 'Revenue(Seized Revenue)'
    },
    series: {
        0: { lineWidth: 2, lineDashStyle: [0.5, 0.5, 0.5] },
        1: { lineWidth: 2, lineDashStyle: [1, 1, 1] },
        2: { lineWidth: 2, lineDashStyle: [1.5, 1.5, 1.5] },
        3: { lineWidth: 2, lineDashStyle: [2, 2, 2] },
        4: { lineWidth: 2, lineDashStyle: [2.5, 2.5, 2.5] },
        5: { lineWidth: 2, lineDashStyle: [3, 3, 3] },
        6: { lineWidth: 2, lineDashStyle: [3.5, 3.5, 3.5] },
        7: { lineWidth: 2, lineDashStyle: [4, 4, 4] },
        8: { lineWidth: 2, lineDashStyle: [4.5, 4.5, 4.5] }
    },
	  pointSize: 3,
    colors:['#506899', '#82c065', '#fd1b3e', '#fcd612', '#36524b', '#9096e6', '#b69c63', '#5e4c5f', '#ff80ed' ],
    width: 900,
    height: 500
  };

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  constructor(public webStorageService: StorageService, 
    private activatedRoute: ActivatedRoute, 
    private userDashboradService: NVDBOutliersSharedService) { }

  ngOnInit() {
    this.date = new Date();
    this.curve = shape.curveCardinal.tension(0);
    let arrRecallHeader: any[] = [['Inspection Rate', 'Recall']];
    let arrRevenueHeader: any[] = [['Inspection Rate', 'Revenue']];

    this.userDashboradService.getRMSMLData().subscribe((res) => {
      this.rmsForecastDashboard = res;
      this.arima = this.rmsForecastDashboard.arima_data || arimaData;
      this.arimaForecast = this.rmsForecastDashboard.arima_forecast !== null 
      ? [
          this.rmsForecastDashboard.arima_data[0], 
          this.rmsForecastDashboard.arima_forecast[0],
          this.rmsForecastDashboard.arima_forecast[2],
          this.rmsForecastDashboard.arima_forecast[1]
      ] 
      : arimaForecast;
      this.multiVariate = this.rmsForecastDashboard.multivariate_data || multivariate;
      this.multiVariateForecast = this.rmsForecastDashboard.multivariate_forecast !== null 
      ? [
          this.rmsForecastDashboard.multivariate_data[0], 
          this.rmsForecastDashboard.multivariate_forecast[1],
          this.rmsForecastDashboard.multivariate_forecast[0],
      ] 
      : multiVariateForecast;
      this.lstm = this.rmsForecastDashboard.lstm_data || lstm;
      this.lstmForecast = this.rmsForecastDashboard.lstm_forecast !== null 
      ? [
          this.rmsForecastDashboard.lstm_data[0], 
          this.rmsForecastDashboard.lstm_forecast[1],
          this.rmsForecastDashboard.lstm_forecast[0],
      ] 
      : lstmForecast;
      this.totalRevenue = this.rmsForecastDashboard.Total_Revenue !== null 
      ? this.rmsForecastDashboard.Total_Revenue : totalRevenue;
      this.trendRevenue = this.rmsForecastDashboard.diff_revenue !== null 
      ? [
          this.rmsForecastDashboard.diff_revenue[0], 
          this.rmsForecastDashboard.trend[0]
      ] 
      : trendRevenue;
      this.cycle = this.rmsForecastDashboard.cycle !== null 
      ? this.rmsForecastDashboard.cycle : cycle;
      this.recallRandomForestData = arrRecallHeader.concat(this.rmsForecastDashboard.RandomForestClassifierRecall) || recallRandomForestData;
      this.revenueRandomForestData = arrRevenueHeader.concat(this.rmsForecastDashboard.RandomForestClassifierRevenue) || revenueRandomForestData;
      this.recallAdaBoostData = arrRecallHeader.concat(this.rmsForecastDashboard.AdaBoostClassifierRecall) || recallAdaBoostData;
      this.revenueAdaBoostData = arrRevenueHeader.concat(this.rmsForecastDashboard.AdaBoostClassifierRevenue) || revenueAdaBoostData;
      this.recallXGBoostData = arrRecallHeader.concat(this.rmsForecastDashboard.XGBoostClassifierRecall) || recallXGBoostData;      
      this.revenueXGBoostData = arrRevenueHeader.concat(this.rmsForecastDashboard.XGBoostClassifierRevenue) || revenueXGBoostData; 
      this.recallLightGBMData = arrRecallHeader.concat(this.rmsForecastDashboard.LGBMClassifierRecall) || recallLightGBMData;
      this.revenueLightGBMData = arrRevenueHeader.concat(this.rmsForecastDashboard.LGBMClassifierRevenue) || revenueLightGBMData;
      this.recallCatBoostData = arrRecallHeader.concat(this.rmsForecastDashboard.CatBoostClassifierRecall) || recallCatBoostData;
      this.revenueCatBoostData = arrRevenueHeader.concat(this.rmsForecastDashboard.CatBoostClassifierRevenue) || revenueCatBoostData;
      this.recallNaiveEnsembleData = arrRecallHeader.concat(this.rmsForecastDashboard.NaiveEnsembleClassifierRecall) || recallNaiveEnsembleData;
      this.revenueNaiveEnsembleData = arrRevenueHeader.concat(this.rmsForecastDashboard.NaiveEnsembleClassifierRevenue) || revenueNaiveEnsembleData;
      this.recallDATEData = arrRecallHeader.concat(this.rmsForecastDashboard.DateClassifierRecall) || recallDATEData;
      this.revenueDATEData = arrRevenueHeader.concat(this.rmsForecastDashboard.DateClassifierRevenue) || revenueDATEData;
      this.recallData =  
        this.createDataPoints(this.getCol(this.rmsForecastDashboard.RandomForestClassifierRecall, 0), this.getCol(this.rmsForecastDashboard.RandomForestClassifierRecall, 1), 
        this.getCol(this.rmsForecastDashboard.AdaBoostClassifierRecall, 1), this.getCol(this.rmsForecastDashboard.XGBoostClassifierRecall, 1), 
        this.getCol(this.rmsForecastDashboard.LGBMClassifierRecall, 1), this.getCol(this.rmsForecastDashboard.CatBoostClassifierRecall, 1), 
        this.getCol(this.rmsForecastDashboard.NaiveEnsembleClassifierRecall, 1), this.getCol(this.rmsForecastDashboard.DateClassifierRecall, 1)
        ) || sampleRecallData;
      this.revenueData =  
        this.createDataPoints(this.getCol(this.rmsForecastDashboard.RandomForestClassifierRevenue, 0), this.getCol(this.rmsForecastDashboard.RandomForestClassifierRevenue, 1), 
        this.getCol(this.rmsForecastDashboard.AdaBoostClassifierRevenue, 1), this.getCol(this.rmsForecastDashboard.XGBoostClassifierRevenue, 1),  
        this.getCol(this.rmsForecastDashboard.LGBMClassifierRevenue, 1), this.getCol(this.rmsForecastDashboard.CatBoostClassifierRevenue, 1), 
        this.getCol(this.rmsForecastDashboard.NaiveEnsembleClassifierRevenue, 1), this.getCol(this.rmsForecastDashboard.DateClassifierRevenue, 1)
        ) || sampleRecallData;
    });
    
    this.activatedRoute.data
      .subscribe((res) => {
      if (this.activatedRoute.snapshot.params.tabId) {
        this.getDashboardData(Number(this.activatedRoute.snapshot.params.tabId));
      } else {
        this.getDashboardData(0);
      }
    });
  }

  public tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.getDashboardData(tabChangeEvent.index);
  }

  public getDashboardData(id: number) {
    this.selectedTab = id;
  }

  formatNumber(input: number): string {
    return '$' + input + 'Bn';
  }

  axisDate(val: string): string {
    return new DatePipe('en').transform(val, 'MM-dd-yyyy');
  }

  getCol(matrix, col): any {
    var column = [];
    for(var i = 0; i < matrix.length; i++) {
       column.push(matrix[i][col]);
    }
    return column;
 }

 createDataPoints(rowIndex: any, rfClassifier: any, abBoost: any, xgBoost: any, lgbmClassifier: any, 
  catBoost: any, naiveClassifier: any, dateClassifier: any): any[] {
    var arrObj: any[] = [['InspectionRate', 'RandomFore', 'AdaBoostCl', 'XGBClassif', 'LGBMClassi', '<catboost.', 'NaiveEnsem', 'DATE']];
    for(var i = 0; i < rowIndex.length; i++) {
      var arrInner = [];
      arrInner.push(rowIndex[i], rfClassifier[i], abBoost[i], xgBoost[i], lgbmClassifier[i], 
        catBoost[i], naiveClassifier[i], dateClassifier[i]);
      arrObj.push(arrInner);
    }
    return arrObj;
 }
}

