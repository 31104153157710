<div [formGroup]="formGroup"  *ngIf="isFormGroup">
       <mat-form-field class="mat-input-container">
          <input matInput [disabled]="disabled" id="txt_{{optionalId}}{{placeholder | removeSpace}}" type="{{type}}" maxlength="{{maxlength}}" minlength="{{minlength}}" placeholder="{{placeholder}}" (keydown)="handleKeydownEvent($event.target.value)" (keypress)="handleKeyPress($event.target.value)"
                    (keyup)="handleKeyupEvent($event.target.value)" (mouseenter)="onMouseEvent($event)" (mouseleave)="onMouseEvent($event)" (mousedown)="onMouseEvent($event)"
                    (mouseup)="onMouseEvent($event)" (blur)="handleblurEvent($event)" (mouseover)="onMouseEvent($event)" autocomplete="{{autocomplete}}" formControlName="{{controlName}}" [required]="required" [readonly]="readonly" />
       </mat-form-field>
    </div>
<div *ngIf="!isFormGroup">
       <mat-form-field class="mat-input-container">
          <input matInput [disabled]="disabled" [value]="value" id="txt_{{optionalId}}{{placeholder | removeSpace}}" type="{{type}}" placeholder="{{placeholder}}" (keydown)="handleKeydownEvent($event.target.value)" (keypress)="handleKeyPress($event.target.value)"
                    (keyup)="handleKeyupEvent($event.target.value)" (mouseenter)="onMouseEvent($event)" (mouseleave)="onMouseEvent($event)" (mousedown)="onMouseEvent($event)"
                    (mouseup)="onMouseEvent($event)" (blur)="handleblurEvent($event)" (mouseover)="onMouseEvent($event)" autocomplete="{{autocomplete}}" [required]="required" [readonly]="readonly" />
       </mat-form-field>
    </div>