/* tslint:disable: max-line-length */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { StorageService } from '../storageService/storageService';


@Injectable()
export class SezConfig implements OnInit {

  // Destruction Request
  listDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDestructionRequest/ListSezDestructionRequest';
  newDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDestructionRequest/NewSEZDestructionRequest';
  openDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDestructionRequest/OpenSEZDestructionRequest';
  submitDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDestructionRequest/SubmitSEZDestructionRequest';
  deleteDestructionRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDestructionRequest/DeleteSEZDestructionRequest';
  listDestructionItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDestructionRequest/ListSEZDestructionItems';

  // SezRemitDuties Request
  listRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/ListSezRemitDuties';
  newRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/NewSezRemitDuties';
  openRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/OpenSezRemitDuties';
  submitRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/SubmitSezRemitDuties';
  deleteRemitDuties: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/DeleteSezRemitDuties';
  filterRemitDutiesTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/ListSezWarehouseFormItems';
  submitRemitDutiesRequestItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/SubmitSezRemittanceReqItems';
  listAssociatedRemitDutiesRequestItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/ListSezAssociatedRemitDutiesRequestItems';
  submitRemitDutiesInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/SubmitSezRemitInspectionItem';
  openRemitDutiesInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezRemitDuties/OpenSezRemitInspectionItem';

  // SezStorageExtension Request
  listStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezStorageExtension/ListSezStorageExtension';
  newStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezStorageExtension/NewSezStorageExtension';
  openStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezStorageExtension/OpenSezStorageExtension';
  submitStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezStorageExtension/SubmitSezStorageExtension';
  deleteStorageExtension: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezStorageExtension/DeleteSezStorageExtension';
  listStorageExtensionWHItems = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezStorageExtension/ListSezStorageExtensionWHItems';

  // SezTransferRequest
  listTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/ListSezTransferRequest';
  newTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/NewSezTransferRequest';
  openTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/OpenSezTransferRequest';
  submitTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/SubmitSezTransferRequest';
  deleteTransferRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/DeleteSezTransferRequest';
  organizationApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/GetSezOrganizations?filterValue=';
  filterTransferGoodsRequestTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/ListSezWarehouseFormItems';
  submitTransferGoodsRequestItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/SubmitSezTransferReqItems';
  listAssociatedTransferGoodsRequestItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/ListAssociatedSezTransferRequestItems';
  submitTransferGoodsRequestInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/SubmitSezWHFormItem';
  openTransferGoodsRequestInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/OpenSezWHFormItem';
  getOrganizationDetailsApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezTransferRequest/GetSezOrganizationDetails';

  // WareHouse Quantity Balance
  listWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/ListSezWarehouseBalance';
  openWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/OpenSezWarehouseBalance';
  deleteWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/deleteWareHouseBalance';
  newWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/newWareHouseBalance';
  submitWareHouseBalance: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/submitWareHouseBalance';
  listWareHouseBalanceFormItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/ListSezWarehouseItems';
  openWareHouseBalanceTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/OpenSezTariffItem';
  listWareHouseQuantityBalanceItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouseBalance/ListSezWarehouseBalanceItems';

  // SezWarehouse Inbound/Outbound form
  listWareHouse = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/ListSezWarehouse';
  openWareHouse = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/OpenSezWarehouse';
  newWareHouse: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/NewSezWarehouse';
  submitWareHouse: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/SubmitSezWarehouse';
  deleteWareHouse: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/DeleteSezWarehouse';
  filterTariffItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/AssociateSezTariffItem';
  submitWarehouseTariffItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/SubmitSezTariffItems';
  getWHRemarksHistoryData: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/GetSezWHRemarksHistoryData';
  listAssociatedWarehouseTariffItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/ListAssociatedSezWarehouseTariffItems';
  licenseInfoApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/GetSezWarehouseLicenseNumbers?filterValue=';
  consigneeInfoApiUrl: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/GetSezConsigneeInfo?filterValue=';
  listWarehouseItems = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/ListSezWarehouseItems';
  newInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/NewSezInvoiceItem';
  getTariffItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/GetSezTariffItems?filterValue=';
  submitInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/SubmitSezInvoiceItem';
  openInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/OpenSezInvoiceItem';
  deleteInvoiceItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/DeleteSezInvoiceItem';
  getWarehouseWfStates: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/GetSezWarehouseWfStates';
  getWhFormItemsWfStates: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/GetSezWHFormItemsWfStates';
  openWHFormItem: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/OpenSezWHFormItem';

  // Vehicle Movement Request
  listVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/ListSezVehicleMovement';
  newVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/NewSezVehicleMovement';
  openVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/OpenSezVehicleMovement';
  submitVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/SubmitSezVehicleMovement';
  deleteVehicleMovement: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/DeleteSezVehicleMovement';
  filterWarehouseVehicles: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/AssociateSezWarehouseVehicles';
  listAssociatedWarehouseVehicles: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/ListAssociatedSezWarehouseVehicles';
  submitAssocWarehouseVehicles: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/SubmitAssocSezWarehouseVehicles';
  listVMExtensionHistory: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/ListSezVMExtensionHistory';
  newVMExtensionHistory: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/NewSezVMExtensionHistory';
  submitVMExtensionHistory: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezVehicleMovement/SubmitSezVMExtensionHistory';

  listAssociatedRemittanceRequestItems: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/listAssociatedWarehouseTariffItems';

  // Discrepancy Request
  listDiscrepancyItems = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/ListSezDiscrepancyItems';
  listDiscrepancyRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/ListSezDiscrepancyRequest';
  newDiscrepancyRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/NewSezDiscrepancyRequest';
  openDiscrepancyRequest = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/OpenSezDiscrepancyRequest';
  submitDiscrepancyRequest = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/SubmitSezDiscrepancyRequest';
  deleteDiscrepancyRequest: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/DeleteSezDiscrepancyRequest';
  openDiscrepancyItem = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/OpenSezDiscrepancyItem';
  submitDiscrepancyItem = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezDiscrepancyRequest/SubmitDiscrepancyItem';

  // MDM
  getMcnTypes: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/McnTypes/GetModuleType';
  countryApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Country/GetCountry?filterValue=';
  volumeUnitApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/VolumeUnit/GetVolumeUnit?filterValue=';
  currencyApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/Currency/GetCurrency?filterValue=';
  customStationApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/CustomStation/GetCustomStation?filterValue=';
  stateApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/State/GetAllState?filterValue=';
  cityApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/City/GetCity?filterValue=';
  postalApiUrl: any = environment.appSettings.apiEndpoints.mdmApiBaseUrl + '/api/PostalCode/GetPostalCode?filterValue=';


  // Documents Upload
  openWarehouseUploadDocument: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/OpenSezWarehouseUploadDocument';
  submitWarehouseUploadDocument: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/SubmitSezWarehouseUploadDocument';
  deleteWarehouseUploadDocuments: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/DeleteSezWarehouseUploadDocuments';
  downloadWarehouseUploadDocuments: any = environment.appSettings.apiEndpoints.bwhApiBaseUrl + '/api/SezWarehouse/DownloadSezWarehouseUploadDocuments';

  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }
  ngOnInit() {
  }

}
