<div fxLayout="row wrap" fxLayoutAlign="end end" [ngClass]="{'arb': (this.webStorage.get('lang_code') === 'Arb')}">
  <div fxFlex="100" fxFlex.gt-sm="10" fxLayout="row" fxLayoutAlign="end center">
    <mcn-select [formGroup]="lineChartForm" optionKey="value"
                optionValue="viewValue" placeholder="{{'Year' | translate}}" controlName="year"
                [optionsData]="this.yearData" (OnChange)="onYearSelect($event)" selectedValue="2021">
    </mcn-select>

    <!--<mat-form-field>
      <mat-select placeholder="Year" (selectionChange)="onYearSelect($event.value)" value="2018">
        <mat-option *ngFor="let year of this.yearData" [value]="year.value">
          {{ year.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
  </div>
</div>
<!--<ngx-charts-line-chart [view]="view"
                       [scheme]="colorScheme"
                       [results]="this.optionsData"
                       [gradient]="gradient"
                       [xAxis]="showXAxis"
                       [yAxis]="showYAxis"
                       [legend]="showLegend"
                       [showGridLines]="showGridLines"
                       [showXAxisLabel]="showXAxisLabel"
                       [showYAxisLabel]="showYAxisLabel"
                       [autoScale]="autoScale"
                       (select)="onSelect($event)">
</ngx-charts-line-chart>-->

<div id="line_chart" [chartData]="this.optionsData" [chartOptions]="line_ChartOptions" chartType="LineChart" GoogleChart></div>
